import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Box, Text } from '@chakra-ui/react';
import './../../App.css';
import WebLinkNewWindow from 'src/components/WebLinkNewWindow';
import FAIcon from 'src/components/FAIcon';
import { useTranslation } from 'react-i18next';

interface Props {
  type: string;
  videoUrl: string;
  maxWidth: number;
  maxHeight: number;
  isPir?: boolean | undefined;
  triggerTodo?: () => void;
}

const InlineVideoPlayer = React.memo(({ videoUrl, maxWidth, maxHeight, isPir, triggerTodo, type }: Props) => {
  const [playerSize, setPlayerSize] = useState({ width: '100%', height: '100%' });
  const [videoLinkDisplay, setVideoLinkDisplay] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(9 / 16);
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation('meditations');

  useEffect(() => {
    if (type === 'attachment') {
      const video = document.createElement('video');
      const onMetadataLoaded = function (this: any) {
        const height = this.videoHeight;
        const width = this.videoWidth;
        const ratio = height / width;
        if (ratio !== aspectRatio) {
          setAspectRatio(ratio);
        }
        if (!isVisible) {
          setIsVisible(true);
        }
      };
      video.addEventListener('loadedmetadata', onMetadataLoaded);
      video.src = videoUrl;
      return () => {
        video.removeEventListener('loadedmetadata', onMetadataLoaded);
        setAspectRatio(9 / 16);
      };
    } else {
      setIsVisible(true);
    }
  }, [videoUrl]);

  useEffect(() => {
    const calculatedHeight = maxWidth * aspectRatio;

    if (calculatedHeight > maxHeight) {
      const scaledWidth = maxHeight / aspectRatio;
      // If calculated height is too tall, scale down:
      setPlayerSize({
        width: `${scaledWidth}px`,
        height: `${maxHeight}px`,
      });
    } else {
      // Otherwise, fit by width:
      setPlayerSize({
        width: `${maxWidth}px`,
        height: `${calculatedHeight}px`,
      });
    }
    return () => {
      setPlayerSize({ width: '100%', height: '100%' });
    };
  }, [maxWidth, maxHeight, aspectRatio]);

  return (
    <Box>
      {videoLinkDisplay ? (
        <Box fontWeight="light" color="#F02F2F">
          <FAIcon icon={'circle-exclamation'} color="#F02F2F" margin="2px" />
          {t('meditationModulePIR.errors.meditationVideoError')}
          <Box>
            <WebLinkNewWindow href={videoUrl}>
              <Text
                fontSize={[16, 16, 18]}
                marginRight={4}
                fontWeight="500"
                color="#2D98A1"
                isTruncated={true}
                _hover={{ textDecoration: 'underline' }}
                onClick={() => {
                  isPir && triggerTodo && triggerTodo();
                }}
              >
                {videoUrl.replace(/(^\w+:|^)\/\//, '')}
              </Text>
            </WebLinkNewWindow>
          </Box>
        </Box>
      ) : isVisible ? (
        <Box
          className="player-wrapper"
          style={{
            width: playerSize.width,
            height: playerSize.height,
            visibility: isVisible ? 'visible' : 'hidden',
          }}
        >
          <ReactPlayer
            url={videoUrl}
            width="100%"
            height="100%"
            controls={true}
            className="react-player"
            onError={() => {
              setVideoLinkDisplay(true);
            }}
            playsinline={true}
            onClick={
              isPir &&
              (() => {
                triggerTodo && triggerTodo();
              })
            }
          />
        </Box>
      ) : null}
    </Box>
  );
});

InlineVideoPlayer.displayName = 'InlineVideoPlayer';

export default InlineVideoPlayer;
