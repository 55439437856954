import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { Flex, Text, Heading, Box } from '@chakra-ui/react';
import FAIcon, { FAIconProps } from '../../components/FAIcon';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import './LeftNav.css';
import ModalAlert from './ModalAlert';
import { UserRole } from '../user/interfaces/IUser';

const USER_ROUTES = new Map([
  [
    'admin',
    [
      {
        key: 'nav-route-invitations',
        icon: 'users',
        titleProp: 'header.invitations',
        route: '/home',
      },
    ],
  ],
  [
    'cp',
    [
      { key: 'nav-route-patients-list', icon: 'users', titleProp: 'header.list', route: '/dashboard' },
      { key: 'nav-route-patient-profile', icon: 'user', titleProp: 'header.profile', route: '/profile' },
      { key: 'nav-route-patient-vitals', icon: 'chart-bar', titleProp: 'header.vital', route: '/vitals' },
    ],
  ],
  [
    'pir',
    [
      { key: 'nav-route-home', icon: 'home', titleProp: 'header.home', route: '/dashboard' },
      { key: 'nav-route-vitals', icon: 'chart-bar', titleProp: 'header.vital', route: '/vitals' },
      { key: 'nav-route-alert', icon: 'exclamation-circle', titleProp: 'header.alert', route: '#' },
    ],
  ],
  ['', []],
]);

interface LeftNavIconProps extends FAIconProps {
  title: string;
  to: string;
  largeNav: boolean;
  flip?: boolean;
  toggleNavSmall: () => void;
}

const LeftNavIcon = (props: LeftNavIconProps): JSX.Element => {
  const { icon, title, to } = props;
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const active = location.pathname === to;
  // const modalAlertDisclosure = useDisclosure();
  const openModal = (): void => {
    setOpen(true);
    props.toggleNavSmall();
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Box>
      <NavLink to={to} onClick={icon === 'exclamation-circle' ? openModal : undefined}>
        <Flex className="navitem" direction="row" alignItems="center" h={['85px', '85px', '65px']}>
          <Flex
            mx="57px"
            align="center"
            justify="center"
            direction="column"
            w="30px"
            color={active ? '#5c4279' : 'grey.500'}
            fontWeight={active ? 'bold' : 'normal'}
          >
            <FAIcon className={props.flip ? 'icon fa-rotate-180' : 'icon'} fontSize={24} as="div" icon={icon} />
            <Text className="title" whiteSpace="nowrap" fontSize={16} display={['flex', 'flex', 'none']}>
              {title}
            </Text>
          </Flex>
          <Flex w={props.largeNav ? '194px' : '0px'} overflow="hidden" transition="width 1s">
            <Text
              className="title"
              whiteSpace="nowrap"
              fontSize={16}
              display={['none', 'none', 'block']}
              color={active ? '#5c4279' : 'gray'}
              fontWeight={active ? 'bold' : 'normal'}
            >
              {title}
            </Text>
          </Flex>
          {active && <Box bg="#5c4279" w="7px" borderRadius="2px" h="66px" />}
        </Flex>
      </NavLink>

      <ModalAlert isOpen={open} onClose={closeModal} source="alert" />
    </Box>
  );
};

const LeftNav = (props: PropsFromRedux): JSX.Element => {
  const { user, linkedUser } = props;
  const { t } = useTranslation('common');
  const [largeNav, setLargeNav] = useState(false);
  const [userType, setUserType] = useState<string>('');

  useEffect(() => {
    const type =
      (user?.role as UserRole) === UserRole.ADMIN
        ? 'admin'
        : linkedUser?.pir?.id === user?.id
        ? 'pir'
        : linkedUser?.pir?.id !== user?.id || (user?.role as UserRole) === UserRole.CP
        ? 'cp'
        : '';
    setUserType(type);
    return () => {
      setUserType('');
    };
  }, [props.linkedUser]);

  const toggleNavLarge = () => {
    if (window.innerWidth >= 1087) {
      setLargeNav(true);
    }
  };

  const toggleNavSmall = () => {
    if (window.innerWidth >= 1087) {
      setLargeNav(false);
    }
  };

  const routesAvailableToUser = () => {
    return USER_ROUTES.get(userType) ?? [];
  };

  return (
    <>
      <Flex
        display={['none', 'flex', 'flex']}
        flexDir="column"
        justifyContent="space-between"
        backgroundColor="white"
        onMouseEnter={toggleNavLarge}
        onMouseLeave={toggleNavSmall}
      >
        <Flex flexDir="column" align="flex-start" mt="30px" ml="57px">
          <Heading as="h1" fontSize={45} fontWeight={300} color="purple3.600" w="50px">
            <Link to="/">{t('header.wordmark')}</Link>
          </Heading>
        </Flex>
        <Flex flexDir="column">
          {routesAvailableToUser().map((routeConfig) => (
            <LeftNavIcon
              key={routeConfig.key}
              icon={routeConfig.icon}
              title={t(routeConfig.titleProp)}
              to={routeConfig.route}
              largeNav={largeNav}
              toggleNavSmall={toggleNavSmall}
            />
          ))}
        </Flex>
        <Flex flexDir="column" mb="30px">
          {/*<LeftNavIcon icon="cog" title={t('header.setting')} to="/setting" largeNav={largeNav} />*/}
          <LeftNavIcon
            icon="sign-out-alt"
            title={t('header.logout')}
            to="/logout"
            flip={true}
            largeNav={largeNav}
            toggleNavSmall={toggleNavSmall}
          />
        </Flex>
      </Flex>
      <Flex
        display={['none', 'flex', 'flex']}
        h="100vh"
        w={17}
        borderBottomLeftRadius={15}
        borderTopLeftRadius={15}
        backgroundColor={user?.role === 'USER' ? 'white' : '#F1F1F0'}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { linkedUsers, user } = state;
  return {
    linkedUser: linkedUsers.selectedLinkedUser,
    user: user.user,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LeftNav);
