import { GotUserAction, UserActionTypes } from '../user/actions';
import { LinkedUsersActionType, SelectLinkedUserAction } from '../linked-users/actions';
import {
  ConsumeInitialLinkAction,
  DynamicLinksActionTypes,
  EnabledGraphsLoadedAction,
  InitialLinkLoadedAction,
  RemoteConfigActionTypes,
} from './actions';

interface InitState {
  userLoading: boolean;
  linkedAccountsLoading: boolean;
  featureFlags: {
    enabledGraphs: {
      loading: boolean;
      participation: boolean;
      incidents: boolean;
      sleep: boolean;
      stress: boolean;
      craving: boolean;
      emotion: boolean;
      watchuse: boolean;
    };
  };
  initialLinkUrl?: string;
  initialLinkLoading: boolean;
}

const defaultState: InitState = {
  userLoading: true,
  linkedAccountsLoading: true,
  featureFlags: {
    enabledGraphs: {
      loading: true,
      participation: true,
      incidents: true,
      sleep: true,
      stress: true,
      craving: false,
      emotion: true,
      watchuse: true,
    },
  },
  initialLinkLoading: true,
};

const initReducer = (state: InitState = defaultState, action: SelectLinkedUserAction | GotUserAction | EnabledGraphsLoadedAction | InitialLinkLoadedAction | ConsumeInitialLinkAction): InitState => {
  switch (action.type) {
    case UserActionTypes.GOT_USER:
      return {
        ...state,
        userLoading: false,
      };

    case LinkedUsersActionType.SELECT_LU:
      return {
        ...state,
        linkedAccountsLoading: false,
      };

    case RemoteConfigActionTypes.ENABLED_GRAPHS_LOADED: {
      return {
        ...state,
        featureFlags: {
          enabledGraphs: {
            loading: false,
            craving: action.enabledGraphs.includes('craving'),
            emotion: action.enabledGraphs.includes('emotion'),
            incidents: action.enabledGraphs.includes('incidents'),
            participation: action.enabledGraphs.includes('participation'),
            sleep: action.enabledGraphs.includes('sleep'),
            stress: action.enabledGraphs.includes('stress'),
            watchuse: action.enabledGraphs.includes('watchuse'),
          },
        },
      };
    }

    case DynamicLinksActionTypes.INITIAL_LINK_LOADED: {
      return {
        ...state,
        initialLinkLoading: false,
        initialLinkUrl: action.initialLinkUrl,
      };
    }

    case DynamicLinksActionTypes.INITIAL_LINK_CONSUMED: {
      return {
        ...state,
        initialLinkUrl: undefined,
      };
    }

    default:
      return state;
  }
};

export default initReducer;
