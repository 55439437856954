import { Action } from 'redux';
import AppThunk from '../../redux/interfaces/AppThunk';
import Firestore from '../firestore/Firestore';
import IInteraction from './interfaces/IInteraction';

export enum InteractionActionTypes {
  SUBMIT_INTERACTION = 'SUBMIT_INTERACTION',
  CREATED_INTERACTION = 'CREATED_INTERACTION',
  CREATING_INTERACTION = 'CREATING_INTERACTION',
}

export type InteractionActions = SubmitInteractionAction | CreatingInteractionAction | CreatedInteractionAction;

export interface SubmitInteractionAction extends Action<InteractionActionTypes.SUBMIT_INTERACTION> {
  interaction: null | IInteraction;
}

export const submitInteraction = (interaction: null | IInteraction): SubmitInteractionAction => ({
  type: InteractionActionTypes.SUBMIT_INTERACTION,
  interaction,
});

export type CreatingInteractionAction = Action<InteractionActionTypes.CREATING_INTERACTION>;

export const creatingInteraction = (): CreatingInteractionAction => ({
  type: InteractionActionTypes.CREATING_INTERACTION,
});

export interface CreatedInteractionAction extends Action<InteractionActionTypes.CREATED_INTERACTION> {
  interaction: IInteraction;
}

export const createdInteraction = (interaction: IInteraction): CreatedInteractionAction => ({
  type: InteractionActionTypes.CREATED_INTERACTION,
  interaction,
});

export const createInteraction = (
  rawInteraction: IInteraction,
): AppThunk<Promise<CreatedInteractionAction>, InteractionActions> => {
  return async (dispatch) => {
    dispatch(creatingInteraction());

    const interaction = rawInteraction;
    const interactionRef = Firestore.collection('interactions').doc();
    await interactionRef.set(interaction);
    return dispatch(
      createdInteraction({
        ...interaction,
      }),
    );
  };
};
