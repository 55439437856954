import React from 'react';

import { Link } from '@chakra-ui/react';
import { useDevice } from 'src/DeviceContext';

interface Props {
  href: string;
  linkText: string;
  propOnClick?: any;
}

const DisplayWebLink = ({ href, linkText, propOnClick }: Props): JSX.Element => {
  const { isCordova } = useDevice();
  if (isCordova) {
    return (
      <Link
        textDecoration="underline"
        isExternal={true}
        mb={3}
        onClick={() => {
          if (propOnClick) {
            propOnClick();
          }
          window.cordova?.InAppBrowser.open(href, '_system', 'location=no');
        }}
      >
        {linkText}
      </Link>
    );
  } else {
    return (
      <Link
        textDecoration="underline"
        href={href}
        onClick={() => {
          if (propOnClick) {
            propOnClick();
          }
        }}
        isExternal={true}
        mb={3}
      >
        {linkText}
      </Link>
    );
  }
};

export default DisplayWebLink;
