import { Action } from 'redux';
import firebase from 'firebase/compat/app';
import AppThunk from '../../redux/interfaces/AppThunk';
import Firestore, { FirestoreUtils } from '../firestore/Firestore';
import IDeviceData from '../deviceData/interfaces/IDeviceData';
import { formatDeviceDataFromFirestore } from './utils';

export enum DeviceDataActionTypes {
  SET_DEVICE_DATA = 'SET_DEVICE_DATA',
  GETTING_DEVICE_DATA = 'GETTING_DEVICE_DATA',
  GOT_DEVICE_DATA = 'GOT_DEVICE_DATA',
  SET_DEVICE_DATA_UNSUB = 'SET_DEVICE_DATA_UNSUB',
}

export type DeviceDataActions =
  | SetDeviceDataAction
  | GettingDeviceDataAction
  | GotDeviceDataAction
  | SetDeviceDataUnsubAction;

export interface SetDeviceDataAction extends Action<DeviceDataActionTypes.SET_DEVICE_DATA> {
  deviceData: null | IDeviceData[];
}

export const setDeviceData = (deviceData: null | IDeviceData[]): SetDeviceDataAction => ({
  type: DeviceDataActionTypes.SET_DEVICE_DATA,
  deviceData,
});

export type GettingDeviceDataAction = Action<DeviceDataActionTypes.GETTING_DEVICE_DATA>;

export const gettingDeviceData = (): GettingDeviceDataAction => ({ type: DeviceDataActionTypes.GETTING_DEVICE_DATA });

export interface GotDeviceDataAction extends Action<DeviceDataActionTypes.GOT_DEVICE_DATA> {
  deviceData: IDeviceData[];
}

export const gotDeviceData = (deviceData: IDeviceData[]): GotDeviceDataAction => {
  return {
    type: DeviceDataActionTypes.GOT_DEVICE_DATA,
    deviceData,
  };
};

export const getDeviceData = (pirId: string): AppThunk<Promise<GotDeviceDataAction>, DeviceDataActions> => {
  return async (dispatch) => {
    dispatch(gettingDeviceData());

    return new Promise<IDeviceData[]>((resolve, reject) => {
      const unsubFn = Firestore.collection('deviceDataHeartRate')
        .where('pir', '==', FirestoreUtils.getDocRef('users', pirId))
        .onSnapshot(
          (snapshot) => {
            const deviceData = snapshot.docs.map((snapshot) => formatDeviceDataFromFirestore(snapshot));
            dispatch(setDeviceData(deviceData));
            resolve(deviceData);
          },
          (error) => {
            reject(error);
          },
        );
      dispatch(setDeviceDataUnsub(unsubFn));
    }).then((deviceData) => {
      return dispatch(gotDeviceData(deviceData));
    });
  };
};

export interface SetDeviceDataUnsubAction extends Action<DeviceDataActionTypes.SET_DEVICE_DATA_UNSUB> {
  unsubFn: null | firebase.Unsubscribe;
}

export const setDeviceDataUnsub = (unsubFn: null | firebase.Unsubscribe): SetDeviceDataUnsubAction => ({
  type: DeviceDataActionTypes.SET_DEVICE_DATA_UNSUB,
  unsubFn,
});

export const stopDeviceDataListener = (): AppThunk<SetDeviceDataUnsubAction, SetDeviceDataUnsubAction> => {
  return (dispatch, getState) => {
    const unsubFn = getState().deviceData.unsubFn;
    if (unsubFn) {
      unsubFn();
    }
    return dispatch(setDeviceDataUnsub(null));
  };
};
