import { ensureInitialized, fetchAndActivate, getRemoteConfig, getString, RemoteConfig } from 'firebase/remote-config';
import FirebaseUtils from '../firestore/Firestore';

const defaults = {
  enabled_graphs: 'participation,incidents,sleep,stress,emotion,watchuse',
};

class RemoteConfigWrapper {
  private mobileRemoteConfig!: CordovaPluginFirebase.FirebaseConfig;
  private webRemoteConfig!: RemoteConfig;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static forMobile(remoteConfig: CordovaPluginFirebase.FirebaseConfig): RemoteConfigWrapper {
    const wrapper = new RemoteConfigWrapper();
    wrapper.mobileRemoteConfig = remoteConfig;
    // defaults are loaded as part of the Cordova plugin initialization

    return wrapper;
  }

  static forWeb(remoteConfig: RemoteConfig): RemoteConfigWrapper {
    const wrapper = new RemoteConfigWrapper();
    wrapper.webRemoteConfig = remoteConfig;
    wrapper.webRemoteConfig.defaultConfig = defaults;

    return wrapper;
  }

  async fetchAndActivate(): Promise<void> {
    if (window.cordova) {
      // fetchAndActivate is broken in the plugin and only calls activate
      await this.mobileRemoteConfig.fetch();
      await this.mobileRemoteConfig.activate();
    } else {
      const activated = await fetchAndActivate(this.webRemoteConfig);

      if (!activated) {
        console.log('Remote configs already activated');
      }

      await ensureInitialized(this.webRemoteConfig);
    }
  }

  async getString(key: string): Promise<string> {
    if (window.cordova) {
      return this.mobileRemoteConfig.getString(key);
    } else {
      return getString(this.webRemoteConfig, key);
    }
  }
}

export const getRemoteConfigWrapper = (isMobileDeviceAndCordova: boolean): RemoteConfigWrapper => {
  if (isMobileDeviceAndCordova) {
    return RemoteConfigWrapper.forMobile(window.cordova.plugins.firebase.config);
  }

  return RemoteConfigWrapper.forWeb(getRemoteConfig(FirebaseUtils.app()));
};
