import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Input, Text, Image } from '@chakra-ui/react';
import FAIcon from './FAIcon';
import { RegisterOptions, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { Center } from '@chakra-ui/layout';
import UploadFile from './UploadFileComponent';
import { uploadFile } from '../modules/firebase/storage';
import { MediaFormValues } from './../utils/utils';

interface Props {
  fileName: keyof MediaFormValues;
  urlName: keyof MediaFormValues;
  register: UseFormRegister<MediaFormValues>;
  changeUploadState: (data: boolean) => void;
  multipleFiles: boolean;
  accept: string;
  validation: RegisterOptions;
  attachmentUrl?: string | undefined;
  interactionCallback?(): void;
  setValue: UseFormSetValue<MediaFormValues>;
  setUploadChosen?: React.Dispatch<React.SetStateAction<boolean>>;
  uploadChosen?: boolean;
  trigger?: UseFormTrigger<MediaFormValues>;
}

/**
 * @param changeUploadState Function to access the state of upload, parameter is set to true while uploading, false when upload is finished
 * @param multipleFiles A boolean as to whether or not to allow multiple files to be selected by the user
 * @param accept A string of valid file formats to accept (e.g. "image/png, image/gif")
 *
 */
const AddMediaFileMobile = ({
  fileName,
  urlName,
  attachmentUrl,
  register,
  changeUploadState,
  multipleFiles,
  accept,
  validation,
  interactionCallback,
  uploadChosen,
  setUploadChosen,
  setValue,
  trigger,
}: Props): React.ReactElement => {
  const [shouldHideButton, setShouldHideButton] = useState(true);
  const [shouldShowFile, setShouldShowFile] = useState(true);
  const [shouldShowConfirm, setShouldShowConfirm] = useState(true);
  const [filePath, setFilePath] = useState(attachmentUrl);
  const [shouldUploaderSpin, setShouldUploaderSpin] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (uploadChosen === false) {
      setFilePath('');
      setShouldHideButton(true);
      setShouldShowFile(true);
      setShouldUploaderSpin(false);
      setShouldShowConfirm(true);
    }
  }, [uploadChosen]);

  const handleFile = (e: FileList | null) => {
    if (e != null) {
      const fileReceived = e[0];
      const url = URL.createObjectURL(fileReceived);
      setFilePath(url);
      sendToFirebase(fileReceived);
    } else {
      alert('ERROR: File is null');
    }
  };

  const handleHideButton = () => {
    setShouldHideButton(false);
    setShouldShowFile(false);
  };

  const sendToFirebase = useCallback(async (acceptedFile: File | null): Promise<void> => {
    if (acceptedFile == null) return;

    setShouldUploaderSpin(true);
    changeUploadState(true);
    if (interactionCallback) {
      // function to add interaction log for module that includes this component
      interactionCallback();
    }
    try {
      const downloadURL = await uploadFile(acceptedFile);
      setFilePath(downloadURL);
      setShouldShowConfirm(false);
      setShouldShowFile(true);
      setValue(urlName, downloadURL);
      setValue(fileName, acceptedFile.name);
      if (trigger) {
        trigger([urlName, fileName]);
      }
    } catch (err: any) {
      alert(err.message);
    } finally {
      setShouldUploaderSpin(false);
      changeUploadState(false);
      if (setUploadChosen !== undefined) {
        setUploadChosen(true);
      }
    }
  }, []);

  const uploadField = () => {
    return (
      <UploadFile
        onUpload={handleFile}
        accept={accept}
        displaySpinner={shouldUploaderSpin}
        multipleFiles={multipleFiles}
      />
    );
  };
  const mobileUploadOptions = () => {
    return <Flex align="center">{uploadField()}</Flex>;
  };
  return (
    <>
      {/* <Text>Uploading from Mobile</Text> */}
      {shouldHideButton && (
        <Button onClick={handleHideButton} mt={5} variant="link" fontSize={14}>
          {t('addMediaFile.addMediaButton')}
        </Button>
      )}
      <Input {...register(urlName, validation)} type="hidden" isReadOnly={true} />
      <Input {...register(fileName, validation)} type="hidden" isReadOnly={true} />
      {!shouldShowFile && (
        <>
          <Box mt={15} mb={2}>
            <Text fontSize={14}>{t('addMediaFile.uploadHeader')}</Text>
          </Box>
          <Center>{mobileUploadOptions()}</Center>
        </>
      )}
      {!shouldShowConfirm && (
        <>
          <Box mt={15}>
            <Text fontSize={14}>{t('addMediaFile.postUploadHeader')}</Text>
          </Box>
          <Flex
            flex-wrap="wrap"
            mt={25}
            pb={10}
            pl={10}
            h={200}
            border={4}
            borderStyle="solid"
            borderRadius="md"
            borderColor="gray.200"
          >
            <Box w="100" alignSelf="center" mt={10}>
              <Flex direction="column">
                <Box padding={5}>
                  <Image
                    alignSelf="center"
                    src={filePath}
                    alt="Downloaded Image"
                    height={150}
                    width={150}
                    fallbackSrc="https://via.placeholder.com/150"
                  />
                  <Text alignSelf="center" fontSize={18}>
                    {t('addMediaFile.uploadConfirm')}
                    <FAIcon icon="check" ml={3} fontSize={18} />
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </>
      )}
    </>
  );
};

export default AddMediaFileMobile;
