import { Action } from 'redux';

export enum HomeAction {
  LOAD_HOME = 'LOAD_HOME',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LoadHomeAction extends Action<HomeAction.LOAD_HOME> {}

export const loadHome = (): LoadHomeAction => {
  return {
    type: HomeAction.LOAD_HOME,
  };
};
