import firebase from 'firebase/compat/app';

export enum InvitedAsRole {
  USER = 'USER',
  CP = 'CARE_PROVIDER',
}

export interface UserInvitation {
  id: string;
  name: string;
  email: string;
  accepted: boolean;
  invitedBy: string;
  invitedByUser: string;
  createdDate: Date;
  acceptedDate?: Date;
  invitedAsRole?: InvitedAsRole;
  verificationToken?: string;
  deletionStatus?: string;
  emailBeforeDeleted?: string;
}

export const formatInvitationFromFirestore = (snapshot: firebase.firestore.QueryDocumentSnapshot): UserInvitation => {
  return {
    id: snapshot.id,
    name: snapshot.get('name'),
    email: snapshot.get('email'),
    accepted: snapshot.get('accepted'),
    invitedBy: snapshot.get('invitedBy'),
    invitedByUser: snapshot.get('invitedByUserId'),
    createdDate: snapshot.get('createdDate').toDate(),
    acceptedDate: snapshot.get('acceptedDate')?.toDate(),
    invitedAsRole: snapshot.get('invitedAsRole'),
    deletionStatus: snapshot.get('deletionStatus'),
    emailBeforeDeleted: snapshot.get('emailBeforeDeleted')
  };
};
