import React, { useState } from 'react';
import { Image } from '@chakra-ui/react';
import './../../App.css';

interface Props {
  imageUrl: string;
  imageName: string;
  maxWidth: number;
  maxHeight: number;
}

const InlineImage = ({ imageUrl, imageName, maxWidth, maxHeight }: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleImageLoaded = () => {
    setIsVisible(true);
  };

  return (
    <Image
      src={imageUrl}
      onLoad={handleImageLoaded}
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      objectFit="contain"
      alt={imageName}
    />
  );
};

export default InlineImage;
