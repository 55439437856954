import { useQuery, UseQueryResult } from 'react-query';
import { getAlerts } from '../../db/alerts';
import { PossibleLinkedUser } from '../linked-users/interfaces/ILinkedUser';
import IAlert from './interfaces/IAlert';

export const KEY_NAME = 'alerts';

export const useQueryAlerts = (provider: PossibleLinkedUser): UseQueryResult<IAlert[], unknown> => {
  return useQuery([KEY_NAME, provider?.pir?.id], async () => getAlerts(provider), { staleTime: Infinity });
};
