import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Text,
  Box,
  Flex,
  VStack,
  Link as ChakraLink,
} from '@chakra-ui/react';
import FAIcon from '../FAIcon';
import ILinkedUser from '../../modules/linked-users/interfaces/ILinkedUser';

import IAppointment, { AppointmentColor, AppointmentType } from 'src/modules/appointments/interfaces/IAppointment';
import AppointmentItemList from 'src/modules/appointments/appointmentComponents/AppointmentItemList';
import { AppointmentsContext } from 'src/modules/interior/Index';
import { useDevice } from 'src/DeviceContext';
import { useModal } from 'src/ModalContext';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
  providers: ILinkedUser[];
  isPir: boolean;
  confirmedUpcomingAppts: IAppointment[];
  confirmedPastAppts: IAppointment[];
  apptsToConfirm: IAppointment[];
  pendingAppts: IAppointment[];
  setSelectedAppointment: React.Dispatch<React.SetStateAction<IAppointment | null>>;
  selectedAppointment: IAppointment | null;
  appointmentType: AppointmentType | null;
  setAppointmentType: React.Dispatch<React.SetStateAction<AppointmentType | null>>;
  openAddAppointment(): void;
  limit: number;
}

type Props = SuppliedProps;

const appointmentColorMap: { [key in AppointmentType]: AppointmentColor } = {
  [AppointmentType.CONFIRM]: AppointmentColor.CONFIRM,
  [AppointmentType.PAST]: AppointmentColor.PAST,
  [AppointmentType.UPCOMING]: AppointmentColor.UPCOMING,
  [AppointmentType.PENDING]: AppointmentColor.PENDING,
};

const AllAppointmentsModal = ({
  isOpen,
  onClose,
  isPir,
  confirmedUpcomingAppts,
  confirmedPastAppts,
  apptsToConfirm,
  pendingAppts,
  setSelectedAppointment,
  selectedAppointment,
  appointmentType,
  setAppointmentType,
  openAddAppointment,
  limit,
}: Props): React.ReactElement => {
  const { t } = useTranslation('appointments');
  const [appointments, setAppointments] = useState<IAppointment[] | null>(null);
  const [upcomingIsExpanded, setUpcomingIsExpanded] = useState(false);
  const [pastIsExpanded, setPastIsExpanded] = useState(false);
  const [toConfirmIsExpanded, setToConfirmIsExpanded] = useState(false);
  const [pendingIsExpanded, setPendingIsExpanded] = useState(false);
  const { isMobileDevice, isPhone } = useDevice();
  const { setRefresh } = useContext(AppointmentsContext);
  const { notificationType, setNotificationType } = useModal();

  useEffect(() => {
    if (appointmentType === AppointmentType.CONFIRM) setAppointments(apptsToConfirm);
    else if (appointmentType === AppointmentType.UPCOMING) setAppointments(confirmedUpcomingAppts);
    else if (appointmentType === AppointmentType.PAST) setAppointments(confirmedPastAppts);
    else if (appointmentType === AppointmentType.PENDING) setAppointments(pendingAppts);
    else setAppointments(null);
  }, [appointmentType, apptsToConfirm.length]);

  useEffect(() => {
    if (isOpen) setRefresh(true);
  }, [isOpen]);

  const toggleExpandAppointments = (type: AppointmentType) => {
    if (type === AppointmentType.UPCOMING) setUpcomingIsExpanded(!upcomingIsExpanded);
    if (type === AppointmentType.PAST) setPastIsExpanded(!pastIsExpanded);
    if (type === AppointmentType.PENDING) setPendingIsExpanded(!pendingIsExpanded);
    if (type === AppointmentType.CONFIRM) setToConfirmIsExpanded(!toConfirmIsExpanded);
  };

  const handleClose = () => {
    if (appointmentType) setAppointmentType(null);
    if (appointments) setAppointments(null);
    if (notificationType === 'appointments') setNotificationType(null);
    onClose();
  };

  const limitAppointments = (appointments: IAppointment[], limit: number) => {
    return appointments.slice(0, limit);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent
          maxWidth={isMobileDevice ? ['100%', '100%', '100%', '100%', '100%'] : ['100%', '100%', '100%', '85%', '80%']}
          zIndex={100}
          p={0}
        >
          <Flex
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            paddingLeft={'16px'}
            paddingRight={'16px'}
          >
            {appointmentType !== null && appointments !== null && (
              <ChakraLink
                onClick={() => {
                  setAppointmentType(null);
                }}
                display="flex"
                alignItems="center"
              >
                <FAIcon icon="angle-left" color="#4F4F4F" fontSize="12px" />
              </ChakraLink>
            )}
            {(!appointmentType || !appointments) && <Box flexShrink={0} />}
            <ModalHeader
              fontWeight="bold"
              color="#4F4F4F"
              fontSize="18px"
              textAlign="center"
              px={[1, 1, 6]}
              letterSpacing="0.02em"
              flex="1"
              minW={0}
            >
              {appointmentType === null || appointments === null
                ? t('allAppointmentsModal.header')
                : t(`allAppointmentsModal.${appointmentType}Header`)}
            </ModalHeader>
            <Box flexShrink={0} />
          </Flex>

          <ModalCloseButton />
          <ModalBody p={0} paddingLeft={'16px'} paddingRight={'16px'}>
            {appointmentType === null || appointments === null ? (
              <VStack backgroundColor="white" mb={5} maxWidth={1012}>
                {/* upcoming */}
                <Flex width="100%" justifyContent="space-between" fontSize="16">
                  <Text fontWeight="semibold" fontSize="14" color={AppointmentColor.UPCOMING}>
                    {t('allAppointmentsModal.upcomingHeader')}
                  </Text>
                  {confirmedUpcomingAppts.length > limit ? (
                    <Text color="#8F8F8F">
                      <ChakraLink
                        onClick={
                          isPhone
                            ? () => setAppointmentType(AppointmentType.UPCOMING)
                            : () => toggleExpandAppointments(AppointmentType.UPCOMING)
                        }
                      >
                        {isPhone
                          ? t('allAppointmentsModal.seeAll')
                          : upcomingIsExpanded
                          ? t('allAppointmentsModal.collapse')
                          : t('allAppointmentsModal.expand')}
                      </ChakraLink>
                    </Text>
                  ) : null}
                </Flex>

                <Flex width="100%" padding="0px" justifyContent="space-between" flexDirection={['column', 'row']}>
                  <AppointmentItemList
                    appointments={
                      upcomingIsExpanded ? confirmedUpcomingAppts : limitAppointments(confirmedUpcomingAppts, limit)
                    }
                    emptyInstruct={t('appointmentModule.noAppointment', { appointmentType: AppointmentType.UPCOMING })}
                    setSelectedAppointment={setSelectedAppointment}
                    selectedAppointment={selectedAppointment}
                    appointmentColor={AppointmentColor.UPCOMING}
                    isPir={isPir}
                  />
                </Flex>
                {/* to confirm */}
                <Flex width="100%" justifyContent="space-between" fontSize="16">
                  <Text fontWeight="semibold" fontSize="14" color={AppointmentColor.CONFIRM}>
                    {t('allAppointmentsModal.confirmHeader')} {`(${apptsToConfirm.length})`}
                  </Text>
                  {apptsToConfirm.length > limit ? (
                    <Text color="#8F8F8F">
                      <ChakraLink
                        onClick={
                          isPhone
                            ? () => setAppointmentType(AppointmentType.CONFIRM)
                            : () => toggleExpandAppointments(AppointmentType.CONFIRM)
                        }
                      >
                        {isPhone
                          ? t('allAppointmentsModal.seeAll')
                          : toConfirmIsExpanded
                          ? t('allAppointmentsModal.collapse')
                          : t('allAppointmentsModal.expand')}
                      </ChakraLink>
                    </Text>
                  ) : null}
                </Flex>

                <Flex width="100%" padding="0px" justifyContent="space-between" flexDirection={['column', 'row']}>
                  <AppointmentItemList
                    appointments={toConfirmIsExpanded ? apptsToConfirm : limitAppointments(apptsToConfirm, limit)}
                    emptyInstruct={t('appointmentModule.noAppointmentsToConfirm')}
                    setSelectedAppointment={setSelectedAppointment}
                    selectedAppointment={selectedAppointment}
                    appointmentColor={AppointmentColor.CONFIRM}
                    isPir={isPir}
                  />
                </Flex>
                {/* pending */}
                <Flex width="100%" justifyContent="space-between" fontSize="16">
                  <Text fontWeight="semibold" fontSize="14" color={AppointmentColor.PENDING}>
                    {t('allAppointmentsModal.pendingHeader')} {`(${pendingAppts.length})`}
                  </Text>
                  {pendingAppts.length > limit ? (
                    <Text color="#8F8F8F">
                      <ChakraLink
                        onClick={
                          isPhone
                            ? () => setAppointmentType(AppointmentType.PENDING)
                            : () => toggleExpandAppointments(AppointmentType.PENDING)
                        }
                      >
                        {isPhone
                          ? t('allAppointmentsModal.seeAll')
                          : pendingIsExpanded
                          ? t('allAppointmentsModal.collapse')
                          : t('allAppointmentsModal.expand')}
                      </ChakraLink>
                    </Text>
                  ) : null}
                </Flex>

                <Flex width="100%" padding="0px" justifyContent="space-between" flexDirection={['column', 'row']}>
                  <AppointmentItemList
                    appointments={pendingIsExpanded ? pendingAppts : limitAppointments(pendingAppts, limit)}
                    emptyInstruct={t('appointmentModule.noAppointment', { appointmentType: AppointmentType.PENDING })}
                    setSelectedAppointment={setSelectedAppointment}
                    selectedAppointment={selectedAppointment}
                    appointmentColor={AppointmentColor.PENDING}
                    isPir={isPir}
                  />
                </Flex>
                {/* past */}
                <Flex width="100%" justifyContent="space-between" fontSize="16">
                  <Text fontWeight="semibold" fontSize="14" color={AppointmentColor.PAST}>
                    {t('allAppointmentsModal.pastHeader')}
                  </Text>
                  {confirmedPastAppts.length > limit ? (
                    <Text color="#8F8F8F">
                      <ChakraLink
                        onClick={() => {
                          setAppointmentType(AppointmentType.PAST);
                        }}
                      >
                        {isPhone
                          ? t('allAppointmentsModal.seeAll')
                          : pastIsExpanded
                          ? t('allAppointmentsModal.collapse')
                          : t('allAppointmentsModal.expand')}
                      </ChakraLink>
                    </Text>
                  ) : null}
                </Flex>

                <Flex width="100%" padding="0px" justifyContent="space-between" flexDirection={['column', 'row']}>
                  <AppointmentItemList
                    appointments={pastIsExpanded ? confirmedPastAppts : limitAppointments(confirmedPastAppts, limit)}
                    emptyInstruct={t('appointmentModule.noAppointment', { appointmentType: AppointmentType.PAST })}
                    setSelectedAppointment={setSelectedAppointment}
                    selectedAppointment={selectedAppointment}
                    appointmentColor={AppointmentColor.PAST}
                    isPir={isPir}
                  />
                </Flex>
              </VStack>
            ) : (
              <Flex width="100%" padding="0px" justifyContent="space-between" flexDirection={['column', 'row']}>
                <AppointmentItemList
                  appointments={appointments}
                  emptyInstruct={t('appointmentModule.noAppointment')}
                  setSelectedAppointment={setSelectedAppointment}
                  selectedAppointment={selectedAppointment}
                  appointmentColor={appointmentColorMap[appointmentType]}
                  isPir={isPir}
                />
              </Flex>
            )}
            <Flex alignItems="center" justifyContent="center" width="100%">
              <Flex
                height="39px"
                backgroundColor="#2680D0"
                alignItems="center"
                justifyContent="center"
                borderRadius="10px"
                onClick={openAddAppointment}
                mb={5}
                padding="10px 28px 10px 28px"
                display="inline-flex"
              >
                <Text color="white" fontWeight={700} fontSize="16px">
                  {t('appointmentModule.requestAppointment')}
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AllAppointmentsModal;
