import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux/store';
import { Flex, Heading, Text, Button, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { onboarded } from './actions';

const GetStarted = (props: PropsFromRedux) => {
  const history = useHistory();
  const { t } = useTranslation('onboarding');

  return (
    <SimpleGrid spacing={3}>
      <Flex
        justifyContent="center"
        direction="column"
        pt={[1, 1, 1, 3]}
        pb={[2, 3, 3, 5]}
        mt={[60, 60, 100, 100]}
        align="center"
      >
        <Heading as="h1" fontSize={[36, 36, 48, 48]} fontWeight={300} color="purple3.600">
          {t('getStartedTitle')}
        </Heading>
      </Flex>

      <Flex justifyContent="center" direction="column" align="center" height={250} />

      <Flex direction="column" justifyContent="center" align="center" ml={[6, 12, 24, 48]} mr={[6, 12, 24, 48]}>
        <Heading as="h2" fontSize={[24, 24, 36, 36]} fontWeight={300} color="purple3.600" textAlign="center">
          {t('getStartedSubTitle')}
        </Heading>
      </Flex>

      <Flex direction="column" justifyContent="center" align="center" ml={[6, 12, 24, 48]} mr={[6, 12, 24, 48]}>
        <Text color="purple3.600" fontSize={[16]} textAlign="center">
          {t('caption')}
        </Text>
      </Flex>

      <Flex direction="column" justifyContent="center" align="center" ml={[6, 12, 24, 48]} mr={[6, 12, 24, 48]}>
        <Text color="purple3.600" fontSize={[16]} textAlign="center">
          {t('caption2')}
        </Text>
      </Flex>

      <Flex direction="column" justifyContent="center" align="center" mb={[6, 12, 24, 48]}>
        <Button
          fontSize={[14]}
          bg="green.700"
          color="white"
          alignSelf="center"
          onClick={() => {
            // Tell the app we're done onboarding so it takes us into the app
            props.onboarded();
            history.push('/');
          }}
        >
          {t('getStarted')}
        </Button>
      </Flex>
    </SimpleGrid>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    hasOnboarded: state.onboarding.hasOnboarded,
  };
};

const mapDispatchToProps = {
  onboarded,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GetStarted);
