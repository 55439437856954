import firebase from 'firebase/compat/app';
import Firestore, { FirestoreUtils } from '../modules/firestore/Firestore';
import { getFirebaseUser } from '../modules/firebase/auth';

import IUser, { UserRole } from '../modules/user/interfaces/IUser';
import { PossibleLinkedUser } from 'src/modules/linked-users/interfaces/ILinkedUser';

/**
 * Get the user data for the current logged in user
 * @returns IUser of current logged in User
 */
export const getLoggedInUser = async (): Promise<IUser> => {
  const currentUser = getFirebaseUser();
  if (currentUser == null) {
    throw new Error('No user logged in');
  }
  return getUser(currentUser.uid);
};

/**
 * Get user data for a particular user
 * @param id the Firestore ID for a particular user
 * @returns IUser matching id
 */
export const getUser = async (id: string): Promise<IUser> => {
  const usersCollection = Firestore.collection('users');
  const snapshot = await usersCollection.doc(id).get();

  if (!snapshot.exists) {
    throw new Error('No user found with requested id');
  }

  const email = snapshot.get('email');
  const showColor = snapshot.get('showColor');
  const showVitals = snapshot.get('showVitals');
  const role = snapshot.get('role');
  let deletedDatetime = snapshot.get('deletedDatetime');
  const deletionStatus = snapshot.get('deletionStatus');

  if (deletedDatetime) {
    deletedDatetime = deletedDatetime.toDate();
  }

  if (typeof email === 'undefined') {
    throw new Error('User missing email');
  }

  return {
    id: snapshot.id,
    name: snapshot.get('name'),
    email,
    cravingsPredictionEnabled: snapshot.get('cravingsPredictionEnabled') ?? false,
    preferences: {
      hiddenGraphs: snapshot.get('preferences.hiddenGraphs') ?? [],
    },
    doesWantEmail: snapshot.get('doesWantEmail'),
    phone: snapshot.get('phone'),
    doesWantSms: snapshot.get('doesWantSms'),
    fcmToken: snapshot.get('fcmToken'),
    fcmTokens: snapshot.get('fcmTokens'),
    doesWantPush: snapshot.get('doesWantPush'),
    image: snapshot.get('image'),
    showColor: typeof showColor === 'undefined' ? true : showColor,
    showVitals: typeof showVitals === 'undefined' ? true : showVitals,
    role: typeof role === 'undefined' ? UserRole.USER : role,
    deletedDatetime,
    deletionStatus,
  };
};

export const updateLinkedUsersWithDeletionStatus = async (
  deleting: boolean,
  linkedUsers: PossibleLinkedUser[],
  isPir: boolean,
): Promise<void> => {
  const batch = firebase.firestore().batch();
  linkedUsers.forEach((linkedUser) => {
    const linkedUserRef = firebase.firestore().collection('linkedUsers').doc(linkedUser.id);
    const update =
      deleting && isPir
        ? { deletedPir: true }
        : deleting
        ? { deletedCp: true }
        : isPir
        ? { deletedPir: FirestoreUtils.deleteField() }
        : { deletedCp: FirestoreUtils.deleteField() };

    batch.update(linkedUserRef, update);
  });

  await batch.commit();
};
