import { Action } from 'redux';
import AppThunk from '../../redux/interfaces/AppThunk';
import Firestore from '../firestore/Firestore';
import ICraving from './interfaces/ICraving';
// import { formatSurveyToFirestore } from './utils';

export enum CravingActionTypes {
  SUBMIT_CRAVING = 'SUBMIT_CRAVING',
  CREATED_CRAVING = 'CREATED_CRAVING',
  CREATING_CRAVING = 'CREATING_CRAVING',
}

export type CravingActions = SubmitCravingAction | CreatingCravingAction | CreatedCravingAction;

export interface SubmitCravingAction extends Action<CravingActionTypes.SUBMIT_CRAVING> {
  craving: null | ICraving;
}

export const submitCraving = (craving: null | ICraving): SubmitCravingAction => ({
  type: CravingActionTypes.SUBMIT_CRAVING,
  craving,
});

export type CreatingCravingAction = Action<CravingActionTypes.CREATING_CRAVING>;

export const creatingCraving = (): CreatingCravingAction => ({
  type: CravingActionTypes.CREATING_CRAVING,
});

export interface CreatedCravingAction extends Action<CravingActionTypes.CREATED_CRAVING> {
  craving: ICraving;
}

export const createdCraving = (craving: ICraving): CreatedCravingAction => ({
  type: CravingActionTypes.CREATED_CRAVING,
  craving,
});

export const createCraving = (
  rawCraving: ICraving, //Omit<ICraving, 'id'>
): AppThunk<Promise<CreatedCravingAction>, CravingActions> => {
  return async (dispatch) => {
    dispatch(creatingCraving());

    const craving = rawCraving; //formatSurveyToFirestore(rawCraving);
    const cravingRef = Firestore.collection('cravings').doc();
    await cravingRef.set(craving);
    return dispatch(
      createdCraving({
        ...craving,
        // id: cravingRef.id
      }),
    );
  };
};
