import ISupportRequest, { SupportRequestStatus } from '../../db/supportRequests';

export const dateInWeek = (date: Date, weeksAgo = 0): boolean => {
  const todayObj = new Date();
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay();

  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay - weeksAgo * 7));
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  return date >= firstDayOfWeek && date <= lastDayOfWeek;
};

export const filterRequestsByStatusSort = (requests: ISupportRequest[], status: SupportRequestStatus): ISupportRequest[] => {
  if (status === SupportRequestStatus.ALL) return [...requests].sort((r1, r2) => cmpDate(r1.dateTime, r2.dateTime));
  return requests.filter((r) => r.status === status).sort((r1, r2) => cmpDate(r1.dateTime, r2.dateTime));
};

export const cmpDate = (d1: Date | undefined, d2: Date | undefined): number => {
  if (!d1 && !d2) return 0;
  if (!d1) return 1;
  if (!d2) return -1;
  return d2.getTime() - d1.getTime();
};

export const filterRequestsRecentSort = (requests: ISupportRequest[]): ISupportRequest[] => {
  return requests.filter((v) => (v.dateTime && (dateInWeek(v.dateTime) || dateInWeek(v.dateTime, 1))))
    .sort((r1, r2) => cmpDate(r1.dateTime, r2.dateTime));
};
