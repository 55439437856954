import { useMutation, useQuery, useQueryClient, UseQueryResult, UseMutationResult } from 'react-query';
import { getMeditations, createMeditation, deleteMeditation, updateMeditation } from '../../db/meditations';
import IMeditation from './interfaces/IMeditation';
import { MeditationToFirestore } from './utils';

const KEY_NAME = 'meditations';

export const useQueryMeditations = (pirId: string): UseQueryResult<IMeditation[], unknown> => {
  return useQuery([KEY_NAME, pirId], async () => getMeditations(pirId), { staleTime: Infinity });
};

export const useCreateMeditations = (
  pirId: string,
): UseMutationResult<void, unknown, Omit<IMeditation | MeditationToFirestore, 'id' | 'meditationType'>> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (rawMeditation: Omit<IMeditation | MeditationToFirestore, 'id' | 'meditationType'>) => {
      return createMeditation(rawMeditation);
    },
    {
      onSuccess: async () => queryClient.invalidateQueries([KEY_NAME, pirId]),
    },
  );
};

export const useDeleteMeditations = (pirId: string): UseMutationResult<void, unknown, IMeditation> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (meditation: IMeditation) => {
      return deleteMeditation(meditation);
    },
    {
      onSuccess: async () => queryClient.invalidateQueries([KEY_NAME, pirId]),
    },
  );
};

export const useUpdateMeditations = (pirId: string): UseMutationResult<void, unknown, IMeditation> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (meditation: IMeditation) => {
      return updateMeditation(meditation);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_NAME, pirId]);
      },
    },
  );
};
