import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  Box,
  Text,
  Button,
  useDisclosure,
  Divider,
  Flex,
} from '@chakra-ui/react';
import FAIcon from '../FAIcon';
import { RootState } from '../../redux/store';
import {
  createLinkedUser,
  deleteLinkedUser,
  createSupporterLinkedUser,
  updatePartialLinkedUser,
} from '../../modules/linked-users/actions';
import SupportMemberInfoForm from './SupportMemberInfoForm';
import { FirestoreUtils } from '../../modules/firestore/Firestore';
import IUser from 'src/modules/user/interfaces/IUser';
import ILinkedUser, {
  LinkedUserRole,
  IIncompleteLinkedUser,
  ISupporterLinkedUser,
  PossibleLinkedUser,
  IRejectedLinkedUser,
  PhoneAuthorization,
  NonPirLinkedUser,
} from '../../modules/linked-users/interfaces/ILinkedUser';
import {
  isLinkedUser,
  isIncompleteLinkedUser,
  isSupporterLinkedUser,
  isRejectedLinkedUser,
} from '../../modules/linked-users/utils';
import ApprovedContact from './ApprovedContact';
import PendingOrRejectedContact from './PendingOrRejectedContact';
import SupportContact from './SupportContact';
import { createInteraction } from '../../modules/interactions/actions';
import { moduleName, interactionType } from '../../modules/interactions/constants';
import { Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { wasEdited } from 'src/modules/user/utils';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  isOpen: boolean;
  onOpen(): void;
  onClose(): void;
  pir: firebase.firestore.DocumentReference | null;
}

export enum ContactType {
  APPROVED_PROVIDER = 'APPROVED_PROVIDER',
  SUPPORTER = 'SUPPORTER',
  PENDING_PROVIDER = 'PENDING_PROVIDER',
}

export interface Contact {
  contactType: ContactType;
  linkedUser: PossibleLinkedUser;
  otherUserPhone?: string;
  otherUserEmail?: string;
  contactAlias?: string;
  relationship?: string;
  preferredName?: string;
  cpName?: string;
  phoneAuthorization?: PhoneAuthorization;
  notificationOnSOS?: boolean;
}

export interface FormData {
  preferredName: string;
  contactAlias: string;
  relationship?: string;
  notificationOnSOS?: boolean;
  otherUserPhone?: string;
  otherUserEmail: string;
  cpName?: string;
}

type Props = SuppliedProps & PropsFromRedux;

const ManageContactModal = (props: Props): JSX.Element => {
  const { t } = useTranslation('common');
  const {
    isOpen,
    onClose,
    user,
    supportNetwork,
    createLinkedUser,
    deleteLinkedUser,
    pir,
    createInteraction,
    hasOnboarded,
    createSupporterLinkedUser,
    updatePartialLinkedUser,
  } = props;
  const [providerFormError, setProviderFormError] = useState<null | string>(null);
  const [supporterFormError, setSupporterFormError] = useState<null | string>(null);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [editingLinkedUser, setEditingLinkedUser] = useState<Contact | null>(null);

  const providerInfoDisclosure = useDisclosure();
  const supporterInfoDisclosure = useDisclosure();
  const { isPhone } = useDevice();

  const addProviderRef: React.RefObject<HTMLInputElement> = React.createRef();
  const editProviderRef: React.RefObject<HTMLInputElement> = React.createRef();
  const addSupporterRef: React.RefObject<HTMLInputElement> = React.createRef();
  const editSupporterRef: React.RefObject<HTMLInputElement> = React.createRef();

  useEffect(() => {
    if (providerInfoDisclosure.isOpen && !editingLinkedUser && addProviderRef.current) {
      addProviderRef.current.scrollIntoView({ behavior: 'auto' });
    } else if (providerInfoDisclosure.isOpen && !!editingLinkedUser && editProviderRef.current) {
      editProviderRef.current.scrollIntoView({ behavior: 'auto' });
    } else if (supporterInfoDisclosure.isOpen && !editingLinkedUser && addSupporterRef.current) {
      addSupporterRef.current.scrollIntoView({ behavior: 'auto' });
    } else if (supporterInfoDisclosure.isOpen && !!editingLinkedUser && editSupporterRef.current) {
      editSupporterRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [providerInfoDisclosure.isOpen, supporterInfoDisclosure.isOpen, editingLinkedUser]);

  // Setup our support network arrays
  const approvedProviders: ILinkedUser[] = [];
  const pendingProviders: IIncompleteLinkedUser[] = [];
  const rejectedProviders: IRejectedLinkedUser[] = [];
  const supporters: ISupporterLinkedUser[] = [];

  supportNetwork.forEach((networkMember) => {
    if (isSupporterLinkedUser(networkMember)) {
      supporters.push(networkMember);
    } else if (networkMember.otherUserRole === LinkedUserRole.PROVIDER) {
      if (isLinkedUser(networkMember)) {
        approvedProviders.push(networkMember);
      } else if (isRejectedLinkedUser(networkMember)) {
        rejectedProviders.push(networkMember);
      } else if (isIncompleteLinkedUser(networkMember)) {
        pendingProviders.push(networkMember);
      }
    }
  });

  const providers: (ILinkedUser | IIncompleteLinkedUser | IRejectedLinkedUser)[] = [
    ...approvedProviders,
    ...rejectedProviders,
    ...pendingProviders,
  ];

  // Provider form
  const providerFormSubmit = async (data: FormData): Promise<void> => {
    try {
      if (supportNetwork.find((supporter) => supporter.contactAlias === data.contactAlias)) {
        return setProviderFormError(t('manageContactModal.supporterForm.errors.sameContactAlias'));
      }
      setProviderFormError(null);
      await createLinkedUser({
        pir: FirestoreUtils.getDocRef('users', user.id),
        default: false,
        otherUserRole: LinkedUserRole.PROVIDER,
        reviewedByPir: true,
        approvedByPir: true,
        reviewedByOther: false,
        dateSubmitted: FirestoreUtils.now(),
        dateReviewedByPir: FirestoreUtils.now(),
        contactAlias: data.contactAlias,
        relationship: data.relationship !== '' ? data.relationship : undefined,
        preferredName: data.preferredName !== '' ? data.preferredName : undefined,
        email: data.otherUserEmail.toLowerCase(),
        phone: data.otherUserPhone !== '' ? data.otherUserPhone : undefined,
        notificationOnSOS: data.notificationOnSOS,
      });
    } catch (e) {
      if ((e as Error).message) {
        switch ((e as Error).message) {
          case 'inviteAlreadyExists':
          case 'linkageAlreadyExists':
          case 'sameEmailAsInviter':
          case 'inviteeIsNotProvider':
            return setProviderFormError(t('manageContactModal.supporterForm.errors.' + (e as Error).message));
          default:
            console.log('error', e);
            return setProviderFormError(t('manageContactModal.supporterForm.errors.unknown'));
        }
      } else {
        console.log('error', e);
        return setProviderFormError(t('manageContactModal.supporterForm.errors.unknown'));
      }
    }
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.SUPPORT_NETWORK,
      interactionType: interactionType.SUPPORT_NETWORK.SAVED,
    });
    return providerInfoDisclosure.onClose();
  };

  const cancelProviderForm = (): void => {
    setEditingLinkedUser(null);
    setProviderFormError(null);
    providerInfoDisclosure.onClose();
  };

  // Supporter form
  const supporterFormSubmit = async (data: FormData): Promise<void> => {
    try {
      if (supportNetwork.find((supporter) => supporter.contactAlias === data.contactAlias)) {
        return setSupporterFormError(t('manageContactModal.supporterForm.errors.sameContactAlias'));
      }
      setSupporterFormError(null);

      await createSupporterLinkedUser({
        pir: FirestoreUtils.getDocRef('users', user.id),
        default: false,
        otherUserRole: LinkedUserRole.SUPPORTER,
        reviewedByPir: true,
        approvedByPir: true,
        dateSubmitted: FirestoreUtils.now(),
        dateReviewedByPir: FirestoreUtils.now(),
        contactAlias: data.contactAlias,
        relationship: data.relationship !== '' ? data.relationship : undefined,
        otherUserEmail: data.otherUserEmail.toLowerCase(),
        otherUserPhone: data.otherUserPhone !== '' ? data.otherUserPhone : undefined,
        preferredName: data.preferredName,
        notificationOnSOS: data.notificationOnSOS,
      });
    } catch (e) {
      if ((e as Error).message) {
        switch ((e as Error).message) {
          case 'linkageAlreadyExists':
            return setSupporterFormError(t('manageContactModal.supporterForm.errors.' + (e as Error).message));
          default:
            return setSupporterFormError(t('manageContactModal.supporterForm.errors.unknown'));
        }
      } else {
        return setSupporterFormError(t('manageContactModal.supporterForm.errors.unknown'));
      }
    }

    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.SUPPORT_NETWORK,
      interactionType: interactionType.SUPPORT_NETWORK.SAVED,
    });

    return supporterInfoDisclosure.onClose();
  };

  const submitEditedForm = async (data: FormData): Promise<void> => {
    if (!editingLinkedUser || !editingLinkedUser.linkedUser || !('contactAlias' in editingLinkedUser.linkedUser)) {
      return;
    }

    const linkedUser: NonPirLinkedUser = editingLinkedUser.linkedUser;

    const updatesToLinkedUser: Partial<NonPirLinkedUser> = {};

    if (wasEdited(linkedUser.preferredName, data.preferredName)) {
      updatesToLinkedUser.preferredName = data.preferredName;
    }

    if (wasEdited(linkedUser.contactAlias, data.contactAlias)) {
      if (supportNetwork.find((supporter) => supporter.contactAlias === data.contactAlias)) {
        return isSupporterLinkedUser(linkedUser)
          ? setSupporterFormError(t('manageContactModal.supporterForm.errors.sameContactAlias'))
          : setProviderFormError(t('manageContactModal.supporterForm.errors.sameContactAlias'));
      }
      updatesToLinkedUser.contactAlias = data.contactAlias;
    }

    if (wasEdited(linkedUser.relationship, data.relationship)) {
      updatesToLinkedUser.relationship = data.relationship;
    }

    if (wasEdited(linkedUser.notificationOnSOS, data.notificationOnSOS)) {
      updatesToLinkedUser.notificationOnSOS = data.notificationOnSOS;
    }

    if (isSupporterLinkedUser(linkedUser) && wasEdited(linkedUser.otherUserEmail, data.otherUserEmail)) {
      updatesToLinkedUser.otherUserEmail = data.otherUserEmail;
    }

    if (isSupporterLinkedUser(linkedUser) && wasEdited(linkedUser.otherUserPhone, data.otherUserPhone)) {
      updatesToLinkedUser.otherUserPhone = data.otherUserPhone;
      updatesToLinkedUser.phoneAuthorization = PhoneAuthorization.PENDING;
    }

    const id = linkedUser.id as string;

    try {
      await updatePartialLinkedUser(id, updatesToLinkedUser, true);
    } catch (e) {
      if ((e as Error).message) {
        return setSupporterFormError(t('manageContactModal.supporterForm.errors.unknown'));
      } else {
        return setSupporterFormError(t('manageContactModal.supporterForm.errors.unknown'));
      }
    }

    if (isSupporterLinkedUser(linkedUser)) {
      supporterInfoDisclosure.onClose();
    } else {
      providerInfoDisclosure.onClose();
    }

    setSupporterFormError(null);
    setEditingLinkedUser(null);
  };

  const cancelSupporterForm = (): void => {
    setEditingLinkedUser(null);
    setSupporterFormError(null);
    supporterInfoDisclosure.onClose();
  };

  const openProviderForm = () => {
    cancelSupporterForm();
    providerInfoDisclosure.onOpen();
  };

  const openSupporterForm = () => {
    cancelProviderForm();
    supporterInfoDisclosure.onOpen();
  };

  const deleteNetworkMember = (
    linkedUser: IIncompleteLinkedUser | ILinkedUser | ISupporterLinkedUser | IRejectedLinkedUser,
  ): void => {
    if (window.confirm(t('manageContactModal.deleteConfirmation'))) {
      deleteLinkedUser(linkedUser);
      createInteraction({
        pir,
        dateTime: new Date(),
        moduleName: moduleName.SUPPORT_NETWORK,
        interactionType: interactionType.SUPPORT_NETWORK.DELETED,
      });
    }
  };

  const editNetworkMember = (
    linkedUser: IIncompleteLinkedUser | ILinkedUser | ISupporterLinkedUser | IRejectedLinkedUser,
    user: IUser | null = null,
  ): void => {
    if (isSupporterLinkedUser(linkedUser)) {
      cancelProviderForm();
    } else {
      cancelSupporterForm();
    }

    const contactType =
      linkedUser.otherUserRole === LinkedUserRole.SUPPORTER
        ? ContactType.SUPPORTER
        : isLinkedUser(linkedUser)
        ? ContactType.APPROVED_PROVIDER
        : ContactType.PENDING_PROVIDER;
    const otherUserPhone =
      contactType === ContactType.APPROVED_PROVIDER && user
        ? user.phone
        : linkedUser.otherUserPhone
        ? linkedUser.otherUserPhone
        : 'phone' in linkedUser
        ? linkedUser.phone
        : '';
    const otherUserEmail =
      contactType === ContactType.APPROVED_PROVIDER && user
        ? user.email
        : linkedUser.otherUserEmail
        ? linkedUser.otherUserEmail
        : 'email' in linkedUser
        ? linkedUser.email
        : '';
    const contactAlias = linkedUser.contactAlias
      ? linkedUser.contactAlias
      : contactType === ContactType.PENDING_PROVIDER && 'name' in linkedUser
      ? linkedUser.name
      : '';
    const phoneAuthorization = 'phoneAuthorization' in linkedUser ? linkedUser.phoneAuthorization : undefined;
    const notificationOnSOS =
      'notificationOnSOS' in linkedUser && linkedUser.notificationOnSOS !== undefined
        ? linkedUser.notificationOnSOS
        : true;
    const cpName = contactType === ContactType.APPROVED_PROVIDER && user ? user.name : undefined;

    const editableContact: Contact = {
      contactType,
      linkedUser,
      otherUserPhone,
      otherUserEmail,
      contactAlias,
      relationship: linkedUser.relationship,
      preferredName: linkedUser.preferredName,
      cpName,
      phoneAuthorization,
      notificationOnSOS,
    };

    setEditingLinkedUser(editableContact);
    if (isSupporterLinkedUser(linkedUser)) {
      supporterInfoDisclosure.onOpen();
    } else {
      providerInfoDisclosure.onOpen();
    }
  };

  const addModifiedFieldInteraction = () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.SUPPORT_NETWORK,
      interactionType: interactionType.SUPPORT_NETWORK.MODIFIED_FIELD,
    });
  };

  const onFieldFocus = () => {
    // Set onInputFocused state to adjust bottom margin for keyboard
    setInputFocused(true);

    // Track user interaction
    addModifiedFieldInteraction();
  };

  const onFieldBlur = () => {
    // Set onInputFocused state to adjust bottom margin for keyboard
    setInputFocused(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxWidth={
          !hasOnboarded || isPhone ? ['100%', '100%', '100%', '100%', '100%'] : ['100%', '98%', '90%', '85%', '75%']
        }
        minHeight={!hasOnboarded || isPhone ? ['100%', '100%', '100%', '100%', '100%'] : undefined}
        marginBottom={(!hasOnboarded || isPhone) && inputFocused ? 300 : undefined}
        marginTop={!hasOnboarded || isPhone ? [0, 0, 0] : undefined}
        p={[2, 2, 4, 8]}
        zIndex={1900}
      >
        <ModalHeader
          fontWeight="light"
          color="purple3.600"
          fontSize={24}
          textAlign="center"
          px={[1, 1, 6]}
          marginBottom={-3}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {t('manageContactModal.header')}
        </ModalHeader>

        {isPhone ? (
          <Flex position="relative" justifyContent="center" alignItems="start" marginTop={4}>
            <Box position="absolute" left="21px" top="0" mr={6}>
              <FAIcon icon={'bell'} as="div" fontSize={18} color="grey.500" />
            </Box>
            <Text
              marginLeft="40px"
              marginRight="40px"
              flexWrap="wrap"
              fontSize="md"
              fontWeight="light"
              color="purple3.600"
              textAlign="center"
            >
              {t('manageContactModal.subheader')}
            </Text>
          </Flex>
        ) : (
          <Box textAlign="center">
            <Flex justifyContent="center" alignItems="start" marginTop={4}>
              <Flex alignItems="start" wrap="nowrap">
                <Box minWidth="24px" mr={['0em', '0.25em']}>
                  <FAIcon icon={'bell'} as="div" fontSize={18} color="grey.500" />
                </Box>
                <Text
                  flexWrap="wrap"
                  fontSize="md"
                  fontWeight="light"
                  color="purple3.600"
                  marginBottom={2}
                  textAlign="center"
                >
                  {t('manageContactModal.subheader')}
                </Text>
              </Flex>
            </Flex>
          </Box>
        )}

        {hasOnboarded && <ModalCloseButton />}
        <ModalBody display="flex" px={[1, 1, 6]} flexWrap="wrap">
          {/* Provider info */}
          <Box w={['100%', '100%', '45%', '45%']} ml={[0, 0, '5%']} mt={[6, 6, 0]}>
            <Text fontSize={18} fontWeight="light" color="purple3.600" as="h4" mb={4}>
              {t('manageContactModal.provider.header')}
            </Text>

            <Box>
              {/* Providers */}
              {providers.map((provider) => {
                return providerInfoDisclosure.isOpen &&
                  editingLinkedUser &&
                  editingLinkedUser.linkedUser.id === provider.id ? (
                  <SupportMemberInfoForm
                    ref={editProviderRef}
                    initialValues={editingLinkedUser}
                    contactAliasPlaceholder={t('manageContactModal.provider.contactAliasPlaceholder')}
                    preferredNamePlaceholder={user.name || t('manageContactModal.preferredNamePlaceholder')}
                    relationshipPlaceholder={t('manageContactModal.provider.relationshipPlaceholder')}
                    error={providerFormError}
                    onSubmit={submitEditedForm}
                    onCancel={cancelProviderForm}
                    interactionCallback={onFieldFocus}
                    onBlurCallback={onFieldBlur}
                    type="provider"
                    key={provider.id}
                  />
                ) : isLinkedUser(provider) ? (
                  <ApprovedContact
                    onDelete={() => deleteNetworkMember(provider)}
                    networkMember={provider}
                    key={provider.id}
                    onEdit={(user) => editNetworkMember(provider, user)}
                  />
                ) : (
                  <PendingOrRejectedContact
                    networkMember={provider}
                    key={provider.id}
                    onDelete={() => deleteNetworkMember(provider)}
                    onEdit={() => editNetworkMember(provider)}
                  />
                );
              })}

              {providerInfoDisclosure.isOpen && !editingLinkedUser && (
                <SupportMemberInfoForm
                  ref={addProviderRef}
                  initialValues={null}
                  contactAliasPlaceholder={t('manageContactModal.provider.contactAliasPlaceholder')}
                  preferredNamePlaceholder={user.name || t('manageContactModal.preferredNamePlaceholder')}
                  relationshipPlaceholder={t('manageContactModal.provider.relationshipPlaceholder')}
                  error={providerFormError}
                  onSubmit={providerFormSubmit}
                  onCancel={cancelProviderForm}
                  interactionCallback={onFieldFocus}
                  onBlurCallback={onFieldBlur}
                  type="provider"
                />
              )}

              {!providerInfoDisclosure.isOpen && (
                <Button
                  variant="link"
                  fontWeight="normal"
                  onClick={openProviderForm}
                  data-test="add-contact-care-provider"
                >
                  <FAIcon icon="plus" mr={3} />
                  Add Contact
                </Button>
              )}
            </Box>
          </Box>

          <Divider w="100%" display={['block', 'block', 'none']} mt={8} />

          {/* Supporter info */}
          <Box w={['100%', '100%', '45%', '45%']} ml={[0, 0, '5%']} mt={[6, 6, 0]}>
            <Text fontSize={18} fontWeight="light" color="purple3.600" as="h4" mb={4}>
              {t('manageContactModal.supporter.header')}
            </Text>

            <Box>
              {/* supporters */}
              {supporters.map((supporter) => {
                return supporterInfoDisclosure.isOpen &&
                  editingLinkedUser &&
                  editingLinkedUser.linkedUser.id === supporter.id ? (
                  <SupportMemberInfoForm
                    ref={editSupporterRef}
                    initialValues={editingLinkedUser}
                    contactAliasPlaceholder={t('manageContactModal.supporter.contactAliasPlaceholder')}
                    preferredNamePlaceholder={user.name || t('manageContactModal.preferredNamePlaceholder')}
                    relationshipPlaceholder={t('manageContactModal.supporter.relationshipPlaceholder')}
                    error={supporterFormError}
                    onSubmit={submitEditedForm}
                    onCancel={cancelSupporterForm}
                    interactionCallback={onFieldFocus}
                    onBlurCallback={onFieldBlur}
                    type="supporter"
                    key={supporter.id}
                  />
                ) : (
                  <SupportContact
                    onDelete={() => deleteNetworkMember(supporter)}
                    networkMember={supporter}
                    key={supporter.id}
                    onEdit={() => editNetworkMember(supporter)}
                  />
                );
              })}

              {supporterInfoDisclosure.isOpen && !editingLinkedUser && (
                <SupportMemberInfoForm
                  ref={addSupporterRef}
                  initialValues={null}
                  contactAliasPlaceholder={t('manageContactModal.supporter.contactAliasPlaceholder')}
                  preferredNamePlaceholder={user.name || t('manageContactModal.preferredNamePlaceholder')}
                  relationshipPlaceholder={t('manageContactModal.supporter.relationshipPlaceholder')}
                  error={supporterFormError}
                  onSubmit={supporterFormSubmit}
                  onCancel={cancelSupporterForm}
                  interactionCallback={onFieldFocus}
                  onBlurCallback={onFieldBlur}
                  type="supporter"
                />
              )}

              {!supporterInfoDisclosure.isOpen && (
                <Button
                  variant="link"
                  fontWeight="normal"
                  onClick={openSupporterForm}
                  data-test="add-contact-support-network-information"
                >
                  <FAIcon icon="plus" mr={3} />
                  Add Contact
                </Button>
              )}
            </Box>
          </Box>

          <Divider w="100%" display={['block', 'block', 'none']} mt={8} />
        </ModalBody>
        <ModalFooter>
          {!hasOnboarded && (
            <Box>
              <Text fontSize={[12, 16]} mt={2} mr={4}>
                {t('manageContactModal.onboarding.nextCaption')}
              </Text>
              <Link to="/medication" style={{ float: 'right' }}>
                <Button fontSize={[12, 16]} mt={2} mr={4}>
                  {t('manageContactModal.onboarding.nextButton')}
                </Button>
              </Link>
            </Box>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  const user = state.user.user;

  if (!user) {
    throw new Error('No user logged in during Manage Contact Modal');
  }

  return {
    user,
    supportNetwork: state.linkedUsers.supportNetwork ?? [],
    hasOnboarded: state.onboarding.hasOnboarded,
  };
};

const mapDispatchToProps = {
  createLinkedUser,
  deleteLinkedUser,
  createInteraction,
  createSupporterLinkedUser,
  updatePartialLinkedUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ManageContactModal);
