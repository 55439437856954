import { QueryDocumentSnapshot } from 'firebase/firestore';
import { IResource, resourceType } from '../../db/resources';

export const formatResourceFromFirestore = (snapshot: QueryDocumentSnapshot): IResource => {
  //taken from fields in database (pir)
  const pir = snapshot.get('pir');

  if (typeof pir === 'undefined') {
    throw new Error('No PIR defined on the resource in the database');
  }

  const newResource = {
    id: snapshot.id,
    pir,
    title: snapshot.get('title'),
    note: snapshot.get('note'),
    link: snapshot.get('link'),
    attachmentUrl: snapshot.get('attachmentUrl'),
    attachmentName: snapshot.get('attachmentName'),
  } as IResource;
  newResource.resourceType = resourceType(newResource);
  return newResource;
};

export interface ResourceToFirestore extends Omit<IResource, 'id' | 'resourceType'> {
  id?: string;
}

export const formatResourceToFirestore = (
  resource: ResourceToFirestore | IResource,
): Omit<IResource, 'id' | 'resourceType'> => {
  const out: Omit<IResource, 'id' | 'resourceType'> = {
    pir: resource.pir,
    title: resource.title,
  };

  if (typeof resource.note !== 'undefined') {
    out.note = resource.note;
  }

  if (typeof resource.link !== 'undefined') {
    out.link = resource.link;
  }

  if (typeof resource.attachmentUrl !== 'undefined') {
    out.attachmentUrl = resource.attachmentUrl;
  }

  if (typeof resource.attachmentName !== 'undefined') {
    out.attachmentName = resource.attachmentName;
  }

  return out;
};
