import { IDataPoint } from './interfaces/IVitalsGraph';
import { GraphType } from './vitalsGraphOptions';
import dayjs from 'dayjs';

export interface IGraphPoint {
  x: Date;
  y: number | null;
  c?: boolean;
}

export interface ICravingPoint {
  x: Date;
  y: number;
  c?: boolean;
  symbol: string;
}

interface ICravingGraphPoint {
  x: number;
  y: number;
  c?: boolean;
  symbol: string;
}

interface ICravingBarGraphPoint {
  x: number;
  y: number;
  c?: boolean;
  y0: number;
  topOverlaps?: boolean;
  bottomOverlaps?: boolean;
}

const isArrayOfNumbers = (values: Array<number> | Array<null>): values is Array<number> => {
  return typeof values[0] === 'number';
};

export type GraphDataReducerType = 'average' | 'max' | 'min' | 'sum';
export type GraphDataReducer = (values: Array<number> | Array<null>) => number | null;
export type GraphDataReducers = {
  [key in GraphDataReducerType]: GraphDataReducer;
};

const reducers: GraphDataReducers = {
  average: (values) => {
    if (isArrayOfNumbers(values)) return values.reduce((a, b) => a + b, 0) / values.length;
    return null;
  },
  min: (values) => {
    if (isArrayOfNumbers(values)) return values.reduce((a, b) => Math.min(a, b), Infinity);
    return null;
  },
  max: (values) => {
    if (isArrayOfNumbers(values)) return values.reduce((a, b) => Math.max(a, b), 0);
    return null;
  },
  sum: (values) => {
    if (isArrayOfNumbers(values)) return values.reduce((a, b) => a + b, 0);
    return null;
  },
};

export const getGraphData = (
  data: IDataPoint[],
  ndays: number,
  graphType: GraphType,
  reducer: GraphDataReducerType,
): IGraphPoint[] => {
  if (data.length === 0) {
    return [];
  }

  const currentTime = dayjs().valueOf();
  let startDate = ndays === 1 ? dayjs().startOf('hour') : dayjs().startOf('day');
  startDate = startDate.subtract(ndays, 'day');
  const startTime = startDate.valueOf();

  const matchData = data.filter((item: IDataPoint) => {
    return item.dateTime.getTime() >= startTime && item.dateTime.getTime() <= currentTime;
  });

  type HourDataValue = number | null | { booleanValue?: boolean; value: number; day?: number };

  const hourData: { [key: string]: HourDataValue[] } = {};
  matchData.forEach((item) => {
    let key;
    if (ndays > 1) {
      // if one point per day
      const date = dayjs(item.dateTime).startOf('day');
      key = date.format('YYYYMMDD');
    } else {
      // if one point per hour
      const hour = dayjs(item.dateTime).startOf('hour');
      key = hour.format('YYYYMMDDHH');
    }
    if (!hourData[key]) {
      hourData[key] = [];
    }
    hourData[key].push(item.value as number);
  });

  if (graphType !== 'emotion') {
    const totalTimeSlots = ndays > 1 ? ndays : 24 * ndays;
    let key;
    for (let i = 0; i <= totalTimeSlots; i++) {
      if (ndays > 1) {
        // one point per day
        const day = dayjs(startTime).add(i, 'day').startOf('day');
        key = day.format('YYYYMMDD');
      } else {
        const hour = dayjs(startTime).add(i, 'hour');
        key = hour.format('YYYYMMDDHH');
      }

      if (!hourData[key]) {
        if (reducer === 'sum') {
          hourData[key] = [0];
        } else {
          hourData[key] = [null];
        }
      }
    }
  }

  let result = [];
  for (const key in hourData) {
    const dateDayjs = ndays > 1 ? dayjs(key, 'YYYYMMDD') : dayjs(key, 'YYYYMMDDHH');
    const date = dateDayjs.toDate();
    result.push({
      x: date,
      y: reducers[reducer](hourData[key] as number[]),
    });
  }

  if (result.every((point) => point.y === null)) result = [];
  return result;
};

// returns points which are isolated from any other points on the graph (will not be connected by a line to another point)
export const findIsolatedPoints = (data: IGraphPoint[]) => {
  const isolated = [];
  for (let i = 0; i < data.length; i++) {
    if (
      data[i].y !== null &&
      (i === 0 || data[i - 1].y === null) &&
      (i === data.length - 1 || data[i + 1].y === null)
    ) {
      isolated.push(data[i]);
    }
  }
  return isolated;
};

/**
 * Creates tick label positions as all date changes within the prediction range.
 *
 * @returns The a list of Date of tick labels
 */
export const generateTickLabelPositions = <T>(data: T[], ndays: number): Date[] => {
  const output = [];
  const currentTime = new Date();

  if (ndays <= 1) {
    // for hourly data
    currentTime.setHours(currentTime.getHours(), 0, 0, 0);
    for (let i = 0; i < Math.min(data.length, 24); i++) {
      output.unshift(new Date(currentTime));
      currentTime.setHours(currentTime.getHours() - 1, 0, 0, 0);
    }
  } else {
    const adjustedNDays = ndays === 7 ? ndays + 1 : ndays;
    currentTime.setHours(0, 0, 0, 0);
    for (let i = 0; i < adjustedNDays; i++) {
      output.unshift(new Date(currentTime));
      currentTime.setDate(currentTime.getDate() - 1);
    }
  }
  return output;
};

export const formatDayTicks = (t: number, ndays: number): string => {
  if (ndays === 1) {
    const hours = new Date(t).getHours();
    return (
      '' +
      (hours === 0
        ? '12a'
        : hours % 3
        ? ''
        : hours < 12
        ? `${hours}a`
        : hours > 12
        ? `${hours - 12}p`
        : hours === 12
        ? '12p'
        : '12a')
    );
  } else if (ndays === 7) {
    const dayOfWeek = new Date(t).getDay();
    switch (dayOfWeek) {
      case 0:
        return 'Sun';
      case 1:
        return 'Mon';
      case 2:
        return 'Tue';
      case 3:
        return 'Wed';
      case 4:
        return 'Thu';
      case 5:
        return 'Fri';
      case 6:
        return 'Sat';
      default:
        return '';
    }
  } else {
    if (ndays === 28 && new Date(t).getDate() !== 1 && new Date(t).getDate() !== 15) {
      return '';
    } else {
      const month = new Date(t).getMonth() + 1;
      const date = new Date(t).getDate();
      return month + '/' + date;
    }
  }
};

export const getCravingGraphData = (data: IDataPoint[], ndays: number): ICravingPoint[] => {
  const startDate = new Date();
  const currentTime = new Date();
  startDate.setHours(startDate.getHours() + 6);
  startDate.setDate(startDate.getDate() - ndays);
  startDate.setHours(0, 0, 0, 0);

  const matchData = data.filter((item: IDataPoint) => {
    const predictionStart = new Date(item.dateTime);
    // set the prediction start to 3 hours after the time it was created (at the beginning of the hour)
    predictionStart.setHours(predictionStart.getHours() + 3);
    predictionStart.setMinutes(0);
    predictionStart.setSeconds(0);
    predictionStart.setMilliseconds(0);
    // only include predictions that start after the start date
    return predictionStart > startDate && item.dateTime <= currentTime;
  });

  const result: ICravingPoint[] = [];

  matchData.forEach((item) => {
    const predictionStart = new Date(item.dateTime);
    predictionStart.setHours(item.dateTime.getHours() + 3);
    const time = predictionStart.getHours();
    const day = new Date(predictionStart);
    day.setHours(0, 0, 0, 0);
    result.push({
      x: day,
      y: time,
      c: item.booleanValue,
      symbol: item.booleanValue === true ? 'square' : 'circle',
    });
  });
  return result;
};

export const getCravingGraphStartDayOfWeek = (): number => {
  const predictionStart = new Date();
  predictionStart.setHours(predictionStart.getHours() + 6);
  predictionStart.setDate(predictionStart.getDate() - 7);
  return predictionStart.getDay();
};

export const getCravingDayOfWeek = (date: Date): number => {
  const startDayOfWeek = getCravingGraphStartDayOfWeek();
  const endDate = getCravingGraphEndDay();
  return date >= endDate || date.getDay() < startDayOfWeek ? date.getDay() + 7 : date.getDay();
};

export const getCravingGraphEndDay = (): Date => {
  const predictionEnd = new Date();
  predictionEnd.setHours(predictionEnd.getHours() + 6);
  const lastPredictionDay = new Date(
    predictionEnd.getFullYear(),
    predictionEnd.getMonth(),
    predictionEnd.getDate(),
    0,
    0,
    0,
    0,
  );
  return lastPredictionDay;
};

export const generateCravingTickLabelPositions = (): number[] => {
  const startDay = getCravingGraphStartDayOfWeek();
  const twoWeeks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

  const output = twoWeeks.slice(startDay, startDay + 8);
  return output;
};

export const formatCravingsDayTicks = (t: number): string => {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const extendedDaysOfWeek = [...daysOfWeek, ...daysOfWeek];
  return extendedDaysOfWeek[t];
};

// for the scatter graph data for the craving graph, any green points which would overlap with a red bar are removed -- the bar will be visible in spots where it doesn't overlap any red bar, but so as not to add confusion, the point (which would otherwise be on top of all bars) is removed.
export const getCravingsDataForScatter = (data: ICravingPoint[]): ICravingGraphPoint[] => {
  return data
    .filter((point, i) => {
      if (point.c === true || i === 0) return true;
      if (data[i - 1] && data[i - 1].c === true) {
        if (data[i - 1].x.getTime() === point.x.getTime() && data[i - 1].y > point.y - 3) return false;
      }
      if (data[i - 2] && data[i - 2].c === true) {
        if (data[i - 2].y > point.y - 3 && data[i - 2].x.getTime() === point.x.getTime()) return false;
      }
      return true;
    })
    .map((point) => {
      return {
        x: getCravingDayOfWeek(point.x),
        y: point.y,
        c: point.c,
        symbol: point.symbol,
      };
    });
};

// For the craving graph bars, there are a few steps. First, the bar ranges are created from the points -- if the time is later than 9pm, two bars are created: one for the first day and one for the next day. Second, variables are added to the bar object which denote whether the top/bottom of the bar overlaps with other bars, which will determine the shape of the top/bottom of the bar. Finally, the bars are sorted so that all "true" (red) craving values come last, so they cover any green bars they overlap with.
export const getCravingsDataForBarChart = (data: ICravingPoint[]): ICravingBarGraphPoint[] => {
  const dataForBarChart: ICravingBarGraphPoint[] = [];

  data.forEach((point) => {
    if (point.y <= 21) {
      const rangeValue = {
        x: getCravingDayOfWeek(point.x),
        y: point.y,
        y0: point.y + 3,
        c: point.c,
      };
      dataForBarChart.push(rangeValue);
    } else {
      const firstDay = point.x;
      const secondDay = new Date(firstDay.getTime());
      secondDay.setHours(secondDay.getHours() + 24);
      const rangeValue1 = {
        x: getCravingDayOfWeek(firstDay),
        y: point.y,
        y0: 24,
        c: point.c,
      };
      const rangeValue2 = {
        x: getCravingDayOfWeek(secondDay),
        y: 0,
        y0: 24 - point.y,
        c: point.c,
      };
      dataForBarChart.push(rangeValue1);
      dataForBarChart.push(rangeValue2);
    }
  });

  const markBarOverlaps = (data: ICravingBarGraphPoint[]) => {
    if (data.length > 0) {
      data[0].topOverlaps = false;
      data[0].bottomOverlaps = false;
    }

    for (let i = 1; i < data.length; i++) {
      data[i].topOverlaps = false;
      data[i].bottomOverlaps = false;

      // Check if top or bottom of current bar's range overlaps with the previous bar's range
      if (data[i].x === data[i - 1].x) {
        if (pointInRange(data[i].y0, data[i - 1].y, data[i - 1].y0)) {
          data[i].topOverlaps = true;
        }
        if (pointInRange(data[i].y, data[i - 1].y, data[i - 1].y0)) {
          data[i].bottomOverlaps = true;
        }
      }

      // Check if top or bottom of current bar's range overlaps with the next bar's range
      if (i + 1 < data.length) {
        if (data[i].x === data[i + 1].x) {
          if (pointInRange(data[i].y0, data[i + 1].y, data[i + 1].y0)) {
            data[i].topOverlaps = true;
          }
          if (pointInRange(data[i].y, data[i + 1].y, data[i + 1].y0)) {
            data[i].bottomOverlaps = true;
          }
        }
      }
    }
  };

  const pointInRange = (point: number, rangeStart: number, rangeEnd: number) => {
    // Only returns true if point is above the start or below the end of the range -- bars should have rounded edges if they touch but don't overlap
    return point > rangeStart && point < rangeEnd;
  };

  markBarOverlaps(dataForBarChart);
  const falseArray = dataForBarChart.filter((bar) => bar.c === false);
  const trueArray = dataForBarChart.filter((bar) => bar.c === true);
  return falseArray.concat(trueArray);
};

export const getCravingsCurrentTimeXCoordinate = (maximumX: number) => {
  // Helper to place the cravings "current date and time" point based on window dimensions so it doesn't overlap other points
  const getCravingsTimeLineOffset = (aspectRatio: number) => {
    const minOffset = 0.09;
    const maxOffset = 0.5;
    const minAspectRatio = 0.5;
    const maxAspectRatio = 2;

    if (aspectRatio <= minAspectRatio) {
      return minOffset;
    } else if (aspectRatio >= maxAspectRatio) {
      return maxOffset;
    } else {
      const interpolationFactor = (aspectRatio - minAspectRatio) / (maxAspectRatio - minAspectRatio);
      return minOffset + (maxOffset - minOffset) * interpolationFactor;
    }
  };
  const aspectRatio = window.innerHeight / window.innerWidth;
  const offset = getCravingsTimeLineOffset(aspectRatio);
  return maximumX - offset;
};
