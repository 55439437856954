import React, { useRef } from 'react';
import { Box, Text, Skeleton, Flex } from '@chakra-ui/react';
import linkifyHtml from 'linkify-html';

interface Props {
  textContent: string;
  loading?: boolean;  isEdit?:boolean;
}

const TextResourceBox = ({ textContent, loading, isEdit }: Props): JSX.Element => {
  const options = { defaultProtocol: 'https', attributes: { class: 'link', target:"_blank"} };
  const textContentHtml = linkifyHtml(textContent.replace(/\n/g, '<br>'), options);
  const isExist = textContent.trim().split("").length

  const textRef = useRef<HTMLDivElement>(null);

  if(isExist){
    return (
      <Box width={"100%"}>
        <Skeleton isLoaded={!loading}>
          <Flex flexDirection={'column'}>
            <Box  noOfLines={isEdit ? 4 : 100} ref={textRef} border={"1px"} borderRadius={'5'} padding={"1"} pl={"2"} pr={"2"}>
              <Text color={"black"} fontSize={14} dangerouslySetInnerHTML={{ __html: textContentHtml }} />
            </Box>
          </Flex>
        </Skeleton>
      </Box>
    );
  }else{
    return <></>
  }
};

export default TextResourceBox;
