import { QueryDocumentSnapshot } from 'firebase/firestore';
import IMeditation from './interfaces/IMeditation';
import { meditationType } from './interfaces/IMeditation';

export const formatMeditationFromFirestore = (snapshot: QueryDocumentSnapshot): IMeditation => {
  const pir = snapshot.get('pir');

  if (typeof pir === 'undefined') {
    throw new Error('No PIR defined on the meditation in the database');
  }

  const newMeditation = {
    id: snapshot.id,
    pir,
    title: snapshot.get('title'),
    length: snapshot.get('length'),
    note: snapshot.get('note'),
    link: snapshot.get('link'),
    attachmentUrl: snapshot.get('attachmentUrl'),
    attachmentName: snapshot.get('attachmentName'),
  } as IMeditation;

  newMeditation.meditationType = meditationType(newMeditation);
  return newMeditation;
};

export interface MeditationToFirestore extends Omit<IMeditation, 'id' | 'meditationType'> {
  id?: string;
}

export const formatMeditationToFirestore = (
  meditation: MeditationToFirestore | IMeditation,
): Omit<IMeditation, 'id' | 'meditationType'> => {
  const out: Omit<IMeditation, 'id' | 'meditationType'> = {
    pir: meditation.pir,
    title: meditation.title,
  };

  if (typeof meditation.length !== 'undefined') {
    out.length = meditation.length;
  }

  if (typeof meditation.note !== 'undefined') {
    out.note = meditation.note;
  }

  if (typeof meditation.link !== 'undefined') {
    out.link = meditation.link;
  }

  if (typeof meditation.attachmentUrl !== 'undefined') {
    out.attachmentUrl = meditation.attachmentUrl;
  }

  if (typeof meditation.attachmentName !== 'undefined') {
    out.attachmentName = meditation.attachmentName;
  }

  return out;
};

export const getUrlType = (url: string): string => {
  const validVideoType = [
    'youtu.be',
    'youtube',
    'facebook',
    'soundcloud',
    'twitch',
    'vimeo',
    'mux',
    'streamable',
    'wistia',
    'dailymotion',
    'mixcloud',
    'vidyard',
  ];
  const checkValidVideo = validVideoType.find((video) => url.toLowerCase().indexOf(video) > -1);
  if (checkValidVideo) {
    return 'video';
  }
  return 'link';
};

export const getAttachmentType = (attachmentName: string): string => {
  const extension =
    attachmentName.toLowerCase().substring(attachmentName.lastIndexOf('.') + 1, attachmentName.length) ||
    attachmentName;
  if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
    return 'image';
  } else if (['mov', 'mpg', 'mpeg4', 'mp4', 'avi'].includes(extension)) {
    return 'video';
  } else if (['pdf', 'txt'].includes(extension)) {
    return 'pdf';
  } else if (['txt'].includes(extension)) {
    return 'text';
  } else if (['m4a', 'flac', 'mp3', 'wav', 'wma', 'aac'].includes(extension)) {
    return 'audio';
  } else return 'unknown';
};
