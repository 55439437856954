import { where, getDocs } from 'firebase/firestore';
import { firestoreQuery } from '../modules/firebase/firestore';
import IAlert from '../modules/alerts/interfaces/IAlert';
import { PossibleLinkedUser } from '../modules/linked-users/interfaces/ILinkedUser';
import { formatAlertFromFirestore } from '../modules/alerts/utils';


export const getAlerts = async (provider: PossibleLinkedUser): Promise<IAlert[]> => {
  const querySnapshot = await getDocs(firestoreQuery('alerts', where('pir', '==', provider.pir)));

  return querySnapshot.docs.map((linkedUser) => formatAlertFromFirestore(linkedUser));
};
