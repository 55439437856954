import firebase from 'firebase/compat/app';
import IMotivator from './interfaces/IMotivator';

export const formatMotivatorFromFirestore = (snapshot: firebase.firestore.QueryDocumentSnapshot): IMotivator => {
  const pir = snapshot.get('pir');

  if (typeof pir === 'undefined') {
    throw new Error('No PIR defined on the motivator in the database');
  }

  return {
    id: snapshot.id,
    pir,
    message: snapshot.get('message'),
    attachmentUrl: snapshot.get('attachmentUrl'),
    attachmentName: snapshot.get('attachmentName'),
  };
};

interface MotivatorToFirestore extends Omit<IMotivator, 'id'> {
  id?: string;
}

export const formatMotivatorToFirestore = (motivator: MotivatorToFirestore | IMotivator): Omit<IMotivator, 'id'> => {
  const out: Omit<IMotivator, 'id'> = {
    pir: motivator.pir,
    message: motivator.message,
  };
  if (typeof motivator.attachmentUrl !== 'undefined') {
    out.attachmentUrl = motivator.attachmentUrl;
  }

  if (typeof motivator.attachmentName !== 'undefined') {
    out.attachmentName = motivator.attachmentName;
  }

  return out;
};
