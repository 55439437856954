import { OnboardingActions, OnboardingActionType } from './actions';

interface OnboardingState {
  loading: boolean;
  hasOnboarded: boolean;
}

const defaultState: OnboardingState = {
  loading: false,
  hasOnboarded: false,
};

const onboardingReducer = (state: OnboardingState = defaultState, action: OnboardingActions): OnboardingState => {
  switch (action.type) {
    case OnboardingActionType.ONBOARDING_STARTED:
      return {
        ...state,
        loading: true,
        hasOnboarded: false,
      };

    case OnboardingActionType.ONBOARDED:
      return {
        ...state,
        loading: false,
        hasOnboarded: true,
      };

    case OnboardingActionType.ONBOARDING_RESET:
      return {
        ...state,
        loading: false,
        hasOnboarded: false,
      };

    default:
      return state;
  }
};

export default onboardingReducer;
