import React, { useContext, useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import firebase from 'firebase/compat/app';
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import { updatePartialUser } from '../user/actions';
import InteriorError from '../../components/InteriorError';
import { RootState } from '../../redux/store';
import PrimaryButton from '../../components/PrimaryButton';
import { useHistory } from 'react-router-dom';
import { FcmTokens } from './interfaces/IUser';
import { UserContext } from 'src/modules/interior/Index';

type SuppliedProps = {
  isPir: boolean;
  pirOnboarding?: boolean;
  onCompleted?: (didEnablePush: boolean) => void;
};

type Props = SuppliedProps & PropsFromRedux;

const GetFcmToken = (props: Props): JSX.Element | null => {
  const { updatePartialUser, pirOnboarding, onCompleted } = props;
  const user = useContext(UserContext);
  const history = useHistory();
  const comingFromSettings = !!onCompleted; // versus when this gets called every time the app is foregrounded

  if (!window.cordova || !window.device) {
    console.error('No instance of Cordova or device found!');

    return <InteriorError />;
  }

  const fcm = window.cordova.plugins.firebase.messaging;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const cancelRef = useRef(null);

  const getToken = () => {
    const getCallback = (token: string): void => {
      if (token && user && device) {
        // take into account old fcmToken before move to fcmTokens, and update tokens
        const deviceId = device.uuid;
        const previousTokens = user.fcmTokens;
        let updatedTokens: FcmTokens;
        const stringFcmTokens = typeof previousTokens === 'string';
        if (!previousTokens) {
          updatedTokens = {
            [deviceId]: token,
          };
        } else if (stringFcmTokens) {
          updatedTokens = {
            noDeviceId: previousTokens,
            [deviceId]: token,
          };
        } else {
          updatedTokens = { ...previousTokens, [deviceId]: token };
        }

        // if user is coming from settings, let them save doesWantPush in settings
        const userUpdates: any = !comingFromSettings
          ? { fcmTokens: updatedTokens, doesWantPush: true }
          : { fcmTokens: updatedTokens };
        if (stringFcmTokens) {
          userUpdates.fcmToken = firebase.firestore.FieldValue.delete();
        }
        updatePartialUser(user.id, userUpdates)
          .then(() => {
            if (pirOnboarding) history.push('/notifications');
            if (comingFromSettings) {
              return onCompleted(true);
            }
          })
          .catch((e) => {
            console.error('Error during getToken:', e);
            if (pirOnboarding) history.push('/notifications');
          });
      }
    };

    fcm
      .requestPermission({ forceShow: true })
      .then(() => {
        // Called when the token is refreshed
        fcm.onTokenRefresh(
          () => {
            fcm
              .getToken()
              .then(getCallback)
              .catch((e) => console.error('Error during getToken!', e));
          },
          (e: any) => {
            console.error('Error during token refresh!', e);
          },
        );

        // Starts the token refresh
        fcm
          .getToken()
          .then(getCallback)
          .catch(() => console.error('Error during getToken!'));
      })
      .catch((e) => {
        console.error('Error while requesting permission', e);
        return rejectToken();
      });
  };

  const rejectToken = (userSaidNo = false): void => {
    if (user?.id) {
      if (!user.fcmTokens || userSaidNo) {
        updatePartialUser(user.id, {
          doesWantPush: false,
        })
          .then(() => {
            if (pirOnboarding) {
              history.push('/notifications');
            }
            if (comingFromSettings) {
              onCompleted(false);
            }
          })
          .catch((e) => console.error(e));
      } else {
        if (pirOnboarding) {
          history.push('/notifications');
        }
        if (comingFromSettings) {
          onCompleted(false);
        }
      }
    }
  };

  // if comingFromSettings, we should let them edit doesWantPush and go through getting FCM token;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (user?.doesWantPush === true || comingFromSettings) {
      getToken();
    }
  }, []);

  // if they're coming from settings, they don't need to be asked if they will enable push because they've already asked to enable push
  if (typeof user?.doesWantPush === 'boolean' || comingFromSettings) {
    return null;
  }

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isCentered={true}
      isOpen={true}
      onClose={() => {
        // do nothing
      }}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader color="purple3.600" fontWeight="light" fontSize={24}>
            Can we send you notifications?
          </AlertDialogHeader>
          <AlertDialogBody>
            Recovery uses push notifications to communicate with you about your status and the status of people you care
            about.
          </AlertDialogBody>
          <AlertDialogFooter display="flex" justifyContent="space-between">
            <PrimaryButton onClick={getToken} w={['33%', '33%', '15%']}>
              Yes
            </PrimaryButton>
            <Button ref={cancelRef} onClick={() => rejectToken(true)} w={['33%', '33%', '15%']}>
              No
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const mapStateToProps = (state: RootState) => {
  const user = state.user.user;
  const { selectedLinkedUser } = state.linkedUsers;

  if (!user) {
    throw new Error('No user defined during push token gathering');
  }

  return {
    selectedLinkedUserPir: selectedLinkedUser?.pir || null,
  };
};

const mapDispatchToProps = {
  updatePartialUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GetFcmToken);
