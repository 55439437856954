import React, { useContext } from 'react';
import { Text, Flex, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getMeasureText } from '../VitalsMeasure';
import { getVitalsTable } from '../../../db/vitals';
import { UserContext, SelectedUserContext } from '../../interior/Index';
import FAIcon from '../../../components/FAIcon';

const VitalStatusTab = (props: { selectedGraph: string }): React.ReactElement => {
  const { t } = useTranslation('vitals');

  const user = useContext(UserContext);
  const { selectedUser } = useContext(SelectedUserContext);
  const uid = selectedUser?.id || (user ? user.id : null);
  const { data } = useQuery('vitalsTable-' + uid, async () => getVitalsTable(uid), { staleTime: 60 * 60 * 1000 });

  const arrowIcon = data && data[props.selectedGraph] < 0 ? 'arrow-down' : 'arrow-up';
  return (
    <Box>
      {data && (
        <Flex
          justifyContent="center"
          alignItems="center"
          border={2}
          borderRadius="lg"
          boxShadow="md"
          bg="purple3.100"
          py="2"
          fontWeight="bold"
        >
          <Box>
            <Text fontSize="lg" color="purple3.600">
              {props.selectedGraph === 'emotion' && data && data[props.selectedGraph] > -12 && (
                <FAIcon icon={arrowIcon} />
              )}
              &nbsp;
              {getMeasureText(t, props.selectedGraph, data[props.selectedGraph])}
              {props.selectedGraph !== 'craving' && '*'}
            </Text>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default VitalStatusTab;
