import React, { useContext } from 'react';
import { Flex, Box, Text, useDisclosure } from '@chakra-ui/react';
import EmergencyAlert from './EmergencyAlert';
import CravingAlert from '../craving/CravingAlert';
import { UserContext } from '../interior/Index';

import UserMenu from './UserMenu';
// import DeviceModulePIR from '../fitbitDevice/DeviceModulePIR';
// import ManageDeviceModal from '../../components/ManageDeviceModal/DeviceModal';
import MockData from '../mockData/MockData';
import ModalAlert from './ModalAlert';
import MockTodo from '../mockData/MockTodo';
// import { userInfo } from 'os';

const Header = (): JSX.Element => {
  const user = useContext(UserContext);
  const modalAlertDisclosure = useDisclosure();
  const emergencyAlert = useDisclosure();
  const cravingAlert = useDisclosure();
  const mockDataGenerator =
    process.env.REACT_APP_MOCK_DATA_GENERATOR_ENABLED === 'true' ? (
      <Flex>
        <MockData />
        <MockTodo />
      </Flex>
    ) : null;

  return (
    <Flex
      align="center"
      as="header"
      h="80px"
      px={[4, 6, 12]}
      borderBottomWidth={[0, 1]}
      borderBottomColor="#C4C4C4"
      data-test="top-header"
    >
      <Box flex="1" visibility={['visible', 'hidden']}>
        <Text fontWeight="bold" fontSize={24}>
          Hey, {user?.name.split(' ').shift()}!
        </Text>
        <Text color="grey3.400">
          {new Date().toLocaleString(undefined, {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
          })}
        </Text>
      </Box>

      {mockDataGenerator}

      <UserMenu />

      <ModalAlert isOpen={modalAlertDisclosure.isOpen} onClose={modalAlertDisclosure.onClose} source="alert" />
      <CravingAlert isOpen={cravingAlert.isOpen} onClose={cravingAlert.onClose} />
      <EmergencyAlert isOpen={emergencyAlert.isOpen} onClose={emergencyAlert.onClose} />
    </Flex>
  );
};

export default Header;
