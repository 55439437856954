import React from 'react';
import { Flex, Heading, Text } from '@chakra-ui/react';
import styles from './intro.module.css';

interface Props {
  title: string;
  caption: string;
  isFirstPage: boolean;
}
const CarouselItem = (props: Props): JSX.Element => {
  return (
    <Flex className={styles.slide} flexDirection="column" >
      {props.isFirstPage && (
        <Flex
          justifyContent="center"
          direction="column"
          flexGrow={1}
          pt={[1, 1, 1, 3]}
          pb={[2, 3, 3, 5]}
          alignItems="center"
        >
          <Heading as="h1" fontSize={[36, 36, 48, 48]} fontWeight={300} color="purple3.600">
            {props.title}
          </Heading>
        </Flex>
      )}

      {!props.isFirstPage && (
        <Flex
          justifyContent="flex-end"
          direction="column"
          pt={[1, 1, 1, 3]}
          pb={[2, 3, 3, 5]}
          align="center"
          flexGrow={1}
        >
          <Heading as="h1" fontSize={[24, 24, 36, 36]} fontWeight={300} color="purple3.600">
            {props.title}
          </Heading>
        </Flex>
      )}

      <Flex justifyContent="center" align="center" ml={[8, 16, 24, 48]} mr={[8, 16, 24, 48]}>
        <Text textAlign="center" color="purple3.600">
          {props.caption}
        </Text>
      </Flex>
    </Flex>
  );
};

export default CarouselItem;
