import firebase from 'firebase/compat/app';
import Firestore, { FirestoreUtils } from '../modules/firestore/Firestore';
import { dateInWeek } from '../modules/supportRequests/utils';

export default interface ISupportRequest {
  survey: firebase.firestore.DocumentReference;
  dateTime?: Date;
  displayDate?: string;
  whatSupportNeeded?: string;
  additionalInfo?: string;
  status: SupportRequestStatus;
}

export interface ISupportRequestUpdate {
  survey: firebase.firestore.DocumentReference;
  newStatus: SupportRequestStatus;
}

export enum SupportRequestStatus {
  ALL = 'ALL',
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export const formatSupportRequestFromFirestore = (snapshot: firebase.firestore.QueryDocumentSnapshot): ISupportRequestPossible => {
  const surveyRef = snapshot.ref;
  const surveyData = snapshot.data();

  if (!surveyData.needHelp) return { survey: surveyRef, status: undefined };

  let dateTime = surveyData.datetime;
  let displayDate;
  const whatSupportNeeded = surveyData.whatSupportNeeded;
  const additionalInfo = surveyData.additionalInfo;

  const statusUncasted = surveyData.status ?? SupportRequestStatus.NEW;
  const status: SupportRequestStatus = (statusUncasted as SupportRequestStatus) ?? SupportRequestStatus.NEW;

  if (dateTime) {
    dateTime = dateTime.toDate();

    const thisWeek = dateInWeek(dateTime);
    const lastWeek = dateInWeek(dateTime, 1);
    if (thisWeek || lastWeek) {
      const dateString = thisWeek ? '' : 'Last ';
      displayDate = dateString + dateTime.toLocaleString([], { weekday: 'short', hour: '2-digit', minute: '2-digit' });
    }
  }

  return {
    survey: surveyRef,
    dateTime,
    displayDate,
    whatSupportNeeded,
    additionalInfo,
    status,
  };
};

export interface ISupportRequestPossible extends Omit<ISupportRequest, 'status'> {
  status?: SupportRequestStatus;
}

export const getSupportRequests = async (pirId: string): Promise<ISupportRequest[]> => {
  const query = await Firestore.collection('surveys').where('pir', '==', FirestoreUtils.getDocRef('users', pirId)).get();
  if (query.empty) return [];

  const queryData = query.docs;
  const data = queryData.map((doc) => formatSupportRequestFromFirestore(doc))
    .filter((possibleRequest) => possibleRequest.status !== undefined) as ISupportRequest[];
  return data;
};


export const updateSupportRequestStatus = async (update: ISupportRequestUpdate): Promise<void> => {
  const surveyId = update.survey.id;
  if (surveyId) {
    await Firestore.collection('surveys').doc(surveyId).update({ 'status': (update.newStatus as string) });
  }
};
