import React, { useState, useEffect } from 'react';
import { Box, Skeleton, Text } from '@chakra-ui/react';
import FAIcon from '../../../components/FAIcon';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { useDevice } from 'src/DeviceContext';

interface Props {
  title: string;
  icon: IconName;
  selected: boolean;
}

const TabContents = ({ title, icon, selected }: Props): React.ReactElement => {
  const [dataReady, setDataReady] = useState(false);
  const { isMobileDevice } = useDevice();

  useEffect(() => {
    setDataReady(true);
  }, []);

  return (
    <Box textAlign="center" w="100%" py="5px" bg="white" borderBottomRadius={['', 'md']}>
      <Skeleton isLoaded={dataReady}>
        <FAIcon icon={icon} as="div" lineHeight="1em" fontSize={isMobileDevice ? (selected ? 30 : 20) : [20, 24, 32]} />
      </Skeleton>
      <Text
        mt="5px"
        overflow="hidden"
        fontSize={[10, 10, 12, 16]}
        visibility={isMobileDevice && !selected ? 'hidden' : 'visible'}
        style={{ textOverflow: 'ellipsis' }}
      >
        {title}
      </Text>
      <Text color="#2680D0" fontSize="35px" lineHeight="1em" display={isMobileDevice || !selected ? 'none' : 'inline'}>
        •
      </Text>
    </Box>
  );
};

export default TabContents;
