import { Box, Flex, Heading } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import VitalsModal from '../../components/ManageVitalsModal/VitalsModal';
import { defaultVitalsTable, getVitalsTable } from '../../db/vitals';
import VitalsGraphsTabContainer from './vitalsComponents/TabContainer';
import VitalsSidePanel from './vitalsComponents/VitalsSidePanel';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux/store';
import { GraphType } from './vitalsGraphOptions';
import { useModal } from 'src/ModalContext';
import { UserContext } from '../interior/Index';
import { UserRole } from '../user/interfaces/IUser';

export interface IVitalList {
  name: string;
  checked: boolean;
  color: string;
}

export type VitalsModuleCPProps = {
  uid: string | null;
  selectedVital?: GraphType;
};

type Props = VitalsModuleCPProps & PropsFromRedux;

const MODAL_NAME = 'vitals';
const SUPPORTED_GRAPHS: GraphType[] = [
  'participation',
  'sleep',
  'stress',
  'craving',
  'incidents',
  'emotion',
  'watchuse',
];

const VitalsModuleCP: React.FC<Props> = ({
  uid,
  selectedVital,
  enabledGraphs,
  userHasCravingsPredictionEnabled,
}): React.ReactElement => {
  const emotionValueList = [
    { name: 'Anxious', checked: true, color: '#00BAA3' },
    { name: 'Happy', checked: true, color: '#5C4279' },
    { name: 'Relaxed', checked: true, color: '#8F8F8F' },
    { name: 'Sad', checked: true, color: '#00B6FF' },
    { name: 'Stressed', checked: true, color: '#008FBC' },
    { name: 'Tired', checked: true, color: '#C8D5DD' },
    { name: 'Pain', checked: true, color: '#2680D0' },
  ];

  const [emotionItemsVisibility, setEmotionItemsVisibility] = useState(emotionValueList);
  const [selectedGraph, setSelectedGraph] = useState<GraphType>(selectedVital || 'participation');
  const { data, status } = useQuery(['vitalsTable-', uid], async () => getVitalsTable(uid), {
    staleTime: 60 * 60 * 1000,
  });
  const { t } = useTranslation('vitals');
  const [dataReady, setDataReady] = useState(false);

  const { modal, openModal, closeModal } = useModal();
  const user = useContext(UserContext);
  const isCP = user?.role === UserRole.CP;

  const history = useHistory();

  let summaryDataVitalsTable = { ...defaultVitalsTable };
  if (status === 'success' && data !== undefined) {
    summaryDataVitalsTable = data;
  }

  useEffect(() => {
    setDataReady(!enabledGraphs.loading);
  }, [enabledGraphs.loading]);

  const tabTitles: GraphType[] = SUPPORTED_GRAPHS.filter((graph) => {
    const matchingGraph = Object.entries(enabledGraphs).find((enabledGraph) => enabledGraph[0] === graph);

    if (graph === 'craving' && !userHasCravingsPredictionEnabled) {
      return false;
    }

    return matchingGraph ? matchingGraph[1] : false;
  });

  return (
    <Flex w="100%" h="100%" direction="column">
      <Flex direction="row" as="header">
        {uid && (
          <Heading as="h3" fontSize={25} fontWeight="bold" mb="3">
            {t('vitalsModuleCP.moduleHeader')}
          </Heading>
        )}
        <VitalsModal
          isOpen={modal === MODAL_NAME}
          onOpen={() => openModal(MODAL_NAME)}
          onClose={() => {
            closeModal();
            history.push('/home');
          }}
        />
      </Flex>
      <Flex direction={['column', 'column', 'column', 'row', 'row']} gap="5" pb="20px">
        {dataReady ? (
          <Box w={['100%', '100%', '100%', '75%', '80%']}>
            <VitalsGraphsTabContainer
              uid={uid}
              showColor={true}
              showVitals={true}
              tabTitles={tabTitles}
              tabTitleData={summaryDataVitalsTable}
              initialGraphType={selectedVital}
              emotionItemsVisibility={emotionItemsVisibility}
              onSelectedGraphChanged={(selected: GraphType) => {
                setSelectedGraph(selected);
              }}
              isCP={isCP}
            />
          </Box>
        ) : (
          <Box>Loading Data...</Box>
        )}
        <Box w={['100%', '100%', '100%', '25%', '20%']}>
          {selectedGraph !== 'incidents' && (
            <VitalsSidePanel
              emotionItemsVisibility={emotionItemsVisibility}
              selectedGraph={selectedGraph}
              onEmotionVisibilityChanged={(visibility: IVitalList[]) => {
                setEmotionItemsVisibility(visibility);
              }}
            />
          )}
        </Box>
      </Flex>
      <VitalsModal
        isOpen={modal === MODAL_NAME}
        onOpen={() => openModal(MODAL_NAME)}
        onClose={() => {
          closeModal();
        }}
      />
    </Flex>
  );
};

const mapStateToProps = (state: RootState) => {
  const { init, linkedUsers, user } = state;

  return {
    userHasCravingsPredictionEnabled:
      (user.user?.cravingsPredictionEnabled || linkedUsers.selectedLinkedUserFeatures?.cravingsPredictionEnabled) ??
      false,
    enabledGraphs: init.featureFlags.enabledGraphs,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(VitalsModuleCP);
