import React, { useEffect } from 'react';
import { Box, Text, Checkbox, Stack, Flex } from '@chakra-ui/react';
import PageHeading from '../../components/PageHeading';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux/store';
import IAlert from './interfaces/IAlert';
import { useQueryAlerts, KEY_NAME } from './queryHooks';
import { useQueryClient } from 'react-query';
import { PossibleLinkedUser } from '../linked-users/interfaces/ILinkedUser';


const Alerts = ({ selectedLinkedUser }: PropsFromRedux): JSX.Element => {
  const queryClient = useQueryClient();
  const { data: alerts = [] } = useQueryAlerts(selectedLinkedUser as PossibleLinkedUser);

  useEffect(() => {
    if (selectedLinkedUser) {
      queryClient.invalidateQueries([KEY_NAME, selectedLinkedUser?.pir?.id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLinkedUser]);

  if (alerts.length < 1) {
    return (
      <div>
        <h3>You don&apos;t have any alerts.</h3>
      </div>
    );
  }

  return (
    <Box width="40%" borderWidth="3px" height="100%">
      <PageHeading mb={4} mt={2} ml={3}>
        Alerts
      </PageHeading>
      <Stack ml={3} spacing={2} mb={4}>
        {alerts.map((alert: IAlert, index: number) => (
          <Flex key={index} direction="row" as="header" mb={4} align="center">
            <Checkbox colorScheme="purple" mr={1}>
              {alert.type}
            </Checkbox>
            <Text fontSize="xs" color="gray">
              {alert.created.toDateString()}
            </Text>
          </Flex>
        ))}
      </Stack>
    </Box>
  );
};

const mapStateToProps = (state: RootState) => {
  const user = state.user.user;
  if (user === null) {
    throw new Error('No user logged in');
  }

  return {
    user,
    selectedLinkedUser: state.linkedUsers.selectedLinkedUser,
  };
};

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Alerts);
