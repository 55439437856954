import {
  Flex,
  Text,
  Box,
  AccordionButton,
  Divider,
  Accordion,
  AccordionItem,
  AccordionPanel,
  Button,
  HStack,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { filterRequestsByStatusSort } from '../../modules/supportRequests/utils';
import ISupportRequest, { SupportRequestStatus } from '../../db/supportRequests';
import FAIcon from '../FAIcon';
import { useUpdateSupportRequest } from '../../modules/supportRequests/queryHooks';
import { SelectedUserContext } from '../../modules/interior/Index';

interface Props {
  status: SupportRequestStatus;
  requests: ISupportRequest[];
  selectedRequest: ISupportRequest | null;
  onSelect?: (supportRequest: ISupportRequest | null) => void;
}

const SupportRequestsList = (props: Props): React.ReactElement | null => {
  const { t } = useTranslation('support_requests');
  const { selectedUser } = useContext(SelectedUserContext);

  if (!selectedUser) return null;
  // TODO(FP): This should not be conditional
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate: updateStatus } = useUpdateSupportRequest(selectedUser);

  const requests = filterRequestsByStatusSort(props.requests, props.status);

  const ALL_STATUSES = Object.values(SupportRequestStatus).filter((status) => status !== SupportRequestStatus.ALL);

  const selectedIndex = requests.findIndex((request) => request.survey.id === props.selectedRequest?.survey.id);

  return (
    <Box width="500px">
      <Flex fontSize="16px" textAlign="left" py="20px">
        <Text w="120px" textTransform="uppercase" pl="21px" color="#8F8F8F" fontWeight={600}>
          {t('fieldsHeading.dateTime')}
        </Text>
        <Text w="160px" flexGrow={1} textTransform="uppercase" px="10px" color="#8F8F8F" fontWeight={600}>
          {t('fieldsHeading.whatSupportNeeded')}
        </Text>
        <Text w="140px" textTransform="uppercase" px="10px" color="#8F8F8F" fontWeight={600}>
          {t('fieldsHeading.status')}
        </Text>
        <Box w="35px" />
      </Flex>
      <Accordion allowToggle={true} index={selectedIndex === -1 ? [] : [selectedIndex]}>
        {requests.map((request) => (
          <AccordionItem key={request.survey.id} color="purple3.600" bg="purple3.100" borderRadius="lg" mb={3}>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  p={0}
                  m={0}
                  alignItems="stretch"
                  textAlign="left"
                  fontWeight="bold"
                  onClick={() => {
                    if (props.onSelect) {
                      if (props.selectedRequest?.survey.id === request.survey.id) {
                        props.onSelect(null);
                      } else {
                        props.onSelect(request);
                      }
                    }
                  }}
                >
                  <Text
                    w="120px"
                    fontSize="16px"
                    pl="21px"
                    my="17px"
                    flexShrink={0}
                    noOfLines={1}
                    textOverflow="ellipsis"
                  >
                    {request.dateTime?.toLocaleDateString()}
                  </Text>
                  <Text w="140px" flexGrow={1} px="10px" my="17px">
                    {request.whatSupportNeeded || <i>{t('requestsModule.whatSupportNeededDefault')}</i>}
                  </Text>
                  <Text w="140px" px="10px" flexShrink={0} my="17px">
                    {t(`statusDescr.${request.status}`)}
                  </Text>
                  <Flex w="35px" flexShrink={0} justifyContent="center" alignItems="center">
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor="white"
                      width="16px"
                      height="16px"
                      borderRadius="full"
                      boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
                    >
                      <FAIcon
                        icon="chevron-down"
                        color="purple3.600"
                        fontSize="8px"
                        transition="0.3s"
                        transform={isExpanded ? 'rotate(-180deg);' : 'rotate(0deg)'}
                      />
                    </Flex>
                  </Flex>
                </AccordionButton>
                <AccordionPanel px="21px" pt="0">
                  <Divider borderColor="white" orientation="horizontal" />
                  <Text fontSize="16px" fontWeight={400} pt="10px">
                    {request.additionalInfo}
                  </Text>
                  <HStack width="100%" justifyContent="flex-end" spacing={4} mt="20px">
                    {ALL_STATUSES.filter((status) => status !== request.status).map((newStatus) => (
                      <Button
                        colorScheme="purple"
                        variant="outline"
                        height="30px"
                        key={newStatus}
                        onClick={() => updateStatus({ survey: request.survey, newStatus })}
                        cursor="pointer"
                        fontSize="14px"
                        borderRadius="10px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="purple3.600"
                        borderColor="purple3.600"
                      >
                        {t(`requestsModal.changeStatusOptions.${newStatus}`)}
                      </Button>
                    ))}
                  </HStack>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default SupportRequestsList;
