import React from 'react';
import { useRadio, useRadioGroup, HStack, Flex } from '@chakra-ui/react';
import { Box, RadioProps } from '@chakra-ui/react';

interface PropsGroup {
  onChange: (v: string) => void;
  options: [string, string][];
  defaultValue: string;
  value: string;
  name: string;
}

// 1. Create a component that consumes the `useRadio` hook
const PrimaryRadio = (props: RadioProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Flex as="label" alignItems="center">
      <input {...input} />
      <Flex
        cursor="pointer"
        width="26px"
        height="26px"
        border="2px solid #E5E5E5"
        borderRadius="13px"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          width="18px"
          height="18px"
          {...checkbox}
          display="none"
          _checked={{ display: 'block' }}
          backgroundColor="#5C4279"
          borderRadius="9px"
        />
      </Flex>
      <Box ml="12px" color="#626161" fontSize="16px" data-test={(props.name || '') + (props.children || '')}>
        {props.children}
      </Box>
    </Flex>
  );
};

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
const PrimaryRadioGroup = (props: PropsGroup): JSX.Element => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: props.name,
    defaultValue: props.defaultValue,
    value: props.value,
    onChange: props.onChange,
  });

  const group = getRootProps();

  return (
    <HStack spacing="46px" {...group} my={['31px', '34px']}>
      {props.options.map(([value, label]) => {
        const radio = getRadioProps({ value });
        return (
          <PrimaryRadio key={value} {...radio}>
            {label}
          </PrimaryRadio>
        );
      })}
    </HStack>
  );
};

export default PrimaryRadioGroup;
