import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalContent, ModalBody, ModalOverlay, Text, Box, Flex } from '@chakra-ui/react';
import PrimaryButton from '../PrimaryButton';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  onUpdateMessage: string;
}

type Props = SuppliedProps;

const OnUpdateAppointmentModal = ({ isOpen, onClose, onUpdateMessage }: Props): React.ReactElement => {
  const { t } = useTranslation('appointments');
  const { isPhone } = useDevice();
  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          maxWidth={isPhone ? ['90%', '90%', '90%', '90%', '90%'] : '350px'}
          py={6}
          px={6}
          zIndex={1900}
          borderRadius="10px"
        >
          <ModalBody w="100%" p={0}>
            <Flex w="100%" direction="column" justifyContent="space-between" alignItems="center">
              <Text color="#4F4F4F" textAlign="center" fontWeight="bold" fontSize={16} mb={4} lineHeight={'22px'}>
                {onUpdateMessage}
              </Text>
              <PrimaryButton onClick={onClose}>{t('appointmentUpdateModal.okay')}</PrimaryButton>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default OnUpdateAppointmentModal;
