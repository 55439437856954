import {
  VictoryAxis,
  VictoryChart,
  VictoryContainer,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryBar,
  VictoryLine,
} from 'victory';
import {
  formatCravingsDayTicks,
  ICravingPoint,
  getCravingsDataForScatter,
  getCravingsDataForBarChart,
  getCravingsCurrentTimeXCoordinate,
} from '../dataProcessing';
import { Box } from '@chakra-ui/react';
import React from 'react';
import { GraphSizeObj } from '../interfaces/IVitalsGraph';
import { ReducableGraphType } from './VitalsGraphs';
import { useWindowWidth } from 'src/hooks/utils';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  axisLabelPadding: number;
  data: ICravingPoint[];
  fillElementId: string;
  graphPadding: number;
  graphSize: GraphSizeObj;
  graphType: ReducableGraphType;
  levelAxisProps: {
    tickValues: number[];
    tickFormat: (tick: number) => number | string;
  };
  levelTickValues: number[];
  numberOfDaysDisplayed: number;
  timeTickValues: number[];
}

const CravingsGraph = ({
  data,
  graphPadding,
  graphSize,
  levelAxisProps,
  levelTickValues,
  timeTickValues,
}: SuppliedProps): React.ReactElement => {
  const currentMinutes = new Date().getMinutes();
  const currentHour = new Date().getHours();
  const currentTime = currentHour + currentMinutes / 60;
  const isLandscape = window.innerWidth > window.innerHeight;
  const { isPhone } = useDevice();

  const windowWidth = useWindowWidth();

  const xCoordinate = getCravingsCurrentTimeXCoordinate(timeTickValues[timeTickValues.length - 1]);
  const dataForScatter = getCravingsDataForScatter(data);
  const dataForBarChart = getCravingsDataForBarChart(data);

  return (
    <VictoryChart
      theme={VictoryTheme.grayscale}
      width={graphSize.width}
      height={isLandscape && isPhone ? graphSize.height * 1.5 : graphSize.height}
      padding={{ top: 5, left: 10, bottom: 60, right: graphPadding }}
      domainPadding={{ y: 10 }}
      minDomain={{ y: levelTickValues[0] }}
      domain={{
        x: [timeTickValues[0] - 0.5, timeTickValues[timeTickValues.length - 1] + 0.5],
      }}
      containerComponent={
        <VictoryContainer
          style={
            !isPhone
              ? { backgroundColor: 'white' }
              : {
                  pointerEvents: 'auto',
                  userSelect: 'auto',
                  touchAction: 'auto',
                  backgroundColor: 'white',
                }
          }
        />
      }
    >
      <VictoryBar
        cornerRadius={{
          top: ({ datum }) =>
            (datum.y0 - datum.y < 3 && datum.y === 0) || datum.bottomOverlaps === true ? 0 : isPhone ? 6 : 8,
          bottom: ({ datum }) =>
            (datum.y0 - datum.y < 3 && datum.y0 === 24) || datum.topOverlaps === true ? 0 : isPhone ? 6 : 8,
        }}
        alignment="middle"
        barRatio={isPhone ? 1.5 : 8}
        style={{
          data: {
            fill: ({ datum }) => (datum.c === true ? '#F9A4A4' : '#A5EBC2'),
            fillOpacity: 1,
            stroke: 'none',
            width: windowWidth / 14,
          },
        }}
        data={dataForBarChart}
      />

      <VictoryAxis
        label="TIME"
        dependentAxis={true}
        orientation="right"
        style={{
          axis: { stroke: '#756f6a' },
          axisLabel: {
            fontSize: 15,
            fontFamily: 'Public Sans',
            fill: '#626161',
            angle: 90,
            padding: isPhone ? 50 : 70,
          },
          tickLabels: {
            fontSize: 15,
            fontFamily: 'Public Sans',
            padding: 5,
            fill: '#626161',
          },
          grid: {
            stroke: '#9B9A9A',
            strokeWidth: 1,
            strokeDasharray: '4, 4',
          },
        }}
        {...levelAxisProps}
      />
      <VictoryAxis
        label="DAY"
        style={{
          grid: { stroke: '#9B9A9A', strokeDasharray: '4, 4' },
          axis: { stroke: '#756f6a' },
          axisLabel: {
            fontFamily: 'Public Sans',
            fontSize: 15,
            fill: '#626161',
          },
          tickLabels: {
            fontSize: 15,
            fontFamily: 'Public Sans',
            padding: 5,
            fill: '#626161',
          },
          ticks: {
            stroke: '#CCCCCC',
            size: 2,
            strokeWidth: 1,
          },
        }}
        tickValues={timeTickValues}
        tickFormat={(t) => formatCravingsDayTicks(t)}
      />

      {/* current time purple line -- if currentHour is after 6pm, predictions will go into the next day, and so the current time will be in a different spot on the graph*/}
      <VictoryLine
        data={[
          {
            x: currentHour > 18 ? xCoordinate - 1 : xCoordinate,
            y: currentTime,
          },
          {
            x:
              currentHour > 18
                ? timeTickValues[timeTickValues.length - 1]
                : timeTickValues[timeTickValues.length - 1] + 0.5,
            y: currentTime,
          },
        ]}
        style={{ data: { stroke: '#5C4279' } }}
      />
      <VictoryScatter
        data={[
          {
            x: currentHour > 18 ? xCoordinate - 1 : xCoordinate,
            y: currentTime,
          },
        ]}
        style={{ data: { fill: '#5C4279' } }}
        size={isPhone ? 4 : 6}
      />

      <VictoryScatter
        data={dataForScatter}
        size={isPhone ? 3.5 : 5}
        style={{
          data: {
            fill: 'white',
            stroke: ({ datum }) => (datum.c === true ? '#F02F2F' : '#27AE5F'),
            strokeWidth: isPhone ? 1.5 : 3,
          },
          labels: {
            fill: '#626161',
          },
        }}
        labelComponent={
          !isPhone ? (
            <VictoryTooltip
              cornerRadius={0}
              flyoutStyle={{ fill: '#583f73', strokeWidth: 0 }}
              pointerLength={4}
              pointerWidth={4}
              style={{ fontSize: 25, fill: 'white' }}
            />
          ) : (
            <Box />
          )
        }
      />
    </VictoryChart>
  );
};

export default CravingsGraph;
