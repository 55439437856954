import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  Box,
  Text,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { useDevice } from 'src/DeviceContext';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

const HelpMenu = (props: Props): JSX.Element => {
  const { t } = useTranslation('menu');
  const { isPhone } = useDevice();

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent
        zIndex={2100}
        maxWidth={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : ['100%', '98%', '90%', '85%', '75%']}
        p={[2, 2, 4, 8]}
      >
        <ModalHeader fontWeight="light" color="purple3.600" fontSize={24} px={[1, 1, 6]}>
          {t('helpMenu.header')}
        </ModalHeader>
        <Box display="flex" sx={{ userSelect: 'text' }} px={[1, 1, 6]} mb={5}>
          <Text>
            {t('helpMenu.instruct1')}
            <ChakraLink href="mailto:support@behaivior.com" isExternal>
              support@behaivior.com
            </ChakraLink>
            {t('helpMenu.instruct2')}
          </Text>
        </Box>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default HelpMenu;
