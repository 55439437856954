import * as Sentry from '@sentry/react';
import type { History } from 'history';
import { UseDisclosureType } from '../../hooks/utils';

type PushPayload = {
  'google.message_id': string;
  'google.sent_time': number;
  gcm: Record<string, any>;
  aps: Record<string, any>;
  routeName: string;
};

type OnMessageCallback = (payload: Record<string, unknown>) => void;

const handleAction = (
  history: History,
  openModal: (modalName: string) => void,
  notificationType: string | null,
  setNotificationType: React.Dispatch<React.SetStateAction<string | null>>,
  modalDisclosure: UseDisclosureType,
): OnMessageCallback => {
  return (payload) => {
    const pushPayload = payload as PushPayload;

    const routeName = pushPayload.routeName ?? '';
    switch (routeName) {
      case 'MEDITATIONS': {
        setNotificationType('meditations');
        history.push('/home');
        openModal('meditations');
        break;
      }
      case 'CRAVING': {
        modalDisclosure.onOpen();
        break;
      }
      case 'APPOINTMENTS': {
        setNotificationType('appointments');
        history.push('/home');
        openModal('appointments');
        break;
      }
      default: {
        history.push('/home');
      }
    }
  };
};

const logError = (error: string): void => {
  Sentry.captureException(new Error(`Deep link on new push notification failed: ${error}`));
};

let activityInProgress = false;
const notificationQueue: Record<string, unknown>[] = [];

export const setActivityInProgress = (active: boolean) => {
  activityInProgress = active;
};

const handleNotification = (
  payload: Record<string, unknown>,
  history: History,
  openModal: (modalName: string) => void,
  notificationType: string | null,
  setNotificationType: React.Dispatch<React.SetStateAction<string | null>>,
  modalDisclosure: UseDisclosureType,
) => {
  if (
    activityInProgress &&
    (payload.routeName === 'MEDITATIONS' || payload.routeName === 'HOME' || payload.routeName === 'APPOINTMENTS')
  ) {
    notificationQueue.push(payload);
  } else {
    handleAction(history, openModal, notificationType, setNotificationType, modalDisclosure)(payload);
  }
};

export const processNotificationQueue = (
  history: History,
  openModal: (modalName: string) => void,
  notificationType: string | null,
  setNotificationType: React.Dispatch<React.SetStateAction<string | null>>,
  modalDisclosure: UseDisclosureType,
) => {
  for (const payload of notificationQueue) {
    handleAction(history, openModal, notificationType, setNotificationType, modalDisclosure)(payload);
  }
  notificationQueue.length = 0;
};

export const initFirebaseMessagingDeepLinking = (
  history: History,
  openModal: (modalName: string) => void,
  modalDisclosure: UseDisclosureType,
  isMobileDeviceAndCordova: boolean,
  notificationType: string | null,
  setNotificationType: React.Dispatch<React.SetStateAction<string | null>>,
): void => {
  if (isMobileDeviceAndCordova) {
    cordova.plugins.firebase.messaging.onMessage(
      (payload) =>
        handleNotification(payload, history, openModal, notificationType, setNotificationType, modalDisclosure),
      logError,
    );
    cordova.plugins.firebase.messaging.onBackgroundMessage(
      handleAction(history, openModal, notificationType, setNotificationType, modalDisclosure),
      logError,
    );
  }
};
