import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './MedCal.css';
import IMedication, { IMedCalArgs } from '../../modules/medications/interfaces/IMedication';
import { formatStreak } from '../../components/ManageMedicationModal/MedicationDisplay';
import PrimaryButton from '../../../src/components/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import addDays from 'date-fns/addDays';
import { isSameDay } from 'date-fns';
import isAfter from 'date-fns/isAfter';
import { getToDos, updateToDo } from '../../modules/todo/actions';
import { useAddToMedCal } from '../../modules/medications/queryHooks';

interface MedCalProps {
  dayColorTracker: Date[] | undefined;
  medication: IMedication;
  hasDoneToday: boolean;
  todayDate: Date;
  isUserPir: boolean;
  completedDays: number[];
}

const MedCal = ({
  todos,
  medication,
  dayColorTracker,
  hasDoneToday,
  todayDate,
  isUserPir,
  completedDays,
  updateToDo,
  pir,
}: MedCalProps & PropsFromRedux): React.ReactElement => {
  //state
  const [selectedDays, setSelectedDays] = useState<Date[]>([...(dayColorTracker || [])]);
  const [markedToday, setMarkedToday] = useState(hasDoneToday);
  const [completedList, setCompletedList] = useState(completedDays);
  const selectedTodo = useSelector((state: RootState) => state.todo.selectedToDo);

  const { mutate: addToMedCal } = useAddToMedCal(pir?.id);

  //util functions
  const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const { t } = useTranslation('medications');
  //assigns a number to each of the days the user selects on the addMedication Form

  const assignsNumberToDay: number[] | undefined =
    medication.daysOfWeek &&
    medication.daysOfWeek.map((day) => {
      return WEEKDAYS.indexOf(day);
    });

  // const disabledDays = {
  //   before: todayDate
  // };

  const triggerTodo = () => {
    if (todos && selectedTodo) {
      if (!selectedTodo.isCompleted && selectedTodo.history === selectedTodo.type + '/' + medication.id) {
        selectedTodo.completedDateTime = new Date();
        selectedTodo.isCompleted = true;
        updateToDo(selectedTodo, todos);
      }
    } else if (todos && !selectedTodo) {
      todos.forEach((todo) => {
        if (todo.history === todo.type + '/' + medication.id) {
          todo.completedDateTime = new Date();
          todo.isCompleted = true;
          updateToDo(todo, todos);
        }
      });
    }
  };

  const markTodayAsDone = () => {
    if (isUserPir) {
      const beginOfToday = new Date(todayDate);
      beginOfToday.setHours(0, 0, 0, 0);
      if (!completedList.includes(beginOfToday.getTime())) {
        setCompletedList([...completedList, beginOfToday.getTime()]);
      }

      if (medication.frequency === 'Select Days') {
        const dayNumber = todayDate.getDay();

        if (assignsNumberToDay && !assignsNumberToDay.includes(dayNumber)) {
          return;
        }

        if (selectedDays.length === 0) {
          setSelectedDays([todayDate]);
        } else {
          //gets last item in array
          if (assignsNumberToDay) {
            const lastArrayItem = selectedDays[selectedDays.length - 1];

            //finds index in the assigns number to day
            const indexInAssignsNumberToDay = assignsNumberToDay.indexOf(lastArrayItem.getDay());

            //increments index for [ie. 1,3,5] unless its the last index
            const nextIndexInAssignsNumberToDay =
              indexInAssignsNumberToDay === assignsNumberToDay.length - 1 ? 0 : indexInAssignsNumberToDay + 1;

            //determines how many days we have to add
            //if we have to go to the following week, we add 7
            //if its within the week, we subtract the dayNumbers

            const diffInDays =
              nextIndexInAssignsNumberToDay === 0
                ? assignsNumberToDay[nextIndexInAssignsNumberToDay] + 7 - assignsNumberToDay[indexInAssignsNumberToDay]
                : assignsNumberToDay[nextIndexInAssignsNumberToDay] - assignsNumberToDay[indexInAssignsNumberToDay];

            const daySelectPlus = addDays(selectedDays[selectedDays.length - 1], diffInDays);

            if (isSameDay(todayDate, daySelectPlus)) {
              setSelectedDays([...selectedDays, todayDate]);
            } else {
              setSelectedDays([todayDate]);
            }
          }
        }
      }

      if (medication.frequency === 'Daily') {
        if (selectedDays.length === 0) {
          setSelectedDays([todayDate]);
        } else {
          const dayPlus = addDays(selectedDays[selectedDays.length - 1], 1);

          if (isSameDay(todayDate, dayPlus)) {
            setSelectedDays([...selectedDays, todayDate]);
          } else {
            setSelectedDays([todayDate]);
          }
        }
      }

      if (medication.frequency === 'Weekly') {
        if (selectedDays.length === 0) {
          setSelectedDays([...selectedDays, todayDate]);
        } else {
          const weekPlus = addWeeks(selectedDays[selectedDays.length - 1], 1);

          if (isSameDay(todayDate, weekPlus)) {
            setSelectedDays([...selectedDays, todayDate]);
          } else if (isAfter(todayDate, weekPlus)) {
            setSelectedDays([todayDate]);
          }
        }
      }

      if (medication.frequency === 'Monthly') {
        if (selectedDays.length === 0) {
          setSelectedDays([...selectedDays, todayDate]);
        } else {
          const monthPlus = addMonths(selectedDays[selectedDays.length - 1], 1);

          if (isSameDay(todayDate, monthPlus)) {
            setSelectedDays([...selectedDays, todayDate]);
          } else if (isAfter(todayDate, monthPlus)) {
            setSelectedDays([todayDate]);
          }
        }
      }
    }

    setMarkedToday(true);
    triggerTodo();
  };

  let goalTrackDisplay = null;

  const daysCompletedAfterStartDate = medication.startDate
    ? selectedDays.filter((day) => {
        return medication.startDate === undefined || day >= medication.startDate;
      })
    : selectedDays;
  const daysCompleted = Number(daysCompletedAfterStartDate.length);
  const streakDays = Number(medication.streak);
  const daysLeftInStreak = streakDays - daysCompleted;

  if (daysCompleted === 0) {
    const message = 'There are no selected days for your goal. Click today to add it to your streak goal.';
    goalTrackDisplay = t('noDays', { message });
  } else {
    const youDidIt = 'Congratulations! You achieved your goal!';
    goalTrackDisplay = t('youDidIt', { youDidIt });

    if (medication.streak && daysCompleted < medication.streak) {
      const format = formatStreak(medication.frequency);
      const formatPlural = daysLeftInStreak > 1 ? `${format}s` : format;
      const keepGoing = t('keepGoing', {
        daysCompleted,
        streak: medication.streak,
        format: formatPlural,
        nickname: medication.nickname,
        daysLeftInStreak,
      });
      goalTrackDisplay = keepGoing;
    }
  }
  useEffect(() => {
    const newMedValues: IMedCalArgs = { completedDays: completedList, goalDaysTracked: selectedDays };
    addToMedCal({ medication, newMedValues });
  }, [addToMedCal, selectedDays, dayColorTracker]);

  useEffect(() => {
    const newMedValues: IMedCalArgs = { completedDays: completedList };
    addToMedCal({ medication, newMedValues });
  }, [completedList]);

  return (
    <>
      <DayPicker selectedDays={completedList.map((date) => new Date(date))} />
      <>
        <PrimaryButton
          onClick={markTodayAsDone}
          mt={3}
          mb={3}
          alignItems="center"
          isDisabled={markedToday || !isUserPir}
        >
          {t('medCal.dailyMedButton')}
        </PrimaryButton>
        {medication.startDate && todayDate >= medication.startDate && <Text>{goalTrackDisplay}</Text>}
      </>
    </>
  );
};

function mapStateToProps(state: RootState) {
  const { selectedLinkedUser } = state.linkedUsers;
  const { todos } = state.todo;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }
  return {
    todos,
    pir: selectedLinkedUser.pir,
  };
}

const mapDispatchToProps = {
  getToDos,
  updateToDo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(MedCal);
