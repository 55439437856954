import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { getHowMeasure } from '../VitalsMeasure';

const VitalsLegendPanel = (props: { selectedGraph: string }): React.ReactElement => {
  const { t } = useTranslation('vitals');

  const graphName = props.selectedGraph.charAt(0).toUpperCase() + props.selectedGraph.slice(1);
  return (
    <Box>
      <Stack spacing={4} color="#8F8F8F">
        <Text>
          <GraphLineImg />
          {graphName}
        </Text>
        {getHowMeasure(t, props.selectedGraph).map((m: any) => {
          return props.selectedGraph === 'craving' && m.cravingPredicted === 'true' ? (
            <Text key={m.level}>
              <CravingGraphScatterImgSquare color={m.color} />
              {m.level}
            </Text>
          ) : props.selectedGraph === 'craving' ? (
            <Text key={m.level}>
              <CravingGraphScatterImg color={m.color} />
              {m.level}
            </Text>
          ) : (
            <Text key={m.level}>
              <GraphScatterImg color={m.color} />
              {m.level}
            </Text>
          );
        })}
        {props.selectedGraph === 'craving' && (
          <Text>
            <CurrentTimeImg />
            Current day and time
          </Text>
        )}
      </Stack>
    </Box>
  );
};

export default VitalsLegendPanel;

const GraphLineImg = styled.span`
  width: 1.5em;
  height: 3px;
  text-align: center;
  margin: 0 0.6em 0.2em 0.1em;
  display: inline-block;
  background: black;
`;

const GraphScatterImg = styled.span`
  width: 0.8em;
  height: 0.8em;
  text-align: center;
  line-height: 2em;
  border-radius: 1em;
  margin: 0 1em 0.2em 0.5em;
  display: inline-block;
  color: white;
  position: relative;
  border: 3px solid;
  border-color: ${(props) => props.color};

  &::before {
    content: '';
    position: absolute;
    top: 0.3em;
    left: -0.6em;
    width: 1.6em;
    height: 0.8em;
    background: ${(props) => props.color};
    border-radius: 0.4em 0.4em 0 0;
    opacity: 0.5;
  }
`;

const CurrentTimeImg = styled.span`
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #5c4279;
  border-radius: 50%;
  margin-right: 3.5em;

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 50px;
    background-color: #5c4279;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
  }
`;

// for future use when we add squares to other graphs
// const GraphScatterImgSquare = styled.span`
//   width: 0.8em;
//   height: 0.8em;
//   text-align: center;
//   line-height: 2em;
//   border-radius: 0;
//   margin: 0 1em 0.2em 0.5em;
//   display: inline-block;
//   color: white;
//   position: relative;
//   border: 3px solid;
//   border-color: ${(props) => props.color};

//   &::before {
//     content: '';
//     position: absolute;
//     top: 0.3em;
//     left: -0.6em;
//     width: 1.6em;
//     height: 0.8em;
//     background: ${(props) => props.color};
//     border-radius: 0.4em 0.4em 0 0;
//     opacity: 0.5;
//   }
// `;

const CravingGraphScatterImgSquare = styled.span`
  width: 0.6em;
  height: 0.6em;
  text-align: center;
  line-height: 2em;
  border-radius: 0;
  margin: 0 1em 0.2em 0.5em;
  display: inline-block;
  color: white;
  position: relative;
  border: 2.5px solid;
  border-color: ${(props) => props.color};
  vertical-align: bottom;

  &::before {
    content: '';
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: 1.6em;
    height: 0.8em;
    background: ${(props) => props.color};
    border-radius: 0 0 0.4em 0.4em;
    opacity: 0.5;
    z-index: 0;
    transform: translate(-50%, 0);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 1;
  }
`;

const CravingGraphScatterImg = styled.span`
  width: 0.65em;
  height: 0.6em;
  text-align: center;
  line-height: 2em;
  border-radius: 1em;
  margin: 0 1em 0.2em 0.5em;
  display: inline-block;
  color: white;
  position: relative;
  border: 2.5px solid;
  border-color: ${(props) => props.color};
  vertical-align: bottom;

  &::before {
    content: '';
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: 1.6em;
    height: 0.8em;
    background: ${(props) => props.color};
    border-radius: 0 0 0.4em 0.4em;
    opacity: 0.5;
    z-index: 0;
    transform: translate(-50%, 0);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    border-radius: 50%; // Adjust this line
    z-index: 1;
  }
`;
