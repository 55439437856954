import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Flex,
  Text,
  Box,
} from '@chakra-ui/react';
import FAIcon from '../FAIcon';
import ResourceListDisplay from './ResourceListDisplay';
import AddResourceForm from './AddResourceForm';
import { IResource } from '../../db/resources';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
}

type Props = SuppliedProps;

const ResourceModal = (props: Props): React.ReactElement => {
  const { t } = useTranslation('resources');
  const { isOpen, onClose } = props;
  const [selectedResource, setSelectedResource] = useState<IResource | null>(null);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const { isPhone } = useDevice();

  const handleModalClose = () => {
    if (!formIsDirty || window.confirm(t('manageResourceModal.modalCloseConfirmation'))) {
      setSelectedResource(null);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose} scrollBehavior="inside" isCentered={true}>
      <ModalOverlay />
      <ModalContent
        maxWidth="700px"
        minHeight="90%"
        marginBottom={isPhone ? [0, 0, 0] : undefined}
        marginTop={isPhone ? [0, 0, 0] : undefined}
        p={[2, 2, 4, 8]}
        zIndex={1900}
      >
        <ModalHeader fontWeight="light" color="purple3.600" fontSize={24} textAlign="center" px={[1, 1, 6]}>
          <FAIcon icon="spa" mr={4} />
          {t('resourceModal.header')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w="50%" mb={5}>
            <Text fontSize={18} mb={5}>
              {t('resourceModal.subheader')}
            </Text>
          </Box>
          <Flex direction="column" alignItems="left">
            <ResourceListDisplay
              onSelectResource={(resource) => {
                setSelectedResource(resource);
              }}
            />
            <AddResourceForm
              selectedResource={selectedResource}
              onSelectResource={(resource) => {
                setSelectedResource(resource);
              }}
              setFormIsDirty={setFormIsDirty}
              setSelectedResource={setSelectedResource}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ResourceModal;
