import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Flex,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { createCraving } from './actions';
import { RootState } from '../../redux/store';
import { isPirLinkedUser } from '../linked-users/utils';
import ICraving from './interfaces/ICraving';
import { useDevice } from 'src/DeviceContext';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

interface FormValues {
  actionTaken: string;
}

const CravingAlert = ({
  isOpen,
  onClose,
  isPir,
  createCraving,
  selectedLinkedUser,
}: // cravingStatus
Props & PropsFromRedux): JSX.Element => {
  const cancelRef = React.createRef<HTMLElement>();
  const toast = useToast();
  const { t } = useTranslation('common');
  const { isPhone } = useDevice();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      actionTaken: 'Nothing',
    },
  });

  const onSubmit = (values: FormValues): void => {
    if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
      return;
    }

    const craving: ICraving = {
      pir: selectedLinkedUser.pir,
      dateTime: new Date(),
      actionTaken: values.actionTaken,
    };

    createCraving(craving)
      .then(() => {
        // throw 'hello';
        toast({
          title: t('cravingAlert.successToast.title'),
          description: t('cravingAlert.successToast.description'),
          status: 'success',
          duration: 10000,
          isClosable: true,
        });

        onClose();
      })
      .catch((e) => {
        console.error(e);

        toast({
          title: t('cravingAlert.errorToast.title'),
          description: t('cravingAlert.errorToast.description'),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay />
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <AlertDialogContent
          maxWidth={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : ['100%', '98%', '90%', '85%', '75%']}
          minHeight={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : undefined}
          marginBottom={isPhone ? [0, 0, 0] : undefined}
          marginTop={isPhone ? [0, 0, 0] : undefined}
        >
          <AlertDialogHeader color="purple3.600" fontWeight="light" fontSize={24}>
            {t('cravingAlert.header')}
          </AlertDialogHeader>
          <AlertDialogBody>
            {isPir && (
              <>
                <Text>{t('cravingAlert.description')}</Text>
                <Flex flexDirection="column" py={5}>
                  <Text>Action Taken</Text>
                  <Select borderColor="black" borderRadius={2} {...register('actionTaken')}>
                    <option value="Meditation">Meditation</option>
                    <option value="Contacted Support">Contacted Support</option>
                    <option value="Used Resource">Used Resource</option>
                    <option value="Nothing">Nothing</option>
                  </Select>
                </Flex>
              </>
            )}
          </AlertDialogBody>
          <AlertDialogFooter display={['block', 'block', 'flex']}>
            <Button
              ref={cancelRef as any}
              onClick={onClose}
              fontWeight="normal"
              display={['block', 'block', 'inherit']}
              w={['100%', '100%', 'auto']}
            >
              {t('cravingAlert.cancelButton')}
            </Button>
            <Button
              type="submit"
              backgroundColor="blue.300"
              // isLoading={loading} // can add form submission loading state
              _hover={{ backgroundColor: 'blue.200' }}
              color="white"
              display={['block', 'block', 'inherit']}
              w={['100%', '100%', 'auto']}
              mt={[3, 3, 0]}
              ml={[0, 0, 4]}
            >
              {t('cravingAlert.logCraving')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </form>
    </AlertDialog>
  );
};

const mapStateToProps = (state: RootState) => {
  const selectedLinkedUser = state.linkedUsers.selectedLinkedUser;
  const craving = state.craving;

  return {
    cravingStatus: craving,
    isPir: selectedLinkedUser !== null && isPirLinkedUser(selectedLinkedUser),
    selectedLinkedUser,
  };
};

const mapDispatchToProps = {
  createCraving,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CravingAlert);
