import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Image,
  Text,
  Textarea,
  Input,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Flex,
} from '@chakra-ui/react';
import AddMediaFile from '../AddMediaFile';
import AddWebLink from '../AddWebLink';
import urlPattern from '../../constants/urlPattern';
import { Center } from '@chakra-ui/layout';
import AddMediaFileMobile from '../AddMediaFileMobile';
import { IResource } from '../../db/resources';
import { MediaFormValues } from '../../utils/utils';
import { useCreateResources, useUpdateResources } from '../../modules/resources/queryHooks';
import { createInteraction } from '../../modules/interactions/actions';
import { moduleName, interactionType } from '../../modules/interactions/constants';
import FAIcon from '../FAIcon';
import { RootState } from '../../redux/store';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import TertiaryButton from '../TertiaryButton';
import { getAttachmentType } from 'src/modules/meditations/utils';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  selectedResource: IResource | null;
  onSelectResource(resource: IResource | null): void;
  setFormIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedResource: React.Dispatch<React.SetStateAction<IResource | null>>;
}

type Props = SuppliedProps;

const AddResourceForm = ({
  pir,
  createInteraction,
  selectedResource,
  onSelectResource,
  setSelectedResource,
  setFormIsDirty,
}: Props & PropsFromRedux): React.ReactElement => {
  // Define T for when translations is used
  const { t } = useTranslation('resources');
  const { isMobileDeviceOrCordova } = useDevice();

  const { register, reset, handleSubmit, getValues, formState, setValue, trigger, clearErrors } =
    useForm<MediaFormValues>({
      defaultValues: {
        title: selectedResource?.title,
        note: selectedResource?.note,
        link: selectedResource?.link,
        attachmentUrl: selectedResource?.attachmentUrl,
        attachmentName: selectedResource?.attachmentName,
      },
    });

  const { mutate: createResource, isLoading: resourceCreated } = useCreateResources(pir?.id);
  const { mutate: updateResource, isLoading: resourceUpdating } = useUpdateResources(pir?.id);

  const [showAddButton, setShowAddButton] = useState(true);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [resourceUploading, setResourceUploading] = useState(false);
  const [currentUpload, setCurrentUpload] = useState<string | null>(null);
  const [saveCurrentUpload, setSaveCurrentUpload] = useState(false);
  const [currentAttachmentUrl, setCurrentAttachmentUrl] = useState<string>();
  const [currentLink, setCurrentLink] = useState<string | null>(null);
  const [saveCurrentLink, setSaveCurrentLink] = useState(false);
  const [uploadChosen, setUploadChosen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const scrollToForm = () => formRef.current?.scrollIntoView();

  const { errors, dirtyFields } = formState;

  const numberOfDirtyFields = Object.keys(dirtyFields).filter((key): key is keyof typeof dirtyFields => {
    return dirtyFields[key as keyof typeof dirtyFields] === true;
  }).length;

  const handleUploading = (data: boolean) => {
    setResourceUploading(data);
  };

  const resetButtonStates = () => {
    setShowAddButton(!showAddButton);
    setShowSaveButton(!showSaveButton);
    setCurrentUpload(null);
    setSaveCurrentUpload(false);
    setSaveCurrentLink(false);
    setCurrentLink(null);
    setUploadChosen(false);
    setFormIsDirty(false);
    reset({
      title: '',
      note: '',
      link: '',
      attachmentUrl: '',
      attachmentName: '',
    });
  };

  const onCancel = () => {
    resetButtonStates();
    setSelectedResource(null);
  };

  const displaySaveButton = () => {
    const content = showSaveButton ? t('addResourceForm.saveButton') : t('addResourceForm.saveButtonConfirm');

    const icon = !showSaveButton && <FAIcon icon="check" ml={4} fontSize={14} />;

    return (
      <FormControl isRequired={true}>
        <Flex justifyContent="space-between">
          <TertiaryButton
            isDisabled={!showSaveButton || resourceUploading}
            mt={10}
            onClick={onCancel}
            isLoading={formState.isSubmitting}
            data-test="cancel-resource"
          >
            {t('manageResourceModal.modalCancel')}
          </TertiaryButton>
          <PrimaryButton
            isDisabled={!showSaveButton || resourceUploading}
            mt={10}
            isLoading={formState.isSubmitting}
            type="submit"
            data-test="save-resource"
          >
            {content}
            {icon}
          </PrimaryButton>
        </Flex>
      </FormControl>
    );
  };

  const saveResource = async (resource: MediaFormValues): Promise<void> => {
    const fullWebLink =
      resource.link === undefined || resource.link === ''
        ? undefined
        : resource.link.startsWith('https://') || resource.link.startsWith('http://')
        ? resource.link
        : `https://${resource.link}`;

    if (selectedResource?.id) {
      await updateResource({
        ...selectedResource,
        title: resource.title as string,
        note: resource.note,
        link: fullWebLink,
        attachmentUrl: resource.attachmentUrl,
        attachmentName: resource.attachmentName,
      });
      onSelectResource(null);
    } else {
      await createResource({
        pir,
        title: resource.title as string,
        note: resource.note,
        link: fullWebLink,
        attachmentUrl: resource.attachmentUrl,
        attachmentName: resource.attachmentName,
      });
    }
    resetButtonStates();
    await createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.RESOURCES,
      interactionType: interactionType.RESOURCES.SAVED,
    });
  };

  const addModifiedFieldInteraction = () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.RESOURCES,
      interactionType: interactionType.RESOURCES.MODIFIED_FIELD,
    });
  };

  useEffect(() => {
    if (saveCurrentLink && selectedResource?.link) {
      setValue('link', selectedResource?.link);
      clearErrors();
    } else {
      setValue('link', '');
    }
    // so users don't think they've saved removal of links without saving
    if (!saveCurrentLink && currentLink) {
      setFormIsDirty(true);
    }
  }, [saveCurrentLink]);

  useEffect(() => {
    if (saveCurrentUpload && selectedResource?.attachmentName && selectedResource?.attachmentUrl) {
      setValue('attachmentName', selectedResource.attachmentName);
      setValue('attachmentUrl', selectedResource.attachmentUrl);
      clearErrors();
    } else {
      setValue('attachmentName', '');
      setValue('attachmentUrl', '');
    }
    // so users don't think they've saved removal of uploads without saving
    if (!saveCurrentUpload && currentUpload) {
      setFormIsDirty(true);
    }
  }, [saveCurrentUpload]);

  useEffect(() => {
    if (!resourceCreated && !resourceUpdating) {
      showSaveButton && setShowSaveButton(false);
      !showAddButton && setShowAddButton(true);
    }
  }, [resourceCreated, resourceUpdating]);

  useEffect(() => {
    // adding / changing upload doesn't dirty fields, and uploadChosen is only set if a user uploads something new or removes a saved upload
    if (numberOfDirtyFields > 0 || uploadChosen) {
      setFormIsDirty(true);
    } else {
      setFormIsDirty(false);
    }
  }, [numberOfDirtyFields, uploadChosen]);

  useEffect(() => {
    if (selectedResource) {
      setShowAddButton(false);
      setShowSaveButton(true);
      reset(selectedResource);
      scrollToForm();
      if (selectedResource.attachmentName) {
        setCurrentUpload(selectedResource.attachmentName);
        setSaveCurrentUpload(true);
      }
      if (selectedResource.attachmentUrl) {
        setCurrentAttachmentUrl(selectedResource.attachmentUrl);
      }
      if (selectedResource.link) {
        setCurrentLink(selectedResource.link);
        setSaveCurrentLink(true);
      }
    }
  }, [selectedResource, reset]);

  useEffect(() => {
    scrollToForm();
  }, [showAddButton]);

  useEffect(() => {
    return () => {
      setCurrentUpload(null);
      setCurrentLink(null);
      setShowAddButton(true);
      setShowSaveButton(false);
      setUploadChosen(false);
      reset({
        title: '',
        note: '',
        link: '',
        attachmentUrl: '',
        attachmentName: '',
      });
    };
  }, []);

  const undoUpload = () => {
    setUploadChosen(false);
    setValue('attachmentUrl', '');
    setValue('attachmentName', '');
  };

  const displayMediaUploader = () => {
    // Conditional for if we were on mobile
    if (isMobileDeviceOrCordova) {
      return (
        <>
          <AddMediaFileMobile
            fileName="attachmentName"
            setUploadChosen={setUploadChosen}
            uploadChosen={uploadChosen}
            urlName="attachmentUrl"
            trigger={trigger}
            setValue={setValue}
            register={register}
            changeUploadState={handleUploading}
            accept="image/png, image/gif, application/msword, image/gif, image/jpeg, audio/mpeg, video/mpeg, application/pdf"
            multipleFiles={false}
            validation={{
              validate: (value: string) => {
                let error = '';
                const attachment = getValues('attachmentUrl');
                const notes = getValues('note');

                const checkNote = notes === undefined || notes === '';
                const checkAttachment = attachment === undefined || attachment === '';

                // If note is defined then we do skip check attachment
                if (checkNote) {
                  if (checkAttachment) {
                    error = t('addResourceForm.errors.linkRequired');
                    return error;
                  }
                }

                const re = new RegExp(urlPattern);
                if (value && !re.test(value)) {
                  error = t('addResourceForm.errors.urlFormat');
                  return error;
                }
              },
            }}
            interactionCallback={addModifiedFieldInteraction}
          />
          {uploadChosen && (
            <SecondaryButton onClick={undoUpload} mt={5} fontSize={14}>
              {t('addResourceForm.undoAddUpload')}
            </SecondaryButton>
          )}
        </>
      );
    }

    return (
      <>
        <AddMediaFile
          fileName="attachmentName"
          setUploadChosen={setUploadChosen}
          uploadChosen={uploadChosen}
          urlName="attachmentUrl"
          setValue={setValue}
          displayFile={''}
          trigger={trigger}
          register={register}
          validation={{
            validate: (value: string) => {
              let error = '';
              const attachment = getValues('attachmentUrl');
              const notes = getValues('note');

              const checkNote = notes === undefined || notes === '';
              const checkAttachment = attachment === undefined || attachment === '';

              // If note is defined then we do skip check attachment
              if (checkNote) {
                if (checkAttachment) {
                  error = t('addResourceForm.errors.linkRequired');
                  return error;
                }
              }

              const re = new RegExp(urlPattern);
              if (value && !re.test(value)) {
                error = t('addResourceForm.errors.urlFormat');
                return error;
              }
            },
          }}
          interactionCallback={addModifiedFieldInteraction}
        />
        {uploadChosen && (
          <SecondaryButton onClick={undoUpload} fontSize={14}>
            {t('addResourceForm.undoAddUpload')}
          </SecondaryButton>
        )}
      </>
    );
  };

  return (
    <Box flexDirection="column">
      {showAddButton ? (
        <Button onClick={resetButtonStates} mt={25} variant="link" data-test="add-resource">
          {t('addResourceForm.addButton')}
        </Button>
      ) : (
        <form ref={formRef} autoComplete="off" onSubmit={handleSubmit(saveResource)}>
          <FormControl isInvalid={errors.title !== undefined}>
            {/*title field*/}
            <FormLabel fontSize={14}>{t('addResourceForm.titleField.resourceTitle')}</FormLabel>
            <Input
              margin="0"
              w="100%"
              size="md"
              borderColor="#E2E8F0"
              borderRadius={4}
              placeholder={t('addResourceForm.titleField.titlePlaceHolder')}
              {...register('title', {
                required: {
                  value: true,
                  message: t('addResourceForm.errors.titleRequired'),
                },
              })}
              mb={errors.title ? 0 : 4}
              onFocus={addModifiedFieldInteraction}
              data-test="resource-title"
            />
            <FormErrorMessage mb={4}>{errors.title && errors.title.message}</FormErrorMessage>
          </FormControl>

          {/*note field*/}
          {/* Required IF there is no Media Content */}
          <FormControl isInvalid={errors.note !== undefined}>
            <FormLabel fontSize={14}>{t('addResourceForm.noteField.resourceNote')}</FormLabel>
            <Textarea
              fontSize={14}
              size="lg"
              w="100%"
              borderColor="#E2E8F0"
              borderRadius={4}
              mb={errors.note ? 0 : 4}
              {...register('note', {
                validate: (): string | undefined => {
                  let error = '';
                  const attachment = getValues('attachmentUrl');
                  const notes = getValues('note');

                  const checkNote = notes === undefined || notes === '';
                  const checkAttachment = attachment === undefined || attachment === '';

                  // If attachment is defined then we can skip check attachment
                  if (checkAttachment) {
                    if (checkNote) {
                      error = t('addResourceForm.errors.notesRequired');
                      return error;
                    }
                  }
                },
              })}
              onFocus={addModifiedFieldInteraction}
              data-test="notes"
            />
            <FormErrorMessage mb={4}>{errors.note && errors.note.message}</FormErrorMessage>
          </FormControl>

          {/*media content field*/}
          {/* Required IF there is no Note */}
          <>
            <FormLabel fontSize={14} fontWeight="bold">
              {t('addResourceForm.mediaField')}
            </FormLabel>
            <FormControl isInvalid={errors.link !== undefined}>
              {saveCurrentLink && currentLink ? (
                <>
                  <FormLabel fontSize={14}>{t('addResourceForm.currentLinkField')}</FormLabel>
                  <Text fontSize={14}>{currentLink}</Text>
                  <SecondaryButton onClick={() => setSaveCurrentLink(false)} fontSize={14} mb={2} mt={2}>
                    {t('addResourceForm.removeLinkField')}
                  </SecondaryButton>
                </>
              ) : (
                <Box flexDirection="column">
                  <AddWebLink
                    name="link"
                    register={register}
                    validation={{
                      validate: (value: string) => {
                        let error = '';
                        const attachment = getValues('attachmentUrl');
                        const notes = getValues('note');

                        const checkNote = notes === undefined || notes === '';
                        const checkAttachment = attachment === undefined || attachment === '';

                        // If note is defined then we do skip check attachment
                        if (checkNote) {
                          if (checkAttachment) {
                            error = t('addResourceForm.errors.linkRequired');
                            return error;
                          }
                        }

                        const re = new RegExp(urlPattern);
                        if (value && !re.test(value)) {
                          error = t('addResourceForm.errors.urlFormat');
                          return error;
                        }
                      },
                    }}
                    interactionCallback={addModifiedFieldInteraction}
                  />
                  {/* if the user hasn't chosen to link something but something has been saved as current link, that means they've chosen to remove link -- give them the chance to undo this */}
                  {currentLink && !saveCurrentLink && (
                    <SecondaryButton mb={2} onClick={() => setSaveCurrentLink(true)} fontSize={14}>
                      {t('addResourceForm.undoRemoveLinkField')}
                    </SecondaryButton>
                  )}
                </Box>
              )}

              {saveCurrentUpload && currentUpload ? (
                <>
                  <FormLabel fontSize={14}>{t('addResourceForm.currentUploadField')}</FormLabel>
                  {selectedResource?.attachmentName &&
                  getAttachmentType(selectedResource.attachmentName) === 'image' ? (
                    <Image src={currentAttachmentUrl} alt={currentUpload} boxSize="100px" objectFit="cover" />
                  ) : (
                    <Text fontSize={14}>{currentUpload}</Text>
                  )}
                  <SecondaryButton onClick={() => setSaveCurrentUpload(false)} mt={2} fontSize={14}>
                    {t('addResourceForm.removeUploadField')}
                  </SecondaryButton>
                </>
              ) : (
                <Box flexDirection="column">
                  {displayMediaUploader()}

                  {/* if the user hasn't chosen to upload something but something has been saved as current upload, that means they've chosen to remove upload -- give them the chance to undo this */}
                  {currentUpload && !uploadChosen && (
                    <SecondaryButton
                      mt={2}
                      style={{ display: 'block' }}
                      onClick={() => setSaveCurrentUpload(true)}
                      fontSize={14}
                    >
                      {t('addResourceForm.undoRemoveUploadField')}
                    </SecondaryButton>
                  )}
                  <FormErrorMessage>
                    {errors.link?.message === t('addResourceForm.errors.urlFormat') && errors.link.message}
                    {!!errors.link && !!errors.attachmentUrl && errors.attachmentUrl.message}
                  </FormErrorMessage>
                </Box>
              )}
            </FormControl>
          </>
          <Center>{displaySaveButton()}</Center>
        </form>
      )}
    </Box>
  );
};

const mapDispatchToProps = {
  createInteraction,
};

function mapStateToProps(state: RootState) {
  const { selectedLinkedUser } = state.linkedUsers;

  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }

  return {
    pir: selectedLinkedUser.pir,
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddResourceForm);
