import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../enums/RequestStatus';
import { getCPInfo } from '../thunks/getCPInfo';
import { AdminCP } from '../interfaces/Admin';

interface AdminState {
  careProviders: AdminCP[];
  intialRequestStatus: RequestStatus;
  requestError: string | null;
}

const initialState: AdminState = {
  careProviders: [],
  intialRequestStatus: RequestStatus.IDLE,
  requestError: null,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCPInfo.pending, (state) => {
        state.intialRequestStatus = RequestStatus.LOADING;
      })
      .addCase(getCPInfo.fulfilled, (state, action) => {
        state.intialRequestStatus = RequestStatus.SUCCEEDED;
        state.careProviders = action.payload;
      })
      .addCase(getCPInfo.rejected, (state, action) => {
        state.intialRequestStatus = RequestStatus.FAILED;
        state.requestError = action.error.message ?? 'An error occurred';
      });
  },
});

export default adminSlice.reducer;
