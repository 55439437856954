import FirebaseUtils from '../firestore/Firestore';
import {
  Unsubscribe,
  getAuth,
  onAuthStateChanged,
  User,
  UserCredential,
  updateEmail,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updatePassword,
} from 'firebase/auth';
import { UserDetails } from '../../types/cordova-plugin-firebase-authentication/FirebaseAuthentication';
import { ActionCodeSettings } from 'firebase/auth';

export const getFirebaseUser = (): User | null => {
  try {
    return getAuth(FirebaseUtils.app()).currentUser;
  } catch (e) {
    console.error('error getting firebase user:', e);
    throw e;
  }
};

export const updateFirebaseUserEmail = async (user: User, email: string): Promise<void> => {
  try {
    return updateEmail(user, email);
  } catch (e) {
    console.error('error updating Firebase user email:', e);
  }
};

export const updateFirebaseUserPassword = async (user: User, password: string): Promise<void> => {
  try {
    return updatePassword(user, password);
  } catch (e) {
    console.error('error updating Firebase password:', e);
  }
};

export const resetFirebaseUserEmail = async (email: string): Promise<void> => {
  const actionCodeSettings : ActionCodeSettings = { 
    android: {
      installApp: true,
      packageName: 'com.behaivior.app',
      minimumVersion: '12'
    },
    iOS: {
      bundleId: 'com.behaivior.app',
    },
    url: `https://${process.env.REACT_APP_DOMAIN}/${process.env.REACT_APP_RESET_PASSWORD_DYNAMIC_LINK}`,
    handleCodeInApp: false,
    dynamicLinkDomain: process.env.REACT_APP_DOMAIN
  }

  return sendPasswordResetEmail(getAuth(FirebaseUtils.app()), email, actionCodeSettings);
};

export const signInToFirebase = async (email: string, password: string): Promise<UserCredential> => {
  try {
    const userCredentials = await signInWithEmailAndPassword(getAuth(FirebaseUtils.app()), email, password);
    await mobileSignInWithCordovaFirebase(email, password);
    return userCredentials;
  } catch (e) {
    console.error('error signing in to Firebase:', e);
    throw e;
  }
};

const mobileSignInWithCordovaFirebase = async (email: string, password: string): Promise<void> => {
  if (window.cordova) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await cordova.plugins.firebase.auth.signInWithEmailAndPassword(email, password);
    } catch(e) {
      console.error('Cordova signInWithEmailAndPassword error', e);
    }
  }
}

export const signOutFirebaseUser = async (): Promise<void> => {
  try {
    await signOut(getAuth(FirebaseUtils.app()));
    await mobileSignOutWithCordovaFirebase();
  } catch (e) {
    console.error('error signing out of firebase:', e);
  }
};

const mobileSignOutWithCordovaFirebase = async (): Promise<void> => {
  if (window.cordova) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await cordova.plugins.firebase.auth.signOut();
    } catch (e) {
      console.error('Cordova signOut error', e);
    }
  }
}

export const onFirebaseAuthStateChanged = (callback: (user: User | null) => void): Unsubscribe => {
    try {
      return onAuthStateChanged(getAuth(FirebaseUtils.app()), callback);
    } catch (e) {
      console.error('error changing state with Firebase:', e);
    throw e;
  }
};

export const onCordovaFirebaseAuthStateChanged = (callback: (userDetails: UserDetails | null) => void): void => {
  if (window.cordova) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cordova.plugins.firebase.auth.onAuthStateChanged(callback);
    } catch (e) {
      console.error('error changing state with Cordova Firebase:', e);
    }
  }
};
