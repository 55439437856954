import React, { useState, useEffect, useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux/store';
import { UserRole } from '../user/interfaces/IUser';
import SelectLinkedUser from '../linked-users/SelectLinkedUser';
import ProviderWithout from './ProviderWithout';
import PirDashboard from './PirDashboard';
import NarrowInterior from '../../components/NarrowInterior';
import PageHeading from '../../components/PageHeading';
import AdminDashboard from './AdminDashboard';
import HeadOfOrgDashboard from './HeadOfOrgDashboard';
import { CareProvider } from '../careProvider/CareProvider';
import { Box, Flex } from '@chakra-ui/react';
import InviteNewUser from '../invitations/InviteNewUser';
import { createLinkedUserForInvitee } from '../linked-users/utils';
import { createLinkedUser } from '../linked-users/actions';
import { IIncompleteLinkedUser } from '../linked-users/interfaces/ILinkedUser';
import ILinkedUser from '../linked-users/interfaces/ILinkedUser';
import { UserContext } from '../interior/Index';
import { logout } from '../auth/actions';

const Index = (props: PropsFromRedux): JSX.Element => {
  // Redirecting to admin / headOfOrg page before checking linkedUser

  const [linkedUsers, setLinkedUsers] = useState<(ILinkedUser | IIncompleteLinkedUser)[]>([]);
  const [providerWithout, setProviderWithout] = useState(false);
  const [CPwithLinkedUsers, setCPwithLinkedUsers] = useState(false);
  const user = useContext(UserContext);

  useEffect(() => {
    if (user?.role === UserRole.CP && props.linkedUsers?.length > 0) {
      const providerWith: ILinkedUser[] = [];
      const providerWithout: IIncompleteLinkedUser[] = [];
      props.linkedUsers.forEach((linkedUser) => {
        if (linkedUser.otherUserRole === 'PROVIDER' && linkedUser.approvedByOther && linkedUser.approvedByPir)
          providerWith.push(linkedUser as ILinkedUser);
        else if (linkedUser.otherUserRole === 'PROVIDER' && (!linkedUser.reviewedByOther || !linkedUser.reviewedByPir))
          providerWithout.push(linkedUser);
      });
      let filteredLinkedUsers: (ILinkedUser | IIncompleteLinkedUser)[] = [];
      if (providerWith.length > 0) {
        filteredLinkedUsers = providerWith;
      } else {
        setProviderWithout(true);
        filteredLinkedUsers = [providerWithout[0]];
      }
      setLinkedUsers(filteredLinkedUsers);
    }
    return () => {
      setProviderWithout(false);
      setLinkedUsers([]);
      setCPwithLinkedUsers(false);
    };
  }, [props.linkedUsers]);

  const handleInvitationSent = async (email: string, pirAlias: string) => {
    await createLinkedUserForInvitee(props.createLinkedUser, props.user, email, pirAlias);
  };

  if (props.user.role === UserRole.ADMIN) {
    return <AdminDashboard />;
  }

  if (props.user.role === UserRole.HEAD_ORG) {
    return <HeadOfOrgDashboard />;
  }

  if (user?.role === UserRole.USER && props.selectedLinkedUser) {
    return <PirDashboard />;
  } else if (user?.role === UserRole.USER) {
    console.error('This user has been incorrectly formatted and is missing a pir linkedUser');
    props.logout();
  } else if (providerWithout) {
    return <ProviderWithout />;
  }

  // if there's no selected user
  else if (props.selectedLinkedUser === null) {
    return (
      <Box mx="auto" width="100%" px={['18px', '36px']} pt="26px">
        <Box>
          {props.user && props.user.role === UserRole.CP ? (
            <Flex width="100%" justifyContent="right" alignItems="right" height={50}>
              <InviteNewUser
                inviterName={props.user.name ?? ''}
                inviterId={props.user.id}
                onInvitationSent={handleInvitationSent}
                role="cp"
              />
            </Flex>
          ) : null}
          <NarrowInterior>
            <PageHeading my={5}>Please select a patient to continue:</PageHeading>
            <SelectLinkedUser includeDashboard={CPwithLinkedUsers ? true : false} filteredLinkedUsers={linkedUsers} />
          </NarrowInterior>
        </Box>
      </Box>
    );
  }

  // if there is a selected and they are not the same as the user
  if (props.selectedLinkedUser?.pir?.id !== user?.id) {
    return <CareProvider noLinks={props.selectedLinkedUser && !('pir' in props.selectedLinkedUser) ? true : false} />;
  }

  return <PageHeading>Home screen</PageHeading>;
};

const mapStateToProps = (state: RootState) => {
  const user = state.user.user;
  if (user === null) {
    throw new Error('No user logged in');
  }

  return {
    linkedUsers: state.linkedUsers.otherLinkedUsers || [],
    user,
    selectedLinkedUser: state.linkedUsers.selectedLinkedUser,
  };
};

const mapDispatchToProps = {
  createLinkedUser,
  logout,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Index);
