import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import {
  Box,
  Button,
  Heading,
  Text,
  FormControl,
  FormErrorMessage,
  Input,
  FormLabel,
  Spinner,
  Flex,
  VStack,
} from '@chakra-ui/react';
import Field from './Field';
import { logoutGarmin } from '../../redux/actions/garmin';
import axios from 'axios';

interface Props {
  onSubmit(data: Record<string, any>): Promise<void>;
  generalFormErrors: string[];
  onBack(): void;
  formErrorIsVisible: boolean;
  token: string | null;
}

const SignUpForm = (props: Props): JSX.Element => {
  const location = useLocation();
  const logInLocation = { ...location, search: '?log-in=true' };
  const introLocation = { ...location, search: '' };
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const tempPassword = query.get('tmppassword');
  const oldLinkEmail = query.get('email');
  const { t } = useTranslation('auth');
  const { handleSubmit, register, watch, formState } = useForm();
  const { isSubmitting } = formState;
  const { errors } = formState;
  const [email, setEmail] = useState<string | null>(null);
  const [verifyingEmail, setVerifyingEmail] = useState(true);
  const [userExists, setUserExists] = useState<boolean | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.token) {
      verifyEmail(props.token);
      dispatch(logoutGarmin());
    } else if (oldLinkEmail) {
      // if there's an email present from an old-style link, no need to verify email ourselves because Firebase has done it already and there's no verification code in the link
      setEmail(oldLinkEmail);
      setVerifyingEmail(false);
    }
  }, []);

  const verifyEmail = async (token: string) => {
    try {
      const { data } = await axios.post(process.env.REACT_APP_VERIFY_EMAIL_URL || '', { verificationToken: token });
      if (data) {
        const { userAlreadyExists } = data;
        if (!userAlreadyExists) {
          setEmail(data.email);
        }
        setUserExists(!!userAlreadyExists);
        setVerifyingEmail(false);
      }
    } catch (err) {
      console.error(`Error verifying email: ${err}`);
      setVerifyingEmail(false);
    }
  };

  const handleLoginClick = () => {
    history.push({ pathname: '/', search: '?straight-to-login=true' });
  };

  if (verifyingEmail) {
    return (
      <VStack spacing={20} flexGrow={1} minWidth={0} flexBasis="100" alignItems="stretch" maxHeight="90vh">
        <Flex justifyContent="center">
          <Box w="100%" h="25%" textAlign="center">
            <Text mt={4} fontSize={24} fontWeight="bold">
              Verifying email
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent="center" paddingBottom={10}>
          <Spinner />
        </Flex>
      </VStack>
    );
  }

  if (!email) {
    return (
      <VStack spacing={20} flexGrow={1} minWidth={0} flexBasis="100" alignItems="stretch" maxHeight="90vh">
        <Flex justifyContent="center">
          <Box w="100%" h="25%" textAlign="center">
            <Text mt={4} fontSize={18} color={userExists ? 'purple3.600' : 'red'}>
              {userExists ? t('signUpForm.userAlreadyExists') : t('signUpForm.errors.emailVerificationError')}
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent="center" paddingBottom={10}>
          <Button
            bg="white"
            color="purple3.600"
            borderRadius={0}
            w="80%"
            type="submit"
            onClick={handleLoginClick}
            _hover={{ background: 'blue', color: 'white' }}
            variant="outline"
            borderColor="purple3.600"
            data-test="login-btn"
          >
            Login or Change Password
          </Button>
        </Flex>
        {/* </Flex> */}
      </VStack>
    );
  }

  return (
    <Box py={10}>
      <Heading as="h2" fontSize={[24, 24, 36, 45]} fontWeight={400} color="purple3.600" mb="40px">
        {t('signUpForm.header')}
      </Heading>

      <form
        onSubmit={handleSubmit(async ({ password }) => {
          try {
            await props.onSubmit({ email, password, tempPassword });
          } catch (err) {
            console.error(`Error submitting signup to firebase: ${err}`);
          }
        })}
      >
        {/* General sign up errors, mostly the responses from Firebase */}
        {props.formErrorIsVisible && (
          <FormControl isInvalid={props.generalFormErrors.length > 0} mb={8}>
            {props.generalFormErrors.map((error, i) => (
              <FormErrorMessage key={i}>{error}</FormErrorMessage>
            ))}
          </FormControl>
        )}

        <FormControl mb="40px">
          <FormLabel as="label" htmlFor="email" color="purple3.600" fontWeight={300} fontSize={16}>
            {t('signUpForm.emailLabel')}
          </FormLabel>
          <Input
            name="email"
            type="email"
            id="email"
            borderRadius={0}
            borderWidth={2}
            borderColor="purple3.600"
            isDisabled={true}
            value={email || ''}
          />
        </FormControl>
        <Field
          {...register('password', {
            required: {
              value: true,
              message: t('signUpForm.errors.requiredPassword'),
            },
          })}
          name="password"
          label={t('signUpForm.passwordLabel')}
          type="password"
          placeholder="**********"
          error={errors.password}
        />
        <Field
          {...register('confirmPassword', {
            required: {
              value: true,
              message: t('signUpForm.errors.requiredPassword'),
            },
            validate: (value: string): boolean | string => {
              return value === watch('password') || (t('signUpForm.errors.samePassword') as string);
            },
          })}
          name="confirmPassword"
          label={t('signUpForm.confirmPasswordLabel')}
          type="password"
          placeholder="**********"
          error={errors.confirmPassword}
        />
        <Button
          bg="blue4.500"
          color="white"
          borderRadius={0}
          w="100%"
          type="submit"
          _hover={{ backgroundColor: 'blue4.300' }}
          isLoading={isSubmitting}
        >
          {t('signUpForm.signUpButton')}
        </Button>
      </form>
      <Text mt={4} textAlign="center">
        <Trans t={t} i18nKey="signUpForm.login">
          login
          <Link to={logInLocation}>
            <Text as="span" color="purple3.600" fontWeight="bold">
              link
            </Text>
          </Link>
        </Trans>
      </Text>
      <Text mt={4} textAlign="center">
        <Trans t={t} i18nKey="signUpForm.backButton">
          backButton
          <Link to={introLocation} onClick={props.onBack}>
            <Text as="span" color="purple3.600" fontWeight="bold">
              link
            </Text>
          </Link>
        </Trans>
      </Text>
    </Box>
  );
};

export default SignUpForm;
