import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { addAlert } from '../user/actions';
import { RootState } from '../../redux/store';
import { isPirLinkedUser } from '../linked-users/utils';
import EmergencyAlert from './EmergencyAlert';
import CravingAlert from '../craving/CravingAlert';

interface Props {
  isOpen: boolean;
  onClose(): void;
  source: string;
}

const ModalAlert = ({ isOpen, onClose, source }: Props & PropsFromRedux): JSX.Element => {
  const cancelRef = React.createRef<HTMLElement>();
  const { t } = useTranslation('common');
  const cravingDisclosure = useDisclosure();
  const emergencyDisclosure = useDisclosure();

  return (
    <>
      <AlertDialog leastDestructiveRef={cancelRef as any} isOpen={isOpen} onClose={onClose} isCentered={true}>
        <AlertDialogOverlay />
        <AlertDialogContent maxWidth="700px" mx="15px">
          <AlertDialogHeader color="purple3.600" fontWeight="light" fontSize={24}>
            {source === 'alert' ? t('generalAlert.alertHeader') : t('generalAlert.notificationHeader')}
          </AlertDialogHeader>
          {source === 'notification' && (
            <Text textAlign="center" maxWidth="700px" mx="15px">
              {t('generalAlert.notificationText')}
            </Text>
          )}
          <AlertDialogBody>
            <Button
              onClick={() => {
                onClose();
                cravingDisclosure.onOpen();
              }}
              backgroundColor="blue.300"
              _hover={{ backgroundColor: 'blue.200' }}
              color="white"
              fontWeight="normal"
              display={['block', 'block', 'inherit']}
              w="100%"
              mb={4}
            >
              {t('cravingAlert.logCraving')}
            </Button>
            <Button
              onClick={() => {
                onClose();
                emergencyDisclosure.onOpen();
              }}
              backgroundColor="red.300"
              _hover={{ backgroundColor: 'red.200' }}
              color="white"
              fontWeight="normal"
              display={['block', 'block', 'inherit']}
              w="100%"
            >
              {/* {t('emergencyAlert.cancelButton')} */}
              Alert Support Network
            </Button>
          </AlertDialogBody>
          <AlertDialogFooter display={['block', 'block', 'flex']}>
            <Button
              ref={cancelRef as any}
              onClick={onClose}
              fontWeight="normal"
              display={['block', 'block', 'inherit']}
              w={['100%', '100%', 'auto']}
            >
              {t('emergencyAlert.cancelButton')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <CravingAlert isOpen={cravingDisclosure.isOpen} onClose={cravingDisclosure.onClose} />
      <EmergencyAlert isOpen={emergencyDisclosure.isOpen} onClose={emergencyDisclosure.onClose} />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const selectedLinkedUser = state.linkedUsers.selectedLinkedUser;

  return {
    isPir: selectedLinkedUser !== null && isPirLinkedUser(selectedLinkedUser),
  };
};

const mapDispatchToProps = {
  addAlert,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ModalAlert);
