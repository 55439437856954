import { Flex, Text, VStack, Avatar } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { SelectedUserContext } from '../../modules/interior/Index';
import { filterRequestsByStatusSort } from '../../modules/supportRequests/utils';
import ISupportRequest, { SupportRequestStatus } from '../../db/supportRequests';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange: (status: SupportRequestStatus) => void;
  selectedStatus: SupportRequestStatus;
  requests: ISupportRequest[];
}

const ModalLeftNav = (props: Props): React.ReactElement => {
  const { t } = useTranslation('support_requests');
  const { selectedUser } = useContext(SelectedUserContext);

  return (
    <Flex width="38%" backgroundColor="#E5E5E5" minHeight="100%" flexDirection="column">
      <Flex paddingTop="35px" pb="49px" flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Avatar size="md" name={selectedUser?.name} src={selectedUser?.image} />
        <Text padding="14px" fontSize="20px" lineHeight="24px" fontWeight={600} textAlign="center">{selectedUser?.name}</Text>
      </Flex>
      <VStack width="100%" alignItems="flex-end">
        {Object.values(SupportRequestStatus).map((status) => (
          <Flex
            onClick={() => {
              props.onChange(status);
              // selectSupportRequest(null);
            }}
            key={status}
            width="100%"
            cursor="pointer"
            pl="10%"
            pr="20%"
            borderTopLeftRadius="10px"
            borderBottomLeftRadius="10px"
            height="31px"
            justifyContent="space-between"
            alignItems="center"
            fontSize="14px"
            lineHeight="18.8px"
            color="#4F4F4F"
            backgroundColor={props.selectedStatus === status ? 'white' : 'transparent'}
            fontWeight={props.selectedStatus === status ? 600 : 400}
          >
            <Text>{t(`requestsModal.tabsTitle.${status}`)}</Text>
            <Text>{filterRequestsByStatusSort(props.requests, status).length}</Text>
          </Flex>
        ))}
      </VStack>
    </Flex>
  );
};

export default ModalLeftNav;
