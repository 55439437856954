import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

const PrimaryButton = (props: ButtonProps): JSX.Element => (
  <Button
    color="white"
    fontWeight="500"
    bg="purple3.600"
    _hover={{
      backgroundColor: 'purple3.300',
      _disabled: {
        bgColor: 'purple3.600',
      },
    }}
    {...props}
  >
    {props.children}
  </Button>
);

export default PrimaryButton;
