/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { initLoadEnabledGraphs, initLoadUser, loadInitialLink } from './actions';
import { store, RootState } from '../../redux/store';
import {
  BluetoothStatus,
  getWearableInfo,
  setupWearableLogging,
  uploadWearableData,
  wearableInitializeSDK,
  wearableSync,
  garminPluginInitializeSentry,
  NeedsToPairStatus,
} from '../../lib/wearable';
import {
  garminSyncFail,
  garminUploadFail,
  garminSyncStart,
  garminSyncSuccess,
  garminSyncProgress,
  updateBluetoothStatus,
  updateDeviceConnection,
  updateNeedsToPair,
  garminSyncStop,
  updateBattery,
  clearGarmin,
} from '../../redux/actions/garmin';
import { initializeBackgroundFetch } from 'src/lib/backgroundFetch';
import { useDevice } from 'src/DeviceContext';
import * as Sentry from '@sentry/react';

interface SuppliedProps {
  children: JSX.Element | JSX.Element[];
}

interface GarminErrorEvent extends Event {
  detail?: {
    error?: string;
  };
}

type Props = SuppliedProps & PropsFromRedux;

const isCustomEvent = (event: Event): event is CustomEvent => 'detail' in event;

const GlobalLoader = (props: Props): JSX.Element => {
  const { initLoadUser, initLoadEnabledGraphs, loadInitialLink } = props;
  const { isMobileDevice, isCordova, isMobileDeviceAndCordova } = useDevice();
  const devicedisconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [loadingWearable, setLoadingWearable] = useState(false);

  useEffect(() => {
    return () => {
      console.log('setting loading wearable false');
      setLoadingWearable(false);
    };
  }, []);

  const initWearable = async (): Promise<void> => {
    console.log('initWearable');
    if (isMobileDeviceAndCordova) {
      console.log('setting loading wearable true');
      setLoadingWearable(true);
      const safetyTimeout = setTimeout(() => {
        console.error('Wearable initialization timed out');
        Sentry.captureMessage('Wearable initialization timed out');
        console.log('setting loading wearable false, device unpaired');
        store.dispatch(clearGarmin());
        setLoadingWearable(false);
      }, 8000);
      // plugin reads sentry dsn from firebase config.
      try {
        await garminPluginInitializeSentry();
        console.log('initialized sentry in garmin plugin');
      } catch (err) {
        console.log('was unable to initialize sentry in garmin plugin');
      }
      window.addEventListener('garminsdk:sdkinitialized', () => {
        console.log('garminsdk:sdkinitialized');
        Sentry.captureMessage(`Garmin SDK initialized`);
      });

      window.onerror = function (message, source, lineno, colno, error) {
        console.error('Global error caught:', message);
        const errorMessage = message instanceof Event ? 'Script error occurred' : message;
        Sentry.captureException(error || new Error(errorMessage));
        return true;
      };

      // Garmin SDK reads license key from firebase config.
      try {
        const wasInitializedAlready = await wearableInitializeSDK();
        if (wasInitializedAlready) {
          console.log('wasAlreadyInitialized');
          Sentry.captureMessage('wasAlreadyInitialized');
          const hasWearableInfo = await getPairedDevice();
          nextStepsWearable(hasWearableInfo, wasInitializedAlready);
          console.log('setting loading wearable false');
          setLoadingWearable(false);
          clearTimeout(safetyTimeout);
        } else {
          console.log('SDK not already initialized, will wait a few seconds');
          setTimeout(async () => {
            const hasWearableInfo = await getPairedDevice();

            nextStepsWearable(hasWearableInfo, false); // SDK wasn't already initialized
            console.log('setting loading wearable false');
            setLoadingWearable(false);
            clearTimeout(safetyTimeout);
          }, 3000);
        }
      } catch (err) {
        console.error('error initializing garmin SDK', err);
        Sentry.captureException(new Error(`Error initializing SDK: ${err}`));
        console.log('setting loading wearable false');
        setLoadingWearable(false);
        clearTimeout(safetyTimeout);
      }
      window.cordova.plugins.diagnostic.getBluetoothState(function (state: string) {
        const bluetoothStatus: BluetoothStatus = bluetoothStateToBluetoothStatus(state);
        store.dispatch(updateBluetoothStatus(bluetoothStatus));
      });
      window.cordova.plugins.diagnostic.registerBluetoothStateChangeHandler(function (state: string) {
        let bluetoothStatus: BluetoothStatus = bluetoothStateToBluetoothStatus(state);
        console.log('bluetoothStatus has changed and is:', bluetoothStatus);
        if (bluetoothStatus !== BluetoothStatus.BLUETOOTH_ON) {
          // make sure it's not just a temporary disconnection
          console.log('bluetooth status not on and about to wait 5 seconds and try again');
          setTimeout(() => {
            window.cordova.plugins.diagnostic.getBluetoothState(function (newState: string) {
              console.log('bluetoothStatus after a pause is:', newState);
              bluetoothStatus = bluetoothStateToBluetoothStatus(newState);
              store.dispatch(updateBluetoothStatus(bluetoothStatus));
            });
          }, 5000);
        } else {
          store.dispatch(updateBluetoothStatus(bluetoothStatus));
        }
      });

      window.addEventListener('unhandledrejection', function (event) {
        console.error('Unhandled rejection:', event.reason);
        Sentry.captureException(event.reason);
      });

      try {
        await initializeBackgroundFetch();
        console.log('initialized background fetch');
      } catch (err) {
        console.error('error initializing background fetch');
      }

      console.log('Attach other event handlers');
      window.addEventListener('garminsdk:syncstart', () => {
        console.log('garminsdk:syncstart');
        store.dispatch(garminSyncStart());
      });

      window.addEventListener('garminsdk:batteryUpdate', async (e) => {
        if (isCustomEvent(e)) {
          const batteryLevel = e.detail.batteryLevel;
          const garminState = store.getState().garmin;
          console.log(`garminsdk:batteryUpdate ${batteryLevel}`);
          store.dispatch(garminSyncProgress(e.detail.progress));
          if (batteryLevel >= 0 && !garminState.isUpdatingBattery) {
            store.dispatch(await updateBattery(batteryLevel));
          }
        }
      });

      window.addEventListener('garminsdk:syncprogress', (e) => {
        if (isCustomEvent(e)) {
          console.log(`garminsdk:syncprogress ${e.detail.progress}`);
          store.dispatch(garminSyncProgress(e.detail.progress));
        }
      });
      window.addEventListener('garminsdk:syncerror', (event: GarminErrorEvent) => {
        const errorMessage = event.detail?.error || '';
        console.log('garminsdk:syncerror', errorMessage);
        if (errorMessage) {
          Sentry.captureException(new Error(`Sync error: ${errorMessage}`));
        }
        store.dispatch(garminSyncFail());
      });
      window.addEventListener('garminsdk:synccomplete', (e) => {
        (async () => {
          console.log('garminsdk:synccomplete');
          // if this sync was not requested by us (i.e. it was started automatically by Garmin), we need to request sync to get all most recent data; otherwise, upload wearable data
          let shouldRequestSync = false;
          if (isCustomEvent(e)) {
            if (e.detail.shouldRequestSync) shouldRequestSync = true;
          }
          store.dispatch(garminSyncSuccess(shouldRequestSync));
          if (shouldRequestSync) {
            try {
              await wearableSync();
            } catch (err) {
              console.log(`Error in wearableSync after synccomplete: ${err}`);
            }
          } else {
            // upload data to firestore
            await uploadWearableData();
          }
        })();
      });
      window.addEventListener('garminsdk:backgroundsynccomplete', () => {
        (async () => {
          console.log('garminsdk:backgroundsynccomplete');
          store.dispatch(garminSyncSuccess(false));
        })();
      });
      console.log('Attach event handler on deviceconnect');
      window.addEventListener('garminsdk:deviceconnect', () => {
        (async () => {
          console.log('garminsdk:deviceconnect');
          if (devicedisconnectTimeoutRef.current) {
            clearTimeout(devicedisconnectTimeoutRef.current);
            devicedisconnectTimeoutRef.current = null;
            console.log('Reconnected before 7 seconds; canceling disconnect logic');
          }
          store.dispatch(updateDeviceConnection(true));
          // try {
          //   setTimeout(async () => {
          //     const garminState = store.getState().garmin;
          //     if (!(garminState.isSyncing || garminState.isUploading)) {
          //       console.log('syncing after connection to device');
          //       try {
          //         await wearableSync();
          //       } catch (err) {
          //         console.log(`Error in wearableSync: ${err}`);
          //       }
          //     }
          //   }, 5000);
          // } catch (err) {
          //   console.log(`Error syncing after device connected: ${err}`);
          //   Sentry.captureException(new Error(`sync after device connected errors: ${err}`));
          // }
        })();
      });
      console.log('attach event handler on devicedisconnect');
      window.addEventListener('garminsdk:devicedisconnect', () => {
        (async () => {
          console.log('garminsdk:devicedisconnect');

          console.log('device disconnected; waiting 7 seconds and checking if it has been reset to connected');
          devicedisconnectTimeoutRef.current = setTimeout(() => {
            console.log('Still disconnected after 7 seconds');
            Sentry.captureMessage('Still disconnected after 7 seconds');
            store.dispatch(updateDeviceConnection(false));
          }, 7000);
        })();
      });
    }
  };

  const bluetoothStateToBluetoothStatus = (state: string): BluetoothStatus => {
    const bluetoothStatus: BluetoothStatus =
      state === 'powered_on'
        ? BluetoothStatus.BLUETOOTH_ON
        : state === 'unauthorized'
        ? BluetoothStatus.UNAUTHORIZED
        : BluetoothStatus.BLUETOOTH_NOT_ON;
    return bluetoothStatus;
  };

  const nextStepsWearable = async (hasWearableInfo: boolean, alreadyInitialized: boolean) => {
    // warmup garmin SDK and init redux state
    if (hasWearableInfo) {
      // if it was initialized previously, these have already been done and it might be syncing/uploading legitimately
      if (!alreadyInitialized) {
        // trigger wearable sync when device is ready
        console.log('Attach event handler on devicefirstconnect');
        window.addEventListener(
          'garminsdk:devicefirstconnect',
          () => {
            (async () => {
              console.log('garminsdk:devicefirstconnect');
              store.dispatch(updateDeviceConnection(true));
              try {
                await setupWearableLogging();
                await wearableSync();
              } catch (err) {
                console.error('Error in setting up logging or wearable sync:', err);
                Sentry.captureException(new Error(`Error setting up logging or wearable sync: ${err}`));
              }
            })();
          },
          { once: true },
        );

        console.log('getting device from redux state');
        Sentry.captureMessage('getting device from redux state');
        const garminDevice = store.getState().garmin;
        if (garminDevice !== null) {
          console.log('device in redux state');
          // having isSyncing or isUploading flag indicates that app was closed before operation is completed. We have to restore correct device state
          if (garminDevice.isSyncing) {
            console.log('WAS SYNCING!');
            store.dispatch(garminSyncFail());
          } else if (garminDevice.isUploading) {
            console.log('WAS UPLOADING!');
            store.dispatch(garminUploadFail());
          }
        }
      } else {
        const garminDevice = store.getState().garmin;
        if (garminDevice !== null) {
          console.log('device in redux state');
          const date = new Date();
          const cstDate = date.toLocaleString('en-US', { timeZone: 'America/Chicago' });
          // having isSyncing or isUploading flag indicates that app was closed before operation is completed. We have to restore correct device state
          if (garminDevice.isSyncing) {
            console.log('WAS SYNCING!');
            Sentry.captureMessage(`WAS SYNCING! ${cstDate}`);
            // Sentry.captureMessage('WAS SYNCING!');
            store.dispatch(garminSyncStop());
          } else if (garminDevice.isUploading) {
            console.log('IS UPLOADING!');
            Sentry.captureMessage(`IS UPLOADING! ${cstDate}`);
            // store.dispatch(garminUploadFail());
          }
        }
        try {
          await wearableSync();
        } catch (err) {
          console.error('Error syncing after garmin was already initialized:', err);
          Sentry.captureException(new Error(`Error syncing after garmin was already initialized: ${err}`));
        }
      }
    } else if (isCordova) {
      console.log('No paired device found in Global Loader');
      if (store.getState().garmin.name || store.getState().garmin.lastUploadCompleted) {
        store.dispatch(updateNeedsToPair(NeedsToPairStatus.SHOULD_TRY_TO_PAIR));
      }
    }
  };

  useEffect(() => {
    initLoadUser();

    const initRemoteConfig = async () => {
      try {
        await initLoadEnabledGraphs(isMobileDeviceAndCordova);
      } catch (err) {
        console.error('error loading enabled graphs', err);
      }
      try {
        await initWearable();
      } catch (err) {
        console.error('error in initWearable:', err);
      }
      try {
        await loadInitialLink(isMobileDeviceAndCordova);
      } catch (err) {
        console.error('error logging initial link:', err);
      }
    };

    initRemoteConfig();
    if (
      !isMobileDevice &&
      (window.location.href.includes('sign-up=true') || window.location.href.includes('verify=true'))
    ) {
      if (!window.location.href.includes('#')) {
        const oflUrl = new URL(window.location.href);

        const origin = oflUrl.origin;
        let path = oflUrl.pathname;
        const searchParams = oflUrl.search;

        const hashIndex = path.lastIndexOf('#');
        if (hashIndex !== -1) {
          path = path.substring(0, hashIndex);
        }
        const newUrl = `${origin}/#${path}${searchParams}`;
        window.location.href = newUrl;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.loading || loadingWearable) {
    return (
      <Flex alignItems="center" justifyContent="center" h="100%">
        <Box w="100%" h="25%" textAlign="center">
          <Spinner />
          <Text mt={4} fontSize={24} fontWeight="bold">
            Initializing
          </Text>
        </Box>
      </Flex>
    );
  }

  return <>{props.children}</>;
};

const getPairedDevice = async (): Promise<boolean> => {
  try {
    console.log('getPairedDevice');
    const wearableInfo = await getWearableInfo();
    const hasWearableInfo = !!wearableInfo;
    console.log('hasWearableInfo in getPairedDevice', !!hasWearableInfo);
    return hasWearableInfo;
  } catch (err) {
    console.log('no paired device in getPairedDevice');
    return false;
  }
};

const mapStateToProps = (state: RootState) => {
  const { init } = state;

  return {
    loading: init.userLoading || init.linkedAccountsLoading || init.initialLinkLoading,
  };
};

const mapDispatchToProps = {
  initLoadUser,
  initLoadEnabledGraphs,
  loadInitialLink,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GlobalLoader);
