import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Box,
  Text,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import SelectLinkedUser from '../linked-users/SelectLinkedUser';
import { UserRole } from '../user/interfaces/IUser';
import { UserContext } from './Index';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  onSelect(): void;
}
type Props = SuppliedProps & PropsFromRedux;

const ChangeLinkedUser = (props: Props): JSX.Element => {
  const { t } = useTranslation('menu');
  const user = useContext(UserContext);
  const { isPhone } = useDevice();

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent
        zIndex={2100}
        maxWidth={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : ['100%', '98%', '90%', '85%', '75%']}
        minHeight={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : undefined}
        marginBottom={isPhone ? [0, 0, 0] : undefined}
        marginTop={isPhone ? [0, 0, 0] : undefined}
        p={[2, 2, 4, 8]}
      >
        <ModalHeader fontWeight="light" color="purple3.600" fontSize={24} px={[1, 1, 6]}>
          {t('roles.changeRoleHeader')}
        </ModalHeader>
        <Box display="flex" sx={{ userSelect: 'text' }} px={[1, 1, 6]}>
          <Text>
            {t('roles.changeRoleInstructions1')}
            <ChakraLink href="mailto:support@behaivior.com" isExternal>
              support@behaivior.com
            </ChakraLink>
            {t('roles.changeRoleInstructions2')}
          </Text>
        </Box>
        <ModalCloseButton />
        {user && (
          <Box display="flex" px={[1, 1, 6]} fontWeight="light" mt={8} fontSize={18} color="purple3.600" mb={2}>
            <Text>{user.role === UserRole.USER ? t('roles.pirSubheader') : t('roles.providerSubheader')}</Text>
          </Box>
        )}

        <ModalBody px={[1, 1, 6]}>
          <SelectLinkedUser onSelect={props.onSelect} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  const user = state.user.user;
  if (!user) {
    throw new Error('No user when there should be one');
  }

  return {
    user,
    pir: state.linkedUsers.pir,
    otherLinkedUsers: state.linkedUsers.otherLinkedUsers ?? [],
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ChangeLinkedUser);
