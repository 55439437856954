import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, IconButton, Box, Text, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { IconLookup, IconName } from '@fortawesome/fontawesome-svg-core';
import IMedication from '../modules/medications/interfaces/IMedication';
import IMeditation from '../modules/meditations/interfaces/IMeditation';
import { IResource } from '../db/resources';
import FAIcon from './FAIcon';

interface Props {
  moduleInterface: IMedication | IResource | IMeditation;
  modalName: string;
  toDisplayFunction: (o: any) => void;
  description?: string | undefined;
  name: string;
  icon?: IconName | IconLookup;
  deleteOpen: boolean;
  deleteFunction?(): void;
  width?: string[];
  bgColor: string;
  textColor: string;
}

const ModuleCard = (props: Props): JSX.Element => {
  return (
    <Flex minWidth="0px" flexGrow={0} flexShrink={0} flexBasis={props.width} paddingBottom="6px">
      <motion.div hidden={!props.deleteOpen} initial={false} animate={{ width: props.deleteOpen ? 50 : 0 }}>
        <IconButton
          aria-label="Delete Resource"
          icon={<FAIcon icon="minus-circle" />}
          size="lg"
          fontSize="32px"
          isRound={true}
          paddingTop="3px"
          onClick={props.deleteFunction}
        />
      </motion.div>
      <Link
        to={`/home/${props.modalName}/${props.moduleInterface.id}`}
        onClick={() => props.toDisplayFunction(props.moduleInterface)}
        style={{ flexGrow: 1 }}
      >
        <Box
          p={['10px', '25px']}
          rounded="10px"
          bg={props.bgColor}
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          height="100%"
        >
          <Flex flexDirection="column" height="100%" alignItems="flex-start" justifyContent="space-between">
            <VStack spacing="0px" alignItems="flex-start" justifyContent="flex-start">
              {props.icon ? (
                <FAIcon fontSize={['23px', '41px']} color={props.textColor} icon={props.icon} />
              ) : (
                <Box marginRight={4} />
              )}

              <Text
                as="span"
                textAlign="left"
                fontWeight="800"
                color={props.textColor}
                fontSize={['14px', '20px']}
                noOfLines={0}
              >
                {props.name}
              </Text>
            </VStack>
            {props.description && (
              <Text
                marginTop={['28px', '42px']}
                as="span"
                color={props.textColor}
                fontSize={['14px', '20px']}
                fontStyle="normal"
                fontWeight="400"
                noOfLines={1}
                textAlign="left"
              >
                {props.description.replace(/(^\w+:|^)\/\//, '')}
              </Text>
            )}
          </Flex>
        </Box>
      </Link>
    </Flex>
  );
};

export default ModuleCard;
