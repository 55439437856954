import firebase from 'firebase/compat/app';
import { LinkedUsersActions, LinkedUsersActionType } from './actions';
import ILinkedUser, {
  IIncompleteLinkedUser,
  IPirLinkedUser,
  IRejectedLinkedUser,
  ISupporterLinkedUser,
  PirUsersData,
  PossibleLinkedUser,
  UserFeatures,
} from './interfaces/ILinkedUser';
import { isPirLinkedUser } from './utils';

interface LinkedUsersState {
  loading: boolean;
  editLoading: boolean;
  pir: null | IPirLinkedUser;
  supportNetwork: null | (IIncompleteLinkedUser | ILinkedUser | ISupporterLinkedUser | IRejectedLinkedUser)[];
  otherLinkedUsers: null | (IIncompleteLinkedUser | ILinkedUser)[];
  invitesLoading: boolean;
  invites: null | IIncompleteLinkedUser[];
  selectedLinkedUser: null | PossibleLinkedUser;
  selectedLinkedUserFeatures?: UserFeatures;
  pirUnsubscribeFn: null | firebase.Unsubscribe;
  otherUnsubscribeFn: null | firebase.Unsubscribe;
  inviteUnsubscribeFn: null | firebase.Unsubscribe;
  dashboardDataLoading: boolean;
  dashboardData: PirUsersData[] | null;
}

const defaultState: LinkedUsersState = {
  loading: false,
  selectedLinkedUser: null,
  editLoading: false,
  pir: null,
  supportNetwork: null,
  otherLinkedUsers: null,
  invitesLoading: false,
  invites: null,
  pirUnsubscribeFn: null,
  otherUnsubscribeFn: null,
  inviteUnsubscribeFn: null,
  dashboardDataLoading: false,
  dashboardData: null,
};

const linkedUsersReducer = (state: LinkedUsersState = defaultState, action: LinkedUsersActions): LinkedUsersState => {
  switch (action.type) {
    case LinkedUsersActionType.GETTING_DASHBOARD_DATA:
      return {
        ...state,
        dashboardDataLoading: true,
      };

    case LinkedUsersActionType.GOT_DASHBOARD_DATA:
      return {
        ...state,
        dashboardDataLoading: false,
        dashboardData: action.dashboardData,
      };

    case LinkedUsersActionType.GOT_LU:
      return {
        ...state,
        loading: false,
      };

    case LinkedUsersActionType.GETTING_LU:
      return {
        ...state,
        loading: true,
      };

    case LinkedUsersActionType.SET_PIR_LU:
      if (action.pirLinkedUsers === null) {
        return {
          ...state,
          pir: null,
          supportNetwork: null,
        };
      }

      // eslint-disable-next-line no-case-declarations
      const pir = action.pirLinkedUsers.find((linkedUser): linkedUser is IPirLinkedUser => isPirLinkedUser(linkedUser));
      // eslint-disable-next-line no-case-declarations
      const supportNetwork = action.pirLinkedUsers.filter(
        (linkedUser): linkedUser is IIncompleteLinkedUser | ILinkedUser | ISupporterLinkedUser | IRejectedLinkedUser =>
          !isPirLinkedUser(linkedUser),
      );

      return {
        ...state,
        pir: pir ?? null,
        supportNetwork,
      };

    case LinkedUsersActionType.SET_OTHER_LU:
      if (action.otherLinkedUsers === null) {
        return {
          ...state,
          otherLinkedUsers: null,
        };
      }

      return {
        ...state,
        otherLinkedUsers: action.otherLinkedUsers,
      };

    case LinkedUsersActionType.SELECT_LU:
      return {
        ...state,
        selectedLinkedUser: action.linkedUser,
      };

    case LinkedUsersActionType.CREATING_LU:
      return {
        ...state,
        editLoading: true,
      };

    case LinkedUsersActionType.CREATED_LU:
      return {
        ...state,
        editLoading: false,
      };

    case LinkedUsersActionType.UPDATING_LU:
      return {
        ...state,
        editLoading: true,
      };

    case LinkedUsersActionType.UPDATED_LU:
      return {
        ...state,
        editLoading: false,
        selectedLinkedUser: action.linkedUser,
      };

    case LinkedUsersActionType.DELETING_LU:
      return {
        ...state,
        editLoading: true,
      };

    case LinkedUsersActionType.DELETED_LU:
      return {
        ...state,
        editLoading: false,
      };

    case LinkedUsersActionType.GOT_USER_FEATURES:
      return {
        ...state,
        selectedLinkedUserFeatures: action.userFeatures,
      };

    case LinkedUsersActionType.GETTING_INVITES:
      return {
        ...state,
        invitesLoading: true,
      };

    case LinkedUsersActionType.GOT_INVITES:
      return {
        ...state,
        invitesLoading: false,
        invites: action.invites,
      };

    case LinkedUsersActionType.SET_INVITES:
      return {
        ...state,
        invites: action.invites,
      };

    case LinkedUsersActionType.UPDATING_INVITE:
      return {
        ...state,
        invitesLoading: true,
      };

    case LinkedUsersActionType.UPDATED_INVITE:
      return {
        ...state,
        invitesLoading: false,
      };

    case LinkedUsersActionType.SET_UNSUBSCRIBE_FNS:
      return {
        ...state,
        pirUnsubscribeFn: typeof action.pirFn !== 'undefined' ? action.pirFn : state.pirUnsubscribeFn,
        otherUnsubscribeFn: typeof action.otherFn !== 'undefined' ? action.otherFn : state.otherUnsubscribeFn,
        inviteUnsubscribeFn: typeof action.inviteFn !== 'undefined' ? action.inviteFn : state.inviteUnsubscribeFn,
      };

    default:
      return state;
  }
};

export default linkedUsersReducer;
