import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Text, List, ListItem, Link, Flex, Image } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import { createInteraction } from '../../modules/interactions/actions';
import { moduleName, interactionType } from '../../modules/interactions/constants';
import { IResource } from '../../db/resources';
import { useQueryResources, useDeleteResources } from '../../modules/resources/queryHooks';
import DisplayWebLink from '../DisplayWebLink';
import { getAttachmentType, getUrlType } from 'src/modules/meditations/utils';
import { isYoutube, youtube_parser } from '../ManageMeditationModal/utils';
import TextResourceBox from '../TextResourceBox';

interface SuppliedProps {
  onSelectResource(resource: IResource | null): void;
}

type Props = SuppliedProps;

const ResourceListDisplay = ({
  pir,
  createInteraction,
  onSelectResource,
}: Props & PropsFromRedux): null | React.ReactElement => {
  const { t } = useTranslation(['resources', 'common']);
  const { data: resources = [] } = useQueryResources(pir?.id);
  const { mutate: deleteResource } = useDeleteResources(pir?.id);

  const onDeleteResource = (resources: IResource): void => {
    if (window.confirm(t('common:manageContactModal.deleteResourceConfirmation'))) {
      deleteResource(resources);
      createInteraction({
        pir,
        dateTime: new Date(),
        moduleName: moduleName.RESOURCES,
        interactionType: interactionType.RESOURCES.DELETED,
      });
    }
  };

  const openEdit = (resource: IResource) => {
    onSelectResource({ ...resource });
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.RESOURCES,
      interactionType: interactionType.RESOURCES.EDITED,
    });
  };

  if (resources.length === 0) {
    return null;
  }

  return (
    <Flex width="100%" direction="column" justifyContent="left">
      {resources.map((resource, index) => (
        <Box
          width="100%"
          backgroundColor="grey6.50"
          pt={4}
          pb={0}
          pr={4}
          pl={4}
          mb={3}
          borderRadius={8}
          key={'rld' + index}
          data-test={resource.title}
        >
          <List as="ol" mb={5}>
            <ListItem mb={3} data-test={resource.title + '-title'}>
              <Flex justifyContent={'space-between'}>
                <Text fontWeight="bold" fontSize={18} mb={3} mr={5}>
                  {resource.title}
                </Text>
                <Text textAlign="right" fontSize={14}>
                  <Link
                    onClick={() => openEdit(resource)}
                    textDecoration="underline"
                    _hover={{
                      textDecoration: 'none',
                    }}
                    data-test={resource.title + '-edit'}
                  >
                    {t('resources:resourceListDisplay.editLink')}
                  </Link>
                  <Link
                    ml={2}
                    textDecoration="underline"
                    onClick={() => onDeleteResource(resource)}
                    _hover={{ textDecoration: 'none' }}
                    data-test={resource.title + '-delete'}
                  >
                    {t('resources:resourceListDisplay.deleteLink')}
                  </Link>
                </Text>
              </Flex>
            </ListItem>
            <ListItem mb={3} fontSize={14} data-test={resource.title + '-notes'}>
              <TextResourceBox isEdit={true} textContent={resource.note ? resource.note : ""}/>
            </ListItem>

            {resource.link &&
              (getUrlType(resource.link) === 'link' ? (
                <ListItem mb={3} fontSize={14}>
                  <DisplayWebLink
                    href={resource.link}
                    linkText={`${resource.link.slice(0, 30)}${resource.link.length > 30 ? '...' : ''}`}
                  />
                </ListItem>
              ) : (
                <ListItem mb={3} fontSize={14}>
                  {isYoutube(resource.link) && youtube_parser(resource.link) ? (
                    <Image
                      src={`https://img.youtube.com/vi/${youtube_parser(resource.link)}/default.jpg`}
                      alt={resource.attachmentName}
                      boxSize="100px"
                      objectFit="cover"
                    />
                  ) : (
                    <Text>
                      {resource.link.slice(0, 30)}${resource.link.length > 30 ? '...' : ''}
                    </Text>
                  )}
                </ListItem>
              ))}

            {resource.attachmentUrl &&
              resource.attachmentName &&
              (getAttachmentType(resource.attachmentName) === 'video' ? (
                <ListItem mb={3} fontSize={14}>
                  <Text> {resource.attachmentName}</Text>
                </ListItem>
              ) : getAttachmentType(resource.attachmentName) === 'image' ? (
                <ListItem mb={3} fontSize={14}>
                  <Image src={resource.attachmentUrl} alt={resource.attachmentName} boxSize="100px" objectFit="cover" />
                </ListItem>
              ) : (
                <ListItem mb={3} fontSize={14}>
                  <DisplayWebLink href={resource.attachmentUrl} linkText={resource.attachmentName} />
                </ListItem>
              ))}

            {/* Oonly Able to View PDFs on Web Platoforms for now  */}
            {/* {!window.cordova && resource.attachmentUrl && resource.resourceType === "pdf" && (
              <ListItem mb={3}>
                <iframe  src={resource.attachmentUrl} width="100%"/>
              </ListItem>
            )} */}

            <ListItem mb={3} fontSize={14}>
              {resource.attachmentUrl && resource.attachmentName && resource.link
                ? `${getUrlType(resource.link).charAt(0).toUpperCase() + getUrlType(resource.link).slice(1)} & ${
                    getAttachmentType(resource.attachmentName).charAt(0).toUpperCase() +
                    getAttachmentType(resource.attachmentName).slice(1)
                  }`
                : resource.resourceType === 'pdf'
                ? resource.resourceType.toUpperCase()
                : resource.resourceType.charAt(0).toUpperCase() + resource.resourceType.slice(1)}
            </ListItem>
          </List>
        </Box>
      ))}
    </Flex>
  );
};

//Connected Component Pieces
const mapStateToProps = (state: RootState) => {
  const { selectedLinkedUser } = state.linkedUsers;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when seeing this component.',
    );
  }

  return {
    pir: selectedLinkedUser.pir,
  };
};

const mapDispatchToProps = {
  createInteraction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ResourceListDisplay);
