import React, { useState } from 'react';

type dragDropReturn = {
  setFileDropError: React.Dispatch<React.SetStateAction<string>>;
  setDragOver: React.Dispatch<React.SetStateAction<boolean>>;
  onDragLeave: (e: React.SyntheticEvent) => void;
  onDragOver: (e: React.SyntheticEvent) => void;
  fileDropError: string;
  dragOver: boolean;
};

export const useDragAndDrop = (isSubmitting: boolean): dragDropReturn => {
  const [dragOver, setDragOver] = useState<boolean>(false);
  const [fileDropError, setFileDropError] = useState<string>('');

  const onDragOver = (e: React.SyntheticEvent) => {
    e.preventDefault();
    !isSubmitting && setDragOver(true);
  };

  const onDragLeave = () => setDragOver(false);

  return {
    dragOver,
    setDragOver,
    onDragOver,
    onDragLeave,
    fileDropError,
    setFileDropError,
  };
};
