export default (url: string): string => {
  const reg = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=$]*)/gi;
  if (reg.test(url)) url = url.slice(8);
  return new URL('', `https://${url}`).href;
};

export const youtube_parser = (url: string): string => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : '';
};

export const isYoutube = (url: string): boolean => {
  return url.includes('youtube') || url.includes('youtu.be');
};
