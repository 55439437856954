import { getVitalsGraph, VitalsGraphMapFunction } from '../../db/vitals';
import { IDataPoint } from './interfaces/IVitalsGraph';
import { getSummarySurveys, ISurveyDataPoint } from '../../db/summarySurveys';
import { getCravingPredictions } from '../../db/cravingPredictions';

export type GraphType = 'participation' | 'incidents' | 'sleep' | 'stress' | 'craving' | 'emotion' | 'watchuse';

const mapFunctions: { [key in GraphType]?: VitalsGraphMapFunction } = {
  incidents: (value) => Math.round(value), // mock data historically generated float numbers, round them
};

export const queryFunctionForGraph = async (
  graphType: GraphType,
  userId: string | null,
): Promise<IDataPoint[] | ISurveyDataPoint[]> => {
  switch (graphType) {
    case 'emotion': {
      return getSummarySurveys(userId);
    }
    case 'craving': {
      return getCravingPredictions(userId);
    }
    default: {
      return getVitalsGraph(userId, graphType, mapFunctions[graphType]);
    }
  }
};

interface VitalsGraphTab {
  days: number;
  title: string;
}

const vitalsGraphHourlyTab: VitalsGraphTab = {
  days: 1,
  title: 'Hourly',
};

const vitalsGraphWeeklyTab: VitalsGraphTab = {
  days: 7,
  title: 'Weekly',
};

const vitalsGraphMonthlyTab: VitalsGraphTab = {
  days: 28,
  title: 'Monthly',
};

type VitalsGraphOptions = {
  [key in GraphType]: VitalsGraphTab[];
};

export const vitalsGraphOptionsByGraphType: VitalsGraphOptions = {
  craving: [vitalsGraphWeeklyTab],
  emotion: [vitalsGraphHourlyTab, vitalsGraphWeeklyTab, vitalsGraphMonthlyTab],
  incidents: [vitalsGraphHourlyTab, vitalsGraphWeeklyTab, vitalsGraphMonthlyTab],
  participation: [vitalsGraphWeeklyTab, vitalsGraphMonthlyTab],
  sleep: [vitalsGraphWeeklyTab, vitalsGraphMonthlyTab],
  stress: [vitalsGraphHourlyTab, vitalsGraphWeeklyTab, vitalsGraphMonthlyTab],
  watchuse: [vitalsGraphWeeklyTab, vitalsGraphMonthlyTab],
};

export type HiddenGraphs = {
  [key in GraphType]: boolean;
};

export const permanentlyVisibleGraphs: GraphType[] = [
  'participation',
  'sleep',
  'stress',
  'incidents',
  'emotion',
  'watchuse',
];
