import React from 'react';
import {
  Flex,
  Text,
  Box,
  VStack,
} from '@chakra-ui/react';
import ISupportRequest from '../../db/supportRequests';
import { useTranslation } from 'react-i18next';
import FAIcon from '../../components/FAIcon';

interface Props {
  requests: ISupportRequest[];
  onSelect?: (supportRequest: ISupportRequest) => void;
}

const SupportRequestsPreview = (props: Props): React.ReactElement => {
  const { requests, onSelect } = props;
  const { t } = useTranslation('support_requests');

  return (
    <Box py="28px" px="34px" bg="white" paddingLeft="10px" paddingRight="10px" boxShadow="0px 3px 5px 0px rgba(0, 0, 0, 0.3)" borderRadius="8px">
      <Flex
        fontSize="14px"
        textAlign="left"
        mb="17px"
        pb={3}
        borderBottom="1px solid #8F8F8F"
        textTransform="uppercase"
        overflow="hidden"
      >
        <Text w="115px" pl={['10px', '10px']} color="#8F8F8F" fontWeight={600}>{t('fieldsHeading.dateTime')}</Text>
        <Text w="160px" flexGrow={1} px={['15px', '30px']} color="#8F8F8F" fontWeight={600}>{t('fieldsHeading.whatSupportNeeded')}</Text>
        <Text w="130px" px={['40px', '13px']}  paddingLeft="10px" paddingRight="10px" color="#8F8F8F" fontWeight={600}>{t('fieldsHeading.status')}</Text>
        
      </Flex>

      <VStack width="100%" spacing="10px">
        {requests.map((supportRequest: ISupportRequest, index: number) => (
          <Flex
            alignItems="center"
            fontWeight={400}
            py="6px"
            cursor={onSelect ? 'pointer' : 'default'}
            onClick={onSelect ? (() => onSelect(supportRequest)) : undefined}
            width="100%"
            height="50px"
            key={index}
            color="purple3.600"
            bg="purple3.100"
            borderRadius="lg"
            overflow="hidden"
          >
            <Text w="95px" flexShrink={0} fontSize="16px" pl={['10px', '10px']} noOfLines={1} textOverflow="ellipsis">
              {supportRequest.dateTime?.toLocaleDateString()}
            </Text>
            <Text w="160px" flexGrow={1} px={['15px', '30px']} noOfLines={1}>
              {supportRequest.whatSupportNeeded || <i>{t('requestsModule.whatSupportNeededDefault')}</i>}
            </Text>
            <Text flexShrink={0} px={['13px', '13px']} noOfLines={1}  >
              {t(`statusDescr.${supportRequest.status}`)}
            </Text>
            <Box w="25px" display={onSelect ? 'block' : 'none'} flexShrink={0}>
              <FAIcon icon="angle-right" />
            </Box>
          </Flex>
        ))}
      </VStack>
    </Box>
  );
};

export default SupportRequestsPreview;
