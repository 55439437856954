import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Text, Link, List, ListItem, Image } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import { getMotivator, deleteMotivator, selectMotivator } from '../../modules/motivator/actions';
import { createInteraction } from '../../modules/interactions/actions';
import { moduleName, interactionType } from '../../modules/interactions/constants';
import IMotivator from '../../modules/motivator/interfaces/IMotivator';
import FAIcon from './../FAIcon';

export const imageOrIcon = (motivator: IMotivator): React.ReactElement => {
  if (motivator.attachmentUrl) {
    return (
      <Image
        boxSize="125px"
        objectFit="cover"
        alignItems="center"
        mb={3}
        src={motivator.attachmentUrl}
        fallbackSrc="https://via.placeholder.com/150"
      />
    );
  } else {
    return <FAIcon icon="user-circle" as="div" fontSize={60} lineHeight="1em" color="grey3.400" />;
  }
};

const MotivatorDisplay = ({
  motivator,
  pir,
  deleteMotivator,
  selectMotivator,
  createInteraction,
}: PropsFromRedux): null | React.ReactElement => {
  const { t } = useTranslation(['motivator', 'common']);
  const onDeleteMotivator = (motivator: IMotivator): void => {
    if (window.confirm(t('common:manageContactModal.deleteMotivatorConfirmation'))) {
      deleteMotivator(motivator);
      createInteraction({
        pir,
        dateTime: new Date(),
        moduleName: moduleName.MOTIVATOR,
        interactionType: interactionType.MOTIVATOR.DELETED,
      });
    }
  };

  const openEdit = (motivator: IMotivator) => {
    selectMotivator(motivator);
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MOTIVATOR,
      interactionType: interactionType.MOTIVATOR.EDITED,
    });
  };

  return (
    <Box>
      {motivator.map((motivator, index) => (
        <Box width="100%" border={['1', 'solid', 'black']} backgroundColor="grey6.50" p={4} mb={3} key={'md' + index} borderRadius={8}>
          <Text textAlign="right" fontSize={12}>
            <Link
              onClick={() => openEdit(motivator)}
              textDecoration="underline"
              _hover={{
                textDecoration: 'none',
              }}
            >
              {t('motivator:motivatorDisplay.editLink')}
            </Link>
            <Link
              ml={2}
              textDecoration="underline"
              onClick={() => onDeleteMotivator(motivator)}
              _hover={{ textDecoration: 'none' }}
            >
              {t('motivator:motivatorDisplay.deleteLink')}
            </Link>
          </Text>
          <List as="ol" mb={5}>
            <ListItem mb={3} fontWeight="bold">
              {' '}
              {t('motivator:motivatorDisplay.doItFor')}
              {motivator.message}
            </ListItem>
            <Box>{imageOrIcon(motivator)}</Box>
          </List>
        </Box>
      ))}
    </Box>
  );
};

const mapStateToProps = (state: RootState) => {
  const { motivator } = state.motivator;
  if (motivator === null) {
    throw new Error("Motivator shouldn't be null!");
  }
  if (!motivator) {
    throw new Error('There is no motivator to delete.');
  }
  const { selectedLinkedUser } = state.linkedUsers;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when seeing this component.',
    );
  }

  return {
    motivator,
    pir: selectedLinkedUser.pir,
  };
};

const mapDispatchToProps = {
  getMotivator,
  deleteMotivator,
  selectMotivator,
  createInteraction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MotivatorDisplay);
