import Firestore, { FirestoreUtils } from '../modules/firestore/Firestore';

import { IDataPoint } from '../modules/vitals/interfaces/IVitalsGraph';

export interface IVitalsTable {
  [index: string]: number;
  sleep: number;
  craving: number;
  emotion: number;
  incidents: number;
  participation: number;
  watchuse: number;
  stress: number;
  supportRequests: number;
}

export const defaultVitalsTable: IVitalsTable = {
  sleep: -999,
  craving: -999,
  emotion: -999,
  incidents: -999,
  participation: -999,
  watchuse: -999,
  stress: -999,
  supportRequests: -999,
};

interface ICollectionMap {
  [index: string]: string;
}

const collectionMap: ICollectionMap = {
  sleep: 'sleepGraph',
  stress: 'stressGraph',
  participation: 'participationGraph',
  watchuse: 'watchUseGraph',
  incidents: 'incidentsGraph',
};

export type VitalsGraphMapFunction = (value: number) => number;

/**
 * Return all of the data for a single Vitals Graph
 * @param id The unique Firestore ID for a person
 * @param graphType The type of graph to be displayed
 * @param mapFunc A mapping function if value conversion is required
 * @returns a list of data points of the type IVitalsGraph[]
 */
export const getVitalsGraph = async (
  id: string | null,
  graphType: string,
  mapFunc: VitalsGraphMapFunction | undefined,
): Promise<IDataPoint[]> => {
  if (id === null) {
    return [];
  }
  const collection = collectionMap[graphType];
  const query = await Firestore.collection(collection)
    .where('pir', '==', FirestoreUtils.getDocRef('users', id))
    .limit(1)
    .get();
  if (query.empty) {
    return [];
  }

  const queryData = query.docs[0].data();
  const data = (queryData.data || []).map((dataPoint: any) => {
    return {
      dateTime: dataPoint.dateTime.toDate(),
      value: mapFunc ? mapFunc(dataPoint.value) : dataPoint.value,
    };
  });
  return data;
};

/**
 * Get the data for the vitals summary table for a single individual
 * @param id the id of an individual as a string
 * @returns a number for each of the vitals types
 */
export const getVitalsTable = async (id: string | null): Promise<IVitalsTable> => {
  if (id === null) {
    return defaultVitalsTable;
  }

  try {
    const query = await Firestore.collection('weeklyVitalsAggregates')
      .where('pir', '==', FirestoreUtils.getDocRef('users', id)) //FirestoreUtils.getDocRef('users', pirId))
      .limit(1)
      .get();
    if (query.empty) {
      return defaultVitalsTable;
    }

    const queryData = query.docs[0].data();
    return {
      sleep: queryData.avgSleep > -1 ? queryData.avgSleep : -999,
      craving: queryData.avgCravingRisk > -1 ? queryData.avgCravingRisk : -999,
      emotion: queryData.avgEmotionalScore > -11 ? queryData.avgEmotionalScore : -999,
      stress: queryData.avgStress > -1 ? queryData.avgStress : -999,
      incidents: queryData.totalIncidents > -1 ? queryData.totalIncidents : 0,
      participation: queryData.avgParticipation > -1 ? queryData.avgParticipation : -999,
      watchuse: queryData.avgWatchUse > -1 ? queryData.avgWatchUse : -999,
      supportRequests: queryData.totalSupportRequests > -1 ? queryData.totalSupportRequests : 0,
    };
  } catch (err) {
    console.error(`Error getting weeklyVitalsAggregates: ${err}`);
    return {
      sleep: -999,
      craving: -999,
      emotion: -999,
      stress: -999,
      incidents: 0,
      watchuse: -999,
      supportRequests: 0,
      participation: 0,
    };
  }
};
