import React, { useState, useEffect, useContext, useRef } from 'react';
import TableAppView from './TableAppView';
import TableWebView from './TableWebView';
import { Avatar, AccordionIcon } from '@chakra-ui/react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux/store';
import { PossibleLinkedUser } from '../linked-users/interfaces/ILinkedUser';
import { selectLinkedUser, updateLinkedUser } from '../linked-users/actions';
import { getByRef as getUserByRef } from '../user/utils';
import { COLUMNS, COLUMNSAPPVIEW, COLUMNSTABLETVIEW } from './data2';
import Firestore from '../firestore/Firestore';
import ISupportRequest, { ISupportRequestPossible, formatSupportRequestFromFirestore } from '../../db/supportRequests';
import { filterRequestsRecentSort } from '../supportRequests/utils';
import { SelectedUserContext } from '../interior/Index';
import { getUser } from '../../db/user';
import IWeeklyVitalsAggregates from '../summaryTable/interfaces/IWeeklyVitalsAggregates';
import { formatWeeklyAggregatesFromFirestore } from '../summaryTable/utils';
import { useDevice } from 'src/DeviceContext';
import { useWindowSize } from 'src/hooks/utils';

export interface PirUsersData {
  [key: string]: any;
  linkedUser?: PossibleLinkedUser;
  person?: string;
  craving?: number | string;
  participation?: number | string;
  watchUse?: number | string;
  sleep?: number | string;
  emotion?: number | string;
  stress?: number | string;
  incidents?: number | string;
  supportRequests?: ISupportRequest[];
  image?: JSX.Element;
  arrow?: JSX.Element;
}
export interface SupportRequest {
  pirName: string;
  message: string;
}

interface SuppliedProps {
  onSelect?(): void;
}

type Props = SuppliedProps & PropsFromRedux;
const LinkedUsers = ({ linkedUsers, onSelect, selectLinkedUser, loggingOut }: Props): JSX.Element => {
  const [tableData, setTableData] = useState<PirUsersData[] | null>(null);
  const { selectedUser, setSelectedUser } = useContext(SelectedUserContext);
  const componentMountedRef = useRef(true);
  const [loading, setLoading] = useState(true);
  const { isTablet } = useDevice();
  const dimensions = useWindowSize();

  const onSelectUser = async (linkedUser: PossibleLinkedUser): Promise<void> => {
    const {
      pir: { id: pirId },
    } = linkedUser as { pir?: any };

    await selectLinkedUser(linkedUser);

    if (selectedUser?.id !== pirId) {
      setSelectedUser(await getUser(pirId as string));
    }
    if (onSelect) {
      onSelect();
    }
  };

  useEffect(() => {
    componentMountedRef.current = true;
    const getData = async () => {
      try {
        // we can drop in a replacement getAction below instead of looping through this map
        // once we get a summary collection
        const dataUsers = linkedUsers.map(async (linkedUser): Promise<PirUsersData> => {
          if (linkedUser.pir && !loggingOut) {
            // && linkedUser.pir?.id === pir?.id // not sure if we still need this
            const pir = await getUserByRef(linkedUser.pir);
            //console.log('pir', pir);
            const dataSnapshot = await Firestore.collection('weeklyVitalsAggregates')
              .where('pir', '==', linkedUser.pir)
              .limit(1)
              .get();

            // Getting support requests using PIR survey results
            const surveySnapshot = await Firestore.collection('surveys').where('pir', '==', linkedUser.pir).get();
            let requests: ISupportRequest[] = [];
            surveySnapshot.forEach((doc) => {
              const needHelp: boolean = doc.get('needHelp');
              const request: ISupportRequestPossible = formatSupportRequestFromFirestore(doc);
              if (needHelp) {
                requests.push(request as ISupportRequest);
              }
            });
            requests = filterRequestsRecentSort(requests);

            const weeklyAggregateResults: IWeeklyVitalsAggregates[] = [];
            dataSnapshot.docs.map((data) => {
              return weeklyAggregateResults.push(formatWeeklyAggregatesFromFirestore(data));
            });

            if (weeklyAggregateResults.length > 0) {
              const pirData = weeklyAggregateResults[0];
              if (pir?.name) {
                return {
                  linkedUser,
                  person: linkedUser.pirAlias || linkedUser.preferredName || pir.name,
                  pirAlias: linkedUser.pirAlias,
                  preferredName: pir.name,
                  craving: pirData.avgCravingRisk,
                  participation: pirData.avgParticipation.toFixed(2),
                  watchUse: Math.round(pirData.avgWatchUse),
                  sleep: pirData.avgSleep,
                  emotion: pirData.avgEmotionalScore,
                  stress: pirData.avgStress,
                  incidents: Math.round(pirData.totalIncidents),
                  supportRequests: requests,
                  image: (
                    <Avatar
                      size="md"
                      name={linkedUser.pirAlias || linkedUser.preferredName || pir.name}
                      src={pir?.image}
                    />
                  ),
                  arrow: <AccordionIcon />,
                  arrow_left: <AccordionIcon />,
                  arrow_right: <AccordionIcon />,
                };
              }
            } else if (pir?.name) {
              // people with pir returns empty
              // eslint-disable-next-line no-console

              return {
                linkedUser,
                person: linkedUser.pirAlias || linkedUser.preferredName || pir.name,
                pirAlias: linkedUser.pirAlias,
                preferredName: pir.name,
                craving: -999, //cravingRiskValue,
                participation: -999, //participationValue,
                watchUse: -999, //watchUseValue
                sleep: -999, //sleepValue,
                emotion: -999, //emotionalScoreValue,
                stress: -999, //stressValue,
                incidents: 0, //incidentsValue,
                supportRequests: requests,
                image: (
                  <Avatar
                    size="md"
                    name={linkedUser.pirAlias || linkedUser.preferredName || pir.name}
                    src={pir?.image}
                  />
                ),
                arrow: <AccordionIcon />,
                arrow_left: <AccordionIcon />,
                arrow_right: <AccordionIcon />,
              };
            } else {
              return {};
            }
          }
          return {};
        });

        const promisedData = await Promise.all(dataUsers);
        //console.log('promisedData', promisedData);
        if (componentMountedRef.current) {
          setTableData(promisedData.filter((data) => !!data.person));
          setLoading(false);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`There was an error getting the data: ${error}`);
        if (componentMountedRef.current) {
          setLoading(false);
        }
      }
    };

    getData();

    return () => {
      componentMountedRef.current = false;
    };
  }, [linkedUsers]);

  if (tableData == null || loading === true) {
    return <p>Loading</p>;
  } else if (tableData.length === 0) {
    return <p>No patients were found</p>;
  } else if (dimensions.width >= 1175 || (isTablet && dimensions.width >= 1080)) {
    return <TableWebView columnsData={COLUMNS} tableData={tableData} onSelect={onSelectUser} />;
  } else if (dimensions.width >= 425) {
    return (
      <TableWebView columnsData={COLUMNSTABLETVIEW} tableData={tableData} onSelect={onSelectUser} tabletView={true} />
    );
  } else {
    return <TableAppView columnsData={COLUMNSAPPVIEW} tableData={tableData} onSelect={onSelectUser} />;
  }
};

const mapStateToProps = (state: RootState) => {
  const otherLinkedUsers = state.linkedUsers.otherLinkedUsers;

  const linkedUsers = otherLinkedUsers || [];

  return {
    linkedUsers,
    loggingOut: state.auth.loggingOut,
  };
};
const mapDispatchToProps = {
  selectLinkedUser,
  updateLinkedUser,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(LinkedUsers);
