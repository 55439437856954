import React, { useState, useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Flex, FlexProps, Skeleton, Text, Button } from '@chakra-ui/react';
import { IIncompleteLinkedUser, LinkedUserRole } from '../../modules/linked-users/interfaces/ILinkedUser';
import IUser from '../../modules/user/interfaces/IUser';
import { getByRef } from '../../modules/user/utils';
import PrimaryButton from '../PrimaryButton';
import { acceptOrDenyInvite } from '../../modules/linked-users/actions';

interface SuppliedProps extends FlexProps {
  invite: IIncompleteLinkedUser;
}

type Props = SuppliedProps & PropsFromRedux;

interface ILoadingUser {
  loading: true;
}

// null if user not found
const isLoading = (user: ILoadingUser | IUser | null | undefined): user is ILoadingUser => {
  if (!user) {
    return false;
  }

  if ((user as ILoadingUser).loading) {
    return true;
  }

  return false;
};

const Invite = (props: Props): JSX.Element | null => {
  const { invite, acceptOrDenyInvite, ...restOfProps } = props;
  const [invitingUser, setInvitingUser] = useState<ILoadingUser | IUser | null>({ loading: true });
  const [acceptDenyLoading, setAcceptDenyLoading] = useState(false);
  const { t } = useTranslation('common');
  const preferredName = 'preferredName' in invite && invite.preferredName;
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    if (invite.pir) {
      getByRef(invite.pir)
        .then((v) => {
          if (isMounted.current) setInvitingUser(v);
        })
        .catch((e) => console.error(`could not get user by ref in invite: ${e}`));
    } else {
      console.error('Invite without PIR', invite);
    }
    return () => {
      isMounted.current = false;
    };
  }, [invite]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const roleDisplay = (role: LinkedUserRole): string => {
    switch (role) {
      case LinkedUserRole.PROVIDER:
        return t('inviteList.providerDisplay');
      case LinkedUserRole.SUPPORTER:
        return t('inviteList.supporterDisplay');
      default:
        return t('inviteList.unknownDisplay');
    }
  };

  const acceptInvite = async (): Promise<void> => {
    try {
      setAcceptDenyLoading(true);
      await acceptOrDenyInvite(invite, true);
      if (isMounted.current) {
        setAcceptDenyLoading(false);
      }
    } catch (e) {
      if (isMounted.current) {
        setAcceptDenyLoading(false);
      }
      console.error(`Error accepting invite: ${e}`);
    }
  };

  const denyInvite = async (): Promise<void> => {
    try {
      setAcceptDenyLoading(true);
      await acceptOrDenyInvite(invite, false);
      if (isMounted.current) {
        setAcceptDenyLoading(false);
      }
    } catch (e) {
      if (isMounted.current) {
        setAcceptDenyLoading(false);
      }
      console.error(`Error denying invite: ${e}`);
    }
  };

  if (invitingUser === null) {
    return null;
  }

  return (
    <Flex
      justifyContent="space-between"
      flexDir={['column', 'column', 'row']}
      backgroundColor="grey6.50"
      py={2}
      px={4}
      {...restOfProps}
    >
      {isLoading(invitingUser) && <Skeleton h={6} w="100%" mt={2} mb={2} />}

      {!isLoading(invitingUser) && (
        <>
          <Text mt="auto" mb="auto">
            {t('inviteList.inviteText', {
              name: preferredName ?? invitingUser.name ?? invitingUser.email,
              role: invite.otherUserRole ? roleDisplay(invite.otherUserRole) : '',
            })}
          </Text>

          <Box
            display={['flex', 'flex', 'block']}
            justifyContent={['space-between', 'space-between', null]}
            mt={[3, 3, 0]}
            minW={150}
          >
            <Button
              onClick={() => {
                denyInvite();
              }}
              variant="link"
              mr={4}
            >
              Deny
            </Button>

            <PrimaryButton
              onClick={() => {
                acceptInvite();
              }}
              isLoading={acceptDenyLoading}
            >
              Accept
            </PrimaryButton>
          </Box>
        </>
      )}
    </Flex>
  );
};

const connector = connect(() => ({}), {
  acceptOrDenyInvite,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Invite);
