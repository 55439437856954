import AppThunk, { AppThunkPromise, AppThunkSync } from '../../redux/interfaces/AppThunk';
import { startAuthStateListener, RegisterAuthStateListenerAction } from '../auth/actions';
import { getRemoteConfigWrapper } from '../firebase/remoteConfig';
import { Action } from 'redux';

export enum RemoteConfigActionTypes {
  ENABLED_GRAPHS_LOADED = 'ENABLED_GRAPHS_LOADED',
}

export enum DynamicLinksActionTypes {
  INITIAL_LINK_CONSUMED = 'INITIAL_LINK_CONSUMED',
  INITIAL_LINK_LOADED = 'INITIAL_LINK_LOADED',
}

/**
 * Starts our auth state listener
 */
export const initLoadUser = (): AppThunk<void, RegisterAuthStateListenerAction> => {
  return (dispatch) => {
    // Starts our firebase auth state listener and will populate the store if the user already has a session
    dispatch(startAuthStateListener());
  };
};

export interface EnabledGraphsLoadedAction extends Action<RemoteConfigActionTypes.ENABLED_GRAPHS_LOADED> {
  enabledGraphs: string[];
}

export const initLoadEnabledGraphs = (
  isMobileDeviceAndCordova: boolean,
): AppThunkPromise<EnabledGraphsLoadedAction> => {
  return async (dispatch) => {
    console.log('Loading remote config feature flags');
    try {
      const remoteConfig = getRemoteConfigWrapper(isMobileDeviceAndCordova);
      await remoteConfig.fetchAndActivate();

      const enabledGraphsAsString = await remoteConfig.getString('enabled_graphs');
      const enabledGraphs = enabledGraphsAsString.split(',');

      return dispatch({
        type: RemoteConfigActionTypes.ENABLED_GRAPHS_LOADED,
        enabledGraphs,
      });
    } catch (err) {
      console.error('error loading enabled graphs', err);
      throw err;
    }
  };
};

export interface InitialLinkLoadedAction extends Action<DynamicLinksActionTypes.INITIAL_LINK_LOADED> {
  initialLinkUrl?: string;
}

export const loadInitialLink = (isMobileDeviceAndCordova: boolean): AppThunkPromise<InitialLinkLoadedAction> => {
  return async (dispatch) => {
    let initialLinkUrl: string | undefined = undefined;

    if (isMobileDeviceAndCordova) {
      console.log('Loading initial dynamic link');

      const payload = await window.cordova.plugins.firebase.dynamiclinks.getDynamicLink();
      if (payload !== null) {
        initialLinkUrl = payload.deepLink ?? undefined;
      }
    }

    return dispatch({
      type: DynamicLinksActionTypes.INITIAL_LINK_LOADED,
      initialLinkUrl,
    });
  };
};

export type ConsumeInitialLinkAction = Action<DynamicLinksActionTypes.INITIAL_LINK_CONSUMED>;

export const consumeInitialLink = (): AppThunkSync<ConsumeInitialLinkAction> => {
  return (dispatch) => {
    return dispatch({
      type: DynamicLinksActionTypes.INITIAL_LINK_CONSUMED,
    });
  };
};
