import { Box, Text, Heading, Flex, HStack } from '@chakra-ui/react';
import React from 'react';
import { getVitalsTable } from '../../db/vitals';
import { useContext } from 'react';
import { SelectedUserContext, UserContext } from '../interior/Index';
import { useQuery } from 'react-query';
import FAIcon from '../../components/FAIcon';
import { useTranslation } from 'react-i18next';
import {
  healthEstimateScore,
  emotionCardTranslationKey,
  cravingCardTranslationKey,
  stressCardTranslationKey,
  sleepCardTranslationKey,
  participationCardTranslationKey,
} from '../dashboardMonitor/data2';

type VitalCardProps = {
  icon: string;
  title: string;
  children: React.ReactElement;
  beta?: boolean;
};

/**
 * A vital card for the vital summary component
 * @param props an icon, title, and children element(s)
 * @returns a vital card
 */
const VitalCard = (props: VitalCardProps): React.ReactElement => {
  return (
    <Flex
      backgroundColor="#DBD1E6"
      height={['145.5px', '243px']}
      width={['108px', '192px']}
      p={['10px', '23px']}
      color="#5C4279"
      borderRadius="10px"
      boxShadow="0px 4px 4px 0px #00000040"
      direction="column"
      flexShrink={0}
      marginBottom="6px"
    >
      {props.beta ? (
        <Flex direction="row" alignItems="center">
          <FAIcon icon={props.icon} fontSize={[23, 45]} margin="0" />
          <Text
            marginLeft="auto"
            order={2}
            fontWeight="bold"
            backgroundColor="#5C4279"
            width="fit-content"
            borderRadius="full"
            pl="2"
            pr="2"
            color="white"
            fontSize={['14px', '20px']}
          >
            Beta
          </Text>
        </Flex>
      ) : (
        <FAIcon icon={props.icon} fontSize={[23, 45]} margin="0" />
      )}
      <Text fontWeight="bold" fontSize={['14px', '20px']}>
        {props.title}
      </Text>
      {props.children}
    </Flex>
  );
};

const VitalsSummaryModule = (): React.ReactElement => {
  const { t } = useTranslation('vitals');
  const user = useContext(UserContext);
  const { selectedUser } = useContext(SelectedUserContext);
  const uid = selectedUser?.id || (user ? user.id : null);

  const { data } = useQuery('vitalsTable-' + uid, async () => getVitalsTable(uid), {
    staleTime: 60 * 60 * 1000,
  });

  if (!data) {
    return <div>Loading</div>;
  }

  function healthEstimateText(
    craving: number,
    emotion: number,
    stress: number,
    sleep: number,
    incidents: number,
  ): string {
    const score: number = healthEstimateScore(craving, emotion, stress, sleep, incidents);

    if (score === -1) return 'N/A';

    if (score <= 100 && score >= 90) {
      return t('vitalsSummary.healthEstimateLevels.flourishing');
    } else if (score <= 89 && score >= 70) {
      return t('vitalsSummary.healthEstimateLevels.coping');
    } else if (score <= 69 && score >= 50) {
      return t('vitalsSummary.healthEstimateLevels.struggling');
    }

    return t('vitalsSummary.healthEstimateLevels.highRisk');
  }

  return (
    <>
      <Heading fontSize={['18px', '20px']} as="h3" marginBottom="30px" textColor="#4F4F4F">
        {t('vitalsSummary.heading')}
      </Heading>
      <HStack spacing={['8px', '30px']} overflowX="auto" alignItems="stretch" justifyContent="flex-start">
        <VitalCard
          title={t('vitalsSummary.headers.healthEstimate')}
          icon="plus-square"
          key="healthEstimate"
          beta={true}
        >
          <Box marginTop="auto">
            <Flex flexDirection="row" alignItems="baseline">
              <Text fontSize={['20px', '30px']} paddingRight="5px" fontWeight="bold">
                {healthEstimateScore(data.craving, data.emotion, data.stress, data.sleep, data.incidents) === -1
                  ? '-'
                  : healthEstimateScore(data.craving, data.emotion, data.stress, data.sleep, data.incidents)}
              </Text>
              <Text fontSize={['14px', '20px']}>
                {healthEstimateScore(data.craving, data.emotion, data.stress, data.sleep, data.incidents) >= 0 &&
                  '/ 100'}
              </Text>
            </Flex>
            <Text fontSize={['14px', '20px']} fontWeight="bold" fontStyle="italic">
              {healthEstimateText(data.craving, data.emotion, data.stress, data.sleep, data.incidents)}
            </Text>
          </Box>
        </VitalCard>
        <VitalCard title={t('vitalsSummary.headers.craving')} icon="file-medical" key="cravings">
          <Box marginTop="auto">
            <Text fontSize={['20px', '30px']} fontWeight="bold">
              {data.craving < 0 ? '-' : t(`${cravingCardTranslationKey(data.craving)}.level`)}
            </Text>
            <Text fontSize={['14px', '20px']} fontWeight="bold" fontStyle="italic">
              {t(`${cravingCardTranslationKey(data.craving)}.condition`)}
            </Text>
          </Box>
        </VitalCard>
        <VitalCard title="Emotions" icon="brain" key="emotions">
          <Flex marginTop="auto" flexDirection="column">
            {data.emotion > -12 && (
              <FAIcon icon={t(`${emotionCardTranslationKey(data.emotion)}.icon`)} fontSize={[20, 40]} />
            )}
            <Text fontSize={['14px', '20px']} fontWeight="bold" fontStyle="italic">
              {t(`${emotionCardTranslationKey(data.emotion)}.condition`)}
            </Text>
          </Flex>
        </VitalCard>
        <VitalCard title="Stress" icon="heartbeat" key="stress">
          <Box marginTop="auto">
            <Text fontSize={['20px', '30px']} fontWeight="bold">
              {t(`${stressCardTranslationKey(data.stress)}.letter`)
                .charAt(0)
                .toUpperCase()}
            </Text>
            <Text fontSize={['14px', '20px']} fontWeight="bold" fontStyle="italic">
              {t(`${stressCardTranslationKey(data.stress)}.condition`)}
            </Text>
          </Box>
        </VitalCard>
        <VitalCard title="Sleep" icon="bed" key="sleep">
          <Box marginTop="auto">
            <Text fontSize={['20px', '30px']} fontWeight="bold">
              {data.sleep < 0 ? '-' : `${Math.max(0, Math.round(data.sleep * 10) / 10)} hrs/day`}
            </Text>
            <Text fontSize={['14px', '20px']} fontWeight="bold" fontStyle="italic">
              {t(`${sleepCardTranslationKey(data.sleep)}.condition`)}
            </Text>
          </Box>
        </VitalCard>
        <VitalCard title="Participation" icon="mobile-alt" key="participation">
          <Box marginTop="auto">
            <Text fontSize={['20px', '30px']} fontWeight="bold">
              {data.participation < 0 ? '-' : `${Math.max(0, Math.round(data.participation * 100))}%`}
            </Text>
            <Text fontSize={['14px', '20px']} fontWeight="bold" fontStyle="italic">
              {t(`${participationCardTranslationKey(data.participation)}.condition`)}
            </Text>
          </Box>
        </VitalCard>
      </HStack>
    </>
  );
};

export default VitalsSummaryModule;
