import { AggregatesActions, AggregatesActionTypes } from './actions';
import IWeeklyVitalsAggregates from './interfaces/IWeeklyVitalsAggregates';

interface AggregatesState {
  loading: boolean;
  aggregates: null | IWeeklyVitalsAggregates;
  aggregateResults: IWeeklyVitalsAggregates[];
}

const defaultState: AggregatesState = {
  loading: false,
  aggregates: null,
  aggregateResults: [
    {
      id: '',
      pir: null,
      avgSleep: -1,
      avgCravingRisk: -1,
      avgEmotionalScore: -99,
      totalIncidents: 0,
      avgParticipation: -1,
      avgStress: -1,
      avgWatchUse: -1,
      totalSupportRequests: 0,
      dateTime: new Date(),
    },
  ],
};

const aggregatesReducer = (state: AggregatesState = defaultState, action: AggregatesActions): AggregatesState => {
  switch (action.type) {
    case AggregatesActionTypes.GETTING_AGGREGATES:
      return {
        ...state,
        loading: true,
      };
    case AggregatesActionTypes.GOT_AGGREGATES:
      return {
        ...state,
        loading: false,
        aggregateResults: action.aggregates,
      };
    default:
      return state;
  }
};

export default aggregatesReducer;
