import firebase from 'firebase/compat/app';
import IToDo from './interfaces/IToDo';

interface ToDoToFirestore extends Omit<IToDo, 'id'> {
  id?: string;
}

export const formatToDoFromFirestore = (snapshot: firebase.firestore.QueryDocumentSnapshot): IToDo => {
  const pir = snapshot.get('pir');

  if (typeof pir === 'undefined') {
    throw new Error('No PIR defined on the resource in the database');
  }

  return {
    id: snapshot.id,
    type: snapshot.get('type'),
    history: snapshot.get('history'),
    createdDateTime: snapshot.get('createdDateTime').toDate(),
    completedDateTime: snapshot.get('completedDateTime'),
    isCompleted: snapshot.get('isCompleted'),
    dueDateTime: snapshot.get('dueDateTime').toDate(),
    title: snapshot.get('title'),
    canClick: snapshot.get('canClick'),
    pir,
  };
};

export const formatToDoToFirestore = (todo: ToDoToFirestore): Omit<IToDo, 'id'> => {
  const out: Omit<IToDo, 'id'> = {
    pir: todo.pir,
    createdDateTime: todo.createdDateTime,
    isCompleted: todo.isCompleted,
    type: todo.type,
    dueDateTime: todo.dueDateTime,
    title: todo.title,
    canClick: todo.canClick,
  };

  if (typeof todo.completedDateTime !== 'undefined') {
    out.completedDateTime = todo.completedDateTime;
  }
  if (typeof todo.history !== 'undefined') {
    out.history = todo.history;
  }

  return out;
};
