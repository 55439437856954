import firebase from 'firebase/compat/app';
import { DeviceGarminActions, DeviceGarminActionTypes } from './actions';
import IDeviceGarmin from './interfaces/IDeviceGarmin';

interface DeviceGarminState {
  loading: boolean;
  creating: boolean;
  deviceGarmin: null | IDeviceGarmin;
  unsubFn: null | firebase.Unsubscribe;
}

const defaultState: DeviceGarminState = {
  loading: false,
  creating: false,
  deviceGarmin: null,
  unsubFn: null,
};

const deviceGarminReducer = (
  state: DeviceGarminState = defaultState,
  action: DeviceGarminActions,
): DeviceGarminState => {
  switch (action.type) {
    case DeviceGarminActionTypes.SET_DEVICE_GARMIN:
    case DeviceGarminActionTypes.CREATED_DEVICE_GARMIN:
      return {
        ...state,
        deviceGarmin: action.deviceGarmin,
      };
    case DeviceGarminActionTypes.GETTING_DEVICE_GARMIN:
      return {
        ...state,
        loading: true,
      };
    case DeviceGarminActionTypes.GOT_DEVICE_GARMIN:
      return {
        ...state,
        loading: false,
      };

    case DeviceGarminActionTypes.SET_DEVICE_GARMIN_UNSUB:
      return {
        ...state,
        unsubFn: action.unsubFn,
      };

    default:
      return state;
  }
};

export default deviceGarminReducer;
