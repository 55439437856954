import React, { useState } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Box } from '@chakra-ui/react';

import EmotionsLegendPanel from './EmotionsLegendPanel';
import MeasurePanel from './MeasurePanel';
import { IVitalList } from '../VitalsModuleCP';
import VitalStatusTab from './VitalStatusTab';
import VitalsLegendPanel from './VitalsLegendPanel';

interface Props {
  emotionItemsVisibility: IVitalList[];
  onEmotionVisibilityChanged: (visibility: IVitalList[]) => void;
  selectedGraph: string;
}

const EmotionSidePanel = (
  emotionItemsVisibility: IVitalList[],
  onEmotionVisibilityChanged: (visibility: IVitalList[]) => void,
): React.ReactElement => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Flex direction="column" h="100%">
      <Box>
        <VitalStatusTab selectedGraph="emotion" />
      </Box>
      <Box rounded="md" boxShadow="md" bg="white" h="100%">
        <Tabs variant="enclosed" colorScheme="black" isFitted={true} onChange={(index) => setTabIndex(index)}>
          <TabList>
            {['Legend', 'Key'].map((tabName, index) => (
              <Tab key={tabName} fontWeight={tabIndex === index ? 'bold' : 'normal'}>
                {tabName}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel>
              <EmotionsLegendPanel
                emotionItemsVisibility={emotionItemsVisibility}
                onEmotionVisibilityChanged={onEmotionVisibilityChanged}
              />
            </TabPanel>
            <TabPanel>
              <MeasurePanel selectedGraph="emotion" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};

const GenericSidePanel = (selectedGraph: string): React.ReactElement => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Flex direction="column" h="100%">
      {selectedGraph !== 'craving' && (
        <Box>
          <VitalStatusTab selectedGraph={selectedGraph} />
        </Box>
      )}
      <Box rounded="md" boxShadow="base" bg="white" h="100%" mt={4}>
        <Tabs variant="enclosed" colorScheme="black" isFitted={true} onChange={(index) => setTabIndex(index)}>
          <TabList>
            {['Legend', 'Key'].map((tabName, index) => (
              <Tab
                key={tabName}
                fontWeight={tabIndex === index ? '600' : '500'}
                color={tabIndex === index ? 'black' : '#8F8F8F'}
              >
                {tabName}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel>
              <VitalsLegendPanel selectedGraph={selectedGraph} />
            </TabPanel>
            <TabPanel>
              <MeasurePanel selectedGraph={selectedGraph} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};

const VitalsSidePanel = ({
  emotionItemsVisibility,
  onEmotionVisibilityChanged,
  selectedGraph,
}: Props): React.ReactElement => {
  switch (selectedGraph) {
    case 'emotion': {
      return EmotionSidePanel(emotionItemsVisibility, onEmotionVisibilityChanged);
    }
    default: {
      return GenericSidePanel(selectedGraph);
    }
  }
};

export default VitalsSidePanel;
