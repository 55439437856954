import { TFunction } from 'react-i18next';
import {
  cravingIndex,
  emotionIndex,
  incidentsIndex,
  participationIndex,
  sleepIndex,
  stressIndex,
  watchUseIndex,
} from '../dashboardMonitor/data2';

const tabFunc: any = {
  craving: { idxFunc: cravingIndex, maxIdx: 2 },
  participation: { idxFunc: participationIndex, maxIdx: 3 },
  sleep: { idxFunc: sleepIndex, maxIdx: 3 },
  stress: { idxFunc: stressIndex, maxIdx: 4 },
  watchuse: { idxFunc: watchUseIndex, maxIdx: 3 },
  emotion: { idxFunc: emotionIndex, maxIdx: 2 },
  incidents: { idxFunc: incidentsIndex, maxIdx: 3 },
};

export const getMeasureText = (
  t: TFunction<'vitals'>,
  tabTitle: string,
  value: number | string | undefined,
): string => {
  if (value === undefined || value === '') {
    return '';
  }

  const idx = tabFunc[tabTitle].idxFunc(value);

  if (idx === -1) return 'N/A';

  return t(`vitals.howMeasured.${tabTitle}.${idx}.level`);
};

export const getHowMeasure = (t: TFunction<'vitals'>, tabTitle: string): { level: string; content: string }[] => {
  const measureList = [...Array(tabFunc[tabTitle].maxIdx).keys()].map((idx) => {
    return tabTitle === 'craving'
      ? {
          level: t(`vitals.howMeasured.${tabTitle}.${idx}.level`),
          content: t(`vitals.howMeasured.${tabTitle}.${idx}.content`),
          color: t(`vitals.howMeasured.${tabTitle}.${idx}.color`),
          cravingPredicted: t(`vitals.howMeasured.${tabTitle}.${idx}.cravingPredicted`),
        }
      : {
          level: t(`vitals.howMeasured.${tabTitle}.${idx}.level`),
          content: t(`vitals.howMeasured.${tabTitle}.${idx}.content`),
          color: t(`vitals.howMeasured.${tabTitle}.${idx}.color`),
        };
  });

  return measureList;
};
