import firebase from 'firebase/compat/app';
import IFreeDate from './interfaces/IFreeDate';

export enum FreeDateActionTypes {
  SET_FREE_DATE = 'SET_FREE_DATE',
  GOT_FREE_DATE = 'GOT_FREE_DATE',
  CREATED_FREE_DATE = 'CREATED_FREE_DATE',
  UPDATED_FREE_DATE = 'UPDATED_FREE_DATE',
}

export const formatFreeDateFromFirestore = (snapshot: firebase.firestore.QueryDocumentSnapshot): IFreeDate => {
  const pir = snapshot.get('pir');

  if (typeof pir === 'undefined') {
    throw new Error('No PIR defined on the motivator in the database');
  }

  return {
    id: snapshot.id,
    pir,
    date: snapshot.get('date').toDate(),
  };
};
