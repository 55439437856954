import React, { forwardRef } from 'react';
import { Switch } from '@chakra-ui/react';

interface ToggleSwitchProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  name: string;
  id: string;
  disabled?: boolean;
}

const ToggleSwitch = forwardRef<HTMLInputElement, ToggleSwitchProps>(
  ({ checked, onChange, name, id, disabled, ...rest }, ref) => {
    return (
      <Switch
        id={id}
        ref={ref}
        colorScheme="purple3"
        name={name}
        isChecked={checked}
        disabled={disabled}
        onChange={(event) => {
          onChange(event);
        }}
        {...rest}
      />
    );
  },
);

ToggleSwitch.displayName = 'ToggleSwitch';

export default ToggleSwitch;
