import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';
import { HashRouter as Router } from 'react-router-dom';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { store, persistor } from './redux/store';
import theme from './constants/theme';
import icons from './constants/icons';
import AuthCheck from './modules/auth/AuthCheck';
import GlobalLoader from './modules/initialize/GlobalLoader';
import './App.css';
import { ModalProvider } from './ModalContext';
import { DeviceProvider } from './DeviceContext';

const queryClient = new QueryClient();

const App = (): JSX.Element => {
  icons();

  return (
    <ReduxProvider store={store}>
      <DeviceProvider>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
              <CSSReset />
              <GlobalLoader>
                <Router>
                  <ModalProvider>
                    <AuthCheck />
                  </ModalProvider>
                </Router>
              </GlobalLoader>
            </ChakraProvider>
          </QueryClientProvider>
        </PersistGate>
      </DeviceProvider>
    </ReduxProvider>
  );
};

export default App;
