import React from 'react';
import { Text, Box, Flex, Heading } from '@chakra-ui/react';
import PageHeading from '../../components/PageHeading';
import { Center } from '@chakra-ui/layout';

const HeadOfOrgDashboard = (): JSX.Element => {
  //   const { t } = useTranslation('home');

  return (
    <Center>
      <Box mx="auto">
        <Box width="100%">
          <Flex
            as="header"
            bg="white"
            align="center"
            pb={5}
            mb={5}
            borderBottomWidth={1}
            borderBottomColor="purple3.600"
            userSelect="none"
            display={['flex', 'flex', 'none', 'none']}
          >
            <Heading as="h1" fontSize={36} fontWeight={300} color="purple3.600">
              <Text>Head of Organization</Text>
            </Heading>
          </Flex>
          <PageHeading mb={8}>Head of Organization</PageHeading>

          <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between" maxW="1200px" />
        </Box>
      </Box>
    </Center>
  );
};

export default HeadOfOrgDashboard;
