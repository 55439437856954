import React, { useState } from 'react';
import { Box, Text, Slider, SliderTrack, SliderThumb, Flex } from '@chakra-ui/react';

export interface NamedSliderProps {
  label: string;
  rank: number;
  referenceLabels: string[];
  updateVal: (val: number) => void;
  interactionCallback?(): void;
}

const NamedSlider = ({
  label,
  rank,
  referenceLabels,
  updateVal,
  interactionCallback,
}: NamedSliderProps): JSX.Element => {
  const [val, setVal] = useState(rank);

  const update = (val: number) => {
    const scaledVal = scale(val);
    updateVal(scaledVal);
    setVal(scaledVal);
  };

  const addModifiedFieldInteraction = () => {
    if (interactionCallback) {
      interactionCallback();
    }
  };

  const scale = (val: number) => {
    return val / 10;
  };

  return (
    <Box width="100%">
      <Text fontSize="16px" color="#626161" fontWeight={500} mb={['21px', '17px']}>
        {label}
      </Text>
      <Box width="100%" mb={['10px']}>
        <Slider
          aria-label={label}
          size="lg"
          defaultValue={rank * 10}
          step={10}
          onChange={(val) => update(val)}
          onFocus={addModifiedFieldInteraction}
        >
          <SliderTrack
            borderRadius="10px"
            height={['12px', '13px']}
            backgroundColor="#CCCCCC"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            overflow="visible"
          >
            {[...Array(11)].map((_, i) => (
              <Box
                borderRadius={['9px', '11px']}
                height={['18px', '22px']}
                width={['18px', '22px']}
                backgroundColor="#CCCCCC"
                key={i}
              />
            ))}
          </SliderTrack>
          <Box position="absolute" top="0" bottom="0" left={['9px', '11px']} right={['9px', '11px']}>
            <SliderThumb
              fontSize="14px"
              backgroundColor="#5C4279"
              color="white"
              zIndex={0}
              fontFamily="body"
              width={['24px', '30px']}
              height={['24px', '30px']}
              fontWeight={700}
              data-test={label}
            >
              {val}
            </SliderThumb>
          </Box>
        </Slider>
      </Box>

      {/* Reference Scale */}
      <Flex width="100%" flexDirection="row" justifyContent="space-between" alignItems="center" mb={['25px', '30px']}>
        {referenceLabels.map((v, i) => (
          <Box
            width="80px"
            textAlign={i === 0 ? 'left' : i === referenceLabels.length ? 'right' : 'center'}
            fontSize="16px"
            color="#626161"
            fontWeight={400}
            key={i}
          >
            {v}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default NamedSlider;
