import React, { useState, useCallback, useEffect } from 'react';
import Dropzone from '../components/Dropzone';
import { useTranslation } from 'react-i18next';
import { Box, Center, Flex, Input, Text, Image } from '@chakra-ui/react';
import { RegisterOptions, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { uploadFile } from '../modules/firebase/storage';
import { MediaFormValues } from './../utils/utils';

interface Props {
  fileName: keyof MediaFormValues;
  urlName: keyof MediaFormValues;
  register: UseFormRegister<MediaFormValues>;
  validation: RegisterOptions;
  displayFile: string | undefined;
  interactionCallback?(): void;
  setValue: UseFormSetValue<MediaFormValues>;
  setUploadChosen?: React.Dispatch<React.SetStateAction<boolean>>;
  uploadChosen?: boolean;
  trigger?: UseFormTrigger<MediaFormValues>;
}

const AddMediaFile = ({
  fileName,
  urlName,
  displayFile,
  register,
  validation,
  interactionCallback,
  setValue,
  trigger,
  setUploadChosen,
  uploadChosen,
}: Props): React.ReactElement => {
  const [shouldShowFile, setShouldShowFile] = useState(false);
  const [shouldShowConfirm, setShouldShowConfirm] = useState(true);
  const [filePath, setFilePath] = useState<string>('');
  const [displayName, setDisplayName] = useState<string | undefined>(displayFile);
  const [shouldUploaderSpin, setShouldUploaderSpin] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (uploadChosen === false) {
      setDisplayName('');
      setFilePath('');
      setShouldShowFile(false);
      setShouldUploaderSpin(false);
      setShouldShowConfirm(true);
    }
  }, [uploadChosen]);

  const onDrop = useCallback(async (acceptedFiles: File[]): Promise<void> => {
    const acceptedFile = acceptedFiles[0];
    // process only first file
    if (!acceptedFile) return;

    setDisplayName(acceptedFile.name);
    setShouldUploaderSpin(true);
    if (interactionCallback) {
      // function to add interaction log for module that includes this component
      interactionCallback();
    }
    try {
      const downloadURL = await uploadFile(acceptedFile);
      setValue(urlName, downloadURL);
      setValue(fileName, acceptedFile.name);
      if (trigger) {
        trigger([urlName, fileName]);
      }
      setFilePath(downloadURL);
      setShouldShowConfirm(false);
      setShouldShowFile(true);
    } catch (err: any) {
      alert(err.message);
    } finally {
      setShouldUploaderSpin(false);
      if (setUploadChosen) {
        setUploadChosen(true);
      }
    }
  }, []);

  return (
    <>
      <Input type="hidden" {...register(urlName, validation)} isReadOnly={true} />
      <Input type="hidden" {...register(fileName, validation)} isReadOnly={true} />

      {!shouldShowFile && (
        <>
          {displayName && (
            <Box>
              <Text mt={!uploadChosen ? 10 : 0} fontSize={14}>
                {t('addMediaFile.currentFile')}
                {displayName}and
              </Text>
            </Box>
          )}

          <Dropzone
            displaySpinner={shouldUploaderSpin}
            onDrop={onDrop}
            accept="image/png, image/gif, application/msword, image/gif, image/jpeg, audio/mpeg, video/mpeg, application/pdf"
          />
        </>
      )}
      {!shouldShowConfirm && (
        <Flex
          flex-wrap="wrap"
          pb={10}
          pl={10}
          mb="18px"
          h="120px"
          border={2}
          borderStyle="solid"
          borderRadius="10px"
          borderColor="#EFEFEF"
        >
          <Box w="66" alignSelf="center" mt={10}>
            <Flex direction="column">
              <Center padding={5}>
                <Text alignSelf="center">{displayName}</Text>
                <Image
                  alignSelf="center"
                  src={filePath}
                  alt="Downloaded Image"
                  height={20}
                  paddingLeft="20px"
                  fallbackSrc="https://via.placeholder.com/150"
                />
              </Center>
            </Flex>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default AddMediaFile;
