import { Action } from 'redux';
import AppThunk, { AppThunkSync } from '../../redux/interfaces/AppThunk';
import { User, UserCredential, Unsubscribe } from 'firebase/auth';
import {
  signInToFirebase,
  resetFirebaseUserEmail,
  signOutFirebaseUser,
  onFirebaseAuthStateChanged,
  updateFirebaseUserPassword,
  onCordovaFirebaseAuthStateChanged,
} from '../firebase/auth';
import {
  setUser,
  SetUserAction,
  getUser,
  stopSnapshotListener as stopUserSnapshotListener,
  gotUser,
  GotUserAction,
  setNativeUserUid,
  SetNativeUserUidAction,
  updateInvitedAsRole,
  UpdatedUserAction,
  updateUserTimezone,
} from '../user/actions';
import {
  getLinkedUsers,
  gotLinkedUsers,
  setPirLinkedUsers,
  setOtherLinkedUsers,
  getInvites,
  selectLinkedUser,
  LinkedUsersActions,
  setInvites,
  stopLinkedUsersListeners,
  SelectLinkedUserAction,
} from '../linked-users/actions';
import { OnboardingResetAction, resetOnboarding } from '../onboarding/actions';
import { getDefaultLinkedUser } from '../linked-users/utils';
import { stopInvitationListenerThunk } from '../invitations/invtiationActions';
import Firestore from '../firestore/Firestore';
import axios from 'axios';

export enum AuthActionTypes {
  LOGGING_IN = 'LOGGING_IN',
  LOGGED_IN = 'LOGGED_IN',
  LOGGING_OUT = 'LOGGING_OUT',
  LOGOUT = 'LOGOUT',
  SENT_RESET_PASSWORD = 'SENT_RESET_PASSWORD',
  REQUESTED_ACCOUNT = 'REQUESTED_ACCOUNT',
  SIGNING_UP = 'SIGNING_UP',
  SIGNED_UP = 'SIGNED_UP',
  REGISTER_AUTH_STATE_LISTENER = 'REGISTER_AUTH_STATE_LISTENER',
  STOP_AUTH_STATE_LISTENER = 'STOP_AUTH_STATE_LISTENER',
  UPDATE_AUTHED_USER = 'UPDATE_AUTHED_USER',
}

export type AuthActions =
  | LoggingInAction
  | LoggedInAction
  | LoggingOutAction
  | LogoutAction
  | SentResetPasswordAction
  | RequestedAccountAction
  | RegisterAuthStateListenerAction
  | UpdateAuthedUserAction
  | StopAuthStateListenerAction
  | SigningUpAction
  | SignedUpAction;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LoggingInAction extends Action<AuthActionTypes.LOGGING_IN> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LoggingOutAction extends Action<AuthActionTypes.LOGGING_OUT> {}

export const loggingIn = (): LoggingInAction => ({ type: AuthActionTypes.LOGGING_IN });

export const loggingOut = (): LoggingOutAction => ({ type: AuthActionTypes.LOGGING_OUT });

export interface LoggedInAction extends Action<AuthActionTypes.LOGGED_IN> {
  credential: null | UserCredential;
}

export const loggedIn = (credential: null | UserCredential): LoggedInAction => {
  return {
    type: AuthActionTypes.LOGGED_IN,
    credential,
  };
};

export const login = (
  email: string,
  password: string,
): AppThunk<Promise<LoggedInAction>, AuthActions | LinkedUsersActions | UpdatedUserAction> => {
  return async (dispatch) => {
    dispatch(loggingIn());

    const credential = await signInToFirebase(email, password);

    const user = credential.user;

    if (user === null || user.email === null) {
      dispatch(loggedIn(null));
      throw new Error('No user associated with the signed in credential');
    }

    if (!credential.user.emailVerified) {
      dispatch(loggedIn(null));
      throw new Error('User email address is not verified');
    }

    await dispatch(getUser(user.uid));
    await dispatch(getInvites(user.email));

    const linkedUsersAction = await dispatch(getLinkedUsers(user.uid));
    await dispatch(selectLinkedUser(getDefaultLinkedUser(linkedUsersAction.linkedUsers)));

    const userSnapshot = await Firestore.collection('users').doc(user.uid).get();

    if (!userSnapshot.exists) {
      dispatch(loggedIn(null));
      throw new Error('No user exists');
    }

    await dispatch(updateUserTimezone());
    return dispatch(loggedIn(credential));
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SentResetPasswordAction extends Action<AuthActionTypes.SENT_RESET_PASSWORD> {}

export const sentResetPassword = (): SentResetPasswordAction => {
  return {
    type: AuthActionTypes.SENT_RESET_PASSWORD,
  };
};

export const resetPassword = (
  email: string,
): AppThunk<Promise<SentResetPasswordAction>, AuthActions | LinkedUsersActions> => {
  //
  return async (dispatch) => {
    await resetFirebaseUserEmail(email);
    // dispatch actions for outcome of reset password
    return dispatch(sentResetPassword());
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LogoutAction extends Action<AuthActionTypes.LOGOUT> {}

export const logout = (): AppThunk<
  Promise<LogoutAction>,
  AuthActions | SetUserAction | SetNativeUserUidAction | LinkedUsersActions | OnboardingResetAction
> => {
  return async (dispatch) => {
    dispatch(loggingOut());
    try {
      await signOutFirebaseUser();
      dispatch(stopUserSnapshotListener());
      dispatch(stopInvitationListenerThunk());
      dispatch(stopAuthStateListener());
      dispatch(stopLinkedUsersListeners());
      dispatch(setUser(null));
      dispatch(setNativeUserUid(null));
      dispatch(resetOnboarding());
      dispatch(setPirLinkedUsers(null));
      dispatch(setOtherLinkedUsers(null));
      dispatch(setInvites(null));
      dispatch(selectLinkedUser(null));

      return dispatch({ type: AuthActionTypes.LOGOUT });
    } catch (e) {
      console.error('An error occurred during logout:', e);
      throw e;
    }
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SigningUpAction extends Action<AuthActionTypes.SIGNING_UP> {}
export interface SignedUpAction extends Action<AuthActionTypes.SIGNED_UP> {
  credential: null | UserCredential;
}

export const signingUp = (): SigningUpAction => {
  return { type: AuthActionTypes.SIGNING_UP };
};

export const signedUp = (credential: null | UserCredential): SignedUpAction => {
  return {
    type: AuthActionTypes.SIGNED_UP,
    credential,
  };
};

async function acceptInvitation(user: User) {
  const invitations = await Firestore.collection('invitations').where('email', '==', user.email).get();
  if (invitations.size === 1) {
    await Firestore.collection('invitations').doc(invitations.docs[0].id).update({
      accepted: true,
      acceptedDate: new Date(),
    });
  }
}

export const signUp = (
  email: string,
  password: string,
  tempPassword: string,
): AppThunk<Promise<SignedUpAction>, AuthActions | SelectLinkedUserAction> => {
  return async (dispatch) => {
    try {
      dispatch(signingUp());
      const credential = await signInToFirebase(email, tempPassword);
      const user = credential.user;
      if (user === null || user.email === null) {
        throw new Error('No user associated with the credential during sign up');
      }
      if (!credential.user.emailVerified) {
        throw new Error('User email address is not verified');
      }
      await updateFirebaseUserPassword(user, password);
      await dispatch(getUser(user.uid));
      await dispatch(updateInvitedAsRole());
      await dispatch(getInvites(user.email));
      await acceptInvitation(user);
      await dispatch(getLinkedUsers(user.uid));
      await dispatch(updateUserTimezone());
      return dispatch(signedUp(credential));
    } catch (e) {
      console.error(`Error signing up: ${e}`);
      throw e;
    }
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RequestedAccountAction extends Action<AuthActionTypes.REQUESTED_ACCOUNT> {}

export const requestedAccount = (): RequestedAccountAction => {
  return {
    type: AuthActionTypes.REQUESTED_ACCOUNT,
  };
};

export const requestAccount = (
  email: string,
): AppThunk<Promise<RequestedAccountAction>, AuthActions | SelectLinkedUserAction> => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(process.env.REACT_APP_CHECK_INVITATIONS_URL || '', { email });
      if (data) {
        const alreadyInvited = data.invited;
        console.log('data', data);
        if (alreadyInvited) {
          throw new Error('email-already-invited');
        }
        const requestsWithEmail = await Firestore.collection('requests').where('email', '==', email).get();
        if (!requestsWithEmail.empty) {
          throw new Error('email-already-requested');
        }

        const request = { email, dateRequested: new Date() };

        await Firestore.collection('requests').doc().set(request);

        return dispatch(requestedAccount());
      } else {
        throw new Error(`Error checking invitations`);
      }
    } catch (e) {
      console.error(`Error requesting account: ${e}`);
      throw e;
    }
  };
};

export interface RegisterAuthStateListenerAction extends Action<AuthActionTypes.REGISTER_AUTH_STATE_LISTENER> {
  unsubscribeFn: Unsubscribe;
}

export interface UpdateAuthedUserAction extends Action<AuthActionTypes.UPDATE_AUTHED_USER> {
  user: User | null;
}

export const startAuthStateListener = (): AppThunk<
  RegisterAuthStateListenerAction,
  AuthActions | SetUserAction | SetNativeUserUidAction | GotUserAction | LinkedUsersActions
> => {
  return (dispatch, getState) => {
    const currentUnsubscribe = getState().auth.stateListenerUnsubscribe;
    // If it's already started, we do not want to start another listener
    if (currentUnsubscribe !== null) {
      return dispatch({
        type: AuthActionTypes.REGISTER_AUTH_STATE_LISTENER,
        unsubscribeFn: currentUnsubscribe,
      });
    }

    if (window.cordova) {
      onCordovaFirebaseAuthStateChanged((userDetails) => {
        dispatch(setNativeUserUid(userDetails?.uid ?? null));
      });
    }

    return dispatch({
      type: AuthActionTypes.REGISTER_AUTH_STATE_LISTENER,
      unsubscribeFn: onFirebaseAuthStateChanged((user) => {
        if (user === null || user.email === null) {
          dispatch(setUser(null));
          dispatch(gotUser());

          dispatch(setPirLinkedUsers(null));
          dispatch(setOtherLinkedUsers(null));

          dispatch(selectLinkedUser(null));
          dispatch(gotLinkedUsers([]));
        } else {
          const userEmail = user.email;
          dispatch(getUser(user.uid))
            .then(async () => dispatch(getLinkedUsers(user.uid)))
            .then(async (action) => dispatch(selectLinkedUser(getDefaultLinkedUser(action.linkedUsers))))
            .then(async () => dispatch(getInvites(userEmail)));
        }

        dispatch({
          type: AuthActionTypes.UPDATE_AUTHED_USER,
          user,
        });
      }),
    });
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StopAuthStateListenerAction extends Action<AuthActionTypes.STOP_AUTH_STATE_LISTENER> {}

export const stopAuthStateListener = (): AppThunkSync<StopAuthStateListenerAction> => {
  return (dispatch, getState) => {
    const unsubscribeFn = getState().auth.stateListenerUnsubscribe;

    if (unsubscribeFn) {
      unsubscribeFn();
    }

    return dispatch({
      type: AuthActionTypes.STOP_AUTH_STATE_LISTENER,
    });
  };
};
