import { Action } from 'redux';
import firebase from 'firebase/compat/app';
import AppThunk from '../../redux/interfaces/AppThunk';
import IToDo from './interfaces/IToDo';
import Firestore, { FirestoreUtils } from '../firestore/Firestore';
import { formatToDoFromFirestore, formatToDoToFirestore } from './utils';

export enum ToDoActionTypes {
  SET_TODOS = 'SET_TODOS',
  GETTING_TODOS = 'GETTING_TODOS',
  GOT_TODOS = 'GOT_TODOS',
  SET_TODOS_UNSUB = 'SET_TODOS_UNSUB_FN',
  CREATED_TODO = 'CREATED_TODO',
  CREATING_TODO = 'CREATING_TODO',
  DELETING_TODO = 'DELETING_RESOURSE',
  DELETED_TODO = 'DELETED_TODO',
  UPDATING_TODO = 'UPDATING_TODO',
  UPDATED_TODO = 'UPDATED_TODO',
  SET_SELECTED_TODO = 'SET_SELECTED_TODO',
  CLEAR_SELECTED_TODO = 'CLEAR_SELECTED_TODO',
}

export type ToDoActions =
  | SetToDosAction
  | GettingToDosAction
  | GotToDosAction
  | SetToDosUnsubAction
  | CreatingToDoAction
  | CreatedToDoAction
  | DeletingToDoAction
  | DeletedToDoAction
  | UpdatingToDoAction
  | UpdatedToDoAction
  | SetSelectedToDoAction
  | ClearSelectedToDoAction;

//SET_TODOS
export interface SetToDosAction extends Action<ToDoActionTypes.SET_TODOS> {
  todos: null | IToDo[];
}

export const setToDos = (todos: null | IToDo[]): SetToDosAction => ({
  type: ToDoActionTypes.SET_TODOS,
  todos,
});

//GETTING_TODOS
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GettingToDosAction extends Action<ToDoActionTypes.GETTING_TODOS> {}

export const gettingToDos = (): GettingToDosAction => ({ type: ToDoActionTypes.GETTING_TODOS });

//GOT_TODOS
export interface GotToDosAction extends Action<ToDoActionTypes.GOT_TODOS> {
  todos: IToDo[];
}

export const gotToDos = (todos: IToDo[]): GotToDosAction => {
  return {
    type: ToDoActionTypes.GOT_TODOS,
    todos,
  };
};

//Get ToDos with Thunk
export const getToDos = (pirId: string): AppThunk<Promise<GotToDosAction>, ToDoActions> => {
  return async (dispatch) => {
    dispatch(gettingToDos());

    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);

    return new Promise<IToDo[]>((resolve, reject) => {
      const unsubFn = Firestore.collection('todo')
        .where('pir', '==', FirestoreUtils.getDocRef('users', pirId))
        .where('createdDateTime', '>=', todaysDate)
        .onSnapshot(
          (snapshot) => {
            const todos = snapshot.docs.map((snapshot) => formatToDoFromFirestore(snapshot));
            dispatch(setToDos(todos));
            resolve(todos);
          },
          (error) => {
            reject(error);
          },
        );
      dispatch(setToDosUnsub(unsubFn));
    }).then((todos) => {
      return dispatch(gotToDos(todos));
    });
  };
};

export interface SetToDosUnsubAction extends Action<ToDoActionTypes.SET_TODOS_UNSUB> {
  unsubFn: null | firebase.Unsubscribe;
}

export const setToDosUnsub = (unsubFn: null | firebase.Unsubscribe): SetToDosUnsubAction => ({
  type: ToDoActionTypes.SET_TODOS_UNSUB,
  unsubFn,
});

export const stopToDosListener = (): AppThunk<SetToDosUnsubAction, SetToDosUnsubAction> => {
  return (dispatch, getState) => {
    const unsubFn = getState().todo.unsubFn;
    if (unsubFn) {
      unsubFn();
    }

    return dispatch(setToDosUnsub(null));
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreatingToDoAction extends Action<ToDoActionTypes.CREATING_TODO> {}

//action
export const creatingToDo = (): CreatingToDoAction => ({ type: ToDoActionTypes.CREATING_TODO });

export interface CreatedToDoAction extends Action<ToDoActionTypes.CREATED_TODO> {
  todo: IToDo;
}

export const createdToDo = (todo: IToDo): CreatedToDoAction => ({
  type: ToDoActionTypes.CREATED_TODO,
  todo,
});

export const createToDo = (rawToDo: Omit<IToDo, 'id'>): AppThunk<Promise<CreatedToDoAction>, ToDoActions> => {
  return async (dispatch) => {
    dispatch(creatingToDo());

    const todo = formatToDoToFirestore(rawToDo);
    //we are getting the url or direct locale of the todos collection
    const todoRef = Firestore.collection('todo').doc();
    //actually adds to the database
    await todoRef.set(todo);
    return dispatch(
      createdToDo({
        ...todo,
        id: todoRef.id,
      }),
    );
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeletingToDoAction extends Action<ToDoActionTypes.DELETING_TODO> {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeletedToDoAction extends Action<ToDoActionTypes.DELETED_TODO> {}

export const deleteToDo = (todos: IToDo): AppThunk<Promise<DeletedToDoAction>, ToDoActions> => {
  return async (dispatch) => {
    dispatch({ type: ToDoActionTypes.DELETING_TODO });
    await Firestore.collection('todo').doc(todos.id).delete();
    return dispatch({ type: ToDoActionTypes.DELETED_TODO });
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdatingToDoAction extends Action<ToDoActionTypes.UPDATING_TODO> {}

export interface UpdatedToDoAction extends Action<ToDoActionTypes.UPDATED_TODO> {
  todos: IToDo[];
}

export const updateToDo = (todo: IToDo, todos: IToDo[]): AppThunk<Promise<UpdatedToDoAction>, ToDoActions> => {
  return async (dispatch) => {
    dispatch({ type: ToDoActionTypes.UPDATING_TODO });
    for (let i = 0; i < todos.length; i++) {
      if (todos[i].id === todo.id) {
        todos[i] = todo;
      }
    }

    await Firestore.collection('todo').doc(todo.id).set(formatToDoToFirestore(todo));

    clearSelectedToDo();
    return dispatch({
      type: ToDoActionTypes.UPDATED_TODO,
      todos,
    });
  };
};

export interface SetSelectedToDoAction extends Action<ToDoActionTypes.SET_SELECTED_TODO> {
  selectedToDo: IToDo | null;
}

export const setSelectedToDo = (selectedToDo: IToDo | null): AppThunk<SetSelectedToDoAction, ToDoActions> => {
  return (dispatch) => {
    return dispatch({
      type: ToDoActionTypes.SET_SELECTED_TODO,
      selectedToDo,
    });
  };
};

export type ClearSelectedToDoAction = Action<ToDoActionTypes.CLEAR_SELECTED_TODO>;

export const clearSelectedToDo = (): AppThunk<ClearSelectedToDoAction, ToDoActions> => {
  return (dispatch) => {
    return dispatch({
      type: ToDoActionTypes.CLEAR_SELECTED_TODO,
    });
  };
};
