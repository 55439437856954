import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Text,
  HStack,
  VStack,
} from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, useSortBy, useTable } from 'react-table';
import FAIcon from '../../components/FAIcon';
import ISupportRequest from '../../db/supportRequests';
import { metricColor } from '../../lib/colorThresholds';
import { PossibleLinkedUser } from '../linked-users/interfaces/ILinkedUser';
import SupportRequestsPreview from '../supportRequests/SupportRequestsPreview';
import VitalsModuleCP from '../vitals/VitalsModuleCP';
import { PirUsersData } from './LinkedUsers';
import { GraphType } from '../vitals/vitalsGraphOptions';
import { healthEstimateScore } from './data2';
import { createPortal } from 'react-dom';
import { useDevice } from 'src/DeviceContext';

const ArrowPortal = ({ onClick, right }: { onClick: () => void; right: number }) => {
  return createPortal(
    <Box
      position="fixed"
      top="260px"
      right={`${right}px`}
      transform="translateY(-50%)"
      cursor="pointer"
      zIndex={1000}
      padding="5px"
      onClick={onClick}
    >
      <FAIcon fontWeight="bold" icon="arrow-right" />
    </Box>,
    document.body, // Render it directly in the body
  );
};

interface TableWebViewProps {
  tableData: PirUsersData[];
  columnsData: any[];
  tabletView?: boolean;
  onSelect: (linkedUser: PossibleLinkedUser) => Promise<void>;
}

export interface PirUsersDataWithHealthEstimate {
  [key: string]: any;
  linkedUser?: PossibleLinkedUser;
  person?: string;
  craving?: number | string;
  participation?: number | string;
  watchUse?: number | string;
  sleep?: number | string;
  emotion?: number | string;
  stress?: number | string;
  incidents?: number | string;
  supportRequests?: ISupportRequest[];
  image?: JSX.Element;
  arrow?: JSX.Element;
  arrow_left?: JSX.Element;
  arrow_right?: JSX.Element;
  healthEstimate: number;
}

const TableWebView = ({ columnsData, tableData, tabletView, onSelect }: TableWebViewProps): JSX.Element => {
  const history = useHistory();
  const [contentStack, setContentStack] = useState<JSX.Element[]>([]);
  const [activeItemId, setActiveItemId] = useState<string | null>(null);
  const columns = React.useMemo(() => columnsData, [columnsData]);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const { isMobileDevice } = useDevice();

  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (containerRef.current && tabletView) {
      const { scrollWidth, clientWidth, scrollLeft } = containerRef.current;
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const handleArrowClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: containerRef.current.scrollWidth,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (tabletView) {
      handleScroll();
      if (containerRef.current) {
        containerRef.current.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    } else {
      setShowRightArrow(false);
    }
  }, [tabletView]);

  const modifyTableData = React.useMemo(() => {
    return (data: PirUsersData[]) => {
      return data.map((item: PirUsersData) => {
        const healthCraving =
          typeof item.craving === 'undefined' || typeof item.craving === 'string' || item.craving < 0
            ? -999
            : item.craving;
        const healthEmotion =
          typeof item.emotion === 'undefined' || typeof item.emotion === 'string' || item.emotion <= -11
            ? -999
            : item.emotion;
        const healthStress =
          typeof item.stress === 'undefined' || typeof item.stress === 'string' || item.stress < 0 ? -999 : item.stress;
        const healthSleep =
          typeof item.sleep === 'undefined' || typeof item.sleep === 'string' || item.sleep < 0 ? -999 : item.sleep;
        const healthIncidents =
          typeof item.incidents === 'undefined' || typeof item.incidents === 'string' || item.incidents < 0
            ? -999
            : item.incidents;
        const possibleHealthEstimate = healthEstimateScore(
          healthCraving,
          healthEmotion,
          healthStress,
          healthSleep,
          healthIncidents,
        );
        return {
          ...item,
          healthEstimate: possibleHealthEstimate,
        };
      }) as PirUsersDataWithHealthEstimate[];
    };
  }, []);

  const modifiedTableData = React.useMemo(() => modifyTableData(tableData), [modifyTableData, tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: modifiedTableData,
      initialState: {
        sortBy: [],
      },
    },
    useSortBy,
  );

  // converting value type of string & undefined to number because metricCPColor only takes in the type number;
  const metricColorSafe = (ctype: string, value: number | string | undefined, show: boolean) => {
    show = true;
    if (typeof value === 'string') {
      value = Number(value);
    } else if (typeof value === 'undefined') {
      value = 0;
      show = false;
    }
    return metricColor(ctype, value, show);
  };

  const trackCellClick = async (
    linkedUser: any,
    row: Row<PirUsersDataWithHealthEstimate>,
    colID: string,
  ): Promise<void> => {
    if (linkedUser) {
      await onSelect(linkedUser);
    }

    const handleClick = (to: string) => {
      if (linkedUser) {
        history.push(to);
      }
    };

    const rowIdx = row.index;

    const userButtons = (
      <Flex justifyContent="flex-" m={6}>
        <ButtonGroup spacing={4}>
          <Button
            onClick={() => {
              handleClick('/profile');
            }}
            colorScheme="blue"
            variant="outline"
            fontSize="14px"
            height="30px"
            width="120px"
          >
            Go to Profile
          </Button>
          <Button
            onClick={() => {
              handleClick('/vitals');
            }}
            colorScheme="blue"
            fontSize="14px"
            height="30px"
            width="120px"
            ml="5"
          >
            Go to Vitals
          </Button>
        </ButtonGroup>
      </Flex>
    );

    const graphCase = ['participation', 'watchUse', 'sleep', 'emotion', 'stress', 'incidents'];

    let el: JSX.Element;
    switch (colID) {
      case graphCase.find((id) => id === colID):
        el = (
          <>
            <VitalsModuleCP key={colID} uid={linkedUser.pir.id} selectedVital={colID as GraphType} />
            {userButtons}
          </>
        );
        break;
      case 'supportRequests':
        el = (
          <>
            {getSupportRequests(rowIdx).length > 0 ? (
              <Box padding={6}>
                <SupportRequestsPreview requests={getSupportRequests(rowIdx)} />
              </Box>
            ) : (
              <Text padding={5}>No recent support requests</Text>
            )}
            {userButtons}
          </>
        );
        break;
      default:
        el = (
          <>
            <Flex m="20px" direction="row" gap="50px">
              <Flex basis="50%" direction={['column', 'column', 'row']} gap="50px">
                <Box w={['full', 'full', '50%']}>
                  <Box fontSize="17px" fontWeight="500" mb="10px">
                    Participation (%)
                  </Box>
                  <Box>
                    <Flex justifyContent="space-between" mb="12px">
                      <Flex
                        direction="column"
                        color={metricColorSafe('participationLow', modifiedTableData[rowIdx].participation, true)}
                        textAlign="center"
                      >
                        <Box>≤ 60</Box>
                        <Box>Low</Box>
                      </Flex>
                      <Flex
                        direction="column"
                        color={metricColorSafe('participationModerate', modifiedTableData[rowIdx].participation, true)}
                        textAlign="center"
                      >
                        <Box>60 - 89</Box>
                        <Box>Moderate</Box>
                      </Flex>
                      <Flex
                        direction="column"
                        color={metricColorSafe('participationHigh', modifiedTableData[rowIdx].participation, true)}
                        textAlign="center"
                      >
                        <Box>≥ 90</Box>
                        <Box>High</Box>
                      </Flex>
                    </Flex>
                  </Box>
                </Box>
                <Box w={['full', 'full', '50%']}>
                  <Box fontSize="17px" fontWeight="500" mb="10px">
                    Sleep (hrs)
                  </Box>
                  <Flex justifyContent="space-between">
                    <Flex
                      direction="column"
                      color={metricColorSafe('sleepPoor', modifiedTableData[rowIdx].sleep, true)}
                      textAlign="center"
                    >
                      <Box>≤ 6</Box>
                      <Box>Poor</Box>
                    </Flex>
                    <Flex
                      direction="column"
                      color={metricColorSafe('sleepModerate', modifiedTableData[rowIdx].sleep, true)}
                      textAlign="center"
                    >
                      <Box>6 - 7</Box>
                      <Box>Moderate</Box>
                    </Flex>
                    <Flex
                      direction="column"
                      color={metricColorSafe('sleepGood', modifiedTableData[rowIdx].sleep, true)}
                      textAlign="center"
                    >
                      <Box>7 - 10</Box>
                      <Box>Good</Box>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
              <Flex direction="column" basis={['50%', '50%', '25%']} gap="30px">
                <Box display={['none', 'none', 'block']}>
                  <Box textAlign="left" fontSize="17px" fontWeight="500" mb="10px">
                    Emotions
                  </Box>
                  <Flex justifyContent="space-between">
                    <Flex color={metricColorSafe('emotionUp', modifiedTableData[rowIdx].emotion, true)} fontSize="16px">
                      <FAIcon icon="arrow-up" mr="10px" />
                      <Box>Stable</Box>
                    </Flex>
                    <Flex
                      color={metricColorSafe('emotionDown', modifiedTableData[rowIdx].emotion, true)}
                      fontSize="16px"
                    >
                      <FAIcon icon="arrow-down" mr="10px" />
                      <Box>Unstable</Box>
                    </Flex>
                  </Flex>
                </Box>
                <Box>
                  <Box fontSize="17px" fontWeight="500" mb="10px">
                    Stress
                  </Box>
                  <Flex justifyContent="space-between">
                    <Box color={metricColorSafe('stressResting', modifiedTableData[rowIdx].stress, true)}>Resting</Box>
                    <Box color={metricColorSafe('stressLow', modifiedTableData[rowIdx].stress, true)}>Low</Box>
                    <Box color={metricColorSafe('stressModerate', modifiedTableData[rowIdx].stress, true)}>
                      Moderate
                    </Box>
                    <Box color={metricColorSafe('stressHigh', modifiedTableData[rowIdx].stress, true)}>High</Box>
                  </Flex>
                </Box>
              </Flex>
              <Flex direction="column" w="25%" gap="15px" display={['none', 'none', 'flex']}>
                <Box textAlign="left" fontSize="17px" fontWeight="500">
                  Alerts
                </Box>
                <Flex justifyContent="space-between">
                  <Flex>
                    <FAIcon icon="exclamation-triangle" color="#AAAAAA" mr="10px" />
                    <Box color="#AAAAAA">Incidents</Box>
                  </Flex>
                  <Box color={metricColorSafe('incidents', modifiedTableData[rowIdx].incidents, true)}>
                    {Math.max(0, Number(modifiedTableData[rowIdx].incidents))}
                  </Box>
                </Flex>
                <Flex justifyContent="space-between">
                  <Flex>
                    <FAIcon icon="envelope" color="#AAAAAA" ml="1px" mr="10px" />
                    <Box color="#AAAAAA">Support Requests</Box>
                  </Flex>
                  <Box
                    color={metricColorSafe(
                      'supportRequests',
                      (modifiedTableData[rowIdx].supportRequests || []).length,
                      true,
                    )}
                  >
                    {(modifiedTableData[rowIdx].supportRequests || []).length}
                  </Box>
                </Flex>
              </Flex>
            </Flex>
            {userButtons}
          </>
        );
    }
    setActiveItemId(row.id);
    setContentStack((stack) => [...stack, el]);
  };

  const getSupportRequests = (index: number): ISupportRequest[] => modifiedTableData[index].supportRequests || [];

  return (
    <>
      {/* Header Bar */}
      {tabletView === true ? (
        <Box position="relative" width="100%">
          <Box overflowX="auto" width="100%" ref={containerRef}>
            <VStack alignItems="stretch" justifyContent="flex-start">
              <HStack alignItems="stretch" justifyContent="flex-start">
                {headerGroups.map((headerGroup) => (
                  // eslint-disable-next-line react/jsx-key
                  <Flex
                    minWidth="min-content"
                    fontFamily="roboto, sans-serif"
                    fontWeight="500"
                    fontSize="14px"
                    textAlign="center"
                    color="white"
                    bg="#4F4F4F"
                    borderRadius="md"
                    py={1}
                    mb={1}
                    width="100%"
                    {...getTableProps()}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup?.headers.map((column, index) => (
                      // eslint-disable-next-line react/jsx-key
                      <Flex
                        minWidth="100px"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          ...(index === 0 && { maxWidth: '30px', minWidth: '30px' }),
                          ...(index === 1 && { maxWidth: '65px', minWidth: '65px' }),
                          ...(index === 2 && { maxWidth: '110px', minWidth: '110px' }),
                          ...(index === headerGroup.headers.length - 1 && { maxWidth: '30px', minWidth: '30px' }),
                        }}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (
                            <FAIcon ml={1} icon={column.isSortedDesc ? 'caret-down' : 'caret-up'} />
                          ) : (
                            ''
                          )}
                        </span>
                      </Flex>
                    ))}
                  </Flex>
                ))}
              </HStack>
              {/* Patient Information Rows */}
              {/* <Box {...getTableBodyProps()}> */}
              <Box {...getTableBodyProps()} width="100%">
                <Accordion allowToggle={true} width="100%">
                  {rows.map((row, i) => {
                    prepareRow(row);
                    const isActive = row.id === activeItemId;
                    return (
                      <AccordionItem
                        key={i}
                        id={row.id}
                        minWidth="min-content"
                        bg="white"
                        mb="0.25rem"
                        borderRadius="lg"
                        width="100%"
                      >
                        <HStack key={i} width="100%">
                          <Flex
                            borderBottom="2px solid #ebebeb"
                            boxShadow="sm"
                            width="100%"
                            {...row.getRowProps()}
                            // {...getTableProps()}
                          >
                            {row.cells.map((cell, index) => {
                              return (
                                // Individual Patient Info cells
                                // eslint-disable-next-line react/jsx-key
                                <Flex
                                  minWidth="100px"
                                  flex="1"
                                  sx={{
                                    ...(index === 0 && { maxWidth: '30px', minWidth: '30px' }),
                                    ...(index === 1 && { maxWidth: '65px', minWidth: '65px' }),
                                    ...(index === 2 && { maxWidth: '110px', minWidth: '110px' }),
                                    ...(index === row.cells.length - 1 && {
                                      maxWidth: '30px',
                                      minWidth: '30px',
                                    }),
                                  }}
                                  {...cell.getCellProps()}
                                  width="100%"
                                >
                                  <AccordionButton
                                    px={0}
                                    justifyContent="center"
                                    alignItems="center"
                                    onClick={() => {
                                      trackCellClick(cell.row.original.linkedUser, cell.row, cell.column.id);
                                    }}
                                  >
                                    {cell.render('Cell')}
                                  </AccordionButton>
                                </Flex>
                              );
                            })}
                          </Flex>
                        </HStack>
                        {/* Dropdown Panel */}
                        <AccordionPanel bg="white" borderBottom="2px solid #ebebeb" boxShadow="sm" borderRadius="lg">
                          {isActive && <Box>{contentStack[contentStack.length - 1]}</Box>}
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </Box>
              {/* </Box> */}
            </VStack>
          </Box>
          {showRightArrow && <ArrowPortal onClick={handleArrowClick} right={isMobileDevice ? 10 : 20} />}
        </Box>
      ) : (
        <>
          <Flex
            fontFamily="roboto, sans-serif"
            fontWeight="500"
            fontSize="14px"
            textAlign="center"
            color="white"
            bg="#4F4F4F"
            borderRadius="md"
            py={1}
            mb={3}
            width="100%"
            {...getTableProps()}
          >
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <Flex width="100%" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Flex
                    flex="1"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      ...(index === 0 && { maxWidth: '5rem' }),
                      ...(index === 1 && { minWidth: '120' }),
                      ...(index === headerGroup.headers.length - 1 && { maxWidth: '2.5rem' }),
                    }}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? <FAIcon ml={1} icon={column.isSortedDesc ? 'caret-down' : 'caret-up'} /> : ''}
                    </span>
                  </Flex>
                ))}
              </Flex>
            ))}
          </Flex>

          {/* Patient Information Rows */}
          <Box {...getTableBodyProps()}>
            <Accordion allowToggle={true}>
              {rows.map((row, i) => {
                prepareRow(row);
                const isActive = row.id === activeItemId;
                return (
                  <AccordionItem key={i} id={row.id} bg="white" mb="0.25rem" borderRadius="lg">
                    <Flex borderBottom="2px solid #ebebeb" boxShadow="sm" {...row.getRowProps()}>
                      {row.cells.map((cell, index) => {
                        return (
                          // Individual Patient Info cells
                          // eslint-disable-next-line react/jsx-key
                          <Flex
                            flex="1"
                            minWidth="min-content"
                            sx={{
                              ...(index === 0 && { maxWidth: '5rem' }),
                              ...(index === 1 && { minWidth: '120' }),
                              ...(index === row.cells.length - 1 && { maxWidth: '2.5rem' }),
                            }}
                            {...cell.getCellProps()}
                          >
                            <AccordionButton
                              px={0}
                              justifyContent="center"
                              alignItems="center"
                              onClick={() => {
                                trackCellClick(cell.row.original.linkedUser, cell.row, cell.column.id);
                              }}
                            >
                              {cell.render('Cell')}
                            </AccordionButton>
                          </Flex>
                        );
                      })}
                    </Flex>
                    {/* Dropdown Panel */}
                    <AccordionPanel bg="white" borderBottom="2px solid #ebebeb" boxShadow="sm" borderRadius="lg">
                      {isActive && <Box>{contentStack[contentStack.length - 1]}</Box>}
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Box>
        </>
      )}
    </>
  );
};

export default TableWebView;
