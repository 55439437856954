import { where, addDoc, deleteDoc, updateDoc, getDoc, deleteField } from 'firebase/firestore';
import { documentRef, collectionRef, queryDocs } from '../modules/firebase/firestore';
import IAppointment from '../modules/appointments/interfaces/IAppointment';
import { formatAppointmentFromFirestore, formatAppointmentToFirestore } from '../modules/appointments/utils';
import ILinkedUser, { LinkedUserRole } from 'src/modules/linked-users/interfaces/ILinkedUser';

export const getAppointments = async (
  userId: string,
  userType: LinkedUserRole,
  linkedUsers: ILinkedUser[],
): Promise<IAppointment[]> => {
  const docRef = documentRef('users', userId);
  const appointmentUserType = userType === LinkedUserRole.PIR ? 'pir' : 'providerUser';
  let querySnapshot;
  try {
    querySnapshot = await queryDocs('appointments', where(appointmentUserType, '==', docRef));
  } catch (err) {
    console.error(`Something has gone wrong with this snapshot: ${err}`);
    throw new Error('Something has gone wrong with this');
  }

  const appointmentsPromises = querySnapshot.docs.map(async (doc) => {
    const appointment = formatAppointmentFromFirestore(doc);
    const appointmentLinkedUser = linkedUsers.find((linkedUser) => {
      return linkedUser.otherUser.id === appointment.providerUser?.id && linkedUser.pir.id === appointment.pir.id;
    });
    const otherUserAlias =
      userType === LinkedUserRole.PIR ? appointmentLinkedUser?.contactAlias : appointmentLinkedUser?.pirAlias;
    let otherUserName = '';
    if (userType === LinkedUserRole.PIR && appointment.providerUser?.path) {
      const providerUserRef = appointment.providerUser;
      const providerUserSnapshot = await getDoc(providerUserRef);
      if (providerUserSnapshot.exists()) {
        otherUserName = providerUserSnapshot.data().name;
      }
    } else if (userType === LinkedUserRole.PROVIDER) {
      if (appointmentLinkedUser?.preferredName) otherUserName = appointmentLinkedUser?.preferredName ?? '';
      else {
        const pirUserRef = appointment.pir;
        const pirUserSnapshot = await getDoc(pirUserRef);
        if (pirUserSnapshot.exists()) {
          otherUserName = pirUserSnapshot.data().name;
        }
      }
    }
    if (otherUserName) return { ...appointment, otherUserAlias, otherUserName };
    else return { ...appointment, otherUserAlias };
  });

  return Promise.all(appointmentsPromises);
};

export const createAppointment = async (rawAppointment: Omit<IAppointment, 'id'>): Promise<void> => {
  const appointment = formatAppointmentToFirestore(rawAppointment);
  await addDoc(collectionRef('appointments'), appointment);
};

export const deleteAppointment = async (appointment: IAppointment): Promise<void> => {
  await deleteDoc(documentRef('appointments', appointment.id as string));
};

export const updateAppointment = async (id: string, appointmentUpdates: Partial<IAppointment>): Promise<void> => {
  const finalUpdates: Record<string, any> = {};
  Object.keys(appointmentUpdates).forEach((key) => {
    const value = appointmentUpdates[key as keyof IAppointment];
    if (value === undefined) {
      finalUpdates[key] = deleteField();
    } else {
      finalUpdates[key] = value;
    }
  });

  await updateDoc(documentRef('appointments', id), finalUpdates);
};
