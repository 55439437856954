import { where, setDoc, addDoc, deleteDoc } from 'firebase/firestore';
import { documentRef, collectionRef, queryDocs } from '../modules/firebase/firestore';
import IMeditation from '../modules/meditations/interfaces/IMeditation';
import { formatMeditationFromFirestore, formatMeditationToFirestore } from '../modules/meditations/utils';

export const getMeditations = async (pirId: string): Promise<IMeditation[]> => {
  const docRef = documentRef('users', pirId);
  const querySnapshot = await queryDocs('meditations', where('pir', '==', docRef));
  return querySnapshot.docs.map((doc) => formatMeditationFromFirestore(doc));
};

export const createMeditation = async (rawMeditation: Omit<IMeditation, 'id' | 'meditationType' >): Promise<void> => {
  const meditation = formatMeditationToFirestore(rawMeditation);
  await addDoc(collectionRef('meditations'), meditation);
};

export const deleteMeditation = async (meditation: IMeditation): Promise<void> => {
  await deleteDoc(documentRef('meditations', meditation.id));
};

export const updateMeditation = async (meditation:  Omit<IMeditation, 'meditationType' >): Promise<void> => {
  await setDoc(documentRef('meditations', meditation.id), formatMeditationToFirestore(meditation));
};
