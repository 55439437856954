import firebase from 'firebase/compat/app';

export default interface IMeditation {
  id: string;
  pir: firebase.firestore.DocumentReference;
  title: string;
  length?: string;
  note?: string;
  link?: string;
  attachmentUrl?: string;
  attachmentName?: string;
  meditationType: string;
}

/**
 * Add the Meditation type, which can be video, PDF, Text, image, link, audio, or N/A if unknown
 * @param doc An existinging Meditation
 * @returns
 */
export const meditationType = (doc: IMeditation): string => {
  if (doc.meditationType) return doc.meditationType;

  if (doc.attachmentName && doc.attachmentName.length > 0) {
    const extension =
      doc.attachmentName.toLowerCase().substring(doc.attachmentName.lastIndexOf('.') + 1, doc.attachmentName.length) ||
      doc.attachmentName;
    if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
      return 'image';
    } else if (['mov', 'mpg', 'mpeg4', 'mp4', 'avi'].includes(extension)) {
      return 'video';
    } else if (['pdf', 'txt'].includes(extension)) {
      return 'pdf';
    } else if (['txt'].includes(extension)) {
      return 'text';
    } else if (['m4a', 'flac', 'mp3', 'wav', 'wma', 'aac'].includes(extension)) {
      return 'audio';
    }
  } else if (doc.link && doc.link.length > 0) {
    const validVideoType = [
      'youtu.be',
      'youtube',
      'facebook',
      'soundcloud',
      'twitch',
      'vimeo',
      'mux',
      'streamable',
      'wistia',
      'dailymotion',
      'mixcloud',
      'vidyard',
    ];
    const link = doc.link;
    const checkValidVideo = validVideoType.find((video) => link.toLowerCase().indexOf(video) > -1);
    if (checkValidVideo) {
      return 'video';
    }
    return 'link';
  }

  if(doc.note?.length){
    return "note"
  }
  
  return 'unknown';
};
