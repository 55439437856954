import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, Link, Flex } from '@chakra-ui/react';
import { IIncompleteLinkedUser, IRejectedLinkedUser } from '../../modules/linked-users/interfaces/ILinkedUser';
import { isRejectedLinkedUser, isIncompleteLinkedUser } from '../../modules/linked-users/utils';
import FAIcon from '../../components/FAIcon';

interface Props {
  networkMember: IIncompleteLinkedUser | IRejectedLinkedUser;
  onEdit(): void;
  onDelete(): void;
}

const PendingOrRejectedContact = ({ networkMember, onEdit, onDelete }: Props): JSX.Element => {
  const { t } = useTranslation('common');
  const phone = 'phone' in networkMember && networkMember.phone ? networkMember.phone : networkMember.otherUserPhone;
  const email = 'email' in networkMember && networkMember.email ? networkMember.email : networkMember.otherUserEmail;
  const doNotNotify =
    isRejectedLinkedUser(networkMember) ||
    ('notificationOnSOS' in networkMember && networkMember.notificationOnSOS === false)
      ? true
      : false;

  return (
    <Box
      p={4}
      bg="grey6.50"
      mb={4}
      data-test={isRejectedLinkedUser(networkMember) ? networkMember.otherUserEmail : networkMember.email}
      border={doNotNotify ? '' : '.5px solid'}
      borderRadius={8}
      borderColor={doNotNotify ? '' : 'purple3.600'}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <FAIcon
          icon={doNotNotify ? 'bell-slash' : 'bell'}
          mr={6}
          as="div"
          display="inline-block"
          fontSize={18}
          width={6}
          color="grey.300"
          mt="-2"
        />
        <Flex>
          <Flex justifyContent="flex-end" fontSize={12}>
            {isIncompleteLinkedUser(networkMember) && (
              <Link
                ml={2}
                textDecoration="underline"
                onClick={onEdit}
                _hover={{ textDecoration: 'none' }}
                data-test={networkMember.contactAlias + 'edit'}
              >
                {t('manageContactModal.editLink')}
              </Link>
            )}

            <Text mx={2} fontStyle="italic">
              {isRejectedLinkedUser(networkMember)
                ? t('manageContactModal.rejectedNotice')
                : t('manageContactModal.pendingNotice')}
            </Text>

            <Link
              textDecoration="underline"
              onClick={onDelete}
              _hover={{ textDecoration: 'none' }}
              data-test={networkMember.contactAlias + 'delete'}
            >
              {t('manageContactModal.deleteLink')}
            </Link>
          </Flex>
        </Flex>
      </Flex>

      {networkMember.contactAlias && (
        <Text fontWeight="bold" fontSize={14}>
          {networkMember.contactAlias}
        </Text>
      )}

      {/* for pending invites from before the move to contact Alias */}
      {!networkMember.contactAlias && isIncompleteLinkedUser(networkMember) && networkMember.name && (
        <Text fontWeight="bold" fontSize={14}>
          {networkMember.name}
        </Text>
      )}

      {phone && (
        <Text fontSize={14} overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
          {phone}
        </Text>
      )}

      <Text fontSize={14} overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
        {email}
      </Text>

      {networkMember.relationship && (
        <Text fontSize={14} overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
          {networkMember.relationship}
        </Text>
      )}

      {networkMember.preferredName && (
        <Text fontSize={14} fontStyle="italic" overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
          Identifies you as {networkMember.preferredName}
        </Text>
      )}
    </Box>
  );
};

export default PendingOrRejectedContact;
