import React from 'react';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  Flex,
  Text,
  Box,
} from '@chakra-ui/react';
import FAIcon from '../FAIcon';
import DayPicker from 'react-day-picker';
import { bindActionCreators } from 'redux';
import * as FreeDateActionCreators from '../../modules/freeDate/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
}

type Props = SuppliedProps;

const FreeDateModal = (props: Props): React.ReactElement => {
  const { t } = useTranslation('free_date');
  const { isOpen, onClose } = props;
  const { isPhone } = useDevice();

  const stateFreeDate = useSelector((state: RootState) => state.freeDate.freeDate);
  const selectedDate = stateFreeDate ? stateFreeDate.date : null;

  const stateLinkedUser = useSelector((state: RootState) => state.linkedUsers);
  const selectedLinkedUser = stateLinkedUser.selectedLinkedUser;

  const dispatch = useDispatch();
  const { createFreeDate, updateFreeDate } = bindActionCreators(FreeDateActionCreators, dispatch);

  const handleDayClick = (date: Date) => {
    if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
      throw new Error(
        'Selected linked user is null when linked user should already be selected when using this component.',
      );
    }
    const pir = selectedLinkedUser.pir;

    const currDate = new Date();
    const freeDate = new Date(date);
    freeDate.setHours(0, 0, 0, 0);
    if (freeDate < currDate) {
      if (!stateFreeDate) {
        // No freeDate object created
        createFreeDate({
          pir,
          date,
        });
      } else if (window.confirm('Are you sure you want to change your free date?')) {
        // Update freeDate object with new date
        updateFreeDate({
          id: stateFreeDate.id,
          pir,
          date,
        });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent
        maxWidth={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : ['100%', '98%', '90%', '85%', '75%']}
        minHeight={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : undefined}
        marginBottom={isPhone ? [0, 0, 0] : undefined}
        marginTop={isPhone ? [0, 0, 0] : undefined}
        p={[2, 2, 4, 8]}
        zIndex={1900}
      >
        <ModalHeader fontWeight="light" color="purple3.600" fontSize={24} textAlign="center" px={[1, 1, 6]}>
          <FAIcon icon="mortar-pestle" mr={4} />
          {t('freeDateModal.header')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w="50%" mb={5}>
            <Text fontSize={18} mb={5}>
              {selectedDate
                ? t('freeDateModal.subheaderSelected', { freeDate: selectedDate.toLocaleDateString('en-us') })
                : t('freeDateModal.subheaderUnselected')}
            </Text>
          </Box>
          <Flex direction="column" alignItems="center">
            {selectedDate ? (
              <DayPicker
                selectedDays={{ from: selectedDate, to: new Date() }}
                disabledDays={[{ after: new Date() }]}
                onDayClick={handleDayClick}
              />
            ) : (
              <DayPicker disabledDays={[{ after: new Date() }]} onDayClick={handleDayClick} />
            )}
          </Flex>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default FreeDateModal;
