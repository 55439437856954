import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAnimation, motion } from 'framer-motion';
import { Heading, Flex, Button, Text, Input } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import { updatePartialUser, updateInvitedAsRole } from '../user/actions';
import { useHistory } from 'react-router-dom';
import NarrowInterior from '../../components/NarrowInterior';
import { InvitedAsRole } from '../invitations/userInvitation';
import { LinkedUserRole } from '../linked-users/interfaces/ILinkedUser';
import {
  CreatedLinkedUserAction,
  createPirLinkedUser,
  createLinkedUser,
  selectLinkedUser,
  getLinkedUsers,
} from '../linked-users/actions';
import { FirestoreUtils } from '../firestore/Firestore';

const EnterName = (props: PropsFromRedux): JSX.Element => {
  const {
    user,
    updatePartialUser,
    invitedAsRole,
    updateInvitedAsRole,
    createPirLinkedUser,
    createLinkedUser,
    selectLinkedUser,
  } = props;
  const { t } = useTranslation('onboarding');
  const headerControls = useAnimation();
  const MotionHeading = motion(Heading);
  const { register, handleSubmit, watch } = useForm();
  const stepTwoControls = useAnimation();
  const history = useHistory();
  const stepThreeControls = useAnimation();

  /**
   * Collects the name and stores that in the database
   */
  const firstStepSubmit = async (data: Record<string, string>): Promise<void> => {
    await updatePartialUser(user.id, { name: data.name });
    stepTwoControls.set({ opacity: 0 });
    await stepTwoControls.start({ opacity: 1, transition: { duration: 0.5 } });
    let invitedAs;
    if (!invitedAsRole) {
      invitedAs = await updateInvitedAsRole();
    } else {
      invitedAs = invitedAsRole;
    }

    const userRole = invitedAs === InvitedAsRole.CP ? 'cp' : invitedAs === InvitedAsRole.USER ? 'pir' : undefined;
    const linkedUserRole = invitedAs === InvitedAsRole.CP ? LinkedUserRole.PROVIDER : LinkedUserRole.PIR;

    if (userRole) await secondStepSubmit(userRole, linkedUserRole);
  };

  const secondStepSubmit = async (userRole: string, role: LinkedUserRole): Promise<void> => {
    let createdLUAction: CreatedLinkedUserAction;
    await updatePartialUser(user.id, { role: userRole });
    if (role === LinkedUserRole.PIR) {
      createdLUAction = await createPirLinkedUser({
        pir: FirestoreUtils.getDocRef('users', user.id),
        default: false,
        approvedByPir: true,
        reviewedByPir: true,
        dateReviewedByPir: FirestoreUtils.now(),
      });
      if (createdLUAction) {
        history.push(window.cordova ? '/fcm-token' : '/notifications');
      }
    } else {
      createdLUAction = await createLinkedUser({
        otherUser: FirestoreUtils.getDocRef('users', user.id),
        otherUserRole: role,
        default: false,
        reviewedByOther: true,
        approvedByOther: true,
        dateReviewedByOther: FirestoreUtils.now(),
      });
      history.push('/notifications');
    }
    await getLinkedUsers(user.id);
    await selectLinkedUser(createdLUAction.linkedUser);
    stepThreeControls.start({ opacity: 0, transition: { delay: 0.5, duration: 0.5 } });
    await headerControls.start({ opacity: 0, transition: { delay: 0.5, duration: 0.5 } });
  };

  return (
    <NarrowInterior>
      <MotionHeading as="h2" my={6} fontSize={24} fontWeight="light" color="purple3.600" animate={headerControls}>
        {t('welcome')}
      </MotionHeading>
      {/* Enter name page */}
      <Flex flexDir="column" mt={16}>
        <form onSubmit={handleSubmit(firstStepSubmit)}>
          <Text fontSize={24} fontWeight="light" color="purple3.600">
            {t('name')}
          </Text>
          <Flex mt={4} flexDir="row" alignItems="center" justifyContent="space-between">
            <Input {...register('name')} bg="white" flex={1} placeholder={t('namePlaceholder')} />

            <Button
              type="submit"
              disabled={!watch('name')}
              backgroundColor="blue3.100"
              fontWeight="normal"
              _hover={{ backgroundColor: 'blue3.300' }}
              whiteSpace="normal"
              ml={4}
            >
              {t('nextButton')}
            </Button>
          </Flex>
        </form>
      </Flex>
    </NarrowInterior>
  );
};

const mapStateToProps = (state: RootState) => {
  const user = state.user.user;
  if (user === null) {
    throw new Error('No authenticated user to onbaord');
  }
  return {
    user,
    invitedAsRole: state.user.invitedAsRole,
  };
};

const mapDispatchToProps = {
  updatePartialUser,
  updateInvitedAsRole,
  createLinkedUser,
  createPirLinkedUser,
  selectLinkedUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(EnterName);
