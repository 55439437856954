import { useMutation, useQuery, useQueryClient, UseQueryResult, UseMutationResult } from 'react-query';
import { getAppointments, createAppointment, deleteAppointment, updateAppointment } from '../../db/appointments';
import ILinkedUser, { LinkedUserRole } from '../linked-users/interfaces/ILinkedUser';
import IAppointment from './interfaces/IAppointment';

const KEY_NAME = 'appointments';

export const useQueryAppointments = (
  pirId: string,
  userType: LinkedUserRole,
  linkedUsers: ILinkedUser[],
): UseQueryResult<IAppointment[], unknown> => {
  return useQuery([KEY_NAME, pirId], async () => getAppointments(pirId, userType, linkedUsers), {
    staleTime: Infinity,
  });
};

export const useCreateAppointment = (pirId: string): UseMutationResult<void, unknown, Omit<IAppointment, 'id'>> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (rawAppointment: Omit<IAppointment, 'id'>) => {
      return createAppointment(rawAppointment);
    },
    {
      onSuccess: async () => queryClient.invalidateQueries([KEY_NAME, pirId]),
    },
  );
};

export const useDeleteAppointment = (pirId: string): UseMutationResult<void, unknown, IAppointment> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (appointment: IAppointment) => {
      return deleteAppointment(appointment);
    },
    {
      onSuccess: async () => queryClient.invalidateQueries([KEY_NAME, pirId]),
    },
  );
};

export const useUpdateAppointment = (
  pirId: string,
): UseMutationResult<void, unknown, { id: string; appointmentUpdates: Partial<IAppointment> }> => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, appointmentUpdates }: { id: string; appointmentUpdates: Partial<IAppointment> }) => {
      return updateAppointment(id, appointmentUpdates);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_NAME, pirId]);
      },
    },
  );
};
