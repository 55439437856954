import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  Flex,
  Box,
  Text,
  Switch,
} from '@chakra-ui/react';
import FAIcon from '../FAIcon';
import { RootState } from '../../redux/store';
import { getUser, updateUser } from '../../modules/user/actions';
import IUser from '../../modules/user/interfaces/IUser';
import { getByRef as getUserByRef } from '../../modules/user/utils';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
}

type Props = SuppliedProps & PropsFromRedux;

const VitalsModal = (props: Props): React.ReactElement => {
  const { t } = useTranslation('resources');
  const {
    isOpen,
    onClose,
    selectedLinkedUser,
    updateUser,
    //   getUser
  } = props;
  const [selectedUser, setSelectedUser] = useState<IUser | null>();
  const [checkedColor, setColorChecked] = useState(selectedUser?.showColor);
  const [checkedVitals, setVitalsChecked] = useState(selectedUser?.showVitals);
  const { isPhone } = useDevice();

  // useEffect(() => {
  //   if (selectedLinkedUser.pir) {
  //     getUserByRef(selectedLinkedUser.pir).then((v) => {
  //       setSelectedUser(v);
  //       setVitalsChecked(v?.showVitals)
  //       setColorChecked(v?.showColor)
  //     });
  //   }
  // }, [selectedLinkedUser])

  useEffect(() => {
    let isMounted = true;
    if (selectedLinkedUser.pir) {
      getUserByRef(selectedLinkedUser.pir)
        .then((v) => {
          if (isMounted) {
            setSelectedUser(v);
            setVitalsChecked(v?.showVitals);
            setColorChecked(v?.showColor);
          }
        })
        .catch((e) => console.error(`could not get user by ref in vitals modal: ${e}`));
    }
    return () => {
      isMounted = false;
    };
  }, [selectedLinkedUser]);

  const name =
    'pirAlias' in selectedLinkedUser
      ? selectedLinkedUser.pirAlias
      : 'preferredName' in selectedLinkedUser
      ? selectedLinkedUser.preferredName
      : selectedUser && selectedUser.name
      ? selectedUser.name
      : 'Person';

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent
        maxWidth={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : ['100%', '98%', '90%', '85%', '75%']}
        minHeight={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : undefined}
        marginBottom={isPhone ? [0, 0, 0] : undefined}
        marginTop={isPhone ? [0, 0, 0] : undefined}
        p={[2, 2, 4, 8]}
        zIndex={1900}
      >
        <ModalHeader fontWeight="light" color="purple3.600" fontSize={24} textAlign="center" px={[1, 1, 6]}>
          <FAIcon icon="book-open" mr={4} />
          {t('vitalsModal.header')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w="50%" mb={5}>
            <Text fontSize={18} mb={5}>
              {t('vitalsModal.subheader', {
                name,
              })}
            </Text>
          </Box>
          <Flex direction="column" alignItems="left">
            <Text isTruncated={true} color="black" fontSize={[16, 16, 18]}>
              Show Colors
            </Text>
            <Switch
              alignItems="center"
              size="lg"
              w="80%"
              mb={4}
              isChecked={checkedColor}
              onChange={() => {
                if (selectedUser) {
                  setColorChecked(!selectedUser.showColor);
                  selectedUser.showColor = !selectedUser.showColor;
                  updateUser(selectedUser);
                }
              }}
            />
            <Text isTruncated={true} color="black" fontSize={[16, 16, 18]}>
              Show Graphs
            </Text>
            <Switch
              alignItems="center"
              size="lg"
              w="80%"
              isChecked={checkedVitals}
              onChange={() => {
                if (selectedUser) {
                  setVitalsChecked(!selectedUser.showVitals);
                  selectedUser.showVitals = !selectedUser.showVitals;
                  updateUser(selectedUser);
                }
              }}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          {/* Cancel button temporarily disabled */}
          {/* <Link onClick={onClose} fontSize={14} mt={2} mr={4}>
            {t('manageResourceModal.modalCancel')}
          </Link> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  const { selectedLinkedUser } = state.linkedUsers;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }
  return {
    selectedLinkedUser,
  };
};

const mapDispatchToProps = {
  updateUser,
  getUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(VitalsModal);
