import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, useSortBy, useTable } from 'react-table';
import FAIcon from '../../components/FAIcon';
import ISupportRequest from '../../db/supportRequests';
import { metricColor } from '../../lib/colorThresholds';
import { PossibleLinkedUser } from '../linked-users/interfaces/ILinkedUser';
import SupportRequestsPreview from '../supportRequests/SupportRequestsPreview';
import VitalsModuleCP from '../vitals/VitalsModuleCP';
import { PirUsersData } from './LinkedUsers';
import { GraphType } from '../vitals/vitalsGraphOptions';
import { useTranslation } from 'react-i18next';
import {
  healthEstimateScore,
  emotionCardTranslationKey,
  cravingCardTranslationKey,
  stressCardTranslationKey,
} from '../dashboardMonitor/data2';

interface TableAppViewProps {
  tableData: PirUsersData[];
  columnsData: any[];
  tabletView?: boolean;
  onSelect: (linkedUser: PossibleLinkedUser) => Promise<void>;
}

export interface PirUsersDataWithHealthEstimate {
  [key: string]: any;
  linkedUser?: PossibleLinkedUser;
  person?: string;
  preferredName?: string;
  pirAlias?: string;
  craving: number;
  participation: number;
  watchUse: number;
  sleep: number;
  emotion: number;
  stress: number;
  incidents: number;
  supportRequests?: ISupportRequest[];
  image?: JSX.Element;
  arrow?: JSX.Element;
  healthEstimate: number;
}

const TableAppView = ({ columnsData, tableData, tabletView, onSelect }: TableAppViewProps): JSX.Element => {
  const history = useHistory();
  const [contentStack, setContentStack] = useState<JSX.Element[]>([]);
  const [activeItemId, setActiveItemId] = useState<string | null>(null);
  const columns = React.useMemo(() => columnsData, [columnsData]);
  const { t } = useTranslation('vitals');

  const modifyTableData = React.useMemo(() => {
    return (data: PirUsersData[]) => {
      return data.map((item: PirUsersData) => {
        const healthCraving =
          typeof item.craving === 'undefined' || typeof item.craving === 'string' || item.craving < 0
            ? -999
            : item.craving;
        const healthEmotion =
          typeof item.emotion === 'undefined' || typeof item.emotion === 'string' || item.emotion <= -11
            ? -999
            : item.emotion;
        const healthStress =
          typeof item.stress === 'undefined' || typeof item.stress === 'string' || item.stress < 0 ? -999 : item.stress;
        const healthSleep =
          typeof item.sleep === 'undefined' || typeof item.sleep === 'string' || item.sleep < 0 ? -999 : item.sleep;
        const healthIncidents =
          typeof item.incidents === 'undefined' || typeof item.incidents === 'string' || item.incidents < 0
            ? -999
            : item.incidents;
        const possibleHealthEstimate = healthEstimateScore(
          healthCraving,
          healthEmotion,
          healthStress,
          healthSleep,
          healthIncidents,
        );
        return {
          ...item,
          healthEstimate: possibleHealthEstimate,
        };
      }) as PirUsersDataWithHealthEstimate[];
    };
  }, []);

  const modifiedTableData = React.useMemo(() => modifyTableData(tableData), [modifyTableData, tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: modifiedTableData,
      initialState: {
        sortBy: [],
      },
    },
    useSortBy,
  );

  // converting value type of string & undefined to number because metricCPColor only takes in the type number;
  const metricColorSafe = (ctype: string, value: number | string | undefined, show: boolean) => {
    show = true;
    if (typeof value === 'string') {
      value = Number(value);
    } else if (typeof value === 'undefined') {
      value = 0;
      show = false;
    }
    return metricColor(ctype, value, show);
  };

  const trackCellClick = async (
    linkedUser: any,
    row: Row<PirUsersDataWithHealthEstimate>,
    colID: string,
  ): Promise<void> => {
    if (linkedUser) {
      //console.log('row', row.original);
      await onSelect(linkedUser);
    }

    const handleClick = (to: string) => {
      if (linkedUser) {
        history.push(to);
      }
    };

    const rowIdx = row.index;
    //console.log('<<row.original.craving>>', row.original.craving);
    //const cravingTest = t(`${cravingText(row.original)}.condition`);
    //console.log(cravingTest);

    const userButtons = (
      <>
        <Button
          onClick={() => {
            handleClick('/profile');
          }}
          variant="outline"
          borderColor={'purple3.600'}
          fontSize="14px"
          height="30px"
          width="120px"
        >
          Go to Profile
        </Button>
        <Button
          onClick={() => {
            handleClick('/vitals');
          }}
          fontSize="14px"
          height="30px"
          color={'white'}
          background={'purple3.600'}
          width="120px"
          ml="5"
        >
          Go to Vitals
        </Button>
      </>
    );

    const graphCase = ['participation', 'watchUse', 'sleep', 'emotion', 'stress', 'incidents'];

    let el: JSX.Element;
    switch (colID) {
      case graphCase.find((id) => id === colID):
        el = (
          <>
            <VitalsModuleCP key={colID} uid={linkedUser.pir.id} selectedVital={colID as GraphType} />
            {userButtons}
          </>
        );
        break;
      case 'supportRequests':
        el = (
          <>
            {getSupportRequests(rowIdx).length > 0 ? (
              <Box padding={6}>
                <SupportRequestsPreview requests={getSupportRequests(rowIdx)} />
              </Box>
            ) : (
              <Text padding={5}>No recent support requests</Text>
            )}
            {userButtons}
          </>
        );
        break;
      default:
        el = (
          <>
            <Wrap spacing="30px">
              {row.original?.pirAlias && (
                <WrapItem
                  justifyContent={'space-between'}
                  w="100%"
                  borderTop={'1px dashed #E5E5E5'}
                  borderBottom={'1px dashed #E5E5E5'}
                  pt={'10px'}
                  pb={'10px'}
                >
                  <Box fontSize="14px" color={'#8F8F8F'} fontWeight="500" textTransform="uppercase">
                    Preferred Name
                  </Box>
                  <Box fontSize="14px" color={'#000000'} fontWeight="500" textAlign={'right'}>
                    <Flex flexDirection="row" alignItems="baseline">
                      <Text fontSize={['14px', '20px']} fontWeight="bold">
                        {row.original?.preferredName}
                      </Text>
                    </Flex>
                  </Box>
                </WrapItem>
              )}
              <WrapItem
                justifyContent={'space-between'}
                w="100%"
                borderBottom={'1px dashed #E5E5E5'}
                borderTop={row.original?.pirAlias ? 'none' : '1px dashed #E5E5E5'}
                marginTop={row.original?.pirAlias ? '0px !important' : '10px !important'}
                pt={row.original?.pirAlias ? '0px' : '10px'}
                pb={'10px'}
              >
                <Box fontSize="14px" color={'#8F8F8F'} fontWeight="500" textTransform="uppercase">
                  {t('vitalsSummary.headers.healthEstimate')}
                </Box>
                <Box fontSize="14px" color={'#27AE5F'} fontWeight="500" textAlign={'right'}>
                  <Flex flexDirection="row" alignItems="baseline">
                    <Text fontSize={['14px', '20px']} fontWeight="bold">
                      {healthEstimateScore(
                        row.original.craving,
                        row.original.emotion,
                        row.original.stress,
                        row.original.sleep,
                        row.original.incidents,
                      ) === -1
                        ? '-'
                        : healthEstimateScore(
                            row.original.craving,
                            row.original.emotion,
                            row.original.stress,
                            row.original.sleep,
                            row.original.incidents,
                          )}
                    </Text>
                    <Text fontSize={['14px', '20px']}>
                      {healthEstimateScore(
                        row.original.craving,
                        row.original.emotion,
                        row.original.stress,
                        row.original.sleep,
                        row.original.incidents,
                      ) >= 0 && '/ 100'}
                    </Text>
                  </Flex>
                </Box>
              </WrapItem>
              <WrapItem
                justifyContent={'space-between'}
                w="100%"
                borderBottom={'1px dashed #E5E5E5'}
                marginTop={'0px !important'}
                pb={'10px'}
              >
                <Box fontSize="14px" color={'#8F8F8F'} fontWeight="500" textTransform="uppercase">
                  {t('vitalsSummary.headers.craving')}
                </Box>
                <Box fontSize="14px" color={'#27AE5F'} fontWeight="500" textAlign={'right'}>
                  {/* <Text fontSize={['20px', '30px']} fontWeight="bold">
                    {row.original.craving < 0 ? '-' : t(`${cravingCardTranslationKey(row.original.craving)}.level`)}
                  </Text> */}
                  <Text fontSize={['14px', '20px']} fontWeight="bold">
                    {row.original.craving > 0 ? t(`${cravingCardTranslationKey(row.original.craving)}.condition`) : '-'}
                  </Text>
                </Box>
              </WrapItem>
              <WrapItem
                justifyContent={'space-between'}
                w="100%"
                borderBottom={'1px dashed #E5E5E5'}
                marginTop={'0px !important'}
                pb={'10px'}
              >
                <Box fontSize="14px" color={'#8F8F8F'} fontWeight="500" textTransform="uppercase">
                  {t('vitalsSummary.headers.emotion')}
                </Box>
                <Box
                  fontSize="14px"
                  color={'#27AE5F'}
                  fontWeight="500"
                  textAlign={'right'}
                  display={'Flex'}
                  alignItems={'center'}
                >
                  {/* <svg style={{ 'marginRight': '0.5rem' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.0607 0.939341C12.4749 0.353554 11.5251 0.353554 10.9393 0.939341L1.3934 10.4853C0.807611 11.0711 0.807611 12.0208 1.3934 12.6066C1.97918 13.1924 2.92893 13.1924 3.51472 12.6066L12 4.12132L20.4853 12.6066C21.0711 13.1924 22.0208 13.1924 22.6066 12.6066C23.1924 12.0208 23.1924 11.0711 22.6066 10.4853L13.0607 0.939341ZM13.5 24L13.5 2L10.5 2L10.5 24L13.5 24Z" fill="#27AE5F" />
                  </svg> */}
                  {row.original.emotion > -12 && (
                    <FAIcon icon={t(`${emotionCardTranslationKey(row.original.emotion)}.icon`)} fontSize={[20, 40]} />
                  )}
                  <Text fontSize={['14px', '20px']} fontWeight="bold" marginLeft="5px">
                    {row.original.emotion < -11
                      ? '-'
                      : t(`${emotionCardTranslationKey(row.original.emotion)}.condition`)}
                  </Text>
                </Box>
              </WrapItem>
              <WrapItem
                justifyContent={'space-between'}
                w="100%"
                borderBottom={'1px dashed #E5E5E5'}
                marginTop={'0px !important'}
                pb={'10px'}
              >
                <Box fontSize="14px" color={'#8F8F8F'} fontWeight="500" textTransform="uppercase">
                  {t('vitalsSummary.headers.stress')}
                </Box>
                <Box fontSize="14px" color={'#27AE5F'} fontWeight="500" textAlign={'right'}>
                  {/* <Text fontSize={['20px', '30px']} fontWeight="bold">
                    {row.original.stress < 0 ? '-' : t(`${stressCardTranslationKey(row.original.stress)}.letter`)
                      .charAt(0)
                      .toUpperCase()}
                  </Text> */}
                  <Text fontSize={['14px', '20px']} fontWeight="bold">
                    {row.original.stress < 0 ? '-' : t(`${stressCardTranslationKey(row.original.stress)}.condition`)}
                  </Text>
                </Box>
              </WrapItem>
              <WrapItem
                justifyContent={'space-between'}
                w="100%"
                borderBottom={'1px dashed #E5E5E5'}
                marginTop={'0px !important'}
                pb={'10px'}
              >
                <Box fontSize="14px" color={'#8F8F8F'} fontWeight="500" textTransform="uppercase">
                  {t('vitalsSummary.headers.Participation')}
                </Box>
                <Box fontSize="14px" color={'#F2994A'} fontWeight="500" textAlign={'right'}>
                  <Box display={'flex'}>
                    {/* <span style={{'display': 'flex',  'margin': '0 1rem 0 0'}}>
                      <svg style={{'marginRight': '0.25rem'}} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_2892_4328)">
                          <path d="M16.6668 10.5C16.6668 8.38333 15.6752 6.49167 14.1335 5.275L13.3335 0.5H6.66683L5.87516 5.275C4.32516 6.49167 3.3335 8.375 3.3335 10.5C3.3335 12.625 4.32516 14.5083 5.87516 15.725L6.66683 20.5H13.3335L14.1335 15.725C15.6752 14.5083 16.6668 12.6167 16.6668 10.5ZM5.00016 10.5C5.00016 7.74167 7.24183 5.5 10.0002 5.5C12.7585 5.5 15.0002 7.74167 15.0002 10.5C15.0002 13.2583 12.7585 15.5 10.0002 15.5C7.24183 15.5 5.00016 13.2583 5.00016 10.5Z" fill="#C4C4C4" />
                        </g>
                        <defs>
                          <clipPath id="clip0_2892_4328">
                            <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                          </clipPath>
                        </defs>
                      </svg>
                      12 <em style={{'color': '#C4C4C4', 'fontStyle': 'normal', "marginLeft": '2px'}}>hrs</em></span>
                    <span style={{'display': 'flex'}}><svg style={{'marginRight': '0.25rem'}} width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2892_4321)">
                        <path d="M11.05 0.5H1.95C0.873437 0.5 0 1.33984 0 2.375V18.625C0 19.6602 0.873437 20.5 1.95 20.5H11.05C12.1266 20.5 13 19.6602 13 18.625V2.375C13 1.33984 12.1266 0.5 11.05 0.5ZM6.5 19.25C5.78094 19.25 5.2 18.6914 5.2 18C5.2 17.3086 5.78094 16.75 6.5 16.75C7.21906 16.75 7.8 17.3086 7.8 18C7.8 18.6914 7.21906 19.25 6.5 19.25ZM11.05 15.0312C11.05 15.2891 10.8306 15.5 10.5625 15.5H2.4375C2.16937 15.5 1.95 15.2891 1.95 15.0312V2.84375C1.95 2.58594 2.16937 2.375 2.4375 2.375H10.5625C10.8306 2.375 11.05 2.58594 11.05 2.84375V15.0312Z" fill="#C4C4C4" />
                      </g>
                      <defs>
                        <clipPath id="clip0_2892_4321">
                          <rect width="13" height="20" fill="white" transform="translate(0 0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                      8 <em style={{'color': '#C4C4C4', 'fontStyle': 'normal', "marginLeft": '2px'}}>hrs</em></span> */}

                    <Text fontSize={['14px', '20px']} fontWeight="bold">
                      {row.original.participation < 0
                        ? '-'
                        : `${Math.max(0, Math.round(row.original.participation * 100))}%`}
                    </Text>
                    {/* <Text fontSize={['14px', '14px']} fontWeight="bold" marginLeft="5px">
                      { row.original.participation < 0 ? ' ' : t(`${participationCardTranslationKey(row.original.participation)}.condition`)}
                    </Text> */}
                  </Box>
                </Box>
              </WrapItem>
              <WrapItem
                justifyContent={'space-between'}
                w="100%"
                borderBottom={'1px dashed #E5E5E5'}
                marginTop={'0px !important'}
                pb={'10px'}
              >
                <Box fontSize="14px" color={'#8F8F8F'} fontWeight="500" textTransform="uppercase">
                  {t('vitalsSummary.headers.sleep')}
                </Box>
                <Box fontSize="14px" color={'#27AE5F'} fontWeight="500" textAlign={'right'}>
                  {/* 10 <em style={{'color': '#C4C4C4', 'fontStyle': 'normal', "marginLeft": '2px'}}>hrs</em> */}
                  <Text fontSize={['14px', '20px']} fontWeight="bold">
                    {row.original.sleep < 0 ? '-' : `${Math.max(0, Math.round(row.original.sleep * 10) / 10)} hrs`}
                  </Text>
                  {/* <Text fontSize={['14px', '20px']} fontWeight="bold" fontStyle="italic">
                    { row.original.sleep < 0 ? ' ' : t(`${sleepCardTranslationKey(row.original.sleep)}.condition`)}
                  </Text> */}
                </Box>
              </WrapItem>
              <WrapItem
                justifyContent={'space-between'}
                w="100%"
                borderBottom={'1px dashed #E5E5E5'}
                marginTop={'0px !important'}
                pb={'10px'}
              >
                <Box fontSize="14px" color={'#8F8F8F'} fontWeight="500" textTransform="uppercase">
                  {t('vitalsSummary.headers.incidents')}
                </Box>
                <Box fontSize="14px" color={'#F02F2F'} fontWeight="500" textAlign={'right'}>
                  <Box color={metricColorSafe('incidents', modifiedTableData[rowIdx].incidents, true)}>
                    {Math.max(0, Number(modifiedTableData[rowIdx].incidents))}
                  </Box>
                </Box>
              </WrapItem>
              <WrapItem
                justifyContent={'space-between'}
                w="100%"
                borderBottom={'1px dashed #E5E5E5'}
                marginTop={'0px !important'}
                pb={'10px'}
              >
                <Box fontSize="14px" color={'#8F8F8F'} fontWeight="500" textTransform="uppercase">
                  SUPPORT REQUESTS
                </Box>
                <Box fontSize="14px" color={'#F02F2F'} fontWeight="500" textAlign={'right'}>
                  <Box
                    color={metricColorSafe(
                      'supportRequests',
                      (modifiedTableData[rowIdx].supportRequests || []).length,
                      true,
                    )}
                  >
                    {(modifiedTableData[rowIdx].supportRequests || []).length}
                  </Box>
                </Box>
              </WrapItem>
              <WrapItem justifyContent={'center'} w="100%" marginTop={'0px !important'}>
                {userButtons}
              </WrapItem>
            </Wrap>
          </>
        );
    }
    setActiveItemId(row.id);
    setContentStack((stack) => [...stack, el]);
  };

  const getSupportRequests = (index: number): ISupportRequest[] => modifiedTableData[index].supportRequests || [];

  return (
    <>
      {/* Header Bar */}
      <>
        <Flex
          fontFamily="roboto, sans-serif"
          fontWeight="500"
          fontSize="14px"
          textAlign="center"
          color="white"
          bg="#4F4F4F"
          borderRadius="md"
          py={1}
          mb={0}
          h={30}
          borderTopRadius={8}
          borderBottomRadius={0}
          width="100%"
          {...getTableProps()}
        >
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <Flex width="100%" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup?.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <Flex
                  flex="1"
                  justifyContent="center"
                  alignItems="center"
                  _first={{ maxWidth: '5rem' }}
                  _last={{ maxWidth: '2.5rem' }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? <FAIcon ml={1} icon={column.isSortedDesc ? 'caret-down' : 'caret-up'} /> : ''}
                  </span>
                </Flex>
              ))}
            </Flex>
          ))}
        </Flex>
      </>
      {/* Patient Information Rows */}
      <Box {...getTableBodyProps()}>
        <Accordion allowToggle={true}>
          {rows.map((row, i) => {
            prepareRow(row);
            const isActive = row.id === activeItemId;
            return (
              <AccordionItem key={i} id={row.id} bg="white" mb="0.25rem" borderRadius="lg">
                <Flex boxShadow="sm" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      // Individual Patient Info cells
                      // eslint-disable-next-line react/jsx-key
                      <Flex
                        flex="1"
                        _first={{ maxWidth: tabletView ? '' : '5rem' }}
                        _last={{ maxWidth: '2.5rem' }}
                        {...cell.getCellProps()}
                      >
                        <AccordionButton
                          px={0}
                          justifyContent="center"
                          alignItems="center"
                          onClick={() => {
                            trackCellClick(cell.row.original.linkedUser, cell.row, cell.column.id);
                          }}
                        >
                          {cell.render('Cell')}
                        </AccordionButton>
                      </Flex>
                    );
                  })}
                </Flex>
                {/* Dropdown Panel */}
                <AccordionPanel bg="white" borderBottom="2px solid #ebebeb" boxShadow="sm" borderRadius="lg">
                  {isActive && <Box>{contentStack[contentStack.length - 1]}</Box>}
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Box>
    </>
  );
};

export default TableAppView;
