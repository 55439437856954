import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Center } from '@chakra-ui/layout';
import {
  Box,
  Button,
  Text,
  Textarea,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Flex,
  Image,
} from '@chakra-ui/react';
import AddWebLink from '../AddWebLink';
import AddMediaFile from '../AddMediaFile';
import AddMediaFileMobile from '../AddMediaFileMobile';
import { RootState } from '../../redux/store';
import { useCreateMeditations, useUpdateMeditations } from '../../modules/meditations/queryHooks';
import { createInteraction } from '../../modules/interactions/actions';
import { moduleName, interactionType } from '../../modules/interactions/constants';
import FAIcon from '../FAIcon';
import urlPattern from '../../constants/urlPattern';
import IMeditation from '../../modules/meditations/interfaces/IMeditation';
import { MediaFormValues } from '../../utils/utils';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import TertiaryButton from '../TertiaryButton';
import { getAttachmentType } from 'src/modules/meditations/utils';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  selectedMeditation: IMeditation | null;
  onSelectMeditation(meditation: IMeditation | null): void;
  setFormIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMeditation: React.Dispatch<React.SetStateAction<IMeditation | null>>;
}

type Props = SuppliedProps;

const AddMeditationForm = ({
  pir,
  createInteraction,
  selectedMeditation,
  setSelectedMeditation,
  onSelectMeditation,
  setFormIsDirty,
}: Props & PropsFromRedux): React.ReactElement => {
  const { t } = useTranslation('meditations');
  const { register, reset, handleSubmit, getValues, formState, setValue, trigger, clearErrors } =
    useForm<MediaFormValues>({
      defaultValues: {
        title: selectedMeditation?.title,
        length: selectedMeditation?.length,
        note: selectedMeditation?.note,
        link: selectedMeditation?.link,
        attachmentUrl: selectedMeditation?.attachmentUrl,
        attachmentName: selectedMeditation?.attachmentName,
      },
    });
  const { isMobileDeviceOrCordova } = useDevice();
  const { errors, dirtyFields } = formState;

  const { mutate: createMeditation, isLoading: meditationCreated } = useCreateMeditations(pir?.id);
  const { mutate: updateMeditation, isLoading: meditationUpdating } = useUpdateMeditations(pir?.id);

  const [showAddButton, setShowAddButton] = useState(true);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [meditationUploading, setMeditationUploading] = useState(false);
  const [currentUpload, setCurrentUpload] = useState<string | null>(null);
  const [saveCurrentUpload, setSaveCurrentUpload] = useState(false);
  const [currentAttachmentUrl, setCurrentAttachmentUrl] = useState<string>();
  const [currentLink, setCurrentLink] = useState<string | null>(null);
  const [saveCurrentLink, setSaveCurrentLink] = useState(false);
  const [uploadChosen, setUploadChosen] = useState(false);

  const [value, setMeditationLengthValue] = useState(1);
  const [meditationLengthInHoursMinutes, setMeditationLengthInHoursMinutes] = useState('');

  const format = (val: number | string): string => {
    if (typeof val === 'number') {
      return val === 1 ? val + ' Minute' : val + ' Minutes';
    } else {
      return '1 Minute';
    }
  };

  const parse = (val: string): number => {
    if (val === '') {
      return 0;
    }
    return parseInt(val.replace(/^(\d+)\sMinutes?$/, '$1'));
  };

  const formRef = useRef<HTMLFormElement>(null);
  const scrollToForm = () => formRef.current?.scrollIntoView();

  const onChangeMeditationLength = (newValue: string) => {
    const meditationMinutes = newValue.replace(/\D/g, ''); //regular expression to replace non-numeric characters with an empty string;
    setMeditationLengthValue(parse(meditationMinutes));

    const hours: number = Math.floor(parseInt(meditationMinutes) / 60);
    const remainingMinutes: number = parseInt(meditationMinutes) % 60;
    if (hours > 0) {
      let unitHours = '';
      let unitMinutes = '';
      if (hours == 1) unitHours = 'Hour';
      else unitHours = 'Hours';

      if (remainingMinutes == 1) unitMinutes = 'minute';
      else unitMinutes = 'minutes';

      setMeditationLengthInHoursMinutes(`${hours} ${unitHours} ${remainingMinutes} ${unitMinutes}`);
    } else {
      setMeditationLengthInHoursMinutes('');
    }
  };

  const numberOfDirtyFields = Object.keys(dirtyFields).filter((key): key is keyof typeof dirtyFields => {
    return dirtyFields[key as keyof typeof dirtyFields] === true;
  }).length;
  const handleUploading = (data: boolean) => {
    setMeditationUploading(data);
  };

  const resetButtonStates = () => {
    setShowAddButton(!showAddButton);
    setShowSaveButton(!showSaveButton);
    setCurrentUpload(null);
    setSaveCurrentUpload(false);
    setSaveCurrentLink(false);
    setCurrentLink(null);
    setUploadChosen(false);
    setFormIsDirty(false);
    reset({
      title: '',
      length: '',
      note: '',
      link: '',
      attachmentUrl: '',
      attachmentName: '',
    });
  };

  const onCancel = () => {
    resetButtonStates();
    setSelectedMeditation(null);
  };

  useEffect(() => {
    return () => {
      setCurrentUpload(null);
      setCurrentLink(null);
      setShowAddButton(true);
      setShowSaveButton(false);
      setUploadChosen(false);
      reset({
        title: '',
        note: '',
        link: '',
        attachmentUrl: '',
        attachmentName: '',
      });
    };
  }, []);

  const displaySaveButton = () => {
    const content = showSaveButton ? t('addMeditationForm.saveButton') : t('addMeditationForm.saveButtonConfirm');

    const icon = !showSaveButton && <FAIcon icon="check" ml={4} fontSize={14} />;

    return (
      <FormControl isRequired={true}>
        <Flex justifyContent="space-between">
          <TertiaryButton
            variant="link"
            isDisabled={!showSaveButton || meditationUploading}
            mt={10}
            onClick={onCancel}
            isLoading={formState.isSubmitting}
            data-test="cancel-meditation"
          >
            {t('meditationModal.modalCancel')}
          </TertiaryButton>
          <PrimaryButton
            isDisabled={!showSaveButton || meditationUploading}
            mt={10}
            isLoading={formState.isSubmitting}
            type="submit"
            data-test="save-meditation"
          >
            {content}
            {icon}
          </PrimaryButton>
        </Flex>
      </FormControl>
    );
  };

  const saveMeditation = (meditation: MediaFormValues): void => {
    const fullWebLink =
      meditation.link === undefined || meditation.link === ''
        ? undefined
        : meditation.link.startsWith('https://') || meditation.link.startsWith('http://')
        ? meditation.link
        : `https://${meditation.link}`;

    if (selectedMeditation?.id) {
      updateMeditation({
        ...selectedMeditation,
        id: selectedMeditation.id,
        pir,
        title: meditation.title as string,
        length: meditation.length?.replace(/^(\d+)\sMinutes?$/, '$1'),
        note: meditation.note,
        link: fullWebLink,
        attachmentUrl: meditation.attachmentUrl,
        attachmentName: meditation.attachmentName,
      });
      onSelectMeditation(null);
    } else {
      createMeditation({
        pir,
        title: meditation.title as string,
        length: meditation.length?.replace(/^(\d+)\sMinutes?$/, '$1'),
        note: meditation.note,
        link: fullWebLink,
        attachmentUrl: meditation.attachmentUrl,
        attachmentName: meditation.attachmentName,
      });
    }
    resetButtonStates();
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MEDITATIONS,
      interactionType: interactionType.MEDITATIONS.SAVED,
    });
  };

  const addModifiedFieldInteraction = () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MEDITATIONS,
      interactionType: interactionType.MEDITATIONS.MODIFIED_FIELD,
    });
  };

  useEffect(() => {
    if (!meditationCreated && !meditationUpdating) {
      showSaveButton && setShowSaveButton(false);
      !showAddButton && setShowAddButton(true);
    }
  }, [meditationCreated, meditationUpdating]);

  useEffect(() => {
    if (selectedMeditation) {
      setShowAddButton(false);
      setShowSaveButton(true);
      reset(selectedMeditation);
      scrollToForm();
      if (selectedMeditation.attachmentName) {
        setCurrentUpload(selectedMeditation.attachmentName);
        setSaveCurrentUpload(true);
      }
      if (selectedMeditation.attachmentUrl) {
        setCurrentAttachmentUrl(selectedMeditation.attachmentUrl);
      }
      if (selectedMeditation.link) {
        setCurrentLink(selectedMeditation.link);
        setSaveCurrentLink(true);

        if (selectedMeditation.length) {
          if (typeof selectedMeditation.length === 'string') {
            setMeditationLengthValue(parseInt(selectedMeditation.length));
            onChangeMeditationLength(selectedMeditation.length);
          }
        }
      }
    }
  }, [selectedMeditation, reset]);

  useEffect(() => {
    if (saveCurrentUpload && selectedMeditation?.attachmentName && selectedMeditation?.attachmentUrl) {
      setValue('attachmentName', selectedMeditation.attachmentName);
      setValue('attachmentUrl', selectedMeditation.attachmentUrl);
      clearErrors();
    } else {
      setValue('attachmentName', '');
      setValue('attachmentUrl', '');
    }
    // so users don't think they've saved removal of uploads without saving
    if (!saveCurrentUpload && currentUpload) {
      setFormIsDirty(true);
    }
  }, [saveCurrentUpload]);

  useEffect(() => {
    if (saveCurrentLink && selectedMeditation?.link) {
      setValue('link', selectedMeditation.link);
      clearErrors();
    } else {
      setValue('link', '');
    }
    // so users don't think they've saved removal of links without saving
    if (!saveCurrentLink && currentLink) {
      setFormIsDirty(true);
    }
  }, [saveCurrentLink]);

  useEffect(() => {
    scrollToForm();
  }, [showAddButton]);

  const undoUpload = () => {
    setUploadChosen(false);
    setValue('attachmentUrl', '');
    setValue('attachmentName', '');
  };

  // adding / changing upload doesn't dirty fields, and uploadChosen is only set if a user uploads something new
  useEffect(() => {
    if (numberOfDirtyFields > 0 || uploadChosen) {
      setFormIsDirty(true);
    } else {
      setFormIsDirty(false);
    }
  }, [numberOfDirtyFields, uploadChosen]);

  const displayMediaUploader = () => {
    if (isMobileDeviceOrCordova) {
      return (
        <>
          <AddMediaFileMobile
            setUploadChosen={setUploadChosen}
            uploadChosen={uploadChosen}
            fileName="attachmentName"
            urlName="attachmentUrl"
            trigger={trigger}
            setValue={setValue}
            register={register}
            changeUploadState={handleUploading}
            accept="image/png, image/gif, application/msword, image/gif, image/jpeg, audio/mpeg, videocp/mpeg, application/pdf"
            multipleFiles={false}
            validation={{
              validate: (value: string) => {
                let error = '';
                const attachment = getValues('attachmentUrl');
                const notes = getValues('note');

                const checkNote = notes === undefined || notes === '';
                const checkAttachment = attachment === undefined || attachment === '';

                // If note is defined then we do skip check attachment
                if (checkNote) {
                  if (checkAttachment) {
                    error = t('addMeditationForm.errors.linkRequired');
                    return error;
                  }
                }

                const re = new RegExp(urlPattern);
                if (value && !re.test(value)) {
                  error = t('addMeditationForm.errors.urlFormat');
                  return error;
                }
              },
            }}
            interactionCallback={addModifiedFieldInteraction}
          />
          {uploadChosen && (
            <SecondaryButton onClick={undoUpload} mt={5} fontSize={14}>
              {t('addMeditationForm.undoAddUpload')}
            </SecondaryButton>
          )}
        </>
      );
    }

    return (
      <>
        <AddMediaFile
          setUploadChosen={setUploadChosen}
          uploadChosen={uploadChosen}
          fileName="attachmentName"
          urlName="attachmentUrl"
          setValue={setValue}
          displayFile={''}
          trigger={trigger}
          register={register}
          validation={{
            validate: (value: string) => {
              let error = '';
              const attachment = getValues('attachmentUrl');
              const notes = getValues('note');

              const checkNote = notes === undefined || notes === '';
              const checkAttachment = attachment === undefined || attachment === '';

              // If note is defined then we do skip check attachment
              if (checkNote) {
                if (checkAttachment) {
                  error = t('addMeditationForm.errors.linkRequired');
                  return error;
                }
              }

              const re = new RegExp(urlPattern);
              if (value && !re.test(value)) {
                error = t('addMeditationForm.errors.urlFormat');
                return error;
              }
            },
          }}
          interactionCallback={addModifiedFieldInteraction}
        />
        {uploadChosen && (
          <SecondaryButton onClick={undoUpload} fontSize={14}>
            {t('addMeditationForm.undoAddUpload')}
          </SecondaryButton>
        )}
      </>
    );
  };

  return (
    <Box>
      {showAddButton ? (
        <Button onClick={resetButtonStates} mt={25} variant="link" data-test="add-meditation">
          {t('addMeditationForm.addButton')}
        </Button>
      ) : (
        <form ref={formRef} autoComplete="off" onSubmit={handleSubmit(saveMeditation)}>
          <FormControl isInvalid={errors.title !== undefined}>
            {/*title field*/}
            <FormLabel fontSize={14}>{t('addMeditationForm.titleField')}</FormLabel>
            <Input
              w="100%"
              size="md"
              borderColor="#E2E8F0"
              borderRadius={4}
              {...register('title', {
                required: {
                  value: true,
                  message: t('addMeditationForm.errors.titleRequired'),
                },
              })}
              mb={errors.title ? 0 : 4}
              onFocus={addModifiedFieldInteraction}
              data-test="meditation-title"
            />
            <FormErrorMessage mb={4}>{errors.title && errors.title.message}</FormErrorMessage>
          </FormControl>

          {/*length field*/}
          <FormLabel fontSize={14}>{t('addMeditationForm.lengthField')}</FormLabel>
          <Stack shouldWrapChildren={true}>
            <NumberInput
              onChange={onChangeMeditationLength}
              pattern="^(\d+)\sMinute(s?)$"
              value={format(value)}
              size="md"
              mb={0}
              min={0}
            >
              <NumberInputField
                borderColor="#E2E8F0"
                borderRadius={4}
                {...register('length')}
                onFocus={addModifiedFieldInteraction}
                data-test="meditation-length"
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Stack>
          <Text mt={1} mb={4} fontStyle={'italic'} color={'#000000'} fontSize={13}>
            {meditationLengthInHoursMinutes}
          </Text>

          {/*note field*/}
          <FormControl isInvalid={errors.note !== undefined}>
            <FormLabel fontSize={14}>{t('addMeditationForm.noteField.meditationNote')}</FormLabel>
            <Text fontStyle={'italic'} color={'#000000'} fontSize={13}>
              {t('addMeditationForm.noteField.noteInstruction')}
            </Text>
            <Textarea
              fontSize={14}
              size="lg"
              borderColor="black"
              borderRadius={2}
              mb={errors.note ? 0 : 4}
              {...register('note', {
                validate: (): string | undefined => {
                  let error = '';
                  const attachment = getValues('attachmentUrl');
                  const notes = getValues('note');

                  const checkNote = notes === undefined || notes === '';
                  const checkAttachment = attachment === undefined || attachment === '';

                  // If attachment is defined then we can skip check attachment
                  if (checkAttachment) {
                    if (checkNote) {
                      error = t('addMeditationForm.errors.notesRequired');
                      return error;
                    }
                  }
                },
              })}
              onFocus={addModifiedFieldInteraction}
              data-test="notes"
            />
            <FormErrorMessage mb={4}>{errors.note && errors.note.message}</FormErrorMessage>
          </FormControl>

          {/*media content field*/}

          <>
            <FormLabel fontSize={14} fontWeight="bold">
              {t('addMeditationForm.mediaField')}
            </FormLabel>
            <FormControl isInvalid={errors.link !== undefined}>
              {saveCurrentLink && currentLink ? (
                <>
                  <FormLabel fontSize={14}>{t('addMeditationForm.currentLinkField')}</FormLabel>
                  <Text fontSize={14}>{currentLink}</Text>
                  <SecondaryButton onClick={() => setSaveCurrentLink(false)} fontSize={14} mb={2} mt={2}>
                    {t('addMeditationForm.removeLinkField')}
                  </SecondaryButton>
                </>
              ) : (
                <Box flexDirection="column">
                  <AddWebLink
                    name="link"
                    register={register}
                    validation={{
                      validate: (value: string) => {
                        let error = '';
                        const attachment = getValues('attachmentUrl');
                        const notes = getValues('note');

                        const checkNote = notes === undefined || notes === '';
                        const checkAttachment = attachment === undefined || attachment === '';

                        // If note is defined then we do skip check attachment
                        if (checkNote) {
                          if (checkAttachment) {
                            error = t('addMeditationForm.errors.linkRequired');
                            return error;
                          }
                        }

                        const re = new RegExp(urlPattern);
                        if (value && !re.test(value)) {
                          error = t('addMeditationForm.errors.urlFormat');
                          return error;
                        }
                      },
                    }}
                    interactionCallback={addModifiedFieldInteraction}
                  />
                  {/* if the user hasn't chosen to link something but something has been saved as current link, that means they've chosen to remove link -- give them the chance to undo this */}
                  {currentLink && !saveCurrentLink && (
                    <SecondaryButton mb={2} onClick={() => setSaveCurrentLink(true)} fontSize={14}>
                      {t('addMeditationForm.undoRemoveLinkField')}
                    </SecondaryButton>
                  )}
                </Box>
              )}

              {saveCurrentUpload && currentUpload ? (
                <>
                  <FormLabel fontSize={14}>{t('addMeditationForm.currentUploadField')}</FormLabel>
                  {selectedMeditation?.attachmentName &&
                  getAttachmentType(selectedMeditation.attachmentName) === 'image' ? (
                    <Image src={currentAttachmentUrl} alt={currentUpload} boxSize="100px" objectFit="cover" />
                  ) : (
                    <Text fontSize={14}>{currentUpload}</Text>
                  )}
                  <SecondaryButton onClick={() => setSaveCurrentUpload(false)} mt={2} fontSize={14}>
                    {t('addMeditationForm.removeUploadField')}
                  </SecondaryButton>
                </>
              ) : (
                <Box flexDirection="column">
                  {displayMediaUploader()}
                  {/* if the user hasn't chosen to upload something but something has been saved as current upload, that means they've chosen to remove upload -- give them the chance to undo this */}
                  {currentUpload && !uploadChosen && (
                    <SecondaryButton
                      mt={2}
                      style={{ display: 'block' }}
                      onClick={() => setSaveCurrentUpload(true)}
                      fontSize={14}
                    >
                      {t('addMeditationForm.undoRemoveUploadField')}
                    </SecondaryButton>
                  )}
                  <FormErrorMessage>
                    {errors.link?.message === t('addMeditationForm.errors.urlFormat') && errors.link.message}
                    {!!errors.link && !!errors.attachmentUrl && errors.attachmentUrl.message}
                  </FormErrorMessage>{' '}
                </Box>
              )}
            </FormControl>
          </>
          <Center>{displaySaveButton()}</Center>
        </form>
      )}
    </Box>
  );
};

const mapDispatchToProps = {
  createInteraction,
};

function mapStateToProps(state: RootState) {
  const { selectedLinkedUser } = state.linkedUsers;

  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }

  return {
    pir: selectedLinkedUser.pir,
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddMeditationForm);
