import React, { useState, useContext } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../redux/store';
import { getToDos, updateToDo } from '../../modules/todo/actions';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Text,
  Box,
  Button,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import AddAppointmentForm from './AddAppointmentForm';
import ILinkedUser, { PossibleLinkedUser } from '../../modules/linked-users/interfaces/ILinkedUser';
import IAppointment, { AppointmentStatus } from 'src/modules/appointments/interfaces/IAppointment';
import { UserContext } from 'src/modules/interior/Index';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import { useUpdateAppointment } from 'src/modules/appointments/queryHooks';
import {
  defaultTimeZone,
  formatTimeStringFromDateTimeAndTz,
  getAppointmentDateStringWithDay,
  getAppointmentTimeStringRange,
  getAppointmentTimeStringWithDuration,
  getOverlappingAppointments,
} from './utils';
import OnUpdateAppointmentModal from './OnUpdateAppintmentModal';
import ConfirmCancelModal from './ConfirmCancelModal';
import { UserRole } from 'src/modules/user/interfaces/IUser';
import linkifyHtml from 'linkify-html';
import { CreatedInteractionAction } from 'src/modules/interactions/actions';
import { interactionType, moduleName } from 'src/modules/interactions/constants';
import IInteraction from 'src/modules/interactions/interfaces/IInteraction';
import ConfirmOverlappingAppointmentsModal from './ConfirmOverlappingAppointmentsModal';
import { useModal } from 'src/ModalContext';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
  isPir: boolean;
  isTodo: boolean;
  selectedAppointment: IAppointment | null;
  setSelectedAppointment: React.Dispatch<React.SetStateAction<IAppointment | null>>;
  selectedLinkedUser: PossibleLinkedUser;
  providers: ILinkedUser[];
  createInteraction: (rawInteraction: IInteraction) => Promise<CreatedInteractionAction>;
  existingAppointments: IAppointment[];
}

type Props = SuppliedProps;

const AppointmentModal = ({
  todos,
  isTodo,
  updateToDo,
  isOpen,
  onClose,
  isPir,
  setSelectedAppointment,
  selectedAppointment,
  selectedLinkedUser,
  providers,
  createInteraction,
  existingAppointments,
}: Props & PropsFromRedux): React.ReactElement => {
  const { t } = useTranslation('appointments');
  const { isPhone } = useDevice();
  const user = useContext(UserContext);
  const userId = user?.id ?? '';
  const editAppointmentDisclosure = useDisclosure();
  const updateMessageDisclosure = useDisclosure();
  const confirmCancelDisclosure = useDisclosure();
  const { mutate: updateAppointment } = useUpdateAppointment(userId);
  const [cancelType, setCancelType] = useState<AppointmentStatus | undefined>(undefined);
  const [onUpdateMessage, setOnUpdateMessage] = useState('');
  const selectedTodo = useSelector((state: RootState) => state.todo.selectedToDo);

  const options = {
    defaultProtocol: 'https',
    attributes: {
      class: 'link',
      target: '_blank',
    },
  };
  const locationWithClickableLinks = selectedAppointment?.location
    ? linkifyHtml(selectedAppointment.location.replace(/\n/g, '<br>'), options)
    : undefined;
  const clickablePirNotes = selectedAppointment?.notesFromPir
    ? linkifyHtml(selectedAppointment.notesFromPir.replace(/\n/g, '<br>'), options)
    : undefined;
  const clickableProviderNotes = selectedAppointment?.notesFromProvider
    ? linkifyHtml(selectedAppointment.notesFromProvider.replace(/\n/g, '<br>'), options)
    : undefined;
  const userTimezone = user?.timezone ?? defaultTimeZone;
  const confirmOverlappingAppointmentDisclosure = useDisclosure();
  const [overlappingAppointments, setOverlappingAppointments] = useState<IAppointment[]>([]);
  const { closeModal } = useModal();

  const needsConfirm =
    (!selectedAppointment?.approvedByPir && isPir) || (!selectedAppointment?.approvedByProvider && !isPir);

  const checkForDuplicateAppointments = () => {
    const potentialAppointmentStart = selectedAppointment?.appointmentTime.timeStart as Date;
    const potentialAppointmentEnd = selectedAppointment?.appointmentTime.timeEnd as Date;
    const overlappingAppointments = getOverlappingAppointments(
      existingAppointments,
      potentialAppointmentStart,
      potentialAppointmentEnd,
    );
    if (overlappingAppointments.length) {
      setOverlappingAppointments(overlappingAppointments);
      confirmOverlappingAppointmentDisclosure.onOpen();
    } else {
      onConfirmAppointment();
    }
  };

  const onConfirmAppointment = async () => {
    const id = selectedAppointment?.id as string;
    const appointmentUpdates: Partial<IAppointment> = {};
    if (isPir) appointmentUpdates.approvedByPir = true;
    else appointmentUpdates.approvedByProvider = true;
    appointmentUpdates.appointmentStatus = AppointmentStatus.CONFIRMED;
    appointmentUpdates.lastChangeMadeBy = isPir ? UserRole.USER : UserRole.CP;
    await updateAppointment({ id, appointmentUpdates });
    const howChanged = t('appointmentUpdateModal.confirmed');
    setOnUpdateMessage(
      t('appointmentUpdateModal.appointmentChangedStatus', {
        otherUserName: selectedAppointment?.otherUserAlias ?? selectedAppointment?.otherUserName,
        dateString: getAppointmentDateStringWithDay(selectedAppointment as IAppointment, userTimezone),
        timeString: getAppointmentTimeStringRange(selectedAppointment as IAppointment),
        howChanged,
      }),
    );
    updateMessageDisclosure.onOpen();
    closeModal();
    addInteraction(interactionType.APPOINTMENTS.CONFIRMED);
  };

  const confirmCancel = (type: AppointmentStatus) => {
    setCancelType(type);
    confirmCancelDisclosure.onOpen();
  };

  const addInteraction = (type: string) => {
    const userRef =
      isPir && selectedAppointment?.pir
        ? selectedAppointment?.pir
        : selectedAppointment?.providerUser
        ? selectedAppointment?.providerUser
        : null;
    createInteraction({
      pir: userRef,
      dateTime: new Date(),
      moduleName: moduleName.APPOINTMENTS,
      interactionType: type,
    });
  };

  const cancelAppointment = async () => {
    const id = selectedAppointment?.id as string;
    const appointmentUpdates: Partial<IAppointment> = {};
    if (isPir) appointmentUpdates.approvedByPir = false;
    else appointmentUpdates.approvedByProvider = false;
    appointmentUpdates.appointmentStatus = cancelType;
    appointmentUpdates.lastChangeMadeBy = isPir ? UserRole.USER : UserRole.CP;
    await updateAppointment({ id, appointmentUpdates });
    const howChanged =
      cancelType === AppointmentStatus.CANCELED
        ? t('appointmentUpdateModal.canceled')
        : t('appointmentUpdateModal.declined');
    setOnUpdateMessage(
      t('appointmentUpdateModal.appointmentChangedStatus', {
        otherUserName: selectedAppointment?.otherUserAlias ?? selectedAppointment?.otherUserName,
        dateString: getAppointmentDateStringWithDay(selectedAppointment as IAppointment, userTimezone),
        timeString: getAppointmentTimeStringRange(selectedAppointment as IAppointment),
        howChanged,
      }),
    );
    confirmCancelDisclosure.onClose();
    updateMessageDisclosure.onOpen();
    closeModal();
    const type =
      cancelType === AppointmentStatus.CANCELED
        ? interactionType.APPOINTMENTS.CANCELED
        : interactionType.APPOINTMENTS.DECLINED;
    addInteraction(type);
  };

  const triggerTodo = () => {
    if (todos && selectedTodo && !selectedTodo.isCompleted) {
      selectedTodo.completedDateTime = new Date();
      selectedTodo.isCompleted = true;
      updateToDo(selectedTodo, todos);
      closeModal();
    }
  };

  return (
    <Box>
      <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          maxWidth={isPhone ? ['90%', '90%', '90%', '90%', '90%'] : '500px'}
          p={[2, 2, 4, 8]}
          zIndex={1900}
          borderRadius="10px"
        >
          <ModalHeader textAlign={'center'} color="#544170" fontWeight="bold" fontSize={16} lineHeight={'28px'} mt={2}>
            {needsConfirm
              ? t('appointments:appointmentModule.confirmAppointmentWith')
              : t('appointments:appointmentModule.appointmentWith')}{' '}
            {selectedAppointment?.otherUserAlias
              ? `${selectedAppointment?.otherUserAlias} (${selectedAppointment?.otherUserName})`
              : selectedAppointment?.otherUserName}
          </ModalHeader>
          <ModalCloseButton />
          {selectedAppointment && (
            <ModalBody w="100%">
              <Flex w="100%" direction="column" justifyContent="space-between">
                {/* Appointment Type */}
                {selectedAppointment.type && (
                  <>
                    <Text color="#544170" fontWeight="medium" fontSize={16} mb={3} lineHeight={'28px'}>
                      {t('appointments:appointmentModule.appointmentType')}
                    </Text>

                    <Text color="#000000" fontWeight="regular" fontSize={16} mb={5} lineHeight={'22px'}>
                      {selectedAppointment.type}
                    </Text>
                  </>
                )}

                {/* Appointment Date and time */}
                <Text color="#544170" fontWeight="medium" fontSize={16} mb={3} lineHeight={'28px'}>
                  {t('appointments:appointmentModule.appointmentDateAndTime')}
                </Text>
                <Text color="#000000" fontWeight="regular" fontSize={16} mb={2} lineHeight={'22px'}>
                  {getAppointmentDateStringWithDay(selectedAppointment, userTimezone)}
                </Text>
                <Text color="#000000" fontWeight="regular" fontSize={16} lineHeight={'22px'}>
                  {getAppointmentTimeStringWithDuration(
                    selectedAppointment.appointmentTime.timeStartString ??
                      formatTimeStringFromDateTimeAndTz(
                        selectedAppointment.appointmentDate,
                        selectedAppointment.appointmentTime.timeStart,
                        user?.timezone,
                      ),
                    selectedAppointment.appointmentTime.timeEndString ??
                      formatTimeStringFromDateTimeAndTz(
                        selectedAppointment.appointmentDate,
                        selectedAppointment.appointmentTime.timeEnd,
                        user?.timezone,
                      ),
                    selectedAppointment.duration as number,
                    user?.timezone,
                  )}
                </Text>

                {/* Appointment location */}
                {(selectedAppointment.location || selectedAppointment.locationType) && (
                  <>
                    <Text color="#544170" fontWeight="medium" fontSize={16} mb={3} mt={5} lineHeight={'28px'}>
                      {t('appointments:appointmentModule.appointmentLocation')}
                    </Text>
                    {selectedAppointment.locationType && (
                      <Text
                        color="#000000"
                        fontWeight="regular"
                        fontSize={16}
                        lineHeight={'22px'}
                        mb={selectedAppointment.location ? '2' : '0'}
                      >
                        {selectedAppointment.locationType}
                      </Text>
                    )}
                    {locationWithClickableLinks && (
                      <Text
                        color="#000000"
                        fontWeight="regular"
                        fontSize={16}
                        lineHeight={'22px'}
                        dangerouslySetInnerHTML={{ __html: locationWithClickableLinks }}
                      />
                    )}
                  </>
                )}

                {/* Pir notes */}
                {clickablePirNotes && (
                  <>
                    <Text color="#544170" fontWeight="medium" fontSize={16} mb={3} mt={5} lineHeight={'28px'}>
                      {isPir
                        ? t('appointments:appointmentModule.yourNotes')
                        : t('appointments:appointmentModule.otherNotes', {
                            otherUser: selectedAppointment.otherUserAlias
                              ? selectedAppointment.otherUserAlias
                              : selectedAppointment.otherUserName
                              ? selectedAppointment.otherUserName
                              : 'your patient',
                          })}
                    </Text>
                    <Text
                      color="#000000"
                      fontWeight="regular"
                      fontSize={16}
                      lineHeight={'22px'}
                      dangerouslySetInnerHTML={{ __html: clickablePirNotes }}
                    />
                  </>
                )}

                {/* Provider notes */}
                {clickableProviderNotes && (
                  <>
                    <Text color="#544170" fontWeight="medium" fontSize={16} mb={3} mt={5} lineHeight={'28px'}>
                      {isPir
                        ? t('appointments:appointmentModule.otherNotes', {
                            otherUser: selectedAppointment.otherUserAlias ?? selectedAppointment.otherUserName,
                          })
                        : t('appointments:appointmentModule.yourNotes')}
                    </Text>
                    <Text
                      color="#000000"
                      fontWeight="regular"
                      fontSize={16}
                      lineHeight={'22px'}
                      dangerouslySetInnerHTML={{ __html: clickableProviderNotes }}
                    />
                  </>
                )}
              </Flex>
              {needsConfirm ? (
                <Flex flexDirection="column" alignItems="center">
                  <PrimaryButton
                    onClick={() => checkForDuplicateAppointments()}
                    mt={10}
                    data-test="confirm-appointment"
                    mb={5}
                  >
                    {t('appointments:appointmentModal.confirm')}
                  </PrimaryButton>
                  <SecondaryButton
                    mb={5}
                    onClick={() => editAppointmentDisclosure.onOpen()}
                    data-test="modtify-appointment"
                  >
                    {t('appointments:appointmentModal.modify')}
                  </SecondaryButton>
                  <Button
                    mb={5}
                    variant="link"
                    color="#583F73"
                    fontWeight="semibold"
                    textDecoration="underline"
                    onClick={() => confirmCancel(AppointmentStatus.DECLINED)}
                    data-test="decline-appointment"
                  >
                    {t('appointments:appointmentModal.decline')}
                  </Button>
                </Flex>
              ) : selectedAppointment?.appointmentDate >= new Date() ? (
                <Flex flexDirection="column" alignItems="center">
                  <Button
                    onClick={() => editAppointmentDisclosure.onOpen()}
                    variant="link"
                    color="#583F73"
                    fontWeight="bold"
                    mt={10}
                    mb={6}
                    textDecoration="underline"
                    data-test="edit-appointment"
                  >
                    {t('appointments:appointmentModule.editAppointmentLink')}
                  </Button>
                  <Button
                    onClick={() => confirmCancel(AppointmentStatus.CANCELED)}
                    variant="link"
                    color="#583F73"
                    fontWeight="bold"
                    mb={6}
                    textDecoration="underline"
                    data-test="cancel-appointment"
                  >
                    {t('appointments:appointmentModule.cancelAppointmentLink')}
                  </Button>
                </Flex>
              ) : isTodo ? (
                <Flex flexDirection="column" alignItems="center">
                  <PrimaryButton onClick={triggerTodo} isDisabled={selectedTodo?.isCompleted}>
                    {t('appointments:appointmentModule.attendedAppointmentButton')}
                  </PrimaryButton>
                </Flex>
              ) : null}
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
      <AddAppointmentForm
        isOpen={editAppointmentDisclosure.isOpen}
        onClose={editAppointmentDisclosure.onClose}
        selectedAppointment={selectedAppointment}
        setSelectedAppointment={setSelectedAppointment}
        providers={providers}
        isPir={isPir}
        existingAppointments={existingAppointments}
      />
      <OnUpdateAppointmentModal
        isOpen={updateMessageDisclosure.isOpen}
        onClose={() => {
          updateMessageDisclosure.onClose();
          setSelectedAppointment(null);
        }}
        onUpdateMessage={onUpdateMessage}
      />
      <ConfirmCancelModal
        isOpen={confirmCancelDisclosure.isOpen}
        onClose={() => {
          confirmCancelDisclosure.onClose();
        }}
        selectedAppointment={selectedAppointment}
        selectedLinkedUser={selectedLinkedUser}
        cancelType={cancelType}
        cancelAppointment={cancelAppointment}
      />
      <ConfirmOverlappingAppointmentsModal
        isOpen={confirmOverlappingAppointmentDisclosure.isOpen}
        onClose={() => {
          confirmOverlappingAppointmentDisclosure.onClose();
        }}
        overlappingAppointments={overlappingAppointments}
        onConfirmAppointment={onConfirmAppointment}
        userTimezone={userTimezone}
      />
    </Box>
  );
};

function mapStateToProps(state: RootState) {
  const { todos } = state.todo;

  return {
    todos,
  };
}

const mapDispatchToProps = {
  getToDos,
  updateToDo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AppointmentModal);
