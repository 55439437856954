import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Box, Text, Flex, Spinner } from '@chakra-ui/react';

interface Props {
  onDrop(acceptedFiles: File[]): void;
  accept: string | string[];
  displaySpinner: boolean;
}

const Dropzone = ({ onDrop, accept, displaySpinner }: Props): React.ReactElement => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });
  const { t } = useTranslation('common');
  const getClassName = (className: string, isActive: boolean): string => {
    if (!isActive) return className;
    return `${className} ${className}-active`;
  };

  return (
    <Box {...getRootProps()} h="120px" border={4} borderStyle="dotted" borderRadius="md" borderColor="#E5E5E5">
      <Flex flexDirection="column" alignItems="center">
        <Text mt={10} mb={10} fontSize={18}>
          {!isDragActive ? t('addMediaFile.dragAndDrop') : t('dropzone.dropZoneRelease')}
        </Text>
        <input className={getClassName('dropzone-input', isDragActive)} {...getInputProps()} />
        {displaySpinner && <Spinner size="xs" speed="0.65s" />}
      </Flex>
    </Box>
  );
};

export default Dropzone;
