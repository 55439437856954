import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export const SecondaryButton = (props: ButtonProps): JSX.Element => (
  <Button
    color="purple3.600"
    fontWeight="500"
    bg="white"
    // variant="outline"
    // colorScheme="purple3.600"
    border="1px"
    borderColor="purple3.600"
    _hover={{
      backgroundColor: 'purple3.50',
      _disabled: {
        bgColor: 'purple3.600',
      },
    }}
    _active={{
      backgroundColor: 'purple3.200',
    }}
    {...props}
  >
    {props.children}
  </Button>
);

export default SecondaryButton;
