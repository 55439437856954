import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import PageHeading from '../../components/PageHeading';
import SecondaryBodyText from '../../components/SecondaryBodyText';
import InviteList from '../../components/InviteList/InviteList';
import NarrowInterior from '../../components/NarrowInterior';
import InviteNewUser from '../invitations/InviteNewUser';
import { createLinkedUserForInvitee } from '../linked-users/utils';
import { createLinkedUser } from '../linked-users/actions';

const ProviderWithout = (props: PropsFromRedux): JSX.Element => {
  const { t } = useTranslation('home');

  const isAlreadyPir = props.pir !== null;

  const handleInvitationSent = async (email: string, pirAlias: string) => {
    await createLinkedUserForInvitee(props.createLinkedUser, props.user, email, pirAlias);
  };

  return (
    <NarrowInterior>
      <PageHeading my={8}>{t('providerWithout.heading')}</PageHeading>
      <>
        {props.invites.length > 0 && <InviteList mb={isAlreadyPir ? 8 : 4} />}

        {props.invites.length === 0 && (
          <SecondaryBodyText mb={isAlreadyPir ? 8 : 4}>{t('providerWithout.noInvitesBody')}</SecondaryBodyText>
        )}

        <Flex width="100%" justifyContent="right" alignItems="right" height={50}>
          <InviteNewUser
            inviterName={props.user.name ?? ''}
            inviterId={props.user.id}
            onInvitationSent={handleInvitationSent}
            role="cp"
          />
        </Flex>
      </>
    </NarrowInterior>
  );
};

const mapStateToProps = (state: RootState) => {
  const user = state.user.user;

  if (!user) {
    throw new Error('No user logged in when there needs to be');
  }

  return {
    user,
    pir: state.linkedUsers.pir,
    invites: state.linkedUsers.invites ?? [],
  };
};

const mapDispatchToProps = {
  createLinkedUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProviderWithout);
