import { SurveyActions, SurveyActionTypes } from './actions';
import ISurvey from './interfaces/ISurvey';

interface SurveyState {
  loading: boolean;
  creating: boolean;
  created: boolean;
  survey: null | ISurvey;
  error: string | null;
}

const defaultState: SurveyState = {
  loading: false,
  creating: false,
  created: false,
  survey: null,
  error: null,
};

const surveyReducer = (state: SurveyState = defaultState, action: SurveyActions): SurveyState => {
  switch (action.type) {
    case SurveyActionTypes.SUBMIT_SURVEY:
      return {
        ...state,
        survey: action.survey,
      };

    case SurveyActionTypes.CREATING_SURVEY:
      return {
        ...state,
        creating: true,
      };

    case SurveyActionTypes.CREATED_SURVEY:
      return {
        ...state,
        creating: false,
        created: true,
      };
    case SurveyActionTypes.CREATING_SURVEY_ERROR:
      return {
        ...state,
        created: false,
        error: action.survey,
      };

    default:
      return state;
  }
};

export default surveyReducer;
