import React, { useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import VitalsModuleCP from './VitalsModuleCP';
import { Box, Flex, Center } from '@chakra-ui/react';
import { IContextUser } from '../user/interfaces/IUser';
import { useTranslation } from 'react-i18next';
import { UserContext, SelectedUserContext } from '../interior/Index';
import { RootState } from '../../redux/store';

const Index = (props: PropsFromRedux): JSX.Element => {
  const { t } = useTranslation('vitals');
  const { selectedUser } = useContext(SelectedUserContext);
  const user = useContext<IContextUser | null>(UserContext);
  const pir: IContextUser = (selectedUser as IContextUser) ?? user;
  const uid = selectedUser?.id || (user ? user.id : null);
  const { pirAlias, preferredName } = props;

  const name = pirAlias || preferredName || pir?.name || pir?.email;

  return (
    <Flex h="100%" direction="column" px={['18px', '36px']} pt="max(0px, env(safe-area-inset-top))" overflow="auto">
      <Box as="h1" fontSize={20} display={selectedUser ? 'block' : 'none'} mb="16px" color="black" fontWeight="bold">
        {t('vitals.heading', {
          name,
        })}
      </Box>
      <Center h="100%" overflow="auto">
        <VitalsModuleCP uid={uid} />
      </Center>
    </Flex>
  );
};

const MapStateToProps = (state: RootState) => {
  const selectedLinkedUser = state.linkedUsers.selectedLinkedUser;

  return {
    selectedLinkedUser,
    otherLinkedUser: state.linkedUsers.otherLinkedUsers ?? [],
    preferredName: selectedLinkedUser && 'preferredName' in selectedLinkedUser && selectedLinkedUser.preferredName,
    pirAlias: selectedLinkedUser && 'pirAlias' in selectedLinkedUser ? selectedLinkedUser.pirAlias : undefined,
  };
};

const connector = connect(MapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Index);
