import { ConnectType } from './DevicePage';
import { Box, Link, Text } from '@chakra-ui/react';
import {
  BluetoothAuthState,
  BluetoothStatus,
  checkBluetoothAuthStatus,
  goToAppSettings,
  isThisAndroid,
  requestBluetoothAuth,
  setBluetoothStatus,
} from 'src/lib/wearable';
import { TFunction } from 'react-i18next';

export const setGlobalBluetoothToTrue = async () => {
  try {
    await setBluetoothStatus(true);
  } catch (err) {
    if (isThisAndroid()) {
      const bluetoothAuthWasNeeded = await bluetoothAuthCheckNeeded();
      if (bluetoothAuthWasNeeded) {
        await setBluetoothStatus(true);
      } else {
        throw err;
      }
    }
  }
};

export const bluetoothAuthCheckNeeded = async (): Promise<boolean> => {
  try {
    const bluetoothAuth = await checkBluetoothAuthStatus();
    if (bluetoothAuth !== BluetoothAuthState.GRANTED) {
      await requestBluetoothAuth();
      return true;
    }
    return false;
  } catch (err) {
    console.log(`Error checking and requesting bluetooth auth: ${err}`);

    throw err;
  }
};

export const stepsToConnect = (
  connectType: ConnectType,
  bluetoothStatus: BluetoothStatus | undefined,
  t: TFunction<'device'>,
  isOnboarding: boolean,
) => {
  const isAndroid = isThisAndroid();

  if (!isAndroid && isOnboarding && bluetoothStatus === BluetoothStatus.UNAUTHORIZED) {
    return (
      <Box fontSize={14} pl={{ base: 4, md: 8 }} listStylePosition="inside">
        <Text>{t('deviceModal.')}</Text>
      </Box>
    );
  }

  return (
    <Box as="ol" fontSize={14} pl={{ base: 4, md: 8 }} listStylePosition="inside">
      {isAndroid && bluetoothStatus !== BluetoothStatus.BLUETOOTH_ON ? (
        <li>
          {t('deviceModal.steps.androidBluetoothIsNotOn')}{' '}
          <Link
            onClick={setGlobalBluetoothToTrue}
            textDecoration="underline"
            _hover={{
              textDecoration: 'none',
            }}
          >
            {t('deviceModal.steps.androidTurnBluetoothOnButton')}
          </Link>
        </li>
      ) : !isAndroid && bluetoothStatus === BluetoothStatus.BLUETOOTH_NOT_ON ? (
        <li>{t('deviceModal.steps.iosBluetoothNotOn')}</li>
      ) : (
        <li>{t('deviceModal.steps.globalBluetooth')}</li>
      )}
      {!isAndroid && !isOnboarding ? (
        <li>
          {bluetoothStatus === BluetoothStatus.UNAUTHORIZED
            ? t('deviceModal.steps.bluetoothAuthOnboardedError')
            : t('deviceModal.steps.bluetoothAuthOnboarded')}{' '}
          <Link
            onClick={goToAppSettings}
            textDecoration="underline"
            _hover={{
              textDecoration: 'none',
            }}
          >
            {t('deviceModal.steps.appSettingsLink')}
          </Link>
        </li>
      ) : !isAndroid ? (
        <li>{t('deviceModal.steps.bluetoothAuthOnboarding')}</li>
      ) : null}
      <li>
        {t('deviceModal.steps.garminConnect')} Garmin Connect<sup>™</sup> app.
      </li>
      {connectType === ConnectType.SYNC ? (
        <li>{t('deviceModal.steps.syncStep')} </li>
      ) : (
        <li>{t('deviceModal.steps.pairStep')} </li>
      )}
      {connectType === ConnectType.PAIR && <li>{t('deviceModal.steps.turnOnDevicePairing')}</li>}
    </Box>
  );
};
