import { History } from 'history';

const DYNAMIC_LINKS_DOMAIN_PATH = process.env.REACT_APP_DYNAMIC_LINKS_DOMAIN_PATH ?? '';

export const handleDynamicLink = (linkUrl: string, history: History): void => {
  const url = new URL(linkUrl);
  if (url.pathname.startsWith(`${DYNAMIC_LINKS_DOMAIN_PATH}/signup-invitation`)) {
    history.push({ ...location, search: `?sign-up=true&${url.search.slice(1)}` });
  }
  if (url.pathname.startsWith(`${DYNAMIC_LINKS_DOMAIN_PATH}/verify-email`)) {
    history.push({ ...location, search: `?verify=true&${url.search.slice(1)}` });
  }
  if (url.pathname.endsWith(`${process.env.REACT_APP_RESET_PASSWORD_DYNAMIC_LINK}`)) {
    history.push({ search: `?straight-to-login=true` });
  } else {
    // eslint-disable-next-line no-console
    console.log('invalid dynamic link');
  }
};
