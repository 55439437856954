import {
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
} from 'victory';
import { formatDayTicks, IGraphPoint } from '../dataProcessing';
import { metricColor } from '../../../lib/colorThresholds';
import React from 'react';
import { GraphSizeObj } from '../interfaces/IVitalsGraph';
import { ReducableGraphType } from './VitalsGraphs';
import dayjs from 'dayjs';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  axisLabelPadding: number;
  data: IGraphPoint[];
  dataWithBoundaries: IGraphPoint[];
  fillElementId: string;
  graphPadding: number;
  graphSize: GraphSizeObj;
  graphType: ReducableGraphType;
  levelAxisProps: {
    tickValues: number[];
    tickFormat: (tick: number) => number | string;
  };
  levelTickValues: number[];
  numberOfDaysDisplayed: number;
  timeTickValues: Date[];
  isolatedPoints: IGraphPoint[];
  day: number;
}

const victoryAxisLabels = new Map<string, string>([
  ['sleep', 'HOURS'],
  ['watchuse', 'HOURS'],
  ['incidents', 'INCIDENTS'],
  ['participation', '% PARTICIPATION'],
  ['emotions', 'LEVEL'],
  ['stress', 'LEVEL'],
]);

const VitalsScatterGraph = ({
  axisLabelPadding,
  data,
  dataWithBoundaries,
  fillElementId,
  graphPadding,
  graphSize,
  graphType,
  levelAxisProps,
  levelTickValues,
  numberOfDaysDisplayed,
  timeTickValues,
  isolatedPoints,
  day,
}: SuppliedProps): React.ReactElement => {
  const victoryAxisLabel = victoryAxisLabels.get(graphType);
  const { isPhone } = useDevice();

  const nowDays = dayjs().startOf('day');
  const nowHours = dayjs().startOf('hour');
  const xStartDays = nowDays.subtract(day, 'day');
  const xStartHours = nowHours.subtract(day, 'day');
  const leftPadding = isPhone ? 18 : 50;

  return (
    <VictoryChart
      theme={VictoryTheme.grayscale}
      width={graphSize.width}
      height={graphSize.height}
      padding={{ top: 5, left: leftPadding, bottom: 60, right: graphPadding }}
      domainPadding={{ y: 10 }}
      minDomain={{ y: levelTickValues ? levelTickValues[0] : 0 }}
      domain={{
        x: day === 1 ? [xStartHours.valueOf(), nowHours.valueOf()] : [xStartDays.valueOf(), nowDays.valueOf()],
      }}
      containerComponent={
        <VictoryContainer
          style={
            !isPhone
              ? { backgroundColor: 'white' }
              : {
                  pointerEvents: 'auto',
                  userSelect: 'auto',
                  touchAction: 'auto',
                  backgroundColor: 'white',
                }
          }
        />
      }
    >
      <VictoryAxis
        label={victoryAxisLabel}
        dependentAxis={true}
        orientation="right"
        // offsetX={isPhone ? graphSize.width - 55 : graphSize.width - 90}
        style={{
          axis: { stroke: '#756f6a' },
          axisLabel: {
            fontSize: 15,
            fontFamily: 'Public Sans',
            angle: 90,
            padding: axisLabelPadding,
          },
          tickLabels: {
            fontSize: 15,
            fontFamily: 'Public Sans',
            padding: 5,
          },
          grid: {
            stroke: 'grey',
            strokeWidth: 1,
            strokeDasharray: '4, 4',
          },
        }}
        {...levelAxisProps}
      />
      <VictoryAxis
        label="TIME"
        style={{
          axis: { stroke: '#756f6a' },
          axisLabel: { fontFamily: 'Public Sans', fontSize: 15 },
          tickLabels: {
            fontSize: 15,
            fontFamily: 'Public Sans',
            padding: 5,
          },
          ticks: {
            stroke: '#756f6a',
            size: 5,
            strokeWidth: 1,
          },
        }}
        tickValues={timeTickValues}
        tickFormat={(t) => formatDayTicks(t, numberOfDaysDisplayed)}
      />
      <VictoryArea
        interpolation="catmullRom"
        style={{
          data: {
            fill: 'url(#' + fillElementId + ')',
            fillOpacity: 0.5,
            stroke: 'none',
          },
        }}
        data={dataWithBoundaries}
      />
      <VictoryLine
        interpolation="catmullRom"
        style={{
          data: {
            stroke: '#000000',
            strokeWidth: isPhone ? 1 : 2,
          },
        }}
        data={dataWithBoundaries}
      />
      <VictoryBar
        data={isolatedPoints}
        style={{
          data: {
            fill: 'url(#' + fillElementId + ')',
            fillOpacity: 0.5,
            stroke: 'none',
            width: isPhone ? 12 : 35,
          },
        }}
        y0={0}
      />
      <VictoryScatter
        data={data}
        size={isPhone ? 3.5 : 5}
        style={{
          data: {
            fill: 'white',
            stroke: ({ datum }) => metricColor(graphType ? graphType : 'unknown', datum.y),
            strokeWidth: isPhone ? 1.5 : 3,
          },
        }}
        labelComponent={
          !isPhone ? (
            <VictoryTooltip
              cornerRadius={0}
              flyoutStyle={{ fill: '#583f73', strokeWidth: 0 }}
              pointerLength={4}
              pointerWidth={4}
              style={{ fontSize: 25, fill: 'white' }}
            />
          ) : (
            <React.Fragment />
          )
        }
        labels={({ datum }) =>
          numberOfDaysDisplayed === 1 || numberOfDaysDisplayed === 3
            ? `${datum.x.getMonth() + 1}/${datum.x.getDate()} ${datum.x.getHours()}:00: ${
                Math.round(datum.y * 100) / 100
              }`
            : `${datum.x.getMonth() + 1}/${datum.x.getDate()}: ${Math.round(datum.y * 100) / 100}`
        }
      />
    </VictoryChart>
  );
};

export default VitalsScatterGraph;
