import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  Flex,
  chakra,
  Divider,
  ModalBody,
} from '@chakra-ui/react';
import FAIcon from '../FAIcon';
import ISupportRequest, { SupportRequestStatus } from '../../db/supportRequests';
import ModalLeftNav from './ModalLeftNav';
import SupportRequestList from './SupportRequestsList';
import { useQuerySupportRequests } from '../../modules/supportRequests/queryHooks';
import { SelectedUserContext } from '../../modules/interior/Index';

interface Props {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
  selectedRequest: ISupportRequest | null;
  onSelect: (supportRequest: ISupportRequest | null) => void;
  selectedStatus: SupportRequestStatus;
  onStatusChange: (newStatus: SupportRequestStatus) => void;
}

const SupportRequestsModal = (props: Props): React.ReactElement | null => {
  const { selectedUser } = useContext(SelectedUserContext);
  /* should never really happen */
  if (!selectedUser) return null;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useQuerySupportRequests(selectedUser);
  const requests = data ? data : [];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation('support_requests');
  const { isOpen, onClose } = props;

  /**
   * Should be removed once context is fixed to avoid
   * Network requests
   */
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered={true}>
      <ModalOverlay />
      <ModalContent borderRadius="20px" overflow="hidden" maxWidth="1000px" maxHeight="639px">
        <ModalHeader display="flex" width="100%" alignItems="center" justifyContent="center" pt="40px" pb="20px">
          <FAIcon transform="scale(1.5, 1)" fontSize="20px" icon="inbox" mr="22px" color="#4F4F4F" />
          <chakra.span fontSize="20px" fontWeight={600} color="#4F4F4F">
            {t('requestsModal.title')}
          </chakra.span>
        </ModalHeader>
        <ModalCloseButton
          width="24px"
          height="24px"
          backgroundColor="#BEBEBE"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="12px"
          marginRight={['19px', '22px']}
          marginTop={['39px', '20px']}
        >
          <FAIcon lineHeight="90%" icon="xmark" fontSize="20px" color="white" />
        </ModalCloseButton>
        <Divider borderColor="#E5E5E5" orientation="horizontal" />
        <Flex alignItems="stretch" height="553px">
          <ModalLeftNav
            requests={requests}
            selectedStatus={props.selectedStatus}
            onChange={(newStatus) => props.onStatusChange(newStatus)}
          />
          <ModalBody pl="33px" pr="46px" pt="15px" pb="20px" height="100%">
            <SupportRequestList
              status={props.selectedStatus}
              requests={requests}
              selectedRequest={props.selectedRequest}
              onSelect={props.onSelect}
            />
          </ModalBody>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default SupportRequestsModal;
