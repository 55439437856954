import React, { forwardRef } from 'react';
import { FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';

interface FieldProps {
  name: string;
  label: string;
  type: string;
  error: FieldError | any;
  placeholder?: string;
  dataTest?: string;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean | undefined;
}

const Field = forwardRef<HTMLInputElement, FieldProps>(
  ({ label, error, dataTest, onChange, isDisabled, defaultValue, ...inputProps }, ref) => {
    return (
      <FormControl mb="40px" isInvalid={error !== undefined}>
        <FormLabel as="label" htmlFor={inputProps.name} color="purple3.600" fontWeight={300} fontSize={16}>
          {label}
        </FormLabel>
        <Input
          {...inputProps}
          ref={ref}
          id={inputProps.name}
          borderRadius={0}
          borderWidth={2}
          borderColor="purple3.600"
          _focus={{ borderColor: 'purple3.200' }}
          data-test={dataTest + '-input'}
          onChange={onChange}
          isDisabled={isDisabled}
          defaultValue={defaultValue}
        />
        <FormErrorMessage data-test={dataTest + '-error'}>{error && error.message}</FormErrorMessage>
      </FormControl>
    );
  },
);

Field.displayName = 'Field';

export default Field;
