import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm, ControllerRenderProps } from 'react-hook-form';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Center } from '@chakra-ui/layout';
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  FormErrorMessage,
  FormControl,
  Stack,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import FAIcon from '../FAIcon';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker from 'react-datepicker';
import { createInteraction } from '../../modules/interactions/actions';
import { moduleName, interactionType } from '../../modules/interactions/constants';
import { useHistory } from 'react-router-dom';
import { isPirLinkedUser } from '../../modules/linked-users/utils';
import { useCreateMedication, useUpdateMedication } from '../../modules/medications/queryHooks';
import IMedication from '../../modules/medications/interfaces/IMedication';
import TertiaryButton from '../TertiaryButton';
import PrimaryButton from '../PrimaryButton';

interface FormValues {
  name: string;
  daysOfWeek?: string[];
  frequency: string;
  goal?: string;
  reward?: string;
  nickname?: string;
  streak?: number;
  startDate?: Date;
  endDate?: Date;
  goalDaysTracked?: Date[];
  completedDays: number[];
  createdDateTime?: Date;
  weeklyDay?: string;
  monthlyDay?: number;
}

interface SuppliedProps {
  selectedMedication: IMedication | null;
  onSelectMedication(medication: IMedication | null): void;
  setSelectedMedication: React.Dispatch<React.SetStateAction<IMedication | null>>;
}

type Props = SuppliedProps;

const TODAY_DATE = new Date();
const monthDayValues: number[] = Array.from({ length: 28 }, (_, index) => index + 1);

const AddMedicationForm = ({
  pir,
  createInteraction,
  hasOnboarded,
  selectedMedication,
  onSelectMedication,
  setSelectedMedication,
}: Props & PropsFromRedux): React.ReactElement => {
  const { t } = useTranslation('medications');
  const history = useHistory();

  const { register, reset, handleSubmit, formState, control, getValues, setValue } = useForm<FormValues>({
    defaultValues: {
      name: selectedMedication?.name,
      daysOfWeek: selectedMedication?.daysOfWeek || [],
      frequency: selectedMedication?.frequency,
      goal: selectedMedication?.goal,
      reward: selectedMedication?.reward,
      nickname: selectedMedication?.nickname,
      streak: selectedMedication?.streak,
      startDate: selectedMedication?.startDate,
      endDate: selectedMedication?.endDate,
      goalDaysTracked: selectedMedication?.goalDaysTracked,
      completedDays: selectedMedication?.completedDays,
      createdDateTime: selectedMedication?.createdDateTime,
      weeklyDay: selectedMedication?.weeklyDay,
      monthlyDay: selectedMedication?.monthlyDay,
    },
  });

  const { errors } = formState;

  const { mutate: createMedication, isLoading: medicationCreating } = useCreateMedication(pir?.id);
  const { mutate: updateMedication, isLoading: medicationUpdating } = useUpdateMedication(pir?.id);

  const [showAddButton, setShowAddButton] = useState(true);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [frequencyValue, setFrequencyValue] = useState(selectedMedication?.frequency ?? '');
  const [goalValue, setGoalValue] = useState(selectedMedication?.goal || 'no');
  const [showDays, setShowDays] = useState(selectedMedication?.frequency === 'Select Days' ? true : false);
  const [showGoalOpts, setShowGoalOpts] = useState(selectedMedication?.goal === 'yes' ? true : false);
  const [count, setCount] = useState<number>(0);

  const selectedLinkedUser = useSelector((state: RootState) => state.linkedUsers.selectedLinkedUser);

  const setFrequency = (value: string) => {
    setFrequencyValue(value);
    setShowDays(value === 'Select Days');
    addModifiedFieldInteraction();
    if (value !== 'Weekly' && getValues('weeklyDay') && !selectedMedication?.weeklyDay)
      setValue('weeklyDay', undefined);
    if (value !== 'Monthly' && getValues('monthlyDay') && !selectedMedication?.monthlyDay)
      setValue('monthlyDay', undefined);
    if (value !== 'Select Days' && getValues('daysOfWeek') !== undefined && !selectedMedication?.daysOfWeek)
      setValue('daysOfWeek', undefined);
  };

  const formRef = useRef<HTMLFormElement>(null);
  const scrollToForm = () => formRef.current?.scrollIntoView();

  const setGoalConfirm = (value: string) => {
    setGoalValue(value);
    setShowGoalOpts(value === 'yes');
    addModifiedFieldInteraction();
  };

  const resetButtonStates = () => {
    setShowAddButton(!showAddButton);
    setShowSaveButton(!showSaveButton);
    setFrequencyValue('');
    setGoalValue('no');
    setShowGoalOpts(false);
    setShowDays(false);
    setCount(0);

    reset({
      name: '',
      daysOfWeek: [],
      frequency: '',
      goal: '',
      reward: '',
      nickname: '',
      streak: undefined,
      startDate: undefined,
      endDate: undefined,
    });
  };

  const onCancel = () => {
    resetButtonStates();
    setSelectedMedication(null);
  };

  useEffect(() => {
    return () => {
      setShowAddButton(true);
      setShowSaveButton(false);
      setShowGoalOpts(false);
      setShowDays(false);
      setCount(0);
      setFrequencyValue('');
      setGoalValue('no');

      reset({
        name: '',
        daysOfWeek: [],
        frequency: '',
        goal: '',
        reward: '',
        nickname: '',
        streak: undefined,
        startDate: undefined,
        endDate: undefined,
      });
    };
  }, []);

  const resetGoal = () => {
    setShowGoalOpts(false);

    reset({
      reward: '',
      nickname: '',
      streak: undefined,
      startDate: undefined,
      endDate: undefined,
    });
  };

  const displaySaveButton = () => {
    const content = showSaveButton ? t('addMedicationForm.saveButton') : t('addMedicationForm.saveButtonConfirm');

    const icon = !showSaveButton && <FAIcon icon="check" ml={4} fontSize={14} />;

    return (
      <FormControl isRequired={true}>
        <Flex justifyContent="space-between">
          <TertiaryButton
            variant="link"
            isDisabled={!showSaveButton}
            mt={10}
            onClick={onCancel}
            isLoading={formState.isSubmitting}
            data-test="cancel-medication"
          >
            {t('medicationModal.modalCancel')}
          </TertiaryButton>
          <PrimaryButton
            isDisabled={!showSaveButton}
            mt={10}
            isLoading={formState.isSubmitting}
            type="submit"
            data-test="save-medication"
          >
            {content}
            {icon}
          </PrimaryButton>
        </Flex>
      </FormControl>
    );
  };

  const saveMedication = async (medication: FormValues): Promise<void> => {
    const monthlyDay =
      medication.frequency !== 'Monthly'
        ? undefined
        : medication.monthlyDay
        ? Number(medication.monthlyDay)
        : undefined;

    medication.monthlyDay ? Number(medication.monthlyDay) : undefined;
    const name = medication.name as string;
    if (selectedMedication?.id) {
      await updateMedication({
        id: selectedMedication.id,
        pir,
        name,
        daysOfWeek: medication?.daysOfWeek,
        goal: medication?.goal,
        frequency: medication.frequency,
        reward: medication?.reward,
        nickname: medication?.nickname,
        streak: medication?.streak,
        startDate: medication?.startDate,
        endDate: medication?.endDate,
        goalDaysTracked: medication?.goalDaysTracked,
        completedDays: selectedMedication.completedDays,
        createdDateTime: selectedMedication.createdDateTime,
        weeklyDay: medication?.weeklyDay,
        monthlyDay,
      });

      onSelectMedication(null);
    } else {
      await createMedication({
        pir,
        name,
        daysOfWeek: medication.daysOfWeek,
        frequency: medication.frequency,
        goal: medication.goal,
        reward: medication.reward,
        nickname: medication.nickname,
        streak: medication.streak,
        startDate: medication.startDate,
        goalDaysTracked: medication.goalDaysTracked,
        completedDays: medication.completedDays,
        createdDateTime: new Date(),
        weeklyDay: medication.weeklyDay,
        monthlyDay,
      });
    }
    resetButtonStates();
    await createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MEDICATIONS,
      interactionType: interactionType.MEDICATIONS.SAVED,
    });

    if (!hasOnboarded) {
      history.push('/survey');
    }
  };

  const addModifiedFieldInteraction = () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MEDICATIONS,
      interactionType: interactionType.MEDICATIONS.MODIFIED_FIELD,
    });
  };

  useEffect(() => {
    if (!medicationCreating && !medicationUpdating) {
      showSaveButton && setShowSaveButton(false);
      !showAddButton && setShowAddButton(true);
    }
  }, [medicationCreating, medicationUpdating]);

  useEffect(() => {
    if (selectedMedication) {
      setShowAddButton(false);
      setShowSaveButton(true);
      setShowGoalOpts(selectedMedication.goal === 'yes');
      setShowDays(!!selectedMedication.daysOfWeek?.length);
      setFrequencyValue(selectedMedication.frequency);
      setGoalValue(selectedMedication.goal ?? 'no');
      reset(selectedMedication);

      scrollToForm();
    }
  }, [selectedMedication, reset]);

  useEffect(() => {
    scrollToForm();
  }, [showAddButton]);

  const frequencyType = () => {
    const frequencyValue = getValues('frequency');
    switch (frequencyValue) {
      case 'Weekly':
        return 'weeks';
      case 'Monthly':
        return 'months';
      default:
        return 'days';
    }
  };

  return (
    <Box>
      {showAddButton ? (
        <Button onClick={resetButtonStates} mb={10} mt={25} variant="link">
          {t('addMedicationForm.addButton')}
        </Button>
      ) : (
        <form ref={formRef} autoComplete="off" onSubmit={handleSubmit(saveMedication)}>
          <FormControl isInvalid={errors.name !== undefined}>
            <Text fontSize={16} fontWeight="bold" mb={5}>
              {t('addMedicationForm.title')}
            </Text>
            {/*name field*/}
            <Text fontSize={14}>{t('addMedicationForm.nameField')}</Text>
            <Input
              placeholder={t('addMedicationForm.namePlaceholder')}
              w="100%"
              size="md"
              borderColor="#E2E8F0"
              borderRadius={4}
              mb={4}
              {...register('name', {
                required: {
                  value: true,
                  message: t('addMedicationForm.errors.nameRequired'),
                },
              })}
              onFocus={addModifiedFieldInteraction}
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>

          {/*days of week & frequency field*/}
          {selectedLinkedUser && isPirLinkedUser(selectedLinkedUser) ? (
            <Text fontSize={14} mb={2}>
              {t('addMedicationForm.frequencyMessagePIR')}
            </Text>
          ) : (
            <>
              <Text fontSize={14}>{t('addMedicationForm.frequencyField')}</Text>
              <Text fontSize={14} color="grey3.400" mb={2}>
                {t('addMedicationForm.frequencyMessage')}
              </Text>
            </>
          )}

          <FormControl isInvalid={errors.frequency !== undefined}>
            <RadioGroup
              name="frequency"
              onChange={(e) => {
                setFrequency(e);
              }}
              value={frequencyValue}
            >
              <Stack spacing={4} direction="column">
                <Radio
                  {...register('frequency', {
                    required: {
                      value: true,
                      message: t('addMedicationForm.errors.freqRequired'),
                    },
                  })}
                  value="Daily"
                >
                  {t('addMedicationForm.radioField.Daily')}
                </Radio>
                <Flex direction="column">
                  <Radio
                    {...register('frequency', {
                      required: {
                        value: true,
                        message: t('addMedicationForm.errors.freqRequired'),
                      },
                    })}
                    value="Weekly"
                  >
                    {t('addMedicationForm.radioField.Weekly')}
                  </Radio>
                  {frequencyValue === 'Weekly' && (
                    <FormControl isInvalid={errors.weeklyDay !== undefined}>
                      <FormLabel fontSize={14} fontWeight={400}>
                        {t('addMedicationForm.chooseWeeklyDayLabel')}
                      </FormLabel>
                      <Controller
                        name="weeklyDay"
                        control={control}
                        rules={{ required: t('addMedicationForm.errors.selectDayRequired') }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            value={value}
                            onChange={onChange}
                            placeholder={t('addMedicationForm.selectDay')}
                            width="150px"
                          >
                            {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(
                              (day) => (
                                <option key={day} value={t(`addMedicationForm.checkBoxField.${day}`)}>
                                  {t(`addMedicationForm.checkBoxField.${day}`)}
                                </option>
                              ),
                            )}
                          </Select>
                        )}
                      />
                      <FormErrorMessage>
                        {errors.weeklyDay && t('addMedicationForm.errors.selectDayRequired')}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Flex>
                <Flex direction="column">
                  <Radio
                    {...register('frequency', {
                      required: {
                        value: true,
                        message: t('addMedicationForm.errors.freqRequired'),
                      },
                    })}
                    value="Monthly"
                  >
                    {t('addMedicationForm.radioField.Monthly')}
                  </Radio>
                  {frequencyValue === 'Monthly' && (
                    <FormControl isInvalid={errors.monthlyDay !== undefined}>
                      <FormLabel fontSize={14} fontWeight={400}>
                        {t('addMedicationForm.chooseMonthlyDayLabel')}
                      </FormLabel>
                      <Controller
                        name="monthlyDay"
                        control={control}
                        rules={{ required: t('addMedicationForm.errors.selectDayRequired') }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            value={value}
                            onChange={onChange}
                            placeholder={t('addMedicationForm.selectDay')}
                            width="150px"
                          >
                            {monthDayValues.map((day) => (
                              <option key={day} value={day}>
                                {day}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                      <FormErrorMessage>
                        {errors.monthlyDay && t('addMedicationForm.errors.selectDayRequired')}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Flex>
                <Flex direction="column">
                  <Radio
                    {...register('frequency', {
                      required: {
                        value: true,
                        message: t('addMedicationForm.errors.freqRequired'),
                      },
                    })}
                    value="Select Days"
                  >
                    {t('addMedicationForm.radioField.selectDays')}
                  </Radio>
                  {showDays && (
                    <FormControl isInvalid={errors.daysOfWeek !== undefined}>
                      <FormLabel fontSize={14} fontWeight={400}>
                        {t('addMedicationForm.chooseDaysLabel')}
                      </FormLabel>
                      <Controller
                        name="daysOfWeek"
                        control={control}
                        rules={{ required: t('addMedicationForm.errors.selectDaysRequired') }}
                        render={({ field: { onChange, value } }) => (
                          <CheckboxGroup value={value} onChange={onChange}>
                            <Stack ml={6}>
                              {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(
                                (day) => (
                                  <Checkbox key={day} value={day}>
                                    {t(`addMedicationForm.checkBoxField.${day}`)}
                                  </Checkbox>
                                ),
                              )}
                            </Stack>
                          </CheckboxGroup>
                        )}
                      />
                      <FormErrorMessage>
                        {errors.daysOfWeek && t('addMedicationForm.errors.selectDaysRequired')}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Flex>
              </Stack>
            </RadioGroup>
            <FormErrorMessage>{errors.frequency && errors.frequency.message}</FormErrorMessage>
          </FormControl>

          {/*GOAL OPTS*/}
          <Text mt={3} fontSize={14}>
            {t('addMedicationForm.goals')}
          </Text>
          <FormControl isInvalid={errors.goal !== undefined}>
            <RadioGroup name="goal" onChange={setGoalConfirm} value={goalValue}>
              <Stack spacing={10} direction="row">
                <Radio
                  {...register('goal', {
                    required: {
                      value: true,
                      message: t('addMedicationForm.errors.goalRequired'),
                    },
                  })}
                  value="yes"
                >
                  {t('addMedicationForm.goal.yes')}
                </Radio>
                <Radio
                  {...register('goal', {
                    required: {
                      value: true,
                      message: t('addMedicationForm.errors.goalRequired'),
                    },
                  })}
                  value="no"
                  onClick={resetGoal}
                >
                  {t('addMedicationForm.goal.no')}
                </Radio>
              </Stack>
            </RadioGroup>
            <FormErrorMessage>{errors.goal?.message}</FormErrorMessage>
          </FormControl>

          {/*goal field*/}
          {showGoalOpts && (
            <Box>
              <Text mt={3} fontSize={14}>
                {t('addMedGoalForm.rewardField')}
              </Text>
              <Text fontSize={14} color="grey3.400" mb={2}>
                {t('addMedGoalForm.rewardFieldinstruct')}
              </Text>
              <FormControl isInvalid={errors.reward !== undefined}>
                <Input
                  placeholder={t('addMedGoalForm.rewardPlaceholder')}
                  w="100%"
                  size="md"
                  borderColor="black"
                  borderRadius={2}
                  mb={5}
                  {...register('reward', {
                    required: {
                      value: true,
                      message: t('addMedicationForm.errors.rewardRequired'),
                    },
                  })}
                  onFocus={addModifiedFieldInteraction}
                />
                <FormErrorMessage>{errors.reward?.message}</FormErrorMessage>
              </FormControl>

              <Text fontSize={14}>{t('addMedGoalForm.nicknameField')}</Text>
              <Text fontSize={14} color="grey3.400" mb={2}>
                {t('addMedGoalForm.nicknameFieldinstruct')}
              </Text>
              <Flex direction="row" w="100">
                <FormControl isInvalid={errors.nickname !== undefined}>
                  <Input
                    w="75%"
                    placeholder={t('addMedGoalForm.instructPlaceholder')}
                    borderColor="black"
                    borderRadius={2}
                    type="text"
                    maxLength={25}
                    mb={5}
                    {...register('nickname', {
                      required: {
                        value: true,
                        message: t('addMedicationForm.errors.nicknameRequired'),
                      },
                    })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCount(e.target.value.length)}
                    onFocus={addModifiedFieldInteraction}
                  />
                  <FormErrorMessage>{errors.nickname?.message}</FormErrorMessage>
                </FormControl>
                <Box w="20%" mt={1} ml={10}>
                  <Text fontSize={14}>{`(${count}/25)`}</Text>
                </Box>
              </Flex>

              <Text fontSize={14}>{t('addMedGoalForm.setGoalStreak')}</Text>
              <Text fontSize={14} color="grey3.400" mb={2}>
                {`Select the number of ${frequencyType()} for your goal.`}
              </Text>
              <FormControl isInvalid={errors.streak !== undefined}>
                <NumberInput borderColor="black" borderRadius={2} w="25%" mb={5} min={1} max={100} defaultValue="">
                  <NumberInputField
                    {...register('streak', {
                      required: {
                        value: true,
                        message: t('addMedicationForm.errors.streakRequired'),
                      },
                    })}
                    onChange={addModifiedFieldInteraction}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormErrorMessage>{errors.streak?.message}</FormErrorMessage>
              </FormControl>

              <Text fontSize={14}>{t('addMedGoalForm.startField')}</Text>
              <Text fontSize={14} color="grey3.400" mb={2}>
                {t('addMedGoalForm.lengthInstruct')}
              </Text>
              <Flex direction="column">
                <FormControl isInvalid={errors.startDate !== undefined}>
                  <Text fontSize={14} color="grey3.400" mb={2}>
                    {t('addMedGoalForm.startDate')}
                  </Text>
                  <Controller
                    control={control}
                    name="startDate"
                    defaultValue={undefined}
                    rules={{
                      required: { value: true, message: t('addMedicationForm.errors.dateInvalid') },
                    }}
                    render={({
                      field: { onChange, value },
                    }: {
                      field: ControllerRenderProps<FormValues, 'startDate'>;
                    }) => (
                      <ReactDatePicker
                        className="input"
                        selected={value ? new Date(value) : undefined}
                        onChange={(date) => {
                          const selectedDate = Array.isArray(date) ? date[0] : date;

                          if (selectedDate instanceof Date) {
                            onChange(selectedDate);
                          } else {
                            onChange(null);
                          }
                        }}
                        onCalendarOpen={addModifiedFieldInteraction}
                        minDate={TODAY_DATE}
                        placeholderText={t('addMedGoalForm.placeholderStart')}
                      />
                    )}
                  />
                  <FormErrorMessage>{t('addMedicationForm.errors.dateInvalid')}</FormErrorMessage>
                </FormControl>
              </Flex>
            </Box>
          )}

          <Center>{displaySaveButton()}</Center>
        </form>
      )}
    </Box>
  );
};

const mapDispatchToProps = {
  createInteraction,
};

function mapStateToProps(state: RootState) {
  const { selectedLinkedUser } = state.linkedUsers;

  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }
  return {
    pir: selectedLinkedUser.pir,
    hasOnboarded: state.onboarding.hasOnboarded,
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddMedicationForm);
