import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalContent, ModalBody, ModalOverlay, Text, Box, Flex } from '@chakra-ui/react';
import { PossibleLinkedUser } from '../../modules/linked-users/interfaces/ILinkedUser';
import IAppointment, { AppointmentStatus } from 'src/modules/appointments/interfaces/IAppointment';
import PrimaryButton from '../PrimaryButton';
import { getAppointmentTimeStringRange } from './utils';
import SecondaryButton from '../SecondaryButton';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  selectedAppointment: IAppointment | null;
  selectedLinkedUser: PossibleLinkedUser;
  cancelType: AppointmentStatus | undefined;
  cancelAppointment: () => Promise<void>;
}

type Props = SuppliedProps;

const ConfirmCancelModal = ({
  isOpen,
  onClose,
  selectedAppointment,
  cancelType,
  cancelAppointment,
}: Props): React.ReactElement => {
  const { t } = useTranslation('appointments');
  const { isPhone } = useDevice();

  const cancel =
    cancelType === AppointmentStatus.CANCELED ? t('confirmCancelModal.cancel') : t('confirmCancelModal.decline');
  const otherUserName = selectedAppointment?.otherUserAlias ?? selectedAppointment?.otherUserName;
  const dateString = selectedAppointment?.appointmentDate.toLocaleDateString(navigator.language, { weekday: 'long' });
  const timeString = selectedAppointment && getAppointmentTimeStringRange(selectedAppointment);

  const message = t('confirmCancelModal.areYouSure', { cancel, otherUserName, dateString, timeString });

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          maxWidth={isPhone ? ['90%', '90%', '90%', '90%', '90%'] : '350px'}
          py={6}
          px={6}
          zIndex={1900}
          borderRadius="10px"
        >
          <ModalBody w="100%">
            <Flex w="100%" direction="column" justifyContent="space-between" alignItems="center">
              <Text color="#4F4F4F" fontWeight="bold" textAlign="center" fontSize={16} mb={10} lineHeight={'22px'}>
                {message}
              </Text>
              <PrimaryButton onClick={cancelAppointment} mb={5}>
                {cancelType === AppointmentStatus.CANCELED
                  ? t('confirmCancelModal.cancelButton')
                  : t('confirmCancelModal.declineButton')}
              </PrimaryButton>
              <SecondaryButton onClick={onClose}>{t('confirmCancelModal.goBack')}</SecondaryButton>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ConfirmCancelModal;
