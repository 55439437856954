import React, { useEffect, forwardRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Button, FormControl, FormErrorMessage, Checkbox, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import emailPattern from '../../constants/emailPattern';
import PrimaryButton from '../PrimaryButton';
import Field from '../Field';
import { Contact, ContactType, FormData } from './ManageContactModal';

interface Props {
  preferredNamePlaceholder: string;
  contactAliasPlaceholder: string;
  relationshipPlaceholder: string;
  error: string | null;
  onSubmit: SubmitHandler<FormData>;
  onCancel(): void;
  interactionCallback?(): void;
  onBlurCallback?(): void;
  type: string;
  initialValues: Contact | null;
}

const SupportMemberInfoForm = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    contactAliasPlaceholder,
    relationshipPlaceholder,
    preferredNamePlaceholder,
    error,
    onSubmit,
    onCancel,
    interactionCallback,
    onBlurCallback,
    type,
    initialValues,
  } = props;
  const { t } = useTranslation('common');
  const { handleSubmit, register, formState, getValues, setValue } = useForm<FormData>();
  const valueKeys = [
    'contactAlias',
    'preferredName',
    'relationship',
    'otherUserPhone',
    'otherUserEmail',
    'preferredName',
    'cpName',
    'notificationOnSOS',
  ];
  const { errors } = formState;

  useEffect(() => {
    if (initialValues && initialValues.linkedUser && initialValues.linkedUser.dateSubmitted) {
      valueKeys.forEach((key) => {
        if (key in initialValues) {
          const contactKey = key as keyof FormData;
          setValue(contactKey, initialValues[contactKey]);
        }
      });
    }
  }, [initialValues]);

  return (
    <Box ref={ref} pb="20px" pt="20px">
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        <FormControl isInvalid={error !== null} mb={2}>
          <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>

        <Box>
          {/* Checkbox to choose if contact gets contacted on alert*/}
          <HeadingText>Send alerts</HeadingText>
          <FormControl>
            <Box as="label" display="flex" alignItems="center" pb={2}>
              <Checkbox
                colorScheme="purple3"
                defaultChecked={
                  initialValues && initialValues.notificationOnSOS !== undefined
                    ? initialValues.notificationOnSOS
                    : true
                }
                {...register('notificationOnSOS')}
              />
              <Text as="label" fontStyle="italic" fontSize="12" fontWeight="light" ml={2}>
                {t('manageContactModal.supporterForm.checkbox')}
              </Text>
            </Box>
          </FormControl>
          {/* CP Name field */}
          {type === 'provider' && initialValues && initialValues.contactType === ContactType.APPROVED_PROVIDER && (
            <>
              <HeadingText>Name*</HeadingText>
              <Field
                {...register('cpName', {
                  maxLength: {
                    value: 255,
                    message: t('manageContactModal.supporterForm.errors.aliasMaxLength'),
                  },
                })}
                name="cpName"
                label={t('manageContactModal.supporterForm.cpNameLabel')}
                type="text"
                error={errors.cpName}
                placeholder=""
                readOnly={true}
                onFocus={interactionCallback}
                onBlur={onBlurCallback}
                data-test="cpName"
                defaultValue={initialValues && !!getValues('cpName') ? getValues('cpName') : undefined}
              />
            </>
          )}

          {/* Contact Alias field */}
          <HeadingText>Nickname*</HeadingText>
          <Field
            InputComponent={StyledInput}
            {...register('contactAlias', {
              required: {
                value: true,
                message: t('manageContactModal.supporterForm.errors.aliasRequired'),
              },
              maxLength: {
                value: 255,
                message: t('manageContactModal.supporterForm.errors.aliasMaxLength'),
              },
            })}
            name="contactAlias"
            label={t('manageContactModal.supporterForm.aliasLabel')}
            type="text"
            error={errors.contactAlias}
            placeholder={contactAliasPlaceholder}
            onFocus={interactionCallback}
            onBlur={onBlurCallback}
            data-test="contactAlias"
            defaultValue={initialValues && !!getValues('contactAlias') ? getValues('contactAlias') : undefined}
          />

          {/* Email field */}
          <HeadingText>Email*</HeadingText>
          <Field
            InputComponent={type === 'provider' && !!initialValues ? undefined : StyledInput}
            {...register('otherUserEmail', {
              required: {
                value: true,
                message: t('manageContactModal.supporterForm.errors.emailRequired'),
              },
              pattern: {
                value: emailPattern,
                message: t('manageContactModal.supporterForm.errors.emailInvalid'),
              },
            })}
            name="otherUserEmail"
            label={t('manageContactModal.supporterForm.emailLabel')}
            type="email"
            error={errors.otherUserEmail}
            placeholder="nobody@example.com"
            readOnly={type === 'provider' && !!initialValues}
            onFocus={interactionCallback}
            onBlur={onBlurCallback}
            data-test="otherUserEmail"
            defaultValue={initialValues && !!getValues('otherUserEmail') ? getValues('otherUserEmail') : undefined}
          />

          {/* Phone field */}
          <>
            <HeadingText>Phone</HeadingText>
            <Field
              InputComponent={type === 'provider' && !!initialValues ? undefined : StyledInput}
              {...register('otherUserPhone', {
                pattern: {
                  value: /[0-9]{10}/,
                  message: t('manageContactModal.supporterForm.errors.phoneInvalid'),
                },
                maxLength: {
                  value: 10,
                  message: t('manageContactModal.supporterForm.errors.phoneInvalid'),
                },
              })}
              name="otherUserPhone"
              label={
                type === 'supporter'
                  ? t('manageContactModal.supporterForm.supporterPhoneLabel')
                  : t('manageContactModal.supporterForm.phoneLabel')
              }
              type="tel"
              error={errors.otherUserPhone}
              placeholder={
                type === 'provider' && initialValues
                  ? t('manageContactModal.supporterForm.uneditableMissingPhone')
                  : t('manageContactModal.supporterForm.phoneNumberPlaceholder')
              }
              readOnly={type === 'provider' && !!initialValues}
              onFocus={interactionCallback}
              onBlur={onBlurCallback}
              data-test="otherUserPhone"
              defaultValue={initialValues && !!getValues('otherUserPhone') ? getValues('otherUserPhone') : undefined}
            />
          </>

          {/* Relationship field */}
          <HeadingText>Relationship*</HeadingText>
          <Field
            InputComponent={StyledInput}
            {...register('relationship', {
              required: {
                value: type === 'provider',
                message: t('manageContactModal.supporterForm.errors.relationshipRequired'),
              },
              maxLength: {
                value: 255,
                message: t('manageContactModal.supporterForm.errors.relationshipMaxLength'),
              },
            })}
            name="relationship"
            label={t('manageContactModal.supporterForm.relationshipLabel')}
            type="text"
            error={errors.relationship}
            placeholder={relationshipPlaceholder}
            onFocus={interactionCallback}
            onBlur={onBlurCallback}
            data-test="relationship"
            defaultValue={initialValues && !!getValues('relationship') ? getValues('relationship') : undefined}
          />

          {/* Preferred Name field */}
          <HeadingText>Preferred Name*</HeadingText>
          <Field
            InputComponent={StyledInput}
            {...register('preferredName', {
              required: {
                value: true,
                message: t('manageContactModal.supporterForm.errors.preferredNameRequired'),
              },
              maxLength: {
                value: 255,
                message: t('manageContactModal.supporterForm.errors.preferredNameMaxLength'),
              },
            })}
            name="preferredName"
            label={t('manageContactModal.supporterForm.preferredNameLabel')}
            type="text"
            error={errors.preferredName}
            placeholder={preferredNamePlaceholder}
            onFocus={interactionCallback}
            onBlur={onBlurCallback}
            data-test="preferredName"
            defaultValue={
              initialValues && !!getValues('preferredName') ? getValues('preferredName') : preferredNamePlaceholder
            }
          />

          {/* Action buttons */}
          <Flex justifyContent="space-between" w="100%">
            <Button variant="link" onClick={onCancel}>
              {t('manageContactModal.supporterForm.cancelButton')}
            </Button>
            <PrimaryButton type="submit" isLoading={formState.isSubmitting} data-test="save">
              {t('manageContactModal.supporterForm.saveButton')}
            </PrimaryButton>
          </Flex>
        </Box>
      </form>
    </Box>
  );
});

export default SupportMemberInfoForm;
SupportMemberInfoForm.displayName = 'SupportMemberInfoForm';

const StyledInput = styled.input`
  border: ${(props) => (props.readOnly ? 'none' : '1px solid black')};
  &:focus {
    border-color: ${(props) => (!props.readOnly ? '2px solid #333' : 'none')};
  }
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
`;

const HeadingText = styled.div`
  font-weight: 700;
  color: #8f8f8f;
  font-size: 14px;
  line-height: 19.07px;
  text-align: left;
  text-transform: uppercase;
`;
