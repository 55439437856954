import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISupporterLinkedUser, PhoneAuthorization } from '../../modules/linked-users/interfaces/ILinkedUser';
import { Box, Text, Link, Flex } from '@chakra-ui/react';
import FAIcon from '../../components/FAIcon';

interface Props {
  networkMember: ISupporterLinkedUser;
  onDelete(): void;
  onEdit(): void;
}

const SupportContact = ({ networkMember, onDelete, onEdit }: Props): JSX.Element => {
  const { t } = useTranslation('common');
  const doNotNotify = 'notificationOnSOS' in networkMember && networkMember.notificationOnSOS === false ? true : false;

  const phoneAuth =
    networkMember.phoneAuthorization === PhoneAuthorization.PENDING
      ? t('manageContactModal.pendingNotice')
      : networkMember.phoneAuthorization === PhoneAuthorization.DENIED
      ? t('manageContactModal.rejectedNotice')
      : networkMember.phoneAuthorization === PhoneAuthorization.AUTHORIZED
      ? t('manageContactModal.authorizedNotice')
      : t('manageContactModal.pendingNotice');

  return (
    <Box
      p={4}
      bg={doNotNotify ? 'grey6.50' : 'grey6.50'}
      mb={4}
      borderRadius={8}
      data-test={networkMember.contactAlias}
      border={doNotNotify ? '' : '1px solid'}
      borderColor={doNotNotify ? '' : 'purple3.600'}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <FAIcon
          icon={doNotNotify ? 'bell-slash' : 'bell'}
          mr={6}
          as="div"
          display="inline-block"
          fontSize={18}
          width={6}
          color="grey.500"
          mt="-2"
        />
        <Flex>
          <Text fontSize={12}>
            <Link
              ml={2}
              textDecoration="underline"
              onClick={onEdit}
              _hover={{ textDecoration: 'none' }}
              data-test={networkMember.contactAlias + 'edit'}
            >
              {t('manageContactModal.editLink')}
            </Link>

            <Link
              ml={2}
              textDecoration="underline"
              onClick={onDelete}
              _hover={{ textDecoration: 'none' }}
              data-test={networkMember.contactAlias + 'delete'}
            >
              {t('manageContactModal.deleteLink')}
            </Link>
          </Text>
        </Flex>
      </Flex>

      <Text fontSize={14} fontWeight="bold" overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
        {networkMember.contactAlias}
      </Text>

      <Text fontSize={14} overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
        {networkMember.otherUserEmail}
      </Text>

      {networkMember.otherUserPhone && (
        <Text fontSize={14} overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
          {networkMember.otherUserPhone} ({phoneAuth})
        </Text>
      )}

      {networkMember.relationship && (
        <Text fontSize={14} overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
          {networkMember.relationship}
        </Text>
      )}

      <Text fontSize={14} fontStyle="italic" overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
        Identifies you as: {networkMember.preferredName}
      </Text>
    </Box>
  );
};

export default SupportContact;
