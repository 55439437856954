import React, { useEffect, useState } from 'react';
import { SimpleGrid, Text } from '@chakra-ui/react';
import { AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/accordion';
import { useTranslation } from 'react-i18next';
import { IResource } from '../../db/resources';
import FAIcon from '../FAIcon';
import { IconLookup, IconName } from '@fortawesome/fontawesome-svg-core';
import WebLinkNewWindow from '../WebLinkNewWindow';
import { getAttachmentType, getUrlType } from 'src/modules/meditations/utils';
import InlineVideoPlayer from 'src/modules/meditations/InlineVideoPlayer';
import { useWindowSize } from 'src/hooks/utils';
import InlineImage from 'src/modules/meditations/InlineImage';
import TextResourceBox from '../TextResourceBox';
import { useDevice } from 'src/DeviceContext';

interface Props {
  resource: IResource;
  name: string;
  displayType: string;
}

type IconMapType = {
  [idx: string]: IconName | IconLookup;
};

const iconMap: IconMapType = {
  link: 'globe',
  unknown: 'globe',
  video: 'video',
  text: 'book-open',
  pdf: 'file-alt',
  image: 'image',
  audio: 'music',
  note: 'book',
};

const VerticalListResourceItem = ({ resource, name, displayType }: Props): JSX.Element => {
  const { t } = useTranslation('resources');
  const size = useWindowSize();
  const [maxDimensions, setMaxDimensions] = useState({ maxWidth: 0, maxHeight: 0 });
  const { isPhone } = useDevice();

  useEffect(() => {
    if (resource !== undefined) {
      const maxWidth = isPhone
        ? size.width * 0.75
        : size.width > 1400
        ? 1050
        : size.width > 1100
        ? size.width * 0.75
        : size.width > 900
        ? size.width * 0.7
        : size.width > 500
        ? size.width * 0.6
        : size.width * 0.5;
      const maxHeight = size.height * 0.6;

      setMaxDimensions({ maxWidth, maxHeight });
    }
  }, [size, resource]);

  const AccordionBase = (): JSX.Element => {
    return (
      <>
        <SimpleGrid isTruncated={true} columns={1}>
          <Text
            fontSize={[16, 16, 18]}
            mb={4}
            fontWeight="bold"
            color="#2D98A1"
            isTruncated={true}
            marginRight={4}
            data-test={resource.title + '-title'}
          >
            {resource.title}
          </Text>
        </SimpleGrid>
        {resource.note && (
          <SimpleGrid columns={1}>
            <Text fontSize={[16, 16, 18]} color="#2D98A1" marginRight={4} isTruncated={true} fontWeight="light">
              {t('verticalResourceList.note')}
            </Text>
            <TextResourceBox textContent={resource.note} />
          </SimpleGrid>
        )}
        <SimpleGrid columns={1} mt={2}>
          {resource.link &&
            (getUrlType(resource.link) === 'video' ? (
              <InlineVideoPlayer
                videoUrl={resource.link}
                maxHeight={maxDimensions.maxHeight}
                maxWidth={maxDimensions.maxWidth}
                type="link"
              />
            ) : (
              <WebLinkNewWindow href={resource.link}>
                <Text
                  fontSize={[16, 16, 18]}
                  marginRight={4}
                  fontWeight="500"
                  color="#2D98A1"
                  isTruncated={true}
                  _hover={{ textDecoration: 'underline' }}
                >
                  {resource.link.replace(/(^\w+:|^)\/\//, '')}
                </Text>
              </WebLinkNewWindow>
            ))}

          {resource.attachmentName && resource.attachmentUrl && resource.link && <br />}

          {resource.attachmentName &&
            resource.attachmentUrl &&
            (getAttachmentType(resource.attachmentName) === 'video' ? (
              <InlineVideoPlayer
                videoUrl={resource.attachmentUrl}
                maxHeight={maxDimensions.maxHeight}
                maxWidth={maxDimensions.maxWidth}
                type="attachment"
              />
            ) : getAttachmentType(resource.attachmentName) === 'image' ? (
              <InlineImage
                imageUrl={resource.attachmentUrl}
                imageName={resource.attachmentName}
                maxWidth={maxDimensions.maxWidth}
                maxHeight={maxDimensions.maxHeight}
              />
            ) : (
              <WebLinkNewWindow href={resource.attachmentUrl}>
                <Text
                  fontSize={[16, 16, 18]}
                  marginRight={4}
                  fontWeight="500"
                  color="#2D98A1"
                  isTruncated={true}
                  _hover={{ textDecoration: 'underline' }}
                >
                  {resource.attachmentName.replace(/(^\w+:|^)\/\//, '')}
                </Text>
              </WebLinkNewWindow>
            ))}
        </SimpleGrid>
      </>
    );
  };

  return (
    <AccordionItem border="none" bg="#DCEDE7" paddingLeft={0} paddingRight={0} marginBottom="10px" rounded={10}>
      <AccordionButton>
        <SimpleGrid columns={4} width="100%" gridTemplateColumns="20px 86px auto 15px">
          <FAIcon icon={iconMap[displayType]} color="#2D98A1" marginTop="2px" />

          <Text marginLeft={4} marginRight={4} textAlign="left" fontWeight="bold" fontSize={16} color="#2D98A1">
            {displayType === 'unknown'
              ? 'N/A'
              : displayType === 'pdf'
              ? displayType.toUpperCase()
              : displayType.charAt(0).toUpperCase() + displayType.slice(1)}
          </Text>
          <Text
            as="span"
            marginLeft={2}
            marginRight={2}
            textAlign="left"
            fontWeight="bold"
            fontSize={16}
            color="#2D98A1"
            noOfLines={2}
            data-test={name}
          >
            {name}
          </Text>

          <AccordionIcon />
        </SimpleGrid>
      </AccordionButton>
      <AccordionPanel>
        <AccordionBase />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default VerticalListResourceItem;
