const GREEN = '#27AE60';
const ORANGE = '#F2994A';
const RED = '#EB5757';
const GRAY = '#AAAAAA';

export const cravingMetricColor = (hasCraving: boolean): string => (hasCraving ? RED : GREEN);

export const metricColor = (ctype: string, value: number, show = true): string => {
  let color = '#583f73'; // Purple
  if (!show) {
    return color;
  }

  // Orange: #F2994A, Green: #27AE60, Red: #EB5757
  switch (ctype) {
    case 'participation':
      color = value >= 0.9 ? GREEN : value > 0.6 ? ORANGE : RED;
      break;

    // all 'poor', 'moderate', 'good', 'up', 'down', 'low' 'high' cases are for the default dropdown panel
    case 'participationLow':
      color = value >= 0 && value <= 0.6 ? '#F02F2F' : GRAY;
      break;

    case 'participationModerate':
      color = value > 0.6 && value < 0.9 ? ORANGE : GRAY;
      break;

    case 'participationHigh':
      color = value >= 0.9 ? '#27AE5F' : GRAY;
      break;

    case 'incidents':
      color = value > 0 ? '#F02F2F' : '#27AE5F';
      break;

    case 'sleep':
      color = value >= 7 ? GREEN : value >= 6 ? ORANGE : RED;
      break;

    case 'sleepPoor':
      color = value >= 0 && value < 6 ? '#F02F2F' : GRAY;
      break;

    case 'sleepModerate':
      color = value >= 6 && value < 7 ? ORANGE : GRAY;
      break;

    case 'sleepGood':
      color = value >= 7 ? '#27AE5F' : GRAY;
      break;

    case 'stress':
      color = value > 75 ? RED : value > 50 ? ORANGE : value > 26 ? '#39D479' : GREEN;
      break;

    case 'stressResting':
      color = value >= 0 && value < 26 ? '#27AE5F' : GRAY;
      break;

    case 'stressLow':
      color = value >= 26 && value < 51 ? '#39D479' : GRAY;
      break;

    case 'stressModerate':
      color = value >= 51 && value < 76 ? '#F2994A' : GRAY;
      break;

    case 'stressHigh':
      color = value >= 76 ? '#F02F2F' : GRAY;
      break;

    case 'emotion':
    case 'emotions':
      color = value >= -0.1 ? GREEN : value > -0.5 ? ORANGE : RED;
      break;

    case 'emotionUp':
      color = value >= 0 ? '#27AE5F' : GRAY;
      break;

    case 'emotionDown':
      color = value < 0 && value > -11 ? '#F02F2F' : GRAY;
      break;

    case 'watchuse':
      color = value < 18 ? RED : value < 20 ? ORANGE : GREEN;
      break;

    case 'watchUsePoor':
      color = value >= 0 && value < 5 ? '#F02F2F' : GRAY;
      break;

    case 'watchUseModerate':
      color = value >= 6 && value <= 8 ? ORANGE : GRAY;
      break;

    case 'watchUseGood':
      color = value >= 9 ? '#27AE5F' : GRAY;
      break;

    case 'supportRequests':
      color = value > 0 ? '#F02F2F' : '#27AE5F';
      break;
  }

  return color;
};

export const metricText = (ctype: string, value: number, synced: boolean): string => {
  let txt = '--';

  switch (ctype) {
    case 'participation':
      value = synced && value >= 0 ? Math.round(value) : 0;
      txt = value.toString() + ' day';
      txt += value === 1 ? 's' : '';
      break;

    case 'incidents':
      value = synced && value >= 0 ? Math.round(value) : 0;
      txt = value.toString() + ' event';
      txt += value === 1 ? 's' : '';
      break;

    case 'sleep':
      if (synced && value >= 0) {
        value = Math.round(value);
        txt = value.toString() + ' hour';
        txt += value === 1 ? 's' : '';
      }
      break;

    case 'stress':
      if (synced && value >= 0) {
        txt = value < 26 ? 'Resting' : value < 51 ? 'Low' : value < 76 ? 'Medium' : 'High';
      }
      break;

    case 'emotion':
    case 'emotions':
      break;

    case 'craving':
      if (synced && value >= 0) {
        value = value > 0.9 ? 4 : value > 0.6 ? 3 : value > 0.4 ? 2 : 1;
        txt = 'Level ' + value.toString();
      }
      break;

    case 'watchuse':
      value = synced && value >= 0 ? Math.round(value) : 0;
      txt = value.toString() + ' hour';
      txt += value === 1 ? 's' : '';
      break;
  }

  return txt;
};

export const emotionArrowIcon = (dataType: string, value: number, recentSync: boolean): string => {
  if (dataType !== 'emotion' || value < -10 || !recentSync) {
    return '';
  } else if (value < -4) {
    return 'arrow-down';
  } else if (value < 4) {
    return 'arrow-right';
  } else {
    return 'arrow-up';
  }
};

export const syncWithinTime = (dataType: string, lastSyncTime: Date): boolean => {
  const cutoff = new Date();
  cutoff.setHours(0, 0, 0, 0);

  switch (dataType) {
    case 'participation':
      cutoff.setDate(cutoff.getDate() - 1);
      break;
    case 'sleep':
      cutoff.setDate(cutoff.getDate() - 2);
      break;
    case 'incidents':
      return true;
  }

  if (lastSyncTime && lastSyncTime < cutoff) {
    return false;
  }
  return true;
};
