import Firestore, { FirestoreUtils } from '../modules/firestore/Firestore';
import { IDataPoint } from '../modules/vitals/interfaces/IVitalsGraph';
import addDays from 'date-fns/addDays';

export const getCravingPredictions = async (userId: string | null): Promise<IDataPoint[]> => {
  if (userId === null) {
    return [];
  }

  const cutOffTime = addDays(new Date(), -8);

  const predictionDocuments = await Firestore.collection('predictions')
    .where('pir', '==', FirestoreUtils.getDocRef('users', userId))
    .where('isDataSufficient', '==', true)
    .where('endTime', '>', cutOffTime)
    .get();
  return predictionDocuments.docs.map((prediction) => ({
    booleanValue: prediction.get('is_craving') ?? false,
    dateTime: prediction.get('endTime')?.toDate() ?? new Date(),
    value: prediction.get('proba') ?? 0,
  }));
};
