import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  Flex,
  Link,
  Text,
  Box,
} from '@chakra-ui/react';
import FAIcon from '../FAIcon';
import AddMedicationForm from './AddMedicationForm';
import MedicationDisplay from './MedicationDisplay';
import IMedication from '../../modules/medications/interfaces/IMedication';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
}

type Props = SuppliedProps;

const MedicationModal = (props: Props & PropsFromRedux): React.ReactElement => {
  const { t } = useTranslation('medications');
  const { isOpen, onClose, hasOnboarded } = props;
  const [selectedMedication, setSelectedMedication] = useState<IMedication | null>(null);
  const { isPhone } = useDevice();

  const handleModalClose = () => {
    setSelectedMedication(null);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose} scrollBehavior="inside" isCentered={true}>
      <ModalOverlay />
      <ModalContent
        maxWidth="700px"
        minHeight={hasOnboarded ? undefined : '90%'}
        marginBottom={!hasOnboarded || isPhone ? [0, 0, 0] : undefined}
        marginTop={!hasOnboarded || isPhone ? [0, 0, 0] : undefined}
        p={[2, 2, 4, 8]}
        zIndex={1900}
      >
        <ModalHeader fontWeight="light" color="purple3.600" fontSize={24} textAlign="center" px={[1, 1, 6]}>
          <FAIcon icon="mortar-pestle" mr={4} />
          {!hasOnboarded ? t('medicationModal.headerOnboard') : t('medicationModal.header')}
        </ModalHeader>
        {hasOnboarded && <ModalCloseButton />}
        <ModalBody>
          <Box w="50%" mb={5}>
            <Text fontSize={18} mb={5}>
              {t('medicationModal.subheaderMed')}
            </Text>
          </Box>
          <Flex direction="column" alignItems="left">
            <MedicationDisplay onSelectMedication={(medication) => setSelectedMedication(medication)} />
            <AddMedicationForm
              selectedMedication={selectedMedication}
              onSelectMedication={(medication) => setSelectedMedication(medication)}
              setSelectedMedication={setSelectedMedication}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          {!hasOnboarded && (
            <Link onClick={onClose} fontSize={14} mt={2} mr={4}>
              {t('medicationModal.skip')}
            </Link>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    hasOnboarded: state.onboarding.hasOnboarded,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MedicationModal);

// comment to merge
