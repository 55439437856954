import { Action } from 'redux';
import firebase from 'firebase/compat/app';
import AppThunk from '../../redux/interfaces/AppThunk';
import Firestore, { FirestoreUtils } from '../firestore/Firestore';
import IMotivator from './interfaces/IMotivator';
import { formatMotivatorFromFirestore, formatMotivatorToFirestore } from './utils';

export enum MotivatorActionTypes {
  SET_MOTIVATOR = 'SET_MOTIVATOR',
  GETTING_MOTIVATOR = 'GETTING_MOTIVATOR',
  GOT_MOTIVATOR = 'GOT_MOTIVATOR',
  SET_MOTIVATOR_UNSUB = 'SET_MOTIVATOR_UNSUB',
  CREATED_MOTIVATOR = 'CREATED_MOTIVATOR',
  CREATING_MOTIVATOR = 'CREATING_MOTIVATOR',
  DELETING_MOTIVATOR = 'DELETING_MOTIVATOR',
  DELETED_MOTIVATOR = 'DELETED_MOTIVATOR',
  UPDATING_MOTIVATOR = 'UPDATING_MOTIVATOR',
  UPDATED_MOTIVATOR = 'UPDATED_MOTIVATOR',
  SELECT_MOTIVATOR_TO_UPDATE = 'SELECT_MOTIVATOR_TO_UPDATE',
}

export type MotivatorActions =
  | SetMotivatorAction
  | GettingMotivatorAction
  | GotMotivatorAction
  | SetMotivatorUnsubAction
  | CreatingMotivatorAction
  | CreatedMotivatorAction
  | DeletingMotivatorAction
  | DeletedMotivatorAction
  | UpdatingMotivatorAction
  | UpdatedMotivatorAction
  | SelectToUpdateMotivatorAction;

export interface SelectToUpdateMotivatorAction extends Action<MotivatorActionTypes.SELECT_MOTIVATOR_TO_UPDATE> {
  motivator: IMotivator;
}
export const selectMotivator = (motivator: IMotivator): SelectToUpdateMotivatorAction => ({
  type: MotivatorActionTypes.SELECT_MOTIVATOR_TO_UPDATE,
  motivator,
});

export interface SetMotivatorAction extends Action<MotivatorActionTypes.SET_MOTIVATOR> {
  motivator: null | IMotivator[];
}

export const setMotivator = (motivator: null | IMotivator[]): SetMotivatorAction => ({
  type: MotivatorActionTypes.SET_MOTIVATOR,
  motivator,
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GettingMotivatorAction extends Action<MotivatorActionTypes.GETTING_MOTIVATOR> {}

export const gettingMotivator = (): GettingMotivatorAction => ({ type: MotivatorActionTypes.GETTING_MOTIVATOR });

export interface GotMotivatorAction extends Action<MotivatorActionTypes.GOT_MOTIVATOR> {
  motivator: IMotivator[];
}

export const gotMotivator = (motivator: IMotivator[]): GotMotivatorAction => {
  return {
    type: MotivatorActionTypes.GOT_MOTIVATOR,
    motivator,
  };
};

export const getMotivator = (pirId: string): AppThunk<Promise<GotMotivatorAction>, MotivatorActions> => {
  return async (dispatch) => {
    dispatch(gettingMotivator());

    return new Promise<IMotivator[]>((resolve, reject) => {
      const unsubFn = Firestore.collection('motivator')
        .where('pir', '==', FirestoreUtils.getDocRef('users', pirId))
        .onSnapshot(
          (snapshot) => {
            const motivator = snapshot.docs.map((snapshot) => formatMotivatorFromFirestore(snapshot));
            dispatch(setMotivator(motivator));
            resolve(motivator);
          },
          (error) => {
            reject(error);
          },
        );
      dispatch(setMotivatorUnsub(unsubFn));
    }).then((motivator) => {
      return dispatch(gotMotivator(motivator));
    });
  };
};

export interface SetMotivatorUnsubAction extends Action<MotivatorActionTypes.SET_MOTIVATOR_UNSUB> {
  unsubFn: null | firebase.Unsubscribe;
}

export const setMotivatorUnsub = (unsubFn: null | firebase.Unsubscribe): SetMotivatorUnsubAction => ({
  type: MotivatorActionTypes.SET_MOTIVATOR_UNSUB,
  unsubFn,
});

export const stopMotivatorListener = (): AppThunk<SetMotivatorUnsubAction, SetMotivatorUnsubAction> => {
  return (dispatch, getState) => {
    const unsubFn = getState().motivator.unsubFn;
    if (unsubFn) {
      unsubFn();
    }
    return dispatch(setMotivatorUnsub(null));
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreatingMotivatorAction extends Action<MotivatorActionTypes.CREATING_MOTIVATOR> {}

export const creatingMotivator = (): CreatingMotivatorAction => ({ type: MotivatorActionTypes.CREATING_MOTIVATOR });

export interface CreatedMotivatorAction extends Action<MotivatorActionTypes.CREATED_MOTIVATOR> {
  motivator: IMotivator;
}

export const createdMotivator = (motivator: IMotivator): CreatedMotivatorAction => ({
  type: MotivatorActionTypes.CREATED_MOTIVATOR,
  motivator,
});

export const createMotivator = (
  rawMotivator: Omit<IMotivator, 'id'>,
): AppThunk<Promise<CreatedMotivatorAction>, MotivatorActions> => {
  return async (dispatch) => {
    dispatch(creatingMotivator());

    const motivator = formatMotivatorToFirestore(rawMotivator);
    const motivatorRef = Firestore.collection('motivator').doc();
   await motivatorRef.set(motivator);
    return dispatch(
      createdMotivator({
        ...motivator,
        id: motivatorRef.id,
      }),
    );
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeletingMotivatorAction extends Action<MotivatorActionTypes.DELETING_MOTIVATOR> {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeletedMotivatorAction extends Action<MotivatorActionTypes.DELETED_MOTIVATOR> {}

export const deleteMotivator = (motivator: IMotivator): AppThunk<Promise<DeletedMotivatorAction>, MotivatorActions> => {
  return async (dispatch) => {
    dispatch({ type: MotivatorActionTypes.DELETING_MOTIVATOR });
    await Firestore.collection('motivator').doc(motivator.id).delete();
    return dispatch({ type: MotivatorActionTypes.DELETED_MOTIVATOR });
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdatingMotivatorAction extends Action<MotivatorActionTypes.UPDATING_MOTIVATOR> {}

export interface UpdatedMotivatorAction extends Action<MotivatorActionTypes.UPDATED_MOTIVATOR> {
  motivator: IMotivator;
}

export const updateMotivator = (motivator: IMotivator): AppThunk<Promise<UpdatedMotivatorAction>, MotivatorActions> => {
  return async (dispatch) => {
    dispatch({ type: MotivatorActionTypes.UPDATING_MOTIVATOR });
    await Firestore.collection('motivator').doc(motivator.id).set(formatMotivatorToFirestore(motivator));
    return dispatch({
      type: MotivatorActionTypes.UPDATED_MOTIVATOR,
      motivator,
    });
  };
};
