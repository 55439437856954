import React, { useState, useEffect, useRef, useContext } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormErrorMessage,
  Text,
  FormLabel,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialog,
  Input,
} from '@chakra-ui/react';
import IUser from '../../modules/user/interfaces/IUser';
import PrimaryButton from '../PrimaryButton';
import TertiaryButton from '../TertiaryButton';
import { updatePartialUser } from 'src/modules/user/actions';
import ToggleSwitch from './ToggleSwitch';
import { formatPhone } from '../../modules/user/utils';
import GetFcmToken from 'src/modules/user/GetFcmToken';
import { ResumeContext } from 'src/modules/interior/Index';
import { useDevice } from 'src/DeviceContext';
import { ErrorSpan } from '../ErrorSpan';

type SuppliedProps = {
  key: string;
  user: IUser;
  onSubmit: (data: Record<string, any>) => Promise<void>;
  setNotificationsIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  hasOnboarded: boolean;
  abbreviatedSettingsInterface?: boolean;
  isPir: boolean;
};

const isThisAndroid = (): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /android/.test(userAgent);
};

type Props = SuppliedProps & PropsFromRedux;

type FormInputs = {
  doesWantSms: boolean;
  doesWantPush: boolean | undefined;
  doesWantEmail: boolean;
  phoneNumber?: string;
};

const NotificationPreferencesForm = (props: Props): JSX.Element => {
  const {
    user,
    onSubmit,
    updatePartialUser,
    setNotificationsIsDirty,
    hasOnboarded,
    isPir,
    abbreviatedSettingsInterface,
  } = props;
  const { t } = useTranslation('common');
  const { handleSubmit, register, formState, getValues, setValue, reset, control, clearErrors, watch } =
    useForm<FormInputs>({
      defaultValues: {
        doesWantSms: user.doesWantSms || false,
        doesWantPush: user.doesWantPush,
        doesWantEmail: user.doesWantEmail || false,
        phoneNumber: user.phone || '',
      },
    });
  const { appResumeCount } = useContext(ResumeContext);
  const canEnablePush = window.cordova;
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [hasValidPhoneNumber, setHasValidPhoneNumber] = useState(!!user.phone && user.stoppedSms !== true);
  const [triedToEnablePush, setTriedToEnablePush] = useState(false);

  const { isDirty } = formState;
  const { errors } = formState;
  const watchedDoesWantPush = watch('doesWantPush');
  const watchedDoesWantSms = watch('doesWantSms');
  const watchedPhoneNumber = abbreviatedSettingsInterface ? watch('phoneNumber') : null;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const cancelRef = useRef(null);
  const isAndroid = isThisAndroid();
  const [noNotificationModeError, setNoNotificationModeError] = useState(false);
  const [shouldShowGetFcmToken, setShouldShowGetFcmToken] = useState(false);
  const { isCordova } = useDevice();

  useEffect(() => {
    // if doesWantPush is false and they tried to enable push via phone settings, see if they successfully turned it on in settings
    if (isCordova && triedToEnablePush && !watchedDoesWantPush) {
      cordova.plugins.diagnostic.isRemoteNotificationsEnabled(
        function (enabled: boolean) {
          if (enabled) {
            setTriedToEnablePush(false);
            setValue('doesWantPush', true);
          }
        },
        function (error: any) {
          console.error('Error checking if push notifications are enabled: ' + error);
        },
      );
    }
    return () => {
      setTriedToEnablePush(false);
    };
  }, [appResumeCount]);

  useEffect(() => {
    if (errors.doesWantEmail) {
      setNoNotificationModeError(true);
    }
    return () => {
      setNoNotificationModeError(false);
    };
  }, [errors.doesWantEmail]);

  useEffect(() => {
    if (user.phone && user.stoppedSms !== true) {
      setHasValidPhoneNumber(true);
    } else if (!watchedPhoneNumber) {
      if (getValues('doesWantSms') === true) setValue('doesWantSms', false);
      setHasValidPhoneNumber(false);
    } else {
      const pattern = /[0-9]{10}/;
      const numberIsValid = pattern.test(watchedPhoneNumber);
      if (!numberIsValid && getValues('doesWantSms') === true) setValue('doesWantSms', false);
      setHasValidPhoneNumber(numberIsValid);
    }
    return () => {
      setHasValidPhoneNumber(!!user.phone);
    };
  }, [hasOnboarded, user.phone, watchedPhoneNumber, watchedDoesWantSms, hasValidPhoneNumber]);

  useEffect(() => {
    setNotificationsIsDirty(isDirty);
  }, [isDirty]);

  // all CPs are initially set up with email notifications
  useEffect(() => {
    if (!hasOnboarded && !isPir && !user.doesWantPush && !user.doesWantSms && !user.doesWantEmail) {
      const setDefaultNotifications = async () => {
        await updatePartialUser(user.id, { doesWantEmail: true });
      };

      setDefaultNotifications();
    }
  }, []);

  useEffect(() => {
    if ((errors.doesWantEmail && watchedDoesWantPush) || watchedDoesWantSms) {
      clearErrors('doesWantEmail');
    }
  }, [watchedDoesWantPush, watchedDoesWantSms]);

  const goToNotificationSettings = () => {
    setAlertDialogOpen(false);
    setTriedToEnablePush(true);
    if (isAndroid) {
      cordova.plugins.diagnostic.switchToNotificationSettings(
        function () {
          console.log('Successfully switched to android settings');
        },
        function (error: any) {
          console.error('Error switching to android settings: ' + error);
        },
      );
    } else {
      cordova.plugins.diagnostic.switchToSettings(
        function () {
          console.log('Successfully switched to iOS settings');
        },
        function (error: any) {
          console.error('Error switching to iOS settings: ' + error);
        },
      );
    }
  };

  const checkNotificationSettingsAndUpdatePush = () => {
    const diagnostic = cordova.plugins.diagnostic;
    if (isAndroid) {
      diagnostic.isRemoteNotificationsEnabled(function (notificationsNotBlocked: boolean) {
        if (notificationsNotBlocked) {
          setShouldShowGetFcmToken(true);
        } else {
          setValue('doesWantPush', false);
          setAlertDialogOpen(true);
        }
      });
    } else {
      diagnostic.getRemoteNotificationsAuthorizationStatus(
        function (status: PermissionStatus) {
          switch (status) {
            case diagnostic.permissionStatus.DENIED_ALWAYS:
              setValue('doesWantPush', false);
              setAlertDialogOpen(true);
              break;
            case diagnostic.permissionStatus.NOT_REQUESTED:
              setShouldShowGetFcmToken(true);
              break;
            default:
              break;
          }
        },
        function (error: any) {
          console.error('Error getting notification auth status: ' + error);
          setValue('doesWantPush', false);
        },
      );
    }
  };

  const handleCloseGetFcmToken = (didEnablePush: boolean) => {
    setValue('doesWantPush', didEnablePush);
    setShouldShowGetFcmToken(false);
  };

  const resetForm = () => {
    reset({
      doesWantSms: user.doesWantSms || false,
      doesWantPush: user.doesWantPush,
      doesWantEmail: user.doesWantEmail || false,
      phoneNumber: user.phone || '',
    });
    setHasValidPhoneNumber(!!user.phone && user.stoppedSms !== true);
    setTriedToEnablePush(false);
    setNoNotificationModeError(false);
  };

  return (
    <Box p={4} bg="grey6.50" borderRadius={8}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          {abbreviatedSettingsInterface && (
            <Box display="flex">
              <Flex flexDirection={'column'} width="100%">
                <Flex flexDirection={'row'} justifyContent="space-between" alignItems="center" mb={2}>
                  <Text fontWeight="500" fontSize={13} mb={4}>
                    {t('manageUserInfoModal.name')}
                  </Text>
                  <Text fontWeight="normal" fontSize={13}>
                    {user.name}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} justifyContent="space-between" alignItems="center">
                  <Text fontWeight="500" fontSize={13}>
                    {t('manageUserInfoModal.email')}
                  </Text>
                  <Text fontWeight="normal" fontSize={13}>
                    {user.email}
                  </Text>
                </Flex>
                {user.phone && (
                  <Flex flexDirection={'row'} justifyContent="space-between" alignItems="center" mt={2}>
                    <Text fontWeight="500" fontSize={13}>
                      {t('manageUserInfoModal.phone')}
                    </Text>
                    <Text fontWeight="normal" fontSize={13}>
                      {formatPhone(user.phone)}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Box>
          )}

          <FormControl display="flex" alignItems="center" isInvalid={!!errors.doesWantEmail || !!errors.phoneNumber}>
            <Flex flexDirection={'column'} width="100%">
              {abbreviatedSettingsInterface && !user.phone && (
                <Box>
                  {/* Phone field */}
                  <Flex flexDirection={'row'} justifyContent="space-between" alignItems="center">
                    <Flex flexDirection={'column'} width="100%">
                      <FormLabel htmlFor="phoneNumber" mb="0" fontSize={13} fontWeight="500" color="black" mt={2}>
                        {t('manageUserInfoModal.notificationPreferences.onboarding.phoneNumberLabel')}
                      </FormLabel>

                      <FormLabel fontSize={12} fontWeight={'normal'} fontStyle="italic">
                        {t('manageUserInfoModal.notificationPreferences.onboarding.phoneNumberDescription')}
                      </FormLabel>
                    </Flex>
                    <Controller
                      control={control}
                      name="phoneNumber"
                      render={({ field }: { field: ControllerRenderProps<FormInputs, 'phoneNumber'> }) => (
                        <Input
                          backgroundColor={'white'}
                          border={'1px solid purple3.600'}
                          borderRadius={'2px'}
                          padding={'4px 6px'}
                          fontSize={'16px'}
                          focusBorderColor={'purple3.600'}
                          width={'300px'}
                          value={field.value}
                          {...register('phoneNumber', {
                            pattern: {
                              value: /[0-9]{10}/,
                              message: t('manageContactModal.pir.errors.phoneInvalid'),
                            },
                            maxLength: {
                              value: 10,
                              message: t('manageContactModal.pir.errors.phoneInvalid'),
                            },
                          })}
                          id="phoneNumber"
                          name={field.name}
                        />
                      )}
                    />
                  </Flex>
                </Box>
              )}
              <Flex flexDirection={'row'} justifyContent="space-between" alignItems="center">
                <FormLabel htmlFor="doesWantEmail" mb="0" fontSize={13} fontWeight="normal" color="black" mt={2}>
                  {t('manageUserInfoModal.notificationPreferences.email')}
                </FormLabel>
                <Controller
                  control={control}
                  name="doesWantEmail"
                  render={({ field }: { field: ControllerRenderProps<FormInputs, 'doesWantEmail'> }) => (
                    <ToggleSwitch
                      {...register('doesWantEmail', {
                        validate: {
                          somethingIsOn: () =>
                            getValues('doesWantPush') === true ||
                            getValues('doesWantEmail') === true ||
                            getValues('doesWantSms') === true ||
                            t('manageUserInfoModal.notificationPreferences.errors.mustEnableSomething'),
                        },
                      })}
                      id="doesWantEmail"
                      checked={field.value}
                      name={field.name}
                    />
                  )}
                />
              </Flex>
              <ErrorSpan>{null}</ErrorSpan>
            </Flex>
          </FormControl>
        </Box>

        <Box>
          <FormControl display="flex" alignItems="center" isInvalid={!!errors.doesWantSms}>
            <Flex flexDirection={'column'} width="100%">
              <Flex justifyContent="space-between" flexDirection={'row'} alignItems="center" width="100%">
                <FormLabel
                  htmlFor="doesWantSms"
                  mb="0"
                  fontSize={13}
                  fontWeight="normal"
                  color={hasValidPhoneNumber || user.doesWantSms ? 'black' : 'grey6.500'}
                  mt={2}
                >
                  {t('manageUserInfoModal.notificationPreferences.text')}
                </FormLabel>
                <Controller
                  control={control}
                  name="doesWantSms"
                  render={({ field }: { field: ControllerRenderProps<FormInputs, 'doesWantSms'> }) => (
                    <ToggleSwitch
                      disabled={!hasValidPhoneNumber && !user.doesWantSms}
                      {...register('doesWantSms')}
                      id="doesWantSms"
                      checked={field.value}
                      name={field.name}
                    />
                  )}
                />
              </Flex>
              {errors.doesWantSms ? (
                <FormErrorMessage>{errors.doesWantSms.message}</FormErrorMessage>
              ) : hasValidPhoneNumber || user.doesWantSms ? (
                <ErrorSpan>{null}</ErrorSpan>
              ) : (
                <Text
                  color="purple3.600"
                  style={{
                    display: 'block',
                    height: '14.5px',
                    fontSize: '13px',
                    lineHeight: 'normal',
                    marginTop: '2px',
                  }}
                >
                  {user.stoppedSms
                    ? t('manageUserInfoModal.notificationPreferences.mustTextStart', {
                        twilioNumber: process.env.REACT_APP_TWILIO_NUMBER,
                      })
                    : t('manageUserInfoModal.notificationPreferences.mustAddPhoneNumberForSms')}
                </Text>
              )}
            </Flex>
          </FormControl>
        </Box>

        <Box>
          <FormControl display="flex" alignItems="center" isInvalid={!!errors.doesWantPush}>
            <Flex flexDirection={'column'} width="100%">
              <Flex justifyContent="space-between" alignItems="center" width="100%">
                <FormLabel
                  htmlFor="doesWantPush"
                  mb="0"
                  fontSize={13}
                  fontWeight="normal"
                  color={canEnablePush || (user.doesWantPush && getValues('doesWantPush')) ? 'black' : 'grey6.500'}
                  mt={2}
                >
                  {t('manageUserInfoModal.notificationPreferences.push')}
                </FormLabel>
                <Controller
                  control={control}
                  name="doesWantPush"
                  render={({ field }: { field: ControllerRenderProps<FormInputs, 'doesWantPush'> }) => {
                    const handleToggleChange = (checked: boolean) => {
                      if (field.value === undefined && checked) {
                        field.onChange(true);
                        setValue('doesWantPush', true);
                      } else if (field.value !== undefined) {
                        field.onChange(checked);
                        setValue('doesWantPush', checked);
                      }
                      if (checked) {
                        checkNotificationSettingsAndUpdatePush();
                      }
                    };
                    return (
                      <ToggleSwitch
                        disabled={!canEnablePush && getValues('doesWantPush') !== true}
                        id="doesWantPush"
                        checked={field.value === true}
                        name={field.name}
                        onChange={(event) => {
                          handleToggleChange(event.target.checked);
                        }}
                      />
                    );
                  }}
                />
              </Flex>
              {errors.doesWantPush ? (
                <FormErrorMessage>{errors.doesWantPush.message}</FormErrorMessage>
              ) : canEnablePush || (user.doesWantPush && getValues('doesWantPush')) ? (
                <ErrorSpan>{null}</ErrorSpan>
              ) : (
                <Text
                  color="purple3.600"
                  style={{
                    display: 'block',
                    height: '14.5px',
                    fontSize: '13px',
                    lineHeight: 'normal',
                    marginTop: '2px',
                  }}
                >
                  {t('manageUserInfoModal.notificationPreferences.pushNotFromMobile')}
                </Text>
              )}
            </Flex>
          </FormControl>
        </Box>
        <Flex></Flex>

        {/* Action buttons */}
        <Flex flexDirection={'column'} width="100%">
          {noNotificationModeError && (
            <ErrorSpan>{t('manageUserInfoModal.notificationPreferences.errors.mustEnableSomething')}</ErrorSpan>
          )}
          <Flex justifyContent="space-between" w="100%">
            <TertiaryButton disabled={!isDirty} variant="link" onClick={resetForm}>
              Cancel
            </TertiaryButton>
            <PrimaryButton disabled={!isDirty} type="submit" isLoading={formState.isSubmitting}>
              {t('manageContactModal.supporterForm.saveButton')}
            </PrimaryButton>
          </Flex>
        </Flex>
      </form>

      {shouldShowGetFcmToken && (
        <GetFcmToken isPir={isPir} onCompleted={(didEnablePush: boolean) => handleCloseGetFcmToken(didEnablePush)} />
      )}

      {/* Alert dialog for if user has previously disabled iOS notifications */}
      <AlertDialog isOpen={alertDialogOpen} leastDestructiveRef={cancelRef} onClose={() => setAlertDialogOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader color="purple3.600" fontWeight="light" fontSize={24}>
              {t('manageUserInfoModal.notificationPreferences.alertDialog.header')}
            </AlertDialogHeader>
            <AlertDialogBody>{t('manageUserInfoModal.notificationPreferences.alertDialog.body')}</AlertDialogBody>
            <AlertDialogFooter display="flex" justifyContent="space-between">
              <PrimaryButton onClick={() => goToNotificationSettings()}>Take me to settings</PrimaryButton>
              <Button ref={cancelRef as any} onClick={() => setAlertDialogOpen(false)}>
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

const mapDispatchToProps = {
  updatePartialUser,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NotificationPreferencesForm);
