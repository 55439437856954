import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Button, FormControl, FormErrorMessage, Textarea, FormLabel } from '@chakra-ui/react';
import IUser, { UserRole } from '../../modules/user/interfaces/IUser';
import emailPattern from '../../constants/emailPattern';
import PrimaryButton from '../PrimaryButton';
import Field from '../Field';

interface Props {
  user: IUser;
  onSubmit(data: Record<string, any>): Promise<void>;
  onCancel(): void;
  interactionCallback?(): void;
  onBlurCallback?(): void;
  submitError: string | undefined;
  setSubmitError: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const UserInfoForm = (props: Props): JSX.Element => {
  const { user, onSubmit, onCancel, interactionCallback, onBlurCallback, submitError } = props;
  const { t } = useTranslation('common');
  const { handleSubmit, register, formState } = useForm({
    defaultValues: {
      name: user.name,
      phone: user.phone,
      email: user.email,
      cpGeneralContactInfo: user.cpGeneralContactInfo,
    },
  });

  const { errors } = formState;

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        <FormControl isInvalid={submitError !== undefined} mb={2}>
          <FormErrorMessage>{submitError}</FormErrorMessage>
        </FormControl>
        {/* Name field */}
        <Field
          {...register('name', {
            maxLength: {
              value: 255,
              message: t('manageContactModal.pir.errors.nameMaxLength'),
            },
          })}
          name="name"
          label={t('manageContactModal.pir.nameLabel')}
          type="text"
          placeholder="Joe Somebody"
          error={errors.name}
          mb={4}
          onFocus={interactionCallback}
          onBlur={onBlurCallback}
        />

        {/* Phone field */}
        <Field
          {...register('phone', {
            pattern: {
              value: /[0-9]{10}/,
              message: t('manageContactModal.pir.errors.phoneInvalid'),
            },
            maxLength: {
              value: 10,
              message: t('manageContactModal.pir.errors.phoneInvalid'),
            },
            validate: (value: string | undefined) =>
              (value !== undefined && !!value) ||
              !user.doesWantSms ||
              t('manageContactModal.pir.errors.phoneRequiredIfDoesWantSmsOn'),
          })}
          name="phone"
          label={t('manageContactModal.pir.phoneLabel')}
          type="tel"
          placeholder="5551234567"
          error={errors.phone}
          mb={4}
          onFocus={interactionCallback}
          onBlur={onBlurCallback}
        />

        {/* Email field */}
        <Field
          {...register('email', {
            required: {
              value: true,
              message: t('manageContactModal.pir.errors.emailRequired'),
            },
            pattern: {
              value: emailPattern,
              message: t('manageContactModal.pir.errors.emailInvalid'),
            },
          })}
          name="email"
          label={t('manageContactModal.pir.emailLabel')}
          type="email"
          placeholder="nobody@example.com"
          error={errors.email}
          mb={4}
          onFocus={interactionCallback}
          onBlur={onBlurCallback}
        />

        {/* CP General Contact Info field */}
        {user.role === UserRole.CP && (
          <FormControl>
            <FormErrorMessage>{errors.cpGeneralContactInfo && errors.cpGeneralContactInfo.message}</FormErrorMessage>
            <FormLabel as="label" fontStyle="italic" fontSize={12} fontWeight="light">
              {t('manageUserInfoModal.cpGeneralContactInfo.label')}
            </FormLabel>
            <Textarea
              {...register('cpGeneralContactInfo', {
                maxLength: {
                  value: 300,
                  message: t('manageUserInfoModal.cpGeneralContactInfo.error'),
                },
              })}
              name="cpGeneralContactInfo"
              placeholder={t('manageUserInfoModal.cpGeneralContactInfo.placeholder')}
              mb={4}
              onFocus={interactionCallback}
              onBlur={onBlurCallback}
            />
          </FormControl>
        )}

        {/* Action buttons */}
        <Flex justifyContent="space-between" w="100%">
          <Button variant="link" onClick={onCancel}>
            Cancel
          </Button>
          <PrimaryButton type="submit" isLoading={formState.isSubmitting}>
            Save
          </PrimaryButton>
        </Flex>
      </form>
    </Box>
  );
};

export default UserInfoForm;
