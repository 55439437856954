import React from 'react';
import NarrowInterior from './NarrowInterior';
import PageHeading from './PageHeading';
import SecondaryBodyText from './SecondaryBodyText';

const InteriorError = (): JSX.Element => {
  return (
    <NarrowInterior>
      <PageHeading mb={4}>Something went wrong!</PageHeading>
      <SecondaryBodyText>Sorry, our engineers have been notified. Please reload the app.</SecondaryBodyText>
    </NarrowInterior>
  );
};

export default InteriorError;
