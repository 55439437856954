import { where, setDoc, addDoc, deleteDoc } from 'firebase/firestore';
import { documentRef, collectionRef, queryDocs } from '../modules/firebase/firestore';
import firebase from 'firebase/compat/app';
import {
  formatResourceFromFirestore,
  formatResourceToFirestore,
  ResourceToFirestore,
} from 'src/components/ManageResourceModal/utils';

export interface IResource {
  id: string;
  pir: firebase.firestore.DocumentReference;
  title: string;
  note?: string;
  link?: string;
  attachmentUrl?: string;
  attachmentName?: string;
  resourceType: string;
}

export const getResources = async (pirId: string): Promise<IResource[]> => {
  const docRef = documentRef('users', pirId);
  const querySnapshot = await queryDocs('resources', where('pir', '==', docRef));
  return querySnapshot.docs.map((doc) => formatResourceFromFirestore(doc));
};

export const createResource = async (rawResource: IResource | ResourceToFirestore): Promise<void> => {
  const resource = formatResourceToFirestore(rawResource);
  await addDoc(collectionRef('resources'), resource);
};

export const deleteResource = async (resource: IResource): Promise<void> => {
  await deleteDoc(documentRef('resources', resource.id));
};

export const updateResource = async (resource: IResource): Promise<void> => {
  await setDoc(documentRef('resources', resource.id), formatResourceToFirestore(resource));
};

/**
 * Add the resource type, which can be video, book, image, link, audio, or unknown
 * @param doc An existinging resource
 * @returns
 */
export const resourceType = (doc: IResource): string => {
  if (doc.resourceType) return doc.resourceType;

  if (doc.attachmentName && doc.attachmentName.length > 0) {
    const extension =
      doc.attachmentName.toLowerCase().substring(doc.attachmentName.lastIndexOf('.') + 1, doc.attachmentName.length) ||
      doc.attachmentName;
    if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
      return 'image';
    } else if (['mov', 'mpg', 'mpeg4', 'mp4', 'avi'].includes(extension)) {
      return 'video';
    } else if (['pdf'].includes(extension)) {
      return 'pdf';
    } else if (['txt'].includes(extension)) {
      return 'text';
    } else if (['m4a', 'flac', 'mp3', 'wav', 'wma', 'aac'].includes(extension)) {
      return 'audio';
    }
  } else if (doc.link && doc.link.length > 0) {
    const validVideoType = [
      'youtu.be',
      'youtube',
      'facebook',
      'soundcloud',
      'twitch',
      'vimeo',
      'mux',
      'streamable',
      'wistia',
      'dailymotion',
      'mixcloud',
      'vidyard',
    ];
    const link = doc.link;
    const checkValidVideo = validVideoType.find((video) => link.toLowerCase().indexOf(video) > -1);
    if (checkValidVideo) {
      return 'video';
    }
    return 'link';
  }

  // Conditional for if there is a note then add Note resource Type
  if(doc.note?.length){
    return "note"
  }


  return 'unknown';
};
