import React, { useEffect, useState } from 'react';
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Flex, FormControl, FormLabel, Text } from '@chakra-ui/react';
import IUser from '../../modules/user/interfaces/IUser';
import PrimaryButton from '../PrimaryButton';
import ToggleSwitch from './ToggleSwitch';
import moment from 'moment-timezone';
import Select, { CSSObjectWithLabel } from 'react-select';
import TertiaryButton from '../TertiaryButton';
import { defaultTimeZone } from '../ManageAppointmentModal/utils';

interface FormValues {
  automaticTimezone: boolean;
  selectedTimezone: { value: string; label: string };
}

interface Props {
  user: IUser;
  onSubmit(data: Record<string, any>): Promise<void>;
}

const TimeZoneInfo = (props: Props): JSX.Element => {
  const { user, onSubmit } = props;
  const { timezone = defaultTimeZone, manuallySetTimezone = false } = user;
  const { t } = useTranslation('common');
  const { handleSubmit, formState, setValue, control, reset, watch } = useForm({
    defaultValues: {
      automaticTimezone: !manuallySetTimezone,
      selectedTimezone: { value: timezone, label: timezone },
    },
  });
  const timezoneOptions = moment.tz.names();
  const selectOptions = timezoneOptions.map((tz) => ({ value: tz, label: tz }));
  const deviceTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const watchedAutomaticTimezone = watch('automaticTimezone', !manuallySetTimezone);
  const watchedTimezone = watch('selectedTimezone', { value: timezone, label: timezone });
  const { isDirty } = formState;
  const [formIsDirty, setFormIsDirty] = useState(false);

  useEffect(() => {
    if (watchedAutomaticTimezone) {
      setValue('selectedTimezone', { value: deviceTimezone, label: deviceTimezone });
    } else {
      setValue('selectedTimezone', { value: timezone, label: timezone });
    }
  }, [watchedAutomaticTimezone, setValue, deviceTimezone, timezone]);

  useEffect(() => {
    const dirty = isDirty || (manuallySetTimezone && watchedTimezone.value !== timezone);
    setFormIsDirty(dirty);
  }, [manuallySetTimezone, isDirty, watchedTimezone.value, timezone]);

  const onSubmitHandler = async (data: any) => {
    await onSubmit(data);
  };

  const handleToggleChange = (checked: boolean) => {
    setValue('automaticTimezone', checked);
    const newTimezone = checked ? deviceTimezone : timezone;
    setValue('selectedTimezone', { value: newTimezone, label: newTimezone });
  };

  const onCancel = () => {
    const zone = manuallySetTimezone ? timezone : deviceTimezone;

    reset({
      automaticTimezone: !manuallySetTimezone,
      selectedTimezone: { value: zone, label: zone },
    });
  };

  const handleSelectChange = (selectedOption: any) => {
    setValue('selectedTimezone', selectedOption);
  };

  const getTz = (zone: string) => {
    const options: {
      timeZone: string;
      timeZoneName: 'short' | 'long';
    } = {
      timeZone: zone,
      timeZoneName: 'long',
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const dateString = formatter.format(new Date());
    return dateString.split(',')[1].toString();
  };

  useEffect(() => {
    if (!user) return;
    const isAuto = !manuallySetTimezone;
    const zone = isAuto ? deviceTimezone : timezone;
    reset({
      automaticTimezone: isAuto,
      selectedTimezone: { value: zone, label: zone },
    });
    return () => {
      const isAuto = !manuallySetTimezone;
      const zone = isAuto ? deviceTimezone : timezone;
      reset({
        automaticTimezone: isAuto,
        selectedTimezone: { value: zone, label: zone },
      });
    };
  }, [user, timezone, manuallySetTimezone, reset, deviceTimezone]);

  return (
    <Box p={4} bg="grey6.50" borderRadius={8} mb={4}>
      <form onSubmit={handleSubmit(onSubmitHandler)} noValidate={true}>
        <FormControl display="flex" alignItems="center">
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <FormLabel htmlFor="timezone" mb="0" fontSize={13} fontWeight="normal" color="black">
              Set automatically
            </FormLabel>
            <Controller
              control={control}
              name="automaticTimezone"
              render={({ field }: { field: ControllerRenderProps<FormValues, 'automaticTimezone'> }) => (
                <ToggleSwitch
                  id="automaticTimezone"
                  onChange={(event) => {
                    handleToggleChange(event.target.checked);
                    field.onChange(event.target.checked);
                  }}
                  checked={field.value}
                  name={field.name}
                />
              )}
            />
          </Flex>
        </FormControl>

        <Flex justifyContent="space-between" alignItems="center" width="100%" fontSize={13} mt={2}>
          <Text>{t('manageContactModal.timezone.timezoneLabel')}</Text>
          {watchedTimezone.value && <Text>{getTz(watchedTimezone.value)}</Text>}
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" width="100%" fontSize={13} mt={2} mb={2}>
          {watchedAutomaticTimezone ? (
            <Text>{t('manageContactModal.timezone.locationLabel')}</Text>
          ) : (
            <Text>{t('manageContactModal.timezone.chooseLocation')}</Text>
          )}

          {!watchedAutomaticTimezone && (
            <Controller
              render={({ field }: { field: ControllerRenderProps<FormValues, 'selectedTimezone'> }) => (
                <Select
                  {...field}
                  id="timezone-selector"
                  onChange={handleSelectChange}
                  options={selectOptions}
                  isSearchable
                  value={selectOptions.find((option) => option.value === field.value.value)}
                  styles={{ control: (provided: CSSObjectWithLabel) => ({ ...provided, minWidth: 170 }) }}
                />
              )}
              name="selectedTimezone"
              control={control}
            />
          )}
          {watchedAutomaticTimezone && watchedTimezone && <Text>{watchedTimezone.value}</Text>}
        </Flex>

        {/* Action buttons */}
        <Flex justifyContent="space-between" w="100%">
          <TertiaryButton disabled={!formIsDirty} variant="link" onClick={onCancel}>
            Cancel
          </TertiaryButton>
          <PrimaryButton disabled={!formIsDirty} type="submit" isLoading={formState.isSubmitting}>
            Save
          </PrimaryButton>
        </Flex>
      </form>
    </Box>
  );
};

export default TimeZoneInfo;
