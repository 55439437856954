import firebase from 'firebase/compat';
import { InvitationActions, InvitationActionTypes } from './invtiationActions';
import { UserInvitation } from './userInvitation';

interface InvitationState {
  deletionLoading: string[];
  error?: string;
  loading: boolean;
  invitations: UserInvitation[];
  unsubscribeFromSnapshots?: firebase.Unsubscribe[];
}

const defaultState: InvitationState = {
  deletionLoading: [],
  loading: false,
  invitations: [],
};

const invitationReducer = (state: InvitationState = defaultState, action: InvitationActions): InvitationState => {
  switch (action.type) {
    case InvitationActionTypes.DELETING_INVITATION: {
      const invitationsBeingDeleted = [...state.deletionLoading];
      invitationsBeingDeleted.push(action.invitationId);

      return {
        ...state,
        deletionLoading: invitationsBeingDeleted,
      };
    }

    case InvitationActionTypes.DELETED_INVITATION: {
      const invitationsBeingDeleted = [...state.deletionLoading];
      delete invitationsBeingDeleted[invitationsBeingDeleted.indexOf(action.invitationId)];

      return {
        ...state,
        deletionLoading: invitationsBeingDeleted,
      };
    }

    case InvitationActionTypes.GETTING_INVITATIONS:
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case InvitationActionTypes.GOT_ERROR:
      return {
        ...state,
        error: 'invitations.list.error',
        invitations: [],
        loading: false,
      };

    case InvitationActionTypes.REGISTER_UNSUBSCRIBE_FUNCTIONS:
      return {
        ...state,
        unsubscribeFromSnapshots: action.unsubscribeFromSnapshots,
      };

    case InvitationActionTypes.SET_INVITATIONS:
      return {
        ...state,
        error: undefined,
        invitations: action.invitations,
        loading: false,
      };

    case InvitationActionTypes.STOP_INVITATION_LISTENER:
      return defaultState;

    default:
      return state;
  }
};

export default invitationReducer;
