import { useMutation, useQuery, useQueryClient, UseQueryResult, UseMutationResult } from 'react-query';
import { getMedications, createMedication, deleteMedication, updateMedication, addToMedCal } from '../../db/medications';
import IMedication, { IAddToMedCalArgs } from './interfaces/IMedication';

const KEY_NAME = 'medications';

export const useQueryMedications = (pirId: string): UseQueryResult<IMedication[], unknown> => {
  return useQuery([KEY_NAME, pirId], async () => getMedications(pirId), { staleTime: Infinity });
};

export const useCreateMedication = (pirId: string): UseMutationResult<void, unknown, Omit<IMedication, 'id'>> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (rawMedication: Omit<IMedication, 'id'>) => {
      return createMedication(rawMedication);
    },
    {
      onSuccess: async () => queryClient.invalidateQueries([KEY_NAME, pirId]),
    },
  );
};

export const useDeleteMedication = (pirId: string): UseMutationResult<void, unknown, IMedication> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (medication: IMedication) => {
      return deleteMedication(medication);
    },
    {
      onSuccess: async () => queryClient.invalidateQueries([KEY_NAME, pirId]),
    },
  );
};

export const useUpdateMedication = (pirId: string): UseMutationResult<void, unknown, IMedication> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (medication: IMedication) => {
      return updateMedication(medication);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_NAME, pirId]);
      },
    },
  );
};

export const useAddToMedCal = (pirId: string): UseMutationResult<void, unknown, IAddToMedCalArgs> => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ medication, newMedValues }: IAddToMedCalArgs) => {
      return addToMedCal(medication, newMedValues);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_NAME, pirId]);
      },
    },
  );
};
