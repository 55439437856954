import { Action } from 'redux';
import AppThunk from '../../redux/interfaces/AppThunk';
import Firestore, { FirestoreUtils } from '../firestore/Firestore';
import { FreeDateActionTypes, formatFreeDateFromFirestore } from './utils';
import IFreeDate from './interfaces/IFreeDate';

export type FreeDateActions = SetFreeDateAction;

export interface SetFreeDateAction extends Action<FreeDateActionTypes.SET_FREE_DATE> {
  freeDate: null | IFreeDate;
}

export const setFreeDate = (freeDate: null | IFreeDate): SetFreeDateAction => ({
  type: FreeDateActionTypes.SET_FREE_DATE,
  freeDate,
});

export const getFreeDate = (pirId: string): AppThunk<Promise<SetFreeDateAction>, FreeDateActions> => {
  return async (dispatch) => {
    return new Promise<IFreeDate | null>((resolve, reject) => {
      Firestore.collection('freeDate')
        .where('pir', '==', FirestoreUtils.getDocRef('users', pirId))
        .onSnapshot(
          (snapshot) => {
            resolve(snapshot.docs.length ? formatFreeDateFromFirestore(snapshot.docs[0]) : null);
          },
          (error) => {
            reject(error);
          },
        );
    }).then((freeDate) => {
      return dispatch(setFreeDate(freeDate));
    });
  };
};

export const createFreeDate = (
  freeDate: Omit<IFreeDate, 'id'>,
): AppThunk<Promise<SetFreeDateAction>, FreeDateActions> => {
  return async (dispatch) => {
    // Can add creating free date action
    const freeDateRef = Firestore.collection('freeDate').doc();
    await freeDateRef.set(freeDate);
    return dispatch(
      setFreeDate({
        ...freeDate,
        id: freeDateRef.id,
      }),
    );
  };
};

export const updateFreeDate = (freeDate: IFreeDate): AppThunk<Promise<SetFreeDateAction>, FreeDateActions> => {
  return async (dispatch) => {
    await Firestore.collection('freeDate').doc(freeDate.id).set(freeDate);
    return dispatch(
      setFreeDate({
        ...freeDate,
      }),
    );
  };
};
