import React from 'react';
import { useDevice } from 'src/DeviceContext';
import { Link } from '@chakra-ui/react';

interface Props {
  href: string;
  children: React.ReactNode;
}
const WebLinkNewWindow = ({ href, children }: Props): JSX.Element => {
  const { isCordova } = useDevice();
  if (isCordova) {
    return (
      <Link isExternal={true} onClick={() => window.cordova?.InAppBrowser.open(href, '_system', 'location=no')}>
        {children}
      </Link>
    );
  } else {
    return (
      <Link href={href} isExternal={true}>
        {children}
      </Link>
    );
  }
};

export default WebLinkNewWindow;
