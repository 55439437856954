import { Box, CircularProgress, Link, Text } from '@chakra-ui/react';
import React, { useContext, useMemo, useState } from 'react';
import { metricColor } from '../../../lib/colorThresholds';
import { randomId } from '../../../lib/utils';
import {
  generateTickLabelPositions,
  getGraphData,
  GraphDataReducerType,
  generateCravingTickLabelPositions,
  getCravingGraphData,
  findIsolatedPoints,
} from '../dataProcessing';
import { GraphSizeObj, IDataPoint } from '../interfaces/IVitalsGraph';
import CravingsGraph from './CravingsGraph';
import VitalsScatterGraph from './VitalsScatterGraph';
import { GraphType } from '../vitalsGraphOptions';
import { useTranslation } from 'react-i18next';
import { SelectedUserContext, UserContext } from '../../interior/Index';
import { IContextUser, UserRole } from '../../user/interfaces/IUser';
import { connect, ConnectedProps } from 'react-redux';
import { hideGraph, showGraph } from '../../user/actions';
import { Center } from '@chakra-ui/layout';
import { RootState } from '../../../redux/store';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  data: IDataPoint[];
  day: number;
  graphType: ReducableGraphType;
  showColor?: boolean;
  graphSize: GraphSizeObj;
  graphPadding: number;
  axisLabelPadding: number;
  canBeHidden: boolean;
}

type LevelTickValuesReference = { [key in GraphType]?: number[] };
const LEVEL_TICK_VALUES: LevelTickValuesReference = {
  participation: [0, 0.6, 0.9],
  // from https://support.garmin.com/en-CA/?faq=WT9BmhjacO4ZpxbCc0EKn9#:~:text=0%E2%80%9325%3A%20Resting%20state,76%E2%80%93100%3A%20High%20stress
  stress: [0, 26, 51, 76],
  craving: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22],
  // number of incidents
  incidents: [],
  // hours of sleep
  sleep: [0, 6, 7],
  // hours of watch usage per day
  watchuse: [0, 18, 20],
};

export type ReducableGraphType = Exclude<GraphType, 'emotion'>;

const reducersByGraphType: { [key in ReducableGraphType]: GraphDataReducerType } = {
  participation: 'max',
  sleep: 'max',
  stress: 'average',
  craving: 'average',
  incidents: 'sum',
  watchuse: 'sum',
};

const VitalsGraphs = ({
  data,
  day,
  graphType,
  graphSize,
  graphPadding,
  axisLabelPadding,
  canBeHidden,
  hideGraph,
  showGraph,
  hiddenGraphs,
}: SuppliedProps & PropsFromRedux): React.ReactElement => {
  // NOTE: Add in showColor
  const { selectedUser } = useContext(SelectedUserContext);
  const user = useContext<IContextUser | null>(UserContext);
  const { t } = useTranslation('vitals');
  const { isPhone } = useDevice();
  const reducer = reducersByGraphType[graphType];
  const graphPoints = useMemo(() => getGraphData(data, day, graphType, reducer), [data, day, graphType, reducer]);
  const cravingGraphPoints = useMemo(() => getCravingGraphData(data, day), [data, day]);
  const graphPointsBoundary = useMemo(
    () => getGraphData(data, day, graphType, reducer),
    [data, day, graphType, reducer],
  );
  const isolatedPoints = useMemo(() => findIsolatedPoints(graphPoints), [data]);
  const timeTickValues = useMemo(() => generateTickLabelPositions(data, day), [data, day, graphType]);
  const dayTickPositions = useMemo(() => generateCravingTickLabelPositions(), []);
  const fillId = useMemo(randomId, [graphType]);

  const isPir = (): boolean => user?.role === UserRole.USER ?? false;
  const isGraphHiddenForUser = (): boolean => hiddenGraphs.includes(graphType) ?? false;
  const isGraphHiddenForSelectedUser = (): boolean =>
    selectedUser?.preferences.hiddenGraphs.includes(graphType) ?? false;
  const getHideLinkText = (): string => {
    if (isPir()) {
      return isGraphHiddenForUser() ? t('vitals.showGraph.pir') : t('vitals.hideGraph.pir');
    } else {
      return isGraphHiddenForSelectedUser() ? t('vitals.showGraph.cp') : t('vitals.hideGraph.cp');
    }
  };
  const [hideLinkText, setHideLinkText] = useState(getHideLinkText());
  const [hideGraphLoading, setHideGraphLoading] = useState(false);

  const levelTickValues = LEVEL_TICK_VALUES[graphType] || [];

  const levelColors = useMemo(() => {
    const yPoints = graphPointsBoundary.map((point) => point.y).filter((y) => y !== null) as number[];

    if (yPoints.length) {
      const min = Math.min(...yPoints);
      const max = Math.max(...yPoints);

      // we don't actually want to incorporate level colors that our points don't reach
      const relevantLevels = levelTickValues.filter((level) => level <= max);

      const linearGradientOffset = (max - min) * 0.1;

      let previousLevelColor = '';
      return relevantLevels
        .map((level, i) => {
          const color = metricColor(graphType, level);
          const colors = [
            ...(i > 0
              ? [
                  {
                    offset: Math.round(Math.max(0, ((level - linearGradientOffset) * 100) / max)),
                    color: previousLevelColor,
                  },
                ]
              : []),
            { offset: Math.round(Math.min(100, ((level + (i > 0 ? linearGradientOffset : 0)) * 100) / max)), color },
          ];
          previousLevelColor = color;
          return colors;
        })
        .flat()
        .sort((a, b) => b.offset - a.offset);
    }
    return [];
  }, [graphPointsBoundary, levelTickValues, graphType]);

  function generateTickFormat(value: number) {
    switch (graphType) {
      case 'incidents':
      case 'sleep':
        return value;
      case 'craving':
        return isPhone && value === 0
          ? '12a'
          : value === 0
          ? '12 AM'
          : isPhone && value < 12
          ? `${value}a`
          : value < 12
          ? `${value} AM`
          : isPhone && value === 12
          ? '12p'
          : value === 12
          ? '12 PM'
          : isPhone
          ? `${value - 12}p`
          : `${value - 12} PM`;
      case 'stress':
        return levelTickValues.indexOf(value) + 1;
      case 'participation':
        return value * 100;
      default:
        return Math.round(value * 10) / 10;
    }
  }

  const levelAxisProps = {
    // sleep and incidents have an y-axis that adjusts to the range of points on a given graph (tickValues = [] controls that),
    // but if all the values on the graph are 0, it needs a graph range, so this sets it to default to 0 and 1
    // as the two points on the y-axis in that case
    tickValues:
      (graphType === 'sleep' || graphType === 'incidents') && graphPoints.every((point) => point.y === 0)
        ? [0, 1]
        : graphType === 'sleep'
        ? []
        : graphType === 'participation'
        ? [0, 0.3, 0.6, 0.9, 1]
        : levelTickValues,
    tickFormat: (t: number) => generateTickFormat(t),
  };

  const shouldHideGraphForLoggedInUser = (graph: GraphType): boolean => {
    return user?.role === UserRole.USER && hiddenGraphs.includes(graph);
  };

  const cravingGraph = (): React.ReactElement => {
    if (graphPoints.length === 0) {
      return (
        <Box w="100%" h="100%" p={2} minH="2xs" maxH={graphSize.height}>
          <Text color="#8F8F8F">{t('vitals.howMeasured.insufficientData.craving')}</Text>
        </Box>
      );
    }

    if (shouldHideGraphForLoggedInUser('craving')) {
      return (
        <Box w="100%" h="100%" p={2} minH="2xs" maxH={graphSize.height}>
          <Text color="#8F8F8F">{t('vitals.hiddenGraph')}</Text>
        </Box>
      );
    }

    return (
      <CravingsGraph
        axisLabelPadding={axisLabelPadding}
        data={cravingGraphPoints}
        graphPadding={graphPadding}
        graphSize={graphSize}
        levelAxisProps={levelAxisProps}
        levelTickValues={levelTickValues}
        timeTickValues={dayTickPositions}
        numberOfDaysDisplayed={day}
        fillElementId={fillId}
        graphType={graphType}
      />
    );
  };

  const graph =
    graphType !== 'craving' ? (
      <VitalsScatterGraph
        day={day}
        isolatedPoints={isolatedPoints}
        axisLabelPadding={axisLabelPadding}
        data={graphPoints}
        dataWithBoundaries={graphPointsBoundary}
        fillElementId={fillId}
        graphPadding={graphPadding}
        graphSize={graphSize}
        graphType={graphType}
        levelAxisProps={levelAxisProps}
        levelTickValues={levelTickValues}
        numberOfDaysDisplayed={day}
        timeTickValues={timeTickValues}
      />
    ) : (
      cravingGraph()
    );

  const showOrHideGraph = async (): Promise<void> => {
    setHideGraphLoading(true);
    if (isPir()) {
      if (shouldHideGraphForLoggedInUser(graphType)) {
        await showGraph(graphType);
        setHideLinkText(t('vitals.hideGraph.pir'));
      } else {
        await hideGraph(graphType);
        setHideLinkText(t('vitals.showGraph.pir'));
      }
    } else if (selectedUser) {
      if (isGraphHiddenForSelectedUser()) {
        await showGraph(graphType, selectedUser);
        setHideLinkText(t('vitals.hideGraph.cp'));
      } else {
        await hideGraph(graphType, selectedUser);
        setHideLinkText(t('vitals.showGraph.cp'));
      }
    }
    setHideGraphLoading(false);
  };

  return (
    <Box>
      <svg style={{ height: 0 }} viewBox={`0 0  ${graphSize.width}  ${graphSize.height}`}>
        <defs>
          <linearGradient id={fillId} gradientTransform="rotate(90)">
            {levelColors.map((color, i) => {
              return <stop key={i} offset={100 - color.offset + '%'} stopColor={color.color} />;
            })}
            ;
          </linearGradient>
        </defs>
      </svg>
      {graph}
      <Center>
        <Link
          px={2}
          py={4}
          textDecoration="underline"
          color="purple.600"
          fontFamily="roboto, sans-serif"
          aria-label={hideLinkText}
          hidden={!canBeHidden}
          onClick={async () => {
            if (!hideGraphLoading) {
              await showOrHideGraph();
            }
          }}
        >
          {hideLinkText}
        </Link>
      </Center>
      <CircularProgress size="16px" color="purple.600" isIndeterminate={true} hidden={!hideGraphLoading} />
    </Box>
  );
};

const mapStateToProps = (state: RootState) => {
  const { user } = state;
  return {
    hiddenGraphs: user.user?.preferences.hiddenGraphs ?? [],
  };
};

const mapDispatchToProps = {
  hideGraph,
  showGraph,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(VitalsGraphs);
