import { useMutation, useQuery, useQueryClient, UseQueryResult, UseMutationResult } from 'react-query';
import { ResourceToFirestore } from 'src/components/ManageResourceModal/utils';
import { getResources, createResource, deleteResource, updateResource } from '../../db/resources';
import { IResource } from '../../db/resources';

const KEY_NAME = 'resources';

export const useQueryResources = (pirId: string): UseQueryResult<IResource[], unknown> => {
  return useQuery([KEY_NAME, pirId], async () => getResources(pirId), { staleTime: Infinity });
};

export const useCreateResources = (
  pirId: string,
): UseMutationResult<void, unknown, Omit<IResource | ResourceToFirestore, 'id' | 'resourceType'>> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (rawResource: Omit<IResource | ResourceToFirestore, 'id' | 'resourceType'>) => {
      return createResource(rawResource);
    },
    {
      onSuccess: async () => queryClient.invalidateQueries([KEY_NAME, pirId]),
    },
  );
};

export const useDeleteResources = (pirId: string): UseMutationResult<void, unknown, IResource> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (resource: IResource) => {
      return deleteResource(resource);
    },
    {
      onSuccess: async () => queryClient.invalidateQueries([KEY_NAME, pirId]),
    },
  );
};

export const useUpdateResources = (pirId: string): UseMutationResult<void, unknown, IResource> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (resource: IResource) => {
      return updateResource(resource);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_NAME, pirId]);
      },
    },
  );
};
