import React from 'react';
import { Spinner, Flex } from '@chakra-ui/react';

type FileUploadProps = {
  onUpload(acceptedFile: FileList | null): void;
  accept: string;
  displaySpinner: boolean;
  multipleFiles: boolean;
};

/**
 *
 * @param onUpload onUpload(acceptedFile: FileList|null), function that runs when file(s) slected
 * @param accept A string of valid file formats to accept (e.g. "image/png, image/gif")
 * @param displaySpinner A boolean as to whether or not to display a spinner alongside the input
 * @param multipleFiles A boolean as to whether or not to allow multiple files to be selected by the user
 * @returns An input button which calls the onUpload function on the file chosen. Also can display a spinenr
 */

const UploadFile = (props: FileUploadProps): JSX.Element => {
  const { onUpload, accept, displaySpinner, multipleFiles } = props;

  return (
    <Flex alignSelf="center">
      {
        multipleFiles && (
          <input type="file" onChange={(e) => onUpload(e.target.files)} accept={accept} multiple={true} />
        ) /*Not sure how to dynamically add multiple wwith JS */
      }
      {!multipleFiles && <input type="file" onChange={(e) => onUpload(e.target.files)} accept={accept} />}
      {displaySpinner && <Spinner size="xs" speed="0.65s" />}
    </Flex>
  );
};

export default UploadFile;
