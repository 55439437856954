import { Dispatch } from 'react';
import { Action } from 'redux';
import Firestore from '../firestore/Firestore';
import ISurvey from './interfaces/ISurvey';
import { formatSurveyToFirestore } from './utils';

export enum SurveyActionTypes {
  SUBMIT_SURVEY = 'SUBMIT_SURVEY',
  CREATED_SURVEY = 'CREATED_SURVEY',
  CREATING_SURVEY = 'CREATING_SURVEY',
  CREATING_SURVEY_ERROR = 'CREATING_SURVEY_ERROR',
}

export type SurveyActions = SubmitSurveyAction | CreatingSurveyAction | CreatedSurveyAction | CreateSurveyErrorAction;

export interface CreateSurveyErrorAction extends Action<SurveyActionTypes.CREATING_SURVEY_ERROR> {
  type: SurveyActionTypes.CREATING_SURVEY_ERROR;
  survey: null | string;
}

export const SurveyError = (e: null | string): CreateSurveyErrorAction => ({
  type: SurveyActionTypes.CREATING_SURVEY_ERROR,
  survey: e,
});

export interface SubmitSurveyAction extends Action<SurveyActionTypes.SUBMIT_SURVEY> {
  survey: null | ISurvey;
}

export const submitSurvey = (survey: null | ISurvey): SubmitSurveyAction => ({
  type: SurveyActionTypes.SUBMIT_SURVEY,
  survey,
});

export type CreatingSurveyAction = Action<SurveyActionTypes.CREATING_SURVEY>;

export const creatingSurvey = (): CreatingSurveyAction => ({
  type: SurveyActionTypes.CREATING_SURVEY,
});

export interface CreatedSurveyAction extends Action<SurveyActionTypes.CREATED_SURVEY> {
  survey: ISurvey;
}

export const createdSurvey = (survey: ISurvey): CreatedSurveyAction => ({
  type: SurveyActionTypes.CREATED_SURVEY,
  survey,
});

export const createSurvey = (rawSurvey: Omit<ISurvey, 'id'>) => {
  return async (dispatch: Dispatch<SurveyActions>): Promise<boolean> => {
    dispatch(creatingSurvey());

    try {
      const survey = formatSurveyToFirestore(rawSurvey);
      const surveyRef = Firestore.collection('surveys').doc();
      await surveyRef.set(survey);
      dispatch(
        createdSurvey({
          ...survey,
          id: surveyRef.id,
        }),
      );
      return true;
    } catch (error) {
      dispatch(SurveyError(error as null | string));
      return false;
    }
  };
};
