import firebase from 'firebase/compat/app';
import { createAsyncThunk, nanoid } from '@reduxjs/toolkit';
import Firestore from '../../modules/firestore/Firestore';
import { LinkedUserRole } from '../../modules/linked-users/interfaces/ILinkedUser';
import { CPData, AdminCP } from '../interfaces/Admin';

export const getCPInfo = createAsyncThunk('getCPInfo', async (): Promise<AdminCP[]> => {
  const linkedUsersCollection = Firestore.collection('linkedUsers');
  const providerDocs = await linkedUsersCollection.where('otherUserRole', '==', LinkedUserRole.PROVIDER).get();
  const cpMap = new Map<string, string[]>();

  //group by CP and pirs linked to CP
  providerDocs.forEach((doc) => {
    const cpRef: firebase.firestore.DocumentReference | undefined = doc.get('otherUser');
    const pirRef: firebase.firestore.DocumentReference | undefined = doc.get('pir');
    let cpPath: string | undefined, pirPath: string | undefined;
    if (cpRef) cpPath = cpRef.path;
    if (pirRef) pirPath = pirRef.path;

    if (cpPath) {
      if (cpMap.has(cpPath) && pirPath) {
        const current = cpMap.get(cpPath);
        if (current) {
          cpMap.set(cpPath, current.concat([pirPath]));
        }
      } else {
        if (!pirPath) {
          cpMap.set(cpPath, []);
        } else {
          cpMap.set(cpPath, [pirPath]);
        }
      }
    }
  });
  const cpData: CPData[] = [];
  for (const [key, values] of cpMap) {
    cpData.push({ cpPath: key, pirPaths: values });
  }
  return getCPData(cpData);
});

const getCPData = async (cpData: CPData[]): Promise<AdminCP[]> => {
  const formattedCPs = cpData.map(async (data) => {
    const cpDoc = await Firestore.database().doc(data.cpPath).get();
    const name: string | undefined = cpDoc.get('name');
    const organization: string | undefined = cpDoc.get('organization');

    const promisedPirNames = data.pirPaths.map(async (pirPath) => {
      const pirDoc = await Firestore.database().doc(pirPath).get();
      const pirName: string | undefined = pirDoc.get('name');
      if (pirName) {
        return pirName;
      } else {
        return 'n/a';
      }
    });
    const pirNames = await Promise.all(promisedPirNames);
    const cp: AdminCP = {
      id: nanoid(),
      name: name ?? 'n/a',
      pirNames,
      organization: organization ?? 'n/a',
    };
    return cp;
  });
  return Promise.all(formattedCPs);
};
