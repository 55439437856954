import { Action } from 'redux';
import firebase from 'firebase/compat/app';
import AppThunk, { AppThunkPromise, AppThunkSync } from '../../redux/interfaces/AppThunk';
import Firestore, { FirestoreUtils } from '../firestore/Firestore';
import {
  formatLinkedUserFromFirestore,
  formatLinkedUserToFirestore,
  isDeletedLinkedUser,
  isIncompleteLinkedUser,
  isPirLinkedUser,
} from './utils';
import { formatUserFromFirestore, getByRef as getUserByRef } from '../user/utils';
import { getToDos, GotToDosAction } from '../todo/actions';
import ILinkedUser, {
  IIncompleteLinkedUser,
  INewLinkedUser,
  ISupporterLinkedUser,
  PossibleLinkedUser,
  IRejectedLinkedUser,
  IPirLinkedUser,
  INewPirLinkedUser,
  PirUsersData,
  UserFeatures,
  LinkedUserRole,
  INewSupporterLinkedUser,
  PhoneAuthorization,
} from './interfaces/ILinkedUser';
import { formatWeeklyAggregatesFromFirestore } from '../summaryTable/utils';
import IWeeklyVitalsAggregates from '../summaryTable/interfaces/IWeeklyVitalsAggregates';
import { getMotivator, GotMotivatorAction } from '../motivator/actions';
import { GotVitalsAction } from '../../redux/actions/vitals';
import { getAggregates, GotAggregatesAction } from '../summaryTable/actions';
import { getFreeDate } from '../freeDate/actions';
import { getDeviceGarmin, GotDeviceGarminAction } from '../deviceGarmin/actions';
import { InvitedAsRole, UserInvitation } from '../invitations/userInvitation';
import IUser, { UserRole } from '../user/interfaces/IUser';
import { generateVerificationToken } from '../invitations/utils';

export enum LinkedUsersActionType {
  GETTING_LU = 'GETTING_LINKED_USERS',
  GOT_LU = 'GOT_LINKED_USERS',
  SET_PIR_LU = 'SET_PIR_LINKED_USERS',
  SET_OTHER_LU = 'SET_OTHER_LINKED_USERS',
  SET_UNSUBSCRIBE_FNS = 'SET_LINKED_USERS_UNSUBSCRIBE_FUNCTIONS',
  SELECT_LU = 'SELECT_LINKED_USER',
  CREATING_LU = 'CREATING_LINKED_USER',
  CREATED_LU = 'CREATED_LINKED_USER',
  UPDATING_LU = 'UPDATING_LINKED_USER',
  UPDATED_LU = 'UPDATED_LINKED_USER',
  DELETING_LU = 'DELETING_LINKED_USER',
  DELETED_LU = 'DELETED_LINKED_USER',
  GOT_USER_FEATURES = 'GOT_USER_FEATURES',
  GETTING_INVITES = 'GETTING_INVITES',
  GOT_INVITES = 'GOT_INVITES',
  SET_INVITES = 'SET_INVITES',
  UPDATING_INVITE = 'UPDATING_INVITE',
  UPDATED_INVITE = 'UPDATED_INVITE',
  GETTING_DASHBOARD_DATA = 'GETTING_DASHBOARD_DATA',
  GOT_DASHBOARD_DATA = 'GOT_DASHBOARD_DATA',
}

export type LinkedUsersActions =
  | GotLinkedUsersAction
  | GettingLinkedUsersAction
  | SetPirLinkedUsersAction
  | SetOtherLinkedUsersAction
  | SetLinkedUsersUnsubscribeFnsAction
  | CreatingLinkedUserAction
  | CreatedLinkedUserAction
  | SelectLinkedUserAction
  | UpdatingLinkedUserAction
  | UpdatedLinkedUserAction
  | DeletingLinkedUserAction
  | DeletedLinkedUserAction
  | GettingInvitesAction
  | GotUserFeaturesAction
  | GotInvitesAction
  | SetInvitesAction
  | UpdatingInvitesAction
  | UpdatedInviteAction
  | GotDeviceGarminAction
  | GettingDashboardData
  | GotDashboardData;

export type GettingDashboardData = Action<LinkedUsersActionType.GETTING_DASHBOARD_DATA>;
export interface GotDashboardData extends Action<LinkedUsersActionType.GOT_DASHBOARD_DATA> {
  dashboardData: PirUsersData[] | null;
}

export const getDashboardData = (): AppThunk<Promise<GotDashboardData>, LinkedUsersActions> => {
  return async (dispatch, getState) => {
    dispatch({
      type: LinkedUsersActionType.GETTING_DASHBOARD_DATA,
    });
    const otherLinkedUsers = getState().linkedUsers.otherLinkedUsers;
    if (otherLinkedUsers) {
      const dataUsers = otherLinkedUsers.map(async (linkedUser): Promise<PirUsersData> => {
        if (linkedUser.pir) {
          const pir = await getUserByRef(linkedUser.pir);
          const dataSnapshot = await Firestore.collection('weeklyVitalsAggregates')
            .where('pir', '==', linkedUser.pir)
            .limit(1)
            .get();
          // Getting number of support requests using PIR survey results
          const surveySnapshot = await Firestore.collection('surveys').where('pir', '==', linkedUser.pir).get();
          // NOTE: this process was changed in another pr
          const supportRequestCount = surveySnapshot.docs.reduce((numSupportRequests, doc) => {
            const survey = doc.data();
            return survey.needHelp ? numSupportRequests + 1 : numSupportRequests;
          }, 0);

          const weeklyAggregateResults: IWeeklyVitalsAggregates[] = [];
          dataSnapshot.docs.map((data) => {
            return weeklyAggregateResults.push(formatWeeklyAggregatesFromFirestore(data));
          });
          if (weeklyAggregateResults.length > 0) {
            const pirData = weeklyAggregateResults[0];
            if (pir?.name) {
              return {
                linkedUser,
                person: linkedUser.pirAlias || linkedUser.preferredName || pir.name,
                craving: pirData.avgCravingRisk,
                participation: Math.round(pirData.avgParticipation),
                watchUse: Math.round(pirData.avgWatchUse),
                sleep: pirData.avgSleep,
                emotion: pirData.avgEmotionalScore,
                stress: pirData.avgStress,
                incidents: Math.round(pirData.totalIncidents),
                supportRequests: supportRequestCount,
                image: { name: pir.name ?? '', image: pir.image ?? '' },
              };
            }
          } else if (pir?.name) {
            // people with pir returns fake data for now
            return {
              linkedUser,
              person: linkedUser.pirAlias || linkedUser.preferredName || pir.name,
              craving: '', //cravingRiskValue,
              participation: '', //participationValue,
              watchUse: '', //watchUseValue
              sleep: '', //sleepValue,
              emotion: '', //emotionalScoreValue,
              stress: '', //stressValue,
              incidents: '', //incidentsValue,
              supportRequests: supportRequestCount, // number of support requests
              image: {
                name: linkedUser.preferredName ?? linkedUser.pirAlias ?? pir.name ?? '',
                image: pir.image ?? '',
              },
            };
          }
        }
        return {};
      });
      const promisedData = await Promise.all(dataUsers);
      return dispatch({
        type: LinkedUsersActionType.GOT_DASHBOARD_DATA,
        dashboardData: promisedData,
      });
    }
    return dispatch({
      type: LinkedUsersActionType.GOT_DASHBOARD_DATA,
      dashboardData: null,
    });
  };
};

export interface SetPirLinkedUsersAction extends Action<LinkedUsersActionType.SET_PIR_LU> {
  pirLinkedUsers: null | PossibleLinkedUser[];
}

export const setPirLinkedUsers = (pirLinkedUsers: null | PossibleLinkedUser[]): SetPirLinkedUsersAction => {
  return {
    type: LinkedUsersActionType.SET_PIR_LU,
    pirLinkedUsers,
  };
};

export interface SetOtherLinkedUsersAction extends Action<LinkedUsersActionType.SET_OTHER_LU> {
  otherLinkedUsers: null | (ILinkedUser | IIncompleteLinkedUser)[];
}

export const setOtherLinkedUsers = (
  otherLinkedUsers: null | (ILinkedUser | IIncompleteLinkedUser)[],
): SetOtherLinkedUsersAction => {
  return {
    type: LinkedUsersActionType.SET_OTHER_LU,
    otherLinkedUsers,
  };
};

export interface SetLinkedUsersUnsubscribeFnsAction extends Action<LinkedUsersActionType.SET_UNSUBSCRIBE_FNS> {
  pirFn?: null | firebase.Unsubscribe;
  otherFn?: null | firebase.Unsubscribe;
  inviteFn?: null | firebase.Unsubscribe;
}

export type GettingLinkedUsersAction = Action<LinkedUsersActionType.GETTING_LU>;

export const getAllLinkedUsersIncludingDeleted = async (
  userId: string,
  isPir: boolean,
): Promise<(ILinkedUser | IIncompleteLinkedUser | IPirLinkedUser | IRejectedLinkedUser)[]> => {
  const linkedUsersCollection = Firestore.collection('linkedUsers');
  if (isPir) {
    const allLinkedUsersSnapshot = await linkedUsersCollection
      .where('pir', '==', FirestoreUtils.getDocRef('users', userId))
      .get();
    const allLinkedUsers = allLinkedUsersSnapshot.docs.map((linkedUser) => formatLinkedUserFromFirestore(linkedUser));
    return allLinkedUsers;
  } else {
    const allLinkedUsersSnapshot = await linkedUsersCollection
      .where('otherUser', '==', FirestoreUtils.getDocRef('users', userId))
      .get();
    const allLinkedUsers = allLinkedUsersSnapshot.docs
      .map((linkedUser) => formatLinkedUserFromFirestore(linkedUser))
      .filter((linkedUser) => !isPirLinkedUser(linkedUser));
    return allLinkedUsers;
  }
};

export const getLinkedUsers = (userId: string): AppThunk<Promise<GotLinkedUsersAction>, LinkedUsersActions> => {
  return async (dispatch) => {
    dispatch({
      type: LinkedUsersActionType.GETTING_LU,
    });

    const linkedUsersCollection = Firestore.collection('linkedUsers');

    // Get all PIR linkages
    const pirSnapshot = await linkedUsersCollection.where('pir', '==', FirestoreUtils.getDocRef('users', userId)).get();
    const pirLinkedUsers = pirSnapshot.docs
      .map((linkedUser) => formatLinkedUserFromFirestore(linkedUser))
      .filter((linkedUser) => isPirLinkedUser(linkedUser) || !isDeletedLinkedUser(linkedUser));

    dispatch(setPirLinkedUsers(pirLinkedUsers.length > 0 ? pirLinkedUsers : null));

    // Get all Other linkages
    // where user is CP, only get linkedusers for PiRs who have not deleted their account:
    const otherSnapshot = await linkedUsersCollection
      .where('otherUser', '==', FirestoreUtils.getDocRef('users', userId))
      .where('approvedByOther', '==', true)
      .get();
    const otherLinkedUsers = otherSnapshot.docs
      .map((linkedUser) => formatLinkedUserFromFirestore(linkedUser))
      .filter(
        (linkedUser): linkedUser is IIncompleteLinkedUser | ILinkedUser =>
          !isPirLinkedUser(linkedUser) && !isDeletedLinkedUser(linkedUser),
      );
    dispatch(setOtherLinkedUsers(otherLinkedUsers.length > 0 ? otherLinkedUsers : null));

    // Setup snapshots
    const pirUnsubscribe = linkedUsersCollection
      .where('pir', '==', FirestoreUtils.getDocRef('users', userId))
      .onSnapshot((snapshot) => {
        const pirLinkedUsers = snapshot.docs
          .map((linkedUser) => formatLinkedUserFromFirestore(linkedUser))
          .filter((linkedUser) => !isDeletedLinkedUser(linkedUser));
        dispatch(setPirLinkedUsers(pirLinkedUsers.length > 0 ? pirLinkedUsers : null));
      });

    const otherUnsubscribe = linkedUsersCollection
      .where('otherUser', '==', FirestoreUtils.getDocRef('users', userId))
      .where('approvedByOther', '==', true)
      .onSnapshot((snapshot) => {
        const otherLinkedUsers = snapshot.docs
          .map((linkedUser) => formatLinkedUserFromFirestore(linkedUser))
          .filter(
            (linkedUser): linkedUser is IIncompleteLinkedUser | ILinkedUser =>
              !isPirLinkedUser(linkedUser) && !isDeletedLinkedUser(linkedUser),
          );
        dispatch(setOtherLinkedUsers(otherLinkedUsers.length > 0 ? otherLinkedUsers : null));
      });

    dispatch({
      type: LinkedUsersActionType.SET_UNSUBSCRIBE_FNS,
      pirFn: pirUnsubscribe,
      otherFn: otherUnsubscribe,
    });

    return dispatch(gotLinkedUsers(pirLinkedUsers.concat(otherLinkedUsers)));
  };
};

export interface GotLinkedUsersAction extends Action<LinkedUsersActionType.GOT_LU> {
  linkedUsers: PossibleLinkedUser[];
}

export const gotLinkedUsers = (linkedUsers: PossibleLinkedUser[]): GotLinkedUsersAction => {
  return {
    type: LinkedUsersActionType.GOT_LU,
    linkedUsers,
  };
};

export interface SelectLinkedUserAction extends Action<LinkedUsersActionType.SELECT_LU> {
  linkedUser: PossibleLinkedUser | null;
}

export const selectLinkedUser = (
  linkedUser: PossibleLinkedUser | null,
): AppThunk<
  Promise<SelectLinkedUserAction>,
  | LinkedUsersActions
  | GotMotivatorAction
  | GotVitalsAction
  | GotToDosAction
  //  | GotDeviceAction
  //  | GotDeviceDataAction
  | GotAggregatesAction
  //  | GotDeviceSdkStatusAction
> => {
  return async (dispatch) => {
    if (linkedUser !== null && linkedUser.pir) {
      const promises: Promise<Action>[] = [];
      promises.push(dispatch(getUserFeatures(linkedUser.pir.id)));
      promises.push(dispatch(getToDos(linkedUser.pir.id)));
      promises.push(dispatch(getMotivator(linkedUser.pir.id)));
      promises.push(dispatch(getAggregates(linkedUser.pir.id)));
      promises.push(dispatch(getFreeDate(linkedUser.pir.id)));
      promises.push(dispatch(getDeviceGarmin(linkedUser.pir.id)));
      // promises.push(dispatch(getDeviceSdkStatus(linkedUser.pir.id)));
      await Promise.all(promises);
    }
    return dispatch({
      type: LinkedUsersActionType.SELECT_LU,
      linkedUser,
    });
  };
};

export type CreatingLinkedUserAction = Action<LinkedUsersActionType.CREATING_LU>;
export interface CreatedLinkedUserAction extends Action<LinkedUsersActionType.CREATED_LU> {
  linkedUser: PossibleLinkedUser;
}

export const createPirLinkedUser = (
  linkedUser: INewPirLinkedUser,
): AppThunk<Promise<CreatedLinkedUserAction>, LinkedUsersActions> => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: LinkedUsersActionType.CREATING_LU });
      const id = Firestore.collection('linkedUsers').doc().id;
      const docToSet: IPirLinkedUser = {
        id,
        pir: linkedUser.pir,
        default: false,
        approvedByPir: linkedUser.approvedByPir ?? false,
        reviewedByPir: linkedUser.reviewedByPir ?? false,
        dateSubmitted: linkedUser.dateSubmitted ?? FirestoreUtils.now(),
      };

      if (linkedUser.dateReviewedByPir) {
        docToSet.dateReviewedByPir = linkedUser.dateReviewedByPir;
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const currentUser = getState().user.user!;
      const linkedUsersWithEmail = await Firestore.collection('linkedUsers')
        .where('email', '==', currentUser.email)
        .get();
      const linkedUsersWithPir = await Firestore.collection('linkedUsers').where('pir', '==', linkedUser.pir).get();
      // I (NM) am updating the code to make sure when a pir joins, their linkedUser doc with their cp is updated and also the additional pir LU doc is added. This fix is far from ideal, but I'm doing a quick fix because we're about to re-evaluate how linkedUsers work. Currently there isn't really a way to account for multiple CPs inviting the same PiR anyway 7/11/2003. But this code creates a new pirLinkedUser if there is none, and updates the CP invite as before.

      const pirIsAlreadyLinkedUser =
        linkedUsersWithPir.size > 0 &&
        linkedUsersWithPir.docs
          .map((linkedUser) => formatLinkedUserFromFirestore(linkedUser))
          .some((linkedUser): linkedUser is IIncompleteLinkedUser | ILinkedUser => isPirLinkedUser(linkedUser));
      // if there isn't already a pirLinkedUser row for this user, add it
      if (!pirIsAlreadyLinkedUser) {
        await Firestore.collection('linkedUsers')
          .doc(docToSet.id)
          .set(formatLinkedUserToFirestore({ ...docToSet, default: true }));
      }

      // if there's one linkedUser row where email is this user's email, assume that is the row attached to inviting this user and update it, putting the otherUser's email in the email spot
      if (linkedUsersWithEmail.size === 1) {
        docToSet.id = linkedUsersWithEmail.docs[0].id;
        await Firestore.collection('linkedUsers')
          .doc(docToSet.id)
          .update({
            ...formatLinkedUserToFirestore(docToSet),
            email: linkedUsersWithEmail.docs[0].get('otherUserEmail'),
          });
      }
      return dispatch({
        type: LinkedUsersActionType.CREATED_LU,
        linkedUser: docToSet,
      });
    } catch (e) {
      console.error(`Error creating pir linked user: ${e}`);
      throw e;
    }
  };
};

export const createSupporterLinkedUser = (
  linkedUser: INewSupporterLinkedUser,
): AppThunk<Promise<CreatedLinkedUserAction>, LinkedUsersActions> => {
  return async (dispatch) => {
    try {
      dispatch({ type: LinkedUsersActionType.CREATING_LU });
      const id = Firestore.collection('linkedUsers').doc().id;
      const docToSet: ISupporterLinkedUser = {
        ...linkedUser,
        id,
        pir: linkedUser.pir,
        approvedByPir: linkedUser.approvedByPir ?? false,
        reviewedByPir: linkedUser.reviewedByPir ?? false,
        dateSubmitted: linkedUser.dateSubmitted ?? FirestoreUtils.now(),
        dateReviewedByPir: linkedUser.dateSubmitted ?? FirestoreUtils.now(),
        otherUserRole: linkedUser.otherUserRole ?? LinkedUserRole.SUPPORTER,
        contactAlias: linkedUser.contactAlias,
        preferredName: linkedUser.preferredName,
        otherUserEmail: linkedUser.otherUserEmail,
        default: false,
        notificationOnSOS: linkedUser.notificationOnSOS ?? true,
      };

      if (linkedUser.otherUserPhone) {
        docToSet.otherUserPhone = linkedUser.otherUserPhone;
        docToSet.phoneAuthorization = PhoneAuthorization.PENDING;

        docToSet.supporteeNumber = await getSupporteeNumber(linkedUser.otherUserPhone);
      }

      const linkedUsersWithPir = await Firestore.collection('linkedUsers').where('pir', '==', linkedUser.pir).get();
      const supporterIsAlreadyLinkedUser = linkedUsersWithPir.docs
        .map((possibleSupporter) => formatLinkedUserFromFirestore(possibleSupporter))
        .some((possibleSupporter): possibleSupporter is ISupporterLinkedUser => {
          let alreadySupporter = false;
          if (
            linkedUser.otherUserEmail !== undefined &&
            'otherUserEmail' in possibleSupporter &&
            possibleSupporter.otherUserEmail === linkedUser.otherUserEmail
          ) {
            alreadySupporter = true;
          }
          if (
            linkedUser.otherUserPhone !== undefined &&
            'otherUserPhone' in possibleSupporter &&
            possibleSupporter.otherUserPhone === linkedUser.otherUserPhone
          ) {
            alreadySupporter = true;
          }
          return alreadySupporter;
        });
      // if there isn't already a pirLinkedUser row for this user, add it
      if (supporterIsAlreadyLinkedUser) {
        throw new Error('linkageAlreadyExists');
      }
      await Firestore.collection('linkedUsers')
        .doc(docToSet.id)
        .set(formatLinkedUserToFirestore({ ...docToSet }));

      return dispatch({
        type: LinkedUsersActionType.CREATED_LU,
        linkedUser: docToSet,
      });
    } catch (e) {
      console.error(`Error creating supporter linked user: ${e}`);
      throw e;
    }
  };
};

export const createLinkedUser = (
  linkedUser: INewLinkedUser,
): AppThunk<Promise<CreatedLinkedUserAction>, CreatingLinkedUserAction | CreatedLinkedUserAction> => {
  return async (dispatch, getState) => {
    dispatch({ type: LinkedUsersActionType.CREATING_LU });

    const id = Firestore.collection('linkedUsers').doc().id;
    const docToSet = {
      ...linkedUser,
      id,
      approvedByPir: linkedUser.approvedByPir ?? false,
      approvedByOther: linkedUser.approvedByOther ?? false,
      reviewedByPir: linkedUser.reviewedByPir ?? false,
      reviewedByOther: linkedUser.reviewedByOther ?? false,
      dateSubmitted: linkedUser.dateSubmitted ?? FirestoreUtils.now(),
    };

    /**
     * Check that this linked user does not already exist in the system
     */

    if (linkedUser.email && linkedUser.pir) {
      // Check by email, for invites:
      const inviteExistsCheck = await Firestore.collection('linkedUsers')
        .where('email', '==', linkedUser.email)
        .where('pir', '==', linkedUser.pir)
        .get();

      if (!inviteExistsCheck.empty) {
        throw new Error('inviteAlreadyExists');
      }

      const userByEmail = await Firestore.collection('users').where('email', '==', linkedUser.email).get();

      if (!userByEmail.empty) {
        // Check that inviter and invitee don't have the same email:
        const invitee = formatUserFromFirestore(userByEmail.docs[0]);
        const inviter = getState().user.user as IUser;

        if (inviter.email === invitee.email) {
          throw new Error('sameEmailAsInviter');
        }

        // check that a provider is being invited rather than someone with another role:
        if (invitee.role !== UserRole.CP) {
          throw new Error('inviteeIsNotProvider');
        }

        const linkedUserId = invitee.id;
        const docRefCheck = await Firestore.collection('linkedUsers')
          .where('pir', '==', linkedUser.pir)
          .where('otherUser', '==', FirestoreUtils.getDocRef('users', linkedUserId))
          .get();

        if (!docRefCheck.empty) {
          throw new Error('linkageAlreadyExists');
        }
      } else {
        const user = getState().user.user;
        const invitation: Omit<UserInvitation, 'id'> = {
          email: linkedUser.email,
          name: linkedUser.pirAlias || linkedUser.contactAlias || '',
          accepted: false,
          createdDate: new Date(),
          invitedByUser: linkedUser.pir.id,
          invitedBy: user?.name ?? '',
          invitedAsRole: InvitedAsRole.CP,
          verificationToken: generateVerificationToken(),
        };
        await Firestore.collection('invitations').doc().set(invitation);
      }
    }

    // Check by doc ref:
    if (linkedUser.pir && linkedUser.otherUser) {
      const docRefCheck = await Firestore.collection('linkedUsers')
        .where('pir', '==', linkedUser.pir)
        .where('otherUser', '==', linkedUser.otherUser)
        .get();

      if (!docRefCheck.empty) {
        if (docRefCheck.docs[0].get('otherUserRole') !== linkedUser.otherUserRole) {
          throw new Error('linkageAlreadyExistsDifferentRole');
        } else {
          throw new Error('linkageAlreadyExistsSameRole');
        }
      }
    }

    await Firestore.collection('linkedUsers').doc(docToSet.id).set(formatLinkedUserToFirestore(docToSet));
    return dispatch({
      type: LinkedUsersActionType.CREATED_LU,
      linkedUser: docToSet,
    });
  };
};

export type UpdatingLinkedUserAction = Action<LinkedUsersActionType.UPDATING_LU>;
export interface UpdatedLinkedUserAction extends Action<LinkedUsersActionType.UPDATED_LU> {
  linkedUser: PossibleLinkedUser;
  isMyRole: boolean;
}

// only use this for when the invite is accepted, otherwise use updatePartialLinkedUser
export const updateLinkedUser = (
  linkedUser: PossibleLinkedUser,
  isMyRole: boolean,
): AppThunk<Promise<UpdatedLinkedUserAction>, UpdatingLinkedUserAction | UpdatedLinkedUserAction> => {
  return async (dispatch) => {
    try {
      dispatch({ type: LinkedUsersActionType.UPDATING_LU });

      await Firestore.collection('linkedUsers').doc(linkedUser.id).set(formatLinkedUserToFirestore(linkedUser));

      return dispatch({
        type: LinkedUsersActionType.UPDATED_LU,
        linkedUser,
        isMyRole,
      });
    } catch (e) {
      console.error(`Error updating linked user: ${e}`);
      throw e;
    }
  };
};

export const updatePartialLinkedUser = (
  id: string,
  linkedUserUpdates: Partial<PossibleLinkedUser>,
  isMyRole: boolean,
  valuesToDelete?: string[],
): AppThunk<Promise<PossibleLinkedUser>, UpdatingLinkedUserAction | UpdatedLinkedUserAction> => {
  return async (dispatch) => {
    try {
      dispatch({ type: LinkedUsersActionType.UPDATING_LU });

      const updatedValues: any = { ...linkedUserUpdates };

      if ('otherUserPhone' in linkedUserUpdates && linkedUserUpdates.otherUserPhone) {
        updatedValues.supporteeNumber = await getSupporteeNumber(linkedUserUpdates.otherUserPhone);
      }

      if (valuesToDelete) {
        for (const value of valuesToDelete) {
          updatedValues[value] = FirestoreUtils.deleteField();
        }
      }
      await Firestore.collection('linkedUsers').doc(id).update(updatedValues);
      const updatedLinkedUserSnapshot = await Firestore.collection('linkedUsers').doc(id).get();

      if (updatedLinkedUserSnapshot.exists) {
        const linkedUser = formatLinkedUserFromFirestore(updatedLinkedUserSnapshot);
        dispatch({
          type: LinkedUsersActionType.UPDATED_LU,
          linkedUser,
          isMyRole,
        });
        return linkedUser;
      } else {
        throw new Error('Updated linked user snapshot does not exist');
      }
    } catch (e) {
      console.error(`Error updating linked user: ${e}`);
      throw e;
    }
  };
};

export const createPirProviderRelationship = (
  linkedUser: ILinkedUser,
): AppThunk<Promise<UpdatedLinkedUserAction>, LinkedUsersActions> => {
  return async (dispatch) => {
    dispatch({ type: LinkedUsersActionType.UPDATING_LU });

    await Firestore.collection('providerPirRelationships')
      .doc(linkedUser.otherUser.id)
      .collection('patients')
      .doc(linkedUser.pir.id)
      .set({
        id: linkedUser.pir.id,
        pir: linkedUser.pir,
        relationship: linkedUser.relationship ?? '',
        role: linkedUser.otherUserRole,
      });

    return dispatch({
      type: LinkedUsersActionType.UPDATED_LU,
      linkedUser,
      isMyRole: true,
    });
  };
};

export type DeletingLinkedUserAction = Action<LinkedUsersActionType.DELETING_LU>;
export type DeletedLinkedUserAction = Action<LinkedUsersActionType.DELETED_LU>;

export const deleteLinkedUser = (
  linkedUser: PossibleLinkedUser,
): AppThunk<Promise<DeletedLinkedUserAction>, LinkedUsersActions> => {
  return async (dispatch, getState) => {
    try {
      await Firestore.collection('linkedUsers').doc(linkedUser.id).delete();

      dispatch({ type: LinkedUsersActionType.DELETING_LU });

      if (getState().linkedUsers.selectedLinkedUser?.id === linkedUser.id) {
        dispatch(selectLinkedUser(null));
      }
      return dispatch({ type: LinkedUsersActionType.DELETED_LU });
    } catch (err) {
      console.log(`Error deleting linkedUser: ${err}`);
      throw new Error(`ErrorDeletingLinkedUser: ${err}`);
    }
  };
};

export interface GotUserFeaturesAction extends Action<LinkedUsersActionType.GOT_USER_FEATURES> {
  userFeatures: UserFeatures;
}

export const getUserFeatures = (pirId: string): AppThunkPromise<GotUserFeaturesAction> => {
  return async (dispatch) => {
    const userDocument = await Firestore.collection('users').doc(pirId).get();
    const userFeatures: UserFeatures = {
      cravingsPredictionEnabled: userDocument.get('cravingsPredictionEnabled') ?? false,
    };

    return dispatch({
      type: LinkedUsersActionType.GOT_USER_FEATURES,
      userFeatures,
    });
  };
};

export type GettingInvitesAction = Action<LinkedUsersActionType.GETTING_INVITES>;
export interface GotInvitesAction extends Action<LinkedUsersActionType.GOT_INVITES> {
  invites: IIncompleteLinkedUser[];
}

export const getInvites = (email: string): AppThunk<Promise<GotInvitesAction>, LinkedUsersActions> => {
  return async (dispatch) => {
    const snapshot = await Firestore.collection('linkedUsers')
      .where('email', '==', email)
      .where('approvedByOther', '==', false)
      .get();

    const out: IIncompleteLinkedUser[] = [];
    snapshot.docs.forEach((doc) => {
      const formattedDoc = formatLinkedUserFromFirestore(doc);

      if (isIncompleteLinkedUser(formattedDoc) && !formattedDoc.deletedPir) {
        out.push(formattedDoc);
      } else {
        throw new Error('Provided snapshot is not an incomplete linked user record');
      }
    });

    const inviteUnsubscribeFn = Firestore.collection('linkedUsers')
      .where('email', '==', email)
      .where('approvedByOther', '==', false)
      .onSnapshot((snapshot) => {
        const out: IIncompleteLinkedUser[] = [];
        snapshot.docs.forEach((doc) => {
          const formattedDoc = formatLinkedUserFromFirestore(doc);

          if (isIncompleteLinkedUser(formattedDoc)) {
            out.push(formattedDoc);
          } else {
            throw new Error('Provided snapshot is not an incomplete linked user record');
          }
        });

        dispatch({
          type: LinkedUsersActionType.GOT_INVITES,
          invites: out,
        });
      });

    dispatch({
      type: LinkedUsersActionType.SET_UNSUBSCRIBE_FNS,
      inviteFn: inviteUnsubscribeFn,
    });

    return dispatch({
      type: LinkedUsersActionType.GOT_INVITES,
      invites: out,
    });
  };
};

export interface SetInvitesAction extends Action<LinkedUsersActionType.SET_INVITES> {
  invites: IIncompleteLinkedUser[] | null;
}

export const setInvites = (invites: IIncompleteLinkedUser[] | null): SetInvitesAction => {
  return {
    type: LinkedUsersActionType.SET_INVITES,
    invites,
  };
};

export type UpdatingInvitesAction = Action<LinkedUsersActionType.UPDATING_INVITE>;
export interface UpdatedInviteAction extends Action<LinkedUsersActionType.UPDATED_INVITE> {
  linkedUser: ILinkedUser | IRejectedLinkedUser;
}

export const acceptOrDenyInvite = (
  invite: IIncompleteLinkedUser,
  isAccepting: boolean,
): AppThunk<Promise<UpdatedInviteAction>, LinkedUsersActions> => {
  return async (dispatch, getState) => {
    dispatch({ type: LinkedUsersActionType.UPDATING_INVITE });
    const user = getState().user.user;
    const otherLinkedUsers = getState().linkedUsers.otherLinkedUsers;

    if (user === null) {
      throw new Error('No user found to accept invite');
    }

    if (!invite.pir) {
      throw new Error('No PIR on invite during acceptance');
    }

    if (!invite.otherUserRole) {
      throw new Error('No role on invite during acceptance');
    }

    if (!invite.dateReviewedByPir) {
      throw new Error('No date reviewed by PIR on invite during acceptance');
    }

    if (isAccepting) {
      const linkedUser: ILinkedUser = {
        id: invite.id,
        pir: invite.pir,
        otherUser: FirestoreUtils.getDocRef('users', user.id),
        otherUserRole: invite.otherUserRole,
        default: false,
        reviewedByPir: true,
        reviewedByOther: true,
        approvedByPir: true,
        approvedByOther: isAccepting,
        dateSubmitted: invite.dateSubmitted,
        dateReviewedByPir: invite.dateReviewedByPir,
        dateReviewedByOther: invite.dateReviewedByOther ?? FirestoreUtils.now(),
        preferredName: invite.preferredName,
        contactAlias: invite.contactAlias,
        notificationOnSOS: invite.notificationOnSOS ?? true,
      };

      if (invite.relationship !== undefined) {
        linkedUser.relationship = invite.relationship;
      }

      await dispatch(createPirProviderRelationship(linkedUser));
      await dispatch(updateLinkedUser(linkedUser, true));

      // Check for any empty roles setup by this user during sign up that can be cleaned up
      if (otherLinkedUsers) {
        for (const otherLinkedUser of otherLinkedUsers) {
          if (
            isIncompleteLinkedUser(otherLinkedUser) &&
            otherLinkedUser.approvedByOther &&
            otherLinkedUser.otherUserRole === invite.otherUserRole
          ) {
            await dispatch(deleteLinkedUser(otherLinkedUser));
          }
        }
      }
      return dispatch({
        type: LinkedUsersActionType.UPDATED_INVITE,
        linkedUser,
      });
    } else {
      const id = invite.id;
      const updatesToRejectedLinkedUser = {
        reviewedByOther: true,
        approvedByOther: false,
        dateReviewedByOther: invite.dateReviewedByOther ?? FirestoreUtils.now(),
        otherUserEmail: invite.email,
        contactAlias: invite.contactAlias ?? invite.name ?? '',
        notificationOnSOS: false,
      };
      const fieldsToDelete = ['email'];

      const rejectedLinkedUser = await dispatch(
        updatePartialLinkedUser(id, updatesToRejectedLinkedUser, true, fieldsToDelete),
      );

      return dispatch({
        type: LinkedUsersActionType.UPDATED_INVITE,
        linkedUser: rejectedLinkedUser as IRejectedLinkedUser,
      });
    }
  };
};

export const stopLinkedUsersListeners = (): AppThunkSync<SetLinkedUsersUnsubscribeFnsAction> => {
  return (dispatch, getState) => {
    const linkedState = getState().linkedUsers;

    if (linkedState.pirUnsubscribeFn) {
      linkedState.pirUnsubscribeFn();
    }

    if (linkedState.otherUnsubscribeFn) {
      linkedState.otherUnsubscribeFn();
    }

    if (linkedState.inviteUnsubscribeFn) {
      linkedState.inviteUnsubscribeFn();
    }

    return dispatch({
      type: LinkedUsersActionType.SET_UNSUBSCRIBE_FNS,
      pirFn: null,
      otherFn: null,
      inviteFn: null,
    });
  };
};

const getSupporteeNumber = async (phoneNumber: string): Promise<number | undefined> => {
  // check if there are other supporterLinkedUsers with the same phone number, and if there are, set the new contactCount in supporterConnections, otherwise set it to 1, and return the new contactCount. This is to ensure that if linkedUsers are deleted for a given supporter phone number, no old supporteeNumbers are reused for new contacts

  try {
    let supporteeNumber = 1;
    const contactCountForPhoneNumber = await Firestore.collection('supporterConnections')
      .where('supporterPhone', '==', phoneNumber)
      .get();
    if (!contactCountForPhoneNumber.empty) {
      const contactCount: number = contactCountForPhoneNumber.docs[0].get('contactCount');
      const id: string = contactCountForPhoneNumber.docs[0].id;
      const newContactCount = contactCount + 1;
      await Firestore.collection('supporterConnections').doc(id).update({ contactCount: newContactCount });
      supporteeNumber = newContactCount;
    } else {
      const id = Firestore.collection('contactCount').doc().id;
      await Firestore.collection('supporterConnections').doc(id).set({ supporterPhone: phoneNumber, contactCount: 1 });
    }
    return supporteeNumber;
  } catch (err) {
    console.error(`Error finding and updating supportee number: ${err}`);
    return undefined;
  }
};
