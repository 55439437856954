import { VitalsActions, VitalsActionTypes } from '../actions/vitals';
import { PirVitalsData } from '../../modules/vitals/interfaces/IVitalsGraph';

interface VitalsState {
  loading: boolean;
  creating: boolean;
  vitals: null | PirVitalsData;
  vitalsResults: null | PirVitalsData;
}

const defaultState: VitalsState = {
  loading: false,
  creating: false,
  vitals: null,
  vitalsResults: null,
};

const vitalsReducer = (state: VitalsState = defaultState, action: VitalsActions): VitalsState => {
  switch (action.type) {
    case VitalsActionTypes.GETTING_VITALS:
      return {
        ...state,
        loading: true,
      };
    case VitalsActionTypes.GOT_VITALS:
      return {
        ...state,
        loading: false,
        vitalsResults: action.vitals,
      };
    default:
      return state;
  }
};

export default vitalsReducer;
