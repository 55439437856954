import { Tab, TabList, TabPanel, TabPanels, Tabs, Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IVitalsTable } from '../../../db/vitals';
import { IVitalList } from '../VitalsModuleCP';
import TabContents from './TabTitleContents';
import VitalsGraphsOverDays from './VitalsGraphsOverDays';
import { GraphType } from '../vitalsGraphOptions';

interface ITabContainerProps {
  uid: string | null;
  showColor: boolean;
  showVitals: boolean;
  initialGraphType?: GraphType;
  tabTitles: GraphType[];
  tabTitleData: IVitalsTable;
  emotionItemsVisibility?: IVitalList[];
  onSelectedGraphChanged?: ((selected: GraphType) => void) | undefined;
  isCP: boolean;
}

const VitalsGraphsTabContainer = ({
  uid,
  showVitals,
  initialGraphType,
  tabTitles,
  emotionItemsVisibility,
  onSelectedGraphChanged,
  isCP,
}: ITabContainerProps): JSX.Element => {
  const { t } = useTranslation('vitals');

  let tabContents = [...tabTitles];

  const defaultTabIndex = initialGraphType ? tabContents.indexOf(initialGraphType) : 0;

  if (!showVitals) {
    tabContents = [];
  }

  const [active, setActive] = useState<number>(defaultTabIndex);

  return (
    <Tabs
      isFitted={true}
      size="lg"
      variant="unstyled"
      isLazy={true}
      index={active}
      onChange={(index) => {
        setActive(index);
      }}
    >
      <TabPanels>
        {emotionItemsVisibility &&
          tabContents.map((tab) => (
            <TabPanel key={tab} padding={0}>
              <VitalsGraphsOverDays
                isCP={isCP}
                graphType={tab}
                uid={uid}
                icon={t(`vitalsModule.${tab}Display.icon`)}
                emotionItemsVisibility={emotionItemsVisibility}
                onSelectedGraphChanged={onSelectedGraphChanged}
              />
            </TabPanel>
          ))}
      </TabPanels>
      <Box>
        <TabList
          bg="white"
          color="grey"
          px={isCP ? 1.5 : 0.3}
          pt={5}
          pb={0}
          borderBottomRadius={['md', 'md']}
          boxShadow={['', 'md']}
          alignItems="flex-start"
          display="flex"
        >
          {tabTitles.map((tab, idx) => {
            return (
              <Tab key={tab} p={0} _selected={{ color: '#5C4279' }}>
                <TabContents
                  title={t(`vitalsModule.${tab}Display.title`)}
                  icon={t(`vitalsModule.${tab}Display.icon`)}
                  selected={active === idx}
                />
              </Tab>
            );
          })}
        </TabList>
      </Box>
    </Tabs>
  );
};
export default VitalsGraphsTabContainer;
