import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HStack, Box } from '@chakra-ui/react';
import { RootState } from '../../../redux/store';
import ManageContactModal from '../../../components/ManageContactModal/ManageContactModal';
import NetworkMember from './NetworkMember';
import { createInteraction } from '../../interactions/actions';
import { moduleName, interactionType } from '../../interactions/constants';
import ModuleContainer from '../../../components/ModuleContainerV2';
import { useModal } from 'src/ModalContext';

const MODAL_NAME = 'support-network';

const SupportNetworkModule = (props: PropsFromRedux): JSX.Element => {
  const { supportNetwork, pir, createInteraction } = props;
  const { t } = useTranslation(['home', 'common']);

  const { modal, openModal, closeModal } = useModal();

  const addClosedInteraction = () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.SUPPORT_NETWORK,
      interactionType: interactionType.SUPPORT_NETWORK.CLOSED,
    });
  };

  useEffect(() => {
    if (modal === MODAL_NAME) {
      createInteraction({
        pir,
        dateTime: new Date(),
        moduleName: moduleName.SUPPORT_NETWORK,
        interactionType: interactionType.SUPPORT_NETWORK.OPEN_MODAL,
      });
    }
  }, [modal === MODAL_NAME]);

  return (
    <Box>
      <ModuleContainer
        title={t('home:pirDash.supportNetworkModule.heading')}
        modalName={MODAL_NAME}
        modalText="See All"
        moduleButton="Add to your Network"
        isEmptyModule={supportNetwork.length === 0}
        moduleInstruct={[t('home:pirDash.supportNetworkModule.noSupporters')]}
        cogFunction={() => openModal(MODAL_NAME)}
      >
        <HStack w="100%" pt="10px" spacing="35px" alignItems="stretch" justifyContent="flex-start" overflow="auto">
          {supportNetwork.map((networkMember) => (
            <NetworkMember linkedUser={networkMember} key={networkMember.id} />
          ))}
        </HStack>
      </ModuleContainer>
      <ManageContactModal
        isOpen={modal === MODAL_NAME}
        onOpen={() => openModal(MODAL_NAME)}
        onClose={() => {
          closeModal();
          addClosedInteraction();
        }}
        pir={pir}
      />
    </Box>
  );
};

const mapStateToProps = (state: RootState) => {
  const { selectedLinkedUser } = state.linkedUsers;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }

  return {
    supportNetwork: state.linkedUsers.supportNetwork ?? [],
    pir: selectedLinkedUser.pir,
  };
};

const mapDispatchToProps = {
  createInteraction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SupportNetworkModule);
