import { Action } from 'redux';
import { getFirebaseUser, updateFirebaseUserEmail } from '../firebase/auth';
import firebase from 'firebase/compat/app';
import AppThunk, { AppThunkPromise, AppThunkSync } from '../../redux/interfaces/AppThunk';
import Firestore, { FirestoreUtils } from '../firestore/Firestore';
import IUser, { DeletionStatus, UserRole } from './interfaces/IUser';
import { formatUserFromFirestore, formatUserToFirestore, removeUndefined } from './utils';
import { GraphType } from '../vitals/vitalsGraphOptions';
import { InvitedAsRole } from '../invitations/userInvitation';
import { PossibleLinkedUser } from '../linked-users/interfaces/ILinkedUser';
import { updateLinkedUsersWithDeletionStatus } from 'src/db/user';
import { generateVerificationToken } from '../invitations/utils';

export enum UserActionTypes {
  GETTING_USER = 'GETTING_USER',
  GOT_USER = 'GOT_USER',
  UPDATING_USER = 'UPDATING_USER',
  UPDATED_USER = 'UPDATED_USER',
  SET_USER = 'SET_USER',
  SET_NATIVE_USER_UID = 'SET_NATIVE_USER_UID',
  UNSET_USER = 'UNSET_USER',
  SET_UNSUB_FN = 'USER_SET_UNSUB_FUNCTION',
  ADDING_ALERT = 'ADDING_ALERT',
  ADDED_ALERT = 'ADDED_ALERT',
  HIDE_GRAPH = 'HIDE_GRAPH',
  SHOW_GRAPH = 'SHOW_GRAPH',
  GRAPH_HIDDEN = 'GRAPH_HIDDEN',
  GOT_INVITED_ROLE = 'GOT_INVITED_ROLE',
  DELETING_USER = 'DELETING_USER',
  RESTORING_USER = 'RESTORING_USER',
  RESTORED_USER = 'RESTORED_USER',
  DELETED_USER = 'DELETED_USER',
}

export type UserActions =
  | SetUserAction
  | SetNativeUserUidAction
  | GettingUserAction
  | GotUserAction
  | SetUserUnsubFunctionAction
  | UpdatingUserAction
  | UpdatedUserAction
  | AddingAlertAction
  | AddedAlertAction
  | HideGraphForUserAction
  | ShowGraphForUserAction
  | GotInvitedRoleAction
  | GraphHiddenAction
  | DeletingUserAction
  | DeletedUserAction
  | RestoringUserAction
  | RestoredUserAction;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GettingUserAction extends Action<UserActionTypes.GETTING_USER> {}

export const getUser = (id: string): AppThunk<Promise<GotUserAction>, UserActions> => {
  return async (dispatch) => {
    dispatch({ type: UserActionTypes.GETTING_USER });

    const usersCollection = Firestore.collection('users');
    const snapshot = await usersCollection.doc(id).get();

    // Check if there is a user doc already
    if (snapshot.exists) {
      // We set the user with the results
      dispatch({
        type: UserActionTypes.SET_USER,
        user: formatUserFromFirestore(snapshot),
      });
    } else {
      // If there is no doc
      const currentUser = getFirebaseUser();
      // Make sure we have a currently authed user, if not we null out the user and exit
      if (currentUser === null) {
        dispatch({
          type: UserActionTypes.SET_USER,
          user: null,
        });

        return dispatch(gotUser());
      }

      // Making sure we have an email for the authed user
      const email = currentUser.email;
      if (email === null) {
        throw new Error('No email on the currently authenticated user!');
      }

      // Then we create our user collection doc, and set the user in the current state
      await usersCollection
        .doc(id)
        .set({
          email,
          cravingsPredictionEnabled: false,
          preferences: {
            hiddenGraphs: [],
          },
          userId: id,
        })
        .catch((err) => console.error(`Error setting new user: ${err}`));

      dispatch({
        type: UserActionTypes.SET_USER,
        user: {
          id,
          email,
          cravingsPredictionEnabled: false,
          preferences: {
            hiddenGraphs: [],
          },
        },
      });
    }

    // Setup our snapshot so that we will be live streaming updates to the user in the database
    dispatch(
      setUnsubFunction(
        usersCollection.doc(id).onSnapshot(
          (snapshot) =>
            dispatch({
              type: UserActionTypes.SET_USER,
              user: formatUserFromFirestore(snapshot),
            }),
          (e) => {
            console.error(e.message);
          },
        ),
      ),
    );

    return dispatch(gotUser());
  };
};

export interface GotInvitedRoleAction extends Action<UserActionTypes.GOT_INVITED_ROLE> {
  type: UserActionTypes.GOT_INVITED_ROLE;
  invitedAsRole: InvitedAsRole;
}

export const updateInvitedAsRole =
  (): AppThunk<Promise<InvitedAsRole | void>, GotInvitedRoleAction> => async (dispatch, getState) => {
    try {
      const user: IUser | null = getState().user.user;
      if (!user) {
        throw new Error('Could not find a user');
      }
      const invitations = await Firestore.collection('invitations')
        .where('email', '==', user.email)
        .get()
        .catch((err) => console.log(`Error finding invitation for ${user.email}: ${err}`));

      if (!invitations || invitations.empty) {
        throw new Error('This user was not invited');
      }

      const invitedAsRole: InvitedAsRole = invitations.docs[0].get('invitedAsRole');
      dispatch({ type: UserActionTypes.GOT_INVITED_ROLE, invitedAsRole });
      return invitedAsRole;
    } catch (err) {
      console.error(`Error updating invitedAsRole: ${err}`);
      throw err;
    }
  };

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GotUserAction extends Action<UserActionTypes.GOT_USER> {}

export const gotUser = (): GotUserAction => {
  return { type: UserActionTypes.GOT_USER };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdatingUserAction extends Action<UserActionTypes.UPDATING_USER> {}
export interface UpdatedUserAction extends Action<UserActionTypes.UPDATED_USER> {
  user: IUser;
}

export const updateUser = (user: IUser): AppThunk<Promise<UpdatedUserAction>, UserActions> => {
  return async (dispatch) => {
    dispatch({ type: UserActionTypes.UPDATING_USER });

    const currentUser = await Firestore.collection('users').doc(user.id).get();
    if (!currentUser.exists) {
      throw new Error('The user provided does not exist, do not use this method to create a new document.');
    }

    const email = currentUser.get('email');
    if (typeof email === 'undefined') {
      throw new Error(
        'The provided user does not yet have an email, do not use this method to add an email to a user that does not have one.',
      );
    }

    try {
      await Firestore.collection('users').doc(user.id).update(formatUserToFirestore(user));
    } catch (err) {
      console.error(`Error updating user: ${err}`);
    }

    // Then we check to see if the email changed and update the Firestore auth as well
    if (email !== user.email) {
      const authCurrentUser = getFirebaseUser();
      if (authCurrentUser === null) {
        throw new Error('No user current authenticated to check');
      }
      if (authCurrentUser.uid !== user.id) {
        throw new Error(
          'The currently authenticated user is not user you are updating. You cannot change the email directly.',
        );
      }
      updateFirebaseUserEmail(authCurrentUser, user.email);
    }

    return dispatch({
      type: UserActionTypes.UPDATED_USER,
      user: { ...user },
    });
  };
};

export const updatePartialUser = (
  id: string,
  userUpdates: Partial<IUser>,
): AppThunk<Promise<UpdatedUserAction>, UserActions> => {
  return async (dispatch) => {
    dispatch({ type: UserActionTypes.UPDATING_USER });

    const currentUser = await Firestore.collection('users').doc(id).get();
    if (!currentUser.exists) {
      throw new Error('The user provided does not exist, do not use this method to create a new document.');
    }

    const email = currentUser.get('email');
    if (typeof email === 'undefined') {
      throw new Error(
        'The provided user does not yet have an email, do not use this method to add an email to a user that does not have one.',
      );
    }

    // If someone is changing their number and stoppedSms is true, set it to false (because that boolean is attached only to the phone number)
    // If they're not just deleting their number, and the phone number they're setting it to is shared by another user and that user's stoppedSms is set to true, set this user's stoppedSms to true and make sure doesWantSms is set to false
    const previousPhone = currentUser.get('phone');
    const previousStoppedSms = currentUser.get('stoppedSms');
    const previousDoesWantSms = currentUser.get('doesWantSms');
    if (userUpdates.phone !== previousPhone) {
      if (previousStoppedSms === true) {
        userUpdates.stoppedSms = false;
      }
      if (userUpdates.phone) {
        const otherUsersWithNewPhone = await Firestore.collection('users')
          .where('phone', '==', userUpdates.phone)
          .get();
        if (!otherUsersWithNewPhone.empty) {
          const anotherUserWithPhone = otherUsersWithNewPhone.docs[0];
          const otherUserStoppedSms = anotherUserWithPhone.get('stoppedSms');
          if (otherUserStoppedSms === true) {
            userUpdates.stoppedSms = true;
            if (userUpdates.doesWantPush === true || previousDoesWantSms === true) {
              userUpdates.doesWantPush = false;
            }
          }
        }
      }
    }

    if (userUpdates.email && userUpdates.email !== email) {
      // add verificationToken for email verification and make sure originalEmail from their invite is saved
      userUpdates.verificationToken = generateVerificationToken();
      if (!currentUser.get('originalEmail')) {
        userUpdates.originalEmail = email;
      }
    }

    try {
      await Firestore.collection('users').doc(id).update(removeUndefined(userUpdates));
    } catch (err) {
      console.error(`Error updating user: ${err}`);
    }

    // Then we check to see if the email changed and update the Firestore auth as well
    if (userUpdates.email && userUpdates.email !== email) {
      const authCurrentUser = getFirebaseUser();
      if (authCurrentUser === null) {
        throw new Error('No user current authenticated to check');
      }
      if (authCurrentUser.uid !== id) {
        throw new Error(
          'The currently authenticated user is not user you are updating. You cannot change the email directly.',
        );
      }
    }

    const userBeforeUpdate = formatUserFromFirestore(currentUser);
    let formattedUserUpdates;
    // if updating user role (to 'pir' or 'cp'), make sure to format the changes set on state to match what is expected by the app
    if (userUpdates.role !== undefined) {
      formattedUserUpdates = { ...userUpdates, role: userUpdates.role === 'cp' ? UserRole.CP : UserRole.USER };
    } else {
      formattedUserUpdates = userUpdates;
    }

    return dispatch({
      type: UserActionTypes.UPDATED_USER,
      user: { ...userBeforeUpdate, ...formattedUserUpdates },
    });
  };
};

export interface SetUserAction extends Action<UserActionTypes.SET_USER> {
  user: IUser | null;
}

export const setUser = (user: IUser | null): SetUserAction => {
  return {
    type: UserActionTypes.SET_USER,
    user,
  };
};

export interface SetNativeUserUidAction extends Action<UserActionTypes.SET_NATIVE_USER_UID> {
  uid: string | null;
}
export const setNativeUserUid = (uid: string | null): SetNativeUserUidAction => {
  return {
    type: UserActionTypes.SET_NATIVE_USER_UID,
    uid,
  };
};

export interface SetUserUnsubFunctionAction extends Action<UserActionTypes.SET_UNSUB_FN> {
  unsubscribe: null | firebase.Unsubscribe;
}

export const setUnsubFunction = (fn: null | firebase.Unsubscribe): SetUserUnsubFunctionAction => {
  return {
    type: UserActionTypes.SET_UNSUB_FN,
    unsubscribe: fn,
  };
};

/**
 * Calls the unsubscribe function on the snapshot listener and removes it from state
 */
export const stopSnapshotListener = (): AppThunkSync<SetUserUnsubFunctionAction> => {
  return (dispatch, getState) => {
    const unsubscribeFn = getState().user.unsubscribeFn;
    if (unsubscribeFn) {
      unsubscribeFn();
    }

    return dispatch({
      type: UserActionTypes.SET_UNSUB_FN,
      unsubscribe: null,
    });
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AddingAlertAction extends Action<UserActionTypes.ADDING_ALERT> {}
const addingAlert = (): AddingAlertAction => ({ type: UserActionTypes.ADDING_ALERT });

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AddedAlertAction extends Action<UserActionTypes.ADDED_ALERT> {}
const addedAlert = (): AddedAlertAction => ({ type: UserActionTypes.ADDED_ALERT });

export const addAlert = (type: 'sos' | 'warnProvider'): AppThunk<Promise<AddedAlertAction>, UserActions> => {
  return async (dispatch, getState) => {
    dispatch(addingAlert());

    const luPir = getState().linkedUsers.selectedLinkedUser?.pir;
    const person = getState().user.user?.name ?? undefined;

    if (!luPir) {
      throw new Error('No PIR in state during alert');
    }

    await Firestore.collection('alerts').doc().set({
      type,
      pir: luPir,
      created: FirestoreUtils.now(),
      name: person,
    });

    return dispatch(addedAlert());
  };
};

export type HideGraphForUserAction = Action<UserActionTypes.HIDE_GRAPH>;
export interface GraphHiddenAction extends Action<UserActionTypes.GRAPH_HIDDEN> {
  hiddenGraphs?: string[];
}

export const hideGraph = (graph: GraphType, selectedUser?: IUser): AppThunkPromise<GraphHiddenAction> => {
  return async (dispatch, getState) => {
    const user = selectedUser ?? getState().user.user;

    if (!user) {
      return dispatch({
        type: UserActionTypes.GRAPH_HIDDEN,
      });
    }

    const userHiddenGraphs = Array.from(user.preferences.hiddenGraphs);
    const graphIndex = userHiddenGraphs.indexOf(graph);
    if (graphIndex === -1) {
      userHiddenGraphs.push(graph);
      await Firestore.collection('users').doc(user.id).update('preferences.hiddenGraphs', userHiddenGraphs);
      if (selectedUser) {
        selectedUser.preferences.hiddenGraphs = userHiddenGraphs;
      }
    }

    return dispatch({
      type: UserActionTypes.GRAPH_HIDDEN,
      hiddenGraphs: userHiddenGraphs,
    });
  };
};

export type ShowGraphForUserAction = Action<UserActionTypes.SHOW_GRAPH>;

export const showGraph = (graph: GraphType, selectedUser?: IUser): AppThunkPromise<GraphHiddenAction> => {
  return async (dispatch, getState) => {
    const user = selectedUser ?? getState().user.user;

    if (!user) {
      return dispatch({
        type: UserActionTypes.GRAPH_HIDDEN,
      });
    }

    const userHiddenGraphs = Array.from(user.preferences.hiddenGraphs);
    const graphIndex = userHiddenGraphs.indexOf(graph);
    if (graphIndex > -1) {
      userHiddenGraphs.splice(graphIndex, 1);
      await Firestore.collection('users').doc(user.id).update('preferences.hiddenGraphs', userHiddenGraphs);
      if (selectedUser) {
        selectedUser.preferences.hiddenGraphs = userHiddenGraphs;
      }
    }

    return dispatch({
      type: UserActionTypes.GRAPH_HIDDEN,
      hiddenGraphs: userHiddenGraphs,
    });
  };
};

export const updateUserTimezone = (): AppThunk<void, UpdatedUserAction> => async (dispatch, getState) => {
  const user: IUser | null = getState().user.user;
  if (!user) return;
  if (user.manuallySetTimezone !== true) {
    const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (currentTz !== user.timezone) {
      await dispatch(updatePartialUser(user.id, { timezone: currentTz }));
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeletingUserAction extends Action<UserActionTypes.DELETING_USER> {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeletedUserAction extends Action<UserActionTypes.DELETED_USER> {}

export const deleteLoggedInUser = (
  user: IUser,
  linkedUsers: PossibleLinkedUser[],
  isPir: boolean,
): AppThunk<Promise<DeletedUserAction>, UserActions> => {
  return async (dispatch) => {
    dispatch({ type: UserActionTypes.DELETING_USER });

    const uid = user.id;
    await Firestore.collection('users').doc(uid).update({
      deletedDatetime: firebase.firestore.FieldValue.serverTimestamp(),
      deletionStatus: DeletionStatus.PENDING,
      cravingsPredictionEnabled: false,
      doesWantEmail: false,
      doesWantPush: false,
      doesWantSms: false,
      fcmToken: firebase.firestore.FieldValue.delete(),
    });

    // dispatch({ type: UserActionTypes.DELETING_USER });

    // add deletion status to user invitation for ease of access for admin dashboard
    const userInvitationSnapshot = await Firestore.collection('invitations')
      .where('email', '==', user.email)
      .get()
      .catch((err) => console.log(`Error finding invitation for ${user.email}: ${err}`));

    if (userInvitationSnapshot && !userInvitationSnapshot.empty) {
      const docId = userInvitationSnapshot.docs[0].id;
      await Firestore.collection('invitations').doc(docId).update({ deletionStatus: DeletionStatus.PENDING });
    }

    await updateLinkedUsersWithDeletionStatus(true, linkedUsers, isPir);

    return dispatch({ type: UserActionTypes.DELETED_USER });
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RestoringUserAction extends Action<UserActionTypes.RESTORING_USER> {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RestoredUserAction extends Action<UserActionTypes.RESTORED_USER> {}

export const restoreLoggedInUser = (
  user: IUser,
  linkedUsers: PossibleLinkedUser[],
  isPir: boolean,
): AppThunk<Promise<RestoredUserAction>, UserActions> => {
  return async (dispatch) => {
    dispatch({ type: UserActionTypes.RESTORING_USER });

    try {
      await Firestore.collection('users').doc(user.id).update({
        deletedDatetime: firebase.firestore.FieldValue.delete(),
        deletionStatus: firebase.firestore.FieldValue.delete(),
      });

      // add deletion status to user invitation for ease of access for admin dashboard
      const userInvitationSnapshot = await Firestore.collection('invitations')
        .where('email', '==', user.email)
        .get()
        .catch((err) => console.log(`Error finding invitation for ${user.email}: ${err}`));

      if (userInvitationSnapshot && !userInvitationSnapshot.empty) {
        const docId = userInvitationSnapshot.docs[0].id;
        await Firestore.collection('invitations').doc(docId).update({ deletionStatus: 'RESTORED' });
      }

      await updateLinkedUsersWithDeletionStatus(false, linkedUsers, isPir);

      return dispatch({ type: UserActionTypes.RESTORED_USER });
    } catch (error: any) {
      throw new Error(error.message || 'Error restoring user');
    }
  };
};
