import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Flex, VStack, Text } from '@chakra-ui/react';
import IUser, { UserRole } from '../../modules/user/interfaces/IUser';
import { useHistory } from 'react-router-dom';
import { Center } from '@chakra-ui/layout';
import PrimaryButton from '../../components/PrimaryButton';
import { SecondaryButton } from '../../components/SecondaryButton';
import LogOut from './LogOut';
import { Route } from 'react-router-dom';

import { PossibleLinkedUser } from '../linked-users/interfaces/ILinkedUser';
import { getAllLinkedUsersIncludingDeleted } from '../linked-users/actions';
import { restoreLoggedInUser } from '../user/actions';
import { connect, ConnectedProps } from 'react-redux';

interface RestoreAccountProps {
  user: IUser;
  pirLinkedUsers?: PossibleLinkedUser[];
}

type Props = RestoreAccountProps & PropsFromRedux;

const RestoreAccountInfo = (props: Props): JSX.Element | null => {
  const { t } = useTranslation('auth');

  const history = useHistory();
  const [error, setError] = useState<string | null>(null);
  const [allLinkedUsers, setAllLinkedUsers] = useState<PossibleLinkedUser[]>([]);

  useEffect(() => {
    getAllLinkedUsersIncludingDeletedLU();
  }, []);

  const getAllLinkedUsersIncludingDeletedLU = async () => {
    const allLU = await getAllLinkedUsersIncludingDeleted(props.user.id, isPir);
    setAllLinkedUsers(allLU);
  };

  const RESTORATION_PERIOD = 31; // days

  const today = new Date();

  const isPir = props.user.role === UserRole.USER ? true : false;

  const onRestore = async () => {
    try {
      await props.restoreLoggedInUser(props.user, allLinkedUsers, isPir);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
      console.log(`Error restoring user: ${error}`);
    }
  };

  const onBack = () => {
    history.push('/logout');
  };

  const getDaysToDeletion = (deleteInitiateDate: Date): number => {
    const diff = today.getTime() - deleteInitiateDate.getTime();
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    return RESTORATION_PERIOD - Math.floor(diff / millisecondsInADay);
  };

  const deleteInitDate = props.user.deletedDatetime;

  // doesn't happen because this component is only used
  // if user.deleteInitDate is defined
  if (!deleteInitDate) {
    return null;
  }

  const daysCount = getDaysToDeletion(deleteInitDate);

  return (
    <Center>
      <VStack py="40px" px="20px">
        <Heading as="h2" fontSize={[24, 24, 36, 45]} textAlign="center" fontWeight={400} color="purple3.600" mb="40px">
          {t('restoreAccountInfo.header')}
        </Heading>
        <Route path="/logout" component={LogOut} />
        {error ? (
          <Text fontSize="16px" color="red">
            {'Error: ' + error}
          </Text>
        ) : null}
        {daysCount >= 0 ? (
          <VStack w="100%" spacing="10px" mt="10px">
            <Text fontSize="16px" textAlign="center" w="100%">
              {daysCount === 0
                ? t('restoreAccountInfo.deleteDaysNoticeToday')
                : t('restoreAccountInfo.deleteDaysNotice', { numDays: daysCount })}
            </Text>
            <Flex w="100%" pt="20px" pb="5px" alignItems="center" justifyContent="center">
              <SecondaryButton w="200px" onClick={onBack} alignItems="center">
                {t('restoreAccountInfo.backButton')}
              </SecondaryButton>
            </Flex>
            <Flex w="100%" py="5px" alignItems="center" justifyContent="center">
              <PrimaryButton w="200px" onClick={onRestore} alignItems="center">
                {t('restoreAccountInfo.restoreButton')}
              </PrimaryButton>
            </Flex>
          </VStack>
        ) : (
          <VStack w="100%">
            {daysCount < 0 ? (
              <Text fontSize="16px" w="100%" textAlign="center">
                {t('restoreAccountInfo.alreadyDeleted')}
              </Text>
            ) : null}
            <Flex w="100%" pt="20px" pb="5px" alignItems="center" justifyContent="center">
              <SecondaryButton w="200px" onClick={onBack} alignItems="center">
                {t('restoreAccountInfo.backButton')}
              </SecondaryButton>
            </Flex>
          </VStack>
        )}
      </VStack>
    </Center>
  );
};

const mapDispatchToProps = {
  restoreLoggedInUser,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RestoreAccountInfo);
