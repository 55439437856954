import { library } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition, IconName } from '@fortawesome/fontawesome-common-types';
import {
  faBars,
  faQuestionCircle,
  faInfoCircle,
  faHome,
  faStream,
  faComment,
  faCamera,
  faMapMarkerAlt,
  faBan,
  faBell,
  faExclamationTriangle,
  faExclamationCircle,
  faListOl,
  faUserCircle,
  faArrowCircleRight,
  faArrowRight,
  faArrowDown,
  faArrowUp,
  faCaretUp,
  faCaretDown,
  faStar,
  faPlus,
  faCog,
  faBookOpen,
  faFileAlt,
  faCheck,
  faSpa,
  faMortarPestle,
  faPollH,
  faHeartbeat,
  faExclamation,
  faLifeRing,
  faTrashAlt,
  faCalendarPlus,
  faCalendarCheck,
  faSignOutAlt,
  faUser,
  faUsers,
  faList,
  faAngleRight,
  faAngleLeft,
  faChartBar,
  faBed,
  faBrain,
  faNotesMedical,
  faMobileAlt,
  faUserFriends,
  faFireAlt,
  faSyncAlt,
  faMinusCircle,
  faPlusCircle,
  faGlobe,
  faBook,
  faVideo,
  faImage,
  faCapsules,
  faChevronCircleDown,
  faChevronCircleUp,
  faClock,
  faEnvelope,
  faCircle,
  faPlusSquare,
  faXmark,
  faAngleDown,
  faInbox,
  faChevronDown,
  faFileMedical,
  faBellSlash,
  faMusic,
} from '@fortawesome/free-solid-svg-icons';

// Custom icons created by Behaivior team
const faWearable: IconDefinition = {
  prefix: 'fas',
  iconName: 'wearable' as IconName,
  icon: [
    640,
    640,
    [],
    'e001',
    'M 293.75,35.75 C 292.00,36.00 285.62,36.88 279.38,37.62 253.62,40.87 224.75,49.62 198.12,62.37 167.62,77.00 145.25,92.50 122.00,115.00 75.62,159.87 46.88,217.50 37.38,285.00 35.12,300.88 35.38,341.50 37.88,358.88 44.50,404.62 61.88,448.62 87.12,483.88 97.00,497.75 100.00,500.25 107.12,500.88 117.38,501.88 125.00,494.88 125.00,484.25 125.00,480.00 123.88,477.50 118.38,469.88 85.25,423.75 68.75,373.88 68.75,320.00 68.75,214.25 134.25,120.62 234.00,83.25 248.75,77.75 271.25,72.12 287.50,69.87 309.38,66.88 361.00,68.50 367.38,72.37 368.25,72.87 370.50,79.12 372.62,86.12 379.75,111.00 384.50,116.62 408.25,128.12 417.50,132.62 431.00,140.37 438.38,145.25 454.25,155.88 476.88,177.25 488.12,192.37 499.38,207.25 511.62,228.38 515.75,239.62 518.75,247.87 520.50,250.62 527.38,257.50 534.25,264.38 538.38,267.12 552.62,274.12 572.62,284.00 570.75,280.50 572.00,310.62 577.25,438.75 482.88,551.50 355.12,569.25 337.00,571.88 304.25,571.88 286.12,569.25 253.50,564.75 220.38,553.25 193.12,536.88 181.38,529.88 175.25,528.75 168.88,532.25 159.50,537.38 157.38,550.12 164.50,557.75 166.50,559.75 173.62,564.50 180.25,568.25 257.75,612.25 354.50,616.88 436.88,580.38 475.75,563.25 516.00,532.38 542.38,499.38 569.75,465.25 590.62,421.62 599.25,380.37 604.00,357.25 605.38,343.62 605.25,318.75 605.12,261.37 589.25,208.62 558.12,161.88 514.12,95.75 446.00,51.88 366.25,38.00 355.00,36.12 301.62,34.38 293.75,35.75 Z',
  ],
};

export default (): void => {
  library.add(
    faBan,
    faBars,
    faQuestionCircle,
    faInfoCircle,
    faHome,
    faStream,
    faComment,
    faCamera,
    faMapMarkerAlt,
    faBell,
    faExclamationTriangle,
    faExclamationCircle,
    faListOl,
    faLifeRing,
    faUserCircle,
    faArrowCircleRight,
    faArrowRight,
    faArrowDown,
    faArrowUp,
    faCaretUp,
    faCaretDown,
    faStar,
    faPlus,
    faCog,
    faBookOpen,
    faFileAlt,
    faCheck,
    faSpa,
    faMortarPestle,
    faPollH,
    faHeartbeat,
    faExclamation,
    faTrashAlt,
    faCalendarPlus,
    faCalendarCheck,
    faSignOutAlt,
    faUser,
    faUsers,
    faList,
    faAngleRight,
    faAngleLeft,
    faChartBar,
    faBed,
    faBrain,
    faNotesMedical,
    faMobileAlt,
    faUserFriends,
    faFireAlt,
    faSyncAlt,
    faMinusCircle,
    faPlusCircle,
    faGlobe,
    faBook,
    faVideo,
    faImage,
    faCapsules,
    faChevronCircleDown,
    faChevronCircleUp,
    faMobileAlt,
    faClock,
    faEnvelope,
    faCircle,
    faPlusSquare,
    faAngleDown,
    faInbox,
    faChevronDown,
    faFileMedical,
    faBell,
    faBellSlash,
    // Custom icons
    faWearable,
    faXmark,
    faMusic,
  );
};
