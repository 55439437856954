import React, { useContext, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import ResourceModule from '../resources/ResourceModule';
import MeditationModule from '../meditations/MeditationModule';
import MedicationModule from '../medications/MedicationModule';
import TodoModule from '../todo/TodoModule';
import PirContactCard from '../linked-users/PirContactCard';
import SupportRequestsModule from '../supportRequests/SupportRequestsModule';
import AppointmentModule from '../appointments/AppointmentModule';
import ChangeLinkedUser from '../interior/ChangeLinkedUser';
import { Center } from '@chakra-ui/layout';
import { SelectedUserContext } from '../interior/Index';
import VitalsSummaryModule from '../vitals/VitalsSummaryModule';

const ProviderDashboard = (props: PropsFromRedux): JSX.Element => {
  const { t } = useTranslation('home');
  const { selectedUser } = useContext(SelectedUserContext);
  const [pirName, setPirName] = useState<string | undefined>(undefined);
  const [pirAlias, setPirAlias] = useState<string | undefined>(undefined);
  const { selectedLinkedUser, preferredName } = props;

  const changeModalDisclosure = useDisclosure();

  const CARD_SPACING = '40px';

  useEffect(() => {
    if (selectedUser) {
      const name = pirAlias
        ? pirAlias
        : selectedLinkedUser && 'pirAlias' in selectedLinkedUser && selectedLinkedUser.pirAlias
        ? selectedLinkedUser.pirAlias
        : preferredName && preferredName.length
        ? preferredName
        : selectedUser.name
        ? selectedUser.name
        : selectedUser.email
        ? selectedUser.email
        : 'this person';
      setPirName(name);
    }
  }, [pirAlias, selectedUser]);

  return (
    <Center>
      <Box mx="auto" width="100%" px={['18px', '36px']} pt="26px">
        <Box justifyContent="left" fontSize={20} color="black" fontWeight="bold" mb={CARD_SPACING}>
          {t('providerDash.heading', {
            name: pirName,
          })}
        </Box>
        <Flex flexWrap="wrap" alignItems="flex-start" justifyContent="space-between" maxW="1200px">
          <Flex
            minWidth="0px"
            flexDirection={['column', 'column', 'row']}
            flexBasis="100%"
            mb={['0px', '0px', CARD_SPACING]}
          >
            <Box mb={[CARD_SPACING, CARD_SPACING, '0px']}>
              <PirContactCard pirAlias={pirAlias} setPirAlias={setPirAlias} />
            </Box>
            <Flex
              minWidth="0px"
              flexDirection="column"
              flexGrow={1}
              flexBasis={['100%', '100%', '50%']}
              pl={['0px', '0px', '30px']}
            >
              <Box mb={[CARD_SPACING, CARD_SPACING, '33px']}>
                <SupportRequestsModule />
              </Box>
              <Box mb={[CARD_SPACING, CARD_SPACING, '0px']}>
                <VitalsSummaryModule />
              </Box>
            </Flex>
          </Flex>
          {/* <Box minWidth="0px" flexBasis={['100%', '100%']} mb={5}>
            </Box> */}
          <Box minWidth="0px" flexBasis={['100%', '100%']} mb={CARD_SPACING}>
            <TodoModule isCP={true} />
          </Box>
          <Box minWidth="0px" flexBasis={['100%', '100%']} mb={CARD_SPACING}>
            <AppointmentModule isPir={false} />
          </Box>
          <Box minWidth="0px" flexBasis={['100%', '100%']} mb={CARD_SPACING}>
            <MedicationModule />
          </Box>
          <Box minWidth="0px" flexBasis={['100%', '100%']} mb={CARD_SPACING}>
            <ResourceModule isPir={false} />
          </Box>
          <Box minWidth="0px" flexBasis={['100%', '100%']} mb={CARD_SPACING}>
            <MeditationModule isPir={false} />
          </Box>
        </Flex>
        <ChangeLinkedUser
          isOpen={changeModalDisclosure.isOpen}
          onClose={changeModalDisclosure.onClose}
          onSelect={() => {
            changeModalDisclosure.onClose();
          }}
        />
      </Box>
    </Center>
  );
};

const mapStateToProps = (state: RootState) => {
  const selectedLinkedUser = state.linkedUsers.selectedLinkedUser;

  return {
    selectedLinkedUser,
    otherLinkedUser: state.linkedUsers.otherLinkedUsers ?? [],
    preferredName: selectedLinkedUser && 'preferredName' in selectedLinkedUser && selectedLinkedUser.preferredName,
    supportNetwork: state.linkedUsers.supportNetwork,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProviderDashboard);
