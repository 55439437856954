import firebase from 'firebase/compat/app';
import IUser from '../user/interfaces/IUser';
import ILinkedUser, {
  PossibleLinkedUser,
  IPirLinkedUser,
  IIncompleteLinkedUser,
  LinkedUserRole,
  ISupporterLinkedUser,
  IRejectedLinkedUser,
} from './interfaces/ILinkedUser';
import { FirestoreUtils } from '../firestore/Firestore';
import { INewLinkedUser } from './interfaces/ILinkedUser';
import { CreatedLinkedUserAction } from '../linked-users/actions';

export const isPirLinkedUser = (linkedUser: PossibleLinkedUser): linkedUser is IPirLinkedUser => {
  const assumedLinkedUser: ILinkedUser | IIncompleteLinkedUser = linkedUser as ILinkedUser | IIncompleteLinkedUser;

  return (
    typeof assumedLinkedUser.otherUser === 'undefined' &&
    typeof assumedLinkedUser.otherUserRole === 'undefined' &&
    typeof assumedLinkedUser.reviewedByOther === 'undefined'
  );
};

export const isDeletedLinkedUser = (linkedUser: PossibleLinkedUser) => {
  return linkedUser.deletedPir === true || (!isPirLinkedUser(linkedUser) && linkedUser.deletedCp);
};

export const isSupporterLinkedUser = (linkedUser: PossibleLinkedUser): linkedUser is ISupporterLinkedUser => {
  const assumedLinkedUser: ILinkedUser | IIncompleteLinkedUser = linkedUser as ILinkedUser | IIncompleteLinkedUser;

  return assumedLinkedUser.otherUserRole === LinkedUserRole.SUPPORTER;
};

export const isIncompleteLinkedUser = (linkedUser: PossibleLinkedUser): linkedUser is IIncompleteLinkedUser => {
  return (
    !isPirLinkedUser(linkedUser) &&
    !isSupporterLinkedUser(linkedUser) &&
    (!linkedUser.reviewedByOther || !linkedUser.reviewedByPir)
  );
};

export const isLinkedUser = (linkedUser: PossibleLinkedUser): linkedUser is ILinkedUser => {
  return (
    !isSupporterLinkedUser(linkedUser) &&
    !isPirLinkedUser(linkedUser) &&
    linkedUser.approvedByOther &&
    linkedUser.approvedByPir
  );
};

export const isRejectedLinkedUser = (linkedUser: PossibleLinkedUser): linkedUser is IRejectedLinkedUser => {
  return (
    !isPirLinkedUser(linkedUser) &&
    linkedUser.reviewedByOther &&
    linkedUser.reviewedByPir &&
    (linkedUser.approvedByOther === false || linkedUser.approvedByPir === false)
  );
};

export const formatLinkedUserFromFirestore = (
  snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
): PossibleLinkedUser => {
  const otherUser = snapshot.get('otherUser');
  const otherUserRole = snapshot.get('otherUserRole');
  const reviewedByOther = snapshot.get('reviewedByOther');
  const dateReviewedByOther = snapshot.get('dateReviewedByOther');

  if (
    typeof otherUser === 'undefined' &&
    typeof otherUserRole === 'undefined' &&
    typeof reviewedByOther === 'undefined' &&
    typeof dateReviewedByOther === 'undefined'
  ) {
    return {
      id: snapshot.id,
      pir: snapshot.get('pir'),
      default: snapshot.get('default'),
      approvedByPir: snapshot.get('approvedByPir'),
      reviewedByPir: snapshot.get('reviewedByPir'),
      dateSubmitted: snapshot.get('dateSubmitted'),
      dateReviewedByPir: snapshot.get('dateReviewedByPir'),
      deletedPir: snapshot.get('deletedPir'),
    };
  }

  const reviewedByPir = snapshot.get('reviewedByPir');
  const approvedByOther = snapshot.get('approvedByOther') || false;
  const approvedByPir = snapshot.get('approvedByPir') || false;
  const out: IIncompleteLinkedUser | ILinkedUser | ISupporterLinkedUser | IRejectedLinkedUser = {
    id: snapshot.id,
    pir: snapshot.get('pir'),
    otherUser,
    otherUserRole,
    default: snapshot.get('default'),
    notificationOnSOS: snapshot.get('notificationOnSOS'),
    approvedByPir: snapshot.get('approvedByPir') ?? false,
    approvedByOther: snapshot.get('approvedByOther') ?? false,
    dateSubmitted: snapshot.get('dateSubmitted'),
    dateReviewedByPir: snapshot.get('dateReviewedByPir'),
    dateReviewedByOther,
    pirAlias: snapshot.get('pirAlias'),
    contactAlias: snapshot.get('contactAlias'),
    preferredName: snapshot.get('preferredName'),
    relationship: snapshot.get('relationship'),
    reviewedByPir,
    reviewedByOther,
    supporteeNumber: snapshot.get('supporteeNumber'),
    deletedPir: snapshot.get('deletedPir'),
    deletedCp: snapshot.get('deletedCp'),
  };

  if (reviewedByPir === true && reviewedByOther === true && approvedByPir === true && approvedByOther === true) {
    return out;
  } else if (otherUserRole === LinkedUserRole.SUPPORTER || isRejectedLinkedUser(out)) {
    return {
      ...out,
      otherUserEmail: snapshot.get('otherUserEmail'),
      otherUserPhone: snapshot.get('otherUserPhone'),
      phoneAuthorization: snapshot.get('phoneAuthorization'),
    };
  } else {
    return {
      ...out,
      email: snapshot.get('email'),
      name: snapshot.get('name'),
      phone: snapshot.get('phone'),
    };
  }
};

export const formatLinkedUserToFirestore = (linkedUser: PossibleLinkedUser): Omit<PossibleLinkedUser, 'id'> => {
  // If we're formatting a PIR Linked User
  if (isPirLinkedUser(linkedUser)) {
    const out: Omit<IPirLinkedUser, 'id'> = {
      pir: linkedUser.pir,
      default: true,
      approvedByPir: linkedUser.approvedByPir,
      reviewedByPir: linkedUser.reviewedByPir,
      dateSubmitted: linkedUser.dateSubmitted,
    };

    if (linkedUser.dateReviewedByPir) {
      out.dateReviewedByPir = linkedUser.dateReviewedByPir;
    }

    if (linkedUser.deletedPir) {
      out.deletedPir = linkedUser.deletedPir;
    }

    return out;
  }

  // If we're formatting a supporter Linked User
  if (isSupporterLinkedUser(linkedUser)) {
    const out: Omit<ISupporterLinkedUser, 'id'> = {
      pir: linkedUser.pir,
      default: false,
      approvedByPir: linkedUser.approvedByPir,
      reviewedByPir: linkedUser.reviewedByPir,
      dateSubmitted: linkedUser.dateSubmitted,
      otherUserRole: linkedUser.otherUserRole,
      dateReviewedByPir: linkedUser.dateReviewedByPir,
      contactAlias: linkedUser.contactAlias,
      preferredName: linkedUser.preferredName,
      otherUserEmail: linkedUser.otherUserEmail,
    };

    if (linkedUser.otherUserPhone) {
      out.otherUserPhone = linkedUser.otherUserPhone;
    }

    if (linkedUser.supporteeNumber) {
      out.supporteeNumber = linkedUser.supporteeNumber;
    }

    if (linkedUser.phoneAuthorization) {
      out.phoneAuthorization = linkedUser.phoneAuthorization;
    }

    if (linkedUser.notificationOnSOS) {
      out.notificationOnSOS = linkedUser.notificationOnSOS;
    }

    if (linkedUser.relationship) {
      out.relationship = linkedUser.relationship;
    }

    if (linkedUser.deletedPir) {
      out.deletedPir = linkedUser.deletedPir;
    }

    return out;
  }

  // If we're formatting a completed LinkedUser
  if (isLinkedUser(linkedUser)) {
    const out: Omit<ILinkedUser, 'id'> = {
      pir: linkedUser.pir,
      otherUser: linkedUser.otherUser,
      otherUserRole: linkedUser.otherUserRole,
      default: false,
      reviewedByPir: linkedUser.reviewedByPir,
      reviewedByOther: linkedUser.reviewedByOther,
      dateReviewedByPir: linkedUser.dateReviewedByPir,
      dateReviewedByOther: linkedUser.dateReviewedByOther,
      approvedByPir: linkedUser.approvedByPir,
      approvedByOther: linkedUser.approvedByOther,
      dateSubmitted: linkedUser.dateSubmitted,
    };

    if (linkedUser.contactAlias) {
      out.contactAlias = linkedUser.contactAlias;
    }

    if (linkedUser.pirAlias) {
      out.pirAlias = linkedUser.pirAlias;
    }

    if (linkedUser.preferredName) {
      out.preferredName = linkedUser.preferredName;
    }

    if (linkedUser.relationship) {
      out.relationship = linkedUser.relationship;
    }

    if ('notificationOnSOS' in linkedUser) {
      out.notificationOnSOS = linkedUser.notificationOnSOS;
    }

    if (linkedUser.deletedPir) {
      out.deletedPir = linkedUser.deletedPir;
    }

    if (linkedUser.deletedCp) {
      out.deletedCp = linkedUser.deletedCp;
    }

    return out;
  }

  // If we're formatting a rejected Linkeduser
  if (isRejectedLinkedUser(linkedUser)) {
    const out: Omit<IRejectedLinkedUser, 'id'> = {
      pir: linkedUser.pir,
      otherUser: linkedUser.otherUser,
      otherUserRole: linkedUser.otherUserRole,
      default: false,
      reviewedByPir: linkedUser.reviewedByPir,
      reviewedByOther: linkedUser.reviewedByOther,
      dateReviewedByPir: linkedUser.dateReviewedByPir,
      dateReviewedByOther: linkedUser.dateReviewedByOther,
      approvedByPir: linkedUser.approvedByPir,
      approvedByOther: linkedUser.approvedByOther,
      dateSubmitted: linkedUser.dateSubmitted,
    };

    if (linkedUser.contactAlias) {
      out.contactAlias = linkedUser.contactAlias;
    }

    if (linkedUser.pirAlias) {
      out.pirAlias = linkedUser.pirAlias;
    }

    if (linkedUser.preferredName) {
      out.preferredName = linkedUser.preferredName;
    }

    if (linkedUser.relationship) {
      out.relationship = linkedUser.relationship;
    }

    if (linkedUser.otherUserEmail) {
      out.otherUserEmail = linkedUser.otherUserEmail;
    }

    if (linkedUser.otherUserPhone) {
      out.otherUserPhone = linkedUser.otherUserPhone;
    }

    if (linkedUser.deletedPir) {
      out.deletedPir = linkedUser.deletedPir;
    }

    if (linkedUser.deletedCp) {
      out.deletedCp = linkedUser.deletedCp;
    }

    return out;
  }

  // If we're formatting an Incomplete Linked User
  const out: Omit<IIncompleteLinkedUser, 'id'> = {
    default: false,
    reviewedByPir: linkedUser.reviewedByPir,
    reviewedByOther: linkedUser.reviewedByOther,
    approvedByPir: linkedUser.approvedByPir,
    approvedByOther: linkedUser.approvedByOther,
    dateSubmitted: linkedUser.dateSubmitted,
  };

  if (linkedUser.pir) {
    out.pir = linkedUser.pir;
  }

  if (linkedUser.otherUser) {
    out.otherUser = linkedUser.otherUser;
  }

  if (linkedUser.otherUserRole) {
    out.otherUserRole = linkedUser.otherUserRole;
  }

  if (linkedUser.dateReviewedByPir) {
    out.dateReviewedByPir = linkedUser.dateReviewedByPir;
  }

  if (linkedUser.dateReviewedByOther) {
    out.dateReviewedByOther = linkedUser.dateReviewedByOther;
  }

  if (linkedUser.contactAlias) {
    out.contactAlias = linkedUser.contactAlias;
  }

  if (linkedUser.pirAlias) {
    out.pirAlias = linkedUser.pirAlias;
  }

  if (linkedUser.preferredName) {
    out.preferredName = linkedUser.preferredName;
  }

  if (linkedUser.relationship) {
    out.relationship = linkedUser.relationship;
  }

  if ('notificationOnSOS' in linkedUser) {
    out.notificationOnSOS = linkedUser.notificationOnSOS;
  }

  if (linkedUser.email) {
    out.email = linkedUser.email;
  }

  if (linkedUser.name) {
    out.name = linkedUser.name;
  }

  if (linkedUser.otherUserEmail) {
    out.otherUserEmail = linkedUser.otherUserEmail;
  }

  if (linkedUser.deletedPir) {
    out.deletedPir = linkedUser.deletedPir;
  }

  if (linkedUser.deletedCp) {
    out.deletedCp = linkedUser.deletedCp;
  }

  return out;
};

export const isAlreadyProvider = (linkedUsers: PossibleLinkedUser[], user: IUser): boolean => {
  return (
    linkedUsers.find((linkedUser) => {
      if (isPirLinkedUser(linkedUser) || isSupporterLinkedUser(linkedUser)) {
        return false;
      }

      return (
        linkedUser.otherUser &&
        linkedUser.otherUser.id === user.id &&
        linkedUser.otherUserRole &&
        linkedUser.otherUserRole === LinkedUserRole.PROVIDER
      );
    }) !== undefined
  );
};

export const getDefaultLinkedUser = (rawLinkedUsers: PossibleLinkedUser[]): PossibleLinkedUser | null => {
  // For right now, as of 9/12/23, I am only getting default linked user if it's a pir, as we decide how we want to use default linked user going forward
  const linkedUsers = rawLinkedUsers.filter((lu) => isPirLinkedUser(lu));

  if (linkedUsers.length === 1) {
    return linkedUsers[0];
  } else {
    for (const linkedUser of linkedUsers) {
      if (isPirLinkedUser(linkedUser)) {
        return linkedUser;
      }
    }
  }
  return null;
};

export const createLinkedUserForInvitee = async (
  createLinkedUser: (linkedUser: INewLinkedUser) => Promise<CreatedLinkedUserAction>,
  user: IUser,
  email: string,
  pirAlias: string,
): Promise<void> => {
  await createLinkedUser({
    default: false,
    otherUser: FirestoreUtils.getDocRef('users', user.id),
    otherUserRole: LinkedUserRole.PROVIDER,
    approvedByOther: true,
    reviewedByOther: true,
    dateSubmitted: FirestoreUtils.now(),
    dateReviewedByOther: FirestoreUtils.now(),
    approvedByPir: false,
    name: user.name ?? '',
    email,
    pirAlias,
    otherUserEmail: user.email,
    notificationOnSOS: true,
  });
};
