import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import FAIcon from '../../../components/FAIcon';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

interface Props {
  graphType: string;
  icon: IconName;
}

const VitalGraphSubHeading = ({ graphType, icon }: Props): JSX.Element => {
  const { t } = useTranslation('vitals');

  return (
    <Flex direction="column" bg="white" borderTopRadius="md">
      <Flex direction="row" justify="center" align="center" mb={['', '5']} mt="25px">
        <FAIcon icon={icon} fontSize={[22, 30]} color="#5C4279" bg="white" mr={[2, 4]} mt={0} />
        <Heading as="h3" fontSize={[16, 25]} fontWeight="bold">
          {t(`vitalsModule.${graphType}Display.title`)}
        </Heading>
      </Flex>
    </Flex>
  );
};
export default VitalGraphSubHeading;
