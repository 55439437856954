import { where, setDoc, addDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { documentRef, collectionRef, queryDocs } from '../modules/firebase/firestore';
import IMedication,{ IMedCalArgs } from '../modules/medications/interfaces/IMedication';
import { formatMedicationFromFirestore, formatMedicationToFirestore } from '../modules/medications/utils';

export const getMedications = async (pirId: string): Promise<IMedication[]> => {
  const docRef = documentRef('users', pirId);
  const querySnapshot = await queryDocs('medications', where('pir', '==', docRef));
  return querySnapshot.docs.map((doc) => formatMedicationFromFirestore(doc));
};

export const createMedication = async (rawMedication: Omit<IMedication, 'id'>): Promise<void> => {
  const medication = formatMedicationToFirestore(rawMedication);
  await addDoc(collectionRef('medications'), medication);
};

export const deleteMedication = async (medication: IMedication): Promise<void>  => {
  await deleteDoc(documentRef('medications', medication.id as string));
};

export const updateMedication = async (medication: IMedication): Promise<void>  => {
  await setDoc(documentRef('medications', medication.id as string), formatMedicationToFirestore(medication));
};

export const addToMedCal = async (
  medication: IMedication,
  newMedValues: IMedCalArgs,
): Promise<void>  => {
  const docRef = documentRef('medications', medication.id as string);
  await updateDoc(docRef, { ...newMedValues });
};
