import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Select,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Field from '../../components/Field';
import { FieldValues, useForm, SubmitHandler } from 'react-hook-form';
import PrimaryButton from '../../components/PrimaryButton';
import Firestore from '../firestore/Firestore';
import { InvitedAsRole, UserInvitation } from '../invitations/userInvitation';
import emailPattern from '../../constants/emailPattern';
import { generateVerificationToken } from './utils';

interface SuppliedProps {
  inviterName: string;
  inviterId: string;
  onInvitationSent?: (email: string, pirAlias: string) => Promise<void>;
  role: string;
}

type Props = SuppliedProps;

const InviteNewUser = ({ inviterName, inviterId, onInvitationSent, role }: Props): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [submitError, setSubmitError] = useState<string | undefined>(undefined);
  const { clearErrors, formState, handleSubmit, register, reset } = useForm({
    defaultValues: {
      invitedAs: role === 'admin' ? '' : InvitedAsRole.USER,
      name: '',
      email: '',
    },
  });
  const { t } = useTranslation('linkedusers');
  const { errors } = formState;

  const [formIsDirty, setFormIsDirty] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [nameValue, setNameValue] = useState('');
  const invitedAs = role === 'admin' ? '' : InvitedAsRole.USER;
  const [invitedAsValue, setInvitedAsValue] = useState(invitedAs);

  const handleEmailChange = (newValue: string) => setEmailValue(newValue);
  const handleNameChange = (newValue: string) => setNameValue(newValue);
  const handleInvitedAsChange = (newValue: string) => setInvitedAsValue(newValue);

  useEffect(() => {
    let dirty = false;
    if (role === 'admin') {
      dirty = !!emailValue && !!nameValue && !!invitedAsValue;
    } else {
      dirty = !!emailValue && !!nameValue;
    }
    setFormIsDirty(dirty);
  }, [emailValue, nameValue, invitedAsValue]);

  const sendInvitation: SubmitHandler<FieldValues> = async (fieldValues: any): Promise<void> => {
    const invitation: Omit<UserInvitation, 'id'> = {
      name: fieldValues.name,
      email: fieldValues.email.toLowerCase(),
      accepted: false,
      createdDate: new Date(),
      invitedBy: inviterName,
      invitedByUser: inviterId,
      invitedAsRole: role === 'admin' ? fieldValues.invitedAs : InvitedAsRole.USER,
      verificationToken: generateVerificationToken(),
    };

    try {
      const usersWithSameEmail = await Firestore.collection('users').where('email', '==', invitation.email).get();
      const invitationsWithSameEmail = await Firestore.collection('invitations')
        .where('email', '==', invitation.email)
        .get();

      if (usersWithSameEmail.empty && invitationsWithSameEmail.empty) {
        await Firestore.collection('invitations').doc().set(invitation);
        reset();
        onClose();
      } else {
        setSubmitError('A user with this email address already exists');
        reset(undefined, {
          keepIsSubmitted: true,
        });
        return;
      }
    } catch (e) {
      setSubmitError('Could not send invitation');
      return;
    }

    if (onInvitationSent) {
      await onInvitationSent(invitation.email, fieldValues.name);
    }
  };

  return (
    <>
      <PrimaryButton onClick={onOpen}>{t('invitations.list.columns.add.label')}</PrimaryButton>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setSubmitError(undefined);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight="light" color="purple3.600" fontSize={24} textAlign="center" px={[1, 1, 6]}>
            {t('invitations.invite.title')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" px={[1, 1, 6]} flexWrap="wrap">
            <FormControl isInvalid={submitError !== undefined} mb={2}>
              <FormErrorMessage>{submitError}</FormErrorMessage>
            </FormControl>

            <Field
              {...register('email', {
                required: {
                  value: true,
                  message: t('invitations.invite.email.errors.required'),
                },
                pattern: {
                  value: emailPattern,
                  message: t('invitations.invite.email.errors.invalid'),
                },
              })}
              name="email"
              label={t('invitations.invite.email.label')}
              type="email"
              error={errors.email}
              placeholder={t('invitations.invite.email.placeholder')}
              onChange={(e) => {
                handleEmailChange(e.target.value);
                clearErrors('email');
              }}
            />

            <Field
              {...register('name', {
                required: {
                  value: true,
                  message: t(`invitations.invite.name.${role}.errors.required`),
                },
              })}
              name="name"
              label={t(`invitations.invite.name.${role}.label`)}
              type="text"
              error={errors.name}
              placeholder={t(`invitations.invite.name.${role}.placeholder`)}
              onChange={(e) => {
                handleNameChange(e.target.value);
                clearErrors('name');
              }}
            />

            {role === 'admin' && (
              <FormControl isInvalid={!!errors.invitedAs}>
                <FormErrorMessage>{errors.invitedAs && errors.invitedAs.message}</FormErrorMessage>
                <FormLabel htmlFor="invitedAs">{t(`invitations.invite.inviteAs.label`)}</FormLabel>
                <Select
                  {...register('invitedAs', {
                    required: {
                      value: true,
                      message: t(`invitations.invite.inviteAs.error`),
                    },
                  })}
                  onChange={(e) => {
                    handleInvitedAsChange(e.target.value);
                    clearErrors('invitedAs');
                  }}
                  defaultValue=""
                >
                  <option value="" disabled>
                    {t(`invitations.invite.inviteAs.selectDefault`)}
                  </option>
                  <option value={InvitedAsRole.USER}>{t(`invitations.invite.inviteAs.pir`)}</option>
                  <option value={InvitedAsRole.CP}>{t(`invitations.invite.inviteAs.cp`)}</option>
                </Select>
              </FormControl>
            )}
          </ModalBody>

          <ModalFooter justifyContent="space-between" w="100%">
            <Button
              variant="link"
              onClick={() => {
                setSubmitError(undefined);
                onClose();
              }}
            >
              {t('invitations.invite.cancel')}
            </Button>
            <PrimaryButton
              type="submit"
              isDisabled={!formIsDirty}
              isLoading={formState.isSubmitting}
              onClick={handleSubmit(sendInvitation)}
            >
              {t('invitations.invite.submit')}
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InviteNewUser;
