import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Heading, FormErrorMessage, FormControl, Text } from '@chakra-ui/react';
import emailPattern from '../../constants/emailPattern';
import Field from './Field';

interface Props {
  onSubmit(data: Record<string, any>): Promise<void>;
  isSubmitSuccessful: boolean;
  generalFormErrors: string[];
}

const ResetPasswordForm = (props: Props): JSX.Element => {
  const logInLocation = { ...useLocation(), search: '?log-in=true' };
  const { t } = useTranslation('auth');
  const { handleSubmit, register, formState } = useForm();
  const { isSubmitting } = formState;
  const { errors } = formState;

  const onSubmit: SubmitHandler<Record<string, any>> = async (data) => {
    return props.onSubmit(data);
  };

  return (
    <Box>
      <Heading as="h2" fontSize={[24, 24, 36, 45]} fontWeight={400} color="purple3.600" mb="40px">
        {t('resetPasswordForm.header')}
      </Heading>
      <Box mb={10}>
        {props.isSubmitSuccessful ? (
          <Text fontSize={16}>{t('resetPasswordForm.submitSuccessful')}</Text>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* General form errors, mostly the responses from Firebase */}
            {props.generalFormErrors.length > 0 && (
              <FormControl isInvalid={props.generalFormErrors.length > 0} mb={8}>
                {props.generalFormErrors.map((error, i) => (
                  <FormErrorMessage key={i}>{error}</FormErrorMessage>
                ))}
              </FormControl>
            )}
            <Field
              {...register('email', {
                required: {
                  value: true,
                  message: t('resetPasswordForm.errors.emailRequired'),
                },
                pattern: {
                  value: emailPattern,
                  message: t('resetPasswordForm.errors.emailInvalid'),
                },
              })}
              name="email"
              label={t('resetPasswordForm.emailLabel')}
              type="email"
              placeholder="somebody@gmail.com"
              error={errors.email}
            />
            <Button
              bg="blue4.500"
              color="white"
              borderRadius={0}
              w="100%"
              type="submit"
              _hover={{ backgroundColor: 'blue4.300' }}
              isLoading={isSubmitting}
            >
              {t('resetPasswordForm.resetPasswordButton')}
            </Button>
          </form>
        )}
      </Box>
      <Link to={logInLocation}>
        <Text as="span" textDecoration="underline">
          Back to Login
        </Text>
      </Link>
    </Box>
  );
};

export default ResetPasswordForm;
