import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  Flex,
  Link,
  chakra,
  Divider,
} from '@chakra-ui/react';
import FAIcon from '../FAIcon';
import SurveyForm from './SurveyForm';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
}

type Props = SuppliedProps;

const SurveyModal = (props: Props & PropsFromRedux): React.ReactElement => {
  const { t } = useTranslation('survey');
  const { isOpen, onClose, hasOnboarded } = props;
  const { isPhone } = useDevice();

  /* code that checks whether input is focused, might be used later */
  // const [inputFocused, setInputFocused] = React.useState<boolean>(false);

  // const onFieldFocus = () => {
  //   // Set onInputFocused state to adjust bottom margin for keyboard
  //   setInputFocused(true);
  // };

  // const onFieldBlur = () => {
  //   // Set onInputFocused state to adjust bottom margin for keyboard
  //   setInputFocused(false);
  // };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered={true}>
      <ModalOverlay />
      <ModalContent
        maxWidth={
          !hasOnboarded || isPhone ? ['100%', '100%', '100%', '100%', '100%'] : ['100%', '98%', '90%', '85%', '75%']
        }
        minHeight={!hasOnboarded || isPhone ? ['100%'] : ['90%']}
        marginBottom={0}
        marginTop={0}
        padding={0}
        zIndex={1900}
        borderRadius={['20px']}
        position="fixed"
      >
        {/* Commented out below is a sliding indicator that could be reused later in issue #313, for
         * the dialogs to be slidable on small screens.
         */}
        {/* <Flex display={['flex', 'none']} alignItems="center" justifyContent="center" width="100%" marginTop="39px">
          <Box backgroundColor="#C4C4C4" borderRadius="10px" height="6px" width="44px" />
        </Flex> */}
        <ModalHeader
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
          fontWeight="light"
          color="purple3.600"
          fontSize={24}
          textAlign="center"
          marginTop={['36px', '42px']}
          marginBottom={['10px', '0px']}
          padding="0"
        >
          <FAIcon icon="poll-h" mr={4} color="#E5DED6" fontSize={['20px', '28px']} />
          <chakra.span fontSize={['18px', '20px']} fontWeight={[700, 600]} color="#626161">
            {t('surveyModal.header')}
          </chakra.span>
        </ModalHeader>
        {hasOnboarded && (
          <ModalCloseButton
            width="24px"
            height="24px"
            backgroundColor="#BEBEBE"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="12px"
            marginRight={['19px', '22px']}
            marginTop={['20px', '20px']}
          >
            <FAIcon lineHeight="90%" icon="xmark" fontSize="20px" color="white" />
          </ModalCloseButton>
        )}
        <Divider
          display={['none', 'block']}
          margin="20px 0px 20px 0px"
          borderColor="#E5E5E5"
          orientation="horizontal"
        />
        <ModalBody px="9px">
          <Flex direction="column" maxWidth="642px" margin="auto" alignItems="left">
            <SurveyForm {...props} />
          </Flex>
        </ModalBody>
        <ModalFooter>
          {!hasOnboarded && (
            <Link onClick={onClose} fontSize={14} mt={2} mr={4}>
              {t('surveyModal.skip')}
            </Link>
          )}

          {/* Cancel button temporarily disabled */}
          {/* {hasOnboarded ? (
            <Link onClick={onClose} fontSize={14} mt={2} mr={4}>
              {t('surveyModal.modalCancel')}
            </Link>
          ) : (
            <Link onClick={onClose} fontSize={14} mt={2} mr={4}>
              {t('surveyModal.skip')}
            </Link>
          )} */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    hasOnboarded: state.onboarding.hasOnboarded,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SurveyModal);
