import { Action } from 'redux';
import AppThunk from '../interfaces/AppThunk';
import Firestore from '../../modules/firestore/Firestore';
import { IVitalsGraph, PirVitalsData } from '../../modules/vitals/interfaces/IVitalsGraph';
import { formatVitalsGraphFromFirestore } from '../../modules/vitals/dbUtils';
import { PossibleLinkedUser } from '../../modules/linked-users/interfaces/ILinkedUser';
import { syncWithinTime } from '../../lib/colorThresholds';
// import ISurvey from '../survey/interfaces/ISurvey';
// import IUser from './interfaces/IUser';

export enum VitalsActionTypes {
  GETTING_VITALS = 'GETTING_VITALS',
  GOT_VITALS = 'GOT_VITALS',
}

export type VitalsActions = GettingVitalsAction | GotVitalsAction;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type GettingVitalsAction = Action<VitalsActionTypes.GETTING_VITALS>;

export const GettingVitals = (): GettingVitalsAction => ({
  type: VitalsActionTypes.GETTING_VITALS,
});

export interface GotVitalsAction extends Action<VitalsActionTypes.GOT_VITALS> {
  vitals: PirVitalsData;
}
export const GotVitals = (vitals: PirVitalsData): GotVitalsAction => ({
  type: VitalsActionTypes.GOT_VITALS,
  vitals,
});

export const getVitals = (provider: PossibleLinkedUser): AppThunk<Promise<GotVitalsAction>, VitalsActions> => {
  return async (dispatch) => {
    dispatch(GettingVitals());

    const stressResults: IVitalsGraph[] = await new Promise((resolve, reject) => {
      const query = Firestore.collection('stressGraph').where('pir', '==', provider.pir).limit(1);
      query.onSnapshot(
        (snapshot) => {
          resolve(snapshot.docs.map((linkedUser) => formatVitalsGraphFromFirestore(linkedUser)));
        },
        (error) => {
          reject(error);
        },
      );
    });

    const sleepResults: IVitalsGraph[] = await new Promise((resolve, reject) => {
      const query = Firestore.collection('sleepGraph').where('pir', '==', provider.pir).limit(1);
      query.onSnapshot(
        (snapshot) => {
          resolve(snapshot.docs.map((linkedUser) => formatVitalsGraphFromFirestore(linkedUser)));
        },
        (error) => {
          reject(error);
        },
      );
    });

    const participationResults: IVitalsGraph[] = await new Promise((resolve, reject) => {
      const query = Firestore.collection('participationGraph').where('pir', '==', provider.pir).limit(1);
      query.onSnapshot(
        (snapshot) => {
          resolve(snapshot.docs.map((linkedUser) => formatVitalsGraphFromFirestore(linkedUser)));
        },
        (error) => {
          reject(error);
        },
      );
    });

    const watchUseResults: IVitalsGraph[] = await new Promise((resolve, reject) => {
      const query = Firestore.collection('watchUseGraph').where('pir', '==', provider.pir).limit(1);
      query.onSnapshot(
        (snapshot) => {
          resolve(snapshot.docs.map((linkedUser) => formatVitalsGraphFromFirestore(linkedUser)));
        },
        (error) => {
          reject(error);
        },
      );
    });

    const cravingRiskResults: IVitalsGraph[] = await new Promise((resolve, reject) => {
      const query = Firestore.collection('cravingRiskGraph').where('pir', '==', provider.pir).limit(1);
      query.onSnapshot(
        (snapshot) => {
          resolve(snapshot.docs.map((linkedUser) => formatVitalsGraphFromFirestore(linkedUser)));
        },
        (error) => {
          reject(error);
        },
      );
    });

    const emotionalScoreResults: IVitalsGraph[] = await new Promise((resolve, reject) => {
      const query = Firestore.collection('ph').where('pir', '==', provider.pir).limit(1);
      query.onSnapshot(
        (snapshot) => {
          resolve(snapshot.docs.map((linkedUser) => formatVitalsGraphFromFirestore(linkedUser)));
        },
        (error) => {
          reject(error);
        },
      );
    });

    const incidentsResults: IVitalsGraph[] = await new Promise((resolve, reject) => {
      const query = Firestore.collection('incidentsGraph').where('pir', '==', provider.pir).limit(1);
      query.onSnapshot(
        (snapshot) => {
          resolve(snapshot.docs.map((linkedUser) => formatVitalsGraphFromFirestore(linkedUser)));
        },
        (error) => {
          reject(error);
        },
      );
    });

    const vitalsResults = {
      rawData: {
        craving: cravingRiskResults.length > 0 ? cravingRiskResults[0].data : [],
        emotion: emotionalScoreResults.length > 0 ? emotionalScoreResults[0].data : [],
        sleep: sleepResults.length > 0 ? sleepResults[0].data : [],
        stress: stressResults.length > 0 ? stressResults[0].data : [],
        participation: participationResults.length > 0 ? participationResults[0].data : [],
        incidents: incidentsResults.length > 0 ? incidentsResults[0].data : [],
        watchuse: watchUseResults.length > 0 ? watchUseResults[0].data : [],
      },
      synced: {
        craving: cravingRiskResults.length > 0 ? syncWithinTime('craving', cravingRiskResults[0].dateTime) : false,
        emotion:
          emotionalScoreResults.length > 0 ? syncWithinTime('emotions', emotionalScoreResults[0].dateTime) : false,
        sleep: sleepResults.length > 0 ? syncWithinTime('sleep', sleepResults[0].dateTime) : false,
        stress: stressResults.length > 0 ? syncWithinTime('stress', stressResults[0].dateTime) : false,
        participation:
          participationResults.length > 0 ? syncWithinTime('participation', participationResults[0].dateTime) : false,
        incidents: true,
        watchuse: watchUseResults.length > 0 ? syncWithinTime('watchuse', watchUseResults[0].dateTime) : false,
      },
    };

    return dispatch(GotVitals(vitalsResults));
  };
};
