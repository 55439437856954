import AppThunk from '../../redux/interfaces/AppThunk';
import Firestore, { FirestoreUtils } from '../firestore/Firestore';
import IWeeklyVitalsAggregates from './interfaces/IWeeklyVitalsAggregates';
import { Action } from 'redux';
import { formatWeeklyAggregatesFromFirestore } from './utils';

// TYPES
export enum AggregatesActionTypes {
  GETTING_AGGREGATES = 'GETTING_AGGREGATES',
  GOT_AGGREGATES = 'GOT_AGGREGATES',
}

export type AggregatesActions = GettingAggregatesAction | GotAggregatesAction;

export type GettingAggregatesAction = Action<AggregatesActionTypes.GETTING_AGGREGATES>;

export const GettingAggregates = (): GettingAggregatesAction => ({
  type: AggregatesActionTypes.GETTING_AGGREGATES,
});

export interface GotAggregatesAction extends Action<AggregatesActionTypes.GOT_AGGREGATES> {
  aggregates: IWeeklyVitalsAggregates[];
}
export const GotAggregates = (aggregates: IWeeklyVitalsAggregates[]): GotAggregatesAction => ({
  type: AggregatesActionTypes.GOT_AGGREGATES,
  aggregates,
});

export const getAggregates = (pirId: string): AppThunk<Promise<GotAggregatesAction>, AggregatesActions> => {
  return async (dispatch) => {
    dispatch(GettingAggregates());

    return new Promise<IWeeklyVitalsAggregates[]>((resolve, reject) => {
      Firestore.collection('weeklyVitalsAggregates')
        .where('pir', '==', FirestoreUtils.getDocRef('users', pirId))
        .limit(1)
        .onSnapshot(
          (snapshot) => {
            const aggregatesResults = snapshot.docs.map((linkedUser) =>
              formatWeeklyAggregatesFromFirestore(linkedUser),
            );
            dispatch(GotAggregates(aggregatesResults));
            resolve(aggregatesResults);
          },
          (error) => {
            reject(error);
          },
        );
    })
      .then((aggregatesResults) => {
        return dispatch(GotAggregates(aggregatesResults));
      })
  };
};
