import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { formatRelative } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { RootState } from '../../redux/store';
import { deleteInvitationThunk, getInvitationsThunk } from '../invitations/invtiationActions';
import { CellProps, Column, useSortBy, useTable } from 'react-table';
import { Box, Flex, IconButton, Skeleton, Stack, Text, Tooltip } from '@chakra-ui/react';
import FAIcon from '../../components/FAIcon';
import { UserInvitation } from '../invitations/userInvitation';
import InviteNewUser from '../invitations/InviteNewUser';
import IUser, { DeletionStatus } from '../user/interfaces/IUser';

const UserInvitationsList = (props: PropsFromRedux): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation('admin');
  const data = useMemo(
    () =>
      props.invitations.map((invitation) => ({
        id: invitation.id,
        email: invitation.emailBeforeDeleted ? invitation.emailBeforeDeleted : invitation.email,
        name: invitation.name,
        role:
          invitation.invitedAsRole === 'USER'
            ? t('invitations.role.user')
            : invitation.invitedAsRole === 'CARE_PROVIDER'
            ? t('invitations.role.provider')
            : t('invitations.role.undefined'),
        status:
          invitation.deletionStatus === DeletionStatus.DELETED
            ? t('invitations.status.deleted')
            : invitation.deletionStatus === DeletionStatus.PENDING
            ? t('invitations.status.deletionPending')
            : invitation.deletionStatus === 'RESTORED'
            ? t('invitations.status.restored')
            : invitation.accepted
            ? t('invitations.status.accepted')
            : t('invitations.status.pending'),
        invitedBy: invitation.invitedBy,
        createdDate: invitation.createdDate,
      })),
    [props.invitations],
  );
  const columns = useMemo(
    () =>
      [
        {
          id: 'email',
          Header: t('invitations.list.columns.email.header'),
          accessor: 'email',
          sortType: 'basic',
        },
        {
          id: 'name',
          Header: t('invitations.list.columns.name.header'),
          accessor: 'name',
          sortType: 'basic',
        },
        {
          id: 'role',
          Header: t('invitations.list.columns.role.header'),
          accessor: 'role',
          sortType: 'basic',
        },
        {
          id: 'createdDate',
          Header: t('invitations.list.columns.createdDate.header'),
          accessor: 'createdDate',
          sortType: 'basic',
          Cell: (cellProps: CellProps<Date>): JSX.Element => {
            const timezonedDate = utcToZonedTime(
              cellProps.cell.value,
              Intl.DateTimeFormat().resolvedOptions().timeZone,
            );
            const dateTimeString = `${timezonedDate.toDateString()} ${timezonedDate.toTimeString()}`;
            return (
              <Tooltip label={dateTimeString}>
                <Text>{formatRelative(cellProps.cell.value, Date.now())}</Text>
              </Tooltip>
            );
          },
        },
        {
          id: 'invitedBy',
          Header: t('invitations.list.columns.invitedBy.header'),
          accessor: 'invitedBy',
          sortType: 'basic',
        },
        {
          id: 'status',
          Header: t('invitations.list.columns.status.header'),
          accessor: 'status',
          sortType: 'basic',
        },
        {
          id: 'delete',
          Header: '',
          accessor: 'id',
          Cell: (cellProps: CellProps<Date>): JSX.Element => {
            return (
              <IconButton
                size="sm"
                isLoading={
                  // eslint-disable-next-line react/prop-types
                  props.deletionLoading.includes(cellProps.cell.value)
                }
                isDisabled={cellProps.row.values.status !== 'Sent'}
                mr={5}
                aria-label={t('invitations.list.columns.delete.label')}
                icon={<FAIcon icon="trash-can" />}
                color="purple.600"
                onClick={() => dispatch(deleteInvitationThunk(cellProps.cell.value))}
              />
            );
          },
        },
      ] as Column<{
        email: string;
        name: string;
        role: string | undefined;
        status: string;
        invitedBy: string;
        createdDate: Date;
      }>[],
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

  useEffect(() => {
    dispatch(getInvitationsThunk());
  }, []);

  const getTableBody = () => {
    if (props.loading) {
      return (
        <Stack>
          <Skeleton height="50px" mb="0.25rem" borderRadius="mb" />
          <Skeleton height="50px" mb="0.25rem" borderRadius="mb" />
          <Skeleton height="50px" mb="0.25rem" borderRadius="mb" />
        </Stack>
      );
    }

    if (props.error) {
      return (
        <Flex
          borderBottom="2px solid #ebebeb"
          boxShadow="sm"
          bg="white"
          mb="0.25rem"
          borderRadius="lg"
          fontWeight="bold"
          color="dimgray"
          justifyContent="center"
          alignItems="center"
        >
          {t(props.error)}
        </Flex>
      );
    }

    if (props.invitations.length === 0) {
      return (
        <Flex
          borderBottom="2px solid #ebebeb"
          boxShadow="sm"
          bg="white"
          mb="0.25rem"
          borderRadius="lg"
          fontWeight="bold"
          color="dimgray"
          justifyContent="center"
          alignItems="center"
        >
          {t('invitations.list.empty')}
        </Flex>
      );
    }

    return rows.map((row) => {
      prepareRow(row);
      return (
        // eslint-disable-next-line react/jsx-key
        <Flex
          borderBottom="2px solid #ebebeb"
          boxShadow="sm"
          bg="white"
          mb="0.25rem"
          borderRadius="lg"
          fontWeight="bold"
          color="dimgray"
          {...row.getRowProps()}
        >
          {row.cells.map((cell) => (
            // eslint-disable-next-line react/jsx-key
            <Flex
              flex={cell.column.id === 'email' ? '2' : '1'}
              ml={2}
              justifyContent="start"
              alignItems="flex-start"
              height={50}
              _last={{ maxWidth: '2.5rem' }}
              {...cell.getCellProps()}
              style={{
                whiteSpace: 'normal',
                overflowWrap: 'break-word',
                wordBreak: 'break-all',
              }}
            >
              {cell.render('Cell')}
            </Flex>
          ))}
        </Flex>
      );
    });
  };

  return (
    <>
      <Box mb={8} fontSize={18} fontWeight="bold">
        {t('invitations.list.header')}
      </Box>
      <Flex width="100%" justifyContent="right" alignItems="right" height={50}>
        <InviteNewUser inviterName={props.user.name ?? ''} inviterId={props.user.id} role="admin" />
      </Flex>
      <Flex
        fontFamily="roboto, sans-serif"
        fontWeight="500"
        fontSize="14px"
        textAlign="left"
        color="white"
        bg="#4F4F4F"
        borderRadius="md"
        py={1}
        mb={3}
        width="100%"
        {...getTableProps()}
      >
        {headerGroups.map((headerGroup) => (
          // eslint-disable-next-line react/jsx-key
          <Flex width="100%" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              // eslint-disable-next-line react/jsx-key
              <Flex
                ml={2}
                flex={column.id === 'email' ? '2' : '1'}
                justifyContent="flex-start"
                alignItems="center"
                _last={{ maxWidth: '2.5rem' }}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted ? <FAIcon ml={1} icon={column.isSortedDesc ? 'caret-down' : 'caret-up'} /> : ''}
                </span>
              </Flex>
            ))}
          </Flex>
        ))}
      </Flex>
      <Box {...getTableBodyProps()}>{getTableBody()}</Box>
    </>
  );
};

const mapStateToProps = (
  state: RootState,
): { deletionLoading: string[]; error?: string; invitations: UserInvitation[]; loading: boolean; user: IUser } => {
  return {
    deletionLoading: state.userInvitations.deletionLoading,
    error: state.userInvitations.error,
    invitations: state.userInvitations.invitations.sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime()),
    loading: state.userInvitations.loading,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    user: state.user.user!,
  };
};

const mapDispatchToProps = {
  getInvitationsThunk,
  deleteInvitationThunk,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserInvitationsList);
