import FirebaseUtils from '../firestore/Firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

export const uploadFile = async (file: File, blob?: Blob): Promise<string> => {
  const fileNameParts = file.name.split('.');
  const newfileName =
    fileNameParts[0] +
     '_' +
     Math.floor(Date.now() / 1000) +
     Math.floor(Math.random() * 1000) +
     '.' +
     fileNameParts[1];
  // adds random value to file name in order to prevent overwrite errors.
  const uploadRef = ref(getStorage(FirebaseUtils.app()), newfileName);
  const snapshot = await uploadBytes(uploadRef, blob || file);
  return getDownloadURL(snapshot.ref);
};

export const deleteFile = async (url: string): Promise<void> => {
  const storageRef = ref(getStorage(FirebaseUtils.app()), url);
  return deleteObject(storageRef);
};
