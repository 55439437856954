import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Button, VStack } from '@chakra-ui/react';
import CarouselItem from './CarouselItem';
import styles from './intro.module.css';
import { useLocation, Link } from 'react-router-dom';
// Source: Modified from https://tinloof.com/blog/how-to-build-an-auto-play-slideshow-with-react/
interface Props {
  onClick(): void;
}

const Intro = (props: Props): JSX.Element => {
  const { t } = useTranslation('auth');
  const items = [
    {
      index: 0,
      title: t('splash.recovery.title'),
      caption: t('splash.recovery.caption'),
      isFirstPage: true,
    },
    {
      index: 1,
      title: t('splash.community.title'),
      caption: t('splash.community.caption'),
      isFirstPage: false,
    },
    {
      index: 2,
      title: t('splash.reminders.title'),
      caption: t('splash.reminders.caption'),
      isFirstPage: false,
    },
    {
      index: 3,
      title: t('splash.healthStats.title'),
      caption: t('splash.healthStats.caption'),
      isFirstPage: false,
    },
    {
      index: 4,
      title: t('splash.privacy.title'),
      caption: t('splash.privacy.caption'),
      isFirstPage: false,
    },
  ];

  const [index, setIndex] = useState(0);
  const [moving, setMoving] = useState(false);
  const [lastTouchX, setLastTouchX] = useState(0);

  const handleSwipe = (e: any) => {
    const deltaX = e.changedTouches[0].clientX - lastTouchX;
    setMoving(true);

    if (deltaX < 0) {
      setIndex(index === 4 ? 0 : index + 1);
    } else if (deltaX > 0) {
      setIndex(index === 0 ? 4 : index - 1);
    }
  };

  const handleClick = () => {
    setMoving(true);

    setIndex(index === 4 ? 0 : index + 1);
  };

  return (
    <VStack spacing={5} flexGrow={1} minWidth={0} flexBasis="100" alignItems="stretch" maxHeight="90vh">
      <Flex className={styles.slideshow} flexBasis="571px" flexGrow={1} flexShrink={1} minWidth={0}>
        <Flex
          className={styles.slideshowSlider}
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)`, transition: 'ease 700ms' }}
          onTouchStart={(e) => {
            setLastTouchX(e.touches[0].clientX);
          }}
          onTouchEnd={(e) => {
            if (!moving) {
              handleSwipe(e);
            }
          }}
          onClick={() => {
            if (!moving) {
              handleClick();
            }
          }}
          onTransitionEnd={() => {
            setMoving(false);
          }}
        >
          {items.map((item) => {
            return (
              <CarouselItem key={item.index} title={item.title} caption={item.caption} isFirstPage={item.isFirstPage} />
            );
          })}
        </Flex>
      </Flex>

      <Flex className={styles.slideshowDots} justifyContent="center" align="center" pb={[2, 3, 3, 5]}>
        {items.map((_, i) => {
          return (
            <div
              key={i}
              className={index === i ? styles.slideshowDotActive : styles.slideshowDot}
              onClick={() => {
                setIndex(i);
              }}
            />
          );
        })}
      </Flex>

      <Flex justifyContent="center" direction="column" paddingBottom={10}>
        <Flex direction="column">
          <Link to={{ ...useLocation(), search: '?log-in=true' }}>
            <Flex justifyContent="center">
              <Button
                bg="white"
                color="purple3.600"
                borderRadius={0}
                w="50%"
                type="submit"
                _hover={{ background: 'blue', color: 'white' }}
                variant="outline"
                borderColor="purple3.600"
                onClick={props.onClick}
                data-test="login-btn"
              >
                {t('intro.login')}
              </Button>
            </Flex>
          </Link>

          <Link to={{ ...useLocation(), search: '?no-account' }}>
            <Flex justifyContent="center" mt={3}>
              <Button
                bg="white"
                color="purple3.600"
                borderRadius={0}
                w="50%"
                type="submit"
                _hover={{ background: 'blue', color: 'white' }}
                variant="outline"
                borderColor="purple3.600"
                onClick={props.onClick}
                data-test="no-account-btn"
              >
                {t('intro.noAccount')}
              </Button>
            </Flex>
          </Link>
        </Flex>
      </Flex>
    </VStack>
  );
};

export default Intro;
