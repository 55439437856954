import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Heading, Flex, Box, Text } from '@chakra-ui/react';
import { login, loggedIn, resetPassword, signUp, requestAccount } from './actions';
import { RootState } from '../../redux/store';
import LogInForm from './LogInForm';
import ResetPasswordForm from './ResetPasswordForm';
import SignUpForm from './SignUpForm';
import Intro from './Intro';
import { updateUser } from '../user/actions';
import NoAccountForm from './NoAccountForm';
import { useDevice } from 'src/DeviceContext';

const Index = (props: PropsFromRedux): JSX.Element => {
  const { login, loggedIn, resetPassword, signUp, requestAccount } = props;
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const token = query.get('verification');
  const isOldInviteLink = query.get('email');
  const straightToLogin = query.get('straight-to-login');
  const isSignUpForm = query.get('sign-up') !== null;
  const isNewEmailVerification = query.get('verify') !== null;
  const isNoAccount = query.get('no-account') !== null;
  const isResetPasswordForm = query.get('reset-password') !== null;
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const { isCordova } = useDevice();

  const [formErrors, setFormErrors] = useState<string[]>([]);
  const { t } = useTranslation(['common', 'auth']);

  const [showIntro, setShowIntro] = useState(true);
  const [formErrorIsVisible, setFormErrorIsVisible] = useState(false);

  useEffect(() => {
    if (formErrors.length === 0) {
      setFormErrorIsVisible(false);
      return;
    }
    setFormErrorIsVisible(true);

    const timer = setTimeout(() => {
      setFormErrorIsVisible(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [formErrors]);

  useEffect(() => {
    if (isSignUpForm && !token && !isOldInviteLink) {
      setFormErrors([t('auth:signUpForm.errors.invalidLink')]);
    }
    if (isNewEmailVerification && !token) {
      setFormErrors([t('auth:signUpForm.errors.invalidVerifyLink')]);
    }
  }, [token, isSignUpForm, isNewEmailVerification]);

  useEffect(() => {
    if (straightToLogin) {
      setShowIntro(false);
    }
  }, [straightToLogin]);

  /**
   * Runs when the login form is submitted and handles the Firebase response either positively or negatively.
   */
  const onLogInSubmit = async (data: Record<string, any>): Promise<void> => {
    return login(data.email, data.password)
      .then(async () => {
        history.push(isNewEmailVerification ? '/' : { ...location, search: getNewQuery(query) });
        return undefined;
      })
      .catch((e: firebase.FirebaseError | Error) => {
        // Turn off our logging in bool
        loggedIn(null);
        console.error('Error logging in:', e);

        if ((e as firebase.FirebaseError).code) {
          switch ((e as firebase.FirebaseError).code) {
            case 'auth/invalid-email':
            case 'auth/user-disabled':
            case 'auth/user-not-found':
            case 'auth/wrong-password':
              return setFormErrors([t('auth:logInForm.errors.userNotFound')]);
            case 'auth/too-many-requests':
              return setFormErrors([t('auth:logInForm.errors.tooMany')]);
            default:
              return setFormErrors([t('auth:common.errors.unknown')]);
          }
        }

        return setFormErrors([t('auth:common.errors.unknown')]);
      });
  };

  const onSignUpSubmit = async (data: Record<string, any>): Promise<void> => {
    try {
      await signUp(data.email, data.password, data.tempPassword);
      query.delete('verification');
      query.delete('tmppassword');
      query.delete('email');
      history.push('/');
    } catch (error) {
      console.error(`Error signing up: ${error}`);
      const e = error as firebase.FirebaseError;
      if ('code' in e) {
        let errorMessages = [];
        switch (e.code) {
          case 'auth/email-already-in-use':
            errorMessages = [t('auth:signUpForm.errors.usedEmail')];
            break;
          case 'auth/invalid-email':
            errorMessages = [t('auth:signUpForm.errors.invalidEmail')];
            break;
          case 'auth/operation-not-allowed':
            errorMessages = [t('auth:signUpForm.errors.badOperation')];
            break;
          case 'auth/weak-password':
            errorMessages = [t('auth:signUpForm.errors.weakPassword')];
            break;
          default:
            errorMessages = [t('auth:common.errors.unknown')];
        }
        setFormErrors(errorMessages);
        throw error;
      } else {
        setFormErrors([t('auth:common.errors.unknown')]);
        throw error;
      }
    }
  };

  const OnRequestAccountSubmit = async (data: Record<string, any>): Promise<void> => {
    try {
      await requestAccount(data.email);
      setFormErrors([]);
      setIsSubmitSuccessful(true);
    } catch (error) {
      const message = (error as Error).message;

      let errorMessages = [];
      switch (message) {
        case 'email-already-requested':
          errorMessages = [t('auth:noAccountForm.errors.emailAlreadyRequested')];
          break;
        case 'email-already-invited':
          errorMessages = [t('auth:noAccountForm.errors.emailAlreadyInvited')];
          break;
        default:
          errorMessages = [t('auth:common.errors.unknown')];
      }
      setFormErrors(errorMessages);
    }
  };

  const onResetPassword = async (data: Record<string, any>): Promise<void> => {
    return resetPassword(data.email)
      .then(() => {
        setIsSubmitSuccessful(true);
        return undefined;
      })
      .catch((e: firebase.FirebaseError | Error) => {
        // Turn off our logging in bool
        console.error(e);

        // from loggedIn
        if ((e as firebase.FirebaseError).code) {
          switch ((e as firebase.FirebaseError).code) {
            case 'auth/invalid-email':
            case 'auth/user-disabled':
            case 'auth/user-not-found':
            case 'auth/wrong-password':
              return setFormErrors([t('auth:resetPasswordForm.errors.userNotFound')]);
            case 'auth/too-many-requests':
              return setFormErrors([t('auth:resetPasswordForm.errors.tooMany')]);
            default:
              return setFormErrors([t('auth:common.errors.unknown')]);
          }
        }

        return setFormErrors([t('auth:common.errors.unknown')]);
      });
  };

  /**
   * @return A valid query string for use in react-router-dom location search key without sign-up or log-in
   */
  const getNewQuery = (query: URLSearchParams): string => {
    const entries = query.entries();
    const newEntries: Array<[string, string]> = [];

    for (const [key, value] of entries) {
      if (
        key !== 'sign-up' &&
        key !== 'log-in' &&
        key !== 'reset-password' &&
        key != 'straight-to-login' &&
        key !== 'verification' &&
        key !== 'verify'
      ) {
        newEntries.push([key, value]);
      }
    }

    if (newEntries.length === 0) {
      return '';
    }

    let out = '?';
    for (const [key, value] of newEntries) {
      out += `${key}=${value}`;
    }

    return out;
  };

  const setIntroInvisible = () => {
    setShowIntro(false);
  };

  const setIntroVisible = () => {
    setShowIntro(true);
  };

  useEffect(() => {
    if (isCordova && showIntro) {
      history.push('/');
    }
  }, []);

  return (
    <Flex flexDir="row" height="100%">
      {showIntro && !isSignUpForm && !isNewEmailVerification ? (
        <Flex flexGrow={1} flexBasis="100%" flexDir="column" overflowY="auto">
          <Flex alignItems={['flex-start', null, 'center']} justifyContent="center" flexBasis="100%">
            <Intro onClick={setIntroInvisible} />
          </Flex>
        </Flex>
      ) : (
        <Flex flexGrow={1} flexBasis="100%" flexDir="column" overflowY="auto">
          <Box as="header" alignSelf="flex-start" justifySelf="flex-start" mb={[8, null, 0]}>
            <Heading
              as="h1"
              fontSize={[36, null, null, 45]}
              fontWeight={300}
              color="purple3.600"
              pt={['10%', null, '24px']}
              pl={['5%', null, '24px']}
              whiteSpace="nowrap"
            >
              {t('common:header.wordmark')}
            </Heading>
          </Box>
          <Flex alignItems={['flex-start', null, 'center']} justifyContent="center" flexBasis="100%">
            <Box
              // transform={[null, null, null, window.innerHeight > 630 ? 'translateY(-80px)' : null]}
              w={['95%', null, '50%']}
            >
              {isSignUpForm && !isResetPasswordForm && (token || isOldInviteLink) && (
                <SignUpForm
                  onSubmit={onSignUpSubmit}
                  generalFormErrors={formErrors}
                  onBack={setIntroVisible}
                  formErrorIsVisible={formErrorIsVisible}
                  token={token}
                />
              )}

              {isNoAccount && (
                <NoAccountForm
                  onSubmit={OnRequestAccountSubmit}
                  generalFormErrors={formErrors}
                  isSubmitSuccessful={isSubmitSuccessful}
                />
              )}

              {(!isSignUpForm || (!token && !isOldInviteLink)) && !isResetPasswordForm && !isNoAccount && (
                <LogInForm
                  onSubmit={onLogInSubmit}
                  generalFormErrors={formErrors}
                  onBack={setIntroVisible}
                  formErrorIsVisible={formErrorIsVisible}
                  hideGoBack={!!straightToLogin}
                />
              )}
              {isResetPasswordForm && (
                <ResetPasswordForm
                  onSubmit={onResetPassword}
                  isSubmitSuccessful={isSubmitSuccessful}
                  generalFormErrors={formErrors}
                />
              )}
            </Box>
          </Flex>
        </Flex>
      )}
      <Flex
        flexGrow={1}
        flexBasis="100%"
        bg="purple3.600"
        display={['none', 'none', 'flex']}
        alignItems="center"
        justifyContent="center"
        overflowY="auto"
      >
        <Box display="inline-block" color="white" fontWeight={300} fontSize={32}>
          <Trans t={t} i18nKey="auth:common.rightHand">
            <Text mb={6}>zero</Text>
            <Text mb={6}>one</Text>
            <Text mb={6}>two</Text>
          </Trans>
        </Box>
      </Flex>
    </Flex>
  );
};

const mapStateToProps = ({ user }: RootState) => {
  return {
    user: user.user,
  };
};

const mapDispatchToProps = {
  login,
  loggedIn,
  resetPassword,
  signUp,
  updateUser,
  requestAccount,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Index);
