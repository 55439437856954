import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Img, Text } from '@chakra-ui/react';
import PrimaryButton from '../../../src/components/PrimaryButton';
import FreeDateModal from '../../components/ManageFreeDateModal/FreeDateModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import GearIcon from './gear_icon.png';
import { useModal } from 'src/ModalContext';

const MODAL_NAME = 'free_date';

interface Props {
  isCP?: boolean;
}

const FreeDateModule = ({ isCP }: Props): React.ReactElement => {
  const { t } = useTranslation('free_date');

  const { modal, openModal, closeModal } = useModal();

  const stateFreeDate = useSelector((state: RootState) => state.freeDate.freeDate);

  const lengthOfStreak = (date: Date) => {
    const todayDate = new Date();
    const ONE_DAY_MSECONDS = 1000 * 60 * 60 * 24;
    const differenceMSeconds = Math.abs(todayDate.getTime() - date.getTime());
    return Math.round(differenceMSeconds / ONE_DAY_MSECONDS);
  };

  return (
    <Box p={isCP ? 6 : 4} backgroundColor="grey6.50" w="100%" h="100%">
      <Link to={`/home/${MODAL_NAME}`} onClick={() => openModal(MODAL_NAME)}>
        <Button backgroundColor="grey6.50" float="right" zIndex={1} data-test="gear-icon">
          <Img src={GearIcon} alt="FreeDate" />
        </Button>
      </Link>
      {!stateFreeDate ? (
        <Flex p={4} direction="column" align="center" justify="center">
          <Text fontSize={14} textAlign="center">
            {isCP ? t('freeDateModuleCP.moduleInstruct') : t('freeDateModulePIR.moduleInstruct')}
          </Text>
          <Link to={`/home/${MODAL_NAME}`} onClick={() => openModal(MODAL_NAME)}>
            <PrimaryButton mt={3} alignItems="center" zIndex={1}>
              {isCP ? t('freeDateModuleCP.moduleButton') : t('freeDateModulePIR.moduleButton')}
            </PrimaryButton>
          </Link>
        </Flex>
      ) : (
        <Flex p={4} direction="column" align="center" justify="center" h="100%">
          <Text fontSize={16}>
            {isCP
              ? t('freeDateModuleCP.moduleStreak', { streak: lengthOfStreak(stateFreeDate.date) + 1 })
              : t('freeDateModulePIR.moduleStreak', { streak: lengthOfStreak(stateFreeDate.date) + 1 })}
          </Text>
        </Flex>
      )}
      <FreeDateModal
        isOpen={modal === MODAL_NAME}
        onOpen={() => openModal(MODAL_NAME)}
        onClose={() => {
          closeModal();
        }}
      />
    </Box>
  );
};

export default FreeDateModule;
