import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@chakra-ui/react';
import { useSlider } from './useSlider';

const addPropsToChildren = (children: React.ReactNode, slideIndex: number) => {
  if (!Array.isArray(children)) {
    return <BoxSlide active={true}>{children}</BoxSlide>;
  }
  return children.map((childElement, index) => (
    <BoxSlide key={`box_slider_${index}`} active={slideIndex === index + 1}>
      {childElement}
    </BoxSlide>
  ));
};

type SliderProps = {
  children: React.ReactNode;
};

const Slider = ({ children }: SliderProps): JSX.Element => {
  const sliderLength = React.Children.toArray(children).length;
  const { slideIndex, handleTouchStart, handleTouchMove, moveDot } = useSlider(sliderLength);

  return (
    <Flex direction="column" mb={4}>
      <SliderContainer onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
        {addPropsToChildren(children, slideIndex)}
      </SliderContainer>
      <DotsContainer>
        {new Array(sliderLength).fill(null).map((_, index) => (
          <Dot
            key={index}
            onClick={() => moveDot(index + 1)}
            active={slideIndex === index + 1}
            data-test={'dot-' + index}
          />
        ))}
      </DotsContainer>
    </Flex>
  );
};

export default Slider;

const SliderContainer = styled.div`
  position: relative;
  max-width: 340x;
  min-height: 198px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 25;
  overflow: hidden;
`;

const BoxSlide = styled.div<{ active: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity ease-in-out 0.5s;
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

const DotsContainer = styled.div`
  display: flex;
  margin: 15px auto;
  z-index: 20;
`;

const Dot = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 5px;
  transition: background-color ease-in-out 0.5s;
  background: ${({ active }) => (active ? '#5C4279' : '#E5E5E5')};
  cursor: pointer;
`;
