import { User, UserCredential, Unsubscribe } from 'firebase/auth';
import { AuthActionTypes, AuthActions } from './actions';

interface AuthState {
  loggingIn: boolean;
  loggingOut: boolean;
  resetPassword: boolean;
  signingUp: boolean;
  credential: null | UserCredential;
  stateListenerUnsubscribe: null | Unsubscribe;
  user: null | User;
}

const defaultState: AuthState = {
  loggingOut: false,
  loggingIn: false,
  resetPassword: false,
  signingUp: false,
  credential: null,
  stateListenerUnsubscribe: null,
  user: null,
};

const authReducer = (state: AuthState = defaultState, action: AuthActions): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGGING_IN:
      return {
        ...state,
        loggingIn: true,
      };
    case AuthActionTypes.LOGGED_IN:
      return {
        ...state,
        loggingIn: false,
        credential: action.credential,
      };
    case AuthActionTypes.LOGGING_OUT:
      return {
        ...state,
        loggingOut: true,
      };
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        credential: null,
        user: null,
        loggingOut: false,
      };

    case AuthActionTypes.SIGNING_UP:
      return {
        ...state,
        signingUp: true,
      };

    case AuthActionTypes.SIGNED_UP:
      return {
        ...state,
        signingUp: false,
        credential: action.credential,
      };

    case AuthActionTypes.REGISTER_AUTH_STATE_LISTENER:
      return {
        ...state,
        stateListenerUnsubscribe: action.unsubscribeFn,
      };

    case AuthActionTypes.UPDATE_AUTHED_USER:
      return {
        ...state,
        credential: action.user === null ? null : state.credential,
        user: action.user,
      };

    case AuthActionTypes.STOP_AUTH_STATE_LISTENER:
      return {
        ...state,
        stateListenerUnsubscribe: null,
      };

    default:
      return {
        ...state,
      };
  }
};

export default authReducer;
