import { Dispatch, SetStateAction, useState } from 'react';

interface SliderHookParams {
  handleTouchStart: (e: React.TouchEvent<HTMLDivElement>) => void;
  handleTouchMove: (e: React.TouchEvent<HTMLDivElement>) => void;
  setSlideIndex: Dispatch<SetStateAction<number>>;
  slideIndex: number;
  nextSlide: () => void;
  prevSlide: () => void;
  moveDot: (index: number) => void;
}

export const useSlider = (sliderLength: number, defaultPosition = 1): SliderHookParams => {
  const [slideIndex, setSlideIndex] = useState<number>(defaultPosition);

  const nextSlide = () => {
    if (slideIndex !== sliderLength) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === sliderLength) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(sliderLength);
    }
  };

  const moveDot = (index: number) => {
    setSlideIndex(index);
  };

  let xDown: number | null = null;
  let yDown: number | null = null;

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const firstTouch = e.touches[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!xDown || !yDown) {
      return;
    }
    const xUp = e.touches[0].clientX;
    const yUp = e.touches[0].clientY;

    const xDiff = xDown - xUp;
    const yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        nextSlide();
      } else {
        prevSlide();
      }
    }

    xDown = null;
    yDown = null;
  };

  return {
    handleTouchStart,
    handleTouchMove,
    setSlideIndex,
    slideIndex,
    nextSlide,
    prevSlide,
    moveDot,
  };
};
