/* eslint-disable no-console */

import { Action } from 'redux';
import AppThunk from '../../redux/interfaces/AppThunk';
import Firestore from '../firestore/Firestore';
import { Accelerometer, AccelerationData, AccelerationDataStorage, WatchHandle } from './interfaces/accelerometer';

declare const navigator: Navigator;

interface Navigator {
  accelerometer: Accelerometer;
}

export enum AccelerometerActionTypes {
  START_ACCELERATION_LISTENER = 'START_ACCELERATION_LISTENER',
  STARTED_ACCELERATION_LISTENER = 'STARTED_ACCELERATION_LISTENER',
  STOP_ACCELERATION_LISTENER = 'STOP_ACCELERATION_LISTENER',
  STOPPED_ACCELERATION_LISTENER = 'STOPPED_ACCELERATION_LISTENER',
  LOG_ACCELERATION_DATA = 'LOG_ACCELERATION_DATA',
  LOGGED_ACCELERATION_DATA = 'LOGGED_ACCELERATION_DATA',
}

export type AccelerometerActions =
  | LoggedAccelerometerAction
  | StartedAccelerometerListenerAction
  | StoppedAccelerometerListenerAction;

// add a logged Accelerometer
export interface LoggedAccelerometerAction extends Action<AccelerometerActionTypes.LOGGED_ACCELERATION_DATA> {
  accelerometer: AccelerationDataStorage;
}

export const loggedAccelerometer = (accelerometer: AccelerationDataStorage): LoggedAccelerometerAction => ({
  type: AccelerometerActionTypes.LOGGED_ACCELERATION_DATA,
  accelerometer,
});

export const logAccelerometerData = (
  rawAccelerometer: AccelerationData,
): AppThunk<Promise<LoggedAccelerometerAction>, AccelerometerActions> => {
  return async (dispatch, getState) => {
    const pir = getState().linkedUsers.selectedLinkedUser?.pir;

    const accelerometer: AccelerationDataStorage = {
      x: rawAccelerometer.x,
      y: rawAccelerometer.y,
      z: rawAccelerometer.z,
      dateTime: new Date(),
      pir,
    };

    const deviceRef = Firestore.collection('deviceAPIAccelerometer').doc();
    await deviceRef.set(accelerometer);

    return dispatch(loggedAccelerometer(accelerometer));
  };
};

export interface StartedAccelerometerListenerAction
  extends Action<AccelerometerActionTypes.STARTED_ACCELERATION_LISTENER> {
  watchID: WatchHandle;
}

export const startedAccelerometerListener = (watchID: WatchHandle): StartedAccelerometerListenerAction => ({
  type: AccelerometerActionTypes.STARTED_ACCELERATION_LISTENER,
  watchID,
});

export const startAccelerometerListener = (): AppThunk<StartedAccelerometerListenerAction, AccelerometerActions> => {
  return (dispatch) => {
    function onSuccess(acceleration: AccelerationData) {
      console.log('x', acceleration.x, 'y', acceleration.y);
      dispatch(logAccelerometerData(acceleration));
    }

    function onError() {
      alert('onError!');
    }

    const options = { frequency: 1000 };

    const watchID = navigator.accelerometer.watchAcceleration(onSuccess, onError, options);

    return dispatch(startedAccelerometerListener(watchID));
  };
};

export type StoppedAccelerometerListenerAction = Action<AccelerometerActionTypes.STOPPED_ACCELERATION_LISTENER>;

export const stoppedAccelerometerListener = (): StoppedAccelerometerListenerAction => ({
  type: AccelerometerActionTypes.STOPPED_ACCELERATION_LISTENER,
});

export const stopAccelerometerListener = (): AppThunk<StoppedAccelerometerListenerAction, AccelerometerActions> => {
  return (dispatch, getState) => {
    const watchID = getState().accelerometer.watchID;

    if (watchID) {
      navigator.accelerometer.clearWatch(watchID);
    }

    console.log('stop acceleration');

    return dispatch(stoppedAccelerometerListener());
  };
};
