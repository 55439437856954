import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItem, Flex, Text, Skeleton, Button } from '@chakra-ui/react';
import { PossibleLinkedUser, LinkedUserRole } from '../linked-users/interfaces/ILinkedUser';
import IUser from '../user/interfaces/IUser';
import { getByRef as getUserByRef } from '../user/utils';
import FAIcon from '../../components/FAIcon';
import { isPirLinkedUser } from './utils';

interface Props {
  linkedUser: PossibleLinkedUser;
  active: boolean;
  onSelect(linkedUser: PossibleLinkedUser): void;
  selectDefault(linkedUser: PossibleLinkedUser): void;
}

interface ItemProps {
  linkedUser: PossibleLinkedUser;
  active: boolean;
  children: JSX.Element | JSX.Element[];
}

const SelectableLinkedUser = (props: Props): JSX.Element => {
  const { t } = useTranslation('home');
  const { linkedUser, active, onSelect, selectDefault } = props;
  const [pir, setPir] = useState<undefined | IUser | null>();
  const [otherUser, setOtherUser] = useState<undefined | IUser | null>();

  useEffect(() => {
    let isMounted = true;
    if (linkedUser.pir) {
      getUserByRef(linkedUser.pir).then((v) => {
        if (isMounted) setPir(v);
      });
    } else {
      if (isMounted) setPir(null);
    }

    if (!isPirLinkedUser(linkedUser) && linkedUser.otherUser) {
      getUserByRef(linkedUser.otherUser).then((v) => {
        if (isMounted) setOtherUser(v);
      });
    } else {
      if (isMounted) setOtherUser(null);
    }

    return () => {
      isMounted = false;
    };
  }, [linkedUser]);

  // While loading, display a skeleton
  if (typeof pir === 'undefined' || typeof otherUser === 'undefined') {
    return (
      <ListItem>
        <Skeleton height={30} />
      </ListItem>
    );
  }

  const Item = (props: ItemProps): JSX.Element => {
    return (
      <ListItem mb={1}>
        <Button
          variant="link"
          onClick={() => selectDefault(props.linkedUser)}
          aria-label={t('selectLinkedUser.setDefaultLabel')}
          title={t('selectLinkedUser.setDefaultTitle')}
          color={props.active ? 'purple3.600' : 'grey6.100'}
          _hover={{
            opacity: 0.8,
          }}
          _active={{
            opacity: 0.4,
          }}
          w={['15%', '15%', '10%']}
        >
          <FAIcon icon="star" />
        </Button>
        <Button
          variant="link"
          onClick={() => onSelect(props.linkedUser)}
          backgroundColor="grey6.50"
          fontWeight={props.active ? 'bold' : 'normal'}
          color="black"
          p={5}
          borderRadius={0}
          w={['85%', '85%', '90%']}
          display="inline-block"
          _hover={{
            backgroundColor: 'grey6.100',
            cursor: 'pointer',
          }}
          _active={{
            opacity: 0.6,
          }}
          _focus={{
            backgroundColor: 'grey6.100',
          }}
        >
          <Flex justifyContent="space-between">
            {props.children}
            <FAIcon icon="arrow-circle-right" color="purple3.600" />
          </Flex>
        </Button>
      </ListItem>
    );
  };

  if (isPirLinkedUser(linkedUser)) {
    return (
      <Item linkedUser={linkedUser} active={active}>
        <Text whiteSpace="normal">{t('selectLinkedUser.pir')}</Text>
      </Item>
    );
  }

  switch (linkedUser.otherUserRole) {
    case LinkedUserRole.PROVIDER:
      if (pir) {
        return (
          <Item linkedUser={linkedUser} active={active}>
            <Text whiteSpace="normal">
              {t('selectLinkedUser.providerWith', {
                name: linkedUser.pirAlias ?? linkedUser.preferredName ?? pir.name ?? pir.email,
              })}
            </Text>
          </Item>
        );
      } else {
        return (
          <Item linkedUser={linkedUser} active={active}>
            <Text whiteSpace="normal">{t('selectLinkedUser.providerWithout')}</Text>
          </Item>
        );
      }

    case LinkedUserRole.SUPPORTER:
      if (pir) {
        return (
          <Item linkedUser={linkedUser} active={active}>
            <Text whiteSpace="normal">{t('selectLinkedUser.supporterWith', { name: pir.name ?? pir.email })}</Text>
          </Item>
        );
      } else {
        return (
          <Item linkedUser={linkedUser} active={active}>
            <Text whiteSpace="normal">{t('selectLinkedUser.supporterWithout')}</Text>
          </Item>
        );
      }
    default:
      return (
        <Item linkedUser={linkedUser} active={active}>
          <Text />
        </Item>
      );
  }

  // // After loading
  // return (
  //   <Item linkedUser={linkedUser} active={active}>
  //     {/* Provider */}
  //     {linkedUser.otherUserRole === LinkedUserRole.PROVIDER && pir && (
  //       <Text whiteSpace="normal">{t('selectLinkedUser.providerWith', { name: pir.name ?? pir.email })}</Text>
  //     )}

  //     {/* Provider without PIR */}
  //     {linkedUser.otherUserRole === LinkedUserRole.PROVIDER && !pir && (
  //       <Text whiteSpace="normal">{t('selectLinkedUser.providerWithout')}</Text>
  //     )}

  //     {/* Supporter */}
  //     {linkedUser.otherUserRole === LinkedUserRole.SUPPORTER && pir && (
  //       <Text whiteSpace="normal">{t('selectLinkedUser.supporterWith', { name: pir.name ?? pir.email })}</Text>
  //     )}

  //     {/* Provider without PIR */}
  //     {linkedUser.otherUserRole === LinkedUserRole.SUPPORTER && !pir && (
  //       <Text whiteSpace="normal">{t('selectLinkedUser.supporterWithout')}</Text>
  //     )}
  //   </Item>
  // );
};

export default SelectableLinkedUser;
