import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAnimation, motion } from 'framer-motion';
import { Heading, Flex, Button, Text } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import { onboarding } from './actions';
import NarrowInterior from '../../components/NarrowInterior';

import { useHistory } from 'react-router-dom';

const Index = (props: PropsFromRedux): JSX.Element => {
  const { onboarding } = props;
  const { t } = useTranslation('onboarding');
  const [step, setStep] = useState(0);
  const MotionHeading = motion(Heading);
  const headerControls = useAnimation();
  const history = useHistory();

  const termsAndConditionsSubmit = (decision: string) => {
    if (decision === 'AGREE') {
      onboarding();
      setStep(1);
      history.push('/informed-consent');
    } else {
      history.push('/logout');
      alert(t('termsAndConditions.disagreeAlert'));
    }
  };

  return (
    <NarrowInterior>
      <MotionHeading as="h2" my={6} fontSize={24} fontWeight="light" color="purple3.600" animate={headerControls}>
        {t('welcome')}
      </MotionHeading>

      {/* Terms and Conditions Page */}
      <Flex flexDir="column" mt={16} mb={22} display={step === 0 ? 'block' : 'none'}>
        <Text fontSize={24} fontWeight="light" color="purple3.600">
          {t('termsAndConditions.header')}
        </Text>
        <Text mt={6} fontSize={18} fontWeight="light" color="purple3.600" whiteSpace="pre-line">
          {t('termsAndConditions.documentation')}
        </Text>
        <Flex mt={12} flexDir="row" alignItems="center" justifyContent="center">
          <Button
            backgroundColor="blue3.100"
            fontWeight="normal"
            _hover={{ backgroundColor: 'blue3.300' }}
            onClick={() => termsAndConditionsSubmit('AGREE')}
            whiteSpace="normal"
          >
            {t('termsAndConditions.agreeButton')}
          </Button>
          <Button
            ml={16}
            backgroundColor="blue3.100"
            fontWeight="normal"
            _hover={{ backgroundColor: 'blue3.300' }}
            onClick={() => termsAndConditionsSubmit('DISAGREE')}
            whiteSpace="normal"
          >
            {t('termsAndConditions.disagreeButton')}
          </Button>
        </Flex>
      </Flex>
    </NarrowInterior>
  );
};

const mapStateToProps = (state: RootState) => {
  const user = state.user.user;
  if (user === null) {
    throw new Error('No authenticated user to onbaord');
  }

  return {
    user,
  };
};

const mapDispatchToProps = {
  onboarding,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Index);
