import firebase from 'firebase/compat/app';
import IWeeklyVitalsAggregates from './interfaces/IWeeklyVitalsAggregates';

export const formatWeeklyAggregatesFromFirestore = (
  snapshot: firebase.firestore.QueryDocumentSnapshot,
): IWeeklyVitalsAggregates => {
  const pir = snapshot.get('pir');

  if (typeof pir === 'undefined') {
    throw new Error('No PIR defined on the alert in the database');
  }

  return {
    id: snapshot.id,
    pir,
    avgSleep: snapshot.get('avgSleep'),
    avgCravingRisk: snapshot.get('avgCravingRisk'),
    avgEmotionalScore: snapshot.get('avgEmotionalScore'),
    totalIncidents: snapshot.get('totalIncidents'),
    avgParticipation: snapshot.get('avgParticipation'),
    avgStress: snapshot.get('avgStress'),
    avgWatchUse: snapshot.get('avgWatchUse'),
    dateTime: snapshot.get('dateTime'),
    totalSupportRequests: snapshot.get('totalSupportRequests'),
  };
};
