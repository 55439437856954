/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text, InputGroup, Input, InputLeftAddon } from '@chakra-ui/react';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import { MediaFormValues } from './../utils/utils';

interface Props {
  name: keyof MediaFormValues;
  register: UseFormRegister<MediaFormValues>;
  validation: RegisterOptions;
  interactionCallback?(): void;
  defaultValue?: string;
}

const AddWebLink = ({ name, register, validation, interactionCallback, defaultValue }: Props): React.ReactElement => {
  const [showLink, setShowLink] = useState(true);
  const { t } = useTranslation('common');

  return (
    <Box>
      {showLink && (
        <Button mt={2} mb={4} onClick={() => setShowLink(false)} variant="link" fontSize={14} data-test="add-media">
          {t('addWebLink.addLinkButton')}
        </Button>
      )}
      <Box mt={4} style={{ display: showLink ? 'none' : 'block' }}>
        <Text fontSize={14}>{t('addWebLink.linkTitle')}</Text>
        <InputGroup size="lg">
          <InputLeftAddon children="https://" bg="grey6.50" />
          <Input
            type="text"
            fontSize={14}
            {...register(name, validation)}
            borderColor="black"
            borderRadius={2}
            mb={4}
            defaultValue={defaultValue === undefined ? '' : defaultValue}
            onFocus={() => {
              if (interactionCallback) {
                // function to add interaction log for module that includes this component
                interactionCallback();
              }
            }}
            data-test="add-media-input"
          />
        </InputGroup>
      </Box>
    </Box>
  );
};
export default AddWebLink;
