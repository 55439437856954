import React, { useContext } from 'react';

import Dashboard from '../dashboardMonitor/Index';
import ProviderDashboard from '../home/ProviderDashboard';
import ProviderWithout from '../home/ProviderWithout';
import { SelectedUserContext } from '../interior/Index';

interface CareProviderProps {
  noLinks: boolean;
}

export const CareProvider = ({ noLinks }: CareProviderProps): JSX.Element => {
  const { selectedUser } = useContext(SelectedUserContext);
  if (noLinks) {
    return <ProviderWithout />;
  } else if (selectedUser === null) {
    return <Dashboard />;
  } else {
    return <ProviderDashboard />;
  }
};
