import firebase from 'firebase/compat/app';
import IUser, { UserRole, IFirebaseUser } from './interfaces/IUser';

interface IRoleTranslation {
  [index: string]: UserRole;
}

const roleTranslation: IRoleTranslation = {
  cp: UserRole.CP,
  pir: UserRole.USER,
  admin: UserRole.ADMIN,
  org: UserRole.HEAD_ORG,
};

export const formatUserFromFirestore = (
  snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
): IUser => {
  if (!snapshot.exists) {
    throw new Error('User document does not exist in Firestore!');
  }

  const email = snapshot.get('email');
  if (typeof email === 'undefined') {
    throw new Error('Email undefined on user document!');
  }

  let showColor = snapshot.get('showColor');
  let showVitals = snapshot.get('showVitals');
  let role = snapshot.get('role');
  let deletedDatetime = snapshot.get('deletedDatetime');

  if (typeof showColor === 'undefined') {
    showColor = true;
  }

  if (typeof showVitals === 'undefined') {
    showVitals = true;
  }

  if (typeof role !== 'undefined') {
    role = roleTranslation[role];
  }

  if (deletedDatetime) {
    deletedDatetime = deletedDatetime.toDate();
  }

  return {
    id: snapshot.id,
    name: snapshot.get('name'),
    email,
    cravingsPredictionEnabled: snapshot.get('cravingsPredictionEnabled') ?? false,
    preferences: {
      hiddenGraphs: snapshot.get('preferences.hiddenGraphs') ?? '',
    },
    doesWantEmail: snapshot.get('doesWantEmail'),
    phone: snapshot.get('phone'),
    doesWantSms: snapshot.get('doesWantSms'),
    fcmToken: snapshot.get('fcmToken'),
    fcmTokens: snapshot.get('fcmTokens'),
    doesWantPush: snapshot.get('doesWantPush'),
    image: snapshot.get('image'),
    timezone: snapshot.get('timezone'),
    manuallySetTimezone: snapshot.get('manuallySetTimezone'),
    stoppedSms: snapshot.get('stoppedSms'),
    cpGeneralContactInfo: snapshot.get('cpGeneralContactInfo'),
    deletionStatus: snapshot.get('deletionStatus'),
    showColor,
    showVitals,
    role,
    deletedDatetime,
    emailBeforeDeleted: snapshot.get('emailBeforeDeleted'),
    originalEmail: snapshot.get('originalEmail'),
    emailReverted: snapshot.get('emailReverted'),
    devicePairedStatus: snapshot.get('devicePairedStatus'),
  };
};

export const formatUserToFirestore = (user: IUser): IFirebaseUser => {
  const out: IFirebaseUser = {
    email: user.email,
    cravingsPredictionEnabled: user.cravingsPredictionEnabled,
    preferences: user.preferences,
    userId: user.id,
  };

  if (typeof user.name !== 'undefined') {
    out.name = user.name;
  }

  if (typeof user.doesWantEmail !== 'undefined') {
    out.doesWantEmail = user.doesWantEmail;
  }

  if (typeof user.phone !== 'undefined') {
    out.phone = user.phone;
  }

  if (typeof user.doesWantSms !== 'undefined') {
    out.doesWantSms = user.doesWantSms;
  }

  if (typeof user.fcmToken !== 'undefined') {
    out.fcmToken = user.fcmToken;
  }

  if (typeof user.fcmTokens !== 'undefined') {
    out.fcmTokens = user.fcmTokens;
  }

  if (typeof user.doesWantPush !== 'undefined') {
    out.doesWantPush = user.doesWantPush;
  }

  if (typeof user.image !== 'undefined') {
    out.image = user.image;
  }

  if (typeof user.timezone !== 'undefined') {
    out.timezone = user.timezone;
  }

  if (typeof user.manuallySetTimezone !== 'undefined') {
    out.manuallySetTimezone = user.manuallySetTimezone;
  }

  if (typeof user.cpGeneralContactInfo !== 'undefined') {
    out.cpGeneralContactInfo = user.cpGeneralContactInfo;
  }

  if (typeof user.stoppedSms !== 'undefined') {
    out.stoppedSms = user.stoppedSms;
  }

  if (typeof user.showColor !== 'undefined') {
    out.showColor = user.showColor;
  } else {
    out.showColor = false;
  }

  if (typeof user.showVitals !== 'undefined') {
    out.showVitals = user.showVitals;
  } else {
    out.showVitals = false;
  }

  if (typeof user.deletedDatetime !== 'undefined') {
    out.deletedDatetime = user.deletedDatetime;
  } else {
    out.deletedDatetime = firebase.firestore.FieldValue.delete();
  }

  if (typeof user.deletionStatus !== 'undefined') {
    out.deletionStatus = user.deletionStatus;
  }

  if (typeof user.emailBeforeDeleted !== 'undefined') {
    out.emailBeforeDeleted = user.emailBeforeDeleted;
  }

  if (typeof user.originalEmail !== 'undefined') {
    out.originalEmail = user.originalEmail;
  }

  if (typeof user.emailReverted !== 'undefined') {
    out.emailReverted = user.emailReverted;
  }

  if (typeof user.devicePairedStatus !== 'undefined') {
    out.devicePairedStatus = user.devicePairedStatus;
  }

  if (typeof user.role !== 'undefined') {
    for (const dbRole in roleTranslation) {
      if (roleTranslation[dbRole] === user.role) {
        out.role = dbRole;
        break;
      }
    }
  }
  return out;
};

/**
 * @param ref A user database reference
 *
 * @return The object for the user or null if the referenced user does not exist
 */
export const getByRef = async (ref: firebase.firestore.DocumentReference): Promise<null | IUser> => {
  if (ref?.parent?.path !== 'users') {
    throw new Error(
      'The provided user reference was not for the users collection, instead it was for: ' + ref.parent.path,
    );
  }

  return ref
    .get()
    .then((snapshot) => {
      return snapshot.exists ? formatUserFromFirestore(snapshot) : null;
    })
    .catch((e) => {
      console.error(`Could not get user by ref in getByRef: ${e}`);
      throw e;
    });
};

/**
 * @param userOrPhone Either a user object or a string to format into a phone
 *
 * @return Either the formatted phone string, or null if the supplied User object does not have a phone number
 */
export const formatPhone = (userOrPhone: IUser | string): string | null => {
  let phoneString: string;
  if (typeof userOrPhone === 'string') {
    if (userOrPhone.length !== 10) {
      throw new Error('The supplied phone string is not 10 characters long');
    }

    phoneString = userOrPhone;
  } else {
    if (!userOrPhone.phone) {
      return null;
    }

    phoneString = userOrPhone.phone;
  }

  return `(${phoneString.substring(0, 3)}) ${phoneString.substring(3, 6)}-${phoneString.substring(6, 10)}`;
};

export const removeUndefined = (obj: any) => {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
};

export const wasEdited = (originalVal: any, newVal: any) => {
  return originalVal !== newVal;
};
