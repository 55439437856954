import { HomeAction, LoadHomeAction } from './actions';

interface HomeState {
  loading: boolean;
}

const defaultState: HomeState = {
  loading: false,
};

const homeReducer = (state: HomeState = defaultState, action: LoadHomeAction): HomeState => {
  switch (action.type) {
    case HomeAction.LOAD_HOME:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default homeReducer;
