import { FreeDateActions } from './actions';
import IFreeDate from './interfaces/IFreeDate';
import { FreeDateActionTypes } from './utils';

interface FreeDateState {
  freeDate: null | IFreeDate;
}

const defaultState: FreeDateState = {
  freeDate: null,
};

const freeDateReducer = (state: FreeDateState = defaultState, action: FreeDateActions): FreeDateState => {
  switch (action.type) {
    case FreeDateActionTypes.SET_FREE_DATE:
      return {
        ...state,
        freeDate: action.freeDate,
      };
    default:
      return state;
  }
};

export default freeDateReducer;
