import React from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Text, List, ListItem, Link, Flex, Image } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import { useQueryMeditations, useDeleteMeditations } from '../../modules/meditations/queryHooks';
import { getToDos, updateToDo } from '../../modules/todo/actions';
import { createInteraction } from '../../modules/interactions/actions';
import { moduleName, interactionType } from '../../modules/interactions/constants';
import IMeditation from '../../modules/meditations/interfaces/IMeditation';
import DisplayWebLink from '../DisplayWebLink';
import { getAttachmentType, getUrlType } from 'src/modules/meditations/utils';
import { isYoutube, youtube_parser } from './utils';
import TextResourceBox from "../TextResourceBox"

interface SuppliedProps {
  onSelectMeditation(meditation: IMeditation | null): void;
}

type Props = SuppliedProps;

const MeditationListDisplay = ({
  todos,
  updateToDo,
  pir,
  createInteraction,
  onSelectMeditation,
}: Props & PropsFromRedux): null | React.ReactElement => {
  const { t } = useTranslation(['meditations', 'common']);
  const { data: meditations = [] } = useQueryMeditations(pir?.id);
  const { mutate: deleteMeditation } = useDeleteMeditations(pir?.id);

  const selectedTodo = useSelector((state: RootState) => state.todo.selectedToDo);
  const onDeleteMeditation = (meditations: IMeditation): void => {
    if (window.confirm(t('common:manageContactModal.deleteMeditationConfirmation'))) {
      deleteMeditation(meditations);
      createInteraction({
        pir,
        dateTime: new Date(),
        moduleName: moduleName.MEDITATIONS,
        interactionType: interactionType.MEDITATIONS.DELETED,
      });
    }
  };

  const openEdit = (meditation: IMeditation) => {
    onSelectMeditation(meditation);
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MEDITATIONS,
      interactionType: interactionType.MEDITATIONS.EDITED,
    });
  };

  if (meditations.length === 0) {
    return null;
  }

  const triggerMeditationTodo = () => {
    if (todos && selectedTodo) {
      if (!selectedTodo.isCompleted && selectedTodo.type === 'meditations') {
        selectedTodo.completedDateTime = new Date();
        selectedTodo.isCompleted = true;
        updateToDo(selectedTodo, todos);
      }
    } else if (todos && !selectedTodo) {
      todos.forEach((todo) => {
        if (todo.type === 'meditations') {
          todo.completedDateTime = new Date();
          todo.isCompleted = true;
          updateToDo(todo, todos);
        }
      });
    }
  };

  console.log(meditations)

  return (
    <Flex width="100%" direction="column" justifyContent="left">
      {meditations.map((meditation, index) => (
        <Box
          width="100%"
          backgroundColor="grey6.50"
          pt={4}
          pb={0}
          pr={4}
          pl={4}
          mb={6}
          key={'meditate' + index}
          data-test={meditation.title}
          borderRadius={8}
        >
          <List as="ol" mb={0}>
            <ListItem data-test={meditation.title + '-title'}>
              <Flex justifyContent={'space-between'}>
                <Text fontWeight="bold" fontSize={18} mb={3} mr={5}>
                  {meditation.title}
                </Text>
                <Text textAlign="right" fontSize={14}>
                  <Link
                    color={'black'}
                    onClick={() => openEdit(meditation)}
                    textDecoration="underline"
                    _hover={{
                      textDecoration: 'none',
                    }}
                    data-test={meditation.title + '-edit'}
                  >
                    {t('meditations:meditationListDisplay.editLink')}
                  </Link>
                  <Link
                    color={'black'}
                    ml={2}
                    textDecoration="underline"
                    onClick={() => onDeleteMeditation(meditation)}
                    _hover={{ textDecoration: 'none' }}
                    data-test={meditation.title + '-delete'}
                  >
                    {t('meditations:meditationListDisplay.deleteLink')}
                  </Link>
                </Text>
              </Flex>
            </ListItem>
            {meditation.length && (
              <>
                <ListItem color="grey3.400" fontSize={14}>
                  {t('meditations:meditationListDisplay.listLengthHeader')}
                </ListItem>
                <ListItem fontSize={14} mb={3} data-test={meditation.title + '-length'}>
                  {meditation.length} {t('meditations:meditationListDisplay.listDisplayLength')}
                </ListItem>
              </>
            )}
            {meditation.note && (
              <>
                <ListItem color="grey3.400" fontSize={14}>
                  {t('meditations:meditationListDisplay.listNoteHeader')}
                </ListItem>
                <ListItem fontSize={14} mb={3} data-test={meditation.title + '-notes'}>
                  <TextResourceBox isEdit={true} textContent={meditation.note ? meditation.note : ""}/>
                </ListItem>
              </>
            )}
            {meditation.link &&
              (getUrlType(meditation.link) === 'link' ? (
                <ListItem mb={3} fontSize={14}>
                  <DisplayWebLink
                    propOnClick={() => {
                      triggerMeditationTodo();
                    }}
                    href={meditation.link}
                    linkText={`${meditation.link.slice(0, 30)}${meditation.link.length > 30 ? '...' : ''}`}
                  />
                </ListItem>
              ) : (
                <ListItem mb={3} fontSize={14}>
                  {isYoutube(meditation.link) && youtube_parser(meditation.link) ? (
                    <Image
                      src={`https://img.youtube.com/vi/${youtube_parser(meditation.link)}/default.jpg`}
                      alt={meditation.link}
                      boxSize="100px"
                      objectFit="cover"
                    />
                  ) : (
                    <Text>
                      {meditation.link.slice(0, 30)}${meditation.link.length > 30 ? '...' : ''}
                    </Text>
                  )}
                </ListItem>
              ))}
            {meditation?.attachmentUrl &&
              meditation?.attachmentName &&
              (getAttachmentType(meditation.attachmentName) === 'video' ? (
                <ListItem mb={3} fontSize={14}>
                  <Text> {meditation.attachmentName}</Text>
                </ListItem>
              ) : getAttachmentType(meditation.attachmentName) === 'image' ? (
                <ListItem mb={3} fontSize={14}>
                  <Image
                    src={meditation.attachmentUrl}
                    alt={meditation.attachmentName}
                    boxSize="100px"
                    objectFit="cover"
                  />
                </ListItem>
              ) : (
                <ListItem mb={3} fontSize={14}>
                  <DisplayWebLink
                    propOnClick={() => {
                      triggerMeditationTodo();
                    }}
                    href={meditation.attachmentUrl}
                    linkText={meditation.attachmentName}
                  />
                </ListItem>
              ))}
            {/*
            {!window.cordova && meditation.attachmentUrl && meditation.meditationType === "pdf" && (
              <ListItem mb={3}>
                <iframe  src={meditation.attachmentUrl} width="100%"/>
              </ListItem>
            )} */}
            <ListItem mb={3} fontSize={14}>
              {meditation.attachmentUrl && meditation.attachmentName && meditation.link
                ? `${getUrlType(meditation.link).charAt(0).toUpperCase() + getUrlType(meditation.link).slice(1)} & ${
                    getAttachmentType(meditation.attachmentName).charAt(0).toUpperCase() +
                    getAttachmentType(meditation.attachmentName).slice(1)
                  }`
                : meditation.meditationType === 'pdf'
                ? meditation.meditationType.toUpperCase()
                : meditation.meditationType.charAt(0).toUpperCase() + meditation.meditationType.slice(1)}
            </ListItem>
          </List>
        </Box>
      ))}
    </Flex>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    todos, // selectedToDo,
  } = state.todo;
  // const todos = state.todo.todos;

  const { selectedLinkedUser } = state.linkedUsers;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when seeing this component.',
    );
  }

  return {
    todos,
    pir: selectedLinkedUser.pir,
  };
};

const MapDispatchToProps = {
  createInteraction,
  getToDos,
  updateToDo,
};

const connector = connect(mapStateToProps, MapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MeditationListDisplay);
