import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text, Input, FormErrorMessage, FormControl, FormLabel, Flex } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import { createMotivator, updateMotivator } from '../../modules/motivator/actions';
import { createInteraction } from '../../modules/interactions/actions';
import { moduleName, interactionType } from '../../modules/interactions/constants';
import FAIcon from '../FAIcon';
import AddMediaFile from '../AddMediaFile';
import AddMediaFileMobile from '../AddMediaFileMobile';
import { MediaFormValues } from '../../utils/utils';

import { Center } from '@chakra-ui/layout';
import { useDevice } from 'src/DeviceContext';

const AddMotivatorForm = ({
  motivator,
  motivatorCreated,
  motivatorUpdating,
  createMotivator,
  updateMotivator,
  pir,
  selectedMotivator,
  createInteraction,
}: PropsFromRedux): React.ReactElement => {
  const { t } = useTranslation('motivator');
  const { register, reset, handleSubmit, formState, setValue } = useForm<MediaFormValues>({
    defaultValues: {
      message: selectedMotivator?.message,
      attachmentUrl: selectedMotivator?.attachmentUrl,
      attachmentName: selectedMotivator?.attachmentName,
    },
  });
  const { errors } = formState;

  const { isMobileDeviceOrCordova } = useDevice();
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [count, setCount] = useState<number>(motivator?.message?.length || 0);

  const [motivatorUploading, setMotivatorUploading] = useState(false);

  const handleUploading = (data: boolean) => {
    setMotivatorUploading(data);
  };

  const displaySaveButton = () => {
    const message = showSaveButton ? t('addMotivatorForm.saveButton') : t('addMotivatorForm.saveButtonConfirm');

    const icon = !showSaveButton && <FAIcon icon="check" ml={4} fontSize={14} />;

    return (
      <FormControl isRequired={true}>
        <Flex justifyContent="flex-end">
          <Button
            backgroundColor={'rgb(88, 63, 115)'}
            color={'#ffffff'}
            isDisabled={!showSaveButton || motivatorUploading}
            mt={10}
            isLoading={formState.isSubmitting}
            type="submit"
          >
            {message}
            {icon}
          </Button>
        </Flex>
      </FormControl>
    );
  };

  const saveMotivator = (motivator: MediaFormValues): void => {
    const message = motivator.message as string;
    if (selectedMotivator?.id) {
      let attachmentUrl = motivator.attachmentUrl;
      if (!attachmentUrl) {
        attachmentUrl = selectedMotivator?.attachmentUrl;
      }
      updateMotivator({
        id: selectedMotivator.id,
        pir,
        message,
        attachmentUrl,
        attachmentName: motivator.attachmentName,
      });
    } else {
      createMotivator({
        pir,
        ...motivator,
        message,
      });
    }
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MOTIVATOR,
      interactionType: interactionType.MOTIVATOR.SAVED,
    });
  };

  const addModifiedFieldInteraction = () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MOTIVATOR,
      interactionType: interactionType.MOTIVATOR.MODIFIED_FIELD,
    });
  };

  useEffect(() => {
    if (motivatorCreated || motivatorUpdating) {
      setShowSaveButton(false);
    }
  }, [motivatorCreated, motivatorUpdating]);

  useEffect(() => {
    if (selectedMotivator) {
      setShowSaveButton(true);
      reset({
        message: selectedMotivator.message,
        attachmentUrl: selectedMotivator.attachmentUrl,
        attachmentName: selectedMotivator.attachmentName,
      });
    }
  }, [selectedMotivator, reset]);

  const displayMediaUploader = () => {
    if (isMobileDeviceOrCordova) {
      return (
        <AddMediaFileMobile
          fileName="attachmentName"
          urlName="attachmentUrl"
          setValue={setValue}
          attachmentUrl={selectedMotivator?.attachmentUrl}
          register={register}
          changeUploadState={handleUploading}
          accept="image/png, image/gif, application/msword, image/gif, image/jpeg, audio/mpeg, video/mpeg, application/pdf"
          multipleFiles={false}
          validation={{
            validate: () => {
              if (selectedMotivator?.attachmentUrl) return true;
            },
          }}
          interactionCallback={addModifiedFieldInteraction}
        />
      );
    }

    return (
      <AddMediaFile
        fileName="attachmentName"
        urlName="attachmentUrl"
        displayFile={selectedMotivator?.attachmentName}
        register={register}
        setValue={setValue}
        validation={{
          validate: (value: string) => {
            if (value === undefined || value === '') {
              const error = t('addMotivatorForm.errors.fileRequired');
              return error;
            }
            if (selectedMotivator?.attachmentUrl) return true;
          },
        }}
        interactionCallback={addModifiedFieldInteraction}
      />
    );
  };

  return (
    <Box>
      {(!motivator || selectedMotivator) && (
        <form autoComplete="off" onSubmit={handleSubmit(saveMotivator)}>
          <FormControl isInvalid={errors.message !== undefined}>
            {/*message field*/}
            <FormLabel fontSize={14}>{t('addMotivatorForm.messageField')}</FormLabel>
            <Flex direction="row" flexWrap={'wrap'} w="100">
              <Input
                fontSize={14}
                size="lg"
                w="100%"
                borderColor="#E2E8F0"
                borderRadius={4}
                mb={4}
                id="text-area"
                {...register('message', {
                  required: {
                    value: true,
                    message: t('addMotivatorForm.errors.messageRequired'),
                  },
                })}
                type="text"
                maxLength={30}
                onChange={(e: any) => setCount(e.target.value.length)}
                onFocus={addModifiedFieldInteraction}
              />
              <Box w="100%" textAlign={'right'}>
                <Text fontSize={14}>{`(${count}/30)`}</Text>
              </Box>
            </Flex>
            <FormErrorMessage>
              {errors.message && errors.message.message && 'Your input should be no more than 30 characters'}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.attachmentUrl !== undefined}>
            {displayMediaUploader()}
            <FormErrorMessage>
              {errors.attachmentUrl && errors.attachmentUrl.message && 'File required'}
            </FormErrorMessage>
          </FormControl>

          <Center>{displaySaveButton()}</Center>
        </form>
      )}
    </Box>
  );
};
const mapDispatchToProps = {
  createMotivator,
  updateMotivator,
  createInteraction,
};

function mapStateToProps(state: RootState) {
  const { selectedLinkedUser } = state.linkedUsers;
  const { motivator, selectedMotivator, creating, loading } = state.motivator;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }

  if (motivator === null) {
    throw new Error("Motivator should't be null!");
  }

  return {
    pir: selectedLinkedUser.pir,
    selectedMotivator,
    motivator: motivator[0],
    motivatorCreated: creating,
    motivatorUpdating: loading,
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AddMotivatorForm);
