import React from 'react';
import { useSelector } from 'react-redux';
import { Box, BoxProps } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import Invite from './Invite';

const InviteList = (props: BoxProps): JSX.Element | null => {
  const invites = useSelector(({ linkedUsers }: RootState) => {
    if (!linkedUsers.invites) {
      return [];
    }

    return linkedUsers.invites;
  });

  if (invites.length === 0) {
    return null;
  }

  return (
    <Box {...props}>
      {invites.map((invite) => (
        <Invite invite={invite} key={invite.id} />
      ))}
    </Box>
  );
};

export default InviteList;
