import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  DrawerHeader,
  Link as ChakraLink,
  List,
  ListItem,
  Avatar,
  Button,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import FAIcon from '../../components/FAIcon';
import { LinkedUserRole } from '../linked-users/interfaces/ILinkedUser';
import ManageContactModal from '../../components/ManageContactModal/ManageContactModal';
import { UserContext } from '../interior/Index';
import { UserRole } from '../user/interfaces/IUser';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  open: boolean;
  closeMenu(): void;
}

interface MenuItemProps {
  link?: string;
  icon: IconName;
  label: string;
  onClick?: () => void;
}

const Menu = (props: SuppliedProps): JSX.Element | null => {
  const { t } = useTranslation('menu');
  const user = useContext(UserContext);
  const { isMobileDeviceAndCordova } = useDevice();
  const contactModalDisclosure = useDisclosure();

  if (user === null) {
    return null;
  }

  const MenuItem = ({ link, icon, label, onClick }: MenuItemProps): JSX.Element => {
    if (!link) {
      // onClick only
      // TODO refactor for the users to use routing
      return (
        <ChakraLink onClick={onClick}>
          <ListItem
            pt={3}
            pb={3}
            pl={8}
            _hover={{ backgroundColor: 'grey.100', cursor: 'pointer' }}
            textTransform="uppercase"
            fontWeight="bold"
            fontSize="xs"
            color="purple3.600"
            letterSpacing="wide"
          >
            <FAIcon icon={icon} mr={6} as="div" display="inline-block" fontSize={18} width={6} color="grey.500" />
            {label}
          </ListItem>
        </ChakraLink>
      );
    }

    return (
      <Link to={link}>
        <ListItem
          pt={3}
          pb={3}
          pl={8}
          _hover={{ backgroundColor: 'grey.100', cursor: 'pointer' }}
          textTransform="uppercase"
          fontWeight="bold"
          fontSize="xs"
          color="purple3.600"
          letterSpacing="wide"
        >
          <FAIcon icon={icon} mr={6} as="div" display="inline-block" fontSize={18} width={6} color="grey.500" />
          {label}
        </ListItem>
      </Link>
    );
  };

  const roleDisplay = (role: LinkedUserRole): string => {
    switch (role) {
      case LinkedUserRole.PIR:
        return t('roles.pir');
      case LinkedUserRole.PROVIDER:
        return t('roles.provider');
      case LinkedUserRole.SUPPORTER:
        return t('roles.supporter');
      default:
        return t('roles.unknown');
    }
  };

  return (
    <>
      <ManageContactModal
        isOpen={contactModalDisclosure.isOpen}
        onOpen={contactModalDisclosure.onOpen}
        onClose={contactModalDisclosure.onClose}
        pir={null}
      />
      <Drawer placement="left" isFullHeight={true} onClose={props.closeMenu} isOpen={props.open} size="xs">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color="white" />
          <DrawerHeader bg="purple3.600" pl={8}>
            <Flex alignItems="center">
              <Box>
                <Avatar
                  w="48px"
                  h="48px"
                  mr={4}
                  src={user?.image}
                  backgroundColor="white"
                  icon={<FAIcon icon="user-circle" color="white" fontSize="3xl" />}
                  // name={user.name} << enable for name initials as fallback
                />
              </Box>
              <Flex flexDir="column">
                <ChakraLink color="white" fontSize="sm" onClick={contactModalDisclosure.onOpen}>
                  {user.name ?? user.email}
                </ChakraLink>
                {
                  <>
                    {user.role === UserRole.USER && (
                      <Text color="white" fontSize="xs" fontWeight="light">
                        {roleDisplay(LinkedUserRole.PIR)}
                      </Text>
                    )}
                    {/*user.role === UserRole.CP && (
                      <Text color="white" fontSize="xs" fontWeight="light">
                        {roleDisplay(props.linkedUser.otherUserRole)}
                      </Text>
                    )*/}
                  </>
                }
              </Flex>
            </Flex>
          </DrawerHeader>
          <DrawerBody pl={0} pr={0}>
            <Flex as="nav">
              <List mt={3} mb={3} w="100%">
                {/*<MenuItem link="/" icon="home" label={t('homeLink')} />*/}
                {/* Temporarily hiding alerts module: */}
                {/* <MenuItem link="/alerts" icon="bell" label={t('Alerts')} /> */}
                {isMobileDeviceAndCordova && <MenuItem link="/device" icon="cog" label={t('device')} />}
                {user.role === UserRole.CP && user.hasAccessTo.length > 0 && (
                  <MenuItem link="/dashboard" icon="users" label={t('monitor')} />
                )}
              </List>
            </Flex>

            <Flex mt={6} ml={8}>
              <Link to="/logout">
                <Button
                  variant="outline"
                  textTransform="uppercase"
                  fontSize="xs"
                  colorScheme="grey"
                  borderWidth={2}
                  borderRadius={100}
                  pr={6}
                  pl={6}
                >
                  {t('logOutButton')}
                </Button>
              </Link>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Menu;
