import { QueryDocumentSnapshot } from 'firebase/firestore';
import IAlert from './interfaces/IAlert';

export const formatAlertFromFirestore = (snapshot: QueryDocumentSnapshot): IAlert => {
  const pir = snapshot.get('pir');

  if (typeof pir === 'undefined') {
    throw new Error('No PIR defined on the alert in the database');
  }

  return {
    pir,
    created: snapshot.get('created').toDate(),
    type: snapshot.get('type'),
    name: snapshot.get('name'),
  };
};
