import React, { useState, useRef } from 'react';
import {
  useToast,
  Input,
  Flex,
  Box,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react';
import AvatarEditor from 'react-avatar-editor';
import styled from '@emotion/styled';
import { uploadFile, deleteFile } from '../../modules/firebase/storage';

import IUser from '../../modules/user/interfaces/IUser';
import { useDragAndDrop } from './useDragAndDrop';
import PrimaryButton from '../PrimaryButton';
import { compressImgFile } from '../../lib/utils';

type ImageUploadProps = {
  user: IUser;
  updatePartialUser: (id: string, partialUser: Partial<IUser>) => Promise<any>;
};

export const ImageUpload = ({ user, updatePartialUser }: ImageUploadProps): JSX.Element | null => {
  const avatarRef = useRef<AvatarEditor>(null);
  const toast = useToast();

  const [imageSrc, setImageSrc] = useState<string>(user.image ?? '');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [scaleValue, setScaleValue] = React.useState(1);
  const [rotateValue, setRotateValue] = React.useState(0);
  const [file, setFile] = useState<File | null>(null);

  const { dragOver, setDragOver, onDragOver, onDragLeave } = useDragAndDrop(isSubmitting);

  const pushToastMsg = (title: string, status: 'success' | 'error') => toast({ title, status, isClosable: true });

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const userImage = user.image ?? '';
      const resetUserImage = !file?.name && !imageSrc && userImage;

      if (resetUserImage) {
        await updateUserImage(user, userImage, '');
        pushToastMsg('Image has been reset!', 'success');
        return;
      }

      if (!avatarRef.current) {
        throw new Error('Please select an image to upload!');
      }

      const croppedImg = avatarRef.current?.getImageScaledToCanvas();
      const imageBlob = await new Promise((resolve) => croppedImg?.toBlob(resolve));

      if (!imageBlob) {
        throw new Error('Please re-select an image to upload!');
      }

      const compressedImgBlob = await compressImgFile(imageBlob as Blob, 512, 512);

      if (!file || !file.name) {
        throw new Error('Please select an image to upload!');
      }
      const downloadURL = await uploadFile(file, compressedImgBlob);
      if (downloadURL === userImage) {
        throw new Error('Image already exists! Please upload a different image.');
      }
      await updateUserImage(user, userImage, downloadURL);

      pushToastMsg('Image has been saved!', 'success');
    } catch (error: any) {
      pushToastMsg(error.message, 'error');
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateUserImage = async (user: IUser, prevImage: string, newImage: string) => {
    resetImage(newImage);

    // add new image url to user.image
    await updatePartialUser(user.id, {
      image: String(newImage),
    });

    if (prevImage) {
      await deleteFile(prevImage);
    }
  };

  const onDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();

    if (isSubmitting) return;

    resetImage();

    setDragOver(false);

    const selectedFile = e?.dataTransfer?.files[0];

    if (selectedFile.type.split('/')[0] !== 'image') {
      return pushToastMsg('Please provide an image file to upload!', 'error');
    }

    setFile(selectedFile);
  };

  const fileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isSubmitting && e.target.files) {
      setFile(e.target.files[0]);
    }
    e.target.value = '';
  };

  const resetImage = (src = '') => {
    setImageSrc(src);
    setFile(null);
    setRotateValue(0);
    setScaleValue(1);
  };

  const convertImgUrl = (image: string | Blob): string => {
    return typeof image !== 'string' ? URL.createObjectURL(image) : image;
  };

  const labelText = !dragOver ? 'Select or Drop your image here...' : 'Drop here...';

  return (
    <Flex align="center" direction="column" m="auto" maxW="400px">
      <Box w="100%">
        <Label
          htmlFor="imageProfile"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          isDraggedOver={dragOver}
          isFile={!!file || !!imageSrc}
          isDisabled={isSubmitting}
        >
          {!imageSrc && !file ? <LabelText isDraggedOver={dragOver}>{labelText}</LabelText> : null}
          {imageSrc ? (
            <Image
              isDisabled={isSubmitting}
              src={convertImgUrl(file ?? imageSrc)}
              alt={"User's uploaded profile image"}
            />
          ) : file ? (
            <AvatarEditor
              ref={avatarRef}
              image={convertImgUrl(file)}
              width={300}
              height={300}
              border={0}
              scale={scaleValue}
              rotate={rotateValue}
              color={[255, 255, 255, 0.6]}
              crossOrigin="anonymous"
            />
          ) : null}
          <Input
            type="file"
            name="imageProfile"
            id="imageProfile"
            accept="image/*"
            hidden={true}
            onChange={fileSelect}
            disabled={!!file || !!imageSrc || isSubmitting}
          />
        </Label>
        {file ? (
          <Box>
            <div>Scale: {scaleValue}</div>
            <Slider
              onChange={(value) => setScaleValue(value)}
              aria-label="slider-ex-1"
              step={0.05}
              value={scaleValue}
              defaultValue={1}
              min={0}
              max={5}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <div>Rotate: {rotateValue} degrees</div>
            <Slider
              onChange={(v) => setRotateValue(v)}
              aria-label="slider-ex-1"
              step={1}
              defaultValue={0}
              value={rotateValue}
              min={0}
              max={360}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>
        ) : null}
      </Box>
      <Flex w="100%">
        <Button w="100%" m="10px" onClick={() => resetImage()} isDisabled={(!imageSrc && !file) || isSubmitting}>
          Clear
        </Button>
        <PrimaryButton w="100%" m="10px" onClick={onSubmit} isLoading={isSubmitting}>
          Save
        </PrimaryButton>
      </Flex>
    </Flex>
  );
};

const Label = styled.label<{
  isDraggedOver: boolean;
  isDisabled: boolean;
  isFile: boolean;
}>`
  min-width: 300px;
  min-height: 300px;
  max-width: 300px;
  max-height: 300px;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px
    ${({ isDraggedOver, isFile }) => (isDraggedOver ? '#2cbc69 dashed' : isFile ? '#583f73 solid' : '#583f73 dashed')};
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto 10px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'initial')};
`;

const LabelText = styled.span<{ isDraggedOver?: boolean }>`
  font-size: 18px;
  word-break: break-word;
  text-align: center;
  margin: auto 10px;
  color: ${({ isDraggedOver }) => (isDraggedOver ? '#2cbc69' : '#583f73')};
`;

const Image = styled.img<{ isDisabled: boolean }>`
  min-width: inherit;
  min-height: inherit;
  max-width: inherit;
  max-height: inherit;
  height: inherit;
  width: inherit;
  object-fit: cover;
  border-radius: 6px;
  transition: opacity 1s ease-in-out;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;
