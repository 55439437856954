import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react';
import { goToAppSettings, isThisAndroid } from 'src/lib/wearable';
import PrimaryButton from 'src/components/PrimaryButton';

interface Props {
  isOpen: boolean;
  setAlertDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  needsToAuthorizeBluetooth: boolean;
}

const isAndroid = isThisAndroid();

const PairAlertDialog = ({ isOpen, setAlertDialogOpen, needsToAuthorizeBluetooth }: Props): JSX.Element => {
  const cancelRef = React.createRef<HTMLElement>();
  const { t } = useTranslation('device');

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={() => setAlertDialogOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader color="purple3.600" fontWeight="light" fontSize={24}>
              {t('deviceModal.bluetoothOffDialog.pairHeader')}
            </AlertDialogHeader>
            <AlertDialogBody>
              {isAndroid ? (
                t('deviceModal.bluetoothOffDialog.androidBody')
              ) : needsToAuthorizeBluetooth ? (
                t('deviceModal.bluetoothOffDialog.iosBodyUnauthorized')
              ) : (
                <>
                  {t('deviceModal.bluetoothOffDialog.iosBodyBluetoothOff1')}
                  Garmin Connect<sup>™</sup>
                  {t('deviceModal.bluetoothOffDialog.iosBodyBluetoothOff2')}
                </>
              )}
            </AlertDialogBody>
            <AlertDialogFooter display="flex" justifyContent="space-between">
              <PrimaryButton
                onClick={
                  isAndroid
                    ? () => setAlertDialogOpen(false)
                    : needsToAuthorizeBluetooth
                    ? () => goToAppSettings()
                    : () => setAlertDialogOpen(false)
                }
              >
                {isAndroid || !needsToAuthorizeBluetooth
                  ? t('deviceModal.bluetoothOffDialog.okayButton')
                  : t('deviceModal.bluetoothOffDialog.iosSettingsButton')}
              </PrimaryButton>
              {needsToAuthorizeBluetooth && (
                <Button ref={cancelRef as any} onClick={() => setAlertDialogOpen(false)}>
                  {t('deviceModal.bluetoothOffDialog.cancelButton')}
                </Button>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default PairAlertDialog;
