import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Heading, FormErrorMessage, FormControl, Text } from '@chakra-ui/react';
import emailPattern from '../../constants/emailPattern';
import Field from './Field';

interface Props {
  onSubmit(data: Record<string, any>): Promise<void>;
  isSubmitSuccessful: boolean;
  generalFormErrors: string[];
}

const NoAccountForm = (props: Props): JSX.Element => {
  const loginPage = { ...useLocation(), search: '?log-in=true?straight-to-login' };
  const { t } = useTranslation('auth');
  const { handleSubmit, register, formState } = useForm();
  const { isSubmitting } = formState;
  const { errors } = formState;

  const onSubmit: SubmitHandler<Record<string, any>> = async (data) => {
    return props.onSubmit(data);
  };

  return (
    <Box>
      <Heading as="h2" fontSize={[24, 24, 36, 45]} fontWeight={400} color="purple3.600" mb="40px">
        {t('noAccountForm.header')}
      </Heading>
      <Box mb={10}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field
            {...register('email', {
              required: {
                value: true,
                message: t('noAccountForm.errors.emailRequired'),
              },
              pattern: {
                value: emailPattern,
                message: t('noAccountForm.errors.emailInvalid'),
              },
            })}
            name="email"
            label={t('resetPasswordForm.emailLabel')}
            type="email"
            placeholder="somebody@gmail.com"
            error={errors.email}
          />
          {props.generalFormErrors.length > 0 && (
            <FormControl isInvalid={props.generalFormErrors.length > 0} mb={8}>
              {props.generalFormErrors.map((error, i) => (
                <FormErrorMessage key={i}>{error}</FormErrorMessage>
              ))}
            </FormControl>
          )}
          {props.isSubmitSuccessful && (
            <Text fontSize={16} color="green">
              {t('noAccountForm.requestSent')}
            </Text>
          )}
          <Button
            bg="blue4.500"
            color="white"
            borderRadius={0}
            w="100%"
            type="submit"
            _hover={{ backgroundColor: 'blue4.300' }}
            isLoading={isSubmitting}
            mt={10}
          >
            {t('noAccountForm.submitButton')}
          </Button>
        </form>
      </Box>
      <Text mt={2} textAlign="center">
        <Trans t={t} i18nKey="noAccountForm.returnToLogin">
          resetPassword
          <Link to={loginPage}>
            <Text as="span" color="purple3.600" fontWeight="bold">
              link
            </Text>
          </Link>
        </Trans>
      </Text>
    </Box>
  );
};

export default NoAccountForm;
