import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux/store';
import { Box, Flex, Divider } from '@chakra-ui/react';
import { Center } from '@chakra-ui/layout';
import LinkedUsers from './LinkedUsers';
import InviteList from '../../components/InviteList/InviteList';
import InviteNewUser from '../invitations/InviteNewUser';
import { createLinkedUserForInvitee } from '../linked-users/utils';
import { createLinkedUser } from '../linked-users/actions';

const Index = (props: PropsFromRedux): JSX.Element => {
  const isAlreadyPir = props.pir !== null;

  const handleInvitationSent = async (email: string, pirAlias: string) => {
    await createLinkedUserForInvitee(props.createLinkedUser, props.user, email, pirAlias);
  };

  return (
    <Center>
      <Box mx="auto" width="100%" px={['18px', '36px']} pt="26px">
        {props.invites.length > 0 && <InviteList mb={isAlreadyPir ? 8 : 4} />}
        <Flex width="100%" justifyContent="right" alignItems="right" height={50}>
          <InviteNewUser
            inviterName={props.user.name ?? ''}
            inviterId={props.user.id}
            onInvitationSent={handleInvitationSent}
            role="cp"
          />
        </Flex>
        <Divider orientation="horizontal" mb={5} mt={5} border={1} borderColor={'black'} />
        <Box mb={2} fontSize={18} fontWeight="bold">
          Patient List
        </Box>
        {/* <Box>
          <Text>The below numbers reflect the past week’s data</Text>
        </Box> */}
        <Box width="100%" overflowX="auto">
          <LinkedUsers />
        </Box>
      </Box>
    </Center>
  );
};

const mapStateToProps = (state: RootState) => {
  const user = state.user.user;

  if (!user) {
    throw new Error('No user logged in when there needs to be');
  }

  return {
    user,
    pir: state.linkedUsers.pir,
    invites: state.linkedUsers.invites ?? [],
  };
};

const mapDispatchToProps = {
  createLinkedUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Index);
