import firebase from 'firebase/compat/app';
import IAppointment, { AppointmentStatus } from './interfaces/IAppointment';

export const formatAppointmentFromFirestore = (snapshot: firebase.firestore.DocumentData): IAppointment => {
  const pir = snapshot.get('pir');

  if (typeof pir === 'undefined') {
    throw new Error('No PIR defined on the appointment in the database');
  }

  const appointmentDate = snapshot.get('appointmentDate').toDate();
  const timeStart = snapshot.get('appointmentTime.timeStart').toDate();
  const timeEnd = snapshot.get('appointmentTime.timeEnd').toDate();
  const lowerDuration = Math.floor((timeEnd.getTime() - timeStart.getTime()) / (60 * 1000));
  const duration = lowerDuration % 15 === 0 ? lowerDuration : lowerDuration + 1;

  return {
    id: snapshot.id,
    pir,
    providerUser: snapshot.get('providerUser'),
    appointmentDate,
    appointmentTime: {
      timeStart,
      timeEnd,
    },
    duration,
    approvedByPir: snapshot.get('approvedByPir'),
    approvedByProvider: snapshot.get('approvedByProvider'),
    notesFromProvider: snapshot.get('notesFromProvider'),
    notesFromPir: snapshot.get('notesFromPir'),
    type: snapshot.get('type'),
    locationType: snapshot.get('locationType'),
    location: snapshot.get('location'),
    appointmentStatus: snapshot.get('appointmentStatus'),
  };
};

interface AppointmentToFirestore extends Omit<IAppointment, 'id'> {
  id?: string;
}

export const formatAppointmentToFirestore = (
  appointment: AppointmentToFirestore,
): Omit<IAppointment, 'id' | 'duration'> => {
  const out: Omit<IAppointment, 'id' | 'duration'> = {
    pir: appointment.pir,
    providerUser: appointment.providerUser,
    appointmentDate: appointment.appointmentDate,
    appointmentTime: {
      timeStart: appointment.appointmentTime.timeStart,
      timeEnd: appointment.appointmentTime.timeEnd,
    },
    appointmentStatus: appointment.appointmentStatus ?? AppointmentStatus.CONFIRMED,
  };

  if (appointment.approvedByPir) {
    out.approvedByPir = appointment.approvedByPir;
  }

  if (appointment.approvedByProvider) {
    out.approvedByProvider = appointment.approvedByProvider;
  }

  if (appointment.notesFromPir) {
    out.notesFromPir = appointment.notesFromPir;
  }

  if (appointment.notesFromProvider) {
    out.notesFromProvider = appointment.notesFromProvider;
  }

  if (appointment.locationType) {
    out.locationType = appointment.locationType;
  }

  if (appointment.location) {
    out.location = appointment.location;
  }

  if (appointment.type) {
    out.type = appointment.type;
  }

  return out;
};
