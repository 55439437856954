import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import FAIcon from '../../components/FAIcon';
import { useTranslation } from 'react-i18next';
import { PirUsersDataWithHealthEstimate } from './TableWebView';
import { Row } from 'react-table';

interface ItemType {
  row: {
    id: string;
  };
  data: {
    craving: number;
    emotion: number;
    stress: number;
    sleep: number;
    incidents: number;
  }[];
  value: any;
}

interface RequestDataType {
  value: string[];
}

export const healthEstimateScore = (
  craving: number,
  emotion: number,
  stress: number,
  sleep: number,
  incidents: number,
): number => {
  let outOf = 0;
  let total = 0;
  if (craving >= 0) {
    total += (cravingIndex(craving) + 1) * 25;
    outOf++;
  }
  if (emotion > -11) {
    total += (emotionIndex(emotion) + 1) * 50;
    outOf++;
  }
  if (stress >= 0) {
    total += (3 - stressIndex(stress) + 1) * 25;
    outOf++;
  }
  if (sleep >= 0) {
    total += (sleepIndex(sleep) + 1) * 33.33;
    outOf++;
  }

  if (outOf < 2) return -1;
  const valueBeforeIncidents = Math.round(total / outOf);
  const incidentsPoints = incidents >= 10 ? 50 : Math.round(incidents) * 5;
  const healthEstimateScore = valueBeforeIncidents - incidentsPoints < 0 ? 0 : valueBeforeIncidents - incidentsPoints;

  return Math.round(healthEstimateScore);
};

export const healthEstimate = (props: ItemType): React.ReactElement => {
  const rowId = parseInt(props.row.id);
  const score = healthEstimateScore(
    props.data[rowId].craving,
    props.data[rowId].emotion,
    props.data[rowId].stress,
    props.data[rowId].sleep,
    props.data[rowId].incidents,
  );
  return (
    <Flex align="baseline" gap="4px">
      <Text
        as="span"
        fontWeight="bold"
        color={score === -1 ? '#C4C4C4' : score >= 70 ? '#27AE60' : score >= 50 && score <= 69 ? '#F2994A' : '#EB5757'}
      >
        {score === -1 ? 'N/A' : score}
      </Text>
      <Text color="#C4C4C4" fontWeight="bold">
        {score >= 0 && '/ 100'}
      </Text>
    </Flex>
  );
};

export const cravingCardTranslationKey = (value: number): string => {
  const index = value < 0 ? 'na' : cravingIndex(value);
  return `vitalsSummary.cravingLevels.${index}`;
};

export const cravingIndex = (value: number): number => {
  if (value < 0.1) {
    return 3;
  } else if (value < 0.2) {
    return 2;
  } else if (value < 0.3) {
    return 1;
  } else {
    return 0;
  }
};

const cravingText = (props: ItemType): JSX.Element => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation('vitals');
  if (props.value === '') {
    return <Text />;
  }
  const key = cravingCardTranslationKey(props.value);
  return (
    <Text textAlign="left" color={t(`${key}.color`)} fontWeight="bold">
      {t(`${key}.condition`)}
    </Text>
  );
};

const hourText = (value: any, color: any): JSX.Element => {
  return (
    <>
      <Text color={color} textAlign="left" fontWeight="bold" mr={1}>
        {value < 0 ? 'N/A' : value}
      </Text>
      <Text textAlign="left" color="#C4C4C4" fontWeight="bold">
        hrs/day
      </Text>
    </>
  );
};

const percentText = (value: any, color: any): JSX.Element => {
  return (
    <>
      <Text color={color} textAlign="left" fontWeight="bold" mr={1}>
        {Math.max(0, value)}
      </Text>
      <Text textAlign="left" color="#C4C4C4" fontWeight="bold">
        %
      </Text>
    </>
  );
};

export const sleepCardTranslationKey = (value: number): string => {
  const index = value < 0 ? 'na' : sleepIndex(value);
  return `vitalsSummary.sleepLevels.${index}`;
};

export const sleepIndex = (value: number): number => {
  if (value < 0) {
    return -1;
  } else if (value < 6) {
    return 0;
  } else if (value < 7) {
    return 1;
  } else {
    return 2;
  }
};

const sleepRound = (props: ItemType): JSX.Element => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation('vitals');
  if (props.value === '') {
    return <Text />;
  }
  const key = sleepCardTranslationKey(props.value);
  const value = props.value < 0 ? 0 : Math.round(props.value * 10) / 10;
  return hourText(value, t(`${key}.color`));
};

export const stressCardTranslationKey = (value: number): string => {
  const index = value < 0 ? 'na' : stressIndex(value);
  return `vitalsSummary.stressLevels.${index}`;
};

export const stressIndex = (value: number): number => {
  if (value < 0) {
    return -1;
  } else if (value < 26) {
    return 0;
  } else if (value < 51) {
    return 1;
  } else if (value < 76) {
    return 2;
  } else {
    return 3;
  }
};

const stressText = (props: ItemType): JSX.Element => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation('vitals');
  if (props.value === '') {
    return <Text />;
  }

  const key = stressCardTranslationKey(props.value);
  return (
    <Text textAlign="left" fontWeight="bold" color={t(`${key}.color`)}>
      {t(`${key}.condition`)}
    </Text>
  );
};

export const emotionCardTranslationKey = (value: number): string => {
  const index = value < -11 ? 'na' : emotionIndex(value);
  return `vitalsSummary.emotionLevels.${index}`;
};

export const emotionIndex = (value: number): number => {
  return value < -11 ? -1 : value < 0 ? 0 : 1;
};

export const incidentsIndex = (value: number): number => {
  if (value > 3) {
    return 2;
  } else if (value >= 1 && value <= 3) {
    return 1;
  } else {
    return 0;
  }
};

const emotionArrow = (props: ItemType): JSX.Element => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation('vitals');
  if (props.value === '') {
    return <Text />;
  }
  const key = emotionCardTranslationKey(props.value);
  return (
    <Flex flexDir="column">
      {props.value > -11 && <FAIcon icon={t(`${key}.icon`)} color={t(`${key}.color`)} />}
      {t(`${key}.condition`) && (
        <Text fontWeight="bold" color={t(`${key}.color`)}>
          {t(`${key}.condition`)}
        </Text>
      )}
    </Flex>
  );
};

const mobileName = (props: ItemType): JSX.Element => {
  return (
    <Text
      textAlign="center"
      fontWeight="bold"
      color="dimgray"
      overflow="hidden"
      sx={{
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      }}
      textOverflow="ellipsis"
      width="215px"
    >
      {props.value}
    </Text>
  );
};

const alignCenter = (props: ItemType): JSX.Element => {
  return (
    <Box
      textAlign="center"
      fontWeight="bold"
      color="dimgray"
      sx={{
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      }}
      overflow="hidden"
      textOverflow="ellipsis"
      width="120px"
    >
      {props.value}
    </Box>
  );
};

const incidents = (props: ItemType): JSX.Element => (
  <Text fontWeight="bold" color={props.value > 0 ? 'red' : '#C4C4C4'}>
    {Math.max(0, props.value)}
  </Text>
);

const requestNumber = (props: RequestDataType): JSX.Element => (
  <Text fontWeight="bold" color={props.value?.length ? 'red' : '#C4C4C4'}>
    {props.value?.length || 0}
  </Text>
);

export const watchUseIndex = (value: number): number => {
  if (value < 0) {
    return -1;
  } else if (value < 18) {
    return 0;
  } else if (value < 20) {
    return 1;
  } else {
    return 2;
  }
};

export const participationCardTranslationKey = (value: number): string => {
  const index = value < 0 ? 'na' : participationIndex(value);
  return `vitalsSummary.participationLevels.${index}`;
};

export const participationIndex = (value: number): number => {
  if (value < 0) {
    return -1;
  } else if (value < 0.6) {
    return 0;
  } else if (value < 0.9) {
    return 1;
  } else {
    return 2;
  }
};

const participation = (props: ItemType): JSX.Element => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation('vitals');
  if (props.value === '') {
    return <Text />;
  }
  const key = participationCardTranslationKey(props.value);
  const value = props.value < 0 ? 0 : Math.round(props.value * 100);
  return percentText(value, t(`${key}.color`));
};

export const COLUMNSAPPVIEW = [
  {
    Header: '',
    accessor: 'image',
    show: true,
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'person',
    Cell: mobileName,
    id: 'pir_column_name',
    show: true,
  },
  {
    Header: '',
    accessor: 'arrow',
    show: true,
    disableSortBy: true,
  },
];

export const COLUMNS = [
  {
    Header: '',
    accessor: 'image',
    show: false,
    disableSortBy: true,
  },
  {
    Header: 'NAME',
    accessor: 'person',
    Cell: alignCenter,
    id: 'pir_column',
    show: true,
  },
  {
    Header: 'HEALTH ESTIMATE',
    accessor: 'healthEstimate',
    sortType: 'basic',
    Cell: healthEstimate,
    id: 'health_estimate_column',
    show: true,
  },
  {
    Header: 'CRAVINGS',
    accessor: 'craving',
    sortType: 'basic',
    id: 'craving_column',
    Cell: cravingText,
    show: true,
  },
  {
    Header: 'EMOTION',
    accessor: 'emotion',
    sortType: 'basic',
    Cell: emotionArrow,
    show: false,
  },
  {
    Header: 'STRESS',
    accessor: 'stress',
    Cell: stressText,
    sortType: 'basic',
    show: false,
  },
  {
    Header: 'PARTICIPATION',
    Cell: participation,
    accessor: 'participation',
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'SLEEP',
    accessor: 'sleep',
    Cell: sleepRound,
    show: false,
  },
  {
    Header: 'INCIDENTS',
    Cell: incidents,
    accessor: 'incidents',
    show: false,
  },
  {
    Header: 'SUPPORT REQUESTS',
    Cell: requestNumber,
    accessor: 'supportRequests',
    show: false,
    sortType: (rowA: Row<PirUsersDataWithHealthEstimate>, rowB: Row<PirUsersDataWithHealthEstimate>) => {
      const rowARequests = rowA.original.supportRequests?.length ?? 0;
      const rowBRequests = rowB.original.supportRequests?.length ?? 0;
      return rowARequests - rowBRequests;
    },
  },
  {
    Header: '',
    accessor: 'arrow',
    show: false,
    disableSortBy: true,
  },
];

export const COLUMNSTABLETVIEW = [
  {
    Header: '',
    accessor: 'arrow_left',
    show: false,
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'image',
    show: false,
    disableSortBy: true,
  },
  {
    Header: 'NAME',
    accessor: 'person',
    Cell: alignCenter,
    id: 'pir_column',
    show: true,
  },
  {
    Header: 'HEALTH ESTIMATE',
    accessor: 'healthEstimate',
    sortType: 'basic',
    Cell: healthEstimate,
    id: 'health_estimate_column',
    show: true,
  },
  {
    Header: 'CRAVINGS',
    accessor: 'craving',
    sortType: 'basic',
    id: 'craving_column',
    Cell: cravingText,
    show: true,
  },
  {
    Header: 'EMOTION',
    accessor: 'emotion',
    sortType: 'basic',
    Cell: emotionArrow,
    show: false,
  },
  {
    Header: 'STRESS',
    accessor: 'stress',
    Cell: stressText,
    sortType: 'basic',
    show: false,
  },
  {
    Header: 'PARTICIPATION',
    Cell: participation,
    accessor: 'participation',
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'SLEEP',
    accessor: 'sleep',
    Cell: sleepRound,
    show: false,
  },
  {
    Header: 'INCIDENTS',
    Cell: incidents,
    accessor: 'incidents',
    show: false,
  },
  {
    Header: 'SUPPORT REQUESTS',
    Cell: requestNumber,
    accessor: 'supportRequests',
    show: false,
    sortType: (rowA: Row<PirUsersDataWithHealthEstimate>, rowB: Row<PirUsersDataWithHealthEstimate>) => {
      const rowARequests = rowA.original.supportRequests?.length ?? 0;
      const rowBRequests = rowB.original.supportRequests?.length ?? 0;
      return rowARequests - rowBRequests;
    },
  },
  {
    Header: '',
    accessor: 'arrow_right',
    show: false,
    disableSortBy: true,
  },
];

export const Data = {
  person: '',
  craving: 0,
  participation: 0,
  watchUse: 0,
  sleep: 0,
  emotion: 0,
  stress: 0,
  incidents: 0,
  supportRequests: 0,
};
