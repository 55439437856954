import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  List,
  ListItem,
  HStack,
  Text,
} from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import { useQueryMeditations } from './queryHooks';
import IMeditation from './interfaces/IMeditation';
import { createInteraction } from '../interactions/actions';
import { moduleName, interactionType } from '../interactions/constants';
import MeditationModal from '../../components/ManageMeditationModal/MeditationModal';
import DisplayWebLink from '../../components/DisplayWebLink';
import { getToDos, updateToDo } from '../../modules/todo/actions';
import ModuleContainer from '../../components/ModuleContainerV2';
import ModuleCard from '../../components/ModuleCard';
import WebLinkNewWindow from 'src/components/WebLinkNewWindow';
import InlineVideoPlayer from './InlineVideoPlayer';
import { getAttachmentType, getUrlType } from './utils';
import { useWindowSize } from 'src/hooks/utils';
import InlineImage from './InlineImage';
import TextResourceBox from 'src/components/TextResourceBox';
import { useModal } from 'src/ModalContext';
import { useDevice } from 'src/DeviceContext';

const MODAL_NAME = 'meditations';

interface Props {
  isPir: boolean;
}

const MeditationModule = ({ pir, createInteraction, isPir }: Props & PropsFromRedux): React.ReactElement => {
  const { t } = useTranslation('meditations');
  const { isPhone } = useDevice();
  const [selectedMeditation, setSelectedMeditation] = useState<IMeditation | undefined>(undefined);
  const size = useWindowSize();
  const [maxDimensions, setMaxDimensions] = useState({ maxWidth: 0, maxHeight: 0 });
  const { modal, openModal, closeModal } = useModal();
  const history = useHistory();
  const location = useLocation();

  const { data: meditations = [] } = useQueryMeditations(pir?.id);

  useEffect(() => {
    if (selectedMeditation !== undefined) {
      const widthPercentages = isPhone
        ? 100
        : size.width <= 768
        ? 100
        : size.width <= 992
        ? 98
        : size.width <= 1200
        ? 90
        : size.width <= 1500
        ? 85
        : 75;
      const maxWidth = isPhone ? size.width * 0.8 : size.width * (widthPercentages / 100) * 0.75;
      const maxHeight = size.height * 0.6;

      setMaxDimensions({ maxWidth, maxHeight });
    }
  }, [size, selectedMeditation]);

  const toDisplay = useCallback((meditation: IMeditation) => {
    setSelectedMeditation(meditation);
  }, []);

  const getDescription = useCallback(
    (meditation: IMeditation): string => {
      return `${meditation.length} ${
        !isPir && meditation.length
          ? t('meditationModuleCP.moduleDisplayMins')
          : meditation.length
          ? t('meditationModulePIR.moduleDisplayMins')
          : ''
      }`;
    },
    [t, isPir],
  );

  const addClosedInteraction = useCallback(() => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MEDITATIONS,
      interactionType: interactionType.MEDITATIONS.CLOSED,
    });
  }, [createInteraction, pir]);

  const addViewedInteraction = useCallback(() => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MEDITATIONS,
      interactionType: interactionType.MEDITATIONS.VIEWED,
    });
  }, [createInteraction, pir]);

  useEffect(() => {
    if (modal === MODAL_NAME) {
      createInteraction({
        pir,
        dateTime: new Date(),
        moduleName: moduleName.MEDITATIONS,
        interactionType: interactionType.MEDITATIONS.OPEN_MODAL,
      });
    }
  }, [modal, pir, createInteraction]);

  useEffect(() => {
    const url = location.pathname;
    const meditationRoute = url.substring(url.lastIndexOf('/') + 1);

    if (meditations.length === 0) return;

    if (meditationRoute !== MODAL_NAME && meditationRoute !== 'home') {
      const selectedMed = meditations.find((med) => med.id === meditationRoute);
      setSelectedMeditation(selectedMed || undefined);
    } else {
      setSelectedMeditation(undefined);
    }
  }, [location, meditations]);

  const memoizedMaxDimensions = useMemo(() => maxDimensions, [maxDimensions]);

  return (
    <Box>
      <ModuleContainer
        title={!isPir ? t('meditationModuleCP.moduleCPHeader') : t('meditationModulePIR.modulePIRHeader')}
        icon="spa"
        isEmptyModule={meditations.length <= 0}
        modalName={MODAL_NAME}
        moduleInstruct={
          !isPir ? [t('meditationModuleCP.moduleCPinstruct')] : [t('meditationModulePIR.modulePIRInstruct')]
        }
        moduleButton={!isPir ? t('meditationModuleCP.moduleCPButton') : t('meditationModulePIR.modulePIRButton')}
        cogFunction={() => openModal(MODAL_NAME)}
      >
        <HStack
          width="100%"
          spacing={['8px', '30px']}
          overflowX="auto"
          alignItems="stretch"
          justifyContent="flex-start"
        >
          {meditations.map((meditation) => (
            <ModuleCard
              moduleInterface={meditation}
              modalName={MODAL_NAME}
              description={getDescription(meditation)}
              toDisplayFunction={toDisplay}
              name={meditation.title}
              icon="spa"
              bgColor="#C8D5DD"
              textColor="#40578F"
              deleteOpen={false}
              width={['108px', '192px']}
              key={meditation.id}
            />
          ))}
        </HStack>
      </ModuleContainer>

      <Modal
        isOpen={selectedMeditation !== undefined}
        onClose={() => {
          history.push('/home');
          setSelectedMeditation(undefined);
          closeModal();
          if (isPir) addViewedInteraction();
        }}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : ['100%', '98%', '90%', '85%', '75%']}
          p={[2, 2, 4, 8]}
          zIndex={1900}
        >
          <ModalHeader>{selectedMeditation?.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxWidth={'760px'}>
            <List as="ol" mb={5}>
              <ListItem color="grey3.400" fontSize={16}>
                {!isPir ? t('meditationModuleCP.moduleLengthHeader') : t('meditationModulePIR.moduleLengthHeader')}
              </ListItem>
              <ListItem mb={3}>
                {selectedMeditation?.length}
                {!isPir ? t('meditationModuleCP.moduleDisplayMins') : t('meditationModulePIR.moduleDisplayMins')}
              </ListItem>

              <ListItem color="grey3.400" fontSize={16}>
                {!isPir ? t('meditationModuleCP.moduleNoteHeader') : t('meditationModulePIR.moduleNoteHeader')}
              </ListItem>

              <ListItem mb={3}>
                <TextResourceBox textContent={selectedMeditation?.note ? selectedMeditation.note : ''} />
              </ListItem>

              {selectedMeditation?.meditationType !== 'note' && (
                <ListItem color="grey3.400" fontSize={16}>
                  {!isPir ? t('meditationModuleCP.moduleMediaHeader') : t('meditationModulePIR.moduleMediaHeader')}
                </ListItem>
              )}

              {selectedMeditation?.link &&
                (getUrlType(selectedMeditation.link) === 'link' ? (
                  <ListItem>
                    <DisplayWebLink
                      href={selectedMeditation.link}
                      linkText={
                        !isPir ? t('meditationModuleCP.moduleCPWebLink') : t('meditationModulePIR.moduleMeditationLink')
                      }
                      propOnClick={
                        isPir &&
                        (() => {
                          // triggerTodo();
                        })
                      }
                    />
                  </ListItem>
                ) : (
                  <InlineVideoPlayer
                    videoUrl={selectedMeditation.link}
                    isPir={isPir}
                    maxHeight={memoizedMaxDimensions.maxHeight}
                    maxWidth={memoizedMaxDimensions.maxWidth}
                    type="link"
                  />
                ))}

              {selectedMeditation?.attachmentUrl && selectedMeditation?.link && <br />}

              {selectedMeditation?.attachmentUrl &&
                selectedMeditation?.attachmentName &&
                (getAttachmentType(selectedMeditation.attachmentName) === 'video' ? (
                  <InlineVideoPlayer
                    videoUrl={selectedMeditation.attachmentUrl}
                    isPir={isPir}
                    maxHeight={memoizedMaxDimensions.maxHeight}
                    maxWidth={memoizedMaxDimensions.maxWidth}
                    type="attachment"
                  />
                ) : getAttachmentType(selectedMeditation.attachmentName) === 'image' ? (
                  <ListItem>
                    <InlineImage
                      imageUrl={selectedMeditation.attachmentUrl}
                      imageName={selectedMeditation.attachmentName}
                      maxWidth={memoizedMaxDimensions.maxWidth}
                      maxHeight={memoizedMaxDimensions.maxHeight}
                    />
                  </ListItem>
                ) : (
                  <WebLinkNewWindow href={selectedMeditation.attachmentUrl}>
                    <Text
                      fontSize={[16, 16, 18]}
                      marginRight={4}
                      fontWeight="500"
                      color="#2D98A1"
                      isTruncated={true}
                      _hover={{ textDecoration: 'underline' }}
                      onClick={() => {
                        // isPir && triggerTodo();
                      }}
                    >
                      {selectedMeditation.attachmentUrl.replace(/(^\w+:|^)\/\//, '')}
                    </Text>
                  </WebLinkNewWindow>
                ))}
            </List>
          </ModalBody>
        </ModalContent>
      </Modal>
      <MeditationModal
        isOpen={modal === MODAL_NAME && !selectedMeditation}
        onOpen={() => openModal(MODAL_NAME)}
        onClose={() => {
          closeModal();
          if (isPir) addClosedInteraction();
        }}
      />
    </Box>
  );
};

const MapStateToProps = (state: RootState) => {
  const { todos } = state.todo;
  const { selectedLinkedUser } = state.linkedUsers;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }

  return {
    todos,
    pir: selectedLinkedUser.pir,
  };
};

const mapDispatchToProps = {
  createInteraction,
  getToDos,
  updateToDo,
};

const connector = connect(MapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MeditationModule);
