import React from 'react';
import { IconName, IconLookup } from '@fortawesome/free-solid-svg-icons';
import { Box, BoxProps } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface FAIconProps extends BoxProps {
  icon: IconName | IconLookup | string;
}

const FAIcon = (props: FAIconProps): JSX.Element => {
  return (
    <Box as="i" {...props}>
      <FontAwesomeIcon icon={props.icon as IconName} />
    </Box>
  );
};

export default FAIcon;
