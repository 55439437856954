import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

let firebaseApp: firebase.app.App;
let db: firebase.firestore.Firestore;

import { getAuth, connectAuthEmulator, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { connectFirestoreEmulator } from 'firebase/firestore';

const init = async (): Promise<void> => {
  if (!firebaseApp) {
    firebaseApp = firebase.initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseUrl: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENVER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
    });

    // Persist login across browser sessions
    setPersistence(getAuth(firebaseApp), browserLocalPersistence);

    db = firebase.firestore();
    // Enable Firebase debug logging for Firestore
    // firebase.firestore.setLogLevel('debug');

    // try {
    //   await db.enablePersistence({ synchronizeTabs: true });
    //   console.log('Offline persistence enabled');
    // } catch (error: any) {
    //   Sentry.captureException(new Error(`Firebase persistence error: ${error} ${error.code}`));
    //   if (error.code === 'failed-precondition') {
    //     console.warn('Offline persistence failed: Multiple tabs open');
    //   } else if (error.code === 'unimplemented') {
    //     console.warn('Offline persistence not supported in this environment');
    //   } else {
    //     console.error('Error enabling offline persistence:', error);
    //   }
    // }

    // this is for local testing
    if (process.env.REACT_APP_FIRESTORE_EMULATOR === 'YES') {
      console.log('Firestore emulator enabled');
      console.log('Firestore emulator host:', process.env.REACT_APP_FIRESTORE_EMULATOR_HOST);
      const host = process.env.REACT_APP_FIRESTORE_EMULATOR_HOST || 'localhost';
      const auth = getAuth();

      connectFirestoreEmulator(db, host, 8080);
      connectAuthEmulator(auth, `https://${host}`);
    }
  }
};

const app = (): firebase.app.App => {
  init();

  return firebaseApp;
};

const database = (): firebase.firestore.Firestore => {
  init();

  return db;
};

const collection = (name: string): firebase.firestore.CollectionReference => {
  init();

  return db.collection(name);
};

const getDocRef = (collectionName: string, docId: string): firebase.firestore.DocumentReference => {
  init();

  return collection(collectionName).doc(docId);
};

const timestamp = (seconds: number, nanoseconds: number): firebase.firestore.Timestamp => {
  return new firebase.firestore.Timestamp(seconds, nanoseconds);
};

const deleteField = (): firebase.firestore.FieldValue => {
  return firebase.firestore.FieldValue.delete();
};

const now = (): firebase.firestore.Timestamp => {
  return firebase.firestore.Timestamp.now();
};

export default {
  app,
  database,
  collection,
};

export type FirestoreFieldValue = firebase.firestore.FieldValue;

export const FirestoreUtils = {
  getDocRef,
  timestamp,
  now,
  deleteField,
};
