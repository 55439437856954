import React from 'react';
import { Box } from '@chakra-ui/react';
import {
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryTooltip,
  VictoryLine,
  VictoryScatter,
  VictoryContainer,
  VictoryBar,
} from 'victory';
import { getGraphData, generateTickLabelPositions, formatDayTicks } from '../dataProcessing';
import dayjs from 'dayjs';

import { ISurveyDataPoint } from '../../../db/summarySurveys';
import { GraphSizeObj, ISurveysData } from '../interfaces/IVitalsGraph';
import { IVitalList } from '../VitalsModuleCP';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  data: ISurveyDataPoint[];
  day: number;
  graphSize: GraphSizeObj;
  graphPadding: number;
  axisLabelPadding: number;
  emotionItemsVisibility: IVitalList[];
}

interface ColorObj {
  [index: string]: string;
  happy: string;
  anxious: string;
  relaxed: string;
  stressed: string;
  sad: string;
  tired: string;
  pain: string;
}

const VitalSurveyGraph = ({
  data,
  day,
  graphSize,
  graphPadding,
  axisLabelPadding,
  emotionItemsVisibility,
}: SuppliedProps): React.ReactElement => {
  const surveyDataPoints: ISurveysData = data.reduce(
    (surveysData: ISurveysData, surveyDataPoint: ISurveyDataPoint) => {
      let key: keyof ISurveysData;
      for (key in surveysData) {
        surveysData[key].push({
          dateTime: surveyDataPoint.dateTime,
          value: surveyDataPoint[key],
        });
      }
      return surveysData;
    },
    {
      anxious: [],
      happy: [],
      relaxed: [],
      stressed: [],
      sad: [],
      tired: [],
      pain: [],
    },
  );

  //filter surveyDataPoints just show the checkedItem is true//
  const emotionItemsVisibilityArray = emotionItemsVisibility.filter((item: any) => item.checked);

  //function that filter surveyDataPoints //
  const lowerCaseCheckedArray = emotionItemsVisibilityArray.map((item) => item.name.toLowerCase());

  const filteredSurveyDataPoints = Object.keys(surveyDataPoints)
    .filter((key) => lowerCaseCheckedArray.includes(key))
    .reduce((obj, key) => {
      obj[key] = surveyDataPoints[key];
      return obj;
    }, {} as ISurveysData);

  const tickValues = generateTickLabelPositions(data, day);
  const { isPhone } = useDevice();

  const nowDays = dayjs().startOf('day');
  const nowHours = dayjs().startOf('hour');
  const xStartDays = nowDays.subtract(day, 'day');
  const xStartHours = nowHours.subtract(day, 'day');
  const leftPadding = isPhone ? 18 : 50;

  const colorsObj: ColorObj = {
    happy: '#5C4279',
    anxious: '#00BAA3',
    relaxed: '#8F8F8F',
    stressed: '#008FBC',
    sad: '#00B6FF',
    tired: '#C8D5DD',
    pain: '#2680D0',
  };

  return (
    <Box bg="white">
      <svg style={{ height: 0 }} viewBox={`0 0  ${graphSize.width}  ${graphSize.height}`}></svg>
      <VictoryChart
        theme={VictoryTheme.grayscale}
        width={graphSize.width}
        height={graphSize.height}
        padding={{ top: 5, left: leftPadding, bottom: 60, right: graphPadding }}
        domain={{
          x: day === 1 ? [xStartHours.valueOf(), nowHours.valueOf()] : [xStartDays.valueOf(), nowDays.valueOf()],
        }}
        containerComponent={
          <VictoryContainer
            style={
              !isPhone
                ? { backgroundColor: 'white' }
                : {
                    pointerEvents: 'auto',
                    userSelect: 'auto',
                    touchAction: 'auto',
                    backgroundColor: 'white',
                  }
            }
          />
        }
      >
        <VictoryAxis
          label="LEVEL"
          dependentAxis={true}
          orientation="right"
          domain={[0, 11]}
          style={{
            axis: { stroke: '#756f6a' },
            axisLabel: {
              fontSize: 15,
              angle: 90,
              padding: axisLabelPadding,
            },
            tickLabels: {
              fontSize: 15,
              fontFamily: 'Public Sans',
              padding: 5,
            },
            grid: {
              stroke: '#CCCCCC',
              strokeWidth: 1,
              strokeDasharray: '4, 4',
            },
          }}
          tickFormat={(t) => `${Math.round(t * 10) / 10}`}
        />

        <VictoryAxis
          label="TIME"
          style={{
            axis: { stroke: '#756f6a' },
            axisLabel: { fontSize: 15 },
            tickLabels: {
              fontSize: 15,
              fontFamily: 'Public Sans',
              padding: 5,
            },
            ticks: {
              stroke: '#756f6a',
              size: 5,
              strokeWidth: 1,
            },
          }}
          tickValues={tickValues}
          tickFormat={(t) => formatDayTicks(t, day)}
        />

        {Object.entries(filteredSurveyDataPoints).map(([emotion, points], i: number) => {
          const emotionGraphData = getGraphData(points, day, 'emotion', 'average');

          if (emotionGraphData.length > 1) {
            return (
              <VictoryLine
                key={`line-${emotion}-${i}`}
                interpolation="catmullRom"
                style={{
                  data: {
                    stroke: colorsObj[emotion],
                    strokeWidth: isPhone ? 1 : 3,
                  },
                }}
                data={emotionGraphData}
              />
            );
          } else if (emotionGraphData.length === 1) {
            return (
              <VictoryBar
                key={`bar-${emotion}-${i}`}
                data={[
                  {
                    x: emotionGraphData[0].x,
                    y: (emotionGraphData[0].y as number) + (isPhone ? 0.03 : 0.05),
                    y0: (emotionGraphData[0].y as number) - (isPhone ? 0.03 : 0.05),
                  },
                ]}
                barWidth={isPhone ? 20 : 40}
                style={{
                  data: {
                    fill: colorsObj[emotion],
                  },
                }}
              />
            );
          }
        })}
        {Object.entries(filteredSurveyDataPoints).map(([emotion, points], i: number) => (
          <VictoryScatter
            key={`scatter-${emotion}-${i}`} //
            data={getGraphData(points, day, 'emotion', 'average')}
            size={isPhone ? 3.5 : 5}
            style={{
              data: {
                fill: 'white',
                stroke: colorsObj[emotion],
                strokeWidth: isPhone ? 1.5 : 3,
              },
            }}
            labelComponent={
              !isPhone ? (
                <VictoryTooltip
                  cornerRadius={0}
                  flyoutStyle={{ fill: '#583f73', strokeWidth: 0 }}
                  pointerLength={4}
                  pointerWidth={4}
                  style={{ fontSize: 25, fill: 'white' }}
                />
              ) : (
                <Box />
              )
            }
            labels={({ datum }) =>
              day === 1
                ? `${datum.x.getMonth() + 1}/${datum.x.getDate()} ${datum.x.getHours()}:00: ${
                    Math.round(datum.y * 100) / 100
                  }`
                : `${datum.x.getMonth() + 1}/${datum.x.getDate()}: ${Math.round(datum.y * 100) / 100}`
            }
          />
        ))}
      </VictoryChart>
    </Box>
  );
};

export default VitalSurveyGraph;
