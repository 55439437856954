import firebase from 'firebase/compat/app';
import { MotivatorActions, MotivatorActionTypes } from './actions';
import IMotivator from './interfaces/IMotivator';

interface MotivatorState {
  loading: boolean;
  creating: boolean;
  motivator: null | IMotivator[];
  selectedMotivator: null | IMotivator;
  unsubFn: null | firebase.Unsubscribe;
}

const defaultState: MotivatorState = {
  loading: false,
  creating: false,
  motivator: null,
  selectedMotivator: null,
  unsubFn: null,
};

const motivatorReducer = (state: MotivatorState = defaultState, action: MotivatorActions): MotivatorState => {
  switch (action.type) {
    case MotivatorActionTypes.SET_MOTIVATOR:
      return {
        ...state,
        motivator: action.motivator,
      };
    case MotivatorActionTypes.GETTING_MOTIVATOR:
      return {
        ...state,
        loading: true,
      };

    case MotivatorActionTypes.GOT_MOTIVATOR:
      return {
        ...state,
        loading: false,
      };

    case MotivatorActionTypes.SET_MOTIVATOR_UNSUB:
      return {
        ...state,
        unsubFn: action.unsubFn,
      };

    case MotivatorActionTypes.CREATING_MOTIVATOR:
      return {
        ...state,
        creating: true,
      };

    case MotivatorActionTypes.CREATED_MOTIVATOR:
      return {
        ...state,
        creating: false,
      };

    case MotivatorActionTypes.UPDATING_MOTIVATOR:
      return {
        ...state,
        loading: true,
      };

    case MotivatorActionTypes.UPDATED_MOTIVATOR:
      return {
        ...state,
        loading: false,
        selectedMotivator: null,
      };

    case MotivatorActionTypes.SELECT_MOTIVATOR_TO_UPDATE:
      return {
        ...state,
        loading: false,
        selectedMotivator: action.motivator,
      };

    default:
      return state;
  }
};

export default motivatorReducer;
