import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Modal,
  Box,
  Text,
  Flex,
  Link as ChakraLink,
  useDisclosure,
  Divider,
  useToast,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import NotificationPreferencesForm from './NotificationPreferencesForm';
import { deleteLoggedInUser, updatePartialUser } from '../../modules/user/actions';
import { formatPhone } from '../../modules/user/utils';
import UserInfoForm from './UserInfoForm';
import { ImageUpload } from '../ImageUpload';
import { createInteraction } from '../../modules/interactions/actions';
import { moduleName, interactionType } from '../../modules/interactions/constants';
import firebase from 'firebase/compat/app';
import PrimaryButton from '../PrimaryButton';
import { useHistory } from 'react-router-dom';
import TimezoneInfo from './TimeZoneInfo';
import IDeviceGarmin from '../../modules/deviceGarmin/interfaces/IDeviceGarmin';
import Firestore from '../../modules/firestore/Firestore';
import { onboarded } from '../../modules/onboarding/actions';
import CPGeneralContactInfoBox from './CPGeneralContactInfoBox';
import { useDevice } from 'src/DeviceContext';
import { PossibleLinkedUser } from 'src/modules/linked-users/interfaces/ILinkedUser';
import { deregisterGarminAPI } from 'src/modules/deviceGarmin/actions';
import { getAllLinkedUsersIncludingDeleted } from 'src/modules/linked-users/actions';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  pir?: firebase.firestore.DocumentReference | null;
  isPir: boolean;
  noNotificationModeEnabled?: boolean;
}

type notificationPreferencesToSubmit = {
  doesWantSms: boolean;
  doesWantPush?: boolean;
  doesWantEmail: boolean;
  phone?: string;
};

type userUpdatesToSubmit = {
  email?: string;
  name?: string;
  phone?: string;
  cpGeneralContactInfo?: string;
};

type Props = SuppliedProps & PropsFromRedux;

const ManageUserInfoModal = (props: Props): JSX.Element => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const toast = useToast();
  const {
    isOpen,
    onClose,
    hasOnboarded,
    pir = null,
    updatePartialUser,
    isPir,
    noNotificationModeEnabled,
    user,
  } = props;
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const garminDevice = useSelector((state: RootState) => state.garmin);
  const garminDeviceAPI = useSelector((state: RootState): IDeviceGarmin | null => state.deviceGarmin.deviceGarmin);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [notificationsIsDirty, setNotificationsIsDirty] = useState(false);
  const abbreviatedSettingsInterface = !hasOnboarded || noNotificationModeEnabled;
  const [submitError, setSubmitError] = useState<string | undefined>(undefined);
  const { isMobileDevice, isCordova, isPhone } = useDevice();

  useEffect(() => {
    if (abbreviatedSettingsInterface) {
      if (!user.doesWantEmail && !user.doesWantSms && !user.doesWantPush) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }
    }
    return () => {
      setNextDisabled(false);
    };
  }, [user.doesWantEmail, user.doesWantPush, user.doesWantSms]);

  const handleModalClose = () => {
    if (!notificationsIsDirty || window.confirm(t('manageUserInfoModal.notificationPreferences.closeConfirmation'))) {
      onClose();
    }
  };

  const closeUserInfoForm = () => {
    setSubmitError(undefined);
    userInfoDisclosure.onClose();
  };
  const handleNext = () => {
    if (!notificationsIsDirty || window.confirm(t('manageUserInfoModal.notificationPreferences.closeConfirmation'))) {
      if (isPir) {
        history.push(
          isMobileDevice &&
            isCordova &&
            (garminDevice.name !== undefined || !(garminDeviceAPI && garminDeviceAPI.status === 'AUTHORIZED'))
            ? '/device'
            : '/contact',
        );
      } else {
        props.onboarded();
        history.push('/');
      }
    }
  };

  // User form
  const userInfoDisclosure = useDisclosure();
  // const notificationPreferencesDisclosure = useDisclosure();
  const userFormSubmit = async (data: Record<string, string>): Promise<void> => {
    if (!data.email || data.email === user.email || window.confirm(t('manageUserInfoModal.emailChangeWarning'))) {
      try {
        const updates: userUpdatesToSubmit = {};
        if (data.email && data.email !== user.email) {
          // check to make sure they're not trying to change their email to an email already in use by another user
          const usersWithSameEmail = await Firestore.collection('users').where('email', '==', data.email).get();

          if (!usersWithSameEmail.empty) {
            setSubmitError(t('manageUserInfoModal.errors.emailAlreadyInUse'));
            return;
          }
          setSubmitError(undefined);
          updates['email'] = data.email;
        }
        if (data.phone !== user.phone) {
          updates['phone'] = data.phone;
        }
        if (data.name && data.name !== user.name) {
          updates['name'] = data.name;
        }

        if (data.cpGeneralContactInfo && data.cpGeneralContactInfo !== user.cpGeneralContactInfo) {
          updates['cpGeneralContactInfo'] = data.cpGeneralContactInfo;
        }

        await updatePartialUser(user.id, updates);
        if (data.email && data.email !== user.email) {
          history.push('/logout');
        }
      } catch (err) {
        console.log(`Error updating user: ${err}`);
      }

      return userInfoDisclosure.onClose();
    }
  };

  const notificationPreferencesFormSubmit = async (data: Record<string, any>): Promise<void> => {
    const updates: notificationPreferencesToSubmit = {
      doesWantSms: data.doesWantSms,
      doesWantEmail: data.doesWantEmail,
    };
    if (data.doesWantPush !== undefined) {
      updates['doesWantPush'] = data.doesWantPush;
    }

    if (abbreviatedSettingsInterface && data.phoneNumber) {
      updates['phone'] = data.phoneNumber;
    }
    setNotificationsIsDirty(false);
    setNextDisabled(false);

    await updatePartialUser(user.id, updates);

    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.SUPPORT_NETWORK,
      interactionType: interactionType.SUPPORT_NETWORK.SAVED,
    });

    // return notificationPreferencesDisclosure.onClose();
  };

  const addModifiedFieldInteraction = () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.SUPPORT_NETWORK,
      interactionType: interactionType.SUPPORT_NETWORK.MODIFIED_FIELD,
    });
  };

  const onFieldFocus = () => {
    // Set onInputFocused state to adjust bottom margin for keyboard
    setInputFocused(true);

    // Track user interaction
    addModifiedFieldInteraction();
  };

  const onFieldBlur = () => {
    // Set onInputFocused state to adjust bottom margin for keyboard
    setInputFocused(false);
  };

  // update time zone
  const submitTimezone = async (data: Record<string, any>): Promise<void> => {
    try {
      await updatePartialUser(user.id, {
        timezone: data.selectedTimezone.value,
        manuallySetTimezone: !data.automaticTimezone,
      });
    } catch (err) {
      console.error(`Error updating time zone on user: ${err}`);
    }
  };

  const confirmAccountDelete = async () => {
    if (window.confirm(t('manageUserInfoModal.accountManagement.confirmation'))) {
      try {
        const allLinkedUsers: PossibleLinkedUser[] = await getAllLinkedUsersIncludingDeleted(user.id, isPir);
        await props.deleteLoggedInUser(user, allLinkedUsers, isPir);
        if (garminDeviceAPI && garminDeviceAPI.status === 'AUTHORIZED')
          await props.deregisterGarminAPI(garminDeviceAPI);
        history.push('/logout');
      } catch (err: any) {
        console.error(err);
        toast({
          title: t('manageUserInfoModal.accountManagement.deleteErrorTitle'),
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Modal scrollBehavior="inside" isCentered={true} isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent
        maxWidth="700px"
        minHeight={hasOnboarded ? undefined : '90%'}
        marginBottom={(abbreviatedSettingsInterface || isPhone) && inputFocused ? 300 : undefined}
        marginTop={abbreviatedSettingsInterface || isPhone ? [0, 0, 0] : undefined}
        p={[2, 2, 4, 8]}
        zIndex={1900}
      >
        <ModalHeader fontWeight="light" color="purple3.600" fontSize={24} textAlign="center" px={[1, 1, 6]}>
          {!abbreviatedSettingsInterface
            ? t('manageContactModal.header')
            : t('manageUserInfoModal.notificationPreferences.onboarding.header')}
        </ModalHeader>
        {!abbreviatedSettingsInterface && <ModalCloseButton />}
        <ModalBody display="flex" px={[1, 1, 6]} flexWrap="wrap">
          {/* My contact info */}
          {!abbreviatedSettingsInterface && (
            <Box w={['100%', '100%', '100%']}>
              <Text fontSize={18} fontWeight="light" color="purple3.600" as="h4" mb={2} mt={2}>
                {t('manageContactModal.pir.header')}
              </Text>
              {!userInfoDisclosure.isOpen && (
                <Box p={4} bg="grey6.50" borderRadius={8} mb={4}>
                  <Text textAlign="right" fontSize={12} mb={2}>
                    <ChakraLink
                      onClick={() => {
                        createInteraction({
                          pir,
                          dateTime: new Date(),
                          moduleName: moduleName.SUPPORT_NETWORK,
                          interactionType: interactionType.SUPPORT_NETWORK.EDITED,
                        });
                        userInfoDisclosure.onOpen();
                      }}
                      textDecoration="underline"
                      _hover={{
                        textDecoration: 'none',
                      }}
                    >
                      {t('manageContactModal.editLink')}
                    </ChakraLink>
                  </Text>
                  <Box display="flex">
                    <Flex flexDirection={'column'} width="100%">
                      <Flex flexDirection={'row'} justifyContent="space-between" alignItems="center" mb={2}>
                        <Text fontWeight="normal" fontSize={13}>
                          {t('manageUserInfoModal.name')}
                        </Text>
                        <Text fontWeight="normal" fontSize={13} color={user.name ? 'black' : 'grey6.500'}>
                          {user.name ? user.name : t('manageContactModal.pir.noneProvided')}
                        </Text>
                      </Flex>

                      <Flex flexDirection={'row'} justifyContent="space-between" alignItems="center" mb={2}>
                        <Text fontWeight="normal" fontSize={13}>
                          {t('manageUserInfoModal.phone')}
                        </Text>
                        <Text fontWeight="normal" fontSize={13} color={user.phone ? 'black' : 'grey6.500'}>
                          {user.phone ? formatPhone(user.phone) : t('manageUserInfoModal.noneProvided')}
                        </Text>
                      </Flex>

                      <Flex flexDirection={'row'} justifyContent="space-between" alignItems="center" mb={2}>
                        <Text fontWeight="normal" fontSize={13}>
                          {t('manageUserInfoModal.email')}
                        </Text>
                        <Text fontWeight="normal" fontSize={13}>
                          {user.email}
                        </Text>
                      </Flex>

                      {!isPir && (
                        <Flex flexDirection={'row'} justifyContent="space-between" alignItems="flex-start">
                          <Text fontWeight="normal" fontSize={13} marginRight={'1'}>
                            {t('manageUserInfoModal.cpGeneralContactInfo.label')}
                          </Text>
                          <Flex flexDirection={'column'}>
                            {user.cpGeneralContactInfo ? (
                              <CPGeneralContactInfoBox cpGeneralContactInfo={user.cpGeneralContactInfo} />
                            ) : (
                              <Text fontWeight="normal" fontSize={13} color={'grey6.500'}>
                                {t('manageUserInfoModal.noneProvided')}
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                  </Box>
                </Box>
              )}
              {user && userInfoDisclosure.isOpen && (
                <UserInfoForm
                  user={user}
                  onSubmit={userFormSubmit}
                  onCancel={closeUserInfoForm}
                  interactionCallback={onFieldFocus}
                  onBlurCallback={onFieldBlur}
                  submitError={submitError}
                  setSubmitError={setSubmitError}
                />
              )}

              {user && (
                <Box>
                  <Text fontSize={18} fontWeight="light" color="purple3.600" as="h4" mb={2} mt={2}>
                    {t('manageContactModal.timezone.header')}
                  </Text>
                  <TimezoneInfo user={user} onSubmit={submitTimezone} />
                </Box>
              )}
            </Box>
          )}

          {/* My Notification Preferences */}
          <Box w={['100%', '100%', '100%']}>
            {!abbreviatedSettingsInterface && (
              <Text fontSize={18} fontWeight="light" color="purple3.600" as="h4" mb={2} mt={2}>
                {t('manageUserInfoModal.notificationPreferences.header')}
              </Text>
            )}

            {nextDisabled && (
              <Text fontSize={12} style={{ color: 'red' }}>
                {noNotificationModeEnabled
                  ? t('manageUserInfoModal.notificationPreferences.noNotificationModeEnabled.disabledContinueCaption')
                  : t('manageUserInfoModal.notificationPreferences.onboarding.disabledNextCaption')}
              </Text>
            )}
            <Text fontSize={12} color={'black'} mb={2}>
              {abbreviatedSettingsInterface && isPir
                ? t('manageUserInfoModal.notificationPreferences.onboarding.pir')
                : abbreviatedSettingsInterface
                ? t('manageUserInfoModal.notificationPreferences.onboarding.cp')
                : isPir
                ? t('manageUserInfoModal.notificationPreferences.settings.pir')
                : t('manageUserInfoModal.notificationPreferences.settings.cp')}{' '}
              {abbreviatedSettingsInterface && t('manageUserInfoModal.notificationPreferences.onboarding.howToChange')}
            </Text>
            {user && (
              <NotificationPreferencesForm
                key={`${user.id}-${user.doesWantPush}-${user.doesWantEmail}`}
                user={user}
                onSubmit={notificationPreferencesFormSubmit}
                setNotificationsIsDirty={setNotificationsIsDirty}
                hasOnboarded={hasOnboarded}
                abbreviatedSettingsInterface={abbreviatedSettingsInterface}
                isPir={isPir}
              />
            )}
          </Box>
          {!abbreviatedSettingsInterface && (
            <Box w={['100%', '100%', '100%']}>
              <Divider w="100%" display={['block', 'block', 'none']} mt={6} mb={6} />
              <Flex flexDirection="column" alignItems="center" w="100%">
                <Box w="100%">
                  <Text fontSize={18} fontWeight="light" color="purple3.600" as="h4" my={4}>
                    Upload Profile Image
                  </Text>

                  {user && <ImageUpload user={user} updatePartialUser={updatePartialUser} />}
                </Box>
                <Divider w="100%" display={['block', 'block', 'none']} mt={6} mb={6} />
                <Flex w="100%" flexDirection="column" alignItems="center">
                  <Text w="100%" fontSize={18} fontWeight="light" color="purple3.600" as="h4" mb={4} textAlign="left">
                    {t('manageUserInfoModal.accountManagement.header')}
                  </Text>
                  <PrimaryButton w="50%" onClick={confirmAccountDelete}>
                    {t('manageUserInfoModal.accountManagement.deleteButton')}
                  </PrimaryButton>
                </Flex>
              </Flex>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          {!hasOnboarded && (
            <Box>
              {!nextDisabled && !noNotificationModeEnabled && (
                <Text fontSize={[12, 16]} mt={2} mr={4}>
                  {t('manageUserInfoModal.notificationPreferences.onboarding.nextCaption')}
                </Text>
              )}
              <Button fontSize={[12, 16]} mt={2} mb={2} mr={4} disabled={nextDisabled} onClick={handleNext}>
                {t('manageContactModal.onboarding.nextButton')}
              </Button>
            </Box>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  const user = state.user.user;

  if (!user) {
    throw new Error('No user logged in during Manage Contact Modal');
  }

  return {
    user,
    supportNetwork: state.linkedUsers.supportNetwork ?? [],
    hasOnboarded: state.onboarding.hasOnboarded,
    otherLinkedUsers: state.linkedUsers.otherLinkedUsers ?? [],
    selectLinkedUser: state.linkedUsers.selectedLinkedUser,
  };
};

const mapDispatchToProps = {
  updatePartialUser,
  createInteraction,
  onboarded,
  deleteLoggedInUser,
  deregisterGarminAPI,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ManageUserInfoModal);
