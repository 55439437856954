import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export const TertiaryButton = (props: ButtonProps): JSX.Element => (
  <Button variant="link" color="purple3.600" fontWeight="500" {...props}>
    {props.children}
  </Button>
);

export default TertiaryButton;
