import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Center } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';
import UserInvitationsList from '../admin/UserInvitationsList';
import MockData from '../mockData/MockData';

const AdminDashboard = (): JSX.Element => {
  const { t } = useTranslation('admin');

  return (
    <Center>
      <Box mx="auto" width="100%" px={['18px', '36px']} pt="26px">
        <Flex>
          <Box mb={8} fontSize={25} fontWeight="bold">
            {t('adminDashboard.heading')}
          </Box>
          <MockData />
        </Flex>
        <UserInvitationsList />
      </Box>
    </Center>
  );
};

export default AdminDashboard;
