import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { IconLookup, IconName } from '@fortawesome/fontawesome-svg-core';
import PrimaryButton from './PrimaryButton';

interface Props {
  title: string; // The title displayed on the container
  icon?: IconName | IconLookup; // The icon displayed on the container
  isEmptyModule?: boolean; // Determines if the module is empty or not (Example: If empty, prompts user to add content)
  modalName?: string; // The name of the modal generated on the button press
  modalText?: string;
  cogFunction?(): void;
  cogFunctionText?: string;
  moduleInstruct?: string[]; // The instructions provided to the user regarding the module
  moduleButton?: string; // The text of the button presented on an empty module
  modal?: React.ReactNode; // Container for the modal generated on butotn-press
  children?: React.ReactNode; // Container for content displayed on non-empty modules
}

const ModuleContainer = (props: Props): JSX.Element => {
  return props.isEmptyModule ? (
    <Box p="0px" w="100%">
      <Flex direction="row" as="header" mb="4px">
        <Heading p="0px" as="h3" fontSize="18px" color="#4F4F4F" fontWeight="700">
          {props.title}
        </Heading>
      </Flex>
      <Flex padding="20px 10px 10px 10px" flexDirection="column" alignItems="center">
        {props.moduleInstruct?.map((moduleInstructText, index) => {
          return (
            <Text key={index} fontSize="14px" mb={4}>
              {moduleInstructText}
            </Text>
          );
        })}

        {props.cogFunction && (
          <PrimaryButton mt="3px" mb="3px" alignItems="center" onClick={props.cogFunction}>
            {props.moduleButton || `Add ${props.modalName}`}
          </PrimaryButton>
        )}
        {!props.cogFunction && props.modalName && (
          <Link to={`/home/${props.modalName}`}>
            <PrimaryButton mt="3px" mb="3px" alignItems="center">
              {props.moduleButton}
            </PrimaryButton>
          </Link>
        )}
        {typeof props.modal != 'undefined' && props.modal}
      </Flex>
    </Box>
  ) : (
    <Box p="0px" w="100%">
      <Flex width="100%" direction="row" as="header" mb="30px" justifyContent="space-between">
        <Heading p="0px" as="h3" fontSize={['18px', '20px']} color="#4F4F4F" fontWeight="700">
          {props.title}
        </Heading>
        {props.cogFunction && (
          <Text
            fontSize="16px"
            onClick={props.cogFunction}
            cursor="pointer"
            fontWeight="400"
            color="#8F8F8F"
            data-test="resources-edit-add"
          >
            {props.cogFunctionText || 'See All'}
          </Text>
        )}
        {!props.cogFunction && props.modalName && (
          <Text fontSize="16px" fontWeight="400" color="#8F8F8F">
            <Link to={`/home/${props.modalName}`} data-test={props.modalName + '-edit-add'}>
              {props.modalText || 'Edit/Add'}
            </Link>
          </Text>
        )}
        {typeof props.modal != 'undefined' && props.modal}
      </Flex>
      {props.children}
    </Box>
  );
};

export default ModuleContainer;
