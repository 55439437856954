import { Action } from 'redux';

export enum OnboardingActionType {
  ONBOARDING_STARTED = 'ONBOARDING_STARTED',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_RESET = 'ONBOARDING_RESET',
}

export type OnboardingActions = OnboardingAction | OnboardedAction | OnboardingResetAction;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OnboardingAction extends Action<OnboardingActionType.ONBOARDING_STARTED> {}
export const onboarding = (): OnboardingAction => ({ type: OnboardingActionType.ONBOARDING_STARTED });

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OnboardedAction extends Action<OnboardingActionType.ONBOARDED> {}
export const onboarded = (): OnboardedAction => ({ type: OnboardingActionType.ONBOARDED });

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OnboardingResetAction extends Action<OnboardingActionType.ONBOARDING_RESET> {}
export const resetOnboarding = (): OnboardingResetAction => ({ type: OnboardingActionType.ONBOARDING_RESET });
