import React, { useContext } from 'react';
import { Text, VStack, Grid } from '@chakra-ui/react';
import IAppointment from '../interfaces/IAppointment';
import AppointmentItem from './AppointmentItem';
import { UserContext } from 'src/modules/interior/Index';
import { defaultTimeZone } from 'src/components/ManageAppointmentModal/utils';
import { useDevice } from 'src/DeviceContext';

interface Props {
  appointments: IAppointment[];
  emptyInstruct: string;
  setSelectedAppointment: React.Dispatch<React.SetStateAction<IAppointment | null>>;
  selectedAppointment: IAppointment | null;
  appointmentColor: string;
  isPir: boolean;
}

const AppointmentItemList = (props: Props): JSX.Element => {
  const user = useContext(UserContext);
  const timezone = user?.timezone ?? defaultTimeZone;
  const { isTablet } = useDevice();

  return (
    <VStack spacing={['15px', '24px']} alignItems="center" justifyContent="flex-start">
      {props.appointments.length === 0 && (
        <Text fontSize="16px" fontStyle="italic" color="8F8F8F">
          {props.emptyInstruct}
        </Text>
      )}
      {props.appointments.length !== 0 && (
        <Grid
          templateColumns={
            isTablet
              ? {
                  base: 'repeat(auto-fit, minmax(240px, 1fr))',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                  lg: 'repeat(4, 1fr)',
                }
              : {
                  base: 'repeat(auto-fit, minmax(275px, 1fr))',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(2, 1fr)',
                  lg: 'repeat(3, 1fr)',
                  xl: 'repeat(4, 1fr)',
                }
          }
          width="100%"
        >
          {props.appointments.map((p) => (
            <AppointmentItem
              appointment={p}
              key={p.id}
              setSelectedAppointment={props.setSelectedAppointment}
              selectedAppointment={props.selectedAppointment}
              appointmentColor={props.appointmentColor}
              isPir={props.isPir}
              timezone={timezone}
            />
          ))}
        </Grid>
      )}
    </VStack>
  );
};

export default AppointmentItemList;
