import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnimation, motion } from 'framer-motion';
import { Heading, Flex, Button, Text, Input, Box } from '@chakra-ui/react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/accordion';
import NarrowInterior from '../../components/NarrowInterior';

import { useHistory } from 'react-router-dom';
import { useDevice } from 'src/DeviceContext';

const InformedConsent = (): JSX.Element => {
  const { t } = useTranslation('onboarding');
  const MotionHeading = motion(Heading);
  const headerControls = useAnimation();
  const history = useHistory();
  const { isPhone } = useDevice();

  // Reference to IRB documentation signature
  const irbSignatureInput = useRef<HTMLInputElement>(null);
  const SECTION_HEADING_SIZE = 22;

  const sectionList = [
    'studyTitle',
    'principalInvestigator',
    'otherContact',
    'purposeOfStudy',
    'procedures',
    'participantInvolvement',
    'participantRequirements',
    'risks',
    'benefits',
    'compensationAndCosts',
    'confidentiality',
    'rights',
    'rightToAskQuestions',
  ];

  const irbDocsSubmit = () => {
    if (irbSignatureInput.current != null && irbSignatureInput.current.value !== '') {
      irbSignatureInput.current.value = '';
      history.push('/enter-name');
    } else {
      alert(t('irbDocumentation.missingInitialAlert'));
    }
  };

  return (
    <NarrowInterior>
      <MotionHeading as="h2" my={6} fontSize={24} fontWeight="light" color="purple3.600" animate={headerControls}>
        {t('welcome')}
      </MotionHeading>

      {/* IRB Agreement Page */}
      <Flex flexDir="column" mt={16} mb={isPhone ? 250 : 22}>
        <Text fontSize={24} fontWeight="light" color="purple3.600">
          {t('irbDocumentation.header')}
        </Text>

        {/* Participant initials section */}
        <Accordion allowMultiple={true}>
          {sectionList.map((section) => (
            <AccordionItem key={section} boxShadow="md">
              <AccordionButton backgroundColor="grey6.50" mb={3} py={3} px={2}>
                <Box flex="1" textAlign="left" fontSize={SECTION_HEADING_SIZE}>
                  {t('irbDocumentation.documentation.' + section + 'Header')}
                </Box>
                <AccordionIcon fontSize="36px" />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Box mx={5} mb={3}>
                  <Text fontSize={18} fontWeight="light" color="purple3.600" whiteSpace="pre-line" mb={2}>
                    {t('irbDocumentation.documentation.' + section)}
                  </Text>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        {/* Voluntary Consent Section */}
        <Flex flexDir="column" mt={8}>
          <Text fontSize={SECTION_HEADING_SIZE} fontWeight="light" color="purple3.600" whiteSpace="pre-line" mb={2}>
            {t('irbDocumentation.documentation.voluntaryConsentHeader')}
          </Text>
          <Text fontSize={18} fontWeight="light" color="purple3.600" whiteSpace="pre-line" mb={2}>
            {t('irbDocumentation.documentation.voluntaryConsent')}
          </Text>
        </Flex>

        <Flex mt={12} flexDir="row" alignItems="center" justifyContent="space-between">
          <Input
            flex={1}
            bg="white"
            name="irbSignature"
            placeholder={t('irbDocumentation.initialPlaceholder')}
            ref={irbSignatureInput}
          />

          <Button
            backgroundColor="blue3.100"
            fontWeight="normal"
            _hover={{ backgroundColor: 'blue3.300' }}
            onClick={() => irbDocsSubmit()}
            whiteSpace="normal"
            ml={4}
          >
            {t('irbDocumentation.agreeButton')}
          </Button>
        </Flex>
      </Flex>
    </NarrowInterior>
  );
};

export default InformedConsent;
