import firebase from 'firebase/compat/app';

export enum UserRole {
  ADMIN = 'ADMIN',
  HEAD_ORG = 'HEAD_OF_ORGANIZATION',
  USER = 'USER',
  CP = 'CARE_PROVIDER',
}

export interface FcmTokens {
  [deviceId: string]: string;
}

export enum DeletionStatus {
  PENDING = 'PENDING',
  DELETED = 'DELETED',
  DELETING = 'DELETING',
  RESTORING = 'RESTORING',
}

export enum DevicePairedStatus {
  PAIRED = 'PAIRED',
  NOT_YET_PAIRED = 'NOT_YET_PAIRED',
  BECAME_UNPAIRED = 'BECAME_UNPAIRED',
  USER_UNPAIRED = 'USER_UNPAIRED',
}

export default interface IUser {
  id: string;
  name?: string;
  email: string;
  cravingsPredictionEnabled: boolean;
  preferences: {
    hiddenGraphs: string[];
  };
  doesWantEmail?: boolean;
  phone?: string;
  doesWantSms?: boolean;
  image?: string;
  fcmToken?: string;
  fcmTokens?: FcmTokens;
  doesWantPush?: boolean;
  showColor?: boolean;
  showVitals?: boolean;
  role?: string;
  deletedDatetime?: Date;
  deletionStatus?: DeletionStatus;
  timezone?: string;
  manuallySetTimezone?: boolean;
  userId?: string;
  stoppedSms?: boolean;
  cpGeneralContactInfo?: string;
  emailBeforeDeleted?: string;
  originalEmail?: string;
  emailReverted?: boolean;
  verificationToken?: string;
  devicePairedStatus?: DevicePairedStatus;
}

export interface IFirebaseUser extends Omit<Omit<IUser, 'id'>, 'deletedDatetime'> {
  deletedDatetime?: Date | firebase.firestore.FieldValue;
}

export interface IContextUser {
  id: string;
  name: string;
  email: string;
  role: string;
  hasAccessTo: string[];
  showColor: boolean;
  showVitals: boolean;
  phone: string | undefined;
  image: string | undefined;
  cravingsPredictionEnabled: boolean;
  preferences: {
    hiddenGraphs: string[];
  };
  doesWantEmail: boolean;
  doesWantSms: boolean;
  doesWantPush: boolean;
  fcmTokens: FcmTokens | undefined;
  onboarded: boolean;
  deletedDatetime?: Date;
  deletionStatus?: DeletionStatus;
  timezone: string;
  manuallySetTimezone: boolean;
  stoppedSms: boolean;
  cpGeneralContactInfo: string;
  devicePairedStatus?: DevicePairedStatus;
}

export interface IContextPirUser {
  id: string;
  name: string;
  email: string;
  role: string;
  hasAccessTo: string[];
  showColor: boolean;
  showVitals: boolean;
  phone: string | null;
  image: string | undefined;
  cravingsPredictionEnabled: boolean;
  preferences: {
    hiddenGraphs: string[];
  };
  doesWantEmail: boolean;
  doesWantSms: boolean;
  doesWantPush: boolean;
  fcmTokens: FcmTokens | null;
  onboarded: boolean;
  pirAlias: string | undefined;
  preferredName: string | undefined;
  deletedDatetime?: Date;
  deletionStatus?: DeletionStatus;
  timezone: string;
  manuallySetTimezone: boolean;
  stoppedSms?: boolean;
}
