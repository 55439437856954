import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import ModuleContainer from '../../components/ModuleContainerV2';
import SupportRequestsModal from '../../components/SupportRequestsModal/SupportRequestsModal';
import { filterRequestsRecentSort } from './utils';
import { useTranslation } from 'react-i18next';
import SupportRequestsPreview from './SupportRequestsPreview';
import { SelectedUserContext } from '../interior/Index';
import { useQuerySupportRequests } from './queryHooks';
import ISupportRequest, { SupportRequestStatus } from '../../db/supportRequests';
import { useModal } from 'src/ModalContext';

const MODAL_NAME = 'support_requests';

const SupportRequestsModuleCP = (): React.ReactElement | null => {
  const { selectedUser } = useContext(SelectedUserContext);
  /* should never really happen */
  if (!selectedUser) return null;

  /* eslint-disable react-hooks/rules-of-hooks */
  const { t } = useTranslation('support_requests');
  const { modal, openModal, closeModal } = useModal();
  const { data } = useQuerySupportRequests(selectedUser);
  const allRequests = data || [];
  const supportRequests = filterRequestsRecentSort(allRequests);
  const history = useHistory();

  const [selectedRequest, setSelectedRequest] = useState<ISupportRequest | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<SupportRequestStatus>(SupportRequestStatus.ALL);
  /* eslint-enable react-hooks/rules-of-hooks */

  return (
    <Box>
      <ModuleContainer
        title={t('requestsModule.title')}
        isEmptyModule={supportRequests.length <= 0}
        modalName={MODAL_NAME}
        moduleInstruct={[t('requestsModule.moduleInstruct')]}
        modalText={t('requestsModal.modalText')}
        moduleButton={t('requestsModal.modalButton')}
        cogFunction={() => openModal(MODAL_NAME)}
      >
        <SupportRequestsPreview
          requests={supportRequests}
          onSelect={(supportRequest: ISupportRequest) => {
            setSelectedRequest(supportRequest);
            setSelectedStatus(SupportRequestStatus.ALL);
            history.push(`/home/${MODAL_NAME}`);
            openModal(MODAL_NAME);
          }}
        />
      </ModuleContainer>
      <SupportRequestsModal
        isOpen={modal === MODAL_NAME}
        onOpen={() => openModal(MODAL_NAME)}
        selectedRequest={selectedRequest}
        selectedStatus={selectedStatus}
        onClose={() => {
          closeModal();
        }}
        onSelect={setSelectedRequest}
        onStatusChange={setSelectedStatus}
      />
    </Box>
  );
};

export default SupportRequestsModuleCP;
