import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { isMobile, isTablet as tablet, isAndroid as android } from 'react-device-detect';

interface DeviceContextProps {
  isTablet: boolean;
  isPhone: boolean;
  isCordova: boolean;
  isMobileDevice: boolean;
  isMobileDeviceOrCordova: boolean;
  isAndroid: boolean | undefined;
  isMobileDeviceAndCordova: boolean;
  deviceIsReady: boolean;
}

const DeviceContext = createContext<DeviceContextProps>({
  isTablet: false,
  isPhone: false,
  isCordova: false,
  isMobileDevice: false,
  isMobileDeviceOrCordova: false,
  isAndroid: undefined,
  isMobileDeviceAndCordova: false,
  deviceIsReady: false,
});

export const DeviceProvider: React.FC = ({ children }) => {
  const isTablet = tablet;
  const isPhone = isMobile && !tablet;
  const isMobileDevice = isMobile || tablet;
  const isCordova = !!window.cordova;
  const isMobileDeviceOrCordova = !!window.cordova || tablet || isMobile;
  const isMobileDeviceAndCordova = !!window.cordova && isMobileDevice;
  const isAndroid = android;
  const [deviceIsReady, setDeviceIsReady] = useState(false);

  useEffect(() => {
    if (isCordova) {
      const onDeviceReady = () => {
        console.log('Device is ready');
        setDeviceIsReady(true);
      };

      document.addEventListener('deviceready', onDeviceReady, false);

      return () => {
        document.removeEventListener('deviceready', onDeviceReady, false);
        setDeviceIsReady(false);
      };
    }
  }, [isCordova]);

  const value = useMemo(
    () => ({
      isPhone,
      isTablet,
      isMobileDevice,
      isMobileDeviceOrCordova,
      isMobileDeviceAndCordova,
      isCordova,
      isAndroid,
      deviceIsReady,
    }),
    [
      isPhone,
      isTablet,
      isMobileDevice,
      isMobileDeviceOrCordova,
      isMobileDeviceAndCordova,
      isCordova,
      isAndroid,
      deviceIsReady,
    ],
  );

  return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>;
};

export const useDevice = () => useContext(DeviceContext);
