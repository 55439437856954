import React from 'react';
import { Text, VStack, HStack, Flex } from '@chakra-ui/react';
import IAppointment from '../interfaces/IAppointment';
import { Link } from 'react-router-dom';
import FAIcon from 'src/components/FAIcon';
import {
  convertDateWithTimezone,
  formatTimeStringFromDateTimeAndTz,
  getTz,
} from 'src/components/ManageAppointmentModal/utils';

interface Props {
  appointment: IAppointment;
  setSelectedAppointment: React.Dispatch<React.SetStateAction<IAppointment | null>>;
  selectedAppointment: IAppointment | null;
  appointmentColor: string;
  isPir: boolean;
  timezone: string;
}

const AppointmentItem = ({ appointment, setSelectedAppointment, appointmentColor, timezone }: Props): JSX.Element => {
  return (
    <Link
      to={`/home/appointments/${appointment.id}`}
      onClick={() => setSelectedAppointment(appointment)}
      style={{ flexGrow: 1 }}
    >
      <Flex width={'100%'} alignItems="center">
        <Flex
          minHeight={['auto', '114px']}
          gap={['21px', '24px']}
          flexWrap="wrap"
          overflow="hidden"
          flexDirection={['column', 'row', 'row', 'row']}
          padding={['20px', '16px', '16px', '16px']}
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          rounded="10px"
          width={['100%', '250px', '250px', '260px']}
          mb="16px"
          mr={['0', '16px', '16px', '16px']}
          backgroundColor="white"
          alignItems="center"
        >
          <Flex flexDirection="row" justifyContent={'space-between'} alignItems={'center'}>
            <HStack
              spacing="20px"
              minWidth={['260px', '215px', '215px', '220px']}
              maxWidth={['290px', '240px', '240px', '250px']}
              overflow="hidden"
              alignItems="center"
            >
              <VStack
                padding="7px"
                spacing="0px"
                backgroundColor={appointmentColor}
                borderRadius="10px"
                width={['66px']}
                height="82px"
                alignItems="center"
                justifyContent="center"
              >
                <Text
                  color="white"
                  fontWeight={'semibold'}
                  fontSize="16px"
                  display="flex"
                  lineHeight="19px"
                  letterSpacing="0.02em"
                  alignItems="center"
                  justifyContent="center"
                >
                  {appointment.appointmentDate.toLocaleDateString(navigator.language, {
                    month: 'short',
                    timeZone: timezone,
                  })}
                </Text>
                <Text
                  color="white"
                  fontWeight={700}
                  fontSize="24px"
                  lineHeight="28px"
                  display="flex"
                  letterSpacing="0.02em"
                  alignItems="center"
                  justifyContent="center"
                >
                  {appointment.appointmentDate.toLocaleDateString(navigator.language, {
                    day: '2-digit',
                    timeZone: timezone,
                  })}
                </Text>
                <Text
                  color="white"
                  fontWeight={'semibold'}
                  fontSize="16px"
                  display="flex"
                  lineHeight="19px"
                  alignItems="center"
                  letterSpacing="0.02em"
                  justifyContent="center"
                >
                  {convertDateWithTimezone(appointment.appointmentDate, timezone).getFullYear()}
                </Text>
              </VStack>

              <VStack alignItems="flex-start" justifyContent="center" height="100%" padding="7px 0px">
                <Text
                  color={appointmentColor}
                  fontWeight={'bold'}
                  fontSize="16px"
                  lineHeight="18.8px"
                  letterSpacing="0.02em"
                  noOfLines={2}
                  textOverflow="ellipsis"
                  alignItems="center"
                >
                  {appointment.otherUserAlias
                    ? `${appointment.otherUserAlias} (${appointment.otherUserName})`
                    : `${appointment.otherUserName}`}
                </Text>
                <Text color={appointmentColor} fontWeight={400} fontSize="14px" lineHeight="18.8px" noOfLines={2}>
                  {`${
                    appointment.appointmentTime.timeStartString?.toUpperCase() ??
                    formatTimeStringFromDateTimeAndTz(
                      appointment.appointmentDate,
                      appointment.appointmentTime.timeStart,
                      timezone,
                    )
                  } ${getTz(timezone)}`}
                  <br />
                  {`${appointment.appointmentDate.toLocaleDateString(navigator.language, {
                    weekday: 'long',
                    timeZone: timezone,
                  })}`}
                </Text>
              </VStack>
            </HStack>
            <FAIcon icon="angle-right" justifyContent={'flex-end'} color="#4F4F4F" />
          </Flex>
        </Flex>
      </Flex>
    </Link>
  );
};

export default AppointmentItem;
