import firebase from 'firebase/compat/app';
import { DeviceDataActions, DeviceDataActionTypes } from './actions';
import IDeviceData from './interfaces/IDeviceData';

interface DeviceDataState {
  loading: boolean;
  creating: boolean;
  deviceData: null | IDeviceData[];
  unsubFn: null | firebase.Unsubscribe;
}

const defaultState: DeviceDataState = {
  loading: false,
  creating: false,
  deviceData: null,
  // selectedDevice: null,
  unsubFn: null,
};

const deviceDataReducer = (state: DeviceDataState = defaultState, action: DeviceDataActions): DeviceDataState => {
  switch (action.type) {
    case DeviceDataActionTypes.SET_DEVICE_DATA:
      return {
        ...state,
        deviceData: action.deviceData,
      };
    case DeviceDataActionTypes.GETTING_DEVICE_DATA:
      return {
        ...state,
        loading: true,
      };
    case DeviceDataActionTypes.GOT_DEVICE_DATA:
      return {
        ...state,
        loading: false,
      };

    case DeviceDataActionTypes.SET_DEVICE_DATA_UNSUB:
      return {
        ...state,
        unsubFn: action.unsubFn,
      };

    default:
      return state;
  }
};

export default deviceDataReducer;
