import React from 'react';
import { Spinner, Box } from '@chakra-ui/react';
import { IDataPoint } from '../interfaces/IVitalsGraph';
import { ISurveyDataPoint } from '../../../db/summarySurveys';

type VitalsGraphsLoadingProps = {
  data: IDataPoint[] | ISurveyDataPoint[] | undefined;
  graphType: string;
  graphSize: {
    width: number;
    height: number;
  };
  graphPadding: number;
};

const VitalsGraphsLoading = (props: VitalsGraphsLoadingProps): React.ReactElement => {
  const { graphSize } = props;
  return (
    <Box pos="relative">
      <Spinner speed="0.65s" pos="absolute" left="50%" top="50%" />
      <svg style={{ height: '100%' }} viewBox={`0 0  ${graphSize.width} ${graphSize.height}`}>
      </svg>
    </Box>
  );
};

export default VitalsGraphsLoading;
