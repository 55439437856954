import Resizer from 'react-image-file-resizer';

/** Create a random ID for use with SVG definitions
 *
 * @returns string: Random ID of length 20
 */
export const randomId = (): string => {
  let out = '';
  const alphabet = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const alphabetLength = alphabet.length;
  for (let i = 0; i < 20; i++) {
    out += alphabet.charAt(Math.floor(Math.random() * alphabetLength));
  }
  return out;
};

/** Compress an image file
 *
 * @returns blob: blob for the compressed image
 */
export const compressImgFile = async (imageBlob: Blob, width: number, height: number): Promise<Blob> => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      imageBlob,
      width,
      height,
      'JPEG',
      90,
      0,
      (uri) => {
        resolve(uri as Blob);
      },
      'blob',
    );
  });
};
