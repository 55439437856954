import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Box, Tabs, TabList, Tab, Flex } from '@chakra-ui/react';
import { IconName } from '@fortawesome/free-solid-svg-icons';

import VitalsGraphs from './VitalsGraphs';
import { ISurveyDataPoint } from '../../../db/summarySurveys';
import VitalSurveyGraph from './VitalSurveyGraph';
import { IDataPoint } from '../interfaces/IVitalsGraph';
import VitalGraphSubHeading from './VitalGraphSubHeading';
import { IVitalList } from '../VitalsModuleCP';
import VitalsGraphsLoading from './VitalsGraphsLoading';
import {
  GraphType,
  permanentlyVisibleGraphs,
  queryFunctionForGraph,
  vitalsGraphOptionsByGraphType,
} from '../vitalsGraphOptions';
import { useDevice } from 'src/DeviceContext';

interface Props {
  graphType: GraphType;
  showColor?: boolean;
  uid: string | null;
  icon: IconName;
  emotionItemsVisibility?: IVitalList[];
  onSelectedGraphChanged?: ((selected: GraphType) => void) | undefined;
  isCP: boolean;
}

const VitalsGraphsOverDays = ({
  uid,
  graphType,
  showColor,
  icon,
  emotionItemsVisibility,
  onSelectedGraphChanged,
  isCP,
}: Props): React.ReactElement => {
  const queryTabType = queryFunctionForGraph(graphType, uid);
  const { isPhone } = useDevice();

  const { data, status } = useQuery(['vitals-', uid, '-', graphType], async () => queryTabType, {
    staleTime: 60 * 60 * 1000,
  });

  const selectedStyle = {
    color: 'white',
    bg: '#2680D0',
    fontWeight: 'bold',
    fontSize: [15, 17],
  };

  const fontSizeArray = [14, 16];
  const graphPadding = graphType === 'craving' && isPhone ? 55 : isPhone && isCP ? 50 : isPhone ? 45 : 80;
  const axisLabelPadding = isPhone ? 43 : 50;

  const [ndays, setNDays] = useState(
    vitalsGraphOptionsByGraphType[graphType][vitalsGraphOptionsByGraphType[graphType].length - 1].days,
  );
  const [graphSize, setGraphSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight >> 1,
  });
  const updateSize = (ev: any) => {
    setGraphSize({
      width: ev.target.innerWidth,
      height: ev.target.innerHeight >> 1,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  useEffect(() => {
    onSelectedGraphChanged && onSelectedGraphChanged(graphType);
  }, [graphType]);

  const graphTabs = () => {
    const graphOptions = vitalsGraphOptionsByGraphType[graphType];
    return graphOptions.map((tab, index) => {
      return (
        <Tab
          key={`tab-${graphType}-${index}`}
          px={1}
          fontSize={fontSizeArray}
          bg="white"
          color="#525252"
          _selected={selectedStyle}
          borderRadius="md"
        >
          {tab.title}
        </Tab>
      );
    });
  };

  return (
    <Flex direction="column" height="100%">
      <Tabs
        variant="unstyled"
        isFitted={true}
        defaultIndex={vitalsGraphOptionsByGraphType[graphType].length - 1}
        onChange={(tabIndex) => {
          setNDays(vitalsGraphOptionsByGraphType[graphType][tabIndex].days);
        }}
      >
        <Flex rounded="md">
          <Box w="100%" mb={[3, 5]} height="full" style={{ margin: 'auto' }}>
            <TabList rounded="md" boxShadow="base" p={0}>
              {graphTabs()}
            </TabList>
          </Box>
        </Flex>
      </Tabs>
      <Box bg="white" borderTopRadius="md" mt={4} height="full">
        <VitalGraphSubHeading icon={icon} graphType={graphType} />
        {graphType === 'emotion' ? (
          status === 'success' && data !== undefined && emotionItemsVisibility && data ? (
            <VitalSurveyGraph
              data={data as ISurveyDataPoint[]}
              graphSize={graphSize}
              day={ndays}
              graphPadding={graphPadding}
              axisLabelPadding={axisLabelPadding}
              emotionItemsVisibility={emotionItemsVisibility}
            />
          ) : (
            <VitalsGraphsLoading data={data} graphType={graphType} graphSize={graphSize} graphPadding={graphPadding} />
          )
        ) : data ? (
          <VitalsGraphs
            data={data as IDataPoint[]}
            day={ndays}
            graphType={graphType}
            showColor={showColor}
            graphSize={graphSize}
            graphPadding={graphPadding}
            axisLabelPadding={axisLabelPadding}
            canBeHidden={!permanentlyVisibleGraphs.includes(graphType)}
          />
        ) : (
          <VitalsGraphsLoading data={data} graphType={graphType} graphSize={graphSize} graphPadding={graphPadding} />
        )}
      </Box>
    </Flex>
  );
};

export default VitalsGraphsOverDays;
