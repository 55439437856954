import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  Flex,
  Box,
  Text,
} from '@chakra-ui/react';
import AddMotivatorForm from './AddMotivatorForm';
import MotivatorDisplay from './MotivatorDisplay';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
}

type Props = SuppliedProps;

const MotivatorModal = (props: Props): React.ReactElement => {
  const { t } = useTranslation('motivator');
  const { isOpen, onClose } = props;
  const { isPhone } = useDevice();

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent
        maxWidth={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : ['100%', '98%', '90%', '85%', '75%']}
        minHeight={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : undefined}
        marginBottom={isPhone ? [0, 0, 0] : undefined}
        marginTop={isPhone ? [0, 0, 0] : undefined}
        p={[2, 2, 4, 8]}
        zIndex={1900}
      >
        <ModalHeader fontWeight="light" color="purple3.600" fontSize={24} textAlign="center" px={[1, 1, 6]}>
          {t('motivatorModal.header')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w="50%" mb={5}>
            <Text fontSize={18} mb={5}>
              {t('motivatorModal.subheader')}
            </Text>
          </Box>
          <Flex direction="column" alignItems="left">
            <MotivatorDisplay />
            <AddMotivatorForm />
          </Flex>
        </ModalBody>
        <ModalFooter>
          {/* Cancel button temporarily disabled */}
          {/* <Link onClick={onClose} fontSize={14} mt={2} mr={4}>
            {t('motivatorModal.modalCancel')}
          </Link> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MotivatorModal;
