import Firestore, { FirestoreUtils } from '../modules/firestore/Firestore';

export interface ISurveyDataPoint {
  [key: string]: any;
  anxious: number;
  happy: number;
  relaxed: number;
  stressed: number;
  sad: number;
  tired: number;
  pain: number;
  datetime: Date;
}

/**
 * Return all of the emotion data for a Graph
 * @param id The unique Firestore ID for a person
 * @returns a list of data points of the type IVitalsGraph[]
 */
export const getSummarySurveys = async (id: string | null): Promise<ISurveyDataPoint[]> => {
  if (id === null) {
    return [];
  }
  const query = await Firestore.collection('surveys')
    .where('pir', '==', FirestoreUtils.getDocRef('users', id))
    .get();
  if (query.empty) {
    return [];
  }

  return query.docs.map((doc) => {
    const data = doc.data();
    const emotionArray = ['happy', 'anxious', 'relaxed', 'stressed', 'sad', 'tired', 'pain'];

    const filteredEmotions = Object.keys({ ...data })
      .filter((key) => emotionArray.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {} as ISurveyDataPoint);

    return { ...filteredEmotions, dateTime: data.datetime.toDate() };
  });
};
