import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux/store';
import { useDisclosure, Avatar, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import FAIcon from '../../components/FAIcon';
import ManageUserInfoModal from '../../components/ManageUserInfoModal/ManageUserInfoModal';
import ChangeLinkedUser from './ChangeLinkedUser';
import { isPirLinkedUser } from '../linked-users/utils';
import HelpMenu from './HelpMenu';

export const UserMenu = (props: PropsFromRedux): JSX.Element => {
  const { user, isPir } = props;
  const history = useHistory();
  const userInfoDisclosure = useDisclosure();
  const changeModalDisclosure = useDisclosure();
  const helpModalDisclosure = useDisclosure();
  const confirmLogout = () => {
    if (window.confirm("You'll be logged out from the current acount")) {
      history.push('/logout');
    }
  };

  const toRoleSelection = () => {
    changeModalDisclosure.onOpen();
  };

  const toHelpMenu = () => {
    helpModalDisclosure.onOpen();
  };

  return (
    <>
      <Menu preventOverflow={true}>
        <MenuButton>
          <Avatar
            w="48px"
            h="48px"
            ml={12}
            cursor="pointer"
            src={user?.image}
            backgroundColor="white"
            icon={<FAIcon icon="user-circle" color="grey3.400" fontSize="5xl" />}
          />
        </MenuButton>
        <MenuList zIndex="100">
          <MenuItem display="flex" gap="26px" alignItems="center" onClick={userInfoDisclosure.onOpen}>
            <FAIcon icon="cog" fontSize="20px" color="grey3.400" />
            <Text>Settings</Text>
          </MenuItem>
          <MenuItem display="flex" gap="26px" alignItems="center" onClick={toRoleSelection}>
            <FAIcon icon="user" fontSize="20px" color="grey3.400" />
            <Text>Role Selection</Text>
          </MenuItem>
          <MenuItem display="flex" gap="26px" alignItems="center" onClick={toHelpMenu}>
            <FAIcon icon="question-circle" fontSize="20px" color="grey3.400" />
            Help
          </MenuItem>
          <MenuItem display="flex" gap="26px" alignItems="center" onClick={confirmLogout}>
            <FAIcon className="fa-rotate-180" icon="sign-out-alt" fontSize="20px" color="grey3.400" />
            Logout
          </MenuItem>
        </MenuList>
        <ChangeLinkedUser
          isOpen={changeModalDisclosure.isOpen}
          onClose={changeModalDisclosure.onClose}
          onSelect={() => {
            changeModalDisclosure.onClose();
            history.push('/home');
          }}
        />
        <HelpMenu isOpen={helpModalDisclosure.isOpen} onClose={helpModalDisclosure.onClose} />
      </Menu>
      <ManageUserInfoModal isOpen={userInfoDisclosure.isOpen} onClose={userInfoDisclosure.onClose} isPir={isPir} />
    </>
  );
};

const mapDispatchToProps = {};
const mapStateToProps = (state: RootState) => {
  const { linkedUsers, user } = state;
  const selectedLinkedUser = linkedUsers.selectedLinkedUser;
  return {
    user: user.user,
    isPir: selectedLinkedUser !== null && isPirLinkedUser(selectedLinkedUser),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserMenu);
