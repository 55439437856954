import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Box, Text, Flex } from '@chakra-ui/react';

import IToDo from '../modules/todo/interfaces/IToDo';
import FAIcon from '../components/FAIcon';
import { defaultTimeZone, getTz } from './ManageAppointmentModal/utils';
import { AppointmentColor, AppointmentType } from 'src/modules/appointments/interfaces/IAppointment';

interface Props {
  width?: string; // percentage
  fontSizes?: number[];
  todo: IToDo;
  isCP?: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
  onChangeCheckbox?: React.ChangeEventHandler<HTMLInputElement>;
  userTimezone: string | undefined;
  appointmentStatus?: AppointmentType;
  appointmentStartTime?: Date;
}

const TodosItem = ({
  // width = '100%',
  fontSizes = [16, 16, 18],
  onClick, // Handles button click
  todo,
  isCP,
  onChangeCheckbox,
  userTimezone,
  appointmentStatus,
  appointmentStartTime,
}: Props): JSX.Element => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [truncatedTitle, setTruncatedTitle] = useState(todo.title);
  const [appointmentTitle, setAppointmentTitle] = useState<string | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (!appointmentTitle) {
        const updatedTitle =
          appointmentStatus === AppointmentType.PAST
            ? todo.title + ' (PAST)'
            : appointmentStatus === AppointmentType.CONFIRM
            ? todo.title + ' (CONFIRM)'
            : appointmentStatus === AppointmentType.PENDING
            ? todo.title + ' (PENDING)'
            : todo.title;
        setAppointmentTitle(updatedTitle);
      }
      if (boxRef.current) {
        const maxWidth = boxRef.current.getBoundingClientRect().width;
        const charLimit = Math.floor(maxWidth / 10); // Approximate characters that fit in the box (adjust as needed)
        const title = todo.type === 'appointments' ? appointmentTitle ?? todo.title : todo.title;
        setTruncatedTitle(title.length > charLimit ? title.slice(0, charLimit) + '...' : title);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize); // Adjust on window resize

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [todo.title, appointmentTitle]);

  let taskColor, taskIcon;
  if (todo.type === 'survey') {
    taskColor = '#FF8F8F';
    taskIcon = 'poll-h';
  } else if (todo.type === 'medications') {
    taskColor = '#C98881';
    taskIcon = 'mortar-pestle';
  } else if (todo.type === 'appointments') {
    taskColor =
      appointmentStatus === AppointmentType.UPCOMING
        ? AppointmentColor.UPCOMING
        : appointmentStatus === AppointmentType.PAST
        ? AppointmentColor.PAST
        : appointmentStatus === AppointmentType.CONFIRM
        ? AppointmentColor.CONFIRM
        : appointmentStatus === AppointmentType.PENDING
        ? AppointmentColor.PENDING
        : '';
    taskIcon = 'calendar-check';
    if (!appointmentTitle) {
      const updatedTitle =
        appointmentStatus === AppointmentType.PAST
          ? todo.title + ' (PAST)'
          : appointmentStatus === AppointmentType.CONFIRM
          ? todo.title + ' (CONFIRM)'
          : appointmentStatus === AppointmentType.PENDING
          ? todo.title + ' (PENDING)'
          : todo.title;
      setAppointmentTitle(updatedTitle);
    }
  } else {
    taskColor = '#40578F';
    taskIcon = 'spa';
  }

  return (
    <Flex
      p={4}
      w="100%"
      alignItems="center"
      bg="white"
      borderRadius="8px"
      borderLeft="10px solid"
      borderColor={taskColor}
      boxShadow={['0px 3px 5px 0px rgba(0, 0, 0, 0.3)', '']}
      data-test={todo.type}
      onClick={onClick}
      cursor="pointer"
    >
      <Box flex="1" isTruncated={true}>
        <FAIcon icon={taskIcon} color={taskColor} mr={3} float="left" />
        <Box ref={boxRef} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" maxWidth="calc(100% - 3rem)">
          <Text isTruncated={true} fontSize={fontSizes} fontWeight="600" maxWidth="100%">
            {truncatedTitle}
          </Text>
          <Text color="grey3.400" marginRight={4} fontSize={fontSizes}>
            {/* Temporary fix for Appointments:
                - In the future would want to have a dueDate Time property for each toDo
                  - Would allow user's to choose when they want to do a certain task
             */}
            {!appointmentStartTime
              ? todo.createdDateTime.toLocaleString(undefined, {
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: userTimezone ?? defaultTimeZone,
                })
              : appointmentStartTime.toLocaleString(undefined, {
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: userTimezone ?? defaultTimeZone,
                }) + ` ${getTz(userTimezone || defaultTimeZone)}`}
          </Text>
        </Box>
      </Box>
      {!isCP && <Checkbox size="lg" colorScheme="green" isChecked={todo.isCompleted} onChange={onChangeCheckbox} />}
    </Flex>
  );
};

export default TodosItem;
