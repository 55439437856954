import { extendTheme } from '@chakra-ui/react';

const customColors = {
  grey: {
    50: '#fbf0f2',
    100: '#dcd8d9',
    200: '#bfbfbf',
    300: '#a6a6a6',
    400: '#8c8c8c',
    500: '#737373',
    600: '#595959',
    700: '#404040', // base color
    800: '#282626',
    900: '#150a0d',
  },
  grey2: {
    50: '#fceff2',
    100: '#ddd7d9',
    200: '#c1bfbf',
    300: '#a6a6a6',
    400: '#8c8c8c',
    500: '#737373',
    600: '#595959', // base color
    700: '#413f40',
    800: '#292526',
    900: '#16090d',
  },
  grey3: {
    50: '#feeff2',
    100: '#ded7d9',
    200: '#c2bebf',
    300: '#a7a5a6',
    400: '#8c8c8c', // base color
    500: '#737373',
    600: '#5a5959',
    700: '#423f40',
    800: '#2a2526',
    900: '#17080d',
  },
  grey4: {
    50: '#fbf0f2',
    100: '#dcd8d9',
    200: '#bfbfbf', // base color
    300: '#a6a6a6',
    400: '#8c8c8c',
    500: '#737373',
    600: '#595959',
    700: '#404040',
    800: '#282626',
    900: '#150a0d',
  },
  grey5: {
    50: '#f8f0f2',
    100: '#d9d9d9', // base color
    200: '#bfbfbf',
    300: '#a6a6a6',
    400: '#8c8c8c',
    500: '#737373',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#120b0d',
  },
  grey6: {
    50: '#f2f2f2', //base color
    100: '#d9d9d9',
    200: '#bfbfbf',
    300: '#a6a6a6',
    400: '#8c8c8c',
    500: '#737373',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#0d0d0d',
  },
  red: {
    50: '#ffe5e5',
    100: '#fbb9ba',
    200: '#f28c8d',
    300: '#ec5f5f', // base color
    400: '#e63333',
    500: '#cc1a19',
    600: '#a01313',
    700: '#730c0d',
    800: '#460506',
    900: '#1e0000',
  },
  orange: {
    50: '#fff1de',
    100: '#fdd6b4',
    200: '#f7bc87',
    300: '#f3a158', // base color
    400: '#ef862b',
    500: '#d56d12',
    600: '#a7550c',
    700: '#773c07',
    800: '#492301',
    900: '#1e0a00',
  },
  yellow: {
    50: '#fff8dd',
    100: '#fbe9b4',
    200: '#f6db87',
    300: '#f3cd59', // base color
    400: '#efbe2c',
    500: '#d6a514',
    600: '#a6800c',
    700: '#775c06',
    800: '#483700',
    900: '#1b1200',
  },
  green: {
    50: '#e1fdee',
    100: '#bcf2d3',
    200: '#95e8b8',
    300: '#6ddf9d',
    400: '#46d683',
    500: '#2cbc69',
    600: '#209251', // base color
    700: '#146939',
    800: '#074021',
    900: '#001707',
  },
  green2: {
    50: '#e0fded',
    100: '#bcf2d3',
    200: '#94e9b8',
    300: '#6bde9c',
    400: '#44d581',
    500: '#2abb67', // base color
    600: '#1e924f',
    700: '#116838',
    800: '#053f20',
    900: '#001705',
  },
  green3: {
    50: '#e4fced',
    100: '#c2edd4',
    200: '#9edfb9',
    300: '#79d29e', // base color
    400: '#55c684',
    500: '#3bac6a',
    600: '#2d8652',
    700: '#1e6039',
    800: '#0e3a21',
    900: '#001507',
  },
  blue: {
    50: '#e1f2ff',
    100: '#b5d4fe',
    200: '#87b7f8',
    300: '#599bf2',
    400: '#2c7eed', // base color
    500: '#1265d3',
    600: '#084ea5',
    700: '#023877',
    800: '#00224a',
    900: '#000c1e',
  },
  blue2: {
    50: '#ddf7ff',
    100: '#b8e1f9',
    200: '#8eccee',
    300: '#64b7e6',
    400: '#3ba3dd', // base color
    500: '#2289c4',
    600: '#146b99',
    700: '#084c6f',
    800: '#002f45',
    900: '#00101d',
  },
  blue3: {
    50: '#dbfaff',
    100: '#b2ebfd',
    200: '#86dbf7',
    300: '#59cdf2', // base color
    400: '#31bfee',
    500: '#1ca5d4',
    600: '#0d80a6',
    700: '#005c77',
    800: '#00384a',
    900: '#00141d',
  },
  blue4: {
    50: '#dff5ff',
    100: '#b9dbf8',
    200: '#90c2ee',
    300: '#66a9e4',
    400: '#3d91dc',
    500: '#2377c2', // base color
    600: '#185d98',
    700: '#0d426e',
    800: '#012845',
    900: '#000e1d',
  },
  purple: {
    50: '#f7e7ff',
    100: '#dbbdf7',
    200: '#c292ee',
    300: '#a867e5',
    400: '#8f3cdc', // base color
    500: '#7623c3',
    600: '#5b1a99',
    700: '#41126e',
    800: '#270a44',
    900: '#10021c',
  },
  purple2: {
    50: '#fae9ff',
    100: '#e5c1f2',
    200: '#d09ae6',
    300: '#be71db', // base color
    400: '#ab49cf',
    500: '#9230b6',
    600: '#71248e',
    700: '#511966',
    800: '#320e3f',
    900: '#130319',
  },
  purple3: {
    50: '#f4edfe',
    100: '#d9cce7',
    200: '#beadd3',
    300: '#a48cc0',
    400: '#8a6bad',
    500: '#715193',
    600: '#583f73', // base color
    700: '#3f2d53',
    800: '#271a33',
    900: '#100716',
  },
  purple4: {
    50: '#ffffff',
    100: '#f9f6fe',
    200: '#f4edfe',
    300: '#d9cce7',
    400: '#beadd3',
    500: '#a48cc0',
    600: '#8a6bad',
    700: '#715193',
    800: '#583f73', // base color
    900: '#3f2d53',
  },
};

export const radioCheckedStyle = {
  bg: `#583f73`,
  borderColor: `#583f73`,
  _before: {
    content: '""',
    display: 'block',
    width: '50%',
    height: '50%',
    borderRadius: '50%',
    bg: 'white',
    margin: '25%',
  },
};

const theme = extendTheme({
  colors: customColors,
  shadows: {
    outline: 'none',
  },
  fonts: {
    body: 'Public Sans, sans-serif',
    heading: 'Public Sans, sans-serif',
  },
});

export default theme;
