import React, { forwardRef } from 'react';
import { FormControl, FormLabel, Input, FormErrorMessage, BoxProps } from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';

interface Props extends BoxProps {
  name: string;
  label: string;
  type: string;
  placeholder: string;
  defaultValue?: string;
  error: FieldError | any;
  readOnly?: boolean;
  InputComponent?: React.ComponentType<any>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Field = forwardRef<HTMLInputElement, Props>((props, ref): JSX.Element => {
  const {
    name,
    label,
    type,
    placeholder,
    error,
    defaultValue,
    readOnly,
    InputComponent = Input,
    onChange,
    ...boxProps
  } = props;

  return (
    <FormControl isInvalid={!!error} {...boxProps}>
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
      <InputComponent
        ref={ref}
        name={name}
        id={name}
        width="full"
        variant="flushed"
        placeholder={placeholder}
        defaultValue={defaultValue}
        type={type}
        readOnly={readOnly}
        onChange={onChange}
      />
      <FormLabel as="label" htmlFor={name} fontStyle="italic" fontSize={12} fontWeight="light">
        {label}
      </FormLabel>
    </FormControl>
  );
});

Field.displayName = 'Field';

export default Field;
