import IAppointment from 'src/modules/appointments/interfaces/IAppointment';
import { format as formatDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { startOfDay, endOfDay } from 'date-fns';

export const defaultTimeZone = 'America/New_York';

export const getUserTimeZoneDate = (date: Date, timeZone = defaultTimeZone) => {
  const zonedDate = utcToZonedTime(date, timeZone);
  return new Date(zonedDate.getFullYear(), zonedDate.getMonth(), zonedDate.getDate());
};

export const getTz = (zone: string) => {
  const options: {
    timeZone: string;
    timeZoneName: 'short' | 'long';
  } = {
    timeZone: zone,
    timeZoneName: 'short',
  };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const dateString = formatter.format(new Date());
  return dateString.split(', ')[1].toString();
};

const getDurationInfo = (minutes: number) => {
  const hours: number = Math.floor(minutes / 60);
  const remainingMinutes: number = minutes % 60;
  return hours < 1
    ? `${remainingMinutes} minutes`
    : `${hours} ${hours === 1 ? 'hour' : 'hours'}${remainingMinutes > 0 ? ` ${remainingMinutes} minutes` : ''}`;
};

export const convertDateWithTimezone = (date: Date, timeZone = defaultTimeZone): Date => {
  return utcToZonedTime(date, timeZone);
};

export const getMaxTime = (timezone = defaultTimeZone) => {
  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59, 999);
  return utcToZonedTime(endOfDay, timezone);
};

export const getOverlappingAppointments = (
  existingAppointments: IAppointment[],
  potentialStart: Date,
  potentialEnd: Date,
  currentAppointmentId?: string,
): IAppointment[] => {
  return existingAppointments.filter((appointment) => {
    // if editing an existing appointment, it can overlap with itself
    if (currentAppointmentId === appointment.id) return false;
    const existingStart = appointment.appointmentTime.timeStart;
    const existingEnd = appointment.appointmentTime.timeEnd;
    const overlapping = !(potentialEnd <= existingStart || potentialStart >= existingEnd);
    return overlapping;
  });
};

export const setMidnightInUserTimeZone = (timeZone = defaultTimeZone) => {
  const now = new Date();
  const midnight = new Date(now.setHours(0, 0, 0, 0));
  return zonedTimeToUtc(midnight, timeZone);
};

export const getCurrentTimeInUserTimeZone = (timeZone = defaultTimeZone) => {
  const now = new Date();
  return utcToZonedTime(now, timeZone);
};

export const formatTimeWithoutDate = (time: Date, timeZone: string): string => {
  const zonedTime = convertDateWithTimezone(time, timeZone);
  return formatDate(zonedTime, 'HH:mm', { timeZone });
};

export const isDateToday = (date: Date): boolean => {
  const now = new Date();
  const startOfToday = startOfDay(now);
  const endOfToday = endOfDay(now);

  return date >= startOfToday && date <= endOfToday;
};

export const formatDateWithoutTime = (date: Date, timeZone: string): string => {
  const zonedDate = convertDateWithTimezone(date, timeZone);
  return formatDate(zonedDate, 'yyyy-MM-dd', { timeZone });
};

export const formatTime = (date: Date, time: Date, timeZone: string) => {
  const appointmentDateWithoutTime = formatDateWithoutTime(date, timeZone);
  const strTime = formatTimeWithoutDate(time, timeZone);
  return `${appointmentDateWithoutTime}T${strTime}`;
};

export const formatTimeStringFromDateTimeAndTz = (date: Date, time: Date, timeZone = defaultTimeZone) => {
  const adjustedTime = new Date(formatTime(date, time, timeZone));
  return formatDate(adjustedTime, 'h:mm aaa');
};

export const getAppointmentTimeStringWithDuration = (
  timeStartString: string,
  timeEndString: string,
  duration: number,
  timezone = defaultTimeZone,
) => {
  const appointmentStart = timeStartString.toUpperCase();
  const appointmentEnd = timeEndString.toUpperCase();
  return `${appointmentStart} - ${appointmentEnd} ${getTz(timezone)} (${getDurationInfo(duration)})`;
};

export const getAppointmentTimeStringRange = (appointment: IAppointment, timeZone = defaultTimeZone) => {
  const appointmentStart =
    appointment.appointmentTime.timeStartString?.toUpperCase() ??
    formatTimeStringFromDateTimeAndTz(
      appointment.appointmentDate,
      appointment.appointmentTime.timeStart,
      timeZone,
    ).toUpperCase();
  const appointmentEnd =
    appointment.appointmentTime.timeEndString?.toUpperCase() ??
    formatTimeStringFromDateTimeAndTz(
      appointment.appointmentDate,
      appointment.appointmentTime.timeEnd,
      timeZone,
    ).toUpperCase();
  return `${appointmentStart} - ${appointmentEnd}`;
};

export const getAppointmentDateStringWithDay = (appointment: IAppointment, timezone: string, locale = 'en-US') => {
  const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: timezone,
  };

  const optionsDay: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    timeZone: timezone,
  };

  const formattedDate = appointment.appointmentDate.toLocaleDateString(locale, optionsDate);
  const formattedDay = appointment.appointmentDate.toLocaleDateString(locale, optionsDay);

  return `${formattedDate} (${formattedDay})`;
};
