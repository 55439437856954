import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, List, ListItem, Link } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import IMedication from '../../modules/medications/interfaces/IMedication';
import { createInteraction } from '../../modules/interactions/actions';
import { moduleName, interactionType } from '../../modules/interactions/constants';
import { useDeleteMedication, useQueryMedications } from '../../modules/medications/queryHooks';
import { formatFrequency } from 'src/modules/medications/utils';

export const formatStreak = (frequency: string): string => {
  switch (frequency) {
    case 'Monthly':
      return 'month';
    case 'Weekly':
      return 'week';
    default:
      return 'day';
  }
};

interface SuppliedProps {
  onSelectMedication(medication: IMedication | null): void;
}

type Props = SuppliedProps;

const MedicationDisplay = ({
  pir,
  createInteraction,
  onSelectMedication,
}: Props & PropsFromRedux): null | React.ReactElement => {
  const { t } = useTranslation(['medications', 'common']);
  const { data: medications = [] } = useQueryMedications(pir.id);
  const { mutate: deleteMedication } = useDeleteMedication(pir.id);

  const onDeleteMedication = (medications: IMedication): void => {
    if (window.confirm(t('common:manageContactModal.deleteMedicationConfirmation'))) {
      deleteMedication(medications);
      createInteraction({
        pir,
        dateTime: new Date(),
        moduleName: moduleName.MEDICATIONS,
        interactionType: interactionType.MEDICATIONS.DELETED,
      });
    }
  };
  const openEdit = (medication: IMedication) => {
    onSelectMedication(medication);
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MEDICATIONS,
      interactionType: interactionType.MEDICATIONS.EDITED,
    });
  };

  if (medications.length === 0) {
    return null;
  }

  return (
    <Flex width="100%" direction="column" justifyContent="left">
      {medications.map((medication, index) => (
        <Box
          width="100%"
          backgroundColor="grey6.50"
          pt={4}
          pb={0}
          pr={4}
          pl={4}
          mb={6}
          key={'medicate' + index}
          borderRadius={8}
        >
          <List as="ol" mb={5}>
            <ListItem mb={3}>
              <Flex justifyContent={'space-between'}>
                <Text textAlign="right" fontSize={18} fontWeight="bold">
                  {medication.name}
                </Text>
                <Text textAlign="right" fontSize={14}>
                  <Link
                    onClick={() => openEdit(medication)}
                    textDecoration="underline"
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    {t('medications:medicationDisplay.editLink')}
                  </Link>
                  <Link
                    ml={2}
                    textDecoration="underline"
                    onClick={() => onDeleteMedication(medication)}
                    _hover={{ textDecoration: 'none' }}
                  >
                    {t('medications:medicationDisplay.deleteLink')}
                  </Link>
                </Text>
              </Flex>
            </ListItem>
            <ListItem color="grey3.400" fontSize={14}>
              {t('medications:medicationDisplay.frequencyHeader')}
            </ListItem>
            <ListItem fontSize={14} mb={3}>
              {formatFrequency(medication)}
            </ListItem>
            {medication.nickname && (
              <ListItem fontSize={14} mb={3} fontWeight="bold">
                {medication.nickname}
              </ListItem>
            )}
            {medication.reward && (
              <ListItem color="grey3.400" fontSize={14}>
                {t('medications:medGoalDisplay.rewardHeader')}
              </ListItem>
            )}
            {medication.reward && (
              <ListItem fontSize={14} mb={3}>
                {medication.reward}
              </ListItem>
            )}
            {medication.streak && (
              <ListItem color="grey3.400" fontSize={14}>
                Goal Streak
              </ListItem>
            )}
            {medication.streak && (
              <ListItem fontSize={14} mb={3}>
                {medication.streak} {formatStreak(medication.frequency)}
              </ListItem>
            )}
            {medication.startDate && (
              <ListItem color="grey3.400" fontSize={14}>
                {t('medications:medGoalDisplay.startHeader')}
              </ListItem>
            )}
            {medication.startDate && (
              <ListItem fontSize={14} mb={3}>
                {t('medications:medGoalDisplay.startDate')} {medication.startDate.toLocaleDateString()}
              </ListItem>
            )}
          </List>
        </Box>
      ))}
    </Flex>
  );
};

const mapStateToProps = (state: RootState) => {
  const { selectedLinkedUser } = state.linkedUsers;

  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when seeing this component.',
    );
  }

  return {
    pir: selectedLinkedUser.pir,
  };
};

const MapDispatchToProps = {
  createInteraction,
};

const connector = connect(mapStateToProps, MapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MedicationDisplay);
