import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, Skeleton, Flex } from '@chakra-ui/react';
import FAIcon from '../../components/FAIcon';
import linkifyHtml from 'linkify-html';

const clampStyle: React.CSSProperties = {
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

interface Props {
  cpGeneralContactInfo: string;
  loading?: boolean;
}

const CPGeneralContactInfoBox = ({ cpGeneralContactInfo, loading }: Props): JSX.Element => {
  const { t } = useTranslation('common');
  const options = { defaultProtocol: 'https', attributes: { class: 'link' } };
  const cpGeneralContactWithLinks = linkifyHtml(cpGeneralContactInfo.replace(/\n/g, '<br>'), options);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // determining if cp general info is longer than two lines
    const fullHeight = textRef?.current?.scrollHeight;
    const clampedHeight = textRef?.current?.offsetHeight;

    // If the full height is greater, then the text exceeds two lines
    if (fullHeight && clampedHeight && fullHeight > clampedHeight) {
      setShowMore(true);
    }
  }, [cpGeneralContactWithLinks]);

  const toggleExpandExtraContactInfo = () => {
    console.log('cpGeneralContactWithLinks', cpGeneralContactWithLinks);
    setIsExpanded(!isExpanded);
  };

  return (
    <Box>
      <Skeleton isLoaded={!loading}>
        <Flex flexDirection={'column'}>
          <Box ref={textRef} border={'1px'} borderRadius={'5'} padding={'1'} style={isExpanded ? {} : clampStyle}>
            <Text fontSize={14} dangerouslySetInnerHTML={{ __html: cpGeneralContactWithLinks }} />
          </Box>
          {showMore && (
            <button
              onClick={toggleExpandExtraContactInfo}
              style={{
                background: 'none',
                border: 'none',
                color: 'purple3.600',
                padding: 0,
                margin: 0,
                cursor: 'pointer',
                fontSize: 10,
              }}
            >
              {
                <Flex justifyContent={'flex-end'}>
                  {isExpanded
                    ? t('manageUserInfoModal.cpGeneralContactInfo.seeLess')
                    : t('manageUserInfoModal.cpGeneralContactInfo.seeMore')}
                  <span>
                    <FAIcon ml={1} icon={isExpanded ? 'caret-up' : 'caret-down'} />
                  </span>
                </Flex>
              }
            </button>
          )}
        </Flex>
      </Skeleton>
    </Box>
  );
};

export default CPGeneralContactInfoBox;
