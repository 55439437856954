import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalContent, ModalBody, ModalOverlay, Text, Box, Flex } from '@chakra-ui/react';
import IAppointment from 'src/modules/appointments/interfaces/IAppointment';
import PrimaryButton from '../PrimaryButton';
import { getAppointmentDateStringWithDay, getAppointmentTimeStringRange } from './utils';
import SecondaryButton from '../SecondaryButton';
import { FormValues } from './AddAppointmentForm';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  overlappingAppointments: IAppointment[];
  saveAppointment?: (appointment: FormValues) => Promise<void>;
  appointment?: FormValues;
  userTimezone: string;
  onConfirmAppointment?: () => Promise<void>;
}

type Props = SuppliedProps;

const ConfirmOverlappingAppointmentsModal = ({
  isOpen,
  onClose,
  overlappingAppointments,
  saveAppointment,
  userTimezone,
  appointment,
  onConfirmAppointment,
}: Props): React.ReactElement => {
  const { t } = useTranslation('appointments');
  const { isPhone } = useDevice();

  const continueWithAppointment = () => {
    if (appointment && saveAppointment) {
      saveAppointment(appointment);
      onClose();
    } else if (onConfirmAppointment) {
      onConfirmAppointment();
      onClose();
    }
  };

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          maxWidth={isPhone ? ['90%', '90%', '90%', '90%', '90%'] : '350px'}
          py={6}
          px={6}
          zIndex={1900}
          borderRadius="10px"
        >
          <ModalBody w="100%">
            <Flex w="100%" direction="column" justifyContent="space-between">
              <Text fontWeight="normal" fontSize={16} mb={5}>
                {t('confirmOverlappingAppointmentsModal.overlapsMessage')}
              </Text>
              <ul>
                {overlappingAppointments.map((appointment, i) => {
                  const otherUserName = appointment.otherUserAlias ?? appointment.otherUserName;
                  const date = getAppointmentDateStringWithDay(appointment, userTimezone);
                  const timeRange = getAppointmentTimeStringRange(appointment, userTimezone);
                  return (
                    <li key={i}>
                      {t('confirmOverlappingAppointmentsModal.appointmentDetails', {
                        otherUserName,
                        date,
                        timeRange,
                      })}
                    </li>
                  );
                })}
              </ul>
              <PrimaryButton onClick={continueWithAppointment} mb={5} mt={5}>
                {appointment
                  ? t('confirmOverlappingAppointmentsModal.save')
                  : t('confirmOverlappingAppointmentsModal.confirm')}
              </PrimaryButton>
              <SecondaryButton onClick={onClose}>{t('confirmOverlappingAppointmentsModal.goBack')}</SecondaryButton>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ConfirmOverlappingAppointmentsModal;
