import { QueryDocumentSnapshot } from 'firebase/firestore';
import IMedication from './interfaces/IMedication';

export const formatMedicationFromFirestore = (snapshot: QueryDocumentSnapshot): IMedication => {
  const pir = snapshot.get('pir');
  let startDate = snapshot.get('startDate');
  let endDate = snapshot.get('endDate');
  let goalDaysTracked = snapshot.get('goalDaysTracked');
  let createdDateTime = snapshot.get('createdDateTime');
  const completedDays = snapshot.get('completedDays');

  if (typeof pir === 'undefined') {
    throw new Error('No PIR defined on the medication in the database');
  }

  if (startDate) {
    startDate = startDate.toDate();
  }

  if (endDate) {
    endDate = endDate.toDate();
  }

  if (createdDateTime) {
    createdDateTime = createdDateTime.toDate();
  }

  if (goalDaysTracked) {
    goalDaysTracked = goalDaysTracked.map((x: any) => x.toDate());
  }

  return {
    id: snapshot.id,
    pir,
    name: snapshot.get('name'),
    daysOfWeek: snapshot.get('daysOfWeek'),
    goal: snapshot.get('goal'),
    frequency: snapshot.get('frequency'),
    reward: snapshot.get('reward'),
    nickname: snapshot.get('nickname'),
    streak: snapshot.get('streak'),
    weeklyDay: snapshot.get('weeklyDay'),
    monthlyDay: snapshot.get('monthlyDay'),
    startDate,
    endDate,
    goalDaysTracked,
    completedDays,
    createdDateTime,
  };
};

interface MedicationToFirestore extends Omit<IMedication, 'id'> {
  id?: string;
}

export const formatMedicationToFirestore = (
  medication: MedicationToFirestore | IMedication,
): Omit<IMedication, 'id'> => {
  const out: Omit<IMedication, 'id'> = {
    pir: medication.pir,
    name: medication.name,
    frequency: medication.frequency,
  };

  if (typeof medication.daysOfWeek !== 'undefined') {
    out.daysOfWeek = medication.daysOfWeek;
  }

  if (typeof medication.reward !== 'undefined') {
    out.reward = medication.reward;
  }

  if (typeof medication.nickname !== 'undefined') {
    out.nickname = medication.nickname;
  }

  if (typeof medication.streak !== 'undefined') {
    out.streak = medication.streak;
  }

  if (typeof medication.goal !== 'undefined') {
    out.goal = medication.goal;
  }

  if (typeof medication.goalDaysTracked !== 'undefined') {
    out.goalDaysTracked = medication.goalDaysTracked;
  }

  if (typeof medication.completedDays !== 'undefined') {
    out.completedDays = medication.completedDays;
  }

  if (typeof medication.startDate !== 'undefined') {
    out.startDate = medication.startDate;
  }

  if (typeof medication.createdDateTime !== 'undefined') {
    out.createdDateTime = medication.createdDateTime;
  }

  if (typeof medication.weeklyDay !== 'undefined') {
    out.weeklyDay = medication.weeklyDay;
  }

  if (typeof medication.monthlyDay !== 'undefined') {
    out.monthlyDay = medication.monthlyDay;
  }

  return out;
};

export const formatFrequency = (medication: IMedication) => {
  if (medication.frequency === 'Daily') return medication.frequency;

  const frequency = medication.frequency;
  const weeklyDay = frequency !== 'Weekly' ? undefined : medication.weeklyDay ? medication.weeklyDay : 'Monday';
  const monthlyDay = frequency !== 'Monthly' ? undefined : medication.monthlyDay ? medication.monthlyDay : 1;
  const days =
    frequency === 'Select Days'
      ? `(${medication.daysOfWeek?.join(', ')})`
      : frequency === 'Weekly'
      ? `(${weeklyDay})`
      : frequency === 'Monthly'
      ? `(Day ${monthlyDay} of the month)`
      : '';

  return `${frequency} ${days}`;
};
