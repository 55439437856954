import React from 'react';
import { Box, Checkbox, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { IVitalList } from '../VitalsModuleCP';

interface Props {
  emotionItemsVisibility: IVitalList[];
  onEmotionVisibilityChanged: (visibility: IVitalList[]) => void;
}

const EmotionsLegendPanel = ({ emotionItemsVisibility, onEmotionVisibilityChanged }: Props): React.ReactElement => {
  const handleCheck = (e: any, selectedItem: string) => {
    const checkedEmotion = emotionItemsVisibility.map((obj) => {
      if (obj.name === selectedItem) {
        return { ...obj, checked: e.target.checked };
      }
      return obj;
    });
    onEmotionVisibilityChanged(checkedEmotion);
  };

  const handleSelectAll = () => {
    const selectedAllItem = [...emotionItemsVisibility];
    selectedAllItem.map((item) => item.checked = true);
    onEmotionVisibilityChanged(selectedAllItem);
  };

  return (
    <Box>
      <Stack spacing={3} mb={5}>
        {emotionItemsVisibility.map((item) => (
          <Checkbox key={item.name} onChange={(e) => handleCheck(e, item.name)} isChecked={item.checked} color="#8F8F8F">
            <GraphLineImg color={item.color} /> &nbsp;
            {item.name}
          </Checkbox>
        ))}
      </Stack>

      <Box as="button" textDecoration="underline" color="#2680D0" onClick={handleSelectAll}>
        Select All
      </Box>
    </Box>
  );
};

export default EmotionsLegendPanel;

const GraphLineImg = styled.span`
  width: 0.8em;
  height: 0.8em;
  text-align: center;
  line-height: 2em;
  border-radius: 1em;
  margin: 0 1em;
  display: inline-block;
  color: white;
  position: relative;
  border: 2px solid;
  border-color: ${(props) => props.color};

  &::before {
    content: '';
    position: absolute;
    top: 0.2em;
    left: 0.6em;
    width: 0.7em;
    height: 0.15em;
    background: ${(props) => props.color};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0.2em;
    left: -0.8em;
    width: 0.7em;
    height: 0.15em;
    background: ${(props) => props.color};
  }
`;
