export const moduleName = {
  MOTIVATOR: 'MOTIVATOR',
  RESOURCES: 'RESOURCES',
  MEDITATIONS: 'MEDITATIONS',
  SURVEY: 'SURVEY',
  MEDICATIONS: 'MEDICATIONS',
  DEVICE: 'DEVICE',
  SUPPORT_NETWORK: 'SUPPORT_NETWORK',
  APPOINTMENTS: 'APPOINTMENTS',
};

export const interactionType = {
  MOTIVATOR: {
    OPEN_MODAL: 'OPEN_MODAL',
    EDITED: 'EDITED',
    MODIFIED_FIELD: 'MODIFIED_FIELD',
    DELETED: 'DELETED',
    SAVED: 'SAVED',
    CLOSED: 'CLOSED',
  },
  RESOURCES: {
    OPEN_MODAL: 'OPEN_MODAL',
    EDITED: 'EDITED',
    MODIFIED_FIELD: 'MODIFIED_FIELD',
    DELETED: 'DELETED',
    SAVED: 'SAVED',
    CLOSED: 'CLOSED',
    VIEWED: 'VIEWED',
  },
  MEDITATIONS: {
    OPEN_MODAL: 'OPEN_MODAL',
    EDITED: 'EDITED',
    MODIFIED_FIELD: 'MODIFIED_FIELD',
    DELETED: 'DELETED',
    SAVED: 'SAVED',
    CLOSED: 'CLOSED',
    VIEWED: 'VIEWED',
  },
  SURVEY: {
    OPEN_MODAL: 'OPEN_MODAL',
    MODIFIED_FIELD: 'MODIFIED_FIELD',
    SAVED: 'SAVED',
    CLOSED: 'CLOSED',
  },
  MEDICATIONS: {
    OPEN_MODAL: 'OPEN_MODAL',
    EDITED: 'EDITED',
    MODIFIED_FIELD: 'MODIFIED_FIELD',
    DELETED: 'DELETED',
    SAVED: 'SAVED',
    CLOSED: 'CLOSED',
    VIEWED: 'VIEWED',
  },
  DEVICE: {
    OPEN_MODAL: 'OPEN_MODAL',
    EDITED: 'EDITED',
    MODIFIED_FIELD: 'MODIFIED_FIELD',
    DELETED: 'DELETED',
    SAVED: 'SAVED',
    CLOSED: 'CLOSED',
  },
  SUPPORT_NETWORK: {
    OPEN_MODAL: 'OPEN_MODAL',
    EDITED: 'EDITED',
    MODIFIED_FIELD: 'MODIFIED_FIELD',
    DELETED: 'DELETED',
    SAVED: 'SAVED',
    CLOSED: 'CLOSED',
  },
  APPOINTMENTS: {
    OPEN_MODAL: 'OPEN_MODAL',
    MODIFIED_FIELD: 'MODIFIED_FIELD',
    SAVED: 'SAVED',
    CLOSED: 'CLOSED',
    CANCELED: 'CANCELED',
    EDITED: 'EDITED',
    CREATED: 'CREATED',
    CONFIRMED: 'CONFIRMED',
    DECLINED: 'DECLINED',
  },
};
