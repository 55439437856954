import { CravingActions, CravingActionTypes } from './actions';
import ICraving from './interfaces/ICraving';

interface CravingState {
  loading: boolean;
  creating: boolean;
  craving: null | ICraving;
}

const defaultState: CravingState = {
  loading: false,
  creating: false,
  craving: null,
};

const cravingReducer = (state: CravingState = defaultState, action: CravingActions): CravingState => {
  switch (action.type) {
    case CravingActionTypes.SUBMIT_CRAVING:
      return {
        ...state,
        craving: action.craving,
      };

    case CravingActionTypes.CREATING_CRAVING:
      return {
        ...state,
        creating: true,
      };

    case CravingActionTypes.CREATED_CRAVING:
      return {
        ...state,
        creating: false,
      };

    default:
      return state;
  }
};

export default cravingReducer;
