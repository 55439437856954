/* eslint-disable camelcase */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Translation files
import en_admin from './locale/en/admin.json';
import en_common from './locale/en/common.json';
import en_errors from './locale/en/errors.json';
import en_auth from './locale/en/auth.json';
import en_menu from './locale/en/menu.json';
import en_onboarding from './locale/en/onboarding.json';
import en_home from './locale/en/home.json';
import en_device from './locale/en/device.json';
import en_resources from './locale/en/resources.json';
import en_meditations from './locale/en/meditations.json';
import en_motivator from './locale/en/motivator.json';
import en_medications from './locale/en/medications.json';
import en_survey from './locale/en/survey.json';
import en_vitals from './locale/en/vitals.json';
import en_appointments from './locale/en/appointments.json';
import en_free_date from './locale/en/free_date.json';
import en_support_requests from './locale/en/support_requests.json';
import en_linkedusers from './locale/en/linkedusers.json';

const resources = {
  en: {
    admin: en_admin,
    common: en_common,
    errors: en_errors,
    auth: en_auth,
    menu: en_menu,
    onboarding: en_onboarding,
    home: en_home,
    device: en_device,
    resources: en_resources,
    meditations: en_meditations,
    motivator: en_motivator,
    medications: en_medications,
    survey: en_survey,
    vitals: en_vitals,
    appointments: en_appointments,
    free_date: en_free_date,
    support_requests: en_support_requests,
    linkedusers: en_linkedusers,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    missingKeyHandler: (lng, ns, key) => {
      console.error(`I18n missing key for: ${lng.join(', ')} | ${ns} | ${key}`);
    },
  });

export default i18n;
