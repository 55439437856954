import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import ISupportRequest, { ISupportRequestUpdate, getSupportRequests, updateSupportRequestStatus } from '../../db/supportRequests';
import IUser from '../../modules/user/interfaces/IUser';

const KEY_NAME = 'support-requests';

export const useQuerySupportRequests = (pirData: IUser): UseQueryResult<ISupportRequest[], undefined> => {
  const { id: pirId } = pirData;
  return useQuery([KEY_NAME, pirId], async () => getSupportRequests(pirId));
};

export const useUpdateSupportRequest = (pirData: IUser): UseMutationResult<void, undefined, ISupportRequestUpdate, void> => {
  const { id: pirId } = pirData;
  const queryClient = useQueryClient();

  return useMutation(
    async (update: ISupportRequestUpdate) => {
      return updateSupportRequestStatus(update);
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries([KEY_NAME, pirId]);
      },
      onSuccess: async () => queryClient.invalidateQueries([KEY_NAME, pirId]),
    },
  );
};
