import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Heading, List, ListItem, Flex, Text, Button } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import { IIncompleteLinkedUser, PossibleLinkedUser } from './interfaces/ILinkedUser';
import ILinkedUser from './interfaces/ILinkedUser';
import { selectLinkedUser, updatePartialLinkedUser } from './actions';
import SelectableLinkedUser from './SelectableLinkedUser';
import { SelectedUserContext, UserContext } from '../interior/Index';
import { getUser } from '../../db/user';
import { useTranslation } from 'react-i18next';
import FAIcon from '../../components/FAIcon';
import { Link } from 'react-router-dom';
import { UserRole } from '../user/interfaces/IUser';

interface SuppliedProps {
  onSelect?(): void;
  includeDashboard?: boolean;
  filteredLinkedUsers?: (ILinkedUser | IIncompleteLinkedUser)[];
}

type Props = SuppliedProps & PropsFromRedux;

interface ItemProps {
  children: JSX.Element | JSX.Element[];
}

const SelectLinkedUser = (props: Props): JSX.Element => {
  const { setSelectedUser } = useContext(SelectedUserContext);
  const [linkedUsers, setLinkedUsers] = useState<(ILinkedUser | IIncompleteLinkedUser)[]>([]);
  const [pirLinkedUser, setPirLinkedUser] = useState<PossibleLinkedUser | null>(null);
  const { t } = useTranslation('home');
  const { filteredLinkedUsers } = props;
  const user = useContext(UserContext);

  useEffect(() => {
    if (user && user.role === UserRole.USER && props.pir) {
      const pir = props.pir;
      setPirLinkedUser(pir);
    } else if (filteredLinkedUsers) {
      setLinkedUsers(filteredLinkedUsers);
    } else if (props.linkedUsers?.length > 0) {
      const providerWith: ILinkedUser[] = [];
      const providerWithout: IIncompleteLinkedUser[] = [];
      props.linkedUsers.forEach((linkedUser) => {
        if (linkedUser.otherUserRole === 'PROVIDER' && linkedUser.approvedByOther && linkedUser.approvedByPir)
          providerWith.push(linkedUser as ILinkedUser);
        else if ((linkedUser.otherUserRole === 'PROVIDER' && !linkedUser.reviewedByOther) || !linkedUser.reviewedByPir)
          providerWithout.push(linkedUser);
      });
      let filteredLinkedUsers: (ILinkedUser | IIncompleteLinkedUser)[] = [];
      if (providerWith.length > 0) {
        filteredLinkedUsers = providerWith;
      } else {
        filteredLinkedUsers.push(providerWithout[0]);
      }
      setLinkedUsers(filteredLinkedUsers);
    }
  }, [filteredLinkedUsers]);

  if (((user && user.role !== UserRole.USER) || !user) && props.linkedUsers.length === 0) {
    return (
      // todo
      <Heading>No patients selectable</Heading>
    );
  }

  const onSelectUser = async (linkedUser: PossibleLinkedUser): Promise<void> => {
    const user = await getUser(linkedUser.pir?.id || '');
    setSelectedUser(user);

    await props.selectLinkedUser(linkedUser);

    if (props.onSelect) {
      props.onSelect();
    }
  };

  // for right now I am setting all defaults to false because it is not clear Ellie wants this function
  const selectDefault = (linkedUser: PossibleLinkedUser): void => {
    const newDefault = !linkedUser.default;
    const id = linkedUser.id as string;

    props.updatePartialLinkedUser(id, { default: false }, true);
    // We can have only one default, so if we're setting the linked user as a new default, we need to unset any others
    if (newDefault) {
      linkedUsers.forEach((linkedUserIter) => {
        if (linkedUserIter.id !== linkedUser.id && linkedUserIter.default) {
          props.updatePartialLinkedUser(linkedUserIter.id, { default: false }, true);
        }
      });
    }
  };

  const Item = (props: ItemProps): JSX.Element => {
    return (
      <Link to="/dashboard">
        <ListItem mb={1}>
          <Button
            textAlign="left"
            variant="link"
            title={t('selectLinkedUser.providerDash')}
            color={'grey6.100'}
            _hover={{
              opacity: 0.8,
            }}
            _active={{
              opacity: 0.4,
            }}
            w={['15%', '15%', '10%']}
          >
            <FAIcon icon="star" />
          </Button>
          <Button
            textAlign="left"
            variant="link"
            backgroundColor="grey6.50"
            fontWeight={'normal'}
            color="black"
            p={5}
            borderRadius={0}
            w={['85%', '85%', '90%']}
            display="inline-block"
            _hover={{
              backgroundColor: 'grey6.100',
              cursor: 'pointer',
            }}
            _active={{
              opacity: 0.6,
            }}
            _focus={{
              backgroundColor: 'grey6.100',
            }}
          >
            <Flex justifyContent="space-between">
              {props.children}
              <FAIcon icon="arrow-circle-right" color="purple3.600" />
            </Flex>
          </Button>
        </ListItem>
      </Link>
    );
  };
  return (
    <List>
      {props.includeDashboard && (
        <Item>
          <Text whiteSpace="normal">{t('selectLinkedUser.providerDash')}</Text>
        </Item>
      )}
      {linkedUsers.map((linkedUser) => {
        return (
          <SelectableLinkedUser
            key={linkedUser.id}
            active={props.selectedLinkedUser !== null && props.selectedLinkedUser.id === linkedUser.id}
            linkedUser={linkedUser}
            onSelect={onSelectUser}
            selectDefault={selectDefault}
          />
        );
      })}
      {user && user.role === UserRole.USER && pirLinkedUser && (
        <SelectableLinkedUser
          key={pirLinkedUser.id}
          active={props.selectedLinkedUser !== null && props.selectedLinkedUser.id === pirLinkedUser.id}
          linkedUser={pirLinkedUser}
          onSelect={onSelectUser}
          selectDefault={selectDefault}
        />
      )}
    </List>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    linkedUsers: state.linkedUsers.otherLinkedUsers || [],
    selectedLinkedUser: state.linkedUsers.selectedLinkedUser,
    pir: state.linkedUsers.pir,
  };
};
const mapDispatchToProps = {
  selectLinkedUser,
  updatePartialLinkedUser,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SelectLinkedUser);
