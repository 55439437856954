import firebase from 'firebase/compat/app';

export enum LinkedUserRole {
  PIR = 'PERSON_IN_RECOVERY',
  PROVIDER = 'PROVIDER',
  SUPPORTER = 'SUPPORTER',
}

export enum PhoneAuthorization {
  PENDING = 'PENDING',
  AUTHORIZED = 'AUTHORIZED',
  DENIED = 'DENIED',
}

export default interface ILinkedUser {
  id: string;
  pir: firebase.firestore.DocumentReference;
  otherUser: firebase.firestore.DocumentReference;
  otherUserRole: LinkedUserRole;
  otherUserPhone?: string;
  otherUserEmail?: string;
  default: boolean;
  approvedByPir: boolean;
  approvedByOther: boolean;
  reviewedByPir: true;
  reviewedByOther: true;
  dateSubmitted: firebase.firestore.Timestamp;
  dateReviewedByPir: firebase.firestore.Timestamp;
  dateReviewedByOther: firebase.firestore.Timestamp;
  contactAlias?: string;
  pirAlias?: string;
  relationship?: string;
  preferredName?: string;
  phoneAuthorization?: PhoneAuthorization;
  notificationOnSOS?: boolean;
  supporteeNumber?: number;
  deletedPir?: boolean;
  deletedCp?: boolean;
}

export interface IPirLinkedUser
  extends Omit<
    Omit<
      Omit<
        Omit<
          Omit<
            Omit<
              Omit<
                Omit<
                  Omit<
                    Omit<
                      Omit<
                        Omit<
                          Omit<
                            Omit<
                              Omit<Omit<Omit<ILinkedUser, 'otherUser'>, 'otherUserRole'>, 'approvedByOther'>,
                              'reviewedByOther'
                            >,
                            'dateReviewedByOther'
                          >,
                          'contactAlias'
                        >,
                        'pirAlias'
                      >,
                      'deletedCp'
                    >,
                    'relationship'
                  >,
                  'reviewedByPir'
                >,
                'preferredName'
              >,
              'otherUserPhone'
            >,
            'notificationOnSOS'
          >,
          'otherUserEmail'
        >,
        'dateReviewedByPir'
      >,
      'phoneAuthorization'
    >,
    'supporteeNumber'
  > {
  reviewedByPir: boolean;
  dateReviewedByPir?: firebase.firestore.Timestamp;
}

export interface INewPirLinkedUser extends Partial<IPirLinkedUser> {
  pir: firebase.firestore.DocumentReference;
  default: boolean;
}

export interface INewSupporterLinkedUser extends Partial<ISupporterLinkedUser> {
  pir: firebase.firestore.DocumentReference;
  contactAlias: string;
  preferredName: string;
  otherUserEmail: string;
}

/**
 * Used during linkage creation and approval
 */
export interface IIncompleteLinkedUser
  extends Omit<
    Omit<
      Omit<
        Omit<
          Omit<Omit<Omit<Omit<ILinkedUser, 'pir'>, 'otherUser'>, 'otherUserRole'>, 'reviewedByOther'>,
          'reviewedByPir'
        >,
        'dateReviewedByOther'
      >,
      'dateReviewedByPir'
    >,
    'supporteeNumber'
  > {
  pir?: firebase.firestore.DocumentReference;
  otherUser?: firebase.firestore.DocumentReference;
  otherUserRole?: LinkedUserRole;
  reviewedByPir: boolean;
  reviewedByOther: boolean;
  dateReviewedByPir?: firebase.firestore.Timestamp;
  dateReviewedByOther?: firebase.firestore.Timestamp;
  email?: string;
  name?: string;
  contactAlias?: string;
  pirAlias?: string;
  phone?: string;
  notificationOnSOS?: boolean;
}

export interface IRejectedLinkedUser extends Partial<ILinkedUser> {
  pir: firebase.firestore.DocumentReference;
  otherUser: firebase.firestore.DocumentReference;
  default: false;
  otherUserPhone?: string;
  otherUserEmail?: string;
  approvedByPir: boolean;
  approvedByOther: boolean;
  reviewedByPir: true;
  reviewedByOther: true;
  dateSubmitted: firebase.firestore.Timestamp;
  dateReviewedByPir: firebase.firestore.Timestamp;
  dateReviewedByOther: firebase.firestore.Timestamp;
  contactAlias?: string;
  pirAlias?: string;
  relationship?: string;
  preferredName?: string;
}

export interface ISupporterLinkedUser
  extends Omit<
    Omit<
      Omit<
        Omit<
          Omit<
            Omit<Omit<Omit<Omit<ILinkedUser, 'pir'>, 'otherUser'>, 'otherUserRole'>, 'reviewedByOther'>,
            'reviewedByPir'
          >,
          'dateReviewedByOther'
        >,
        'deletedCp'
      >,
      'approvedByOther'
    >,
    'dateReviewedByPir'
  > {
  pir: firebase.firestore.DocumentReference;
  otherUserRole: LinkedUserRole;
  reviewedByPir: boolean;
  dateReviewedByPir: firebase.firestore.Timestamp;
  contactAlias: string;
  otherUserPhone?: string;
  otherUserEmail: string;
  preferredName: string;
  phoneAuthorization?: PhoneAuthorization;
  notificationOnSOS?: boolean;
  supporteeNumber?: number;
}

export type PossibleLinkedUser =
  | ILinkedUser
  | IIncompleteLinkedUser
  | IPirLinkedUser
  | ISupporterLinkedUser
  | IRejectedLinkedUser;

export type NonPirLinkedUser = ILinkedUser | IIncompleteLinkedUser | ISupporterLinkedUser | IRejectedLinkedUser;
/**
 * Used for creating linkages
 */
export interface INewLinkedUser extends Partial<IIncompleteLinkedUser> {
  default: boolean;
  type?: string;
}

export interface PirUsersData {
  linkedUser?: PossibleLinkedUser;
  person?: string;
  craving?: number | string;
  participation?: number | string;
  watchUse?: number | string;
  sleep?: number | string;
  emotion?: number | string;
  stress?: number | string;
  incidents?: number | string;
  supportRequests?: number | string;
  image?: { name: string; image: string };
}

export interface UserFeatures {
  cravingsPredictionEnabled: boolean;
}
