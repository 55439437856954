import firebase from 'firebase/compat/app';
import { UserRole } from 'src/modules/user/interfaces/IUser';

export enum LocationType {
  IN_PERSON = 'In-Person',
  VIRTUAL = 'Virtual',
}

export enum AppointmentType {
  UPCOMING = 'upcoming',
  PAST = 'past',
  CONFIRM = 'confirm',
  PENDING = 'pending',
}

export enum AppointmentColor {
  UPCOMING = '#A48CC0',
  PAST = '#929292',
  CONFIRM = '#F19442',
  PENDING = '#5B72C1',
}

export enum AppointmentStatus {
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  DECLINED = 'DECLINED',
  CREATED = 'CREATED',
}

export default interface IAppointment {
  id: string;
  pir: firebase.firestore.DocumentReference;
  providerUser?: firebase.firestore.DocumentReference;
  appointmentDate: Date;
  appointmentTime: {
    timeStart: Date;
    timeEnd: Date;
    timeStartString?: string;
    timeEndString?: string;
  };
  duration?: number;
  approvedByPir?: boolean;
  approvedByProvider?: boolean;
  notesFromProvider?: string;
  notesFromPir?: string;
  type?: string;
  otherUserAlias?: string;
  otherUserName?: string;
  location?: string;
  locationType?: LocationType;
  appointmentStatus: AppointmentStatus;
  lastChangeMadeBy?: UserRole;
}
