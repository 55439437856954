import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import {
  Box,
  Button,
  Heading,
  Text,
  FormErrorMessage,
  FormControl,
  Spinner,
  Flex,
  VStack,
  useToast,
} from '@chakra-ui/react';
import emailPattern from '../../constants/emailPattern';
import Field from './Field';
import axios from 'axios';

interface Props {
  onSubmit(data: Record<string, any>): Promise<void>;
  generalFormErrors: string[];
  onBack(): void;
  formErrorIsVisible: boolean;
  hideGoBack?: boolean | undefined;
}

const LogInForm = (props: Props): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const introLocation = { ...useLocation(), search: '' };
  const resetPassword = { ...useLocation(), search: '?reset-password=true' };
  const query = new URLSearchParams(location.search);
  const token = query.get('verification');
  const isEmailVerification = query.get('verify') !== null;
  const { t } = useTranslation('auth');
  const { handleSubmit, register, formState } = useForm();
  const { isSubmitting } = formState;
  const { errors } = formState;
  const [verifyingEmail, setVerifyingEmail] = useState<boolean>(isEmailVerification ? true : false);
  const [verifiedEmail, setVerifiedEmail] = useState<string>('');
  const toast = useToast();

  useEffect(() => {
    if (isEmailVerification && token) {
      verifyEmail(token);
    }
  }, []);

  const verifyEmail = async (token: string) => {
    try {
      const { data } = await axios.post(process.env.REACT_APP_VERIFY_EMAIL_URL || '', {
        verificationToken: token,
        isUpdatingEmail: true,
      });
      if (data) {
        const { email } = data;
        setVerifiedEmail(email);
        setVerifyingEmail(false);
      }
      toast({
        description: t('logInForm.emailVerifiedDescription'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    } catch (err) {
      console.error(`Error verifying email: ${err}`);
      setVerifyingEmail(false);
    }
  };

  if (verifyingEmail) {
    return (
      <VStack spacing={20} flexGrow={1} minWidth={0} flexBasis="100" alignItems="stretch" maxHeight="90vh">
        <Flex justifyContent="center">
          <Box w="100%" h="25%" textAlign="center">
            <Text mt={4} fontSize={24} fontWeight="bold">
              Verifying email
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent="center" paddingBottom={10}>
          <Spinner />
        </Flex>
      </VStack>
    );
  }

  const handleLoginClick = () => {
    history.push({ pathname: '/', search: '?straight-to-login=true' });
  };

  if (isEmailVerification && !verifiedEmail) {
    return (
      <VStack spacing={20} flexGrow={1} minWidth={0} flexBasis="100" alignItems="stretch" maxHeight="90vh">
        <Flex justifyContent="center">
          <Box w="100%" h="25%" textAlign="center">
            <Text mt={4} fontSize={18} color={'red'}>
              {t('logInForm.errors.emailVerificationError')}
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent="center" paddingBottom={10}>
          <Button
            bg="white"
            color="purple3.600"
            borderRadius={0}
            w="80%"
            type="submit"
            onClick={handleLoginClick}
            _hover={{ background: 'blue', color: 'white' }}
            variant="outline"
            borderColor="purple3.600"
            data-test="login-btn"
          >
            Login or Change Password
          </Button>
        </Flex>
      </VStack>
    );
  }

  return (
    <Box>
      <Heading as="h2" fontSize={[24, 24, 36, 45]} fontWeight={400} color="purple3.600" mb="40px">
        {t('logInForm.header')}
      </Heading>

      <form onSubmit={handleSubmit(props.onSubmit)} noValidate={true}>
        {/* General form errors, mostly the responses from Firebase */}
        {props.formErrorIsVisible && (
          <FormControl isInvalid={props.generalFormErrors.length > 0} mb={8}>
            {props.generalFormErrors.map((error, i) => (
              <FormErrorMessage key={i}>{error}</FormErrorMessage>
            ))}
          </FormControl>
        )}

        <Field
          {...register('email', {
            required: {
              value: true,
              message: t('logInForm.errors.emailRequired'),
            },
            pattern: {
              value: emailPattern,
              message: t('logInForm.errors.emailInvalid'),
            },
          })}
          name="email"
          label={t('logInForm.emailLabel')}
          type="email"
          placeholder="somebody@gmail.com"
          error={errors.email}
          dataTest="login-email"
          defaultValue={isEmailVerification && verifiedEmail ? verifiedEmail : ''}
        />
        <Field
          {...register('password', {
            required: {
              value: true,
              message: t('logInForm.errors.passwordRequired'),
            },
          })}
          name="password"
          label={t('logInForm.passwordLabel')}
          type="password"
          placeholder="********"
          error={errors.password}
          dataTest="login-password"
        />
        <Button
          bg="blue4.500"
          color="white"
          borderRadius={0}
          w="100%"
          type="submit"
          _hover={{ backgroundColor: 'blue4.300' }}
          isLoading={isSubmitting}
          data-test="login-btn"
        >
          {t('logInForm.logInButton')}
        </Button>
      </form>
      <Text mt={4} textAlign="center">
        <Trans t={t} i18nKey="logInForm.resetPassword">
          resetPassword
          <Link to={resetPassword}>
            <Text as="span" color="purple3.600" fontWeight="bold">
              link
            </Text>
          </Link>
        </Trans>
      </Text>
      {!props.hideGoBack && (
        <Text mt={4} textAlign="center">
          <Trans t={t} i18nKey="logInForm.backButton">
            backButton
            <Link to={introLocation} onClick={props.onBack} data-test="login-back-btn">
              <Text as="span" color="purple3.600" fontWeight="bold">
                link
              </Text>
            </Link>
          </Trans>
        </Text>
      )}
    </Box>
  );
};

export default LogInForm;
