import React from 'react';
import { Text, Flex, Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import FAIcon from '../../../components/FAIcon';
import { getHowMeasure } from '../VitalsMeasure';

const MeasurePanel = (props: { selectedGraph: string }): React.ReactElement => {
  const { t } = useTranslation('vitals');

  const createTableRow = Array.from(Array(10), (e, i) => {
    const level = i + 1;
    return (
      <Tr key={i}>
        <Td py="1" px="0" textAlign="center">
          {level}
        </Td>
        <Td py="1" px="0" textAlign="center">
          {level === 1 ? 'Low' : level === 5 ? 'Moderate' : level === 10 ? 'High' : '.'}
        </Td>
      </Tr>
    );
  });

  return (
    <Box m="2">
      <Text fontWeight="bold" textAlign="center" lineHeight="1.2">
        <FAIcon icon="info-circle" color="purple3.600" marginRight="3" />
        How is this being measured?
      </Text>

      {props.selectedGraph === 'craving' ? (
        <Flex direction="column" mt="2" color="#4F4F4F">
          <Text fontSize="sm" lineHeight="1">
            {t(`vitals.howMeasured.contents.${props.selectedGraph}`)}
          </Text>
        </Flex>
      ) : null}
      {props.selectedGraph === 'emotion' ? (
        <Flex direction="column" mt="2" color="#4F4F4F">
          <Text textAlign="center" fontSize="sm" lineHeight="1">
            The higher the level, the more intense the specified emotion is felt.
          </Text>
          <TableContainer my="1" py="3">
            <Table size="sm" variant="unstyled">
              <Thead>
                <Tr>
                  <Th textAlign="center" pb="2" px="0">
                    Level
                  </Th>
                  <Th textAlign="center" pb="2" px="0">
                    Intensity
                  </Th>
                </Tr>
              </Thead>
              <Tbody>{createTableRow}</Tbody>
            </Table>
          </TableContainer>
          <Text textAlign="center" fontSize="xs" lineHeight="1">
            {t(`vitals.howMeasured.asterisk.${props.selectedGraph}`)}
          </Text>
        </Flex>
      ) : props.selectedGraph !== 'craving' ? (
        <>
          {getHowMeasure(t, props.selectedGraph).map((m: any) => (
            <Flex key={m.level} direction="column" my={4} color="#4F4F4F">
              <Text mb="1">{m.level}</Text>
              <Text fontSize="sm" lineHeight="1">
                {m.content}
              </Text>
            </Flex>
          ))}
          {props.selectedGraph !== 'craving' ? (
            <Text fontSize="sm" lineHeight="1" color="#4F4F4F">
              {t(`vitals.howMeasured.asterisk.${props.selectedGraph}`)}
            </Text>
          ) : null}
        </>
      ) : null}
    </Box>
  );
};

export default MeasurePanel;
