interface ErrorSpanProps {
  children: React.ReactNode;
}

export const ErrorSpan: React.FC<ErrorSpanProps> = ({ children }) => (
  <span
    style={{
      display: 'block',
      height: '16.5px',
      fontSize: '14px',
      lineHeight: 'normal',
      marginTop: '8px',
      color: '#cc1a19',
    }}
  >
    {children}
  </span>
);
