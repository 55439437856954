import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Heading, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import MotivatorModal from '../../components/ManageMotivatorModal/MotivatorModal';
import { getMotivator } from './actions';
import { createInteraction } from '../interactions/actions';
import { RootState } from '../../redux/store';

import { moduleName, interactionType } from '../interactions/constants';
import { isPirLinkedUser } from '../linked-users/utils';
import FAIcon from '../../components/FAIcon';
import PrimaryButton from '../../components/PrimaryButton';
import { useModal } from 'src/ModalContext';

const MODAL_NAME = 'motivator';

const MotivatorModule = ({ createInteraction, motivator, pir, isPir }: PropsFromRedux): React.ReactElement => {
  const { t } = useTranslation('motivator');

  const { modal, openModal, closeModal } = useModal();

  const addClosedInteraction = () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MOTIVATOR,
      interactionType: interactionType.MOTIVATOR.CLOSED,
    });
  };

  useEffect(() => {
    if (modal === MODAL_NAME) {
      isPir &&
        createInteraction({
          pir,
          dateTime: new Date(),
          moduleName: moduleName.MOTIVATOR,
          interactionType: interactionType.MOTIVATOR.OPEN_MODAL,
        });
    }
  }, [modal === MODAL_NAME]);

  return (
    <Flex direction="column" w="100%" h="100%" backgroundColor="gray.400">
      {motivator ? (
        <Flex position="relative" direction="column" w="100%" h="100%">
          <Text w="100%" textAlign="end" fontSize={18} zIndex={5} padding="15px">
            <Link style={{ display: 'inline-block' }} to={`/home/${MODAL_NAME}`} onClick={() => openModal(MODAL_NAME)}>
              <FAIcon
                icon="cog"
                color="#ffffff"
                width="26px"
                height="26px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="#8c8c8c"
                borderRadius="50%"
              />
            </Link>
          </Text>
          <Image src={motivator.attachmentUrl} alt={`${motivator.attachmentName}`} />
          <BottomContainer>
            <Heading textAlign="center" as="h3" fontSize={18} mb={1} fontWeight="normal" color="#000">
              {t('motivatorModule.moduleHeader')}
            </Heading>
            <Text textAlign="center" color="#000">
              {motivator.message}
            </Text>
          </BottomContainer>
        </Flex>
      ) : (
        <Flex p={4} direction="column" align="center" justify="center" backgroundColor="grey6.50" w="100%" h="100%">
          <Text fontSize={14} textAlign="center">
            {isPir ? t('motivatorModule.moduleInstructPIR') : t('motivatorModule.moduleInstruct')}
          </Text>
          <Link to={`/home/${MODAL_NAME}`} onClick={() => openModal(MODAL_NAME)}>
            <PrimaryButton mt={3} alignItems="center" zIndex={1}>
              {t('motivatorModule.moduleButton')}
            </PrimaryButton>
          </Link>
        </Flex>
      )}
      <MotivatorModal
        isOpen={modal === MODAL_NAME}
        onOpen={() => openModal(MODAL_NAME)}
        onClose={() => {
          closeModal();
          addClosedInteraction();
        }}
      />
    </Flex>
  );
};

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 45%;
  margin-top: auto;
  padding: 13px 0px;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.75);
`;

const Image = styled.img`
  width: 100%;
  position: absolute;
  transform: translateY(-18%);
`;

const mapStateToProps = (state: RootState) => {
  const { motivator } = state.motivator;
  if (motivator === null) {
    throw new Error("Motivator should't be null!");
  }

  const { selectedLinkedUser } = state.linkedUsers;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }
  return {
    motivator: motivator[0],
    pir: selectedLinkedUser.pir,
    isPir: isPirLinkedUser(selectedLinkedUser),
  };
};

const mapDispatchToProps = {
  getMotivator,
  createInteraction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MotivatorModule);
