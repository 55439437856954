import React, { ComponentProps } from 'react';
import { Flex, Select, SimpleGrid } from '@chakra-ui/react';
import FAIcon from './FAIcon';

interface Props extends ComponentProps<typeof SimpleGrid> {
  onDateChange: (newDate: Date | null, invalid: boolean) => void;
  maxYear: number;
  currDate: Date;
}

const DiscreteDatePicker = (gridProps: Props): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { columns, spacingX, spacingY, onDateChange, currDate, maxYear, ...props } = gridProps;
  const date = gridProps.currDate;
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const minutes12H = Math.floor(((date.getMinutes() + 60 * date.getHours()) % (12 * 60)) / 10) * 10;
  const pm = date.getHours() >= 12;

  const COMMON_SELECT_PROPS = {
    height: '29px',
    borderRadius: '5px',
    fontWeight: 400,
    fontSize: '16px',
    color: '#626161',
    border: '1px solid #E5E5E5',
    iconColor: '#C4C4C4',
    iconSize: '23px',
    icon: <FAIcon icon="caret-down" alignItems="center" justifyContent="center" display="flex" />,
    mr: ['11px', '7px'],
    mb: '7px',
  };

  const newDateWithoutSeconds = (date: Date): Date => {
    const res = new Date(date);
    res.setSeconds(0);
    res.setMilliseconds(0);
    return res;
  };

  const dateChanged = (newDate: Date) => {
    gridProps.onDateChange(newDate, isNaN(newDate.getTime()));
  };

  const yearChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const year = Number(event.target.value);
    const newDate = newDateWithoutSeconds(date);
    newDate.setFullYear(year);
    dateChanged(newDate);
  };

  const monthChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const month = Number(event.target.value);
    const newDate = newDateWithoutSeconds(date);
    newDate.setMonth(month);
    dateChanged(newDate);
  };

  const dayChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const day = Number(event.target.value);
    const newDate = newDateWithoutSeconds(date);
    newDate.setDate(day);
    dateChanged(newDate);
  };

  const minutes12HChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const minutes12H = Number(event.target.value);
    const hour = Math.floor(minutes12H / 60);
    const minute = minutes12H % 60;
    const newDate = newDateWithoutSeconds(date);
    const currHour = newDate.getHours();
    newDate.setHours(Math.floor(currHour / 12) * 12 + hour);
    newDate.setMinutes(minute);
    dateChanged(newDate);
  };

  const PMChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const multiplier = Number(event.target.value);
    const newDate = newDateWithoutSeconds(date);
    newDate.setHours((newDate.getHours() % 12) + 12 * multiplier);
    dateChanged(newDate);
  };

  const minutes12HToString = (minutes12H: number): string => {
    let hour = Math.floor(minutes12H / 60);
    hour = hour === 0 ? 12 : hour;
    const minutes = minutes12H % 60;
    const hourStr = hour < 10 ? '0' + hour.toString() : hour.toString();
    const minuteStr = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
    return `${hourStr}:${minuteStr}`;
  };

  const validYMD = (year: number, month: number, day: number): boolean => {
    try {
      const test = new Date(year, month, day);
      return (
        !isNaN(test.getTime()) && test.getDate() === day && test.getFullYear() === year && test.getMonth() === month
      );
    } catch (e) {
      return false;
    }
  };

  return (
    <SimpleGrid
      columns={columns ? columns : [1, 2]}
      spacingX={spacingX ? spacingX : '22px'}
      spacingY={spacingY ? spacingY : '17px'}
      {...props}
    >
      <Flex flexWrap="wrap">
        <Select width="82px" value={month} onChange={monthChanged} {...COMMON_SELECT_PROPS}>
          {[...Array(12).keys()]
            .filter((i) => validYMD(year, i, day))
            .map((i) => (
              <option value={i} key={i}>
                {new Date(2020, i).toLocaleString('default', { month: 'short' })}
              </option>
            ))}
        </Select>
        <Select width="70px" value={day} onChange={dayChanged} {...COMMON_SELECT_PROPS}>
          {[...Array(31).keys()]
            .filter((i) => validYMD(year, month, i + 1))
            .map((i) => (
              <option value={i + 1} key={i + 1}>
                {i + 1}
              </option>
            ))}
        </Select>
        <Select width="87px" value={year} onChange={yearChanged} {...COMMON_SELECT_PROPS}>
          {[...Array(10).keys()]
            .filter((i) => validYMD(gridProps.maxYear - i, month, day))
            .map((i) => (
              <option value={gridProps.maxYear - i} key={i}>
                {gridProps.maxYear - i}
              </option>
            ))}
        </Select>
      </Flex>
      <Flex flexWrap="wrap">
        <Select width="92px" value={minutes12H} onChange={minutes12HChanged} {...COMMON_SELECT_PROPS}>
          {[...Array(12 * 6).keys()].map((i) => (
            <option value={i * 10} key={i}>
              {minutes12HToString(i * 10)}
            </option>
          ))}
        </Select>
        <Select width="76px" value={pm ? 1 : 0} onChange={PMChanged} {...COMMON_SELECT_PROPS}>
          <option value={0}>AM</option>
          <option value={1}>PM</option>
        </Select>
      </Flex>
    </SimpleGrid>
  );
};

export default DiscreteDatePicker;
