import FirebaseUtils from '../firestore/Firestore';
import { Query, QueryConstraint, QuerySnapshot, query, getFirestore, collection, doc, DocumentReference, CollectionReference, DocumentData, getDocs } from 'firebase/firestore';

export const documentRef = (collectionName: string, documentId: string): DocumentReference => {
  return doc(getFirestore(FirebaseUtils.app()), collectionName, documentId);
};

export const collectionRef = (collectionName: string): CollectionReference => {
  return collection(getFirestore(FirebaseUtils.app()), collectionName);
};

export const firestoreQuery = (collectionName: string, ...constrants: QueryConstraint[]): Query<DocumentData> => {
  return query(collectionRef(collectionName), ...constrants);
};

export const queryDocs = async (collectionName: string, ...constrants: QueryConstraint[]): Promise<QuerySnapshot<DocumentData>> => {
  return getDocs(query(collectionRef(collectionName), ...constrants));
};
