import firebase from 'firebase/compat/app';
import { InvitedAsRole } from '../invitations/userInvitation';
import { UserActions, UserActionTypes } from './actions';
import IUser, { DeletionStatus } from './interfaces/IUser';

interface UserState {
  loading: boolean;
  user: null | IUser;
  nativeUserUid: null | string;
  unsubscribeFn: null | firebase.Unsubscribe;
  invitedAsRole: null | InvitedAsRole;
}

const defaultState: UserState = {
  loading: true,
  user: null,
  nativeUserUid: null,
  unsubscribeFn: null,
  invitedAsRole: null,
};

const userReducer = (state: UserState = defaultState, action: UserActions): UserState => {
  switch (action.type) {
    case UserActionTypes.GETTING_USER:
      return {
        ...state,
        loading: true,
      };

    case UserActionTypes.GOT_USER:
      return {
        ...state,
        loading: false,
      };

    case UserActionTypes.UPDATING_USER:
      return {
        ...state,
        loading: true,
      };

    case UserActionTypes.UPDATED_USER:
      return {
        ...state,
        loading: false,
        user: action.user,
      };

    case UserActionTypes.SET_USER:
      if (state.user?.deletionStatus === DeletionStatus.DELETING) {
        return { ...state };
      }
      return {
        ...state,
        user: action.user,
      };

    case UserActionTypes.GOT_INVITED_ROLE:
      return {
        ...state,
        invitedAsRole: action.invitedAsRole,
      };

    case UserActionTypes.SET_NATIVE_USER_UID:
      return {
        ...state,
        nativeUserUid: action.uid,
      };

    case UserActionTypes.DELETING_USER:
      if (!state.user) {
        return { ...state };
      }

      return {
        ...state,
        user: { ...state.user, deletionStatus: DeletionStatus.DELETING },
      };

    case UserActionTypes.DELETED_USER:
      if (!state.user) {
        return { ...state };
      }

      return {
        ...state,
        user: { ...state.user, deletionStatus: undefined },
      };

    case UserActionTypes.RESTORING_USER:
      if (!state.user) {
        return { ...state };
      }

      return {
        ...state,
        user: { ...state.user, deletionStatus: DeletionStatus.RESTORING },
      };

    case UserActionTypes.RESTORED_USER:
      if (!state.user) {
        return { ...state };
      }

      return {
        ...state,
        user: { ...state.user, deletionStatus: undefined },
      };

    case UserActionTypes.SET_UNSUB_FN:
      return {
        ...state,
        unsubscribeFn: action.unsubscribe,
      };

    case UserActionTypes.GRAPH_HIDDEN: {
      if (action.hiddenGraphs === undefined) {
        return { ...state };
      }

      if (!state.user) {
        return { ...state };
      }

      return {
        ...state,
        user: {
          ...state.user,
          preferences: {
            ...state.user?.preferences,
            hiddenGraphs: action.hiddenGraphs,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default userReducer;
