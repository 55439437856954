import React from 'react';
import { Box } from '@chakra-ui/react';

interface Props {
  children: JSX.Element | JSX.Element[];
}

// eslint-disable-next-line react/display-name
export default ({ children }: Props): JSX.Element => (
  <Box w={['95%', '95%', '75%', '75%', '50%']} mx="auto">
    {children}
  </Box>
);
