import React, { useEffect, useState, useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Avatar,
  Flex,
  VStack,
  HStack,
  SimpleGrid,
  Divider,
  AspectRatio,
  Skeleton,
  Button,
  Box,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { RootState } from '../../redux/store';
import IUser from '../user/interfaces/IUser';
import { formatPhone } from '../user/utils';
import FAIcon from '../../components/FAIcon';
import { SelectedUserContext } from '../interior/Index';
import { updatePartialLinkedUser } from '../linked-users/actions';
import PrimaryButton from '../.././components/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { ErrorSpan } from 'src/components/ErrorSpan';

interface SuppliedProps {
  pirAlias: string | undefined;
  setPirAlias: React.Dispatch<React.SetStateAction<string | undefined>>;
}

type Props = PropsFromRedux & SuppliedProps;

interface IUserData extends IUser {
  name?: string;
  age?: number;
  pronouns?: string;
  gender?: string;
  background?: string;
  preferredName?: string;
  pirAlias?: string | undefined;
}

/* This is only for CP Dashboard */
const PirContactCard = (props: Props): JSX.Element => {
  const [userData, setUserData] = useState<IUserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [contactCardError, setContactCardError] = useState<null | string>(null);
  const { selectedUser } = useContext(SelectedUserContext);
  const [tempUserData, setTempUserData] = useState<IUserData | null>(null);
  const NULL_FIELD_VALUE = '-';
  const { t } = useTranslation('linkedusers');
  const { updatePartialLinkedUser, selectedLinkedUser, pirAlias, setPirAlias, otherLinkedUsers } = props;

  useEffect(() => {
    if (selectedUser && selectedLinkedUser) {
      const pirData: IUserData = { ...selectedUser };
      if ('pirAlias' in selectedLinkedUser) {
        pirData.pirAlias = selectedLinkedUser.pirAlias;
        setPirAlias(pirAlias);
      }
      if ('preferredName' in selectedLinkedUser) pirData.preferredName = selectedLinkedUser.preferredName;
      setUserData(pirData);
      setLoading(false);
    }
  }, [props.selectedLinkedUser]);

  useEffect(() => {
    if (contactCardError) {
      setContactCardError(null);
    }
  }, [tempUserData]);

  const updatePirProfile = (data: IUserData | null): void => {
    if (selectedLinkedUser) {
      const newPirAlias = data?.pirAlias;
      const oldPirAlias = 'pirAlias' in selectedLinkedUser && selectedLinkedUser.pirAlias;

      if (newPirAlias && (!oldPirAlias || oldPirAlias !== newPirAlias)) {
        if (otherLinkedUsers?.find((pir) => pir.pirAlias === newPirAlias)) {
          return setContactCardError(t('pirProfile.errors.samePirAlias'));
        }
      }

      const updates = newPirAlias
        ? {
            pirAlias: newPirAlias,
          }
        : {};
      const toDelete = data?.pirAlias ? undefined : ['pirAlias'];
      updatePartialLinkedUser(selectedLinkedUser.id || '', updates, true, toDelete);

      if (data && data.pirAlias) setPirAlias(data.pirAlias);
      setUserData(data);
      setIsEditing(false);
    }
  };

  return (
    <Skeleton isLoaded={!loading}>
      <Flex
        flexDirection="column"
        width={['100%', '100%', '270px']}
        borderRadius="10px"
        overflow="hidden"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        backgroundColor="white"
        pb={['0px', '0px', '19px']}
      >
        {isEditing ? (
          <Flex position="relative">
            <Flex justifyContent="space-between" w="100%">
              <Button
                position="absolute"
                top="5px"
                left="5px"
                zIndex="2"
                onClick={() => {
                  setIsEditing(false);
                  setTempUserData(userData);
                }}
              >
                Cancel
              </Button>
              <PrimaryButton
                position="absolute"
                top="5px"
                right="5px"
                zIndex="2"
                onClick={() => {
                  updatePirProfile(tempUserData);
                }}
              >
                Save
              </PrimaryButton>
            </Flex>
          </Flex>
        ) : (
          <Flex position="relative">
            <Button
              position="absolute"
              top="5px"
              right="5px"
              zIndex="2"
              onClick={() => {
                setIsEditing(true);
                setTempUserData(userData);
              }}
            >
              Edit
            </Button>
          </Flex>
        )}

        <Flex width="100%" flexDirection={['row', 'row', 'column']} flexWrap={['wrap']} alignItems="stretch">
          <AspectRatio
            minWidth={['0px', '0px', '100%']}
            flexBasis={['241px', '241px', 'auto']}
            flexGrow={1}
            ratio={270 / 176}
            overflow="hidden"
            borderRadius={['10px', '10px', '0px']}
          >
            <Avatar
              src={userData?.image}
              backgroundColor="white"
              borderRadius="none"
              icon={<FAIcon icon="user-circle" color="grey3.400" fontSize="8xl" />}
              // name={user.name} << enable for name initials as fallback
            />
          </AspectRatio>
          <HStack
            px="25px"
            pt={['20px', '20px', '39px']}
            pb={['20px', '20px', '5px']}
            minWidth="0px"
            flexGrow={[100, 100, 1]}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <SimpleGrid
              minWidth="0px"
              columns={1}
              spacingX="30px"
              spacingY="14px"
              alignItems="flex-start"
              justifyContent="flex-start"
              p={0}
            >
              {contactCardError && (
                <Box mb={3}>
                  <ErrorSpan>{contactCardError}</ErrorSpan>
                </Box>
              )}
              <VStack spacing="10px" alignItems="flex-start">
                <HeadingText>Alias</HeadingText>
                {isEditing ? (
                  <VStack spacing="5px" alignItems="flex-start">
                    <Input
                      id="pirAlias"
                      value={tempUserData?.pirAlias || ''}
                      onChange={(e) => {
                        if (!tempUserData) return;
                        setTempUserData({ ...tempUserData, pirAlias: e.target.value });
                      }}
                      placeholder={t('invitations.invite.name.cp.placeholder')}
                    />
                    <Label>{t('invitations.invite.name.cp.label')}</Label>
                  </VStack>
                ) : userData?.pirAlias && userData?.pirAlias?.length > 0 ? (
                  <DescriptionText>{userData.pirAlias}</DescriptionText>
                ) : (
                  <MissingInput>{t('pirProfile.emptyPirAlias')}</MissingInput>
                )}
              </VStack>
              <VStack spacing="10px" alignItems="flex-start">
                <HeadingText>Preferred Name</HeadingText>
                <DescriptionText>{userData?.preferredName ?? userData?.name ?? NULL_FIELD_VALUE}</DescriptionText>
              </VStack>
            </SimpleGrid>
            <Flex
              minWidth="0px"
              minHeight="0px"
              border="none"
              width="26px"
              height="26px"
              onClick={() => setExpanded(!expanded)}
              borderRadius="full"
              backgroundColor="white"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
              display={['flex', 'flex', 'none']}
            >
              <FAIcon
                fontSize="12px"
                icon="angle-down"
                transition="0.3s"
                transform={expanded ? 'rotate(-180deg)' : 'rotate(0deg)'}
              />
            </Flex>
          </HStack>
        </Flex>
        <Flex
          maxHeight={expanded ? '700px' : ['0px', '0px', '700px']}
          transition="0.4s"
          overflow="hidden"
          width="100%"
          flexDirection="row"
          // justifyContent="space-between"
          flexWrap="wrap"
          pt={expanded ? '15px' : ['0px', '0px', '15px']}
        >
          {/* commenting this out until it's fully implemented */}
          {/* <VStack minWidth="0px" flexGrow={1} flexBasis="400px" px="25px" pt={['8px', '8px', '19px']} pb={['30px', '30px', '19px']} alignItems="flex-start">
            <HeadingText>Background</HeadingText>
            <Divider borderColor="#8F8F8F" />
            <SubHeadingText>{userData?.background ?? NULL_FIELD_VALUE}</SubHeadingText>
          </VStack>
          <VStack minWidth="0px" flexGrow={1} flexBasis="400px" px="25px" pt={['8px', '8px', '19px']} pb={['30px', '30px', '19px']} alignItems="flex-start">
            <HeadingText>Details</HeadingText>
            <Divider borderColor="#8F8F8F" />
            <VStack spacing="17px" width="100%" pt="15px">
              <HStack width="100%" spacing="20px" justifyContent="space-between">
                <SubHeadingText>Age</SubHeadingText>
                <SubDescriptionText>{userData?.age ?? NULL_FIELD_VALUE}</SubDescriptionText>
              </HStack>
              <HStack width="100%" spacing="20px" justifyContent="space-between">
                <SubHeadingText>Pronouns</SubHeadingText>
                <SubDescriptionText>{userData?.pronouns ?? NULL_FIELD_VALUE}</SubDescriptionText>
              </HStack>
              <HStack width="100%" spacing="20px" justifyContent="space-between">
                <SubHeadingText>Gender</SubHeadingText>
                <SubDescriptionText>{userData?.gender ?? NULL_FIELD_VALUE}</SubDescriptionText>
              </HStack>
            </VStack>
          </VStack> */}
          <VStack
            minWidth="0px"
            flexGrow={1}
            flexBasis="400px"
            px="25px"
            pt={['8px', '8px', '19px']}
            pb={['30px', '30px', '19px']}
            alignItems="flex-start"
          >
            <HeadingText>Contact</HeadingText>
            <Divider borderColor="#8F8F8F" />
            <VStack spacing="17px" width="100%" pt="15px">
              {userData?.phone ? (
                <VStack width="100%" alignItems="flex-start">
                  <HeadingText>Phone</HeadingText>
                  <SubDescriptionText>
                    {userData?.phone ? formatPhone(userData.phone) : NULL_FIELD_VALUE}
                  </SubDescriptionText>
                </VStack>
              ) : null}
              <VStack width="100%" alignItems="flex-start">
                <HeadingText>Email</HeadingText>
                <SubDescriptionText>{userData?.email ?? NULL_FIELD_VALUE}</SubDescriptionText>
              </VStack>
            </VStack>
          </VStack>
        </Flex>
      </Flex>
    </Skeleton>
  );
};

const MapStateToProps = (state: RootState) => {
  const { selectedLinkedUser } = state.linkedUsers;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }
  return {
    pir: selectedLinkedUser.pir,
    selectedLinkedUser,
    otherLinkedUsers: state.linkedUsers.otherLinkedUsers,
  };
};

const MapDispatchToProps = {
  updatePartialLinkedUser,
};

const connector = connect(MapStateToProps, MapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PirContactCard);

const HeadingText = styled.div`
  font-weight: 700;
  color: #8f8f8f;
  font-size: 14px;
  line-height: 19.07px;
  text-align: left;
  text-transform: uppercase;
`;

const DescriptionText = styled.div`
  font-weight: 700;
  color: #4f4f4f;
  font-size: 18px;
  line-height: 24.51px;
  width: 100%;
`;

const SubDescriptionText = styled.div`
  font-weight: 700;
  color: #4f4f4f;
  font-size: 14px;
  line-height: 19.07px;
  width: 100%;
`;

const Input = styled.input`
  border: 1px solid #8f8f8f;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
`;

const Label = styled.label`
  font-size: 12px;
  font-style: italic;
`;

const MissingInput = styled.label`
  font-size: 12px;
  font-style: italic;
  color: #7f7f7f;
`;
