import firebase from 'firebase/compat/app';
import IDeviceGarmin from './interfaces/IDeviceGarmin';

// Device Garmin
export const formatDeviceGarminFromFirestore = (snapshot: firebase.firestore.QueryDocumentSnapshot): IDeviceGarmin => {
  const id = snapshot.id;
  const pir = snapshot.get('pir');
  const userId = snapshot.get('userId');
  const status = snapshot.get('status');

  return {
    id,
    pir,
    userId,
    status,
  };
};

interface DeviceGarminToFirestore extends Omit<IDeviceGarmin, 'id'> {
  id?: string;
}

export const formatDeviceGarminToFirestore = (deviceGarmin: DeviceGarminToFirestore): Omit<IDeviceGarmin, 'id'> => {
  const out: Omit<IDeviceGarmin, 'id'> = {
    pir: deviceGarmin.pir,
    userId: deviceGarmin.userId,
    status: deviceGarmin.status,
  };

  return out;
};
