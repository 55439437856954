import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Text,
  Button,
  useToast,
} from '@chakra-ui/react';
import { addAlert } from '../user/actions';
import { RootState } from '../../redux/store';
import { isPirLinkedUser } from '../linked-users/utils';
import { useDevice } from 'src/DeviceContext';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

const EmergencyAlert = ({ isOpen, onClose, isPir, addAlert }: Props & PropsFromRedux): JSX.Element => {
  const cancelRef = React.createRef<HTMLElement>();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { t } = useTranslation('common');
  const { isPhone } = useDevice();

  const sendAlert = async (): Promise<void> => {
    setLoading(true);
    try {
      await addAlert('sos');

      toast({
        title: t('emergencyAlert.successToast.title'),
        description: t('emergencyAlert.successToast.description'),
        status: 'success',
        duration: 10000,
        isClosable: true,
      });

      onClose();
    } catch (err) {
      toast({
        title: t('emergencyAlert.errorToast.title'),
        description: t('emergencyAlert.errorToast.description'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay />
      <AlertDialogContent
        maxWidth={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : ['100%', '98%', '90%', '85%', '75%']}
        minHeight={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : undefined}
        marginBottom={isPhone ? [0, 0, 0] : undefined}
        marginTop={isPhone ? [0, 0, 0] : undefined}
      >
        <AlertDialogHeader color="purple3.600" fontWeight="light" fontSize={24}>
          {t('emergencyAlert.header')}
        </AlertDialogHeader>
        <AlertDialogBody>
          {isPir && (
            <>
              <Text fontWeight="bold" mb={1}>
                {t('emergencyAlert.call911')}
              </Text>
              <Text>{t('emergencyAlert.description')}</Text>
            </>
          )}

          {!isPir && <Text>{t('emergencyAlert.providerDescription')}</Text>}
        </AlertDialogBody>
        <AlertDialogFooter display={['block', 'block', 'flex']}>
          <Button
            ref={cancelRef as any}
            onClick={onClose}
            fontWeight="normal"
            display={['block', 'block', 'inherit']}
            w={['100%', '100%', 'auto']}
          >
            {t('emergencyAlert.cancelButton')}
          </Button>
          <Button
            backgroundColor="red.300"
            isLoading={loading}
            _hover={{ backgroundColor: 'red.200' }}
            color="white"
            display={['block', 'block', 'inherit']}
            w={['100%', '100%', 'auto']}
            mt={[3, 3, 0]}
            ml={[0, 0, 4]}
            onClick={sendAlert}
          >
            {t('emergencyAlert.sendButton')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const mapStateToProps = (state: RootState) => {
  const selectedLinkedUser = state.linkedUsers.selectedLinkedUser;

  return {
    isPir: selectedLinkedUser !== null && isPirLinkedUser(selectedLinkedUser),
  };
};

const mapDispatchToProps = {
  addAlert,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EmergencyAlert);
