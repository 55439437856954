import { Action } from 'redux';
import { AppThunkPromise, AppThunkSync } from '../../redux/interfaces/AppThunk';
import Firestore from '../firestore/Firestore';
import { formatInvitationFromFirestore, UserInvitation } from './userInvitation';

export enum InvitationActionTypes {
  DELETED_INVITATION = 'DELETED_INVITATION',
  DELETING_INVITATION = 'DELETING_INVITATION',
  GETTING_INVITATIONS = 'GETTING_INVITATIONS',
  GOT_ERROR = 'GOT_ERROR',
  REGISTER_UNSUBSCRIBE_FUNCTIONS = 'REGISTER_UNSUBSCRIBE_FUNCTIONS',
  SET_INVITATIONS = 'SET_INVITATIONS',
  STOP_INVITATION_LISTENER = 'STOP_INVITATION_LISTENER',
}

export type InvitationActions =
  | DeletedInvitationAction
  | DeletingInvitationAction
  | GettingInvitationsAction
  | GotErrorAction
  | RegisterUnsubscribeFunctionsAction
  | SetInvitationsAction
  | StopInivtationListenerAction;

export const getInvitationsThunk = (): AppThunkSync<InvitationActions> => {
  return (dispatch) => {
    dispatch(gettingInvitations());

    const unsubscribeFromInvitations = Firestore.collection('invitations').onSnapshot(
      (snapshot) => {
        dispatch(gettingInvitations());
        const invitations = snapshot.docs.map((snapshot) => formatInvitationFromFirestore(snapshot));
        dispatch(setInvitations(invitations));
      },
      (error) => {
        dispatch(gotError(error.message));
      },
    );

    return dispatch(registerInvitationsUnsubscribeFunctions([unsubscribeFromInvitations]));
  };
};

export const deleteInvitationThunk = (invitationId: string): AppThunkPromise<InvitationActions> => {
  return async (dispatch) => {
    dispatch(deletingInvitation(invitationId));

    try {
      await Firestore.collection('invitations').doc(invitationId).delete();
    } catch (e) {
      return dispatch(gotError('Could not delete invitation'));
    }

    return dispatch(deletedInvitation(invitationId));
  };
};

export type GettingInvitationsAction = Action<InvitationActionTypes.GETTING_INVITATIONS>;

const gettingInvitations = (): GettingInvitationsAction => ({ type: InvitationActionTypes.GETTING_INVITATIONS });

export interface DeletingInvitationAction extends Action<InvitationActionTypes.DELETING_INVITATION> {
  invitationId: string;
}

const deletingInvitation = (invitationId: string): DeletingInvitationAction => ({
  type: InvitationActionTypes.DELETING_INVITATION,
  invitationId,
});

export interface DeletedInvitationAction extends Action<InvitationActionTypes.DELETED_INVITATION> {
  invitationId: string;
}

const deletedInvitation = (invitationId: string): DeletedInvitationAction => ({
  type: InvitationActionTypes.DELETED_INVITATION,
  invitationId,
});

export interface SetInvitationsAction extends Action<InvitationActionTypes.SET_INVITATIONS> {
  invitations: UserInvitation[];
}

const setInvitations = (invitations: UserInvitation[]): SetInvitationsAction => ({
  type: InvitationActionTypes.SET_INVITATIONS,
  invitations,
});

export interface GotErrorAction extends Action<InvitationActionTypes.GOT_ERROR> {
  error: string;
}

const gotError = (error: string): GotErrorAction => ({
  type: InvitationActionTypes.GOT_ERROR,
  error,
});

export interface RegisterUnsubscribeFunctionsAction
  extends Action<InvitationActionTypes.REGISTER_UNSUBSCRIBE_FUNCTIONS> {
  unsubscribeFromSnapshots: (() => void)[];
}

const registerInvitationsUnsubscribeFunctions = (
  unsubscribeFromSnapshots: (() => void)[],
): RegisterUnsubscribeFunctionsAction => ({
  type: InvitationActionTypes.REGISTER_UNSUBSCRIBE_FUNCTIONS,
  unsubscribeFromSnapshots,
});

export type StopInivtationListenerAction = Action<InvitationActionTypes.STOP_INVITATION_LISTENER>;

export const stopInvitationListenerThunk = (): AppThunkSync<StopInivtationListenerAction> => {
  return (dispatch, getState) => {
    const functions = getState().userInvitations.unsubscribeFromSnapshots;

    if (functions) {
      functions.forEach((unsubscribeFn) => {
        unsubscribeFn();
      });
    }

    return dispatch({
      type: InvitationActionTypes.STOP_INVITATION_LISTENER,
    });
  };
};
