import { AccelerometerActions, AccelerometerActionTypes } from './actions';

interface AccelerometerState {
  watchID: WatchHandle | null;
}

const defaultState: AccelerometerState = {
  watchID: null,
};

const accelerometerReducer = (
  state: AccelerometerState = defaultState,
  action: AccelerometerActions,
): AccelerometerState => {
  switch (action.type) {
    case AccelerometerActionTypes.STARTED_ACCELERATION_LISTENER:
      return {
        ...state,
        watchID: action.watchID,
      };
    case AccelerometerActionTypes.STOPPED_ACCELERATION_LISTENER:
      return {
        ...state,
        watchID: null,
      };

    default:
      return state;
  }
};

export default accelerometerReducer;
