import React from 'react';
import { Button, Box, useToast } from '@chakra-ui/react';
import Firestore from '../firestore/Firestore';

const MockData = (): JSX.Element => {

  const toast = useToast();

  type GenerateData = {
    dateTime: Date;
  };
  const dateTime = new Date();

  const generateData: GenerateData = { dateTime };

  const updateMockData = async () => {
    await Firestore.collection('mockDataGeneration')
      .doc()
      .set(generateData)
      .then(() => {
        toast({
          title: 'started mock data update',
          status: 'success',
          duration: 10000,
          isClosable: true,
        });
      })
      .catch((e) => {
        console.error(e);
        toast({
          title: 'Error in updating mock data',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Box>
      <Button
        onClick={async () => updateMockData()}
        type="submit"
        backgroundColor="green.300"
        _hover={{ backgroundColor: 'blue.200' }}
        color="white"
        display={['block', 'block', 'inherit']}
        w={['100%', '100%', 'auto']}
        mt={[3, 3, 0]}
        ml={[0, 0, 4]}
      >
        Update Demo Data
      </Button>
    </Box>
  );
};

export default MockData;
